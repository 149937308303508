import humps from 'humps';
import BaseStore from '../BaseStore';
import couponStore from './CouponStore';
import config from '../../config';
import {http} from '../../utils/http';
import { datetime } from '../../utils/datetime';
import qs from 'query-string';
import { set, get } from 'lodash';

export class CouponGroup extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      query: {},
      pagination: {},
      info: this.initInfo(),
      updateFields: this.initUpdateFiels(),
    });
    this.setDefaultQuery();
  }

  initInfo() {
    return {
      couponInfo: this.initCouponInfo(),
      name: '',
      coupons: [],
      newCouponInfo: {
        codes: [],
        productChannel: null,
        channel: [],
        model: 'MarketPlace',
        stores: [],
      },
      isDelete: false,
      couponFile: {
        name: '',
        path: '',
      },
      adBanner: null,
      heroBanner: null,
      heroBannerSquare: null,
      promotionBottomBanner: null,
      promotionThumbnail: null,
      isBannerPromotion: false,
      information: {
        seqNo: 1,
        title: '',
        description: '',
        isOnlyPromotionDetail: false,
        termsBrief: '',
        termsUse: '',
        couponHeader: '',
        content: '',
      },
    };
  }

  initNewCouponInfoStores() {
    this.info.newCouponInfo.stores = []
  }

  initCouponInfo () {
    const info = couponStore.initInfo();
    delete info.name;
    delete info.code;
    delete info.channel;
    delete info.isDelete;

    return info;
  }

  initUpdateFiels() {
    return {
      type: true,
      date: true,
      model: true,
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  clearNewCouponInfo() {
    this.info.newCouponInfo = {
      codes: [],
      productChannel: null,
      channel: [],
      model: 'MarketPlace',
      stores: [],
    }
  }

  saveInfo(key, value) {
    const { info } = this.toJS();

    if (key === 'model') {
      this.saveCouponInfo('requestStorePayment', false);
      this.saveCouponInfo('storePaymentNotiEmails', []);
    }

    set(info, key, value);
    this.info = info;
  }

  clearCouponInfo() {
    const coupon = this.initCouponInfo();
    delete coupon.code;
    this.info.couponInfo = coupon;
  }

  saveCouponInfo(key, value) {
    const { couponInfo } = this.toJS().info;

    if (key === 'model') {
      couponInfo.requestStorePayment = false;
      couponInfo.storePaymentNotiEmails = [];
    }

    set(couponInfo, key, value);
    this.info.couponInfo = couponInfo;
  }

  saveNewCouponInfo(key, value) {
    const { newCouponInfo } = this.toJS().info;
    set(newCouponInfo, key, value);
    this.info.newCouponInfo = newCouponInfo;

    if (key === 'model') {
      this.saveCouponInfo('requestStorePayment', false);
      this.saveCouponInfo('storePaymentNotiEmails', []);
    }
  }

  clearUpdateFields() {
    this.updateFields = this.initUpdateFiels();
  }

  saveUpdateFields(key, value) {
    const { updateFields } = this.toJS();
    set(updateFields, key, value);
    this.updateFields = updateFields;
  }

  setDefaultQuery() {
    this.query = {
      page_size: 10,
      page: 1,
      page_range: 5,
      channel: '',
      product: '',
      province: '',
      status: '',
      effectiveDate: undefined,
      name: '',
    };
  }

  setQueryValue(key, value) {
    this.query[key] = value;
  }

  setQuery(value) {
    this.query = value;
  }

  async getProductsDup(options) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.getUpdateInfo(options);
      const response = await http.post(`${config.api.qchang}/v1/coupongroup/productsDup`, info);
      if (response.status !== 200) {
        if (response.data.message) {
          throw new Error(`${response.data.message}`);
        } else {
          throw new Error('ไม่สำเร็จ');
        }
      } else {
        return response.data.data
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async searchCouponGroup() {
    if (this.isLoading) return;
    const {query} = this.toJS();
    try {
      this.isLoading = true;
      const res = await http.post(`${config.api.sims}/qchang/v1/coupongroup/search?${qs.stringify(query)}`);
      if (res && res.data && res.data.data) {
        this.items = res.data.data;
        this.pagination = res.data.pagination;
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async toggleStatus(index, status) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.put(`${config.api.qchang}/v1/coupongroup/${this.items[index]._id}/status`, { status });
      if (response.status === 200 && response.data) {
        this.items[index] = response.data.data;
      } else {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getCouponGroupById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.qchang}/v1/coupongroup/${id}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      const data = humps.camelizeKeys(response.data.data);
      if (!data.information) {
        data.information = {
          seqNo: '',
          title: '',
          content: '',
        }
      }

      if (!data.couponInfo.requestStorePayment) {
        data.couponInfo.requestStorePayment = false;
      }

      if (!data.couponInfo.storePaymentNotiEmails) {
        data.couponInfo.storePaymentNotiEmails = [];
      }

      this.info = {
        ...this.initInfo(),
        ...data,
      };
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  getUpdateInfo(options) {
    const { info } = this.toJS();
    const { couponInfo } = info;
    const { selectProductIds, provinceItems, branchItems } = options;
    if (Array.isArray(selectProductIds) && selectProductIds.length) {
      couponInfo.items = selectProductIds;
    }
    if (provinceItems && provinceItems.length) {
      couponInfo.provinceId = provinceItems.filter(Boolean);
    }
    if (branchItems && branchItems.length) {
      info.newCouponInfo.stores = branchItems.filter(Boolean);
    } else {
      delete info.newCouponInfo.stores
    }
    couponInfo.startDate = datetime.SendToApi(couponInfo.startDate);
    couponInfo.endDate = datetime.SendToApi(couponInfo.endDate);
    info.couponInfo = couponInfo;

    return info;
  }

  async create(options) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.getUpdateInfo(options);
      const response = await http.post(`${config.api.qchang}/v1/coupongroup`, info);
      if (response.status !== 200) {
        if (response.data.message) {
          throw new Error(`${response.data.message}`);
        } else {
          throw new Error('การบันทึกล้มเหลว');
        }
      }
      return get(response, 'data.data', {});
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async update(id, options) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const info = this.getUpdateInfo(options);
      const response = await http.put(`${config.api.qchang}/v1/coupongroup/${id}`, info);
      if (response.status !== 200) {
        if (response.data.message) {
          throw new Error(`${response.data.message}`);
        } else {
          throw new Error('การบันทึกล้มเหลว');
        }
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateCouponByGroupId(id, payload = {}) {
    try {
      const response = await http.put(`${config.api.qchang}/v1/coupongroup/${id}/update-coupon`, payload);
      if (response.status !== 200) {
        if (response.data.message) {
          throw new Error(`${response.data.message}`);
        } else {
          throw new Error('การบันทึกล้มเหลว');
        }
      }
    } catch (error) {
      throw error;
    } finally {
    }
  }

  async deleteCouponGroup(id) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.delete(`${config.api.qchang}/v1/coupongroup/${id}`);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteCouponFromGroup(couponId) {
    try {
      const response = await http.delete(`${config.api.qchang}/v1/coupongroup/${couponId}/coupon`);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    }
  }

  saveInformation(key, value) {
    const { information } = this.toJS().info;
    set(information, key, value);
    this.info.information = information;
    // this.info.information[key] = value;
  }

  deleteInformation() {
    this.info.information = {
      seqNo: 1,
      title: '',
      content: '',
    };
  }

  async uploadBannerImage(file) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await http.postMultipartFormData(`${config.api.qchang}/v1/coupongroup/upload`, formData);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateBannerInfo(id, info) {
    if (this.isLoading) return;
    this.isLoading = true;
    let url = `${config.api.qchang}/v1/coupongroup/${id}/banner`;
    let body = {
      ad_banner: info.adBanner,
      hero_banner: info.heroBanner,
      hero_banner_square: info.heroBannerSquare,
      promotion_bottom_banner: info.promotionBottomBanner,
      promotion_thumbnail: info.promotionThumbnail,
      information: info.information
    };

    try {
      let response = await http.put(url, body);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async uploadFile(file) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await http.postMultipartFormData(`${config.api.qchang}/v1/coupongroup/upload`, formData);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new CouponGroup();
