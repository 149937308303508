import React from 'react';
import { observer, inject } from 'mobx-react';
import { isString } from 'lodash';

import config from '../config';
import http from '../utils/http';

export class NavBarContractor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  componentDidMount = async () => {
    const { contractorgrade: contractorGradeStore } = this.props;
    await contractorGradeStore.initGradeMap();
  };

  toggleMenu() {
    let lastState = this.state.showMenu;
    this.setState({
      showMenu: !lastState,
    }, () => {
      let doc = document.getElementsByClassName('perfect-scrollbar-on');
      if (doc && doc.length) {
        let hDoc = doc[0];
        hDoc.classList.toggle('nav-open', !lastState);
      }
    });
  }
  

  render() {
    const { contractorgrade: contractorGradeStore } = this.props;
    const contractor = this.props.auth.getContractor();
    const displayedTeamName = isString(contractor.team_name) ? `(${contractor.team_name})` : '';
    const displayedTeamGrade = isString(contractor.team_grade) ? `(${contractorGradeStore.getDisplayedGrade(contractor.team_grade)})` : '';
    
    return (
      <nav className="navbar navbar-transparent navbar-absolute">
        <div className="container-fluid">
          <div className="navbar-header">
            <a className="navbar-brand user-name-text" onClick={this.toggleMenu.bind(this)}>
              {
                // <i className="fa fa-user-circle" aria-hidden="true" />
                <img alt="รูปโปรไฟล์" src={contractor.profile_pic} />
              }
              {contractor.first_name} {contractor.last_name} {displayedTeamName} {displayedTeamGrade}
            </a>
            <button type="button" className="navbar-toggle" data-toggle="collapse" onClick={this.toggleMenu.bind(this)}>
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
          </div>
        </div>
      </nav>
    );
  }
}

export default inject('auth', 'contractorgrade')(observer(NavBarContractor));
