/* eslint-disable no- */
/* eslint-disable no-unused-vars */
/* global FormData */

import * as _ from 'lodash';
import qs from 'query-string';
import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';
import Axios from 'axios';

export class ContractorBlockedPaymentStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      listBlockedPayment: [],
      lastestBlockedPayment: [],
      pagination: {
        start_index: 0
      },
      query: {
        page: 1,
        page_size: 15
      }
    });
  }
  
  async createBlockedPaymentHistory(body) {
    try {
      const url = `${config.api.sims}/v1/contractors/block-payment-history`
      const res = await Axios.post(url, body)
      if (res.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      console.log('createBlockedPaymentHistory error =====>', error.message);
      throw new Error('การบันทึกล้มเหลว');
    }
  }

  async getBlockedPaymentContractorHistories(contractorId, query) {
    try {
      const url = `${config.api.sims}/v1/contractors/${contractorId}/block-payment-histories?${qs.stringify(query)}`
      const result = await http.get(url);
      this.listBlockedPayment = result.data.data;
      this.pagination = result.data.pagination;
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  async getLatestBlockedPaymentContractorHistory(contractorId) {
    try {
      const url = `${config.api.sims}/v1/contractors/${contractorId}/latest-block-payment-history`
      const result = await http.get(url);
      this.lastestBlockedPayment = result.data.data;
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  setQueryValue(key, value) {
    this.query[key] = value;
  }
}

export default new ContractorBlockedPaymentStore();
