import React, { Component } from 'react';

class UploadFileV2 extends Component {
  compareFileChange = (oldFiles, newFiles, type) => {

    if (!newFiles || !newFiles.length) return false;

    const oldFile = oldFiles.filter((item) => item.type === type);
    const newFile = newFiles.filter((item) => item.type === type);

    return oldFile.length !== newFile.length;
  };

  // RENDER
  getDisplayEmpty = (message) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50px',
          width: '100%',
        }}
      >
        <em>
          <small>{message}</small>
        </em>
      </div>
    );
  };

  renderFileUpload = (files, draftFiles, type, editMode, hasDraft, hasFileDraft, onDeleteFile) => {
    if (!files.length && !hasFileDraft) {
      return (
        <>
          {this.getDisplayEmpty('ไม่มีเอกสาร')}
          <hr />
        </>
      );
    }

    const tempFiles = hasFileDraft ? draftFiles || [] : files; //handle draft file undefined
    const prepareFile = tempFiles.filter((item) => item.type === type);
    if (!prepareFile.length) {
      return (
        <>
          {this.getDisplayEmpty('ไม่มีเอกสาร')}
          <hr />
        </>
      );
    }

    return prepareFile.map((item, index) => {
      const lastIndex = prepareFile.length - 1 === index;
      return (
        <div key={`${item.type}-${index}`}>
          <div className="col-md-12" style={{ paddingRight: '0px' }}>
            <span className="col-md-1 text-center" style={{ }}>
              <i className="fa fa-file" aria-hidden="true" />
            </span>

            <span className="col-md-9 text-left" style={{ wordWrap: 'break-word' }}>
              <a href={item.url || ''} target="_blank">
                {item.name}
              </a>
            </span>

            {!hasDraft && editMode && (
              <span className="col-md-2 text-right" style={{ }}>
                <i className="fa fa-times" style={{ cursor: 'pointer' }} aria-hidden="true" onClick={() => onDeleteFile(item)} />
              </span>
            )}
          </div>

          {lastIndex && (
            <div className="col-md-12">
              <hr />
            </div>
          )}
        </div>
      );
    });
  };

  render() {
    const { selectedInfo, draftData, editMode, hasDraft, label, onAddFile, onDeleteFile, fileType, isContractor } = this.props;

    const hasFileDraft = isContractor ?  this.compareFileChange(selectedInfo.oldFiles, draftData.file, fileType) : this.compareFileChange(selectedInfo.file, draftData.file, fileType);

    return (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: hasFileDraft ? 'flex-start' : 'space-between',
            alignItems: 'center',
          }}
        >
          <label className="control-label">{label}</label>

          {/* EDIT MODE */}
          {!hasDraft && editMode && (
            <label className="control-label" style={{ cursor: 'pointer', color: '#265ed6' }}>
              <span>เพิ่มไฟล์ +</span>
              <input
                name={fileType}
                type="file"
                multiple
                accept="image/*,image/x-raw,image/heic,.raw,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.zip,.rar,.7z"
                style={{ display: 'none' }}
                onChange={(event) => onAddFile(event.target.files, fileType)}
              />
            </label>
          )}

          {/* DRAFT MODE */}
          {hasDraft && hasFileDraft && (
            <span style={{ padding: '5px' }}>
              <i className="fa fa-thumb-tack" aria-hidden="true"></i>
            </span>
          )}
        </div>
        {this.renderFileUpload(selectedInfo.file, draftData.file, fileType, editMode, hasDraft, hasFileDraft, onDeleteFile)}
      </>
    );
  }
}

export default UploadFileV2;
