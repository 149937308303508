import React from 'react';
import '../../assets/css/phone-pad.css';

class FuncButton extends React.Component {
  render() {
    const { className, onPress } = this.props;
    return (
      <div className="pad-btn">
        <div className="func-btn" onClick={onPress}>
          <i className={className} aria-hidden="true" />
        </div>
      </div>
    );
  }
}

export default FuncButton;
