import moment from 'moment';
import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';
import datetime from '../utils/datetime';

export class HomeOwnerStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      info: {},
      orderId: '',
      acceptance: {
        status: undefined,
        rating: 5,
        acceptMessage: '',
        rejectMessage: '',
        anonymous: false,
      },
    });
  }

  setAcceptanceInfo(key, value) {
    this.acceptance[key] = value;
  }

  getAvailableTimeText = (available) => {
    switch (available) {
      case 0:
        return 'ทั้งวัน';
      case 1:
        return 'เช้า';
      case 2:
        return 'บ่าย';
      default:
        return 'ทั้งวัน';
    }
  }

  getDateText(data) {
    let date = datetime.isEqual(data.start_date, data.end_date);
    let isServiceCard = false;
    if (config.svCardTypeId && config.svCardTypeId.includes(data.type_of_work.values().next().value)) {
      isServiceCard = true;
    }

    if (date) {
      return `${moment(data.start_date).format('DD/MM/YYYY')} ( ${isServiceCard ? data.time_label : this.getAvailableTimeText(data.available)} )`;
    } else {
      return `${moment(data.start_date).format('DD/MM/YYYY')} - ${moment(data.end_date).format('DD/MM/YYYY')} ( ${this.getAvailableTimeText(0)} )`;
    }
  }

  groupAndSortHistories(histories, status) {
    if (!Array.isArray(histories) || !histories.length) {
      return [];
    }
    return histories.filter(his => his.value === status).sort((d1, d2) => new Date(d2) - new Date(d1));
  }

  async getJobForAcceptance(id) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let response = await http.get(`${config.api.sims}/v1/homeowners/job/${id}/acceptance`);
      console.log('response:', response);
      if (response.status !== 200 || !response.data) {
        throw new Error('ไม่มีข้อมูล');
      }

      const { data, order_id: orderId } = response.data;
      // console.log('data:', data, ', orderId:', orderId);
      if (data) {
        this.info = {
          id: data._id,
          jobDate: this.getDateText(data),
          customerName: `${data.customer_firstname} ${data.customer_lastname}`,
          contractorName: data.contractor_name,
          contractorTeamName: data.contractor_team_name,
          contractorPhone: data.contractor_phone,
          contractorEmail: data.contractor_email,
          typeOfWorkName: data.type_of_work_name,
          otherWork: data.other_work,
          status: data.status,
          installationBeforeFiles: data.installation_before_files,
          installationAfterFiles: data.installation_after_files,
          installationFinishedHistories: this.groupAndSortHistories(data.status_history, 'installation_finished'),
          channel: data.channel,
        };
      }
      if (orderId) {
        this.orderId = orderId;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async acceptOrReject(status, reason) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const { info } = this.toJS();
      let response = await http.put(`${config.api.sims}/v1/homeowners/job/${info.id}/acceptance`, {
        next_status: status,
        reject_reason: reason,
      });

      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async sendAcceptanceData(jobId, { status, rating, acceptMessage, rejectMessage }) {
    if (this.isLoading) return;
    this.isLoading = true;
    const reqBody = status === 'installation_accepted'
      ? { status, rating, message: acceptMessage, anonymous: this.acceptance.anonymous }
      : { status, message: rejectMessage };
    try {
      const res = await http.put(`${config.api.sims}/v1/homeowners/acceptance/${jobId}`, reqBody);
      if (res.status !== 200 || !res.data || !res.data.data) {
        throw new Error('พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new HomeOwnerStore();
