import React, { Fragment } from 'react';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import EnTextFormGroup from '../../../../components/form/EnTextFormGroup';
import FormGroup from '../../../../components/form/FormGroup';
import EnButton from '../../../../components/form/EnButton';
import EnCKEditor from '../../../../components/form/EnCKEditor';
import EnDatePicker from '../../../../components/form/EnDatePicker';
import { config } from '../../../../config';

export class InformationWithTimerSection extends React.Component {
  onDeleteInfomationWithTimerClick(index, e) {
    e.preventDefault();
    this.props.product.deleteInformationWithTimer(index);
  }

  onDateInfomationWithTimerChange(index, key, e) {
    this.props.product.saveInformationWithTimer(index, key, e.target.value);
  }

  onInfomationWithTimerChange(index, key, e) {
    if (key !== 'seqNo') {
      this.props.product.saveInformationWithTimer(index, key, e.target.value);
      return;
    }

    if (+e.target.value > 0) {
      this.props.product.saveInformationWithTimer(index, key, Math.round(e.target.value));
    } else if (e.target.value.length === 0) {
      this.props.product.saveInformationWithTimer(index, key, '');
    }
  }

  onAddNewInfomationWithTimerClick() {
    this.props.product.addNewInformationWithTimer();
  }

  renderInformationsWithTimer(informationsWithTimer) {
    return (informationsWithTimer || []).map((info, index) => (
      <div key={`information-with-timer-${index}`}>
        <div className="row" style={{ paddingLeft: '15px' }}>
          <label>{`ข้อมูลและรายละเอียดแบบตั้งเวลาชุดที่ ${index + 1}`}</label>
        </div>
        <div className="row">
          <EnTextFormGroup containerClassName="col-sm-3 col-md-3" type="text" placeholder="seq.No" value={info.seqNo} onChange={this.onInfomationWithTimerChange.bind(this, index, 'seqNo')} />
          <div className="col-sm-3 col-md-3">
            <EnDatePicker
              id="dateInfo"
              initialDate={info.dateUpload ? moment(info.dateUpload) : undefined}
              isPlaceholder="เลือกวันที่ต้องการอัพเดทรายละเอียด"
              onDateChange={(date) => {
                if (date) {
                  const value = date.format('YYYY-MM-DD');
                  this.onDateInfomationWithTimerChange(index, 'dateUpload', { target: { value } });
                }
              }}
            />
          </div>
          <div className="col-sm-3 col-md-3">
            <EnDatePicker
              id="dateInfo"
              initialDate={info.dateDelete ? moment(info.dateDelete) : undefined}
              isPlaceholder="เลือกวันที่ต้องการลบรายละเอียด"
              onDateChange={(date) => {
                if (date) {
                  const value = date.format('YYYY-MM-DD');
                  this.onDateInfomationWithTimerChange(index, 'dateDelete', { target: { value } });
                }
              }}
            />
          </div>
        </div>
        <div className="row">
          <EnTextFormGroup containerClassName="col-sm-10 col-md-11" type="text" placeholder="หัวข้อ" value={info.title} onChange={this.onInfomationWithTimerChange.bind(this, index, 'title')} />
          <div className="col-sm-2 col-md-1 information-button-desktop">
            <EnButton className="btn-danger" style={{ marginTop: '0' }} onClick={this.onDeleteInfomationWithTimerClick.bind(this, index)}>
              <i className="fa fa-trash" aria-hidden="true" />
            </EnButton>
          </div>
        </div>
        <div className="row">
          <FormGroup containerClassName="col-md-12">
            <EnCKEditor
              type="product"
              value={info.content}
              onChange={(event, editor) => {
                this.props.product.saveInformationWithTimer(index, 'content', editor.getData());
              }}
            />
          </FormGroup>
        </div>
      </div>
    ));
  }

  render() {
    const { informationsWithTimer } = this.props;
    return (
      <Fragment>
        {this.renderInformationsWithTimer(informationsWithTimer)}
        <div className="row">
          <EnButton className="btn-success" style={{ marginLeft: '15px' }} onClick={this.onAddNewInfomationWithTimerClick.bind(this)}>
            <i className="fa fa-plus btn-icon" aria-hidden="true" />
            เพิ่มข้อมูลและรายละเอียดแบบตั้งเวลา
          </EnButton>
        </div>
      </Fragment>
    );
  }
}

export default inject('product')(observer(InformationWithTimerSection));
