import React, { Component } from 'react';
import Button from 'react-validation/build/button';

export class EnButton extends Component {
  render() {
    let propInput = Object.assign({}, this.props);
    propInput.className = `btn ${propInput.className || ''}`;
    propInput.type = 'button';
    delete propInput.validations;

    if (this.props.validations) {
      return (
        <Button {...propInput}>
          {this.props.children}
        </Button>
      );
    } else {
      return (
        <button {...propInput}>
          {this.props.children}
        </button>
      );
    }
  }
}

export default EnButton;
