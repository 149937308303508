import store from 'store';
import axios from 'axios';
import FormData from 'form-data';
import {get} from 'lodash';

export const UploadAdapter = (loader, uploadUrl) => {
  const getUrl = (response) => {
    if (!response) return null;
    let url = get(response, 'data.file.url', null);

    if (!url) url = get(response, 'data.file.path', null);
    if (!url) url = get(response, 'data.file.url', null);

    if (!url) url = get(response, 'data.path', null);
    if (!url) url = get(response, 'data.url', null);
    if (!url) url = null;
    return url;
  }

  return {
    // Starts the upload process.
    upload: async () => {
      try {
        // Wait for the file promise to resolve
        const file = await loader.file;
        // console.log('Uploading file', file);

        const data = new FormData();
        data.append('file', file);

        // console.log('Uploading to', { uploadUrl, data });

        const response = await axios.post(uploadUrl, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${store.get('token')}`,
          },
        });

        // console.log('Upload response', { default: getUrl(response) });

        return {
          default: getUrl(response),
        }
      } catch (error) {
        // console.error('Upload failed', error);
        return {
          default: null,
        };
      }
    },

    // Aborts the upload process.
    abort: () => {
      // console.log('Upload aborted');
    },
  };
};
