import config from "../config";
import http from "../utils/http";
import BaseStore from "./BaseStore";

export class ShirtStore extends BaseStore {
    constructor() {
        super();
        this.observable({
            info: undefined,
        });
    }

    async getAllShirtSize() {
        try {
            const res = await http.get(`${config.api.sims}/v1/config/shirt-size`);
            if (res && res.data && res.data.data) {
                const data = res.data.data;
                this.info = data;
                return data;
            }
        } catch (error) {
            throw error;
        }
    }
}

export default new ShirtStore();