import React from 'react';
import { observer, inject } from 'mobx-react';
import {
  getDisplayStatusButton,
  showBasicInfo,
  toListItemData,
  initListItemHeaderOption,
  onUploadItemClick,
  saveInfo,
  getListItem,
  listItemHeaderOpts,
  getDropZone,
  doUploadFiles,
  onUploadComplete,
  uploadAndNextStep,
  onError,
  getListOnsitePicture,
  getDropZoneOnsitePicture,
  onUploadOnsiteComplete,
  onDeleteOnsite,
  nextState,
} from './stepCommon';
import EnTextAreaFormGroup from '../../../components/form/EnTextAreaFormGroup';
import EnButton from '../../../components/form/EnButton';
// import FormGroup from '../../../components/form/FormGroup';
import Modal from 'react-responsive-modal';
import swal from 'sweetalert2';
import { get } from 'lodash';

export class EnInstalledConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNextStep: false,
      nextStep: 'installation_started',
      remark: 'installationBeforeRemark',
      files: 'installationBeforeFiles',
      remarkTitle: 'หมายเหตุก่อนติดตั้ง',
      openModal: false,
      addedOnsiteFiles: [],
      openModalOnsite: false,
    };
  }

  componentDidMount() {
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const jobId = jobInfo.id;
    this.getMapFilesCustomer(jobId);
  }

  onSubmit() {
    try {
      if (
        this.dropzone &&
        this.dropzone.getFiles() &&
        this.dropzone.getFiles().length
      ) {
        doUploadFiles(this.dropzone);
      } else {
        onUploadComplete(
          [],
          this.state,
          this.props.contractorMode,
          this.props.onSubmit
        );
      }
    } catch (error) {
      onError(error.message);
    }
  }

  onOpenShowOnsite() {
    try {
      this.setState({ openModalOnsite: true });
    } catch (error) {
      // onError(error.message);
    }
  }

  async onUploadAndNext(nestStatus) {
    try {
      this.setState({
        isNextStep: true,
      });
      uploadAndNextStep(this.dropzone, nestStatus, this.props.contractorMode);
    } catch (error) {
      onError(error.message);
    }
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  getComponentMessage(icon, iconFeeling, color, message, messagetxh) {
    return (
      <div>
        <i className={icon} style={{ fontSize: '60px', color: color }} />
        <h4>
          {message} <i className={iconFeeling} />
        </h4>
        <h6>{messagetxh}</h6>
      </div>
    );
  }

  onOpenAll() {
    try {
      this.setState({ openModal: true });
    } catch (error) {
      onError(error.message);
    }
  }

  onCloseModal = () => {
    this.setState({ openModal: false, openModalOnsite: false });
  }

  onAddedOnsiteFile() {
    if (this.dropzone) {
      this.setState({
        addedOnsiteFiles: this.dropzone.getFiles(),
      });
    }
  }

  mapOptions(contractorTeams, contractorTeamFromJob) {
    if (!contractorTeams || !contractorTeams.length) {
      return `
        <div className='col-md-12'>
          <div className='col-md-12'>
            ยังไม่มีช่างที่เข้างาน
          </div>
        </div>
      `
    }

    return contractorTeams.map((item, index) => {
      const isChecked =
        get(contractorTeamFromJob, 'mainContractor._id', '') === item._id.toString() ||
        get(contractorTeamFromJob, 'subContractor', []).find(sub => sub._id === item._id.toString())

      return `
      <div key=${index} class="align-items-start" style="padding: 6px 12px;">
        <div class="d-flex" style="justify-content: space-between;">
          <div class="col-8 d-flex">
            <div class="col-4">
              ${
                item.profile_pic ? (
                  `<img 
                    src=${item.profile_pic}
                    alt='profile_pic'
                    class="img-fluid w-auto"
                    style="border-radius: 50%; object-fit: cover; max-width: 65px; max-height: 65px; min-width: 65px; min-height: 65px;"
                    onError="this.onerror=null"
                  />`
                ) :
                ('<i class="fa fa-user-circle" style="font-size: 65px; color: #ccc;"></i>')
              }
            </div>
            <div class="col-8" style="padding-left: 10px;">
              <p class="d-block m-0 text-left">${item.first_name} ${item.last_name}</p>
              <p class="d-block m-0 text-left">${item.contact_phone_no}</p>
            </div>
          </div>
          <div class="col-4" style="display: grid; justify-items: center; min-width: 60px">
            <input
              ${item.status === 'registered' ? 'disabled' : ''}
              class="checkbox"
              style="width: 25px; height: 25px"
              type='checkbox'
              class="mt-2"
              data-id="${item._id.toString()}"
              ${isChecked ? 'checked' : ''}
            />
            ${item.status === 'registered' ? ('<span>(รออนุมัติ)</span>') : ''}
          </div>
        </div>
      </div>
    `
    }).join('')
  }

  async getMapFilesCustomer (jobId) {
    const jobStore = this.props.job.toJS();
    await jobStore.getOrderByJobId(jobId);
  }

  async addContractor(contractorTeam, contractorId, nextState=null) {
    if (!contractorId) {
      this.noti.error('กรุณาเลือกช่างหลักก่อนเพิ่มช่างเข้าหน้างาน')
      return;
    }

    const newOption = await this.props.subContractor.findTeam(contractorId);

    // Map over the options and generate HTML for each item
    const contractorItems = this.mapOptions(newOption, contractorTeam)

    return (
      swal.fire({
        title: 'เลือกช่างเข้าหน้างาน',
        // showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'ยืนยัน',
        html: `
        <div className='col-md-12'>
          <div className='col-md-12'>
            <input
              type='text'
              id='search'
              className='form-control'
              placeholder='ค้นหาช่าง'
              style="margin-bottom: 10px; width: 100%; height: 40px; color: #495057; border: 1px solid #ced4da; border-radius: .25rem; padding: 6px 12px;"
            />
          </div>
          <div className='col-md-12' style="margin-bottom: -20px;">
            <div id="contractorList" style="width: 100%; max-height: 450px; overflow-y: scroll;">
              ${contractorItems}
            </div>
          </div>
        </div>
      `,
        onOpen: () => {
          const searchInput = document.getElementById('search');
          const contractorList = document.getElementById('contractorList');
        
          searchInput.addEventListener('input', () => {
            const { value } = searchInput;
        
            // filter the options
            const filteredOptions = newOption.filter(item => {
              if (!value) return item;
              return item.first_name.toLowerCase().includes(value.toLowerCase()) ||
                item.last_name.toLowerCase().includes(value.toLowerCase()) ||
                item.contact_phone_no.toLowerCase().includes(value.toLowerCase());
            });
        
            // Update the HTML
            contractorList.innerHTML = this.mapOptions(filteredOptions, contractorTeam);
          })
        },
        preConfirm: async () => {
          // get contractors from the checkbox
          const checkboxes = document.querySelectorAll('.checkbox');
          const selectedContractors = [];
          checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
              const id = checkbox.getAttribute('data-id');
              const contractor = newOption.find(item => item._id.toString() === id);
              if (contractor) {
                selectedContractors.push(contractor);
              }
            }
          });

          // map to contractor_team
          const payload = {
            mainContractor: '',
            subContractor: [],
          }

          selectedContractors.map((item) => {
            if (item._id.toString() === contractorId.toString()) {
              payload.mainContractor = item
            } else {
              payload.subContractor.push(item);
            }
          });
          
          // update the state
          this.props.job.setInfo('contractorTeam', payload);

          // save and next state for contractorMode
          if(this.props.contractorMode && nextState) {
            await this.props.job.updateJob(this.props.jobId);
            await this.props.job.saveAndNextState(nextState, {
              isContractor: this.props.contractorMode,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      })
    )
  }

  render() {
    const { contractorMode, statusClaim } = this.props;
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const orderId = jobStore.orderId;
    // const userInfo = this.props.auth.getUserInfo();
    // const channel = userInfo.channel ? userInfo.channel.channelName : '';
    const channel = this.props.auth.getChannelName();
    let isSCGHome = channel === 'SCGHOME Online';
    // let itemHeader = initListItemHeaderOption('รูปถ่ายหรือไฟล์แนบก่อนติดตั้ง');
    // if (contractorMode) {
    let itemHeader = initListItemHeaderOption(
      'รูปถ่ายหรือไฟล์แนบก่อนติดตั้ง ( อัพโหลดได้สูงสุด 20 ไฟล์ต่อครั้ง )'
    );
    // }
    let onsitePictureHeader = initListItemHeaderOption(
      'รูปภาพและวีดีโอหน้างานจากลูกค้า ( อัพโหลดได้สูงสุดไม่เกิน 10 ไฟล์)'
    );
    const imagesOnsite = jobStore.onsitePicture ? jobStore.onsitePicture[0].file : [];
    const videosOnsite = jobStore.onsitePicture ? jobStore.onsitePicture[1].file : [];
    const allFiles = [...imagesOnsite, ...videosOnsite];
    let itemFileNameCustomer = toListItemData(allFiles);
    let listOnsitePicture = getListOnsitePicture(
      onsitePictureHeader,
      itemFileNameCustomer,
      onDeleteOnsite.bind(this, orderId)
    );
    onsitePictureHeader.push(listItemHeaderOpts);
    let itemData = toListItemData(jobInfo[this.state.files]);
    let listItem = getListItem(
      itemHeader,
      itemData,
      onUploadItemClick.bind(this, this.state.files, jobInfo)
    );
    if (contractorMode) {
      itemHeader.push(listItemHeaderOpts);
    }

    let dropzoneOnsite = getDropZoneOnsitePicture(
      e => { this.dropzone = e; },
      (e) => {
        onUploadOnsiteComplete(e, orderId);
      },
      this.onAddedOnsiteFile.bind(this),
      "",
      statusClaim
    );

    let dropzone = getDropZone(
      (e) => {
        this.dropzone = e;
      },
      this.props.jobId,
      (e) => {
        onUploadComplete(
          e,
          this.state,
          this.props.contractorMode,
          this.props.onSubmit
        );
      },
      undefined,
      "",
      statusClaim
    );

    const { openModal, openModalOnsite } = this.state;
    const imgs = itemData.map((item, idx) => {
      return (
        <div className='row col-md-12'>
          <div className='row' style={{ paddingLeft: '16px' }}>
            <h4>{idx + 1}</h4>
          </div>
          <div className='row col-md-12' style={{ textAlign: 'center' }}>
            <img
              src={item.url}
              alt=''
              style={{ textAlign: 'center' }}
              width={400}
              height={300}
              mode='fill'
            />
          </div>
        </div>
      );
    });
    const showImgsOnsite = imagesOnsite.map((item, idx) => {
      return (
        <div className="row col-md-12">
          <div className="row" style={{ paddingLeft: '16px' }}>
            <h4>{(idx + 1)}</h4>
          </div>
          <div className="row col-md-12" style={{ textAlign: 'center' }}>
            <img src={item.url} alt="" style={{ textAlign: 'center' }} width={400} height={300} mode="fill" />
          </div>
        </div>
      );
    });
    const contractorAccept = jobInfo.contractorAccept || !contractorMode;
    return (
      <div>
        <div className='col-md-12'>
          <Modal
            style={{ textAlign: 'center' }}
            open={openModal}
            onClose={this.onCloseModal}
            center
          >
            {imgs}
          </Modal>
        </div>
        <div className="col-md-12">
          <Modal style={{ textAlign: 'center' }} open={openModalOnsite} onClose={this.onCloseModal} center>
            {showImgsOnsite}
          </Modal>
        </div>
        {showBasicInfo(jobInfo, contractorMode)}
        <div className="row">
          <div className='col-md-12'>
            {listOnsitePicture}
            {
              // contractorMode && (
              dropzoneOnsite
              // )
            }
          </div>
        </div>
        <div className="row">
          <div className='col-md-12'>
            <EnButton className="btn-info pull-left" onClick={this.onOpenShowOnsite.bind(this)}>
              <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
            </EnButton>
          </div>
        </div>
        <hr />
        <div className='row'>
          <div className='col-md-12'>
            {listItem}
            {
              // contractorMode && (
              dropzone
              // )
            }
            <EnButton
              className='btn-info pull-left col-md-3'
              onClick={this.onOpenAll.bind(this)}
            >
              <i className='fa fa-image btn-icon' aria-hidden='true' />
              แสดงรูปทั้งหมด
            </EnButton>
          </div>
        </div>
        {
          // contractorMode && (
          <div>
            <div className='row'>
              <div className='col-md-12'>
                <EnTextAreaFormGroup
                  label='หมายเหตุก่อนติดตั้ง'
                  rows='5'
                  value={jobInfo[this.state.remark]}
                  onChange={saveInfo.bind(this, this.state.remark)}
                  disabled={statusClaim}
                />
              </div>
            </div>
          </div>

          // )
        }
        {
          // Delete unused from InstallConfirm <stepper 2> Reference with QCNS-2178
        }
        {/* {
          !contractorMode && (
            <div className="row">
              <div className="col-md-12">
                <FormGroup label="หมายเหตุก่อนติดตั้ง">
                  {jobInfo[this.state.remark] ? <div>{jobInfo[this.state.remark]}</div> : getDisplayEmpty('-')}
                </FormGroup>
              </div>
            </div>
          )
        } */}
        {contractorMode &&
          !jobInfo.contractorAccept &&
          (jobInfo.status === 'assigned' ||
            jobInfo.status === 'installation_requested' ||
            jobInfo.status === 'installation_confirmed') &&
          this.props.componentMessage}
        <div className='row'>
          <div className='col-md-12'>
            {!isSCGHome &&
              (jobInfo.status === 'installation_confirmed' ||
                jobInfo.status === 'assigned') &&
                jobInfo.jobType === 'I' || 'S' && (jobInfo.status === 'assigned' || jobInfo.status === 'installation_confirmed') ? 
              <EnButton className="btn-warning pull-right" onClick={() => { 
                  if(contractorMode && (!jobInfo.contractorTeam || (!jobInfo.contractorTeam.mainContractor && !jobInfo.contractorTeam.subContractor.length))) {
                    this.addContractor(jobInfo.contractorTeam, jobInfo.contractorId, jobInfo.jobType === 'S' ? 'survey_started' : 'installation_started');
                  } else {
                    jobInfo.jobType === 'S' ? nextState('survey_started') : nextState('installation_started');
                  }
                }}>
                <i className="fa fa-play btn-icon" aria-hidden="true" />{jobInfo.jobType === 'S' ? 'เริ่มสำรวจ' : 'เริ่มการติดตั้ง'}
              </EnButton> : getDisplayStatusButton(
                jobInfo.jobType,
                jobInfo.status,
                contractorMode,
                async () => {
                  await this.onUploadAndNext(this.state.nextStep);
                },
                '',
                true,
                undefined,
                contractorAccept
              )  
             }
            {contractorMode && (
              <EnButton
                id='submit-button'
                className='btn-info pull-right'
                onClick={this.onSubmit.bind(this)}
                disabled={statusClaim}
              >
                <i className='fa fa-floppy-o btn-icon' aria-hidden='true' />
                บันทึก
              </EnButton>
            )}
            <EnButton
              id='back-button'
              className='btn-danger pull-right'
              onClick={this.onCancel.bind(this)}
            >
              <i className='fa fa-reply btn-icon' aria-hidden='true' />
              กลับ
            </EnButton>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('auth', 'job', 'subContractor')(observer(EnInstalledConfirm));
