import humps from 'humps';
import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';

export class Material extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      listFileUploade:[],
      info: this.initInfo(),
      isErrorUnitPrice: false,
      errorMessage:''
    });
  }

  initInfo() {
    return {
      code: '',
      name: '',
      cost:0,
      typeOfWorks: [],
      unitPrice: 0,
      unit: '',
      mapTypeOfWorks: [],
      status:'active'
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  saveData(key,value) {
    this[key] = value
  }
  mapIdAndLabel() {
    const items = this.toJS().items;
    return (items || []).map(i => {
      return { id: i.code, label: i.name ? i.name.th : '' };
    });
  }

  async getMaterialList() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/material`);
      if (response.status === 200 && response.data) {
        this.items = humps.camelizeKeys(response.data.data);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getMaterialForEdit(id) {
    try {
      const data = await this.getMaterialById(id);
      this.info = data;
    } catch (error) {
      throw error;
    }
  }

  async getMaterialById(id) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.get(`${config.api.sims}/v1/material/${id}`,);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      return humps.camelizeKeys(response.data.data);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async toggleStatus(index, status) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.put(`${config.api.sims}/v1/material/${this.items[index].id}/status`, { status });
      if (response.status === 200 && response.data) {
        this.items[index] = humps.camelizeKeys(response.data.data);;
      } else {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
  
  async getSkuByType(type) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/skus/type/${type}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      this.items = humps.camelizeKeys(response.data.data);
      return this.items;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getMaterialForEdit(id) {
    try {
      const data = await this.getMaterialById(id);
      this.info = data;
    } catch (error) {
      throw error;
    }
  }

  async createMaterial() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      delete this.info.mapTypeOfWorks;
      const info = this.toJS().info;
      const response = await http.post(`${config.api.sims}/v1/material`, info);
      if (response.status !== 200 && response.data) {
        const { message } = response.data;
        throw new Error(message || 'เกิดข้อผิดพลาด');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateMaterial(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      let info = this.toJS().info;
      info.id = id;
      const response = await http.put(`${config.api.sims}/v1/material/updateMaterial`, info);
      if (response.status !== 200 && response.data) {
        const { message } = response.data;
        throw new Error(message || 'เกิดข้อผิดพลาด');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getSkuByMultiType(id) {
    try {
      this.isLoading = true;
      if (Array.isArray(id) && id) {
        const typeOfWorkId = id.toString();
        const response = await http.get(`${config.api.sims}/v1/skus/list/${typeOfWorkId}`);
        if (response.status !== 200 && response.data) {
          throw new Error('ไม่สามารถโหลดข้อมูลได้');
        }
        return response.data.data;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
  async getUploadeFiles(downloadType, channel, storeId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let url = `${config.api.sims}/v1/downloads/file?type=${downloadType || ''}&channel=${channel || ''}&storeId=${storeId || ''}`;
      const response = await http.get(url);
      if (response.status === 200 && response.data && response.data.data) {
        this.listFileUploade = response.data.data;
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }
  updateSkuFile = async (data) => {
    this.isLoading = true
    try {
      const response = await http.post(`${config.api.sims}/v1/skus/updateDataSkuFile`,data);
      if (response && response.data && response.data.data) {
        return response.data.data;
      }
      // this.isLoading = false
    } catch (error) {
      // this.isLoading = false
      throw new Error(error);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new Material();
