import React, { Component } from "react";
import * as _ from "lodash";
import { observer, inject } from "mobx-react";
import moment from "moment";
import Select from "react-select";
import swal from "sweetalert2";

import EnTagSelect from "../../../../components/form/EnTagSelect";
import EnDatePicker from "../../../../components/form/EnDatePicker";
import EnButton from "../../../../components/form/EnButton";
import Loader from "../../../../components/common/Loader";

export class PromotionSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChannelLoading: false,
      isTypeOfWorkLoading: false,
      isContractorLoading: false,
      channelOptions: [],
      typeOfWorkOptions: [],
      contractorOptions: [],
    };
    this.typeOfStatusOptions = [
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];
  }

  componentDidMount() {
    this.initOptions();
  }

  async initOptions() {
    this.getChannelOptions();
  }

  async getChannelOptions() {
    let channelOptions = [];
    try {
      this.setState({
        isChannelLoading: true,
      });
      await this.props.channel.getAllChannel();

      let channel = this.props.channel.toJS().info || [];
      channelOptions = channel.map((item) => {
        return {
          label: item.channel_name,
          value: item.channel_name,
        };
      });
    } catch (err) {
      console.log(err);
      channelOptions = [];
    }

    this.setState({
      channelOptions,
      isChannelLoading: false,
    });
  }

  onQueryChange(key, value) {
    this.props.download.setQuery(key, value);
  }

  onDateChange(key, date) {
    let errorMessage = "";
    // validate input date
    if (key === "start_date") {
      const { query } = this.props.download.toJS();
      if (!this.checkIsValidDateRange(date, query.end_date)) {
        errorMessage = "กรุณาตรวจสอบวันที่";
      }
    } else if (key === "end_date") {
      const { query } = this.props.download.toJS();
      if (!this.checkIsValidDateRange(query.start_date, date)) {
        errorMessage = "กรุณาตรวจสอบวันที่";
      }
    }

    // display error message
    if (!errorMessage) {
      this.props.download.setQuery(key, date);
    } else {
      swal.fire({
        icon: "error",
        title: errorMessage,
        confirmButtonText: "ตกลง",
      });
    }
  }

  onSubmit(query) {
    query.channel = this.props.channelName;
    query.store_id = this.props.storeId;
    this.props.onSubmit(query);
  }

  checkIsValidDateRange(fromDate, toDate) {
    if (fromDate && toDate) {
      return moment(fromDate).valueOf() <= moment(toDate).valueOf();
    }
    return true;
  }

  isAllowDownload(fields) {
    const filterOption = Object.keys(fields).filter((item) =>
      ["start_date", "end_date", "status", "channel_list"].includes(item)
    );
    const allow = filterOption.every((item) => fields[item]);
    return allow;
  }

  render() {
    const { channelName } = this.props;
    const { isChannelLoading, isTypeOfWorkLoading } =
      this.state;
    const { channelOptions } = this.state;
    const { query } = this.props.download.toJS();
    const isLoading = isChannelLoading || isTypeOfWorkLoading;

    const isDisabledDownload = !this.isAllowDownload(query);

    return (
      <div style={{ marginTop: "15px" }}>
        <Loader show={isLoading} />
        <div className="row">
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">ช่องทางการขาย</label>
            <div className="col-md-9">
              {channelName ? (
                <div>{channelName || ""}</div>
              ) : (
                <EnTagSelect
                  multi={true}
                  disabled={isChannelLoading}
                  closeOnSelect
                  options={channelOptions}
                  onTagChange={(selectedItem) => {
                    this.onQueryChange("channel_list", selectedItem);
                  }}
                  value={query.channel_list}
                  searchable
                  className="coupon-search-select"
                  placeholder={isChannelLoading ? "กำลังโหลด..." : "เลือก..."}
                />
              )}
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">สถานะ</label>
            <div className="col-md-9">
              <Select
                multi={false}
                value={this.typeOfStatusOptions.find(
                  (item) => item.value === query.status
                )}
                options={this.typeOfStatusOptions}
                onChange={(selectedItem) => {
                  this.onQueryChange(
                    "status",
                    (selectedItem || {}).value || null
                  );
                }}
                placeholder="เลือก..."
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">
              เดือน <br />
              (จาก - ถึง)
            </label>
            <div className="col-md-9">
              <EnDatePicker.MonthPicker
                id="startDate"
                initialDate={{
                  start_date: query.start_date,
                  end_date: query.end_date,
                }}
                onChangeStartDate={this.onDateChange.bind(this, "start_date")}
                onChangeEndDate={this.onDateChange.bind(this, "end_date")}
                maxMonthSelected={3}
              />
            </div>
          </div>
          <div className="form-group col-md-6" style={{ textAlign: "right" }}>
            <button
              className="btn btn-success"
              title="ดาวน์โหลด"
              data-tip="ดาวน์โหลด"
              onClick={() => {
                this.onSubmit(query);
                this.props.onClearSearch();
              }}
              disabled={isDisabledDownload}
            >
              <span className="fa fa-plus btn-icon" aria-hidden="true" />
              ดาวน์โหลด
            </button>
            <EnButton
              className="btn-warning"
              onClick={this.props.onClearSearch.bind(this)}
            >
              <i className="fa fa-refresh btn-icon" aria-hidden="true" />
              เคลียร์
            </EnButton>
          </div>
        </div>
      </div>
    );
  }
}

export default inject(
  "auth",
  "channel",
  "typeofwork",
  "contractor",
  "download"
)(observer(PromotionSearchForm));
