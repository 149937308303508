// import React from 'react';

// export default ({ children }) => (
//   <div>{children}</div>
// );

import React from 'react';

export class Tab extends React.Component {
  render() {
    // const children = [...this.props.children];
    return (
      this.props.children
    );
  }
}

export default Tab;
