import React, { Component } from 'react';
import { ToastContainer, ToastMessage } from 'react-toastr';
import renderHTML from 'react-render-html';

const ToastMessageFactory = React.createFactory(ToastMessage.animation);

export class Notification extends Component {
  option = {
    timeOut: 2000,
    extendedTimeOut: 3000,
  };

  clear() {
    if (!this.container) return;
    this.container.clear();
  }

  info(message, title) {
    if (!this.container) return;
    this.container.info(renderHTML(message), title || 'Infomation', this.option);
  }

  success(message, title) {
    if (!this.container) return;
    this.container.success(renderHTML(message), title || 'Success', this.option);
  }

  warning(message, title) {
    if (!this.container) return;
    this.container.warning(renderHTML(message), title || 'Warning!', this.option);
  }

  error(message, title) {
    if (!this.container) return;
    this.container.error(renderHTML(message), title || 'Error!!', this.option);
  }

  render() {
    return (
      <ToastContainer ref={(input) => { this.container = input; }}
        toastMessageFactory={ToastMessageFactory}
        className="toast-top-right p4-toast" />
    );
  }
}

export default Notification;
