import humps from 'humps';
import BaseStore from '../BaseStore';
import config from '../../config';
import { http } from '../../utils/http';

export class Member extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      list: [],
      info: this.initInfo(),
    });
  }

  initInfo() {
    return {
      id: '',
      name: '',
      mobile: '',
      address: {
        subDistrict: {
          code: '',
          name: '',
        },
        district: {
          code: '',
          name: '',
        },
        province: {
          code: '',
          name: '',
        },
        postcode: '',
      },
      location: [100.53791268803536, 13.805439700182204],
      lineUserId: '',
      lineDisplayName: '',
      linePictureUrl: '',
      vatInfo: {
        isRequire: false,
        companyName: '',
        taxId: '',
        branchNo: 0,
        address: {
          no: '',
          subDistrict: {
            code: '',
            name: '',
          },
          district: {
            code: '',
            name: '',
          },
          province: {
            code: '',
            name: '',
          },
          postcode: '',
        },
      },
      status: '',
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    const info = this.toJS().info;
    info[key] = value;
    this.info = info;
  }

  async getMemberList() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/members`);
      if (response.status === 200 && response.data) {
        this.list = response.data.data;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getMemberById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/members/${id}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getMemberByLineUserId(lineUserId) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/members/line/${lineUserId}`);
      if (response.status === 200 && response.data && response.data.data) {
        this.info = humps.camelizeKeys(response.data.data);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getMemberForEdit(id) {
    try {
      const data = await this.getMemberById(id);
      this.info = data;
    } catch (error) {
      throw error;
    }
  }

  async updateMember(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      const response = await http.put(`${config.api.bestdeal}/v1/members/${id}`, info);
      if (response.status !== 200 && response.data) {
        throw new Error(response.data.message)
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new Member();
