import { isNil } from 'lodash';
import React, { Component } from 'react';
import FormGroup from './FormGroup';
import EnTextAreaEditor from './EnTextAreaEditor';

export class EnTextAreaEditorFormGroup extends Component {
  onChange = (e) => {
    let val = e.target.value;
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          value: val,
        },
      });
    }
  };

  render() {
    let { label, value, validations, rows, cols, containerClassName, maxLength, showMaxLength, argAddable } = this.props;
    let options = {
      label,
      validations: !isNil(validations) && Array.isArray(validations),
    };

    return (
      <FormGroup containerClassName={containerClassName} {...options}>
        <EnTextAreaEditor
          rows={rows}
          cols={cols}
          disabled={this.props.disabled}
          ref={(e) => {
            this.input = e;
          }}
          value={value || ''}
          onChange={this.onChange}
          validations={validations}
          maxLength={maxLength}
          showMaxLength={showMaxLength}
          argAddable={argAddable}
        />
      </FormGroup>
    );
  }
}

export default EnTextAreaEditorFormGroup;
