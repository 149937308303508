import React, { Fragment } from 'react';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Select from 'react-select';
import { FormValidation, required } from '../../../../components/form/FormValidation';
import EnTextFormGroup from '../../../../components/form/EnTextFormGroup';
import FormGroup from '../../../../components/form/FormGroup';
import EnToggle from '../../../../components/form/EnToggle';
import EnDropDown from '../../../../components/form/EnDropDown';
import { EnTagSelectValidation } from '../../../../components/form/EnTagSelect';
import { isAllow } from '../../../../utils/permission';

export class BasicInfoSection extends React.Component {
  onNameChanged(e) {
    this.props.product.saveInfo('name', { th: e.target.value });
  }

  onSkuCodeChanged(e) {
    this.props.product.saveInfo('skuCode', e.target.value);
  }

  onWorkTypeChanged(e) {
    const value = +e.target.value;
    this.props.product.saveInfo('typeOfWork', value);
    this.props.sku.getSkuByType(value);
  }

  onSelectChannel = (value) => {
    this.props.product.saveInfo('channel', value);
  };

  onProductChange(e) {
    if (e.target) {
      this.props.product.saveProductType(e.target.value);
    }
  }

  onIsWageOnlyChange(value) {
    this.props.product.saveInfo('isWageOnly', value || false);
  }

  onisSurveyDiscountChange(value) {
    this.props.product.saveInfo('isSurveyDiscount', value || false);
  }

  onPriceChanged(e) {
    this.props.product.saveInfo('price', e.target.value);
  }

  onUnitPriceChanged(e) {
    this.props.product.saveUnitPrice(e.target.value);
  }

  onNormalPriceChanged(e) {
    this.props.product.saveInfo('normalPrice', e.target.value);
  }

  onUnitChanged(e) {
    this.props.product.saveUnit(e.target.value);
  }

  onShortNameChanged(e) {
    this.props.product.saveInfo('shortName', { th: e.target.value });
  }

  onGuaranteeChange(option) {
    this.props.product.saveInfo('guaranteeCode', _.get(option, 'value', null));
  }

  onLimitQty(value) {
    if (value) {
      this.props.product.saveInfo('limitQty', value);
    } else {
      this.props.product.saveMinQty(1);
      this.props.product.saveInfo('limitQty', value);
    }
  }

  onMinQtyChange(e) {
    if (e.target) {
      this.props.product.saveMinQty(e.target.value);
    }
  }

  onLockDaysChanged(e) {
    this.props.product.saveInfo('lockDays', e.target.value);
  }

  onRequireWHChanged(e) {
    this.props.product.saveInfo('requireWh', e);
  }

  onRequireOnsitePictureChange(e) {
    this.props.product.saveInfo('requireOnsitePicture', e);
  }

  getChannelOptions = () => {
    const channel = this.props.channel.toJS().info;
    return (channel || []).map((c, index) => {
      return {
        label: c.channel_name,
        value: c.channel_name,
      };
    });
  };

  getGuaranteeOptions() {
    const { guaranteeData } = this.props.guarantee.toJS() || [];

    return guaranteeData.map((item) => ({
      label: item.name,
      value: item.code
    }))
  }

  displayTypeOfWorkSelector() {
    const typeOfWorks = this.props.typeofwork.mapIdAndLabel();
    let res = _.orderBy(typeOfWorks, ['label']).map((item) => {
      return {
        label: item.label,
        value: item.id,
      };
    });
    return res;
  }

  render() {
    const { info } = this.props;
    const tow = this.displayTypeOfWorkSelector();

    return (
      <Fragment>
        <div className="row">
          <EnTextFormGroup containerClassName="col-md-6" type="text" label="ชื่อสินค้า" value={info.name.th} onChange={this.onNameChanged.bind(this)} validations={[required]} />
          <FormGroup containerClassName={`col-md-6`} label="ประเภทงาน" validations={[required]}>
            {/* <EnDropDown
                          id="type-work"
                          value={info.typeOfWork}
                          onChange={this.onWorkTypeChanged.bind(this)}
                        >
                          {this.displayTypeOfWorkSelector()}
                        </EnDropDown> */}
            <Select
              id="type-work"
              multi={false}
              value={tow.find((item) => item.value === info.typeOfWork)}
              options={tow}
              className="select-custom1"
              onChange={(selected) => {
                const value = selected ? selected.value : '';
                this.onWorkTypeChanged({ target: { value } });
              }}
              placeholder="เลือกประเภทงาน..."
            />
          </FormGroup>
        </div>
        <div className="row">
          <EnTextFormGroup containerClassName="col-md-6" type="text" label="รหัสสินค้า" value={info.skuCode} onChange={this.onSkuCodeChanged.bind(this)} validations={[required]} />
        </div>
        <div className="row">
          <FormValidation>
            <div className="col-md-6">
              <span className="form-label">ช่องทางการขาย </span>
              <EnTagSelectValidation
                placeholder="กรุณาเลือกช่องทางการขาย"
                closeOnSelect={false}
                options={this.getChannelOptions()}
                onTagChange={this.onSelectChannel.bind(this)}
                value={info.channel}
                searchable={false}
              />
            </div>
          </FormValidation>
        </div>
        <div className="row">
          <FormGroup containerClassName={`col-md-6`} label="ประเภทสินค้า" validations={[required]}>
            <EnDropDown
              id="type-product"
              value={info.productType}
              onChange={(e) => {
                this.onProductChange(e);
                const type = _.get(e, 'target.value');
                if (type === 'I') {
                  this.onIsWageOnlyChange(false);
                }
              }}>
              <option value={'S'}>สำรวจ</option>
              <option value={'I'}>ติดตั้ง</option>
            </EnDropDown>
          </FormGroup>
          <EnTextFormGroup containerClassName="col-md-6" type="number" label="ราคาค่าบริการสำรวจ" value={info.price} onChange={this.onPriceChanged.bind(this)} validations={[required]} />
        </div>
        {info.productType === 'S' && (
          <div className="row">
            <FormGroup containerClassName="col-md-2" label="เฉพาะค่าแรง">
              <EnToggle checked={info.isWageOnly} onChange={this.onIsWageOnlyChange.bind(this)} />
            </FormGroup>
            <FormGroup containerClassName="col-md-2" label="หักค่าสำรวจ">
              <EnToggle disabled={!this.props.auth.isSuperAdmin()} checked={info.isSurveyDiscount} onChange={this.onisSurveyDiscountChange.bind(this)} />
            </FormGroup>
          </div>
        )}
        <div className="row">
          <EnTextFormGroup containerClassName="col-md-3" type="number" label="ราคาต่อหน่วย" value={info.calculator.unitPrice} onChange={this.onUnitPriceChanged.bind(this)} validations={[required]} />
          <EnTextFormGroup containerClassName="col-md-3" type="number" label="ราคาปกติ" value={info.normalPrice} onChange={this.onNormalPriceChanged.bind(this)} />
          <EnTextFormGroup containerClassName="col-md-6" type="text" label="หน่วย" value={info.calculator.unit.th} onChange={this.onUnitChanged.bind(this)} validations={[required]} />
        </div>
        <div className="row">
          <EnTextFormGroup containerClassName="col-md-6" type="text" label="ชื่อย่อ สินค้า" value={info.shortName ? info.shortName.th : ''} onChange={this.onShortNameChanged.bind(this)} validations={[required]} />
          <FormGroup containerClassName={`col-md-6`} label="ใบรับประกัน">
            <Select id="guarantee" className="select-custom1" placeholder="กรุณาเลือกใบรับประกัน" disabled={!isAllow('SELECT_WARRANTY_LIST')} options={this.getGuaranteeOptions()} value={info.guaranteeCode} onChange={this.onGuaranteeChange.bind(this)} searchable={true} clearable={false} />
          </FormGroup>
        </div>
        <div className="row">
          <div style={{ padding: '0px' }}>
            <FormGroup containerClassName="col-md-2" label="จำกัดการซื้อขั้นต่ำ">
              <EnToggle checked={info.limitQty} onChange={this.onLimitQty.bind(this)} />
            </FormGroup>
            {info.limitQty && (
              <FormGroup>
                <EnTextFormGroup label="จำนวน" containerClassName="col-md-4" type="text" value={info.calculator.minQty} onChange={this.onMinQtyChange.bind(this)} />
              </FormGroup>
            )}
            <EnTextFormGroup containerClassName="col-md-6" type="number" label="วันที่ก่อนการซื้อ (Day+)" value={info.lockDays} onChange={this.onLockDaysChanged.bind(this)} validations={[required]} />
          </div>
        </div>
        <div className="row">
          <div style={{ padding: '0px' }}>
            <FormGroup containerClassName="col-md-2" label="เก็บค่า W/H Tax">
              <EnToggle checked={info.requireWh} onChange={this.onRequireWHChanged.bind(this)} />
            </FormGroup>
            <FormGroup containerClassName="col-md-2" label="ต้องการรูปภาพหน้างาน">
              <EnToggle checked={info.requireOnsitePicture} onChange={this.onRequireOnsitePictureChange.bind(this)} />
            </FormGroup>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default inject('product', 'typeofwork', 'guarantee', 'channel', 'sku', 'auth')(observer(BasicInfoSection));
