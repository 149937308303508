import React, { Component } from 'react';

export class ScrollButton extends Component {
  constructor() {
    super();

    this.state = {
      intervalId: 0,
    };
  }

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop(e) {
    e.preventDefault();
    let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
    this.setState({ intervalId: intervalId });
  }

  render () {
    let { title } = this.props;
    if (!title) {
      title = 'กลับไปด้านบน';
    }
    return (
      <button title={title}
        className={'scroll-button'}
        onClick={this.scrollToTop.bind(this)}>
        <span className="scroll-icon fa fa-chevron-up" />
      </button>
    );
  }
}

export default ScrollButton;
