import humps from 'humps';
import BaseStore from '../BaseStore';
import config from '../../config';
import { http } from '../../utils/http';

export class Store extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      list: [],
      info: this.initInfo(),
    });
  }

  initInfo() {
    return {
      id: '',
      name: '',
      contact: '',
      mobile: '',
      address: {
        subDistrict: {
          code: '',
          name: '',
        },
        district: {
          code: '',
          name: '',
        },
        province: {
          code: '',
          name: '',
        },
        postcode: '',
      },
      location: [100.53791268803536, 13.805439700182204],
      delivery: true,
      lineUserId: '',
      lineDisplayName: '',
      linePictureUrl: '',
      botIndex: 0,
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    const info = this.toJS().info;
    info[key] = value;
    this.info = info;
  }

  async getStoreList() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/stores`);
      if (response.status === 200 && response.data) {
        this.list = response.data.data;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getStoreById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/stores/${id}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getStoreByLineUserId(lineUserId) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/stores/line/${lineUserId}`);
      if (response.status === 200 && response.data && response.data.data) {
        this.info = humps.camelizeKeys(response.data.data);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getStoreForEdit(id) {
    try {
      const data = await this.getStoreById(id);
      this.info = data;
    } catch (error) {
      throw error;
    }
  }

  async createStore() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      const response = await http.post(`${config.api.bestdeal}/v1/stores`, info);
      if (response.status !== 200 && response.data) {
        throw new Error(response.data.message)
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateStore(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      const response = await http.put(`${config.api.bestdeal}/v1/stores/${id}`, info);
      if (response.status !== 200 && response.data) {
        throw new Error(response.data.message)
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new Store();
