import _ from 'lodash';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import LocationPicker from 'react-location-picker';
import { geolocated } from 'react-geolocated';
import swal from 'sweetalert2';
import moment from 'moment';
import querystring from 'query-string';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import config from '../../../config';
import { FormValidation, required, phone } from '../../../components/form/FormValidation';
import FormGroup from '../../../components/form/FormGroup';
import EnToggle from '../../../components/form/EnToggle';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnTextAreaFormGroup from '../../../components/form/EnTextAreaFormGroup';
import EnButton from '../../../components/form/EnButton';
import EnDatePicker from '../../../components/form/EnDatePicker';
import EnDropDown from '../../../components/form/EnDropDown';
import AddressForm from '../../../components/address/AddressForm';
import liffHelper from '../../../utils/bestdeal/LiffHelper';
import GoogleMap from '../../../utils/bestdeal/GoogleMap';
import Loader from '../../../components/form/Loader';
import '../../../assets/css/loader.css';
import '../../../assets/css/bestdeal.css';
// import '../../../utils/bestdeal/VConsole';

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useCurrentLocation: true,
      currentLocation: {},
      time: 1,
      status: 'create',
    };
    let parsed = querystring.parse(this.props.location.search);
    const status = parsed.status;
    if (status !== 'view' && status !== 'admin' && status !== 'edit') {
      liffHelper.getProfile()
        .then(profile => {
          this.saveInfo('lineUserId', profile.userId);
          this.saveInfo('lineDisplayName', profile.displayName);
          this.saveInfo('linePictureUrl', profile.pictureUrl);
          this.props.bestdealMember.getMemberByLineUserId(profile.userId)
            .then(() => {
              const memberInfo = this.props.bestdealMember.toJS().info;
              this.saveInfo('name', memberInfo.name);
              this.saveInfo('mobile', memberInfo.mobile);
              this.saveInfo('address', memberInfo.address);
              this.saveInfo('googleAddress', memberInfo.googleAddress);
              this.saveInfo('location', memberInfo.location);
              this.saveInfo('vatInfo', memberInfo.vatInfo);
              this.saveInfo('delivery', memberInfo.delivery);
              this.saveInfo('remark', memberInfo.remark);
            });
        })
        .catch(err => {
          console.log('err', err);
          const profile = {
            userId: 'Uacf17a894d30ce32437e7d018d8f89aa',
            displayName: 'kookoo',
            pictureUrl: 'https://profile.line-scdn.net/0m0404029f7251c8aae0cc9220cfba9845826df7a707df',
          };
          this.saveInfo('lineUserId', profile.userId);
          this.saveInfo('lineDisplayName', profile.displayName);
          this.saveInfo('linePictureUrl', profile.pictureUrl);
          this.props.bestdealMember.getMemberByLineUserId(profile.userId)
            .then(() => {
              const memberInfo = this.props.bestdealMember.toJS().info;
              this.saveInfo('name', memberInfo.name);
              this.saveInfo('mobile', memberInfo.mobile);
              this.saveInfo('address', memberInfo.address);
              this.saveInfo('googleAddress', memberInfo.googleAddress);
              this.saveInfo('location', memberInfo.location);
              this.saveInfo('vatInfo', memberInfo.vatInfo);
              this.saveInfo('delivery', memberInfo.delivery);
              this.saveInfo('remark', memberInfo.remark);
            });
        });
    }
  }

  getGreetingTime(m) {
    let g = null;
    if (!m || !m.isValid()) { return; }
    const splitAfternoon = 12;
    const splitNextDay = 17;
    var currentHour = parseFloat(m.format('HH'));
    if (currentHour >= splitNextDay) {
      // next day
      g = 3;
    } else if (currentHour >= splitAfternoon) {
      // afternoon
      g = 2;
    } else {
      // all day
      g = 1;
    }
    return g;
  }

  saveInfo(key, value) {
    this.props.bestdealOrder.saveInfo(key, value);
  }

  async componentDidMount() {
    let parsed = querystring.parse(this.props.location.search);
    const orderId = parsed.order_id;
    const status = parsed.status;
    if (status !== 'view' && status !== 'admin') {
      let checkTime = this.getGreetingTime(moment());
      const bestdealOrder = this.props.bestdealOrder.toJS();
      const info = bestdealOrder.info;
      if ((moment(info.expectedDate).format('DMY') === moment().format('DMY'))) {
        if (checkTime === 1 || checkTime === 2) {
          this.saveInfo('expectedTime', checkTime);
        } else {
          let calendarDate = moment(info.expectedDate).add(1, 'days').startOf('day');
          this.saveInfo('expectedDate', calendarDate.toDate());
          this.saveInfo('expectedTime', 1);
        }
      }
      this.setState({ time: checkTime });
    }
    if (status === 'view') {
      this.setState({ status: 'view' });
    }
    if (orderId) this.props.bestdealOrder.getOrderById(orderId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.coords) {
      const location = [nextProps.coords.longitude, nextProps.coords.latitude];
      const bestdealOrder = this.props.bestdealOrder.toJS();
      const info = bestdealOrder.info;
      const isEdit = info.id && info.id.length;
      if (!isEdit) {
        this.setState({
          useCurrentLocation: this.state.useCurrentLocation,
          currentLocation: location,
        });
      }
    }
  }

  onItemTextChange(index, key, e) {
    const value = e.target.value;
    const bestdealOrder = this.props.bestdealOrder.toJS();
    const items = bestdealOrder.info.items;
    items[index][key] = value;
    this.saveInfo('items', items);
  }

  onTextChange(key, e) {
    const value = e.target.value;
    this.saveInfo(key, value);
  }

  onDeliveryClick(value) {
    this.saveInfo('delivery', value);
  }

  onDateChange(date) {
    this.saveInfo('expectedDate', date.toDate());
  }

  onTimeChanged(e) {
    this.saveInfo('expectedTime', e.target.value);
  }

  onDeleteItem(index) {
    const bestdealOrder = this.props.bestdealOrder.toJS();
    const items = _.cloneDeep(bestdealOrder.info.items);
    items.splice(index, 1);
    this.saveInfo('items', items);
  }

  onAddItem() {
    const bestdealOrder = this.props.bestdealOrder.toJS();
    const items = bestdealOrder.info.items;
    items.push({
      qty: '',
      unit: '',
    });
    this.saveInfo('items', items);
  }

  onAddressChange = async (addressObj) => {
    const address = {
      subDistrict: {
        code: addressObj.dcode,
        name: addressObj.dname,
      },
      district: {
        code: addressObj.acode,
        name: addressObj.aname,
      },
      province: {
        code: addressObj.pcode,
        name: addressObj.pname,
      },
      postcode: addressObj.zcode,
    };
    this.saveInfo('address', address);
    if (!this.state.useCurrentLocation) {
      const location = await GoogleMap.getLocationFromAddress(address);
      if (location && location.status === 200 && location.data) {
        const results = location.data.results;
        if (results && results.length > 0) {
          const geoLocation = results[0].geometry.location;
          const location = [geoLocation.lng, geoLocation.lat];
          this.saveInfo('location', location);
        }
      }
    }
  }

  handleLocationChange({ position, address }) {
    const positionLngLat = [position.lng, position.lat];
    this.saveInfo('googleAddress', address);
    this.saveInfo('location', positionLngLat);
    this.setState({
      useCurrentLocation: _.isEqual(positionLngLat, this.state.currentLocation),
      currentLocation: this.state.currentLocation,
    });
  }

  onUseCurrentChange(value) {
    this.setState({ useCurrentLocation: value });
    if (value && !_.isEmpty(this.state.currentLocation)) {
      this.setState({
        useCurrentLocation: value,
        currentLocation: this.state.currentLocation,
      });
      this.saveInfo('location', this.state.currentLocation);
    }
  }

  onVatChanged(value) {
    const bestdealOrder = this.props.bestdealOrder.toJS();
    const vatInfo = bestdealOrder.info.vatInfo;
    vatInfo.isRequire = value;
    this.saveInfo('vatInfo', vatInfo);
  }

  onVatInfoChange(key, e) {
    const value = e.target.value;
    const bestdealOrder = this.props.bestdealOrder.toJS();
    const vatInfo = bestdealOrder.info.vatInfo;
    if (key === 'addressNo') {
      vatInfo.address.no = value;
    } else {
      vatInfo[key] = value;
    }
    this.saveInfo('vatInfo', vatInfo);
  }

  onVatAddressChange = async (addressObj) => {
    const bestdealOrder = this.props.bestdealOrder.toJS();
    const vatInfo = bestdealOrder.info.vatInfo;
    const address = {
      no: vatInfo.address.no,
      subDistrict: {
        code: addressObj.dcode,
        name: addressObj.dname,
      },
      district: {
        code: addressObj.acode,
        name: addressObj.aname,
      },
      province: {
        code: addressObj.pcode,
        name: addressObj.pname,
      },
      postcode: addressObj.zcode,
    };
    vatInfo.address = address;
    this.saveInfo('vatInfo', vatInfo);
  }

  async sendMessageToUser(order, botIndex) {
    let items = order.items.map((item, index) => `${index + 1}. ${item.name} ${item.spec} [${item.qty} ${item.unit}]`).join('\n');
    // line allow up to 160 charactor
    items = 'ได้รับคำสั่งซื้อเรียบร้อยแล้ว\n' + items;
    items = (items.length > 156) ? items.substring(0, 156) + ' ...' : items;
    const message = {
      type: 'template',
      altText: `ได้รับคำสั่งซื้อเรียบร้อยแล้ว`,
      template: {
        type: 'buttons',
        text: `${items}`,
        actions: [
          {
            type: 'uri',
            label: 'เปิดดู',
            uri: `${config.bestdeal.liff[botIndex].order}?order_id=${order._id}&status=edit`,
          },
        ],
      },
    };
    return message;
  }

  isValid() {
    const bestdealOrder = this.props.bestdealOrder.toJS();
    const info = bestdealOrder.info;
    const items = info.items;
    const itemValid = items.length > 0 && items[0].name && items[0].spec && items[0].qty && items[0].unit;
    const customerValid = info.name && info.mobile && info.mobile.length === 10;
    // const addressValid = info.address && info.address.subDistrict && info.address.district && info.address.province && info.address.postcode;
    const addressValid = this.state.useCurrentLocation || info.googleAddress;
    return itemValid && customerValid && addressValid;
  }

  async onSubmit() {
    if (this.state.status === 'view') {
      liffHelper.closeWindow();
    } else {
      const bestdealOrder = this.props.bestdealOrder.toJS();
      const info = bestdealOrder.info;
      const isEdit = info.id && info.id.length;
      let parsed = querystring.parse(this.props.location.search);
      const botIndex = parsed['bot_index'] || 0;
      this.saveInfo('botIndex', botIndex);
      let apiResult;
      let confirmDialogOptions = {
        title: 'บันทึกข้อมูล',
        text: isEdit ? 'กรุณายืนยันการแก้ไขการค้นหา' : 'กรุณายืนยันการค้นหา',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: 'font-size-200',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            if (isEdit) {
              apiResult = await this.props.bestdealOrder.updateOrder(info.id);
            } else {
              apiResult = await this.props.bestdealOrder.createOrder();
            }
            return apiResult;
          } catch (error) {
            swal.showValidationMessage(`การบันทึกล้มเหลว ${error.message}`);
          }
        },
        allowOutsideClick: () => !swal.isLoading(),
      };

      let result = await swal.fire(confirmDialogOptions);
      if (result.value) {
        let storeCountMsg = '';
        const swalTitle = isEdit ? 'บันทึกข้อมูลสำเร็จ' : `บันทึกข้อมูลสำเร็จ\n${storeCountMsg}`;
        swal.fire({
          title: `${swalTitle}\nOrder : ${result.value.order_no}`,
          icon: 'success',
        }).then(async () => {
          const templateMsg = await this.sendMessageToUser(apiResult, botIndex);
          liffHelper.sendMessages(templateMsg)
            .then(() => {
              this.props.bestdealOrder.clearInfo();
              this.onCancel();
            })
            .catch((err) => {
              console.error('error', err);
            });
          // this.sendMessageToUser(apiResult, botIndex).then(this.onCancel);
        });
      }
    }
  }

  onCancel() {
    liffHelper.closeWindow();
  }

  googlePlaceChange(address) {
    this.saveInfo('googleAddress', address);
  };

  googlePlaceSelect(address) {
    this.saveInfo('googleAddress', address);
    geocodeByAddress(address)
      .then(results => {
        if (!this.state.useCurrentLocation) {
          const geoLocation = results[0].geometry.location;
          const location = [geoLocation.lng(), geoLocation.lat()];
          this.saveInfo('location', location);
        }
      })
      .catch(error => console.error('Error', error));
  }

  render() {
    const bestdealOrder = this.props.bestdealOrder.toJS();
    const info = bestdealOrder.info;
    let timeOption = [
      { label: 'ช่วงเช้า (9.00-12.00)', value: 1 },
      { label: 'ช่วงบ่าย (13.00-17.00)', value: 2 },
    ];
    let checkDay = 0;
    let calendarDate = moment(info.expectedDate);
    if ((moment(info.expectedDate).format('DMY') === moment().format('DMY'))) {
      if (this.state.time === 3) {
        checkDay = 1;
        calendarDate = moment(info.expectedDate).add(1, 'days').startOf('day');
      } else if (this.state.time === 2) {
        timeOption = [
          { label: 'ช่วงบ่าย (13.00-17.00)', value: 2 },
        ];
      }
    } else {
      this.state.time === 3 ? checkDay = 1 : checkDay = 0;
    }
    const isEdit = info.id && info.id.length;
    const googleAddress = info.googleAddress;
    const vatAddress = info.vatInfo.address;
    // let addressSelector = {};
    // if (address) {
    //   addressSelector = {
    //     subDistrict: address.subDistrict.name,
    //     subDistrictCode: address.subDistrict.code,
    //     district: address.district.name,
    //     districtCode: address.district.code,
    //     province: address.province.name,
    //     provinceCode: address.province.code,
    //     postCode: address.postcode,
    //   };
    // }
    let vatAddressSelector = {};
    if (vatAddress) {
      vatAddressSelector = {
        subDistrict: vatAddress.subDistrict.name,
        subDistrictCode: vatAddress.subDistrict.code,
        district: vatAddress.district.name,
        districtCode: vatAddress.district.code,
        province: vatAddress.province.name,
        provinceCode: vatAddress.province.code,
        postCode: vatAddress.postcode,
      };
    }

    let timeDropdownOption = timeOption.map((item, idx) => {
      return <option key={`time-${idx}`} value={item.value}>{item.label}</option>;
    });

    const circleOptions = {
      fillColor: '#ffff00',
    };

    const items = info.items.map((item, index) => {
      return (
        <div className="bsd-product-sets" key={index}>
          <div className="bsd-title">
            <span>รายการสินค้าที่ {index + 1}</span>
            {
              (info.items).length > 1 &&
              <div className="bsd-delete"
                onClick={this.onDeleteItem.bind(this, index)}>
                <strong><span className="fa fa-minus-circle" aria-hidden="true" /></strong>
              </div>
            }
          </div>
          <div className="row">
            <EnTextFormGroup disabled={this.state.status === 'view' ? 1 : 0} id="name" containerClassName="col-xs-6" label={'ชื่อสินค้า ยี่ห้อ รุ่น'} value={item.name} onChange={this.onItemTextChange.bind(this, index, 'name')} validations={[required]} />
            <EnTextFormGroup disabled={this.state.status === 'view' ? 1 : 0} id="spec" containerClassName="col-xs-6" label={'สเปค ขนาด สี'} value={item.spec} onChange={this.onItemTextChange.bind(this, index, 'spec')} validations={[required]} />
          </div >
          <div className="row">
            <EnTextFormGroup disabled={this.state.status === 'view' ? 1 : 0} type="number" id="qty" containerClassName="col-xs-6" label={'จำนวน'} value={+item.qty || ''} onChange={this.onItemTextChange.bind(this, index, 'qty')} validations={[required]} />
            <EnTextFormGroup disabled={this.state.status === 'view' ? 1 : 0} id="unit" containerClassName="col-xs-6" label={'หน่วย'} value={item.unit || ''} onChange={this.onItemTextChange.bind(this, index, 'unit')} validations={[required]} />
          </div>
        </div>
      );
    });
    const gAddressSearchOptions = {
      componentRestrictions: {
        country: 'th',
      },
    };
    const gPlaceAutoComplete = (
      <PlacesAutocomplete
        value={googleAddress || ''}
        onChange={this.googlePlaceChange.bind(this)}
        onSelect={this.googlePlaceSelect.bind(this)}
        searchOptions={gAddressSearchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'ค้นหาไซต์งาน ...',
                className: 'location-search-input',
                style: { width: '100%' },
                disabled: this.state.useCurrentLocation || this.state.status === 'view',
                validations: [required],
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = (index % 2 === 0)
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = (index % 2 === 0)
                  ? { backgroundColor: '#ffffcc', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
    let btnAddItem;
    if (this.state.status !== 'view') {
      btnAddItem =
        <div className="row">
          <FormGroup containerClassName={'col-md-12'}>
            <div className="bsd-add" onClick={this.onAddItem.bind(this)}>
              <span className="fa fa-plus-circle" aria-hidden="true" /> เพิ่มรายการ
            </div>
          </FormGroup>
        </div>;
    }

    return (
      <div className="content" >
        <Loader loading={bestdealOrder.isLoading} />
        <div className="container-fluid">
          <div className="row">
            <div>
              <div className="card info">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">ค้นหาสินค้า</h4>
                  <p className="category">ระบุสินค้าที่ต้องการได้เลย</p>
                </div>
                <div className="card-content">
                  <FormValidation ref="FormValidation">
                    <h5>ข้อมูลลูกค้า</h5>
                    <div className="row">
                      <EnTextFormGroup disabled={this.state.status === 'view' ? 1 : 0} id="name" containerClassName="col-xs-6" label={'ชื่อ-นามสกุล'} value={info.name} onChange={this.onTextChange.bind(this, 'name')} validations={[required]} />
                      <EnTextFormGroup disabled={this.state.status === 'view' ? 1 : 0} id="mobile" placeholder="08xxxxxxx" containerClassName="col-xs-6" label={'เบอร์มือถือ'} value={info.mobile} onChange={this.onTextChange.bind(this, 'mobile')} validations={[required, phone]} />
                    </div>
                    {items}
                    {btnAddItem}
                    <hr />
                    <h4>จะใช้เมื่อไหร่</h4>
                    <div className="row">
                      <FormGroup containerClassName={'col-xs-6'} label={'วันที่'}>
                        <EnDatePicker
                          disabled={this.state.status === 'view' ? 1 : 0}
                          id="expectedDate"
                          initialDate={info.expectedDate ? calendarDate : moment().add(checkDay, 'days').startOf('day')}
                          onDateChange={this.onDateChange.bind(this)}
                          isOutsideRange={(dates) =>
                            checkDay === 0 ? dates.isBefore(moment().add(0, 'days').startOf('day')) : dates.isBefore(moment().add(1, 'days').startOf('day'))
                          } />
                      </FormGroup>
                      <FormGroup containerClassName={'col-xs-6'} label={'ช่วงเวลา'}>
                        <EnDropDown disabled={this.state.status === 'view' ? 1 : 0} value={info.expectedTime} onChange={this.onTimeChanged.bind(this)}>
                          {timeDropdownOption}
                        </EnDropDown>
                      </FormGroup>
                    </div>
                    <div className="row">
                      <FormGroup containerClassName={'col-xs-6'} label={(info.delivery) ? 'ต้องการให้จัดส่ง' : 'รับของที่ร้าน'}>
                        <EnToggle disabled={this.state.status === 'view' ? 1 : 0} checked={info.delivery} onChange={this.onDeliveryClick.bind(this)} />
                      </FormGroup>
                    </div>
                    <div className="row">
                      <FormGroup containerClassName={'col-xs-6'} label={'ต้องการใบกำกับภาษี (VAT)'}>
                        <EnToggle disabled={this.state.status === 'view' ? 1 : 0} checked={info.vatInfo.isRequire} onChange={this.onVatChanged.bind(this)} />
                      </FormGroup>
                    </div>
                    {info.vatInfo && info.vatInfo.isRequire &&
                      <div>
                        <div className="row">
                          <EnTextFormGroup disabled={this.state.status === 'view' ? 1 : 0} id="companyName" containerClassName="col-xs-6" label={'ชื่อบริษัท'} value={info.vatInfo.companyName} onChange={this.onVatInfoChange.bind(this, 'companyName')} validations={[required]} />
                          <EnTextFormGroup disabled={this.state.status === 'view' ? 1 : 0} id="taxId" containerClassName="col-xs-6" label={'เลขประจำตัวผู้เสียภาษี'} value={info.vatInfo.taxId} onChange={this.onVatInfoChange.bind(this, 'taxId')} validations={[required]} />
                        </div>
                        <div className="row">
                          <EnTextFormGroup disabled={this.state.status === 'view' ? 1 : 0} id="branchNo" containerClassName="col-xs-6" label={'รหัสสาขา'} value={info.vatInfo.branchNo} onChange={this.onVatInfoChange.bind(this, 'branchNo')} validations={[required]} />
                          <EnTextFormGroup disabled={this.state.status === 'view' ? 1 : 0} id="vatAddress" containerClassName="col-xs-6" label={'ที่อยู่ออกใบกำกับภาษี'} value={info.vatInfo.address ? info.vatInfo.address.no : ''} onChange={this.onVatInfoChange.bind(this, 'addressNo')} validations={[required]} />
                        </div>
                        <div className="row">
                          <AddressForm
                            disabled={this.state.status === 'view' ? 1 : 0}
                            addressData={vatAddressSelector}
                            onAddressSelected={(addressObject) => this.onVatAddressChange(addressObject)}
                            validations={[required]} />
                        </div>
                      </div>
                    }
                    <hr />
                    <h5>ที่อยู่หน้างาน</h5>
                    <div className="row">
                      <FormGroup containerClassName={'col-xs-4'} label={'ใช้ตำแหน่งปัจจุบัน'}>
                        <EnToggle
                          disabled={this.state.status === 'view' ? 1 : 0}
                          checked={this.state.useCurrentLocation}
                          onChange={this.onUseCurrentChange.bind(this)} />
                      </FormGroup>
                      {!this.state.useCurrentLocation &&
                        <FormGroup containerClassName={'col-xs-8'} label={`สถานที่ส่งของ${this.state.useCurrentLocation ? '' : ' *'}`}>
                          {gPlaceAutoComplete}
                        </FormGroup>
                      }
                    </div>
                    <div className="row">
                      {/* <FormGroup containerClassName={'col-md-12'} label={locationLabel}> */}
                      <FormGroup containerClassName={'col-md-12'}>
                        <LocationPicker
                          disabled={this.state.status === 'view' ? 1 : 0}
                          containerElement={<div style={{ height: '100%' }} />}
                          mapElement={<div style={{ height: '400px' }} />}
                          defaultPosition={{
                            lat: info.location[1],
                            lng: info.location[0],
                          }}
                          zoom={12}
                          radius={5000}
                          circleOptions={circleOptions}
                          onChange={this.handleLocationChange.bind(this)}
                        />
                      </FormGroup>
                    </div>
                    <hr />
                    <div className="row">
                      <EnTextAreaFormGroup rows="4" id="remark" containerClassName="col-md-12 col-xs-12" label={'หมายเหตุ'} value={info.remark} onChange={this.onTextChange.bind(this, 'remark')} disabled={this.state.status === 'view'} />
                    </div>
                    <hr />
                    <div className="text-center">
                      <EnButton className="btn-info" disabled={!this.isValid()} onClick={this.onSubmit.bind(this)}>{this.state.status === 'view' ? 'ปิด' : (isEdit ? 'แก้ไขคำสั่งซื้อ' : 'ค้นหาสินค้า')}</EnButton>
                    </div>
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

const geo = geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    watchPosition: true,
  },
  userDecisionTimeout: 15000,
});
export default inject('bestdealOrder', 'bestdealMember')(geo(observer(Order)));
