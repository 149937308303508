import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import swal from 'sweetalert2';

import Container from '../../../layouts/Container';
import Notification from '../../../components/common/Notification';
import Loader from '../../../components/common/Loader';

import { FormValidation } from '../../../components/form/FormValidation';
import { EnTagSelectValidation } from '../../../components/form/EnTagSelect';
import EnButton from '../../../components/form/EnButton';

export class ProjectConfigPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentWillMount() {
    try {
      await this.props.projectconfig.getActiveProjects();
      await this.props.projectconfig.getRecentProjectIds();
    } catch (error) {
      this.noti.error(error.message, 'โหลดข้อมูลล้มเหลว');
    }
  }

  onProjectSelectChange(values) {
    console.log('select change ->', values);
    this.props.projectconfig.setSelectedIds(values);
  }

  // validate() {
  //   const { selectedIds } = this.props.projectconfig.toJS();
  //   const ids = selectedIds.split(',');
  //   console.log('validate ids ->', ids);
  //   if (ids.length !== 3) {
  //     throw new Error('กรุณาเลือกผลงาน 3 รายการ');
  //   }
  // }

  async onSubmitClick() {
    // try {
    //   this.validate();
    // } catch (error) {
    //   swal.fire({
    //     icon: 'error',
    //     title: `${error.message}`,
    //   });
    //   return;
    // }

    const options = {
      title: 'บันทึกข้อมูล',
      text: 'กรุณายืนยันการบันทึกข้อมูล',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.props.projectconfig.updateRecentProjectIds();
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    const result = await swal.fire(options);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  }

  render() {
    const { isLoading, projects, selectedIds } = this.props.projectconfig.toJS();
    const projectOptions = (projects || []).map(p => {
      return {
        value: p._id,
        label: p.title,
      };
    });
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card" style={{ height: '600px' }}>
              <div className="card-header" data-background-color="orange">
                <h4 className="title">จัดลำดับการแสดงผลงานในหน้าเว็บคิวช่าง</h4>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="row">
                    <div className="col-md-12">
                      <span className="form-label">ผลงาน *</span>
                      <EnTagSelectValidation
                        placeholder="กรุณาเลือกผลงาน"
                        closeOnSelect={false}
                        options={projectOptions}
                        searchable={false}
                        value={selectedIds}
                        onTagChange={this.onProjectSelectChange.bind(this)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <EnButton
                      className="btn-info pull-right"
                      onClick={this.onSubmitClick.bind(this)}
                    >
                      <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                    </EnButton>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('projectconfig')(observer(ProjectConfigPage));
