import { isNil } from 'lodash';
import React, { Component } from 'react';

import EnText from './EnText';
import FormGroup from './FormGroup';

export class EnTextFormGroup extends Component {
  onChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  onBlur = (e) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  render() {
    let {
      label,
      value,
      validations,
      type,
      onKeyPress,
      name,
      size,
      maxLength,
      containerClassName,
      notRequired,
      id,
      placeholder,
      min,
      className,
      labelClassName,
      isError,
      errorMessage,
      style,
      icon,
    } = this.props;

    let options = {
      label,
      labelClassName,
      notRequired,
      validations: !isNil(validations) && Array.isArray(validations),
      containerStyle: style || {},
    };

    let inputOptions = {};
    if (name) {
      inputOptions['name'] = name;
    }
    if (size) {
      inputOptions['size'] = size;
    }
    if (maxLength) {
      inputOptions['maxLength'] = maxLength;
    }
    if (id) {
      inputOptions['id'] = id;
    }
    if (min) {
      inputOptions['min'] = min;
    }

    if (className) {
      inputOptions['className'] = className;
    }

    return (
      <FormGroup containerClassName={containerClassName} {...options}>
        {icon && (
          <span style={{ padding: '5px' }}>
            <i className={icon} aria-hidden="true" />
          </span>
        )}
        <EnText
          {...inputOptions}
          placeholder={placeholder}
          type={type}
          disabled={this.props.disabled}
          ref={(e) => {
            this.input = e;
          }}
          value={value || ''}
          onChange={this.onChange}
          validations={validations}
          onKeyPress={onKeyPress}
          onBlur={this.onBlur}
        />
        {this.props.children}
        {isError && <span className="input-error">{errorMessage}</span>}
      </FormGroup>
    );
  }
}

export default EnTextFormGroup;
