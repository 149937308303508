import React from 'react';
import { observer, inject } from 'mobx-react';
import Select from 'react-select';
import { required } from '../../../../components/form/FormValidation';
import EnTextFormGroup from '../../../../components/form/EnTextFormGroup';
import FormGroup from '../../../../components/form/FormGroup';
import EnButton from '../../../../components/form/EnButton';

import guaranteeIcon from '../../../../assets/img/guarantee-icon.svg';
import cubeIcon from '../../../../assets/img/cube-icon.svg';
import pinLocationIcon from '../../../../assets/img/pin-location-icon.svg';
import { Fragment } from 'react';

const sellingPointIcons = [
  {
    icon: guaranteeIcon,
    value: 'guarantee',
  },
  {
    icon: cubeIcon,
    value: 'cube',
  },
  {
    icon: pinLocationIcon,
    value: 'pin_location',
  },
];

export class SellingPointSection extends React.Component {
  onAddNewSellingPointClick() {
    this.props.product.addNewSellingPoint();
  }

  onDeleteSellingPointClick(index, e) {
    e.preventDefault();
    this.props.product.deleteSellingPoint(index);
  }

  onSellingPointIconChange(index, key, e) {
    this.props.product.saveSellingPoint(index, key, e.value);
  }

  onSellingPointChange(index, key, e) {
    this.props.product.saveSellingPoint(index, key, e.target.value);
  }

  renderSellingPoints(sellingPoints) {
    const options = sellingPointIcons.map((spi, index) => {
      return {
        label: <img src={spi.icon} alt="" className="dropdown-image" />,
        value: spi.value,
      };
    });
    options.unshift({
      label: 'เลือก Icon',
      value: '',
    });
    return (sellingPoints || []).map((sp, index) => (
      <div key={`selling-point-${index}`}>
        <div className="row" style={{ paddingLeft: '15px' }}>
          <label>{`Selling Point ${index + 1}`}</label>
        </div>
        <div className="row">
          <FormGroup containerClassName="col-sm-3 col-md-2" validations={[required]}>
            <Select className="selling-point" searchable={false} clearable={false} onChange={this.onSellingPointIconChange.bind(this, index, 'icon')} options={options} value={sp.icon} />
          </FormGroup>
          <EnTextFormGroup containerClassName="col-sm-7 col-md-9" type="text" value={sp.text} onChange={this.onSellingPointChange.bind(this, index, 'text')} />
          <div className="col-sm-2 col-md-1 selling-point-button">
            <EnButton className="btn-danger" style={{ marginTop: '0' }} onClick={this.onDeleteSellingPointClick.bind(this, index)}>
              <i className="fa fa-trash" aria-hidden="true" />
            </EnButton>
          </div>
        </div>
      </div>
    ));
  }

  render() {
    const { sellingPoints } = this.props;
    return (
      <Fragment>
        {this.renderSellingPoints(sellingPoints)}
        <div className="row">
          <EnButton className="btn-success" style={{ marginLeft: '15px' }} onClick={this.onAddNewSellingPointClick.bind(this)}>
            <i className="fa fa-plus btn-icon" aria-hidden="true" />
            เพิ่ม Selling Point
          </EnButton>
        </div>
      </Fragment>
    );
  }
}

export default inject('product')(observer(SellingPointSection));
