import humps from 'humps';
import BaseStore from '../BaseStore';
import config from '../../config';
import { http } from '../../utils/http';

export class Solution extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      info: this.initInfo(),
    });
  }

  initInfo() {
    return {
      name: {
        th: '',
      },
      description: {
        th: '',
      },
      image: undefined,
      bestSellerId: '',
      productIds: [],
      productGroupIds: [],
      channel: [],
      seo: {
        title: '',
        slug: '',
        keywords: '',
        description: '',
      },
      isActive: true,
      publish: true,
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveNameTh(value) {
    this.info.name.th = value;
  }

  saveDescriptionTh(value) {
    this.info.description.th = value;
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  saveSeoInfo = (value, key) => {
    this.info.seo[key] = value;
  }

  async getSolutionList() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.qchang}/v1/solutions`);
      if (response.status === 200 && response.data) {
        this.items = response.data.data;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getSolutionById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.qchang}/v1/solutions/${id}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      const data = humps.camelizeKeys(response.data.data);
        this.info = data;
        return
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  getUpdateInfo(options) {
    const info = this.toJS().info;
    const { selectProductIds, selectBestSellerId, selectProductGroupIds } = options;

    if (Array.isArray(selectProductIds) && selectProductIds.length) {
      info.productIds = selectProductIds;
      info.productGroupIds = selectProductGroupIds;
    }

    if (selectBestSellerId) {
      info.bestSellerId = selectBestSellerId;
    }

    return info;
  }

  async createSolution(options) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.getUpdateInfo(options);
      const response = await http.post(`${config.api.qchang}/v1/solutions`, info);
      if (response.status !== 200) {
        if (response.data && response.data.message === 'DUPLICATED_SLUG') {
          throw new Error('Slug ซ้ำ');
        }
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateSolution(id, options) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.getUpdateInfo(options);
      const response = await http.put(`${config.api.qchang}/v1/solutions/${id}`, info);
      if (response.status !== 200) {
        if (response.data && response.data.message === 'DUPLICATED_SLUG') {
          throw new Error('Slug ซ้ำ');
        }
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteSolution(id) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.delete(`${config.api.qchang}/v1/solutions/${id}`);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async togglePublish(index, id, publish) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.put(`${config.api.qchang}/v1/solutions/${id}/publish`, { publish });
      if (res.status === 200 && res.data) {
        this.items[index] = res.data.data;
      } else {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new Solution();
