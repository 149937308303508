import * as _ from 'lodash';
import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';

export class UploadStore extends BaseStore {
  async uploadFile(file, path) {
    try {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('path', path);
      const res = await http.postMultipartFormData(`${config.api.sims}/v1/uploads`, formData);

      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }
}
export default new UploadStore();
