import React, { Component } from 'react';
import Select from 'react-validation/build/select';

export class EnDropDown extends Component {
  value() {
    return this.input.value;
  }

  clear() {
    this.input.value = '';
  }

  render() {
    let propInput = Object.assign({}, this.props);
    propInput.className = `${propInput.className ? propInput.className : ''} form-control`;

    if (propInput.validations && propInput.validations.length) {
      return <Select ref={(e) => { this.input = e; }} {...propInput}>
        {this.props.children}
      </Select>;
    } else {
      return <select ref={(e) => { this.input = e; }} {...propInput}>
        {this.props.children}
      </select>;
    }
  }
}

export default EnDropDown;
