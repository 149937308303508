import React, { Component } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';

export default class EnDropzoneDialog extends Component {
  render() {
    let {
      open,
      onSave,
      onClose,
      filesLimit = 1,
      maxFileSize = 5000000, // bytes => 5MB
      showPreviews = true,
      acceptedFiles = ['image/*'],
      dialogTitle = 'อัพโหลดไฟล์',
      dropzoneText = 'ลากไฟล์ลงที่นี่หรือคลิ๊กเพืิ่อเลือกไฟล์',
      submitButtonText = 'อัพโหลด',
      cancelButtonText = 'ยกเลิก',
    } = this.props;
    return (
      <DropzoneDialog
        open={open}
        acceptedFiles={acceptedFiles}
        showPreviews={showPreviews}
        maxFileSize={maxFileSize}
        onSave={onSave}
        onClose={onClose}
        filesLimit={filesLimit}
        dialogTitle={dialogTitle}
        dropzoneText={dropzoneText}
        submitButtonText={submitButtonText}
        cancelButtonText={cancelButtonText}
      />
    );
  }
}
