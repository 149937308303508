import React, { useEffect, useState } from 'react';

const CheckboxItem = ({ label = '', checked = false, disabled = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleCheckboxChange = (event) => {
    const newChecked = event.target.checked;
    setIsChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  return (
    <div className="checkbox-container">
      <input
        id="checkbox-input"
        type="checkbox"
        disabled={disabled}
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <span>{label}</span>
    </div>
  );
};

export default CheckboxItem;
