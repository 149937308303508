import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import Notification from '../../components/common/Notification';
import Pagination from '../../components/common/Pagination';
import BroadcastSearchForm from './components/BroadcastSearchForm';
import BroadcastRowTable from './components/BroadcastRowTable';

import BroadcastHeaderTable from './components/BroadcastHeaderTable';
import swal from 'sweetalert2';
import QocBroadcastStore from '../../stores/QocBroadcastStore';
import { is } from 'date-fns/locale';
import { defaultConfirmOption } from '../../utils/common';
import infoCheck from '../../assets/img/icn_check.svg';

class BroadcastListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      broadcasts: {
        result: [],
      },
      query: {},
      pagination: {
        page: 1,
        page_size: 10,
      },
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.fetchData();
    this.setState({ isLoading: false });
  }

  async fetchData(nextPage) {
    this.setState({ isLoading: true });
    try {
      const { page_size, page } = this.state.pagination;
      const { query } = { ...this.state };
      const pageNum = nextPage || page;
      let form = Object.assign({}, query);

      if (query.createdAt) {
        form.createdAt = query.createdAt && query.createdAt.toISOString();
      }
      if (query.publishedAt) {
        form.publishedAt = query.publishedAt && query.publishedAt.toISOString();
      }

      const response = await QocBroadcastStore.getAll({ page: pageNum || page, page_size: page_size, ...form });
      const totalPage = Math.ceil(response.total / page_size);
      this.setState({
        broadcasts: { result: response.results },
        pagination: {
          page_size: page_size,
          page: nextPage ? 1 : pageNum,
          current_page: pageNum,
          next_page: pageNum + 1,
          previous_page: pageNum - 1,
          has_next: pageNum < totalPage,
          has_previous: pageNum > 1,
          is_first_page: pageNum === 1,
          is_last_page: pageNum === totalPage,
          total_pages: totalPage,
          start_page_range: Math.floor((pageNum - 1) / 5) * 5 + 1,
          end_page_range: Math.min(Math.floor((pageNum - 1) / 5) * 5 + 5, totalPage),
          total_items: response.total,
        },
      });
    } catch (error) {
      console.log(error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async handleDelete(id) {
    const result = await swal.fire({
      title: 'ยืนยันการลบข้อมูล',
      html: 'คุณต้องการลบข้อมูลหรือไม่',
      imageWidth: 60,
      imageHeight: 60,
      width: 400,
      height: 400,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      ...defaultConfirmOption,
    });
    if (result.value) {
      this.setState({ isLoading: true });
      await QocBroadcastStore.delete(id);
      await this.fetchData();
      swal.fire({
        toast: true,
        title: 'ลบข้อมูลสำเร็จแล้ว',
        imageUrl: infoCheck,
        position: 'top',
        timer: 2000,
        customClass: 'swal2-success',
        showConfirmButton: false,
      });
      this.setState({ isLoading: false });
    }
  }

  async handleChangeQuery(newQuery) {
    this.setState({ query: newQuery });
  }

  checkFilterIsDirty() {
    const { query } = this.state;
    const result = Object.keys(query).some((key) => {
      if (key === 'announcement_date' || key === 'create_at') {
        return query[key] !== null;
      } else if (key === 'release_status' || key === 'is_published') {
        return query[key] !== '';
      }
      return query[key] !== '';
    });

    return result;
  }

  handleClearSearch() {
    this.setState({
      query: {
        name: '',
        recipientType: '',
        isPublish: '',
        isActive: '',
        publishedAt: null,
        createdAt: null,
      },
    });
    setTimeout(() => {
      this.fetchData();
    });
  }

  render() {
    const { broadcasts, isLoading } = this.state;
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <Notification
            ref={(ref) => {
              this.noti = ref;
            }}
          />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ประกาศ QOC</h4>
              </div>
              <div className="card-content">
                <BroadcastSearchForm
                  query={this.state.query}
                  handleChangeQuery={(key, values) => this.handleChangeQuery(key, values)}
                  handleClickSearch={this.fetchData.bind(this)}
                  handleClearSearch={this.handleClearSearch.bind(this)}
                />
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <BroadcastHeaderTable history={this.props.history} />
                        <tbody>
                          {_.isEmpty(broadcasts.result) && this.checkFilterIsDirty() ? (
                            <tr key={0}>
                              <td colSpan="12" style={{ height: '270px' }}>
                                <div className=" d-flex w-100 justify-content-center justify-items-center" style={{ height: '100%' }}>
                                  <div className="text-center">
                                    <img className="additional-products-page__empty-img" src={require('../../assets/img/empty-search.svg')} />
                                    <div style={{ fontSize: '24px', marginTop: '16px', color: '#014672' }}>ไม่พบข้อมูลที่ค้นหา</div>
                                    <div style={{ color: '#838799' }}>กรุณาลองใหม่อีกครั้ง</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ) : (
                            _.isEmpty(broadcasts.result) && (
                              <tr key={0}>
                                <td colSpan="12" style={{ height: '270px' }}>
                                  <div className=" d-flex w-100 justify-content-center justify-items-center" style={{ height: '100%' }}>
                                    <div className="text-center">
                                      <img className="additional-products-page__empty-img" src={require('../../assets/img/empty-box.svg')} />
                                      <div style={{ marginTop: '16px', color: '#838799' }}>ยังไม่มีข้อมูลการประกาศ</div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                          {broadcasts.result &&
                            broadcasts.result.map((broadcast) => (
                              <BroadcastRowTable
                                key={broadcast.id}
                                broadcast={broadcast}
                                history={this.props.history}
                                deleteBroadcast={(id) => this.handleDelete(id)}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Pagination pagination={this.state.pagination} handlePageClick={this.fetchData.bind(this)} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('misc', 'typeofwork', 'broadcasts')(observer(BroadcastListPage));
