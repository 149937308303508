export const TabKeyPlugin = (editor) => {
  // allow the tab key to be used to indent and outdent without affecting the browser focus and list creation
  const view = editor.editing.view;
  const viewDocument = view.document;

  viewDocument.on('keydown', (event, data) => {
    if (data.keyCode === 9 && viewDocument.isFocused) {
      const model = editor.model;
      const selection = model.document.selection;
      const firstPosition = selection.getFirstPosition();
      const isInList = firstPosition.findAncestor('listItem');
      if (!isInList) {
        // editor.execute( 'input', { text: "\t" } );
        editor.execute('input', { text: '    ' });

        event.stop(); // Prevent executing the default handler.
        data.preventDefault();
        view.scrollToTheSelection();
      }
    }
  });
};


// export const TabKeyPlugin = (editor) => {
//   // allow the tab key to be used to indent and outdent without affecting the browser focus and list creation
//   editor.keystrokes.set('Tab', (data, stop) => {
//     const model = editor.model;
//     const selection = model.document.selection;

//     model.change((writer) => {
//       const firstPosition = selection.getFirstPosition();
//       // Otherwise, insert a tab character
//       const tabSpaces = '    '; // Adjust the number of spaces to simulate a tab
//       const tabText = writer.createText(tabSpaces);
//       model.insertContent(tabText, firstPosition);
//       stop();
//     });

//     stop();
//   });
// };
