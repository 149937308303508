import _ from 'lodash';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import LocationPicker from 'react-location-picker';
import { geolocated } from 'react-geolocated';
import swal from 'sweetalert2';
import querystring from 'query-string';
import { FormValidation, required, phone } from '../../../components/form/FormValidation';
import FormGroup from '../../../components/form/FormGroup';
import EnToggle from '../../../components/form/EnToggle';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnButton from '../../../components/form/EnButton';
import EnRadio from '../../../components/form/EnRadio';
import AddressForm from '../../../components/address/AddressForm';
import liffHelper from '../../../utils/bestdeal/LiffHelper';
import GoogleMap from '../../../utils/bestdeal/GoogleMap';
import Loader from '../../../components/form/Loader';
import '../../../assets/css/loader.css';
// import '../../../utils/bestdeal/VConsole';

class RegisterStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      useCurrentLocation: false,
      currentLocation: {},
    };
    liffHelper.getProfile()
      .then(profile => {
        this.saveInfo('lineUserId', profile.userId);
        this.saveInfo('lineDisplayName', profile.displayName);
        this.saveInfo('linePictureUrl', profile.pictureUrl);
        this.props.bestdealStore.getStoreByLineUserId(profile.userId);
      });
    // .catch(() => {
    //   const mockLineUserId = 'U19ec7f5a0149fb37f27300677f802b55';
    //   this.saveInfo('lineUserId', mockLineUserId);
    //   this.saveInfo('lineDisplayName', 'profiledisplayName');
    //   this.saveInfo('linePictureUrl', 'profilepictureUrl');
    //   this.props.bestdealStore.getStoreByLineUserId(mockLineUserId);
    // });
  }

  saveInfo(key, value) {
    this.props.bestdealStore.saveInfo(key, value);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.coords) {
      const location = [nextProps.coords.longitude, nextProps.coords.latitude];
      const bestdealStore = this.props.bestdealStore.toJS();
      const item = bestdealStore.info;
      const isEdit = item.id && item.id.length;
      if (!isEdit) {
        this.setState({
          useCurrentLocation: this.state.useCurrentLocation,
          currentLocation: location,
        });
      }
    }
  }

  onTextChange(type, e) {
    const value = e.target.value;
    this.saveInfo(type, value);
  }

  onDeliveryClick(value) {
    this.saveInfo('delivery', value === 1);
  }

  onAddressChange = async (addressObj) => {
    const address = {
      subDistrict: {
        code: addressObj.dcode,
        name: addressObj.dname,
      },
      district: {
        code: addressObj.acode,
        name: addressObj.aname,
      },
      province: {
        code: addressObj.pcode,
        name: addressObj.pname,
      },
      postcode: addressObj.zcode,
    };
    this.saveInfo('address', address);
    if (!this.state.useCurrentLocation) {
      const location = await GoogleMap.getLocationFromAddress(address);
      if (location && location.status === 200 && location.data) {
        const results = location.data.results;
        if (results && results.length > 0) {
          const geoLocation = results[0].geometry.location;
          const location = [geoLocation.lng, geoLocation.lat];
          this.saveInfo('location', location);
        }
      }
    }
  }

  handleLocationChange({ position, address }) {
    const positionLngLat = [position.lng, position.lat];
    this.saveInfo('location', positionLngLat);
    this.setState({
      useCurrentLocation: _.isEqual(positionLngLat, this.state.currentLocation),
      currentLocation: this.state.currentLocation,
    });
  }

  onUseCurrentChange(value) {
    this.setState({ useCurrentLocation: value });
    if (value && !_.isEmpty(this.state.currentLocation)) {
      this.setState({
        useCurrentLocation: value,
        currentLocation: this.state.currentLocation,
      });
      this.saveInfo('location', this.state.currentLocation);
    }
  }

  sendMessageToUser() {
    return liffHelper.sendMessages({
      type: 'text',
      text: 'ลงทะเบียนเรียบร้อยแล้ว ขอบคุณที่ร่วมเป็นส่วนหนึ่งของเรา',
    });
  }

  async onSubmit() {
    const bestdealStore = this.props.bestdealStore.toJS();
    const item = bestdealStore.info;
    const isEdit = item.id && item.id.length;
    let parsed = querystring.parse(this.props.location.search);
    console.log(parsed);
    this.saveInfo('botIndex', parsed['bot_index'] || 0);
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: isEdit ? 'กรุณายืนยันการแก้ไขร้านค้า' : 'กรุณายืนยันการลงทะเบียนร้านค้าใหม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          if (isEdit) {
            await this.props.bestdealStore.updateStore(item.id);
          } else {
            await this.props.bestdealStore.createStore();
          }
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error.message}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลสำเร็จ',
        icon: 'success',
      });
      this.props.bestdealStore.clearInfo();
      if (isEdit) {
        this.onCancel();
      } else {
        this.sendMessageToUser().then(this.onCancel);
      }
    }
  }

  onCancel() {
    liffHelper.closeWindow();
  }

  render() {
    const bestdealStore = this.props.bestdealStore.toJS();
    const item = bestdealStore.info;
    const isEdit = item.id && item.id.length;
    const address = item.address;
    let addressSelector = {};
    if (address) {
      addressSelector = {
        subDistrict: address.subDistrict.name,
        subDistrictCode: address.subDistrict.code,
        district: address.district.name,
        districtCode: address.district.code,
        province: address.province.name,
        provinceCode: address.province.code,
        postCode: address.postcode,
      };
    }
    const deliveryOption = [
      {
        label: 'ไม่มี',
        value: 0,
      }, {
        label: 'มี',
        value: 1,
      },
    ];
    // const locationLabel = (
    //   <div>
    //     {item.location &&
    //       <span>{`ตำแหน่งของร้าน: [${item.location[1].toFixed(6)} , ${item.location[0].toFixed(6)}]`}</span>
    //     }
    //     <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //     <EnToggle
    //         checked={this.state.useCurrentLocation}
    //         label="ใช้ตำแหน่งปัจจุบัน"
    //         onChange={this.onUseCurrentChange.bind(this)}
    //       />
    //     </span>
    //   </div>
    // );
    const circleOptions = {
      fillColor: '#ffff00',
    };
    return (
      <div className="content">
        <Loader loading={bestdealStore.isLoading} />
        <div className="container-fluid">
          <div className="row">
            <div>
              <div className="card info">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">ลงทะเบียน</h4>
                  <p className="category">เป็นผู้จำหน่ายสินค้าร่วมกับเรา</p>
                </div>
                <div className="card-content">
                  <FormValidation ref="FormValidation">
                    <div className="row">
                      {/* <EnTextFormGroup id="userid" containerClassName="col-md-6" label="User Id" value={item.lineUserId} /> */}
                      <EnTextFormGroup id="name" containerClassName="col-md-6" label="ชื่อร้าน" value={item.name} onChange={this.onTextChange.bind(this, 'name')} validations={[required]} />
                    </div>
                    <div className="row">
                      <EnTextFormGroup id="contact" containerClassName="col-md-6" label="ชื่อผู้ติดต่อ" value={item.contact} onChange={this.onTextChange.bind(this, 'contact')} validations={[required]} />
                      <EnTextFormGroup id="mobile" containerClassName="col-md-6" label="เบอร์ติดต่อ" value={item.mobile} onChange={this.onTextChange.bind(this, 'mobile')} validations={[required, phone]} placeholder="08xxxxxxx" />
                    </div>
                    <div className="row">
                      <EnRadio containerClassName="col-md-6" label={'บริการจัดส่ง'} value={item.delivery ? 1 : 0} options={deliveryOption} onClick={this.onDeliveryClick.bind(this)} />
                    </div>
                    <h5>ระบุรหัสไปรษณีย์ และตำแหน่งของร้าน</h5>
                    <div className="row">
                      <AddressForm
                        displayFields={['ZIPCODE']}
                        addressData={addressSelector}
                        onAddressSelected={(addressObject) => this.onAddressChange(addressObject)}
                        validations={[required]} />
                      <FormGroup containerClassName={'col-xs-6'} label={'ใช้ตำแหน่งปัจจุบัน'}>
                        <EnToggle
                          checked={this.state.useCurrentLocation}
                          onChange={this.onUseCurrentChange.bind(this)} />
                      </FormGroup>
                    </div>
                    <div className="row">
                      {/*  <FormGroup containerClassName={'col-md-12'} label={locationLabel}> */}
                      <FormGroup containerClassName={'col-md-12'}>
                        <LocationPicker
                          containerElement={<div style={{ height: '100%' }} />}
                          mapElement={<div style={{ height: '300px' }} />}
                          defaultPosition={{
                            lat: item.location[1],
                            lng: item.location[0],
                          }}
                          zoom={12}
                          radius={5000}
                          circleOptions={circleOptions}
                          onChange={this.handleLocationChange.bind(this)}
                        />
                      </FormGroup>
                    </div>
                    <div className="text-center">
                      <EnButton className="btn-info" onClick={this.onSubmit.bind(this)}>{isEdit ? 'แก้ไขข้อมูล' : 'ลงทะเบียน'}</EnButton>
                    </div>
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

const geo = geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    watchPosition: true,
  },
  userDecisionTimeout: 15000,
});
export default inject('bestdealStore')(geo(observer(RegisterStore)));
