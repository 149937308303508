import moment from 'moment';
import momentTz from 'moment-timezone';
import * as _ from 'lodash';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Swal from 'sweetalert2';
import Select from 'react-select';
// import  from '../../utils/common';
import { common, customerReturn } from '../../utils/common';
import {
  TypeOfJobs,
  ProductList,
  AvailableTime,
  MarketPlaceHideButton,
  MarketPlaceCreditHideButton,
  RetailHideButton,
} from '../../utils/data';
import {
  FormValidation,
  required,
  phone,
  phone2,
} from '../../components/form/FormValidation';
import ModalReasonsWithEtc from '../../components/modal/ModalReasonsWithEtc';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import FormGroup from '../../components/form/FormGroup';
import EnButton from '../../components/form/EnButton';
import EnDropDown from '../../components/form/EnDropDown';
import EnDatePicker from '../../components/form/EnDatePicker';
import EnDateRangePicker from '../../components/form/EnDateRangePicker';
import EnNumeric from '../../components/form/EnNumeric';
import Notification from '../../components/common/Notification';
import datetime from '../../utils/datetime';
import history from '../../utils/history';
import {
  getDisplayStatusButton,
  toListItemData,
  initListItemHeaderOption,
  onUploadItemClick,
  getListItem,
  listItemHeaderOpts,
  getDropZone,
  doUploadFiles,
  onUploadComplete,
  uploadAndNextStep,
  onError,
  getLastFiles,
  nextState,
} from './step/stepCommon';
import ScrollButton from '../../components/common/ScrollButton';
import CallingButton from '../../components/common/CallingButton';
import AddressForm from '../../components/address/AddressForm';
import AdminShareStore from './AdminShareStore';
import authStore from '../../stores/AuthStore';
import config from '../../config';
import Autosuggest from 'react-autosuggest';
import Modal from 'react-responsive-modal';
import EnToggle from '../../components/form/EnToggle';
import { Col, Checkbox } from 'react-bootstrap/lib';
import LocationPicker from 'react-location-picker';
import { geocodeByAddress } from 'react-places-autocomplete';
// import { array } from 'prop-types';
import EnText from './../../components/form/EnText';
import Loader from '../../components/common/Loader';
import { Left } from 'react-bootstrap/lib/Media';
import EnTextAreaFormGroup from '../../components/form/EnTextAreaFormGroup';
import swal from 'sweetalert2';
import ModalAuth from '../../components/common/ModalAuth';
import { thaiDate } from '../../utils/datetime';
import { isAllow } from '../../utils/permission';
import { get } from 'lodash';
import http from '../../utils/http';

const theme = {
  container: {
    position: 'relative',
  },
  input: {
    width: '100%',
    height: 40,
    padding: '6px 12px',
    fontFamily: 'DB Helvethaica X',
    fontWeight: 300,
    fontSize: 16,
    border: '1px solid #ccc',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  inputFocused: {
    outline: 'none',
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: 'none',
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 51,
    width: 280,
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    fontFamily: 'DB Helvethaica X',
    fontWeight: 300,
    fontSize: 16,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
    zIndex: 2,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px',
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd',
  },
  activityLogTextArea: {
    display: 'block',
    height: '152px',
    overflowY: 'scroll',
    padding: '6px 12px',
    fontSize: '19.2px',
    lineHeight: 1.42857143,
    color: '#555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)'
  }
};

export class JobRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: undefined,
      isInstallerSuperAdmin: false,
      isNextStep: false,
      nextStep: 'installation_confirmed',
      files: 'files',
      showQuotationModal: false,
      shareable: false,
      orderId: undefined,
      orderInfo: undefined,
      jobTime: undefined,
      editTime: false,
      value: '',
      suggestions: [],
      contactInfo: [],
      openModal: true,
      isAsa: false,
      jobStartDate: undefined,
      promotionText: undefined,
      searchContractorQuery: '',
      searchContractorList: [],
      contractorDetail: undefined,
      previousRemark: '',
      currentRemark: '',
      employeeList: [],
      deletedJobReason: '',
      seller: {
        name: '',
        phone: '',
        code: '',
        branch: ''
      },
      modalAuthEvent: '',
      disableCustomerRemark: true, // For Validate customer remark box
      oldValueCustomerRemark: "",
      displaySearchContractorModal: false,
      isFinalJobOrInstallationJob: true,
      selectedContractorValue: null,
      availableContractorIds: [],
      trackingUrl: '',
    };
  }

  async componentWillMount() {
    const store = authStore.getStore();
    const channelName = this.props.auth.getChannelName();
    let employeeList = await this.props.employee.getAllEmployeeForChannel(channelName);
    employeeList = employeeList.filter((e) => (e.isActive === true && e.isAdmin === true));
    this.setState({ employeeList: employeeList });

    this.setState({
      isInstallerSuperAdmin: this.props.auth.isInstallerSuperAdmin()
    })

    let jobInfo = this.props.job.toJS().info;
    this.setState({ job: jobInfo })
    // get BU Store
    const buStoreId = jobInfo.bu_store_id;
    if (buStoreId) {
      const buStore = await this.props.admin.getStoreById(buStoreId);
      this.props.job.setInfo('buStore', buStore);
    }
    this.setState(
      { seller: jobInfo.seller ? jobInfo.seller : this.state.seller });
    let jobTime;
    const { superAdminMode } = this.props;
    if (jobInfo) {
      this.setState({ jobStartDate: jobInfo.startDate });
    }

    if (
      config.svCardTypeId &&
      config.svCardTypeId.includes(jobInfo.typeOfWork.values().next().value)
    ) {
      const typeOfWork = this.props.typeofwork.toJS();
      const result = typeOfWork.items.filter(
        (a) => a.value === jobInfo.typeOfWork.values().next().value
      );
      jobTime = result
        .values()
        .next()
        .value.time.map((item, idx) => {
          return { id: parseInt(item.value, 10), title: item.label };
        });
      this.setState({ jobTime: jobTime });
    }

    if (store && store.shareable) {
      if (store._id === jobInfo.storeId || this.props.isCreated) {
        this.setState({ shareable: true });
      }
    }

    /*if (jobInfo && jobInfo.storeId && jobInfo.refCode) {
      if (
        jobInfo.storeId === config.qchang.storeId ||
        (superAdminMode && jobInfo.storeId === config.qchang.storeId)
      ) {
        const checkPromotion = true;
        const orderInfo = await this.props.job.getOrderTracking(
          jobInfo.refCode,
          checkPromotion
        );
        if (!_.isNil(orderInfo) && !_.isEmpty(orderInfo)) {
          if (
            !_.isNil(orderInfo.promotion) &&
            !_.isEmpty(orderInfo.promotion) &&
            orderInfo.promotion_text
          ) {
            this.setState({ promotionText: orderInfo.promotion_text });
          }
          this.setState({ orderId: orderInfo._id, orderInfo });
        }
      }
    }*/
    if (this.props.workTypeId) {
      this.props.job.setInfo('typeOfWork', this.props.workTypeId);
    } else {
      this.props.job.setInfo('typeOfWork', jobInfo.typeOfWork[0]);
    }
    let isAsa = false;
    if (store) {
      isAsa = store._id === config.asa.storeId;
      this.setState({ isAsa: isAsa });
    }
    if ((superAdminMode || isAsa) && this.state.contactInfo.length === 0) {
      const contact = await this.props.contact.getAllContact();
      if (contact && contact.length) {
        this.setState({
          contactInfo: contact,
        });
      }
    }

    if (jobInfo && jobInfo.jobType === 'S') {
      const jobIns = await this.props.job.getInstallationJobInfo(
        jobInfo.jobCode,
        false
      );
      if (jobIns) {
        this.setState({ surveyQuotation: jobIns.quotation });
      }
    }
    this.setState({ selectedContractorValue: jobInfo.contractorId });

    const old_remark =
      jobInfo.old_remark && jobInfo.old_remark.length ? jobInfo.old_remark : [];

    let previous = old_remark.length ? old_remark[old_remark.length - 2] : '';
    let current = old_remark.length ? old_remark[old_remark.length - 1] : '';

    this.setState({ previousRemark: previous, currentRemark: current });

    if (jobInfo) {
      this.setState({ oldValueCustomerRemark: jobInfo.remark || "" });
      if (jobInfo.installationFromSurvey) {
        await this.getDraftNoByOtherData(jobInfo.jobCode);
        const quotationInfo = _.get(this.props.quotation.toJS(), 'quotationInfo');
        let hasFinal = false;
        if (_.get(quotationInfo, 'draft_quotation')) {
          hasFinal = quotationInfo.draft_quotation.find(draft => draft.is_final);
        }
        if (!hasFinal) {
          this.setState({ isFinalJobOrInstallationJob: false });
        }
      }
    }

    // Set default moment locale => TH
    moment.locale('th', thaiDate);
  }

  async componentDidMount() {
    if (this.props.initial && !this.props.isNotInitial) {
      await this.props.initial();
    }
    const jobInfo = this.props.job.toJS().info;
    const { superAdminMode } = this.props;
    if (jobInfo && jobInfo.typeOfWork && jobInfo.typeOfWork.length) {
      const typeId = jobInfo.typeOfWork;
      const data = await this.props.sku.getSkuByType(typeId.toString());
      const sku = data.toJS();
      if (jobInfo.sku) {
        this.props.job.setInfo('sku', jobInfo.sku);
      } else if (sku && sku.length) {
        this.props.job.setInfo('sku', '');
      }
    }


    await this.props.contractorblockedpayment.getLatestBlockedPaymentContractorHistory(
      jobInfo.contractorId
    );

    let jobTime;
    const info = this.props.job.toJS().info;
    if (
      config.svCardTypeId &&
      config.svCardTypeId.includes(info.typeOfWork.values().next().value)
    ) {
      const typeOfWork = this.props.typeofwork.toJS().items;
      const result = typeOfWork.filter((a) => a.value === info.typeOfWork[0]);
      if (result && result.length) {
        jobTime = result[0].time.map((item, idx) => {
          return { id: parseInt(item.value, 10), title: item.label };
        });
      }
      this.setState({ jobTime: jobTime });
    }

    // if (this.state.isAsa) {
    if (jobInfo.asa_data && jobInfo.asa_data.request_location) {
      this.updateGoogleMap(jobInfo.asa_data);
    } else {
      this.updateGoogleMap();
    }

    let subsidizePromotion;
    if (
      !(
        jobInfo.subsidizePromotion &&
        jobInfo.subsidizePromotion.is_paid === true
      ) &&
      (jobInfo.customer_approved || jobInfo.saleModel === 'Claim')
    ) {

      subsidizePromotion = await this.props.job.getSubsidizePromotion(
        jobInfo.id
      );
    }
    if (subsidizePromotion) {
      this.setState({ subsidize_amount: subsidizePromotion.subsidize_amount });
    }

    if (jobInfo && jobInfo.storeId && jobInfo.refCode) {
      if (
        jobInfo.storeId === config.qchang.storeId ||
        (superAdminMode && jobInfo.storeId === config.qchang.storeId)
      ) {
        const checkPromotion = false;
        const orderInfo = await this.props.job.getOrderTracking(
          jobInfo.refCode,
          checkPromotion
        );
        if (!_.isNil(orderInfo) && !_.isEmpty(orderInfo)) {
          this.setState({ orderId: orderInfo._id, orderInfo });
        }
      }
    }

    let trackingUrl = `${config.qchang.consumerWeb}/tracking/order/${this.state.orderId}?jobId=${jobInfo.id}`;
    if (jobInfo && jobInfo.jobType === 'S') {
      const jobI = await this.props.job.getInstallationJobInfo(
        jobInfo.jobCode,
        false
      );
      if (jobI) {
        trackingUrl = `${config.qchang.consumerWeb}/tracking/order/${this.state.orderId}?jobId=${jobI._id}`;
      }
    }
    this.setState({ trackingUrl: trackingUrl });

    await this.onCriteriaChange();
    await this.initAvailableContractorIds();
    await this.props.subContractor.findTeam(jobInfo.contractorId);
  }

  componentWillUnmount() {
    if (!this.props.isNotClear) {
      this.props.job.clearInfo();
    }
  }

  saveInfo(key, e) {
    switch (key) {
      case 'available':
        this.props.job.setInfo(key, e.value);
        this.onCriteriaChange();
        break;
      case 'typeOfWork':
      case 'jobType':
      case 'startDate':
      case 'endDate':
      case 'sku':
        if (e.target) {
          this.props.job.setInfo(key, e.target.value);
        } else {
          this.props.job.setInfo(key, e);
        }
        this.onCriteriaChange(key);
        break;
      default:
        if (e.target) {
          this.props.job.setInfo(key, e.target.value);
        } else {
          this.props.job.setInfo(key, e);
        }
        break;
    }
  }

  saveLandmark(e) {
    if (e.target) {
      this.props.job.saveLandmark(e.target.value);
    }
  }

  saveAddress(e) {
    if (e.target) {
      this.saveInfo('address', e);
    }
  }

  checkDayBlock(date) {
    if (this.props.checkDayBlock) {
      return this.props.checkDayBlock(date);
    } else {
      const { unavailableDate } = this.props;
      if (Array.isArray(unavailableDate) && unavailableDate.length) {
        const dateBlock = unavailableDate.find((u) =>
          datetime.isEqual(date, u)
        );
        return !!dateBlock;
      } else {
        return false;
      }
    }
  }

  checkOutsideRange(date) {
    if (this.props.checkOutsideRange) {
      return this.props.checkOutsideRange(date);
    } else {
      const filter = this.props.job.toJS().filter;
      return !datetime.isBetween(date, filter.minDate, filter.maxDate);
    }
  }

  getAvailableTime() {
    const jobInfo = this.props.job.toJS().info;
    if (jobInfo && !datetime.isEqual(jobInfo.startDate, jobInfo.endDate)) {
      return AvailableTime.filter((a) => a.id === 0);
    } else {
      if (
        jobInfo.timeLabel &&
        config.svCardTypeId &&
        config.svCardTypeId.includes(jobInfo.typeOfWork.values().next().value)
      ) {
        return this.state.jobTime;
      } else {
        return AvailableTime;
      }
    }
  }

  getContractors() {
    const { unavailableDate } = this.props;
    let { contractors } = this.props;
    if (Array.isArray(contractors) && contractors.length) {
      contractors = _.uniqBy(contractors, '_id');
      const jobInfo = this.props.job.toJS().info;
      return (
        this.props.job.mappingAvailableContractorByDate(
          contractors,
          unavailableDate,
          jobInfo.jobType
        ) || []
      );
    } else {
      return [];
    }
  }

  getAvailableMessage(availableContractors) {
    const { contractors } = this.props;
    if (contractors && Array.isArray(contractors) && contractors.length > 0) {
      return this.props.job.getMessage(contractors);
    } else {
      return '';
    }
  }

  async getTeamChangeDialogHtml(id1, id2) {
    let html = '';
    try {
      html =
        '<div classname="row"></br><label></label></br>' +
        '<select class="swal2-select" name="employee" id="' +
        id1 +
        '">' +
        '<option value="">--- ผู้ทำรายการ ---</option>';
      this.state.employeeList.map((e) => {
        html += '<option value="' + e.nicknameTh + '">' + e.nicknameTh + '</option>';
      });
      html +=
        '</select> </div><label>เหตุผล<label style="color:red">*</label></label><input id="' +
        id2 +
        '" class="swal2-input">';
    } catch (error) { }
    return html;
  }

  async getComplaintDialogHtml(inputOptions) {
    let radioHtml = '';
    for (let index in inputOptions) {
      const key = inputOptions[index].key;
      const value = inputOptions[index].value;
      radioHtml += `
      <div>
        <input type="radio" id="claim${key}" name="claim-radio" value="${key}">
        <label for="claim${key}">${key}. ${value}</label>
      </div>`;
    }
    let html = `
      <div>
        <div style="
          display: flex;
          flex-direction: column;
          width: 310px;
          text-align: left;
          margin: auto;
        ">`+
      radioHtml
      + `
        </div>
      </div>    
      ` + await this.getTeamChangeDialogHtml('submitBy', 'reason') +
      `</select></div><label>กรุณาแนบ Link URL Ticket<label style="color:red">*</label></label><input id="ticketUrl" class="swal2-input">`;
    html = html.replace('</br>', '');
    return html;
  }

  // For support customer remark case
  async getCustomerModal() {
    try {
      const html = await this.getTeamChangeDialogHtml('submitBy', 'reason');
      const { value: formValues } = await Swal.fire({
        title: 'ยืนยันการแก้ไขหมายเหตุจากลูกค้า',
        html,
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        allowOutsideClick: false,
        preConfirm: () => {
          const submitBy = document.getElementById('submitBy').value;
          const reason = document.getElementById('reason').value || '';

          if (reason.trim().length < 5 && submitBy) {
            Swal.showValidationMessage(`กรุณาระบุผู้ทำรายการและเหตุผล`);
            return false
          }

          if (submitBy && reason) {
            return [submitBy, reason];
          }
          Swal.showValidationMessage(`กรุณาระบุผู้ทำรายการและเหตุผล`);
          return false;
        },
      })
      if (formValues && formValues[0] && formValues[1]) {
        this.props.job.setInfo('changeCustomerRemark', {
          submit_by: formValues[0],
          reason: formValues[1],
        });

        this.onChangeCustomerBox()

        return 'success';
      }
    } catch (error) { }
  }

  async onChangeCustomerBox() {
    this.setState({ disableCustomerRemark: false })
  }

  async onTeamChanged(contractorId) {
    const job = this.props.job.toJS().info;
    this.setState({ selectedContractorValue: contractorId });

    if (job.creditProvider && contractorId !== job.original.contractorId) {
      swal.fire({
        icon: 'warning',
        title: 'แจ้งเตือนการเปลี่ยนช่าง',
        html: `ไม่สามารถเปลี่ยนทีมช่างได้ เนื่องจากมี Credit ซื้อของ ในระบบ<br> กรุณาติดต่อ ${job.creditProvider.submited_by} ผู้ทำรายการ Credit ซื้อของ`,
        confirmButtonText: 'ตกลง',
        preConfirm: async () => {
          history.go(0);
        },
      });

      return;
    }

    // ถ้ามีการเปลี่ยนทีมช่าง ให้ขึ้นถามเหตุผล
    if (contractorId && contractorId !== job.original.contractorId) {
      this.props.modal.setDisplayModalName('adminChangesContractor');
      this.saveInfo('contractorId', contractorId);
    }

    if (contractorId && contractorId === job.original.contractorId) {
      this.saveInfo('contractorId', contractorId);
    }

    if (!contractorId) {
      this.setState({ selectedContractorValue: '' });
      this.saveInfo('contractorId', '');
      this.saveInfo('available', { value: job.available });
    }

    // force clear contractorTeam
    this.props.job.setInfo('contractorTeam', {
      mainContractor: '',
      subContractor: [],
    })
  }

  onTeamChangedSaved(callbackState) {
    this.props.job.setInfo('changeContractor', {
      reason: callbackState.reason,
    });
    this.saveInfo('contractorId', this.state.selectedContractorValue);
    this.onCriteriaChange();
  }

  onTeamChangedCancel() {
    const job = this.props.job.toJS().info;
    this.setState({ selectedContractorValue: job.original.contractorId });
    this.saveInfo('contractorId', job.original.contractorId);
  }

  onDateChange(key, date) {
    if (key === 'startDate') {
      this.props.job.saveMultiInfo({
        startDate: date.toDate(),
        endDate: date.toDate(),
      });
      this.onCriteriaChange('DATE_CHANGED');
    } else {
      this.props.job.setInfo(key, date.toDate());
    }
  }

  onDateRangChange(startDate, endDate) {
    this.props.job.saveMultiInfo({
      startDate,
      endDate,
    });
    this.onCriteriaChange();
  }

  onCriteriaChange(eventName) {
    if (this.props.onCriteriaChange) {
      const jobInfo = this.props.job.toJS().info;
      const options = {
        eventName,
        workTypeId: jobInfo.typeOfWork[0],
        productId: jobInfo.typeOfWork.indexOf(1) >= 0 ? ProductList[0].id : '',
        jobType: jobInfo.jobType,
        available: jobInfo.available,
        startDate: jobInfo.startDate,
        endDate: jobInfo.endDate,
        contractorId: jobInfo.contractorId,
        districtCode: jobInfo.addressInfo.districtCode,
        skuCode: jobInfo.sku,
      };
      if (eventName === 'sku') {
        this.props.onSkuChange(options);
      } else {
        this.props.onCriteriaChange(options);
      }

      if (config.env === 'localhost') {
        this.props.job.setInfo('contactName', 'kookoo');
        this.props.job.setInfo('contactPhone', '0816087531');
      }
    }
  }

  initAvailableContractorIds = async () => {
    const { job: jobStore, unavailableDate } = this.props;
    const jobInfo = jobStore.toJS().info;
    const options = {
      workTypeId: jobInfo.typeOfWork[0],
      productId: jobInfo.typeOfWork.indexOf(1) >= 0 ? ProductList[0].id : '',
      jobType: jobInfo.jobType,
      available: jobInfo.available,
      startDate: jobInfo.startDate,
      endDate: jobInfo.endDate,
      districtCode: jobInfo.addressInfo.districtCode,
      skuCode: jobInfo.sku,
    };
    const { contractors } = await jobStore.getDataForAdminJobRequest(options);
    const availableContractors = jobStore.mappingAvailableContractorByDate(contractors, unavailableDate, jobInfo.jobType);
    const availableContractorIds = availableContractors.map(({ _id }) => _id);

    this.setState({ availableContractorIds });
  };

  onUploadError(err) {
    this.noti.error(err);
  }

  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  async getConfirmUpdateJob(_event, successMsg = 'บันทึกข้อมูลคิวช่างเรียบร้อย') {
    try {
      // set seller if has new Value
      // this.props.job.setInfo('seller', this.state.seller);
      this.props.job.setInfo('submitedBy', this.props.auth.getUsernameOrEmail());

      // check chang match jobs  case bug tb-2221
      const jobInfo = this.props.job.toJS().info;
      const getJobsAddress = _.get(jobInfo, 'addressInfo.districtCode',)
      const contractor = await this.props.contractor.getContractor({ id: jobInfo.contractorId, isAdmin: true })
      if (contractor) {
        const getWorkAreaOfContractor = _.get(contractor, 'area_of_work_approved', []).map(areaWork => _.get(areaWork, 'district_id', []).map(item => item))
        const flatWorkAreaOfContractor = getWorkAreaOfContractor.flat()
        const workAreaOfContractorMatchJobs = flatWorkAreaOfContractor.includes(getJobsAddress)
        if (!workAreaOfContractorMatchJobs) {
          return Swal.fire({ icon: 'error', title: "การเลือกทีมช่างมีปัญหา แอดมินโปรดตรวจสอบ", });
        }
      }
      await this.onSubmit(successMsg);
    } catch (error) {
      onError(error.message);
    }
  }

  async extendQuotationTime() {
    try {
      let jobInfo = this.props.job.toJS().info;
      await this.props.job.extendQuotationTime(jobInfo.id);
      this.noti.success("ต่ออายุใบเสนอราคาสำเร็จ");
    } catch (error) {
      onError(error.message);
    }
  }

  async onSubmit(successMsg) {
    try {
      // เพิ่ม validate ช่าง ว่าว่างจริงหรือไม่ก่อนจะให้ save ได้
      const jobInfo = this.props.job.toJS().info;
      if (await this.props.job.showJobUpdatedDialog(jobInfo)) {
        return;
      }
      const { contractors } = this.props;
      const selectedCon = contractors.filter(
        (c) => c._id.toString() === jobInfo.contractorId
      );
      if (selectedCon && selectedCon.length) {
        this.props.job.saveContractorTeamName(selectedCon[0].team_name);
      }
      this.manageJobOrderFiles(jobInfo);
      if (
        this.dropzone &&
        this.dropzone.getFiles() &&
        this.dropzone.getFiles().length
      ) {
        doUploadFiles(this.dropzone);
      } else {
        onUploadComplete(
          [],
          this.state,
          this.props.contractorMode,
          this.props.onSubmit
        );
      }
      if (this.state.oldValueCustomerRemark.trim() != (jobInfo.remark && jobInfo.remark.trim())) {
        // After submit should save customer Remark into Activity Log Ref.QCNS-2178
        const userSubmit = this.props.job.info.changeCustomerRemark.submit_by;
        const dateTimeFormat = moment().format('HH:mm DD/MM/YYYY');

        const remark = `[${dateTimeFormat}] หมายเหตุจากลูกค้า is edited from "Job : [${this.state.oldValueCustomerRemark}] to [${jobInfo.remark}]" by [${userSubmit}]`;
        const userReason = this.props.job.info.changeCustomerRemark.reason;

        const activityLog = this.saveModelEventLogs(`Update_Customer_Remark`,
          jobInfo.jobCode,
          remark,
          { oldValue: this.state.oldValueCustomerRemark, newValue: jobInfo.remark || "", userSubmit, userReason, date: new Date() },
        )
        // Should check add this new value into setInfoLogActivity first before call API 
        this.props.permission.setInfoLogActivity([activityLog])
        // For save call API
        this.props.permission.saveUamActivityLogs();
        this.props.job.sendNotiToCustomer();
      }
      if (successMsg) this.noti.success(successMsg);
    } catch (error) {
      onError(error.message);
      throw error;
    }
  }

  activityLogInTextarea() {
    let { activity_logs } = this.props.job.toJS().info;
    let results = [];
    if (activity_logs) {
      results = activity_logs
        .filter(function (log) {
          if (log.remark != '') return log;
        })
        .map((log) => {
          return log.remark;
        })
        .join('<br />')
    }
    return results;
  }

  manageJobOrderFiles(jobInfo) {
    if (!jobInfo.contractorId) {
      jobInfo.po_survey = [];
      jobInfo.po_installation = [];
    }
  }

  onEditTime() {
    this.setState({ editTime: true });
  }

  async handleModalAuth(event, payload = "") {
    if (event === 'confirmPaid') {
      if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
        return;
      }
      this.confirmPaid(payload)
    } else if (event === 'onDeleteJobByPasscode') {
      this.onDeleteJobByPasscode()
    } else {
      alert("not found event");
    }
  }

  async onDeleteJobByPasscode() {
    const status = this.props.permission.getUserFromEmail();
    if (!status) {
      return;
    }
    this.props.permission.setShowModal(false);
    const { userName } = this.props.permission.toJS();
    this.props.onDelete(this.state.deletedJobReason, userName);
    this.logActivityDeleteJob(this.props.job.info.jobCode, this.state.deletedJobReason);
  }

  logActivityDeleteJob(jobCode, reason) {
    const { userName } = this.props.permission.toJS();
    const activityLogs = [];
    activityLogs.push(
      this.saveModelEventLogs(
        `delete_job`,
        jobCode,
        `job_code (${jobCode}) is deleted by (${userName}) with reason (${reason})`
      )
    );
    this.props.permission.setInfoLogActivity(activityLogs);
    this.props.permission.saveUamActivityLogs();
  }

  logActivityConfirmPaid(jobId, jobCode, typeOfJob, user) {
    const initInfo = {};
    initInfo.job_id = jobId;
    initInfo.event = "admin_confirm_payment";
    initInfo.function_name = "jobs";
    initInfo.reference_code = jobCode;
    initInfo.type_of_job = typeOfJob;
    initInfo.user = user;
    initInfo.remark = `[${momentTz().utcOffset(7).format('HH:mm DD/MM/YYYY')}] : ${user} ได้ทําการยืนยันการชำระเงิน`;
    this.props.permission.setInfoLogActivity([initInfo]);
    this.props.permission.saveUamActivityLogs();
  }

  logActivityComplaint(jobCode, claimRadioValue, reason, linkTicket, submitBy) {
    const initInfo = {};
    initInfo.event = "claim";
    initInfo.function_name = "jobs";
    initInfo.type_of_job = "S / I";
    initInfo.reference_code = jobCode;
    initInfo.user_reason = reason;
    initInfo.link_ticket = linkTicket;
    initInfo.user = submitBy;
    initInfo.remark = `
      [${momentTz().utcOffset(7).format('HH:mm DD/MM/YYYY')}] : Admin ${submitBy} ได้ทําการ Claim
      แบบที่ ${claimRadioValue} ด้วยเหตุผล ${reason} <a href="${initInfo.link_ticket}">${linkTicket}</a>
    `;
    this.props.permission.setInfoLogActivity([initInfo]);

    this.props.permission.saveUamActivityLogs();
  }

  async onDelete() {
    if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
      return;
    }
    Swal.fire({
      title: 'ยืนยันการลบงาน',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (!value || value.length < 5) {
          return '* กรุณากรอกสาเหตุในการลบงาน'
        }
      },
      preConfirm: async (reason) => {
        try {
          if (reason) {
            this.setState({
              deletedJobReason: reason,
            });
            this.handleModalAuth("onDeleteJobByPasscode")
          }
          return true;
        } catch (error) {
          throw error;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  }
  onApproveChanged(job, checked) {
    checked = !checked;
    let text = checked
      ? 'คุณต้องการอนุมัติเตรียมการจ่ายเงินช่าง <br /> ของงานนี้ใช่หรือไม่?'
      : 'คุณต้องการระงับเตรียมการจ่ายเงินช่าง <br /> ของงานนี้ใช่หรือไม่?';
    Swal.fire({
      title: text,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
    }).then(async (result) => {
      if (result.value) {
        if (!checked && !(job.adminApprovePaid === false)) {
          const dateTimeFormat = moment().format('HH:mm DD/MM/YYYY');
          const reason = "Action by System";
          const activityLog = {
            event: "admin_approve_paid",
            action: "close",
            function_name: "jobs",
            job_id: job.id,
            reference_code: job.jobCode,
            type_of_job: job.jobType,
            created_date: dateTimeFormat,
            user: this.props.auth.getUsernameOrEmail(),
            reason: reason,
            remark: `[${dateTimeFormat}] : ${this.props.auth.getUsernameOrEmail()} ได้ทำการปิดให้จ่ายเงินช่างได้ เนื่องจาก ${reason}`
          }
          this.props.permission.setInfoLogActivity([activityLog]);
          this.props.permission.saveUamActivityLogs();

          await this.props.onContinueOrBreakJob(job.id, checked, false)
          await this.onChangeAdminApprovePaid(job, false)
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          await this.props.onContinueOrBreakJob(job.id, checked);
        }
      }
    });
  }

  async onChangeAdminApprovePaid(job, doReload = true) {
    try {
      const flag = !!(job.adminApprovePaid === false);
      await this.props.job.updateFlagAdminApprovePaid(job.id, flag);
      this.noti.success('บันทึกข้อมูลคิวช่างเรียบร้อย');
      if (doReload) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      this.noti.error(error.message);
    }
  }

  async onToggleAdminApprovePaidClick(job, checked) {
    const isSuperadmin = this.props.auth.isSuperAdmin();
    if (!isSuperadmin && checked) {
      swal.fire({
        icon: 'question',
        title: 'ขออภัยสิทธิ์ของท่านไม่สามารถใช้เปิดให้จ่ายเงินช่างได้',
      })
    } else {
      const flag = !!(job.adminApprovePaid === false);
      let confirmDialogOptions = {
        title: flag ? 'ยืนยันเปิดการจ่ายเงิน' : 'ยืนยันระงับการจ่ายเงิน',
        input: 'text',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: 'font-size-200',
        showLoaderOnConfirm: true,
        onOpen: function () {
          Swal.disableButtons();
          Swal.getInput().addEventListener('keyup', function (e) {
            const value = e.target.value;
            if (value.trim() === '' || value.length < 5) {
              Swal.disableButtons();
            } else {
              Swal.enableButtons();
            }
          })
        },
        preConfirm: async (reason) => {
          try {
            if (reason) {
              const dateTimeFormat = moment().format('HH:mm DD/MM/YYYY');
              const activityLog = {
                event: "admin_approve_paid",
                action: flag ? "open" : "close",
                function_name: "jobs",
                job_id: job.id,
                reference_code: job.jobCode,
                type_of_job: job.jobType,
                created_date: dateTimeFormat,
                user: this.props.auth.getUsernameOrEmail(),
                reason: reason,
                remark: flag ?
                  `[${dateTimeFormat}] : ${this.props.auth.getUsernameOrEmail()} ได้ทำการเปิดให้จ่ายเงินช่างได้ เนื่องจาก ${reason}` :
                  `[${dateTimeFormat}] : ${this.props.auth.getUsernameOrEmail()} ได้ทำการปิดให้จ่ายเงินช่างได้ เนื่องจาก ${reason}`
              }
              this.props.permission.setInfoLogActivity([activityLog]);
              this.props.permission.saveUamActivityLogs();
              await this.onChangeAdminApprovePaid(job, true);
            }
            return true;
          } catch (error) {
            throw error;
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      };
      await Swal.fire(confirmDialogOptions);
    }
  }

  async onUploadAndNext(nextStatus) {
    try {
      this.setState({
        isNextStep: true,
      });
      uploadAndNextStep(this.dropzone, nextStatus, this.props.contractorMode);
    } catch (error) {
      onError(error.message);
    }
  }

  changeAddressSet = async (value) => {
    const jobInfo = this.props.job.toJS().info;
    this.props.job.saveAddressData(value);
    this.onCriteriaChange('FILTER_CODE');
    this.updateGoogleMap();
    if (!jobInfo.contractorId) return

    const contractor = await this.props.contractor.getContractor({ id: jobInfo.contractorId, isAdmin: true })
    const getWorkAreaOfContractor = _.get(contractor, 'area_of_work_approved', []).map(areaWork => _.get(areaWork, 'district_id', []).map(item => item))
    const flatWorkAreaOfContractor = getWorkAreaOfContractor.flat()
    const workAreaOfContractorMatchJobs = flatWorkAreaOfContractor.includes(value.acode)
    if (workAreaOfContractorMatchJobs) return

    Swal.fire({
      icon: 'error',
      title: "ช่างคนเดิมไม่รับงานในพี้นที่นี้ แอดมินกรุณาเปลี่ยนช่างทีมใหม่",
    });

    this.saveInfo('contractorId', '');
    this.onCriteriaChange('LIST CHANG MATH JOBS');
  };

  showAddressForm = () => {
    if (this.props.isCreated) {
      return true;
    } else if (this.props.isUpdated) {
      return true;
    } else {
      return false;
    }
  };

  // Add new optional value for support case old and new value Ref QCNS-2179
  saveModelEventLogs(event, referenceCode, remark, ...values) {
    const dataPermission = {}
    dataPermission.event = event
    dataPermission.function_name = event
    dataPermission.reference_code = referenceCode
    dataPermission.remark = remark

    // For check data values (optional) have been include oldValue or newValue
    const customerRemarkActivity = values.reduce((prev, current) =>
    ({
      ...{ oldValue: current['oldValue'] },
      ...{ newValue: current['newValue'] },
      ...{ userSubmit: current['userSubmit'] },
      ...{ userReason: current['userReason'] },
      ...{ date: current['date'] },
    }), {})

    // If doesn't have old and new value shold send empty string
    dataPermission.old_value = customerRemarkActivity.oldValue || ""
    dataPermission.new_value = customerRemarkActivity.newValue || ""
    dataPermission.user_submit = customerRemarkActivity.userSubmit || ""
    dataPermission.user_reason = customerRemarkActivity.userReason || ""
    dataPermission.update_date = customerRemarkActivity.date || ""

    return dataPermission
  }

  async confirmPaid(payload) {
    const { jobId, jobCode, typeOfJob, jobQuotation } = payload;
    const paidByCustomer = {
      text: 'จำนวนเงินที่ลูกค้าจ่าย',
      amount: 0
    };
    const payToContractor = {
      text: 'จำนวนเงินจ่ายช่าง',
      amount: 0
    };
    const qChangProfit = {
      text: 'คิวช่างได้รับกำไรคิดเป็น',
      percent: 0
    };
    const styleDisplayGrid = `
      display: grid;
      grid-template-columns: 1fr 120px;
      gap: 20px;
    `;
    const styleBodyDialog = `
      width: 330px;
      margin: auto;
      font-size: 23px;
      padding-top: 10px;
    `;
    const remark = `[${jobId}] is confirmed payment by `
    this.props.permission.setInfoLogActivity([this.saveModelEventLogs(`confirmed_payment`, jobId, remark)])
    const status = this.props.permission.getUserFromEmail();
    if (!status) {
      return
    }
    try {
      if (Array.isArray(jobQuotation) && jobQuotation.length > 0) {
        paidByCustomer.amount = jobQuotation[0].grand_total;
        for (let quotationItem of jobQuotation[0].items) {
          payToContractor.amount += quotationItem.contractor_amount;
        }
        qChangProfit.percent = (paidByCustomer.amount - payToContractor.amount) / paidByCustomer.amount * 100;
        qChangProfit.percent = Math.round((qChangProfit.percent * 100) / 100).toFixed(2)
      }
      const confirmDialogOptions = {
        icon: 'question',
        title: `ยืนยันการชำระเงิน ?`,
        html: `
        <div style="${styleBodyDialog}">
          <div style="${styleDisplayGrid}">
            <div style="text-align: left;">
              ${paidByCustomer.text}
            </div>
            <div style="text-align: right;">
              ${paidByCustomer.amount.toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })} บาท
            </div>
          </div>
          <div style="${styleDisplayGrid}">
            <div style="text-align: left;">
              ${payToContractor.text}
            </div>
            <div style="text-align: right;">
              ${payToContractor.amount.toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })} บาท
            </div>
          </div>
          <div style="${styleDisplayGrid}">
            <div style="text-align: left;">
              ${qChangProfit.text}
            </div>
            <div style="text-align: right;">
              ${qChangProfit.percent} %
            </div>
          </div>
        </div>`,
        footer:
          '<i style="color: rgb(221, 51, 51);">เมื่อกดยืนยันแล้วจะไม่สามารถยกเลิกได้<i>',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: 'font-size-200',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            this.setState({
              isNextStep: true,
            });
            this.saveInfo('is_paid', true);
            // await this.getConfirmUpdateJob()
            await this.onSubmit();
            return true;
          } catch (error) {
            throw error;
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      };
      const result = await Swal.fire(confirmDialogOptions);
      if (result.value) {
        Swal.fire({
          icon: 'success',
          title: `ยืนยันการชำระเงินเรียบร้อย`,
        });
        this.logActivityConfirmPaid(jobId, jobCode, typeOfJob, this.props.auth.getUsernameOrEmail())
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: `${error.message}`,
      });
    }
  }


  async loadCustomerInfoClick() {
    try {
      await this.props.job.loadCustomerInfoByPhone();
      this.onCriteriaChange('FILTER_CODE');
      this.updateGoogleMap();
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: `${err.message}`,
      });
    }
  }

  async gotoCreateQuotationPage() {
    const jobInfo = this.props.job.toJS().info;
    const { id } = jobInfo;
    await this.props.job.showJobUpdatedDialog(
      jobInfo,
      () => history.push(`${config.publicUrl}/superadmin/jobs/${id}/quotation?create=true`)
    );
  }

  async getDraftNoByOtherData(jobCode) {
    let quotationInfo = _.get(this.props.quotation.toJS(), 'quotationInfo');
    if (!(quotationInfo.main_quotation && quotationInfo.draft_quotation)) {
      quotationInfo = await this.props.quotation.getOptionQuotation(jobCode);
    }
    const mainNetTotal = _.get(quotationInfo, 'main_quotation.0.net_total');
    const mainItemsLength = _.get(quotationInfo, 'main_quotation.0.items.length');
    const draftQuotations = quotationInfo.draft_quotation;
    if (mainNetTotal && mainItemsLength && Array.isArray(draftQuotations)) {
      for (let draftQuotation of draftQuotations) {
        if (
          draftQuotation.net_total === mainNetTotal &&
          _.get(draftQuotation, 'items.length') === mainItemsLength
        ) {
          return draftQuotation.draft_no;
        }
      }
    }
    return null;
  }

  async gotoQuotationPage() {
    const jobInfo = this.props.job.toJS().info;
    const { id, installment, jobCode } = jobInfo;
    const quotationNo = _.get(_.last(jobInfo.quotation), 'no');
    let redirectUrl = `${config.publicUrl}/superadmin/jobs/${id}/quotation?quotationNo=${quotationNo}`;
    if (installment) {
      const draftNo = await this.getDraftNoByOtherData(jobCode);
      redirectUrl = redirectUrl + `&draftNo=${draftNo}&isFinal=true`;
    }
    await this.props.job.showJobUpdatedDialog(
      jobInfo,
      () => history.push(redirectUrl)
    );
  }

  async gotoInvoicePage() {
    const jobInfo = this.props.job.toJS().info;
    const { id } = jobInfo;
    await this.props.job.showJobUpdatedDialog(
      jobInfo,
      () => history.push(`${config.publicUrl}/superadmin/jobs/${id}/invoice`)
    );
  }

  gotoClaimPage() {
    const { id } = this.props.job.toJS().info;
    history.push(`${config.publicUrl}/superadmin/jobs/${id}/claim`);
  }

  async gotoCreditProviderPage() {
    const jobInfo = this.props.job.toJS().info;

    // QBC-1446: check other expense
    if (jobInfo.otherExpense && jobInfo.otherExpense.total_expense > 0) {
      Swal.fire({
        icon: 'warning',
        title: "มีรายการหักค่าใช้จ่ายอยู่ก่อนแล้ว",
        text: `เลือก "ตกลง" หากต้องการใช้ Credit ซื้อของ`,
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.goToCreditProvider(jobInfo);
        }
      })
    } else {
      this.goToCreditProvider(jobInfo);
    }
  }

  goToCreditProvider(jobInfo) {
    this.props.job.showJobUpdatedDialog(
      jobInfo,
      () => history.push(`${config.publicUrl}/superadmin/jobs/${jobInfo.id}/creditProvider`)
    );
  }

  async gotoJoExpensePage() {
    const jobInfo = this.props.job.toJS().info;
    const { id } = jobInfo;
    history.push(`${config.publicUrl}/superadmin/jobs/${id}/joExpense`)
  }

  async gotoContractorReceiptPage() {
    const jobInfo = this.props.job.toJS().info;
    const { id } = jobInfo;
    await this.props.job.showJobUpdatedDialog(
      jobInfo,
      () => history.push(`${config.publicUrl}/superadmin/jobs/${id}/contractorReceipt`)
    );
  }

  async gotoCreditSalePage() {
    const jobInfo = this.props.job.toJS().info;
    const { id } = jobInfo;
    await this.props.job.showJobUpdatedDialog(
      jobInfo,
      () => history.push(`${config.publicUrl}/superadmin/jobs/${id}/creditSale`)
    );
  }

  async gotoManagePoPage() {
    const jobInfo = this.props.job.toJS().info;
    const { id } = jobInfo;
    await this.props.job.showJobUpdatedDialog(
      jobInfo,
      () => history.push(`${config.publicUrl}/superadmin/jobs/${id}/po`)
    );
  }

  async gotoCreateGuaranteePage() {
    const jobInfo = this.props.job.toJS().info;
    const { id } = jobInfo;
    const getGuaranteeData = await this.props.guarantee.getGuaranteeByFilter({ saleOrderCode: jobInfo.saleOrderCode });
    if (getGuaranteeData) {
      await this.props.job.showJobUpdatedDialog(
        jobInfo,
        () => history.push(`${config.publicUrl}/superadmin/jobs/${id}/guarantee`)
      );
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'สินค้านี้ไม่มีใบรับประกัน',
        html: `
        <div>
          <p> หากต้องการออกใบรับประกัน กรุณาเลือกใบรับประกัน </p>
          <p> สำหรับ<b>สินค้า</b>ที่<b>เมนูสินค้า</b>ให้เรียบร้อยก่อนจึงจะสามารถออกใบรับประกันได้ </p>
        </div>
        `
      });
    }
  }

  handleTypeOfWork = async (e, key) => {
    if (e && e.target) {
      const value = e.target.value;
      this.props.job.setInfo('sku', '');
      this.props.job.setInfo('typeOfWork', value);
    }
    this.onCriteriaChange();
  };

  validateButton = (superAdminMode, contractorMode, store, jobInfo, key) => {
    if (key === 'quotation') {
      return (
        !contractorMode &&
        ((store && store.quotation_require) || superAdminMode) &&
        jobInfo.contractorId
      );
    } else if (key === 'receipt') {
      return (
        !contractorMode &&
        jobInfo &&
        jobInfo.is_paid &&
        ((store && store.receipt_require) || superAdminMode)
      );
    }
    return false;
  };

  showDocumentButton(
    buttonType,
    requiredButton,
    isSuperAdminMode,
    isStoreMode,
    isJobShare,
    jobInfo
  ) {
    let isShowButton = false;
    switch (buttonType) {
      case 'quotation':
        if (
          requiredButton &&
          !isJobShare &&
          (isSuperAdminMode || isStoreMode) &&
          jobInfo.jobType === 'I' &&
          jobInfo.original
        ) {
          isShowButton = true;
        }
        break;
      case 'po':
        if (
          requiredButton &&
          !isJobShare &&
          (isSuperAdminMode || isStoreMode) &&
          jobInfo.jobType === 'S' &&
          jobInfo.cost &&
          jobInfo.cost.survey &&
          jobInfo.sku &&
          jobInfo.po_survey
        ) {
          isShowButton = true;
        }
        break;
      case 'reciept':
        if (
          requiredButton &&
          !isJobShare &&
          (isSuperAdminMode || isStoreMode) &&
          jobInfo.is_paid
        ) {
          isShowButton = true;
        }
        break;
      default:
        isShowButton = false;
        break;
    }
    return isShowButton;
  }

  conChageTime(e) {
    this.saveInfo('available', e);
    this.props.job.setInfo('timeLabel', e.label);
    this.setState({ editTime: false });
  }

  getAvailableHtml(jobInfo, availableTime) {
    let data;
    const { contractorMode } = this.props;
    let disabled = contractorMode === true;
    if (
      config.svCardTypeId &&
      config.svCardTypeId.includes(jobInfo.typeOfWork.values().next().value)
    ) {
      if (this.state.editTime) {
        data = (
          <FormGroup containerClassName='col-md-12' label='ช่วงเวลา'>
            <Select
              disabled={disabled || jobInfo.customer_approved || this.props.statusClaim}
              multi={false}
              searchable={false}
              clearable={false}
              value={jobInfo.available}
              options={availableTime}
              onChange={this.conChageTime.bind(this)}
            />
          </FormGroup>
        );
      } else {
        data = (
          <div className='row'>
            <FormGroup containerClassName='col-md-12'>
              <EnTextFormGroup
                id='available'
                disabled={disabled || jobInfo.customer_approved || this.props.statusClaim}
                containerClassName='col-xs-9'
                label='ช่วงเวลา'
                value={jobInfo.timeLabel ? jobInfo.timeLabel : common.getAvailableTimeText(jobInfo.available)}
              />
              <EnButton
                disabled={disabled || jobInfo.customer_approved}
                id='edit-button'
                className='col-xs-2 btn-warning pull-right'
                onClick={this.onEditTime.bind(this)}
                style={{ marginTop: '35px' }}
              >
                <i className='fa fa-pencil btn-icon' aria-hidden='true' />
                แก้ไข
              </EnButton>
            </FormGroup>
          </div>
        );
      }
    } else {
      data = (
        <FormGroup containerClassName='col-md-12' label='ช่วงเวลา'>
          <Select
            disabled={disabled || jobInfo.customer_approved || this.props.statusClaim}
            multi={false}
            searchable={false}
            clearable={false}
            value={jobInfo.available}
            options={availableTime}
            onChange={this.conChageTime.bind(this)}
          />
        </FormGroup>
      );
    }
    return data;
  }

  async onSearchContractor() {
    if (
      this.state.searchContractorQuery &&
      this.state.searchContractorQuery.length > 2
    ) {
      const searchResult = await this.props.job.getSearchContractorList(
        this.state.searchContractorQuery
      );
      this.setState({ searchContractorList: searchResult });
    }
  }

  async onSearchContractorDetail(contractor) {
    if (contractor && contractor._id) {
      const jobInfo = this.props.job.toJS().info;
      const { result: searchResult, returnContractor } = await this.props.job.getContractorDetail(jobInfo, contractor);
      // console.log('returnContractor',returnContractor)
      const grade = this.findGradeByTypeOfWork(returnContractor.grade, jobInfo.typeOfWork)
      let newContractor = {
        ...returnContractor,
        grade_for_job: grade,
        typeOfWorkText: contractor.typeOfWorkText
      }
      this.setState({ contractorDetail: { contractor: newContractor, searchResult } });
    }
  }

  changeQuerySearchContractor(key, value) {
    this.setState({ searchContractorQuery: value });
  }

  onViewContractorDetail(item) {
    if (item) {
      this.onSearchContractorDetail(item);
    }
  }

  reloadContractorDetail() {
    if (this.state.contractorDetail && this.state.contractorDetail.contractor) {
      this.onSearchContractorDetail(this.state.contractorDetail.contractor);
    }
  }

  getContractorListDisplay() {
    const { availableContractorIds, selectedContractorValue: selectedContractorId } = this.state;
    const jobInfo = this.props.job.toJS().info;
    let typeOfWorkText = ''
    let typeOfWorks = this.props.typeofwork.mapIdAndLabel();
    typeOfWorks.forEach((item, idx) => {
      if (item.id === jobInfo.typeOfWork[0]) {
        typeOfWorkText = item.label
      }
    });
    let items = [];
    if (
      this.state.searchContractorList &&
      this.state.searchContractorList.length
    ) {
      items = (this.state.searchContractorList || []).map((item, index) => {
        const isEnabledSelectBtn = availableContractorIds.includes(item._id) && item._id !== selectedContractorId;
        const grade = this.findGradeByTypeOfWork(item.grade, jobInfo.typeOfWork)
        let newItem = {
          ...item,
          grade_for_job: grade,
          typeOfWorkText: typeOfWorkText
        }
        return (
          <tr key={item._id}>
            <td> ชื่อ : {item.first_name + ' ' + item.last_name}<br />
              ทีม : {item.team_name}<br />
              เกรด : {grade || 'New'}<br />
              ประเภทงาน : {typeOfWorkText}<br />
              เบอร์โทรศัพท์ : {item.phone}
            </td>
            <td className="text-center">
              <EnButton
                id="open-detail-contractor"
                className="default-button"
                data-target="#viewContractorModal"
                data-toggle="modal"
                onClick={this.onViewContractorDetail.bind(this, newItem)}
              >
                ดูเพิ่มเติม
              </EnButton>
              <EnButton
                className="primary-button"
                data-target="#searchContractorModal"
                data-toggle="modal"
                disabled={!isEnabledSelectBtn}
                style={{ marginTop: 8 }}
                onClick={() => this.onTeamChanged(item._id)}
              >
                เลือก
              </EnButton>
            </td>
          </tr>
        );
      });
    }
    return items;
  }

  getContractorDetailDisplay() {
    let items = [];
    if (this.state.contractorDetail) {
      const { contractor, searchResult } = this.state.contractorDetail;
      if (contractor && searchResult) {
        items = (
          <tr key={contractor._id}>
            <td> ชื่อ : {contractor.first_name + ' ' + contractor.last_name}<br />
              ทีม : {contractor.team_name}<br />
              เกรด : {contractor.grade_for_job || 'New'}<br />
              ประเภทงาน : {contractor.typeOfWorkText || 'New'}<br />
              เบอร์โทรศัพท์ : {contractor.phone}
            </td>
            <td className="text-center">
              {searchResult.map((e, index) => {
                return (<div key={index} style={{ textAlign: 'left' }}><span>- {e}</span><br /></div>);
              })}
            </td>
          </tr>
        );
      }
    }
    return items;
  }

  async onClickSearchContractorButton() {
    if (!this.state.displaySearchContractorModal) {
      let showJobDialog = await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info);
      if (!showJobDialog) {
        this.setState({
          displaySearchContractorModal: true,
        });
        document.getElementById("open-search-contractor").click();
      }
    } else {
      this.setState({
        displaySearchContractorModal: false,
      });
    }
  }

  getsearchContractorHtml() {
    const { availableContractorIds, contractorDetail, selectedContractorValue: selectedContractorId } = this.state;
    const jobInfo = this.props.job.toJS().info;
    let data;
    let query = this.state.searchContractorQuery;
    let contractorDetailElement = this.getContractorDetailDisplay();
    let items = this.getContractorListDisplay();
    const contractorId = get(contractorDetail, 'contractor._id');
    const isEnabledSelectBtn = availableContractorIds.includes(contractorId) && contractorId !== selectedContractorId;

    data = (
      <div className='row'>
        <EnButton
          id='open-search-contractor'
          className='btn-primary btn-quotation  btn-search'
          data-toggle={this.state.displaySearchContractorModal ? 'modal' : ''}
          data-target='#searchContractorModal'
          disabled={jobInfo.customer_approved || this.props.statusClaim}
          onClick={() => {
            this.clearSearchContractor();
            this.onClickSearchContractorButton();
          }}
        >
          <i className='fa fa-file-text-o btn-icon' aria-hidden='true' />
          ค้นหาทีมช่าง
        </EnButton>

        <div className='modal fade' id='searchContractorModal' role='dialog'>
          <div className='modal-dialog modal-lg'>
            {/* <!-- Modal content--> */}
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal'>
                  &times;
                </button>
                <h4 className='modal-title'>ค้นหาทีมช่าง</h4>
              </div>
              <div className='modal-body'>
                <FormGroup label='ค้นหา:' containerClassName='col-md-10'>
                  <div className='input-group'>
                    <div className='form-group has-feedback has-clear'>
                      <EnText
                        className='form-control'
                        value={query || ''}
                        onKeyPress={async (e) => {
                          if (e.key === 'Enter') {
                            await this.initAvailableContractorIds();
                            this.onSearchContractor();
                          }
                        }}
                        onChange={(e) => {
                          this.changeQuerySearchContractor('q', e.target.value);
                        }}
                        placeholder='ค้นหาด้วย ชื่อ ชื่อทีม หรือเบอร์โทร อย่างน้อย 3 ตัวอักษร'
                      />
                    </div>
                    <span className='input-group-btn'>
                      <button
                        id='search-button'
                        type='button'
                        className='btn btn-primary btn-outline-secondary btn-custom'
                        onClick={async () => {
                          await this.initAvailableContractorIds();
                          this.onSearchContractor();
                        }}
                      >
                        <i className='fa fa-search' aria-hidden='true' />
                      </button>
                    </span>
                  </div>
                </FormGroup>
              </div>
              <div
                className='row'
                style={{ paddingLeft: '15px', paddingRight: '15px' }}
              >
                <div className='col-md-12'>
                  <div className='table-responsive'>
                    <table className='table table-bordered table-hover has-pagination'>
                      <thead>
                        <tr>
                          <th width='70%' className='text-center'>
                            ชื่อ-นามสกุล / ชื่อทีม
                          </th>
                          <th width='10%' className='text-center'>
                            ดูข้อมูล
                          </th>
                        </tr>
                      </thead>
                      <tbody>{items}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='modal' id='viewContractorModal' role='dialog'>
          <div className='modal-dialog modal-lg'>
            <div className='modal-content'>
              <div className='modal-header'>
                <button type='button' className='close' data-dismiss='modal'>
                  &times;
                </button>
                <h4 className='modal-title'>รายละเอียดเพิ่มเติม</h4>
              </div>
              <div className='modal-body'>
                <div
                  className='row'
                  style={{ paddingLeft: '15px', paddingRight: '15px' }}
                >
                  <div className='col-md-12'>
                    <div className='table-responsive'>
                      <table className='table table-bordered table-hover has-pagination'>
                        <thead>
                          <tr>
                            <th width='40%' className='text-center'>
                              ชื่อ-นามสกุล / ชื่อทีม
                            </th>
                            <th width='60%' className='text-center'>
                              รายละเอียด
                            </th>
                          </tr>
                        </thead>
                        <tbody>{contractorDetailElement}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer" style={{ padding: '22px 39px', paddingTop: 0 }}>
                <EnButton
                  className="default-button"
                  onClick={async () => {
                    await this.initAvailableContractorIds();
                    this.reloadContractorDetail();
                  }}
                >
                  Refresh
                </EnButton>
                <span data-target="#viewContractorModal" data-toggle="modal">
                  <EnButton
                    className="primary-button"
                    data-target="#searchContractorModal"
                    data-toggle="modal"
                    disabled={!isEnabledSelectBtn}
                    style={{ marginLeft: 8 }}
                    onClick={() => this.onTeamChanged(get(contractorDetail, 'contractor._id'))}
                  >
                    เลือก
                  </EnButton>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    return data;
  }

  mapOptions(contractorTeams, contractorTeamFromJob) {
    if (!contractorTeams || !contractorTeams.length) {
      return `
        <div className='col-md-12'>
          <div className='col-md-12'>
            ยังไม่มีช่างที่เข้างาน
          </div>
        </div>
      `
    }

    return contractorTeams.map((item, index) => {
      const isChecked =
        get(contractorTeamFromJob, 'mainContractor._id', '') === item._id.toString() ||
        get(contractorTeamFromJob, 'subContractor', []).find(sub => sub._id === item._id.toString())

      return `
      <div key=${index} class="align-items-start" style="padding: 6px 12px;">
        <div class="d-flex" style="justify-content: space-between;">
          <div class="col-8 d-flex">
            <div class="col-4">
              ${item.profile_pic ? (
          `<img 
                    src=${item.profile_pic}
                    alt='profile_pic'
                    class="img-fluid w-auto"
                    style="border-radius: 50%; object-fit: cover; max-width: 65px; max-height: 65px; min-width: 65px; min-height: 65px;"
                    onError="this.onerror=null"
                  />`
        ) :
          ('<i class="fa fa-user-circle" style="font-size: 65px; color: #ccc;"></i>')
        }
            </div>
            <div class="col-8" style="padding-left: 10px;">
              <p class="d-block m-0 text-left">${item.first_name} ${item.last_name}</p>
              <p class="d-block m-0 text-left">${item.contact_phone_no}</p>
            </div>
          </div>
          <div class="col-4" style="display: grid; justify-items: center; min-width: 60px">
            <input
              ${item.status === 'registered' ? 'disabled' : ''}
              class="checkbox"
              style="width: 25px; height: 25px"
              type='checkbox'
              class="mt-2"
              data-id="${item._id.toString()}"
              ${isChecked ? 'checked' : ''}
            />
            ${item.status === 'registered' ? ('<span>(รออนุมัติ)</span>') : ''}
          </div>
        </div>
      </div>
    `
    }).join('')
  }

  async addContractor(contractorTeam, contractorId, nextState = null) {
    if (!contractorId) {
      this.noti.error('กรุณาเลือกช่างหลักก่อนเพิ่มช่างเข้าหน้างาน')
      return;
    }

    const newOption = await this.props.subContractor.findTeam(contractorId);

    // Map over the options and generate HTML for each item
    const contractorItems = this.mapOptions(newOption, contractorTeam)

    return (
      swal.fire({
        title: 'เลือกช่างเข้าหน้างาน',
        // showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#d33',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'ยืนยัน',
        html: `
        <div className='col-md-12'>
          <div className='col-md-12'>
            <input
              type='text'
              id='search'
              className='form-control'
              placeholder='ค้นหาช่าง'
              style="margin-bottom: 10px; width: 100%; height: 40px; color: #495057; border: 1px solid #ced4da; border-radius: .25rem; padding: 6px 12px;"
            />
          </div>
          <div className='col-md-12' style="margin-bottom: -20px;">
            <div id="contractorList" style="width: 100%; max-height: 450px; overflow-y: scroll;">
              ${contractorItems}
            </div>
          </div>
        </div>
      `,
        onOpen: () => {
          const searchInput = document.getElementById('search');
          const contractorList = document.getElementById('contractorList');

          searchInput.addEventListener('input', () => {
            const { value } = searchInput;

            // filter the options
            const filteredOptions = newOption.filter(item => {
              if (!value) return item;
              return item.first_name.toLowerCase().includes(value.toLowerCase()) ||
                item.last_name.toLowerCase().includes(value.toLowerCase()) ||
                item.contact_phone_no.toLowerCase().includes(value.toLowerCase());
            });

            // Update the HTML
            contractorList.innerHTML = this.mapOptions(filteredOptions, contractorTeam);
          })
        },
        preConfirm: async () => {
          // get contractors from the checkbox
          const checkboxes = document.querySelectorAll('.checkbox');
          const selectedContractors = [];
          checkboxes.forEach((checkbox) => {
            if (checkbox.checked) {
              const id = checkbox.getAttribute('data-id');
              const contractor = newOption.find(item => item._id.toString() === id);
              if (contractor) {
                selectedContractors.push(contractor);
              }
            }
          });

          // map to contractor_team
          const payload = {
            mainContractor: '',
            subContractor: [],
          }

          selectedContractors.map((item) => {
            if (item._id.toString() === contractorId.toString()) {
              payload.mainContractor = item
            } else {
              payload.subContractor.push(item);
            }
          });

          // update the state
          this.props.job.setInfo('contractorTeam', payload);

          // save and next state for contractorMode
          if (this.props.contractorMode && nextState) {
            await this.props.job.updateJob(this.props.jobId);
            await this.props.job.saveAndNextState(nextState, {
              isContractor: this.props.contractorMode,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
          else if (this.props.contractorMode) {
            await this.props.job.updateJob(this.props.jobId);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      })
    )
  }

  getAssignContractorHtml(jobInfo) {
    const { contractorId, contractorTeam } = jobInfo;
    const { options } = this.props.subContractor.toJS();
    const { contractorMode } = this.props;

    const removeContractor = (contractorId) => {
      const { contractorTeam } = this.props.job.toJS().info;
      const payload = {
        mainContractor: get(contractorTeam, 'mainContractor', ''),
        subContractor: get(contractorTeam, 'subContractor', [])
      }

      // remove main_contractor
      if (contractorTeam.mainContractor && contractorTeam.mainContractor._id === contractorId) {
        payload.mainContractor = ''
      } else {
        // remove sub_contractor
        payload.subContractor = contractorTeam.subContractor.filter(item => item._id !== contractorId)
      }

      // update the state
      this.props.job.setInfo('contractorTeam', payload);
    }

    const renderContractorCard = (contractor) => {
      if (!contractor) return null

      if (contractorMode) {
        return (
          <div className='col-md-12 p-0'>
            <div className='col-md-10 p-0 text-left'>
              <i className='fa fa-user' aria-hidden='true' style={{ paddingRight: '5px' }}></i>
              <span>{contractor.first_name} {contractor.last_name}</span>
              <span> </span>
              {contractor.contact_phone_no && <span>{`(${contractor.contact_phone_no})`}</span>}
            </div>
          </div>
        )
      }

      return (
        <div className='col-md-12 p-0'>
          <div className='col-md-10 p-0 text-left'>
            <i className='fa fa-user' aria-hidden='true' style={{ paddingRight: '5px' }}></i>
            <span>{contractor.first_name} {contractor.last_name}</span>
            <span> </span>
            {contractor.contact_phone_no && <span>{`(${contractor.contact_phone_no})`}</span>}
          </div>
          <div className='col-md-2 p-0 c-pointer text-right'>
            <i className='fa fa-times' aria-hidden='true' onClick={() => removeContractor(contractor._id)}></i>
          </div>
        </div>
      )
    }

    return (
      <div className='col-md-12'>
        {/* HEADER */}
        <div className='col-md-12 d-flex' style={{ justifyContent: 'space-between', padding: 0 }}>
          <label className='control-label'>ทีมช่างเข้าหน้างาน</label>
          <label className='control-label text-primary c-pointer' hidden={contractorMode && (!contractorTeam || (!contractorTeam.mainContractor && !contractorTeam.subContractor.length))} onClick={() => this.addContractor(contractorTeam, contractorId)}>เพิ่มช่าง</label>
        </div>

        {/* BODY */}
        <div className='col-md-12 p-0'>
          {/* EMPTY CONTRACTOR */}
          {
            !contractorTeam || (!contractorTeam.mainContractor && !contractorTeam.subContractor.length) && (
              <div className='col-md-12 p-0 text-left'>
                <em>
                  <small>
                    ยังไม่มีช่างอยู่ในทีม
                  </small>
                </em>
              </div>
            )
          }

          {/* MAIN CONTRACTOR */}
          {
            contractorTeam && contractorTeam.mainContractor && (renderContractorCard(contractorTeam.mainContractor))
          }

          {/* SUB CONTRACTOR */}
          {
            contractorTeam && contractorTeam.subContractor && contractorTeam.subContractor.map((contractor, index) => {
              return renderContractorCard(contractor)
            })
          }

        </div>

      </div>
    )
  }

  getSuggestions = (value = '') => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : this.state.contactInfo.filter(
        (c) =>
          c.id.toLowerCase().slice(0, inputLength) === inputValue ||
          c.name.toLowerCase().slice(0, inputLength) === inputValue
      );
  };

  onChange = async (event, { newValue }) => {
    this.props.job.setInfo('emp_id', newValue);
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  renderSuggestion = (suggestion) => (
    <div>{`${suggestion.id} ${suggestion.name}`}</div>
  );

  setSuggestionValue = (suggestion) => {
    this.props.job.setInfo('contactName', suggestion.name);
    this.props.job.setInfo('contactPhone', suggestion.phone);
    this.props.job.setInfo('emp_id', suggestion.id);
    return suggestion.id;
  };

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  onCloseModalCustomer = () => {
    this.setState({ openModalCustomer: false });
  };

  onModalClick = (value) => {
    const available =
      value === 'morning'
        ? { value: 1, label: 'ช่วงเช้า' }
        : { value: 2, label: 'ช่วงบ่าย' };
    this.saveInfo('available', available);
    this.props.job.setInfo('timeLabel', available.label);
    this.setState({ editTime: false });
  };

  async gotoClaimModal() {
    if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
      return;
    }
    let dialogResult = await Swal.fire({
      title: 'ระบุวิธีการคืนเงิน',
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
      allowOutsideClick: false,
      html: await this.getComplaintDialogHtml(customerReturn),
      inputValidator: (value) => {
        if (!value) {
          return 'กรุณาระบุวิธีการคืนเงิน';
        }
      },
      preConfirm: async () => {
        let claimRadioValue = null;
        if (document.querySelector('input[name="claim-radio"]:checked')) {
          claimRadioValue = document.querySelector('input[name="claim-radio"]:checked').value;
        }
        const submitBy = document.getElementById('submitBy').value;
        const reason = document.getElementById('reason').value;
        const ticketUrl = document.getElementById('ticketUrl').value;
        var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        const validUrl = regex.test(ticketUrl);
        if (!(claimRadioValue && submitBy && reason.length >= 5)) {
          Swal.showValidationMessage(`กรุณากรอกข้อมูลให้ครบ`);
          return false;
        }
        if (!validUrl) {
          Swal.showValidationMessage(`Link URL Ticket ไม่ถูกต้อง`);
          return false;
        }
        if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
          return;
        }
        if (claimRadioValue && submitBy && reason.length >= 5 && validUrl) {
          return {
            'claimRadioValue': claimRadioValue,
            'submitBy': submitBy,
            'reason': reason,
            'ticketUrl': ticketUrl
          };
        }
        Swal.showValidationMessage(`กรุณากรอกข้อมูลให้ครบ`);
        return false;
      },
    });
    if (dialogResult.dismiss == 'cancel') {
      return;
    }
    const { claimRadioValue, submitBy, reason, ticketUrl } = dialogResult.value;
    if (claimRadioValue && submitBy && reason && ticketUrl) {
      const result = await Swal.fire({
        text: 'คุณยืนยันที่จะทำการคืนเงินคิวช่างนี้ใช่หรือไม่?',
        icon: 'question',
        titleText: customerReturn.find((item) => item.key === +claimRadioValue).value || '',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
      });
      if (result.value) {
        const { email } = this.props.auth.getUserInfo();
        const jobInfo = this.props.job.toJS().info;
        let newJob = await this.props.job.createClaimJob(jobInfo.id, claimRadioValue, email);

        if (newJob) {
          const { superAdminMode } = this.props;
          const url = superAdminMode ? 'superadmin' : 'admin/calendar';

          const qs = common.urlBuilder({ status: 'claim' });
          // Case 1, 2, 3, 4 => all button can click !!!. Ref QCNS-2441.
          // Case 1 with thai QR, 2c2p(Credit Card) => disabled all button. Ref QCNS-2441.
          const paymentTypeCantEdit = jobInfo.invoice.find((item) => item.payment_channel === 'Credit and debit cards' || item.payment_channel === 'QR Gateway -Thai QR')
          if (newJob._id.toString() === jobInfo.id && +claimRadioValue !== 4) {
            history.push(
              `${config.publicUrl}/${url}/${superAdminMode ? 'jobs' : ''}`
            );
          } else if (+claimRadioValue === 4) {
            history.push(
              `${config.publicUrl}/${url}/jobs/${newJob._id.toString()}`
            );
          } else if (+claimRadioValue === 1 && paymentTypeCantEdit) {
            history.push(
              `${config.publicUrl}/${url}/jobs/${newJob._id.toString()}?${qs}`
            );
          } else {
            history.push(
              `${config.publicUrl}/${url}/jobs/${newJob._id.toString()}`
            );
          }
          this.logActivityComplaint(
            jobInfo.jobCode,
            claimRadioValue,
            reason,
            ticketUrl,
            submitBy
          );
          Swal.fire({
            icon: 'success',
            title: `ยืนยันการคืนเงินเรียบร้อย`,
          }).then((result) => {
            if (result.value) {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          });
        } else {
          this.onCancel();
        }
      }
    }
  }

  getSwal = (jobDate, availableMessage) => {
    Swal.fire({
      title: `วันที่ ${jobDate}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.value) {
        // this.props.onDelete();
      }
    });
  };

  updateGoogleMap(data) {
    const jobInfo = this.props.job.toJS().info;
    if (!(jobInfo.asa_data && jobInfo.asa_data.request_location)) {
      return;
    }
    if (data && data.location) {
      this.markerPositionChanged({
        position: {
          lat: data.location.site_latitude,
          lng: data.location.site_longitude,
        },
      });
    } else {
      const locationText =
        jobInfo.addressInfo.subDistrict +
        ' ' +
        jobInfo.addressInfo.district +
        ' ' +
        jobInfo.addressInfo.province;
      geocodeByAddress(locationText)
        .then((results) => {
          const geoLocation = results[0].geometry.location;
          const location = [geoLocation.lng(), geoLocation.lat()];
          this.markerPositionChanged({
            position: {
              lat: location[1],
              lng: location[0],
            },
          });
        })
        .catch((error) => console.error('Error', error));
    }
  }

  markerPositionChanged({ position }) {
    this.props.job.setAddressLocation({
      lat: position.lat,
      lng: position.lng,
    });
  }

  displayGoogleMap(address) {
    let data;
    if (address.asa_data.request_location) {
      if (address.asa_data.location) {
        data = (
          <LocationPicker
            containerElement={<div style={{ height: '100%', width: '100%' }} />}
            mapElement={<div style={{ height: '300px' }} />}
            defaultPosition={{
              lat: address.asa_data.location.site_latitude,
              lng: address.asa_data.location.site_longitude,
            }}
            zoom={12}
            radius={0}
            onChange={this.markerPositionChanged.bind(this)}
          />
        );
      }
    }
    return data;
  }

  setRequestLocation = (e) => {
    if (e.target) {
      this.props.job.setRequestLocation(e.target.checked);
    }
  };

  getCurrentPosition = (options = {}) => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  };

  loadCurrentPosition = async () => {
    let location;
    if ('geolocation' in navigator) {
      const position = await this.getCurrentPosition();
      const { latitude, longitude } = position.coords;
      location = { lat: latitude, lng: longitude };
    } else {
      location = { lat: 13.805439700182204, lng: 100.53791268803536 };
    }
    this.props.job.setAddressLocation(location);
  };

  onCancelJob = async () => {
    if (this.props.onDelete) {
      if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
        return;
      }
      Swal.fire({
        title: 'คุณต้องการยกเลิกคิวช่างนี้ใช่หรือไม่?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
      }).then((result) => {
        if (result.value) {
          const jobStore = this.props.job.toJS();
          const jobInfo = jobStore.info;
          this.props.onCancelJob(jobInfo);
          // this.props.onDelete();
        }
      });
    }
  };

  createInvoiceComission = async () => {
    if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
      return;
    }
    Swal.fire({
      title: 'คุณต้องการออกใบแจ้งหนี้ ใช่หรือไม่?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
    }).then(async (result) => {
      if (result.value) {
        const data = await this.props.job.saveCommision();
        if (data && data.message && data.message === 'success') {
          Swal.fire({
            title: `บันทึกเรียบร้อย`,
            icon: 'success',
          });
          setTimeout(() => window.location.reload(), 2000);
          // this.props.onCancel();
        }
      }
    });
  };

  async onReverseJobStatus() {
    if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
      return;
    }
    Swal.fire({
      title: 'คุณต้องการย้อนสถานะงานนี้ ใช่หรือไม่?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
    }).then(async (result) => {
      if (result.value) {
        const result = await Swal.fire(confirmDialogOptions);
        if (result.value) {
          Swal.fire({
            icon: 'success',
            title: `ยืนยันการย้อนสถานะงานเรียบร้อย`,
          }).then((result) => {
            if (result.value) {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          });
        }
      }
    });
    const confirmDialogOptions = {
      icon: 'question',
      title: `ยืนยันการย้อนสถานะงาน ?`,
      text: `เหตุผล`,
      input: 'text',
      footer: `<i style="color: rgb(221, 51, 51);">เมื่อกดยืนยันแล้วจะไม่สามารถยกเลิกได้ และสถานะจะเปลี่ยนเป็น 'มอบหมายงานให้ช่าง'`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        return !value && 'กรุณาระบุเหตุผล';
      },
      preConfirm: async (reason) => {
        try {
          this.props.job.reverseJobStatus(reason);
          return true;
        } catch (error) {
          throw error;
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    };
  }

  onDeleteQuotationItem() {
    Swal.fire({
      title: 'คุณต้องการลบใบเสนอราคา ใช่หรือไม่?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
    }).then(async (result) => {
      if (result.value) {
        await this.props.job.deleteQuotationByJobId();
        Swal.fire({
          title: `บันทึกเรียบร้อย`,
          icon: 'success',
        });
        setTimeout(() => window.location.reload(), 2000);
      }
    });
  }

  toQuotationListData = (qt, longFileName, showDeleteButton) => {
    let files;
    if (qt && qt.length) {
      files = qt.map((q) => {
        let file = q.files[q.files.length - 1];
        if (q.periods.find((p) => p.status === 'pending')) {
          file.showDeleteButton =
            showDeleteButton === undefined ? true : showDeleteButton;
        }
        return file;
      });
    }

    if (Array.isArray(files) && files.length) {
      return files
        .filter((f) => f && f.name)
        .map((f, idx) => {
          return {
            filename:
              idx +
              1 +
              '_' +
              (longFileName ? f.name : f.name.substring(14, f.name.length)),
            name: f.name,
            url: f.url,
            date: f.date
              ? `${moment(f.date).format('DD/MM/YYYY HH:mm:ss')}`
              : '',
            delete: f.showDeleteButton ? (
              <i className='fa fa-trash' />
            ) : (
              <div />
            ),
            icon: common.getFileIcon(f.name),
            downloadHref: f.url,
            isShowDelete: f.showDeleteButton && !this.props.job.info.installment,
          };
        });
    }
    return [];
  };

  getStorePaymentInfoBadge(info) {
    if (!info || info === undefined) {
      return <div />;
    }

    const paid = _.get(info, 'is_paid') === true;
    if (paid === true) {
      return (
        <div className='row' style={{ paddingLeft: '15px' }}>
          <div className={'alert col-md-4 alert-success'}>
            <span>
              <i
                className={'fa btn-icon fa-check-square-o'}
                aria-hidden='true'
              />
              หน้าร้านชำระเงินแล้ว
            </span>
          </div>
        </div>
      );
    }

    return (
      <div className='row' style={{ paddingLeft: '15px' }}>
        <div
          className={'alert col-md-4 alert-warning'}
          style={{ marginBottom: '15px' }}
        >
          <span>
            <i className={'fa btn-icon fa-clock-o'} aria-hidden='true' />
            หน้าร้านยังไม่ชำระเงิน
          </span>
        </div>
        <div className='col-md-4'>
          <a
            href={`${config.qchang.url}/tracking/${this.state.orderId}?user=store`}
            target='_blank'
          >
            &nbsp;&nbsp;&nbsp;<u>ตรวจสอบการชำระเงินจากหน้าร้าน</u>
          </a>
        </div>
        {/* <div className="col-md-12" style={{ marginBottom: '15px' }}>
          <a href={`${config.qchang.url}/tracking/${this.state.orderId}?user=store`} target="_blank">
            <i className="fa fa-credit-card" aria-hidden="true" />
            &nbsp;&nbsp;ดำเนินการชำระเงิน
          </a>
        </div> */}
      </div>
    );
  }

  // setSeller(key, e) {
  //   if (!key) return;
  //   const seller = this.state.seller;
  //   seller[key] = e.target.value;
  //   this.setState(seller)
  // }

  findGradeByTypeOfWork(gradeData = [], typeOfWork = []) {

    let text = ''
    _.forEach(typeOfWork, (value, index) => {
      const item = _.find(gradeData, function (item) { return item.type_of_work === value; }) || {};
      if (index !== 0) {
        text += `,${item.grade || ''}`
      } else {
        text += item.grade || ''
      }

    })
    return text
  }

  handleHideButton(jobInfo, buttonName) {
    if (!buttonName) return false;
    const status = jobInfo.status === 'paid_to_contractor' ? jobInfo.status : jobInfo.customer_approved ? "customer_approved" : jobInfo.status;
    const saleModel = jobInfo.saleModel ? jobInfo.saleModel.toLowerCase() : '';
    switch (saleModel) {
      case "marketplace":
        const marketPlaceHideButton = MarketPlaceHideButton.find(
          (value) => value.jobType === jobInfo.jobType
        );
        return (
          marketPlaceHideButton &&
          marketPlaceHideButton.button[buttonName] &&
          marketPlaceHideButton.button[buttonName].includes(status)
        );
      case "marketplacecredit":
        const marketPlaceCreditHideButton = MarketPlaceCreditHideButton.find(
          (value) => value.jobType === jobInfo.jobType
        );
        return (
          marketPlaceCreditHideButton &&
          marketPlaceCreditHideButton.button[buttonName] &&
          marketPlaceCreditHideButton.button[buttonName].includes(status)
        );
      case "retail":
        const retailHideButton = RetailHideButton.find(
          (value) => value.jobType === jobInfo.jobType
        );
        return (
          retailHideButton &&
          retailHideButton.button[buttonName] &&
          retailHideButton.button[buttonName].includes(status)
        );
      default:
        const defaultHideButton = MarketPlaceHideButton.find(
          (value) => value.jobType === jobInfo.jobType
        );
        return (
          defaultHideButton &&
          defaultHideButton.button[buttonName] &&
          defaultHideButton.button[buttonName].includes(status)
        );
    }
  }

  clearSearchContractor = () => {
    this.setState({
      searchContractorQuery: '',
      searchContractorList: [],
      contractorDetail: undefined,
    });
  };

  copyToClipboard() {
    navigator.clipboard.writeText(this.state.trackingUrl);
  }

  render() {
    const {
      contractorMode,
      superAdminMode,
      isReadOnly,
      showCancelJobButton,
      adminComponentMessage,
      statusClaim,
    } = this.props;
    const { openModal } = this.state;
    let storeMode = false;
    let isAsaStore = false;
    let isEcomAdmin = false;
    // const userInfo = this.props.auth.getUserInfo();
    // const channel = userInfo.channel ? userInfo.channel.channelName : '';
    // -> check role 
    const isBuAccount = this.props.auth.isBuAccount();
    const isAdminBU = this.props.auth.isAdminBU();
    const isSuperAdmin = this.props.auth.isSuperAdmin();
    // ---
    const channel = this.props.auth.getChannelName();
    let isSCGHome = channel === 'SCGHOME Online';
    const jobStore = this.props.job.toJS();
    const sku = this.props.sku.toJS().items;
    const jobInfo = jobStore.info;
    const hasSubsidize = !(jobInfo.subsidizePromotion && jobInfo.subsidizePromotion.is_paid === true) && this.state.subsidize_amount > 0;
    const showResendSmsToCustomerButton = ['installation_finished'].includes(jobInfo.status);
    const trackingQueryString = !contractorMode && (hasSubsidize || showResendSmsToCustomerButton) ? '?user=admin' : '';
    let typeOfWorks = this.props.typeofwork.mapIdAndLabel();
    typeOfWorks = _.orderBy(typeOfWorks, ['label']);
    let disabled = contractorMode === true;
    const store = authStore.getStore();
    const buStore = jobInfo.buStore;
    // let disableEditSeller = false;
    // if (jobInfo.disableEditSeller) {
    //   disableEditSeller = true;
    // }
    // else {
    //   if (!isSuperAdmin) {
    //     disableEditSeller = true;
    //   }
    // }

    if (store) {
      storeMode = true;
      isAsaStore = store._id === config.asa.storeId;
      isEcomAdmin = store._id === config.ecom.storeId;
    }
    if (isReadOnly || statusClaim) {
      disabled = true;
    }
    const { value, suggestions } = this.state;

    const inputProps = {
      disabled: isAllow("EDIT_EMPLOYEE_INFORMATION") ? false : true,
      placeholder: 'กรอกชื่อหรือรหัสของท่าน',
      value: jobInfo.emp_id || value,
      onChange: this.onChange.bind(this),
      ...statusClaim && { disabled: statusClaim },
    };

    let typeOfJobs = [];
    typeOfJobs = TypeOfJobs.map((item, idx) => {
      return (
        <option key={idx} value={item.id}>
          {item.label}
        </option>
      );
    });
    typeOfJobs = typeOfJobs.slice(1);

    const timeSlot = this.getAvailableTime();
    let availableTime;
    if (timeSlot) {
      availableTime = timeSlot.map((item, index) => {
        return { value: item.id, label: `${item.title}` };
      });
    }

    const otherWork = (jobInfo.typeOfWork || []).find((i) => i === 0);
    const typeOfWork = typeOfWorks.map((item, idx) => {
      return (
        <option key={idx} value={item.id}>
          {item.label}
        </option>
      );
    });
    let productItems = [];
    if (
      Array.isArray(jobInfo.typeOfWork) &&
      jobInfo.typeOfWork.length &&
      sku &&
      sku.length
    ) {
      productItems = sku.map((item, idx) => {
        return (
          <option key={idx} value={item.code}>
            {item.name.th}
          </option>
        );
      });
    }
    if (productItems.length) {
      productItems.unshift(
        <option key={-1} value={''}>
          กรุณาเลือกสินค้า
        </option>
      );
    }

    const contractors = this.getContractors();
    const availableMessage = this.getAvailableMessage(contractors);
    const teams = _.orderBy(contractors, ['team_name']).map((contractor, idx) => {
      const contractorType = contractor.is_juristic ? 'นิติบุคคล' : 'บุคคลธรรมดา';
      const grade = this.findGradeByTypeOfWork(contractor.grade, jobInfo.typeOfWork)
      // const grade = contractor.grade ? contractor.grade : 'NEW';
      return <option key={idx} value={contractor._id}>{contractor.team_name} ({contractorType}) ({grade || 'New'}) โทร {contractor.phone} </option>;
    });
    if (teams.length) {
      teams.unshift(
        <option key={-1} value={''}>
          กรุณาเลือกทีมช่าง
        </option>
      );
    }

    let itemHeader = initListItemHeaderOption(
      'ไฟล์เพิ่มเติม ( อัพโหลดได้สูงสุด 20 ไฟล์ต่อครั้ง )'
    );
    let itemData = toListItemData(jobInfo.files);
    if (!contractorMode) {
      itemHeader.push(listItemHeaderOpts);
    }
    let listItem = getListItem(
      itemHeader,
      itemData,
      onUploadItemClick.bind(this, 'files', jobInfo)
    );

    let receiptItemHeader = initListItemHeaderOption('ใบรับเงิน');
    let contractorReceiptItemHeader = initListItemHeaderOption(
      'ใบเสร็จรับเงินของช่าง'
    );
    let pcItemHeader = initListItemHeaderOption('ใบยืนยันการชำระเงินจากร้านค้า');
    let creditSaleItemHeader = initListItemHeaderOption('ใบขายเงินเชื่อ');
    let receiptFiles = [];
    let pcFiles = [];
    let file = []
    if (jobInfo.invoice) {
      const { invoice } = jobInfo;
      if (Array.isArray(invoice) && invoice.length) {
        // receiptFiles = invoice[invoice.length - 1].files || [];
        file = invoice.reduce((files, iv) => {
          if (Array.isArray(iv.files) && iv.files.length) {
            const lastFile = iv.files[iv.files.length - 1];
            files.push(lastFile);
          }
          return files;
        }, []);
      } else {
        file = invoice.files || [];
      }
      for (let i = 0; i < file.length; i++) {
        if ((file[i].name || '').slice(0, 2) === 'PC') {
          pcFiles.push(file[i])
        } else {
          receiptFiles.push(file[i])
        }
      }
    }

    let contractorReceiptFiles = [];
    if (jobInfo.contractorReceipt) {
      contractorReceiptFiles = jobInfo.contractorReceipt.files || [];
    }

    let creditSaleFiles = [];
    if (jobInfo.creditSale) {
      creditSaleFiles = jobInfo.creditSale.files || [];
    }
    // if (receiptFiles) {
    // receiptFiles = receiptFiles.sort((a, b) => new Date(b.date) - new Date(a.date));
    // เอาเฉพาะใบล่าสุด
    // receiptFiles = [receiptFiles[0]];
    // }
    let receiptItemData = toListItemData(receiptFiles, true);
    let receiptListItem = getListItem(
      receiptItemHeader,
      receiptItemData,
      onUploadItemClick.bind(this, 'receiptFiles', jobInfo)
    );
    let contractorReceiptItemData = toListItemData(
      contractorReceiptFiles,
      true
    );
    let pcItemData = toListItemData(pcFiles, true)
    let pcListItem = getListItem(
      pcItemHeader,
      pcItemData,
      onUploadItemClick.bind(this, 'pcFiles', jobInfo)
    );
    let contractorPcItemData = toListItemData(
      contractorReceiptFiles,
      true
    );
    let creditSaleItemData = toListItemData(creditSaleFiles, true);
    let contractorReceiptListItem = getListItem(
      contractorReceiptItemHeader,
      contractorReceiptItemData
    );
    let creditSaleListItem = getListItem(
      creditSaleItemHeader,
      creditSaleItemData
    );
    const invoiceWording = receiptFiles.length || pcFiles.length ? 'แก้ไข' : 'ออก';

    let quotationItemHeader = [
      {
        title: 'ใบเสนอราคา',
        size: 12,
        key: 'filename',
        showIcon: true,
        downloadLink: true,
        appendDescription: true,
        descriptionKey: 'date',
      },
    ];

    let quotationItemData = this.toQuotationListData(
      this.state.surveyQuotation || jobInfo.quotation,
      true
    );
    let quotationListItem = getListItem(
      quotationItemHeader,
      quotationItemData,
      this.onDeleteQuotationItem.bind(this)
    );

    const poItemHeader = initListItemHeaderOption(
      'ใบสั่งซื้อสินค้า (Purchase Order)'
    );
    const poSurveyFiles = getLastFiles(jobInfo, 'po_survey');
    const poSurveyItemData = toListItemData(poSurveyFiles, true);
    const poListItem = getListItem(
      poItemHeader,
      poSurveyItemData,
      onUploadItemClick.bind(this, 'po_survey', jobInfo)
    );

    const creditProviderItemHeader = initListItemHeaderOption(
      'เอกสารการวางบิลของ Credit Provider'
    );
    const creditProviderFiles = jobInfo.creditProvider
      ? jobInfo.creditProvider.billing_file
      : undefined;
    const creditProviderItemData = toListItemData(creditProviderFiles, true);
    const creditProviderListItem = getListItem(
      creditProviderItemHeader,
      creditProviderItemData,
      onUploadItemClick.bind(this, 'credit_provider', jobInfo)
    );
    const hasCreditProvider = !!jobInfo.creditProvider;

    const poInstallationFiles = getLastFiles(jobInfo, 'po_installation');
    const poInstallationItemData = toListItemData(poInstallationFiles, true);
    const poInstallationList = getListItem(
      poItemHeader,
      poInstallationItemData,
      onUploadItemClick.bind(this, 'po_installation', jobInfo)
    );

    // เพิ่มใบรับประกัน
    let guaranteeItemHeader = initListItemHeaderOption('ใบรับประกัน');
    const countguaranteeFiles =
      jobInfo.guarantee && jobInfo.guarantee ? jobInfo.guarantee.length - 1 : 0;
    const guaranteeFiles = jobInfo.guarantee
      ? jobInfo.guarantee[countguaranteeFiles]
      : [];
    let guaranteeItemData = toListItemData([guaranteeFiles], true);
    let guaranteeListItem = getListItem(
      guaranteeItemHeader,
      guaranteeItemData,
      onUploadItemClick.bind(this, 'guaranteeFiles', jobInfo)
    );

    // เพิ่มใบเคลม
    let claimItemHeader = initListItemHeaderOption('ใบเคลม');
    const countclaimFiles =
      jobInfo.claim && jobInfo.claim.files ? jobInfo.claim.files.length - 1 : 0;
    const claimFiles =
      jobInfo.claim && jobInfo.claim.files
        ? jobInfo.claim && jobInfo.claim.files[countclaimFiles]
        : [];
    let claimItemData = toListItemData([claimFiles], true);
    let claimListItem = getListItem(
      claimItemHeader,
      claimItemData,
      onUploadItemClick.bind(this, 'claimFiles', jobInfo)
    );

    let invoiceComissionHeader = initListItemHeaderOption('ใบแจ้งหนี้');
    const countInvoiceCommisionFile =
      jobInfo.invoice_commision &&
        jobInfo.invoice_commision.files &&
        jobInfo.invoice_commision.files.length
        ? jobInfo.invoice_commision.files.length - 1
        : 0;

    const invoiceComissionFile =
      jobInfo.invoice_commision &&
        jobInfo.invoice_commision.files &&
        jobInfo.invoice_commision.files.length
        ? jobInfo.invoice_commision.files[countInvoiceCommisionFile]
        : [];
    let invoiceComissionData = toListItemData([invoiceComissionFile], true);
    let commisionListItem = getListItem(
      invoiceComissionHeader,
      invoiceComissionData,
      onUploadItemClick.bind(this, 'invoice_commision', jobInfo)
    );

    let chargeFileData = toListItemData(jobInfo.additionalCharge);
    let chargeFileHeader = initListItemHeaderOption('ค่าใช้จ่ายเพิ่มเติม และเอกสารอื่น ๆ');
    let listChargeFile = getListItem(
      chargeFileHeader,
      chargeFileData,
      onUploadItemClick.bind(this, 'additionalCharge', jobInfo)
    );

    // dropzone for upload file
    let dropzone = getDropZone(
      (e) => {
        this.dropzone = e;
      },
      this.props.jobId,
      (e) => {
        onUploadComplete(e, this.state, this.props.contractorMode);
      },
      undefined,
      "",
      statusClaim
    );

    // const isSameDate = datetime.isEqual(jobInfo.startDate, jobInfo.endDate);
    const isInstallation = jobInfo.jobType === 'I';
    // let isShowPoButton = false;
    let isShowReceiptButton = false;
    let isShowQuotationButton = false;
    let showAddQuotation = false;

    let isShowManualPo = false;
    if (!contractorMode) {
      // isShowPoButton = this.showDocumentButton('po', (superAdminMode) ? true : store.po_require, superAdminMode, storeMode, isReadOnly, jobInfo);
      isShowReceiptButton = this.showDocumentButton(
        'reciept',
        superAdminMode ? true : store && store.receipt_require,
        superAdminMode,
        storeMode,
        isReadOnly,
        jobInfo
      );
      isShowQuotationButton = this.showDocumentButton(
        'quotation',
        superAdminMode ? true : store && store.quotation_require,
        superAdminMode,
        storeMode,
        isReadOnly,
        jobInfo
      );
      isShowManualPo =
        !!(
          jobInfo.contractorId &&
          jobInfo.id &&
          !contractorMode &&
          !isReadOnly &&
          ((storeMode && store.po_require) || superAdminMode) &&
          jobInfo.status !== 'paid_to_contractor'
        ) || false;
    }
    // const purchaseOrderWording = (jobInfo.po_survey && Array.isArray(jobInfo.po_survey.files) && jobInfo.po_survey.files.length) ||
    //   (jobInfo.po_installation && Array.isArray(jobInfo.po_installation.files) && jobInfo.po_installation.files.length) ? 'แก้ไข' : 'ออก';
    const purchaseOrderWording =
      poInstallationFiles.length || poSurveyFiles.length ? 'แก้ไข' : 'ออก';
    const quotationWording =
      jobInfo.quotation && jobInfo.quotation.length ? 'แก้ไข' : 'เพิ่ม';

    const availableHtml = this.getAvailableHtml(jobInfo, availableTime);
    const jobDate = datetime.toTextString(
      jobInfo.startDate,
      jobInfo.endDate,
      'DD/MM/YYYY'
    );
    let contractorAbsentButton = <div />;
    if (
      jobInfo &&
      jobInfo.status &&
      !(jobInfo.status === 'created') &&
      !(jobInfo.customer_approved === true)
    ) {
      const flag = jobInfo.customer_approved === false;
      const label = 'ช่างไม่เข้าหน้างาน';
      const today = moment(moment().format('YYYY/MM/DD'));
      const compareDate = moment(this.state.jobStartDate).format('YYYY/MM/DD');
      contractorAbsentButton = !_.isEmpty(this.state.jobStartDate) && today.isSameOrAfter(compareDate) ? (
        <div className='row' style={{ paddingLeft: 15, fontWeight: 700 }}>
          <EnToggle
            containerClassName='col-md-6'
            checked={flag}
            label={label}
            onChange={this.onApproveChanged.bind(this, jobInfo)}
          />
        </div>
      ) : (
        <div />
      );
    }

    let adminApprovePaidButton = <div />;
    const foundIndex = jobInfo.statusHistory.findIndex(
      (j) => j.value === 'paid_to_contractor'
    );
    const adminApprovePaid = !(jobInfo.adminApprovePaid === false);
    if (jobInfo && jobInfo.status !== "paid_to_contractor" && foundIndex === -1) {
      adminApprovePaidButton = (
        <div className='row' style={{ paddingLeft: 15, fontWeight: 700 }}>
          <EnToggle
            containerClassName='col-md-6'
            checked={adminApprovePaid}
            label={'พร้อมจ่ายเงินให้ช่าง'}
            onChange={this.onToggleAdminApprovePaidClick.bind(this, jobInfo)}
          />
        </div>
      );
    }

    const latestHistory = this.props.contractorblockedpayment.toJS().lastestBlockedPayment;
    const contractorBlockedPaymentBanner = Array.isArray(latestHistory) &&
      latestHistory.length > 0 &&
      latestHistory[0].blocked_payment && (
        <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger col-md-6" role="alert" style={{ margin: '15px 0', backgroundColor: '#EF4E30' }}>
              ระงับการจ่ายเงินช่าง โดย {latestHistory[0].user} {moment(latestHistory[0].created_date).utcOffset(7).format('DD/MM/YYYY HH:mm:ss') + ' น.'}
            </div>
          </div>
        </div>
      );

    const showInvoiceComission = !!(jobInfo.statusHistory || []).find(
      (j) => j.value === 'paid_to_contractor'
    );
    if (jobInfo.quotation && jobInfo.quotation.length) {
      const checkQuotationPaid = !!jobInfo.quotation[
        jobInfo.quotation.length - 1
      ].periods.find((p) => p.status === 'paid');
      showAddQuotation =
        isShowQuotationButton &&
        checkQuotationPaid &&
        jobInfo.status !== 'paid_to_contractor' &&
        jobInfo.is_paid;
    }

    let isShowContractorReceiptButton =
      !contractorMode && jobInfo.saleModel === 'Retail';
    const contractorAccept = jobInfo.contractorAccept;
    const canStartJob = jobInfo.contractorAccept || !contractorMode;
    const isClaim = jobInfo.saleModel === 'Claim';

    const surveyStatusCanReverse = [
      'survey_started',
      'survey_finished',
      'installation_requested',
    ];
    const InstallationStatusCanReverse = [
      'installation_started',
      'installation_finished',
    ];
    const isInValidReverseJobStatus = !!(
      (jobInfo.jobType === 'S' &&
        surveyStatusCanReverse.indexOf(jobInfo.status) < 0) ||
      (InstallationStatusCanReverse.indexOf(jobInfo.status) < 0 &&
        jobInfo.jobType === 'I')
    );

    let showClaimButton = !isClaim;
    if (guaranteeFiles && guaranteeFiles.expire_date) {
      const expireDate = new Date(guaranteeFiles.expire_date);
      const today = new Date();
      if (today > expireDate) {
        showClaimButton = false;
      }
    }

    let saleModel = jobInfo.saleModel;
    if (jobInfo.saleModel === 'Claim' && jobInfo.refJob) {
      saleModel = `${jobInfo.saleModel} แบบที่ ${jobInfo.refJob.claim_method || '-'
        }`;
    }

    const isShowCreditProviderButton =
      !!(poSurveyFiles || poInstallationFiles) && !contractorMode;
    const searchContractorHtml = this.getsearchContractorHtml();
    // const viewContractorDetailHtml = this.getsearchContractorHtml();
    const storePaymentInfoBadge = this.getStorePaymentInfoBadge(
      jobInfo.storePaymentInfo
    );

    // const quotationButton = jobInfo.quotation && jobInfo.quotation.length ? 'editQuotation' : 'createQuotation';
    const rcButton = receiptFiles.length || pcFiles.length ? 'editRc' : 'createRc';
    const poButton = poInstallationFiles.length || poSurveyFiles.length ? 'editPo' : 'createPo';
    const stepCreateOrInstall = this.props.stepAt === 'created' || this.props.stepAt === 'installation_requested'; // Condition for check stepper status
    const quotationWillBeExpiredIn7days = moment().diff(moment(jobInfo.submitedAt), 'days') >= 23;
    return (
      <FormValidation>
        <Notification
          ref={(ref) => {
            this.noti = ref;
          }}
        />
        <ModalReasonsWithEtc
          modalName="adminChangesContractor"
          title="ยืนยันการเปลี่ยนช่าง"
          onSave={this.onTeamChangedSaved.bind(this)}
          onCancel={this.onTeamChangedCancel.bind(this)}
        />
        {/* {availableMessage && teams && teams.length === 0 && (
          <Modal
            open={openModal}
            onClose={this.onCloseModal}
            showCloseIcon={false}
            center
          >
            <div style={{ padding: '10px' }} className='text-center'>
              {availableMessage === 'ไม่มีช่างพร้อมให้บริการ' ? (
                <div>
                  <h3>วันที่ {jobDate}</h3>
                  <h3>ไม่มีช่างพร้อมให้บริการ</h3>
                </div>
              ) : (
                <div>
                  <h3>วันที่ {jobDate} มีช่าง</h3>
                  <h3>ให้บริการเฉพาะ..</h3>
                  <div className='row'>
                    <button
                      className='btn btn-primary'
                      disabled={
                        !(
                          availableMessage === 'เช้า' ||
                          availableMessage === 'เช้า และ บ่าย'
                        )
                      }
                      onClick={this.onModalClick.bind(this, 'morning')}
                    >
                      ช่วงเช้า
                    </button>
                    <button
                      className='btn btn-primary'
                      disabled={
                        !(
                          availableMessage === 'บ่าย' ||
                          availableMessage === 'เช้า และ บ่าย'
                        )
                      }
                      onClick={this.onModalClick.bind(this, 'afternoon')}
                    >
                      ช่วงบ่าย
                    </button>
                    <button className='btn btn-primary' disabled>
                      ทั้งวัน
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        )} */}
        {!jobInfo.is_paid && (
          <div className='alert alert-info alert-dismissible alert-paid'>
            <span>
              <i className='fa fa-clock-o btn-icon' aria-hidden='true' />{' '}
              รอการชำระเงินจากลูกค้า
            </span>
          </div>
        )}
        {this.state.previousRemark && (
          <div className='alert alert-warning col-md-12'>
            <span>
              <i className='fa fa-exclamation-circle' aria-hidden='true' />{' '}
              {moment(this.state.currentRemark.submited_at).format('HH:mm DD/MM/YYYY')}{' '}
              {jobInfo.jobType === 'S' ? 'ลูกค้าเปลี่ยนแปลงวันที่นัดสำรวจครั้งที่ ' : 'ลูกค้าเปลี่ยนแปลงวันที่ติดตั้งครั้งที่ '}
              {jobInfo.old_remark.length - 1} จากวันที่{' '}
              {moment(this.state.previousRemark.start_date).format(
                'DD/MM/YYYY'
              )}{' '}
              เป็นวันที่{' '}
              {moment(this.state.currentRemark.start_date).format('DD/MM/YYYY')}{' '}
              เวลา {this.state.currentRemark.time_label}{' '}
            </span>
          </div>
        )}
        {!contractorMode &&
          'assigned installation_confirmed'.includes(jobInfo.status) &&
          jobInfo.read_history && (
            <div className='row' style={{ paddingLeft: '15px' }}>
              {contractorAccept && (
                <div className='alert alert-success col-md-4'>
                  <span>
                    <i className='fa fa-check-square-o' aria-hidden='true' />{' '}
                    ช่างรับงานแล้ว (
                    {datetime.formatWithOffset(
                      jobInfo.contractorAccept.accepted_date,
                      'DD/MM/YYYY hh:mm',
                      7
                    )}{' '}
                    น.)
                  </span>
                </div>
              )}
              {!contractorAccept && (
                <div
                  className='alert alert-warning col-md-4'
                  style={{ backgroundColor: '#ae5d0e' }}
                >
                  <span>
                    <i
                      className='fa fa-exclamation-circle'
                      aria-hidden='true'
                    />{' '}
                    ช่างยังไม่รับงาน
                  </span>
                </div>
              )}
            </div>
          )}
        {contractorMode && contractorAccept && (
          <div className='row'>
            <div className='alert alert-success col-md-3'>
              <span>
                <i className='fa fa-check-square-o' aria-hidden='true' />{' '}
                ช่างรับงานแล้ว{' '}
              </span>
            </div>
          </div>
        )}
        {/* <div className="row" style={{ paddingLeft: '15px' }}>
          {
            (!contractorMode && jobInfo.freesurveyPaid === true) && (
              // <div className="alert alert-info alert-dismissible alert-paid">
              <div className="alert alert-success col-md-4" >
                <span><i className="fa fa-check-square-o" aria-hidden="true" /> แอดมินชำระค่าสำรวจแทนลูกค้าแล้ว</span>
              </div>
            )
          }

          {
            (!contractorMode && jobInfo.freesurveyPaid === false) && (
              // <div className="alert alert-info alert-dismissible alert-paid">
              <div className="alert alert-warning col-md-4" >
                <span><i className="fa fa-clock-o btn-icon" aria-hidden="true" /> แอดมินยังไม่ได้ชำระค่าสำรวจแทนลูกค้า</span>
              </div>
            )
          }
        </div> */}
        <div className='row' style={{ paddingLeft: '15px' }}>
          {!contractorMode &&
            jobInfo.subsidizePromotion &&
            jobInfo.subsidizePromotion.is_paid === true && (
              // <div className="alert alert-info alert-dismissible alert-paid">
              <div className='alert alert-success col-md-4'>
                <span>
                  <i className='fa fa-check-square-o' aria-hidden='true' />{' '}
                  แอดมินชำระค่า Subsidize Promotion แล้ว
                </span>
              </div>
            )}

          {!contractorMode && hasSubsidize && (
            // <div className="alert alert-info alert-dismissible alert-paid">
            <div className='alert alert-warning col-md-4'>
              <span>
                <i className='fa fa-clock-o btn-icon' aria-hidden='true' />{' '}
                แอดมินยังไม่ได้ชำระค่า Subsidize Promotion
              </span>
            </div>
          )}
        </div>
        <div className='row' style={{ paddingLeft: '15px' }}>
          {!contractorMode &&
            jobInfo.saleModel === 'Claim' &&
            !(
              jobInfo.claim &&
              jobInfo.claim.files &&
              jobInfo.claim.files.length
            ) && (
              // <div className="alert alert-info alert-dismissible alert-paid">
              <div className='alert alert-warning col-md-4'>
                <span>
                  <i className='fa fa-clock-o btn-icon' aria-hidden='true' />{' '}
                  แอดมินยังไม่ได้ออกเอกสารการเคลม
                </span>
              </div>
            )}
        </div>
        {!contractorMode && storePaymentInfoBadge}
        {!contractorMode && jobInfo.jobType === 'S' && contractorAbsentButton}
        {!contractorMode && adminApprovePaidButton}
        {!contractorMode && contractorBlockedPaymentBanner}
        {!contractorMode && (
          <div className='row'>
            {isAllow("VIEW_CHANNEL_INFORMATION") &&
              (
                <EnTextFormGroup
                  id='channel'
                  disabled
                  containerClassName='col-md-6'
                  label='ช่องทางการขาย'
                  value={jobInfo.channel || ''}
                />
              )}
            <EnTextFormGroup
              id='sale_model'
              disabled
              containerClassName='col-md-3'
              label='รูปแบบการขาย'
              value={saleModel || ''}
            />
            <EnTextFormGroup
              id='sale_model'
              disabled
              containerClassName='col-md-3'
              label='ชื่อร้าน'
              value={buStore ? buStore.name : ''}
            />
          </div>
        )}
        {(!!jobInfo.id || !!jobInfo.storeName) && (
          <div className='row'>
            <EnTextFormGroup
              id='job-id'
              disabled
              containerClassName={jobInfo.installment ? `col-md-3` : 'col-md-6'}
              label='รหัสอ้างอิง'
              value={jobInfo.jobCode || jobInfo.id}
            />
            {jobInfo.installment && (<EnTextFormGroup
              id='job-id'
              disabled
              containerClassName='col-md-3'
              label='งานแบ่งงวด'
              value={`งวดที่ ${jobInfo.installment}` || "งวดที่ ..."}
            />)}
            {!contractorMode && !isAsaStore && (
              <EnTextFormGroup
                id='is-paid'
                disabled
                containerClassName='col-md-6'
                label='สถานะการชำระเงิน'
                value={jobInfo.is_paid ? 'ชำระเงินแล้ว' : 'รอการชำระเงิน'}
              />
            )}
            {contractorMode && (
              <EnTextFormGroup
                id='job-id'
                disabled
                containerClassName='col-md-6'
                label='ชื่อร้าน'
                value={jobInfo.storeName}
              />
            )}
          </div>
        )}
        <div className='row'>
          <EnTextFormGroup
            id='sale-order-code'
            disabled={true}
            containerClassName={`col-md-6`}
            label='หมายเลข SO'
            value={jobInfo.saleOrderCode}
            onChange={this.saveInfo.bind(this, 'saleOrderCode')}
          />
          <EnTextFormGroup
            id='cart-order-number'
            disabled={true}
            containerClassName={`col-md-6`}
            label='หมายเลข CO'
            value={jobInfo.cartOrderNo}
          />
        </div>
        <div className='row'>
          {isAllow("VIEW_CUSTOMER_INFORMATION") &&
            (
              <EnTextFormGroup
                id='customer-firstname'
                disabled={isAllow("EDIT_CUSTOMER_INFORMATION") ? disabled : true}
                containerClassName='col-md-6'
                label='ชื่อลูกค้า'
                value={jobInfo.firstName}
                onChange={this.saveInfo.bind(this, 'firstName')}
                validations={[required]}
              />
            )}
          {isAllow("VIEW_CUSTOMER_INFORMATION") &&
            (
              <EnTextFormGroup
                id='customer-lastname'
                disabled={isAllow("EDIT_CUSTOMER_INFORMATION") ? disabled : true}
                containerClassName='col-md-6'
                label='นามสกุล'
                value={jobInfo.lastName}
                onChange={this.saveInfo.bind(this, 'lastName')}
                validations={[required]}
              />
            )}
        </div>
        <div className='row'>
          {isAllow("VIEW_CUSTOMER_INFORMATION") &&
            (
              <EnTextFormGroup
                id='customer-phone'
                disabled={isAllow("EDIT_CUSTOMER_INFORMATION") ? disabled : true || this.props.statusClaim}
                containerClassName='col-md-6'
                label='เบอร์ติดต่อ'
                value={jobInfo.phone}
                onChange={this.saveInfo.bind(this, 'phone')}
                validations={[phone]}
              >
                <CallingButton phone={jobInfo.phone} isButtonGroup />
              </EnTextFormGroup>
            )}
          {isAllow("VIEW_CUSTOMER_INFORMATION") &&
            (
              <EnTextFormGroup
                id='customer-phone'
                disabled={isAllow("EDIT_CUSTOMER_INFORMATION") ? disabled : true || this.props.statusClaim}
                containerClassName='col-md-6'
                label='เบอร์ติดต่อสำรอง'
                value={jobInfo.phone2}
                onChange={this.saveInfo.bind(this, 'phone2')}
                validations={[phone2]}
                notRequired
              >
                <CallingButton phone={jobInfo.phone2} isButtonGroup />
              </EnTextFormGroup>
            )}
        </div>
        <div className='row'>
          {isAllow("VIEW_CUSTOMER_INFORMATION") &&
            (
              <EnTextFormGroup
                id='customer-email'
                disabled={isAllow("EDIT_CUSTOMER_INFORMATION") ? disabled : true || this.props.statusClaim}
                containerClassName='col-md-6'
                label='อีเมล'
                value={jobInfo.email}
                onChange={this.saveInfo.bind(this, 'email')}
              >
                {!contractorMode && (
                  <EnButton
                    id='load-customerinfo-button'
                    className='btn-info'
                    onClick={this.loadCustomerInfoClick.bind(this)}
                    disabled={this.props.statusClaim}
                  >
                    <i className='fa fa-refresh btn-icon' aria-hidden='true' />
                    โหลดข้อมูลลูกค้า
                  </EnButton>
                )}
              </EnTextFormGroup>
            )}
          {isAllow("VIEW_SELLER_INFORMATION") &&
            (
              <EnTextFormGroup
                id='customer-email'
                disabled={true}
                containerClassName='col-md-3'
                label='ชื่อ-นามสกุล พนักงานที่รับผิดชอบ'
                value={this.state.seller ? this.state.seller.name : ''}
              // onChange={this.setSeller.bind(this, 'name')}
              >
              </EnTextFormGroup>
            )}
          {isAllow("VIEW_SELLER_INFORMATION") &&
            (
              <EnTextFormGroup
                id='customer-email'
                disabled={true}
                containerClassName='col-md-3'
                label='เบอร์ติดต่อพนักงาน'
                value={this.state.seller ? this.state.seller.phone : ''}
                // onChange={this.setSeller.bind(this, 'phone')}
                validations={[phone]}
              >
              </EnTextFormGroup>
            )}
        </div>

        {this.showAddressForm() && (
          <div className='row'>
            <AddressForm
              disabled={contractorMode || isBuAccount || isAdminBU || this.props.statusClaim}
              addressData={jobInfo.addressInfo}
              onChange={(addrData) => { this.props.job.clearAddressData(); }}
              onAddressSelected={(addressObject) => this.changeAddressSet(addressObject)}
              validations={[required]}
            />
          </div>
        )}
        <div className='row'>
          <EnTextFormGroup
            id='address'
            disabled={disabled}
            containerClassName='col-md-6'
            label='ที่อยู่ (บ้านเลขที่ ซอย ถนน)'
            value={jobInfo.address}
            onChange={this.saveAddress.bind(this)}
            validations={[required]}
          />
          <EnTextFormGroup
            id='landmark'
            disabled={disabled}
            containerClassName='col-md-6'
            label='จุดสังเกต'
            value={jobInfo.addressInfo.landmark}
            onChange={this.saveLandmark.bind(this)}
          />
        </div>

        <div className='row'>
          {isAsaStore && (
            <div className='row'>
              <FormGroup>
                <Col sm={8} smOffset={1}>
                  <Checkbox
                    id='requestLocation'
                    name='requestLocation'
                    title='ระบุพิกัดเพื่อความแม่นยำ'
                    checked={
                      jobInfo.asa_data
                        ? jobInfo.asa_data.request_location
                        : false
                    }
                    onChange={this.setRequestLocation.bind(this)}
                  >
                    {' '}
                    ระบุพิกัดเพื่อความแม่นยำ
                  </Checkbox>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={12}>{this.displayGoogleMap(jobInfo)}</Col>
              </FormGroup>
            </div>
          )}
        </div>
        <div className='row'>
          <FormGroup containerClassName='col-md-12' label='ลักษณะงาน'>
            <EnDropDown
              id='job-type'
              disabled={disabled}
              value={jobInfo.jobType}
              onChange={this.saveInfo.bind(this, 'jobType')}
            >
              {typeOfJobs}
            </EnDropDown>
          </FormGroup>
        </div>
        <div className='row'>
          <FormGroup
            containerClassName={`col-md-${otherWork === 0 ? '6' : '12'}`}
            label={this.state.isAsa ? 'ประเภทงาน *' : 'ประเภทงาน'}
          >
            <EnDropDown
              id='type-work'
              disabled={disabled}
              value={jobInfo.typeOfWork[0]}
              onChange={(e) => this.handleTypeOfWork(e, 'typeOfWork')}
            >
              {typeOfWork}
            </EnDropDown>
          </FormGroup>
          {otherWork === 0 && (
            <EnTextFormGroup
              id='other-work'
              disabled={disabled}
              containerClassName='col-md-6'
              label='ระบุเพิ่มเติม'
              value={jobInfo.otherWork}
              onChange={this.saveInfo.bind(this, 'otherWork')}
              validations={[required]}
            />
          )}
        </div>
        <div className='row'>
          {sku && sku.length > 0 && (
            <FormGroup
              containerClassName={`col-md-${jobInfo.jobType === 'I' ? '6' : '12'
                }`}
              label={this.state.isAsa ? 'รายการสินค้า *' : 'รายการสินค้า'}
            >
              <EnDropDown
                id='product'
                disabled={disabled}
                value={jobInfo.sku}
                onChange={this.saveInfo.bind(this, 'sku')}
                validations={[required]}
              >
                {productItems}
              </EnDropDown>
            </FormGroup>
          )}
          {jobInfo.jobType === 'I' &&
            jobInfo.typeOfWork.indexOf(1) >= 0 &&
            jobInfo.storeId !== config.qchang.storeId && (
              <FormGroup
                containerClassName='time-work col-md-6'
                label='จำนวน (ม้วน)'
              >
                <EnNumeric
                  disabled={disabled}
                  min={1}
                  onChange={this.saveInfo.bind(this, 'productQty')}
                  value={jobInfo.productQty}
                />
              </FormGroup>
            )}
        </div>

        <div className='row'>
          <FormGroup
            containerClassName='col-md-6'
            label={`วันที่เข้า${isInstallation ? 'ติดตั้ง' : 'สำรวจ'}`}
          >
            <EnDateRangePicker
              withPortal
              numberOfMonths={1}
              disabled={disabled || jobInfo.customer_approved}
              ref={(e) => {
                this.dptDateRange = e;
              }}
              startDate={
                jobInfo.startDate ? moment(jobInfo.startDate) : undefined
              }
              endDate={jobInfo.endDate ? moment(jobInfo.endDate) : undefined}
              onDateChange={this.onDateRangChange.bind(this)}
              isDayBlocked={this.checkDayBlock.bind(this)}
              isOutsideRange={(date) => {
                const now = moment().utcOffset(7).startOf('day'); // Current date with UTC offset of +7
                const futureDate = now.clone().add(550, 'days'); // 550 days from now

                return !date.isBetween(now, futureDate, 'day', '[]');
              }}
              minimumNights={0}
              startDatePlaceholderText='วันที่เริ่มต้น'
              endDatePlaceholderText='วันที่สิ้นสุด'
            />
          </FormGroup>
          <div>{availableHtml}</div>
        </div>
        <div className='row'>
          <FormGroup
            containerClassName='col-md-12'
            label={this.state.isAsa ? 'ทีมช่าง *' : 'ทีมช่าง'}
          >
            {teams.length > 0 ? (
              <EnDropDown
                id='contractor'
                disabled={disabled || jobInfo.customer_approved}
                ref={(e) => {
                  this.ddlContractor = e;
                }}
                value={this.state.selectedContractorValue}
                onChange={event => this.onTeamChanged(event.target.value)}
                validations={[required]}
              >
                {teams}
              </EnDropDown>
            ) : (
              <div style={{ color: 'red' }}>*ไม่มีช่างพร้อมให้บริการ*</div>
            )}
            {!contractorMode && searchContractorHtml}
          </FormGroup>

          <div className='col-md-12'>
            {!superAdminMode &&
              !contractorMode &&
              jobInfo.contractorId &&
              jobInfo.sku &&
              store &&
              store.po_require &&
              !(
                jobInfo.cost &&
                ((jobInfo.jobType === 'I' && jobInfo.cost.installation) ||
                  (jobInfo.jobType === 'S' && jobInfo.cost.survey))
              ) && (
                <div className='col-md-12'>
                  <span style={{ color: 'red' }}>
                    ระบบไม่สามารถออก PO ให้อัตโนมัติได้
                    เนื่องจากท่านยังไม่ได้ระบุราคาจ่ายช่างทีมนี้
                  </span>
                  &nbsp;&nbsp;
                  <EnButton
                    id='back-button'
                    className='btn-success'
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.goToManageCost(jobInfo.contractorId);
                    }}
                    disabled={this.props.statusClaim}
                  >
                    ระบุราคาจ่ายช่าง
                  </EnButton>
                </div>
              )}
            {superAdminMode &&
              !contractorMode &&
              jobInfo.contractorId &&
              jobInfo.sku &&
              !(
                (jobInfo.cost ? jobInfo.cost.survey : '') ||
                (jobInfo.cost ? jobInfo.cost.installation : '')
              ) && (
                <div className='col-md-12'>
                  <span style={{ color: 'red' }}>
                    ระบบไม่สามารถออก PO ให้อัตโนมัติได้
                    เนื่องจากท่านยังไม่ได้ระบุราคาจ่ายช่างทีมนี้
                  </span>
                </div>
              )}
          </div>

          <div className='col-md-6'>
            {this.getAssignContractorHtml(jobInfo)}
          </div>
        </div>

        {/* Add new Activity Log status like created or installation_requested */}
        {!contractorMode && stepCreateOrInstall && (
          <div>
            <div className='row'>
              <div className='col-md-12'>
                <div
                  style={theme.activityLogTextArea}
                  dangerouslySetInnerHTML={{ __html: this.activityLogInTextarea() }}
                />
              </div>
            </div>
          </div>
        )}
        {/* End Scope */}
        {(isAsaStore || superAdminMode) && (
          <div className='row'>
            {isAllow("VIEW_EMPLOYEE_INFORMATION") && (
              <FormGroup containerClassName='col-md-4' label='รหัสพนักงาน'>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.setSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  inputProps={inputProps}
                  theme={theme}
                />
              </FormGroup>
            )}
            {isAllow("VIEW_EMPLOYEE_INFORMATION") && (
              <EnTextFormGroup
                id='contact-name'
                disabled={isAllow("EDIT_EMPLOYEE_INFORMATION") ? disabled || this.state.isAsa : true}
                containerClassName='col-md-4'
                label='ชื่อพนักงานขาย'
                value={jobInfo.contactName}
                onChange={this.saveInfo.bind(this, 'contactName')}
                validations={[required]}
              />
            )}
            {isAllow("VIEW_EMPLOYEE_INFORMATION") && (
              <EnTextFormGroup
                id='contact-phone'
                disabled={isAllow("EDIT_EMPLOYEE_INFORMATION") ? disabled || this.state.isAsa : true}
                containerClassName='col-md-4'
                label='เบอร์พนักงานขาย'
                value={jobInfo.contactPhone}
                onChange={this.saveInfo.bind(this, 'contactPhone')}
                validations={[required]}
              >
                <CallingButton phone={jobInfo.contactPhone} isButtonGroup />
              </EnTextFormGroup>
            )}
          </div>
        )}
        {!(isAsaStore || superAdminMode) && (
          <div className='row'>
            <EnTextFormGroup
              id='contact-name'
              disabled={disabled}
              containerClassName='col-md-6'
              label='ชื่อพนักงานขาย'
              value={jobInfo.contactName}
              onChange={this.saveInfo.bind(this, 'contactName')}
              validations={[required]}
            />

            <EnTextFormGroup
              id='contact-phone'
              disabled={disabled}
              containerClassName='col-md-6'
              label='เบอร์พนักงานขาย'
              value={jobInfo.contactPhone}
              onChange={this.saveInfo.bind(this, 'contactPhone')}
              validations={[required]}
            >
              <CallingButton phone={jobInfo.contactPhone} isButtonGroup />
            </EnTextFormGroup>
            <FormGroup
              label='อีเมลล์พนักงานขาย'
              containerClassName='col-md-10'
              style={{ paddingLeft: 15 }}
            >
              {_.map(jobInfo.contactEmails, (mail, index) => {
                const lenghtMails = jobInfo.contactEmails.length;
                return (
                  <div
                    className='row'
                    style={{ paddingLeft: 15 }}
                    key={mail.key}
                  >
                    <EnTextFormGroup
                      containerClassName='col-md-10'
                      type='email'
                      label=''
                      value={mail.value}
                      onChange={(e) => {
                        this.props.job.setContractEmail(index, e.target.value);
                      }}
                      validations={[required]}
                      disabled={disabled}
                    // id={`email-${mail.toString()}`}
                    />
                    {index + 1 < lenghtMails && isBuAccount && (
                      <div className='form-group col-md-2'>
                        <EnButton
                          style={{ marginTop: '0px' }}
                          className='btn btn-danger'
                          onClick={(e) => {
                            this.props.job.deleteContractEmails(mail.key);
                          }}
                          disabled={this.props.statusClaim}
                        // disabled={data.surveyText === ''}
                        >
                          <i
                            className='fa fa-minus btn-icon'
                            aria-hidden='true'
                          />
                          ลบ
                        </EnButton>
                      </div>
                    )}
                    {lenghtMails === index + 1 && (
                      <div className='form-group col-md-2'>
                        <EnButton
                          style={{ marginTop: '0px' }}
                          className='btn btn-success'
                          onClick={(e) => {
                            this.props.job.addContractEmails();
                          }}
                          disabled={this.props.statusClaim}
                        // disabled={data.surveyText === ''}
                        >
                          <i
                            className='fa fa-plus btn-icon'
                            aria-hidden='true'
                          />
                          เพิ่ม
                        </EnButton>
                      </div>
                    )}
                  </div>
                );
              })}
            </FormGroup>
          </div>
        )}
        <div className='row'>
          <EnTextFormGroup
            id='description'
            disabled={disabled}
            containerClassName='col-md-12'
            label='รายละเอียดเพิ่มเติม'
            value={jobInfo.description}
            onChange={this.saveInfo.bind(this, 'description')}
          />
        </div>

        {this.state.promotionText && (
          <div className='row'>
            <EnTextFormGroup
              id='promotion'
              disabled
              containerClassName='col-md-12'
              label='โปรโมชั่น'
              value={this.state.promotionText}
            />
          </div>
        )}

        {contractorMode && jobInfo.requestTax && jobInfo.customerTax && (
          <div>
            <div className='row'>
              <div className='col-md-6' style={{ marginLeft: '20px' }}>
                <Checkbox
                  id='requestTax'
                  name='requestTax'
                  title='ต้องการใบกำกับภาษีเต็มรูปแบบ'
                  disabled
                  checked={jobInfo.requestTax}
                >
                  ต้องการใบกำกับภาษีเต็มรูปแบบ
                </Checkbox>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-10 col-md-offset-2'>
                <label>
                  <input
                    type='radio'
                    value='individual'
                    checked={jobInfo.customerTax.type === 'individual'}
                    readOnly
                    disabled
                  />{' '}
                  &nbsp;&nbsp;บุคคลธรรมดา
                </label>
                &nbsp;&nbsp;&nbsp;
                <label>
                  <input
                    type='radio'
                    value='juristic'
                    checked={jobInfo.customerTax.type === 'juristic'}
                    readOnly
                    disabled
                  />
                  &nbsp;&nbsp;นิติบุคคล/บริษัท
                </label>
              </div>
            </div>
            <div className='row'>
              <EnTextFormGroup
                id='name'
                containerClassName='col-md-6'
                type='text'
                label={
                  jobInfo.customerTax.type === 'individual'
                    ? 'ชื่อ-นามสกุล'
                    : 'ชื่อบริษัท'
                }
                value={jobInfo.customerTax.name}
                disabled
              />
              <EnTextFormGroup
                id='address'
                containerClassName='col-md-6'
                type='text'
                label='ที่อยู่ (บ้านเลขที่ ซอย ถนน)'
                value={jobInfo.customerTax.address.no}
                disabled
              />
            </div>
            <div className='row'>
              <AddressForm
                addressData={{
                  subDistrict: jobInfo.customerTax.address.sub_district.name,
                  subDistrictCode:
                    jobInfo.customerTax.address.sub_district.code,
                  district: jobInfo.customerTax.address.district.name,
                  districtCode: jobInfo.customerTax.address.district.code,
                  province: jobInfo.customerTax.address.province.name,
                  provinceCode: jobInfo.customerTax.address.province.code,
                  postCode: jobInfo.customerTax.address.postcode,
                }}
                disabled
              />
            </div>
            <div className='row'>
              <EnTextFormGroup
                id='taxId'
                containerClassName='col-md-6'
                type='text'
                label={
                  jobInfo.customerTax.type === 'individual'
                    ? 'หมายเลขบัตรประชาชน'
                    : 'หมายเลขประจำตัวผู้เสียภาษีอากร'
                }
                value={jobInfo.customerTax.tax_id}
                disabled
              />
              {jobInfo.customerTax.type === 'juristic' && (
                <EnTextFormGroup
                  id='branch'
                  containerClassName='col-md-6'
                  type='text'
                  label={'หมายเลขสำนักงานใหญ่ / สาขา'}
                  value={jobInfo.customerTax.branch}
                  disabled
                />
              )}
              {jobInfo.customerTax.type === 'individual' && (
                <EnTextFormGroup
                  id='phone'
                  containerClassName='col-md-6'
                  type='text'
                  label='เบอร์ติดต่อ'
                  value={jobInfo.customerTax.phone}
                  disabled
                />
              )}
            </div>
            {jobInfo.customerTax.type === 'juristic' && (
              <div className='row'>
                <EnTextFormGroup
                  id='phone'
                  containerClassName='col-md-6'
                  type='text'
                  label='เบอร์ติดต่อ'
                  value={jobInfo.customerTax.phone}
                  disabled
                />
              </div>
            )}
          </div>
        )}
        <div className='row'>
          <EnTextFormGroup
            id='jobRemark'
            disabled={disabled}
            containerClassName='col-md-12'
            label='หมายเหตุ'
            value={jobInfo.jobRemark}
            onChange={this.saveInfo.bind(this, 'jobRemark')}
          />
        </div>
        {!contractorMode && (
          <div className='row'>
            <EnTextFormGroup
              id='adminRemark'
              disabled={disabled}
              containerClassName='col-md-12'
              label='หมายเหตุจาก admin'
              value={jobInfo.adminRemark}
              onChange={this.saveInfo.bind(this, 'adminRemark')}
            />
          </div>
        )}

        {
          // Add new Remark from customer ref QCNS-2179
          <div className='row'>
            <EnTextFormGroup
              id='remark'
              disabled={disabled || this.state.disableCustomerRemark}
              containerClassName='col-md-8'
              label='หมายเหตุจากลูกค้า'
              value={jobInfo.remark || ""}
              onChange={this.saveInfo.bind(this, 'remark')}
            />
            <div className='col-md-1'></div>
            <div className='col-md-3' style={{ paddingTop: 26 }}>
              {
                !contractorMode &&
                <EnButton
                  id='load-customer-edit-remark-button'
                  className='btn-warning'
                  style={{ width: "100%", marginLeft: 0 }}
                  onClick={() => this.getCustomerModal()}
                  disabled={this.props.statusClaim}
                >
                  <i className='fa fa-pencil btn-icon' />
                  แก้ไข
                </EnButton>
              }
            </div>
          </div>
        }

        <div className='row'>
          {isInstallation ? (
            <FormGroup containerClassName='col-md-6' label='วันที่ส่งสินค้า'>
              {jobInfo.deliveryDate ? (
                <EnDatePicker
                  disabled={disabled}
                  ref={(e) => {
                    this.dptDate = e;
                  }}
                  initialDate={moment(jobInfo.deliveryDate)}
                  isOutsideRange={(dates) => {
                    return false;
                  }}
                  onDateChange={this.onDateChange.bind(this, 'deliveryDate')}
                />
              ) : (
                <EnDatePicker
                  disabled={disabled}
                  ref={(e) => {
                    this.dptDate = e;
                  }}
                  isOutsideRange={(dates) => {
                    return false;
                  }}
                  onDateChange={this.onDateChange.bind(this, 'deliveryDate')}
                />
              )}
            </FormGroup>
          ) : null}
        </div>
        {(this.state.shareable || superAdminMode) && !contractorMode && (
          <div className='row'>
            <AdminShareStore
              superAdminMode={superAdminMode}
              onStoreChange={(items) => {
                this.props.job.setShareStore(items);
              }}
              statusClaim={statusClaim}
            />
          </div>
        )}
        {this.state.orderId && !contractorMode && isAllow("VIEW_JOB_TRACKING_PROCESS") && (
          <div className='col-md-12'>
            <a
              href={this.state.trackingUrl}
              target='_blank'
            >
              <i className='fa fa-truck' aria-hidden='true' />
              &nbsp;&nbsp;ตรวจสอบสถานะ
            </a>
            &nbsp;&nbsp;<a onClick={() => this.copyToClipboard()} style={{ cursor: 'pointer' }}>
              <i className='fa fa-copy' aria-hidden='true' />
              &nbsp;&nbsp;คัดลอก
            </a>
          </div>
        )}

        {quotationWillBeExpiredIn7days && this.state.isFinalJobOrInstallationJob && !jobInfo.is_paid && (
          <div className='col-md-12'>
            <div style={{ display: 'flex', gap: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className='label label-danger'>! แจ้งเตือนใบเสนอราคาใกล้หมดอายุ กรุณากดต่ออายุใบเสนอราคาก่อนชำระเงิน</div>
              </div>
              <EnButton
                className='btn-info'
                onClick={(e) => this.extendQuotationTime()}
              >
                ต่ออายุใบเสนอราคา
              </EnButton>
            </div>
          </div>)}
        {isAllow("UPLOAD_AND_VIEW_ADDITIONAL_FILES") && (
          <div className='row'>
            <div className='col-md-12'>
              {listItem}
              {!contractorMode && !isReadOnly && dropzone}
            </div>
          </div>
        )}
        {!contractorMode && (
          <div>
            <h4>
              {' '}
              <i className='fa fa-file-text-o btn-icon' aria-hidden='true' />
              เอกสาร
            </h4>
            <div className='row'>
              <div className='col-md-6'>{quotationListItem}</div>
            </div>
            <div className='row'>
              <div className='col-md-12'>{receiptListItem}</div>
            </div>
            <div className='row'>
              <div className='col-md-12'>{pcListItem}</div>
            </div>
            {isShowContractorReceiptButton && (
              <div>
                <div className='row'>
                  <div className='col-md-12'>{contractorReceiptListItem}</div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>{creditSaleListItem}</div>
                </div>
              </div>
            )}
            {((!isReadOnly) || isBuAccount) && jobInfo.jobType === 'S' && (
              <div className='row'>
                <div className='col-md-12'>{poListItem}</div>
              </div>
            )}
            {((!isReadOnly) || isBuAccount) && jobInfo.jobType === 'I' && (
              <div className='row'>
                <div className='col-md-12'>{poInstallationList}</div>
              </div>
            )}
            {hasCreditProvider && (
              <div className='row'>
                <div className='col-md-12'>{creditProviderListItem}</div>
              </div>
            )}
            <div className='row'>
              <div className='col-md-12'>{guaranteeListItem}</div>
            </div>
            <div className='row'>
              <div className='col-md-12'>{claimListItem}</div>
            </div>
            {!isReadOnly && (
              <div className='row'>
                <div className='col-md-12'>{commisionListItem}</div>
              </div>
            )}
            {!isReadOnly && (
              <div className='row'>
                <div className='col-md-12'>{listChargeFile}</div>
              </div>
            )}
            <div className='row'>
              <div className='col-md-12'>
                {
                isAllow("CREATE_AND_EDIT_QUOTATION") 
                ? !isSCGHome && isShowQuotationButton 
                && !this.handleHideButton(jobInfo, 'editQuotation')
                && quotationWording === 'แก้ไข'
                && (
                  <EnButton
                    id='open-quotation-button'
                    className='btn-primary btn-quotation'
                    onClick={this.gotoQuotationPage.bind(this)}
                    disabled={this.props.statusClaim}
                  >
                    <i
                      className='fa fa-file-text-o btn-icon'
                      aria-hidden='true'
                    />
                    แก้ไขใบเสนอราคา
                  </EnButton>
                ) : <div></div>
                }
                {isAllow("CREATE_AND_EDIT_RC") ? !isSCGHome && isShowReceiptButton && !this.handleHideButton(jobInfo, rcButton) && (
                  <EnButton
                    id='open-quotation-button'
                    className='btn-primary btn-quotation'
                    onClick={this.gotoInvoicePage.bind(this)}
                    disabled={this.props.statusClaim}
                  >
                    <i
                      className='fa fa-file-text-o btn-icon'
                      aria-hidden='true'
                    />
                    {invoiceWording}ใบรับเงิน
                  </EnButton>
                ) : <div></div>}
                {isAllow("CREATE_AND_EDIT_PURCHASE_ORDER") ? !isSCGHome && isShowManualPo && !this.handleHideButton(jobInfo, poButton) && (
                  <EnButton
                    id='back-button'
                    className='btn-primary btn-quotation'
                    onClick={(e) => {
                      e.preventDefault();
                      if (jobInfo.otherExpense && jobInfo.otherExpense.total_expense > 0 && (jobInfo.otherExpense.detail && jobInfo.otherExpense.detail.length > 0)) {
                        swal.fire({
                          title: 'แจ้งเตือนในการแก้ไขเอกสาร PO',
                          text: 'เนื่องจากมีรายการหักค่าใช้จ่าย JO ดำเนินการไว้ เลือก "ตกลง" หากต้องการดำเนินการแก้ไข',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonText: 'ตกลง',
                          cancelButtonText: 'ยกเลิก',
                          reverseButtons: true
                        }).then((result) => {
                          if (result.isConfirmed) {
                            this.gotoManagePoPage(this);
                          }
                        })
                      } else {
                        this.gotoManagePoPage(this);
                      }
                    }}
                    disabled={this.props.statusClaim}
                  >
                    <i
                      className='fa fa-file-text-o btn-icon'
                      aria-hidden='true'
                    />
                    {purchaseOrderWording}เอกสาร PO
                  </EnButton>
                ) : <div></div>}
                {isAllow("CREATE_AND_EDIT_GUARUNTEE_DOCUMENTS") ?
                  !contractorMode &&
                  (jobInfo.status === 'installation_finished' ||
                    jobInfo.status === 'installation_accepted' ||
                    (jobInfo.jobType === 'I' &&
                      jobInfo.status === 'paid_to_contractor')) && (
                    <EnButton
                      id='back-button'
                      className='btn-primary btn-quotation'
                      onClick={(e) => {
                        this.gotoCreateGuaranteePage(this);
                      }}
                      disabled={this.props.statusClaim}
                    >
                      <i
                        className='fa fa-file-text-o btn-icon'
                        aria-hidden='true'
                      />
                      แก้ไขใบรับประกัน
                    </EnButton>
                  ) : <div></div>}
                {isAllow("CREATE_AND_EDIT_INVOICE") ? !isSCGHome && !contractorMode && showInvoiceComission && (
                  <EnButton
                    id='back-button'
                    className='btn-primary btn-quotation'
                    onClick={(e) => {
                      e.preventDefault();
                      this.createInvoiceComission(this);
                    }}
                    disabled={this.props.statusClaim}
                  >
                    <i
                      className='fa fa-file-text-o btn-icon'
                      aria-hidden='true'
                    />
                    ออกใบแจ้งหนี้
                  </EnButton>
                ) : <div></div>}
                {
                isAllow("CREATE_AND_EDIT_QUOTATION")
                ? showAddQuotation && !this.handleHideButton(jobInfo, 'addQuotation')
                && (
                  <EnButton
                    id='open-quotation-button'
                    className='btn-success  btn-quotation'
                    onClick={this.gotoCreateQuotationPage.bind(this)}
                    disabled={this.props.statusClaim}
                  >
                    <i className='fa fa-plus' aria-hidden='true' style={{ paddingRight: '5px'}} />
                     เพิ่มใบเสนอราคา
                  </EnButton>
                ) : <div></div>
                }
                {
                  isAllow("CREATE_AND_EDIT_QUOTATION") ? 
                  !isSCGHome
                  && isShowQuotationButton
                  && !this.handleHideButton(jobInfo, 'createQuotation')
                  && quotationWording === 'เพิ่ม'
                  && (
                    <EnButton
                      id='open-quotation-button'
                      className='btn-success btn-quotation'
                      onClick={this.gotoQuotationPage.bind(this)}
                      disabled={this.props.statusClaim}
                    >
                      <i className='fa fa-plus' aria-hidden='true' style={{ paddingRight: '5px'}} />
                      เพิ่มใบเสนอราคา
                    </EnButton>
                  ) : <div></div>
                }
                {isShowContractorReceiptButton && (
                  <div>
                    {isAllow("CREATE_AND_EDIT_CRC") && (
                      <EnButton
                        id='open-quotation-button'
                        className='btn-primary btn-quotation'
                        onClick={this.gotoContractorReceiptPage.bind(this)}
                        disabled={this.props.statusClaim}
                      >
                        <i
                          className='fa fa-file-text-o btn-icon'
                          aria-hidden='true'
                        />
                        ออกใบเสร็จรับเงินแทนช่าง
                      </EnButton>)}
                    {isAllow("CREATE_AND_EDIT_CS") && (
                      <EnButton
                        id='open-quotation-button'
                        className='btn-primary btn-quotation'
                        onClick={this.gotoCreditSalePage.bind(this)}
                        disabled={this.props.statusClaim}
                      >
                        <i
                          className='fa fa-file-text-o btn-icon'
                          aria-hidden='true'
                        />
                        ออกใบขายเงินเชื่อ
                      </EnButton>)}
                  </div>
                )}
                {isAllow("CREATE_AND_EDIT_CLAIM_DOCUMENTS") ? isClaim && (
                  <EnButton
                    id='open-claim-button'
                    className='btn-primary btn-quotation'
                    onClick={this.gotoClaimPage.bind(this)}
                    disabled={this.props.statusClaim}
                  >
                    <i
                      className='fa fa-file-text-o btn-icon'
                      aria-hidden='true'
                    />
                    ออกเอกสารการเคลม
                  </EnButton>
                ) : <div></div>}
                {isAllow("CREATE_COMPLAIN_JOB_DOCUMENT") ? !isSCGHome && showClaimButton && !isBuAccount && !this.handleHideButton(jobInfo, 'complain') && (
                  <EnButton
                    id='open-claim-dialog'
                    className='btn-primary btn-quotation'
                    onClick={this.gotoClaimModal.bind(this)}
                    disabled={this.props.statusClaim}
                  >
                    <i
                      className='fa fa-file-text-o btn-icon'
                      aria-hidden='true'
                    />
                    Complain
                  </EnButton>
                ) : <div></div>}
                {isAllow("CREATE_CREDIT_PROVIDER_FORM") ? !isSCGHome && isShowCreditProviderButton && !isBuAccount && !this.handleHideButton(jobInfo, 'credit') && (
                  <EnButton
                    id='open-credit-provider'
                    className='btn-primary btn-quotation'
                    onClick={this.gotoCreditProviderPage.bind(this)}
                    disabled={this.props.statusClaim}
                  >
                    <i
                      className='fa fa-file-text-o btn-icon'
                      aria-hidden='true'
                    />
                    Credit ซื้อของ
                  </EnButton>
                ) : <div></div>}
                {
                  this.state.isInstallerSuperAdmin
                    && isAllow("CREATE_AND_EDIT_OTHER_EXPENSE")
                    && isAllow("DELETE_OTHER_EXPENSE")
                    && get(this.state.job, 'saleModel') == 'MarketPlace'
                    && (poInstallationFiles.length || poSurveyFiles.length) ?
                    <EnButton
                      id='open-jo-form'
                      className='btn-primary btn-quotation'
                      onClick={this.gotoJoExpensePage.bind(this)}
                      disabled={this.props.statusClaim}
                    >
                      <i
                        className='fa fa-file-text-o btn-icon'
                        aria-hidden='true'
                      />
                      หักค่าใช้จ่าย JO
                    </EnButton> : ''
                }
              </div>
            </div>
          </div>
        )}
        {
          // Delete unused from JobRequest this.props.stepAt === 'assigned'  <stepper 2> Reference with QCNS-2178
        }
        {contractorMode && (
          // <div className="row">
          <div>
            <h4>
              {' '}
              <i className='fa fa-file-text-o btn-icon' aria-hidden='true' />
              เอกสาร
            </h4>
            <div className='row'>
              <div className='col-md-12'>{receiptListItem}</div>
            </div>
            {!isReadOnly && jobInfo.jobType === 'S' && (
              <div className='row'>
                <div className='col-md-12'>{poListItem}</div>
              </div>
            )}
            {!isReadOnly && jobInfo.jobType === 'I' && (
              <div className='row'>
                <div className='col-md-12'>{poInstallationList}</div>
              </div>
            )}
            {!isReadOnly && (
              <div className='row'>
                <div className='col-md-12'>{commisionListItem}</div>
              </div>
            )}
            {!isReadOnly && (
              <div className='row'>
                <div className='col-md-12'>{listChargeFile}</div>
              </div>
            )}
          </div>
        )}
        {
          // contractor can reject job
          !!(
            contractorMode &&
            !jobInfo.contractorAccept &&
            (jobInfo.status === 'assigned' ||
              jobInfo.status === 'installation_requested' ||
              jobInfo.status === 'installation_confirmed')
          ) && this.props.componentMessage
        }

        {
          // admin can reject job
          !!(
            !contractorMode &&
            (jobInfo.status === 'assigned' ||
              jobInfo.status === 'installation_requested' ||
              jobInfo.status === 'installation_confirmed')
          ) && adminComponentMessage
        }
        <div className='row'>
          <div className='col-md-12'>
            {!isSCGHome &&
              this.props.showJobStep &&
              jobInfo.status === 'assigned' || jobInfo.status === 'installation_confirmed' &&
              jobInfo.jobType === 'I' || 'S' && (jobInfo.status === 'assigned' || jobInfo.status === 'installation_confirmed') ?
              <EnButton className="btn-warning pull-right" onClick={() => {
                if (contractorMode && (!jobInfo.contractorTeam || (!jobInfo.contractorTeam.mainContractor && !jobInfo.contractorTeam.subContractor.length))) {
                  this.addContractor(jobInfo.contractorTeam, jobInfo.contractorId, jobInfo.jobType === 'S' ? 'survey_started' : 'installation_started');
                } else {
                  jobInfo.jobType === 'S' ? nextState('survey_started') : nextState('installation_started');
                }
              }}>
                <i className="fa fa-play btn-icon" aria-hidden="true" />{jobInfo.jobType === 'S' ? 'เริ่มสำรวจ' : 'เริ่มการติดตั้ง'}
              </EnButton> : getDisplayStatusButton(
                jobInfo.jobType,
                jobInfo.status,
                contractorMode,
                undefined,
                undefined,
                undefined,
                undefined,
                canStartJob
              )
            }
            {!isSCGHome &&
              this.props.showJobStep &&
              !contractorMode &&
              jobInfo.jobType === 'I' &&
              jobInfo.is_paid &&
              (jobInfo.status === 'installation_requested' ||
                jobInfo.status === 'created') &&
              !isReadOnly &&
              getDisplayStatusButton(
                jobInfo.jobType,
                jobInfo.status,
                contractorMode,
                async () => {
                  await this.onUploadAndNext('installation_confirmed');
                },
                !this.props.validation || !jobInfo.contractorId,
                true,
                undefined,
                canStartJob
              )}
            {isAllow("CONFIRM_JOB_PAYMENT") ? ((!contractorMode && !isReadOnly) ||
              superAdminMode ||
              isEcomAdmin) &&
              (jobInfo.status === 'created' ||
                jobInfo.status === 'installation_requested') &&
              !jobInfo.is_paid && (

                <EnButton
                  id='confirm-paid-button'
                  className='btn-success pull-right'
                  style={{ backgroundColor: '#0E1F2E', boxShadow: "none" }}
                  onClick={() => {
                    this.handleModalAuth("confirmPaid", {
                      jobId: jobInfo.id,
                      jobCode: jobInfo.jobCode,
                      typeOfJob: jobInfo.jobType,
                      jobQuotation: jobInfo.quotation
                    })
                  }}
                  disabled={
                    this.props.statusClaim ||
                    !this.props.contractors ||
                    !_.get(jobInfo, "quotation.length") ||
                    !(this.state.isFinalJobOrInstallationJob)
                  }
                >
                  ยืนยันการชำระเงิน
                </EnButton>
              ) : <div></div>}

            {!contractorMode && !isReadOnly && (
              <EnButton
                id='submit-button'
                className='btn-info pull-right'
                disabled={!this.props.validation || !teams.length || this.props.statusClaim}
                // onClick={this.onSubmit.bind(this)}
                onClick={this.getConfirmUpdateJob.bind(this)}
              >
                <i className='fa fa-floppy-o btn-icon' aria-hidden='true' />
                บันทึก
              </EnButton>
            )}

            <EnButton
              id='back-button'
              className='btn-danger pull-right'
              onClick={this.onCancel.bind(this)}
            >
              <i className='fa fa-reply btn-icon' aria-hidden='true' />
              กลับ
            </EnButton>
            {!isSCGHome &&
              !contractorMode &&
              jobInfo.id &&
              !isReadOnly &&
              !showCancelJobButton && !isBuAccount &&
              !this.handleHideButton(jobInfo, 'delete') && (
                <EnButton
                  id='delete-button'
                  className='btn-danger pull-left'
                  onClick={this.onDelete.bind(this)}
                  disabled={this.props.statusClaim}
                >
                  <i className='fa fa-trash btn-icon' aria-hidden='true' />
                  ลบ
                </EnButton>
              )}
            {!isSCGHome &&
              !contractorMode &&
              !isReadOnly &&
              !isInValidReverseJobStatus && (
                <EnButton
                  className='btn-primary pull-left'
                  onClick={this.onReverseJobStatus.bind(this)}
                  disabled={this.props.statusClaim}
                >
                  <i className='fa fa-refresh btn-icon' aria-hidden='true' />{' '}
                  ย้อนสถานะงาน
                </EnButton>
              )}
            {!isSCGHome &&
              !contractorMode &&
              jobInfo.id &&
              !isReadOnly &&
              false &&
              showCancelJobButton && (
                <EnButton
                  id='delete-cancel'
                  className='btn-danger pull-left'
                  onClick={this.onCancelJob.bind(this)}
                  disabled={this.props.statusClaim}
                >
                  <i className='fa fa-ban btn-icon' aria-hidden='true' />
                  ยกเลิกงาน
                </EnButton>
              )}
          </div>
        </div>
        <ScrollButton scrollStepInPx='50' delayInMs='16.66' />
      </FormValidation>
    );
  }
}
export default inject(
  'auth',
  'job',
  'typeofwork',
  'sku',
  'contractor',
  'contact',
  'misc',
  'employee',
  'permission',
  'admin',
  'contractorblockedpayment',
  'quotation',
  'modal',
  'guarantee',
  'subContractor'
)(observer(JobRequest));
