import React from 'react';
// import { observer, inject } from 'mobx-react';
import { required } from '../../../components/form/FormValidation';
// import EnButton from '../../../components/form/EnButton';
import EnDropDown from '../../../components/form/EnDropDown';
// import EnTextAreaFormGroup from '../../../components/form/EnTextAreaFormGroup';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
// import EnStickyFooter from '../../../components/form/EnStickyFooter';
// import Notification from '../../../components/common/Notification';
// import config from '../../../config';

export const Address = (props) => (
  <div>
    <div className="row">
      <FormGroup containerClassName="col-md-12" label="จังหวัด">
        <EnDropDown value={props.contractor.taxProvinceCode} onChange={props.onProvinceChanged}>
          {props.provinceName}
        </EnDropDown>
      </FormGroup>
    </div>
    <div className="row">
      <FormGroup containerClassName="col-md-12" label="อำเภอ / เขต">
        <EnDropDown value={props.contractor.taxDistrictCode} onChange={props.onDistrictChanged}>
          {props.districtName}
        </EnDropDown>
      </FormGroup>
    </div>
    <div className="row">
      <FormGroup containerClassName="col-md-12" label="ตำบล">
        <EnDropDown value={props.contractor.taxSubDistrictCode} onChange={props.onSubDistrictChanged}>
          {props.subDistrictName}
        </EnDropDown>
      </FormGroup>
    </div>
    <div className="row">
      <FormGroup containerClassName="col-md-12">
        <EnTextFormGroup label="รหัสไปรษณีย์" value={props.contractor.taxZipCode} onChange={props.saveRegisterInfo('zipCode')} validations={[required]} />
      </FormGroup>
    </div>
    <div className="row">
      <EnTextFormGroup containerClassName="col-md-12" label="ที่อยู่ (บ้านเลขที่ ซอย ถนน)" value={props.contractor.taxAddress} onChange={props.saveRegisterInfo('taxAddress')} validations={[required]} />
    </div>
  </div>
);
