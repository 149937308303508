import humps from 'humps';
import BaseStore from '../BaseStore';
import config from '../../config';
import { http } from '../../utils/http';
import qs from 'query-string';
import crypto from 'crypto';
import moment from 'moment';
import AuthStore from '../AuthStore'

class Permission extends BaseStore {
  constructor() {
    super();
    this.observable({
      infoLogActivity: [],
      initInfo: this.initInfoLog(),
      passwordLogActivity: '',
      openModal: false,
      openReasonModal: false,
      userName: '',
      reason: '',
      statusPassword: {
        status: false,
        text: ""
      }
    });
  }

  initInfoLog() {
    return {
      event: "",
      function_name: "",
      reference_code: "",
      user: "",
      remark: ""
    }
  }

  setPassword(value) {
    this.passwordLogActivity = value
    this.statusPassword.status = false
  }

  setShowModal(value) {
    this.openModal = value
  }

  setShowReasonModal(value) {
    this.openReasonModal = value
  }

  setReason(value) {
    if (value.length < 5 && value) {
      let res = /^[ก-๙a-zA-Z]+$/.test(value);
      if (!res) return
    }
    this.reason = value
  }

  setInfoLogActivity(value) {
    this.infoLogActivity = value
  }
  addUser(name) {
    let date = moment().format('HH:mm DD/MM/YYYY');
    this.userName = name
    this.infoLogActivity.map(element => {
      element.remark = `[${date}]` + element.remark + name
      return element.user = name
    });
  }

  encryptMD5(text) {
    return crypto.createHash('md5').update(text).digest('hex');
  }

  encryptSHA256(text) {
    return crypto.createHash('sha256').update(text).digest('hex');
  }

  isEqualArrays(arr1, arr2) {
    // Check if the arrays are the same length
    if (arr1.length !== arr2.length) return false;

    // Check if all items exist and are in the same order
    for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    // Otherwise, return true
    return true;
  };

  getUserFromEmail() {
    let user = AuthStore.getUserInfo();
    if (user.email) {
      this.addUser(user.email)
      return true
    }
    return false
  }

  async saveUamActivityLogs() {
    // api insert log
    try {
      await http.post(`${config.api.sims}/v1/uamActivityLogs`, this.infoLogActivity.toJS());
    } catch (error) {
      console.log("===>>error saveUamActivityLogs", error);
    }
  }

  async getActivityLogs(query) {
    try {
      const response = await http.get(`${config.api.sims}/v1/uamActivityLogs?${qs.stringify(query)}`);
      return response.data;
    } catch (error) {
      console.log("===>>get activityLogs", error);
    }
  }
}

export default new Permission();
