import React from 'react';
import { observer, inject } from 'mobx-react';
import FormGroup from '../../../components/form/FormGroup';
import EnButton from '../../../components/form/EnButton';
import * as stCommon from './stepCommon';
import EnTabContent from '../../../components/form/EnTabContent';
import Modal from 'react-responsive-modal';

export class EnInstalledFinished extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 'installation_finished',
      openModalBefore: false,
      openModalStart: false,
      openModalOnsite: false,
      addedOnsiteFiles: [],
    };
  }

  componentDidMount() {
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const jobId = jobInfo.id;
    this.getMapFilesCustomer(jobId);
  }
  
  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  getOptions(data) {
    const options = data.map((value, index) => {
      return {
        title: stCommon.getTitleOptions(data, index),
        active: stCommon.getActiveClass(index),
        content: () => {
          return (
            this.getOptionsContent(data, index)
          );
        },
      };
    });
    return options;
  }

  onOpenAllBefore() {
    try {
      this.setState({ openModalBefore: true });
    } catch (error) {
      // onError(error.message);
    }
  }

  onOpenAllStart() {
    try {
      this.setState({ openModalStart: true });
    } catch (error) {
      // onError(error.message);
    }
  }

  onOpenShowOnsite() {
    try {
      this.setState({ openModalOnsite: true });
    } catch (error) {
      // onError(error.message);
    }
  }

  onCloseModal = () => {
    this.setState({ openModalStart: false, openModalBefore: false, openModalOnsite: false});
  };

  onAddedOnsiteFile() {
    if (this.dropzone) {
      this.setState({
        addedOnsiteFiles: this.dropzone.getFiles(),
      });
    }
  }

  async getMapFilesCustomer (jobId) {
    const jobStore = this.props.job.toJS();
    await jobStore.getOrderByJobId(jobId);
  }

  getOptionsContent(data, index) {
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const orderId = jobStore.orderId;
    const { contractorMode, statusClaim } = this.props;
    const isLastversion = (index === 0);
    let onsitePictureHeader = stCommon.initListItemHeaderOption(
      'รูปภาพและวีดีโอหน้างานจากลูกค้า ( อัพโหลดได้สูงสุดไม่เกิน 10 ไฟล์ )'
    );
    const imagesOnsite = jobStore.onsitePicture ? jobStore.onsitePicture[0].file : [];
    const videosOnsite = jobStore.onsitePicture ? jobStore.onsitePicture[1].file : [];
    const allFiles = [...imagesOnsite, ...videosOnsite];
    let itemFileNameCustomer = stCommon.toListItemData(allFiles);
    let listOnsitePicture = stCommon.getListOnsitePicture(
      onsitePictureHeader,
      itemFileNameCustomer,
      stCommon.onDeleteOnsite.bind(this, orderId)
    );
    onsitePictureHeader.push(stCommon.listItemHeaderOpts);
    let historyNextStep = stCommon.filterHiatoryStatus(jobInfo.statusHistory, this.state.currentStep);
    historyNextStep = stCommon.sortDataByDate(historyNextStep);

    let installationBeforeFilesData = stCommon.toListItemData(jobInfo.installationBeforeFiles);
    let listHeaderInstallationBefore = stCommon.initListItemHeaderOption('รูปถ่ายหรือไฟล์แนบก่อนติดตั้ง');
    let listItemBefore = stCommon.getListItem(listHeaderInstallationBefore, installationBeforeFilesData, stCommon.onUploadItemClick.bind(this, 'installationBeforeFiles', jobInfo));
    listHeaderInstallationBefore.push(stCommon.listItemHeaderOpts);

    let listHeaderInstallationAfter = stCommon.initListItemHeaderOption('รูปถ่ายหรือไฟล์แนบหลังติดตั้ง');
    let installationAfterFilesData = stCommon.toListItemData(jobInfo.installationAfterFiles);
    let listItemAfter = stCommon.getListItem(listHeaderInstallationAfter, installationAfterFilesData, stCommon.onUploadItemClick.bind(this, 'installationAfterFiles', jobInfo));
    listHeaderInstallationAfter.push(stCommon.listItemHeaderOpts);
    let remarkAfter = jobInfo.installationAfterRemark;

    let indexHistory = index;
    if (!isLastversion) {
      remarkAfter = historyNextStep[indexHistory]['remark'];
      installationAfterFilesData = stCommon.toListItemData(data[index]['files']);
      listItemAfter = stCommon.getListItem(listHeaderInstallationAfter, installationAfterFilesData, () => { });
    }
    let dropzoneOnsite = stCommon.getDropZoneOnsitePicture(
      e => { this.dropzone = e; },
      (e) => {
        stCommon.onUploadOnsiteComplete(e, orderId);
      },
      this.onAddedOnsiteFile.bind(this),
      "",
      statusClaim
    );

    const historyInfo = stCommon.getstatusInfo(jobInfo.statusHistory, this.state.currentStep);
    const date = stCommon.formatDate(historyInfo[historyInfo.length - 1].date);
    const stepDetail = stCommon.showStepStatus('fa fa-clock-o', 'สิ้นสุดการติดตั้ง รอการยอมรับจากลูกค้า', date, 'orange');

    const { openModalBefore, openModalOnsite } = this.state;
    const imgsBefore = installationBeforeFilesData.map((item, idx) => {
      return (
        <div className="row col-md-12">
          <div className="row" style={{ paddingLeft: '16px' }}>
            <h4>{(idx + 1)}</h4>
          </div>
          <div className="row col-md-12" style={{ textAlign: 'center' }}>
            <img src={item.url} alt="" style={{ textAlign: 'center' }} width={400} height={300} mode="fill" />
          </div>
        </div>
      );
    });
    const { openModalStart } = this.state;
    const imgsStart = installationAfterFilesData.map((item, idx) => {
      return (
        <div className="row col-md-12">
          <div className="row" style={{ paddingLeft: '16px' }}>
            <h4>{(idx + 1)}</h4>
          </div>
          <div className="row col-md-12" style={{ textAlign: 'center' }}>
            <img src={item.url} alt="" style={{ textAlign: 'center' }} width={400} height={300} mode="fill" />
          </div>
        </div>
      );
    });
    const showImgsOnsite = imagesOnsite.map((item, idx) => {
      return (
        <div className="row col-md-12">
          <div className="row" style={{ paddingLeft: '16px' }}>
            <h4>{(idx + 1)}</h4>
          </div>
          <div className="row col-md-12" style={{ textAlign: 'center' }}>
            <img src={item.url} alt="" style={{ textAlign: 'center' }} width={400} height={300} mode="fill" />
          </div>
        </div>
      );
    });

    return (
      <div>
        {
          jobInfo.status === this.state.currentStep && isLastversion && stepDetail
        }
        {stCommon.showBasicInfo(jobInfo, contractorMode)}
        <div className="col-md-12">
          <Modal style={{ textAlign: 'center' }} open={openModalOnsite} onClose={this.onCloseModal} center>
            {showImgsOnsite}
          </Modal>
        </div>
        <div className="col-md-12">
          <Modal style={{ textAlign: 'center' }} open={openModalBefore} onClose={this.onCloseModal} center>
            {imgsBefore}
          </Modal>
        </div>
        <div className="col-md-12">
          <Modal style={{ textAlign: 'center' }} open={openModalStart} onClose={this.onCloseModal} center>
            {imgsStart}
          </Modal>
        </div>
        <div className="row">
          <div className='col-md-12'>
            {listOnsitePicture}
            {
              // contractorMode && (
              dropzoneOnsite
              // )
            }
          </div>
        </div>
        <div className="row">
          <div className='col-md-12'>
            <EnButton className="btn-info pull-left" onClick={this.onOpenShowOnsite.bind(this)}>
              <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
            </EnButton>
          </div>
        </div>
        <hr />
        <div className="job-info">
          <div className="title">
            <p>สรุปงานติดตั้ง</p>
          </div>
        </div>

        <div className="row wordWarp">
          <div className="col-md-6">
            {listItemBefore}
          </div>
          <div className="col-md-6">
            <FormGroup label="หมายเหตุก่อนติดตั้ง">
              {jobInfo.installationBeforeRemark ? <div>{jobInfo.installationBeforeRemark}</div> : stCommon.getDisplayEmpty('-')}
            </FormGroup>
          </div>
        </div>
        <EnButton className="btn-info pull-left" onClick={this.onOpenAllBefore.bind(this)}>
          <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
        </EnButton>
        <div className="row" />
        <hr />
        <div className="row wordWarp">
          <div className="col-md-6">
            {listItemAfter}
          </div>
          <div className="col-md-6">
            <FormGroup label="หมายเหตุหลังติดตั้ง">
              {remarkAfter ? <div>{remarkAfter}</div> : stCommon.getDisplayEmpty('-')}
            </FormGroup>
          </div>
        </div>
        <EnButton className="btn-info pull-left" onClick={this.onOpenAllStart.bind(this)}>
          <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
        </EnButton>
        <div className="row">
          <div className="col-md-12">
            <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
              <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
            </EnButton>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    let statusHistory = stCommon.filterHiatoryStatus(jobInfo.statusHistory, this.state.currentStep);
    statusHistory = stCommon.sortDataByDate(statusHistory);
    const options = this.getOptions(statusHistory);
    const isOnlyStatus = options.length < 2;

    return (
      <div>
        <EnTabContent options={options} className="tab-right" displayNone={isOnlyStatus} />
      </div>
    );
  }
}

export default inject('auth', 'job')(observer(EnInstalledFinished));
