import { isNil } from 'lodash';
import React, { Component } from 'react';

import EnTextArea from './EnTextArea';
import FormGroup from './FormGroup';

export class EnTextAreaFormGroup extends Component {
  onChange = (e) => {
    let val = e.target.value;
    if (this.props.onChange) {
      this.props.onChange({
        target: {
          value: val,
        },
      });
    }
  }

  render() {
    let { label, value, validations, rows, cols, containerClassName } = this.props;
    let options = {
      label,
      validations: !isNil(validations) && Array.isArray(validations),
    };

    return (
      <FormGroup containerClassName={containerClassName} {...options} >
        <EnTextArea rows={rows} cols={cols} disabled={this.props.disabled} ref={(e) => { this.input = e; }} value={value || ''} onChange={this.onChange} validations={validations} />
      </FormGroup>);
  }
}

export default EnTextAreaFormGroup;
