import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import swal from 'sweetalert2';
import moment from 'moment';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import Notification from '../../../components/common/Notification';
import EnToggle from '../../../components/form/EnToggle';
import config from '../../../config';
import Pagination from '../../../components/common/Pagination';
import SearchForm from './components/SearchForm';
import { isAllow } from '../../../utils/permission';

export class ManageCoupon extends React.Component {
  constructor(props) {
    super(props);

    // this.state = {};
    this.channel = [];
    this.product = [];
    this.province = [];
    this.status = [];
  }

  async componentWillMount() {
    await this.props.channel.getAllChannel();
    this.channel = this.props.channel.toJS().info;
    await this.props.misc.getAllProvince();
    let miscStore = this.props.misc.toJS();
    this.province = miscStore.miscData.province;
    this.status = [
      { name: 'Active', value: 'active' },
      { name: 'Inactive', value: 'inactive' }];

    await this.props.product.getProductList(undefined, undefined, undefined, 'coupon');
  }

  onAddNew() {
    this.props.history.push(`${config.publicUrl}/qchang/coupon/add`);
  }

  onToggle(index, value) {
    this.props.coupon.toggleStatus(index, value ? 'active' : 'inactive');
  }

  async onDelete(item) {
    let confirmDialogOptions = {
      title: 'ลบข้อมูล',
      text: 'คุณต้องการลบ รหัสส่วนลด นี้ใช่หรือไม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.props.coupon.deleteCoupon(item._id);
          await this.props.coupongroup.deleteCouponFromGroup(item._id);
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      setTimeout(() => {
        window.location.reload();
        // this.onCancel();
      }, 1000);
    }
  }

  calculateValue(promotion) {
    if (promotion.items && promotion.items.length) {
      let value = promotion.items.map(i => i.value);
      const max = Math.max(...value);
      const min = Math.min(...value);
      if (max === min) {
        return max;
      }
      return `${min} - ${max}`;
    }
    return '';
  }

  // async componentDidMount() {
  //   try {
  //     // await this.props.coupon.getList();
  //     await this.props.coupon.searchCoupon();
  //   } catch (err) {
  //     this.noti.error(err.message);
  //   }
  // }

  getTableRows(items) {
    const { pagination } = this.props.coupon.toJS();
    return items.map((item, index) => {
      const no = ((pagination.current_page - 1) * 10) + (index + 1);
      return (
        <tr key={item._id || ''}>
          <td width="5%" className="text-center">{no || ''}</td>
          <td width="10%">{item.name || ''}</td>
          <td width="10%" className="text-center">{item.type === 'S' ? 'ส่วนลดค่าสำรวจ' : 'ส่วนลดค่าติดตั้ง'}</td>
          <td width="10%">{item.code || ''}</td>
          <td width="20%" className="text-center">{Array.isArray(item.channel) ? item.channel.join(', ') : item.channel}</td>
          <td width="5%" className="text-center">{moment(item.start_date).format('LL')}</td>
          <td width="5%" className="text-center">{moment(item.end_date).format('LL')}</td>
          <td width="5%" className="text-center"><EnToggle disabled={isAllow("EDIT_COUPON_STATUS") ? false : true} checked={item.status === 'active'} onChange={this.onToggle.bind(this, index)} /></td>
          <td width="10%" className="text-center">
            <a style={{color: 'black'}} href={`${config.publicUrl}/qchang/coupon/${item._id}/edit`} target="_blank">
              <button disabled={!isAllow('EDIT_COUPON_DETAILS_BUTTON')} className="btn btn-xs" title="แก้ไข" data-tip="แก้ไข">
                <span className="fa fa-pencil" aria-hidden="true" />
              </button>
            </a>
            <button disabled={isAllow("DELETE_COUPON") ? false : true} className="btn btn-xs" title="ลบ" data-tip="ลบ" onClick={this.onDelete.bind(this, item)}>
              <span className="fa fa-trash btn-icon" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  async onPageClick(pageNum) {
    this.props.coupon.setQueryValue('page', pageNum);
    await this.props.coupon.searchCoupon();
  }

  async onSearchClick(query) {
    this.props.coupon.setQuery(query);
    this.props.coupon.setQueryValue('page', 1);
    await this.props.coupon.searchCoupon();
  }

  async onClearSearchClick(e) {
    this.props.coupon.setDefaultQuery();
    await this.props.coupon.searchCoupon();
  }

  render() {
    this.product = this.props.product.toJS().items;
    this.product = _.sortBy(this.product, 'skuCode');
    const { items, query, pagination, isLoading } = this.props.coupon.toJS();
    const rows = this.getTableRows(items);

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายการส่วนลด</h4>
              </div>
              <div className="card-content">
                <SearchForm
                  query={query}
                  channel={this.channel}
                  product={this.product}
                  province={this.province}
                  status={this.status}
                  onSearchClick={(query) => { this.onSearchClick(query) }}
                  onClearSearchClick={() => { this.onClearSearchClick() }}
                />
                {/* {this.displaySearchForm(query, this.channel, this.product, this.province, this.status)} */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="5%" className="text-center">ลำดับที่</th>
                            <th width="20%" className="text-center">ชื่อส่วนลด</th>
                            <th width="15%" className="text-center">ประเภทส่วนลด</th>
                            <th width="10%" className="text-center">รหัสส่วนลด</th>
                            <th width="10%" className="text-center">ช่องทางการขาย</th>
                            <th width="15%" className="text-center">วันที่เริ่มต้น</th>
                            <th width="15%" className="text-center">วันที่สิ้นสุด</th>
                            <th width="5%" className="text-center">สถานะ</th>
                            <th width="5%" className="text-center">
                              <button disabled={isAllow("CREATE_COUPON") ? false : true} className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={this.onAddNew.bind(this)}>
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Pagination
                      pagination={pagination}
                      handlePageClick={this.onPageClick.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('coupon', 'coupongroup', 'misc', 'channel', 'product')(observer(ManageCoupon));
