import humps from 'humps';
import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';
import { datetime } from '../utils/datetime';
import moment from 'moment';
import qs from 'query-string';
import { set, get } from 'lodash';

export class BroadcastStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      broadcasts: [],
      query: {},
      pagination: {},
    });
  }

  initialBroadcastsValue() {
    return {

    }
  }

  initialQueryValue() {
    return {

    }
  }

  async getAllBoradcasts(filter) {

  }

  async getOneBoradcast(id) {

  }

  async createBoradcasts(body) {

  }

  async updateOneBoradcasts(id, body) {

  }

  async deleteOneBoradcasts(id) {

  }

}

export default new BroadcastStore();
