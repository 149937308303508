import React, { useState, useRef, useMemo } from 'react';
import Notification from '../common/Notification';

import { IconRemove, IconImage } from './icon';
import { FileContainer, FileContent } from './container';

const SectionUpload = ({ onChange, accept = '*', multiple = false, inputRef, onDrop }) => {
  return (
    <div
      className="col-md-12"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '200px',
        padding: '10px',
        border: '1px dashed #ccc',
        borderRadius: '5px',
        zIndex: '100',
        backgroundColor: '#f9f9f9', // Added to highlight drop area
      }}
      onDrop={onDrop}
      onDragOver={(e) => e.preventDefault()} // Prevent default drag over behavior
    >
      <IconImage />
      <div>ลากและวางรูปภาพเพื่ออัพโหลด</div>
      <div style={{ color: '#ccc' }}>หรือ</div>
      <div>
        <label
          className="control-label"
          style={{
            cursor: 'pointer',
            color: '#265ed6',
            border: '1px solid #265ed6',
            borderRadius: '5px',
            padding: '5px 10px',
          }}
        >
          <span>เลือกรูป</span>
          <input style={{ display: 'none' }} type="file" accept={accept} multiple={multiple} onChange={onChange} ref={inputRef} />
        </label>
      </div>
    </div>
  );
};

const SectionPreview = ({ files, handleRemove, handleDragStart, handleDrop }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '5px',
        paddingTop: '5px',
        paddingBottom: '5px',
      }}
    >
      {files.map((file, index) => {
        return (
          <FileContainer key={index} index={index} handleDragStart={handleDragStart} handleDrop={handleDrop}>
            <FileContent file={file} />
            <IconRemove onClick={() => handleRemove(file)} />
          </FileContainer>
        );
      })}
    </div>
  );
};

const UploadImagesComponent = ({ onChange, files: fileProps, maxFile = 1, accept = '*', multiple = false }) => {
  const maxSize = 5 * 1024 * 1024;
  const allowedFileType = accept.split(',').map((item) => item.trim());

  const inputRef = useRef(null);
  const notiRef = useRef(null);

  const [files, setFiles] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);

  useMemo(() => {
    const convertFileToArray = (files) => {
      if (files) return Array.isArray(files) ? files : [files];
      return [];
    };

    const convertFiles = convertFileToArray(fileProps);
    setFiles(convertFiles);
  }, [fileProps]);

  const isMaxFile = (newFiles) => files.length + newFiles.length > maxFile;

  const isOverSize = (files) => {
    return files.some((file) => file.size > maxSize);
  }

  const isAllowedFileType = (files) => {
    // allowedFileType is * , return true
    if (allowedFileType.length === 1 && allowedFileType[0] === '*') {
      return true;
    }

    return files.every((file) => allowedFileType.includes(file.type));
  };

  const handleChange = (e) => {
    const filesFromInput = e.target.files;
    const filesArray = Array.from(filesFromInput); // Convert FileList to an array

    if (isMaxFile(filesArray)) {
      notiRef.current.error(`สามารถเพิ่มรูปได้สูงสุด ${maxFile} รูป`);
      if (inputRef.current) {
        inputRef.current.value = null;
      }
      return;
    }

    if (isOverSize(filesArray)) {
      notiRef.current.error('กรุณาอัปโหลดไฟล์ขนาดไม่เกิน 5MB ต่อไฟล์');
      if (inputRef.current) {
        inputRef.current.value = null;
      }
      return;
    }

    if (!isAllowedFileType(filesArray)) {
      notiRef.current.error('กรุณาอัปโหลดไฟล์ .jpg, .jpeg, .png, .webp เท่านั้น');
      if (inputRef.current) {
        inputRef.current.value = null;
      }
      return;
    }

    const newFiles = [...files, ...filesArray];
    setFiles(newFiles);

    if (onChange) {
      onChange(newFiles);
    }
  };

  const handleRemove = (file) => {
    const filterFiles = files.filter((f) => f !== file);
    setFiles(filterFiles);

    if (onChange) {
      onChange(filterFiles);
    }

    if (inputRef.current) {
      inputRef.current.value = null;
    }
  };

  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    e.stopPropagation();

    if (draggedIndex === null || draggedIndex === index) return;

    const newFiles = [...files];
    const draggedFile = newFiles[draggedIndex];
    newFiles.splice(draggedIndex, 1);
    newFiles.splice(index, 0, draggedFile);

    if (onChange) {
      onChange(newFiles);
    }

    setFiles(newFiles);
    setDraggedIndex(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDropArea = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const droppedFiles = e.dataTransfer.files;
    handleChange({ target: { files: droppedFiles } });
  };

  return (
    <div
      className="col-md-12"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '200px',
        paddingRight: '5px',
        border: '1px solid #ccc',
        borderRadius: '5px',
      }}
    >
      <Notification
        ref={(ref) => {
          notiRef.current = ref;
        }}
      />
      {files && files.length < maxFile && (
        <div style={{ display: 'flex' }}>
          <SectionUpload inputRef={inputRef} accept={accept} multiple={multiple} onChange={handleChange} onDrop={handleDropArea} />
        </div>
      )}

      {files && files.length > 0 && (
        <div style={{ display: 'flex' }}>
          <SectionPreview
            files={files}
            handleRemove={handleRemove}
            handleDragStart={handleDragStart}
            handleDrop={handleDrop}
            handleDragOver={handleDragOver}
          />
        </div>
      )}
    </div>
  );
};

export default UploadImagesComponent;
