import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
class Map extends Component {
  render() {
    const { center, markers } = this.props;
    const centerLatLng = { lat: center[1], lng: center[0] };
    const centerMarker = <Marker position={centerLatLng} />;
    const otherMarkers = (markers || []).map(marker => {
      const latLng = { lat: marker.location[1], lng: marker.location[0] };
      return (
        <Marker position={latLng} />
      );
    }
    );
    const GoogleMapExample = withGoogleMap(props => (
      <GoogleMap
        defaultCenter={centerLatLng}
        defaultZoom={13}>
        {centerMarker}
        {otherMarkers}
      </GoogleMap>
    ));
    return (
      <div>
        <GoogleMapExample
          containerElement={<div style={{ height: `300px`, width: '100%' }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
};
export default Map;
