import React from 'react';
import _ from 'lodash';
// import { observer, inject } from 'mobx-react';
import { required } from '../../../components/form/FormValidation';
// import EnButton from '../../../components/form/EnButton';
import EnDropDown from '../../../components/form/EnDropDown';
// import EnTextAreaFormGroup from '../../../components/form/EnTextAreaFormGroup';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
// import EnStickyFooter from '../../../components/form/EnStickyFooter';
// import Notification from '../../../components/common/Notification';
// import config from '../../../config';

export const Address = (props) => {
  const provinceText = 
    _.get( 
      props.provinceName.find(provinces => provinces.props.value === props.contractor.taxProvinceCode),
      'props.children'
    );
  const districtText = 
    _.get( 
      props.districtName.find(districts => districts.props.value === props.contractor.taxDistrictCode),
      'props.children'
    );
  const subDistrictText = 
    _.get( 
      props.subDistrictName.find(subDistricts => subDistricts.props.value === props.contractor.taxSubDistrictCode),
      'props.children'
    );
  return (
  <div>
    <div className="row">
      <FormGroup containerClassName="col-md-6" label="จังหวัด">
        {
          props.disabled === true &&
          <input type="text"
            className="form-control"
            value={provinceText}
            disabled={true}
          />
        }
        {
          props.disabled !== true &&
          <EnDropDown value={props.contractor.taxProvinceCode} onChange={props.onProvinceChanged}>
            {props.provinceName}
          </EnDropDown>
        }
      </FormGroup>
      <FormGroup containerClassName="col-md-6" label="อำเภอ / เขต">
        {
          props.disabled === true &&
          <input type="text"
            className="form-control"
            value={districtText}
            disabled={true}
          />
        }
        {
          props.disabled !== true &&
          <EnDropDown value={props.contractor.taxDistrictCode} onChange={props.onDistrictChanged}>
            {props.districtName}
          </EnDropDown>
        }
      </FormGroup>
    </div>
    <div className="row">
      <FormGroup containerClassName="col-md-6" label="ตำบล">
        {
          props.disabled === true &&
          <input type="text"
            className="form-control"
            value={subDistrictText}
            disabled={true}
          />
        }
        {
          props.disabled !== true &&
          <EnDropDown value={props.contractor.taxSubDistrictCode} onChange={props.onSubDistrictChanged}>
            {props.subDistrictName}
          </EnDropDown>
        }
      </FormGroup>
      <FormGroup containerClassName="col-md-6">
        <EnTextFormGroup disabled={true} label="รหัสไปรษณีย์" value={props.contractor.taxZipCode} onChange={props.saveRegisterInfo('taxZipCode')} validations={[required]} />
      </FormGroup>
    </div>
    <div className="row">
      <EnTextFormGroup disabled={true} containerClassName="col-md-12" label="ที่อยู่ (บ้านเลขที่ ซอย ถนน)" value={props.contractor.taxAddress} onChange={props.saveRegisterInfo('taxAddress')} validations={[required]} />
    </div>
  </div>
)};
