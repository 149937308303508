import React, { Component } from 'react';
import moment from 'moment';
import EnToggle from '../../../components/form/EnToggle';
import config from '../../../config';
import swal from 'sweetalert2';
import { isCampaignManager } from '../../../utils/permission';
import { broadcastRecipientType } from '../../../utils/data';
import numeral from 'numeral';
import QocBroadcastStore from '../../../stores/QocBroadcastStore';
import Loader from '../../../components/common/Loader';

class BroadcastRowTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      name: '',
      work_of_areas: [],
      type_of_works: [],
      published_date: '',
      published_time: '',
      createdAt: '',
      isPublish: false,
      id: '',
    };
  }

  componentDidMount() {
    this.setState({ ...this.props.broadcast });
  }

  getIsPublishedIcon(status) {
    return status ? (
      <div style={{ backgroundColor: '#D6E7D3', color: '#008A4F', borderRadius: '16px', width: '74px', margin: 'auto' }}>เผยแพร่</div>
    ) : (
      <div style={{ backgroundColor: '#FFEAEC', color: '#EB3D32', borderRadius: '16px', width: '74px', margin: 'auto' }}>ไม่เผยแพร่</div>
    );
  }

  getRecipientType(type) {
    if (!type) return '';
    return broadcastRecipientType.find((item) => item.value === type).label;
  }

  convertAnnouncementDate(date, time) {
    return (
      <span>
        <span>{date && moment(date).add(543, 'year').format('L')}</span>
        <br />
        {time && <span>{time} น.</span>}
      </span>
    );
  }

  async handleChangeToggle(id, value) {
    if (this.overTime()) {
      return swal.fire({
        title: 'ประกาศนี้เกินกำหนด',
        icon: 'error',
      });
    }

    this.setState({ isLoading: true });

    const body = value ? { isActive: true } : { isActive: false };
    await QocBroadcastStore.update(id, body);
    this.setState({ ...body });
    this.setState({ isLoading: false });
  }
  overTime() {
    const { publishedAt, publishType } = this.state;
    if (publishType === 'NOW') return false;
    const currentTime = moment();
    const publishedDate = moment(publishedAt);

    const overDate = currentTime.isAfter(publishedDate);

    return overDate;
  }

  render() {
    const { id, name, recipientType, publishedAt, isPublish, isActive, createdAt, openRate, isLoading } = this.state;
    const publishedTime = publishedAt && moment(publishedAt).format('HH:mm');
    return (
      <>
        <Loader show={isLoading} />
        <tr key={id}>
          <td
            style={{
              width: '15%',
              maxWidth: '15%',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                overflow: 'hidden',
                width: '100%',
                maxWidth: '100%',
                whiteSpace: 'normal',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            >
              {name}
            </div>
          </td>
          <td width="15%">{this.getRecipientType(recipientType)}</td>
          <td width="10%" className="text-center">
            {this.convertAnnouncementDate(createdAt)}
          </td>
          <td width="10%" className="text-center">
            {this.convertAnnouncementDate(publishedAt, publishedTime)}
            <br />
            {!isPublish && this.overTime() && <span style={{ color: 'red' }}>ประกาศนี้เลยกำหนด</span>}
          </td>
          <td width="10%">
            {openRate && openRate.numberOfSent ? (
              <div>
                ส่ง {numeral(openRate.numberOfSent).format('0,0')} <br />
                เปิด {numeral(openRate.numberOfOpen).format('0,0')} ({openRate.numberOfOpenPercent || 0}%)
              </div>
            ) : (
              '-'
            )}
          </td>
          <td width="5%">
            <EnToggle
              disabled={isCampaignManager() ? isPublish || this.overTime() : true}
              checked={isActive}
              onChange={(value) => this.handleChangeToggle(id, value)}
            />
            <br />
            <span>{isActive ? 'พร้อมเผยแพร่' : 'ฉบับร่าง'}</span>
          </td>
          <td width="5%" className="text-center">
            {this.getIsPublishedIcon(isPublish)}
          </td>
          <td width="10%" className="text-center">
            {isPublish || !isCampaignManager() ? (
              <button
                className="btn btn-xs"
                title="ดู"
                data-tip="ดูรายละเอียด"
                onClick={() => this.props.history.push(`${config.publicUrl}/superadmin/qoc-broadcasts/${id}/edit`)}
              >
                <span className="fa fa-eye" aria-hidden="true" />
              </button>
            ) : (
              <button
                disabled={isCampaignManager() ? false : true}
                className="btn btn-xs"
                title="แก้ไข"
                data-tip="แก้ไข"
                onClick={() => this.props.history.push(`${config.publicUrl}/superadmin/qoc-broadcasts/${id}/edit`)}
              >
                <span className="fa fa-pencil" aria-hidden="true" />
              </button>
            )}
            <button
              disabled={isCampaignManager() ? isPublish : true}
              className="btn btn-xs"
              title="ลบ"
              data-tip="ลบ"
              onClick={() => this.props.deleteBroadcast(id)}
            >
              <span className="fa fa-trash btn-icon" aria-hidden="true" />
            </button>
          </td>
        </tr>
      </>
    );
  }
}

export default BroadcastRowTable;
