import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import '../../assets/css/document-picker.css';
import numeral from 'numeral';
import Container from "../../layouts/Container";
import Notification from "../../components/common/Notification";
import Loader from "../../components/common/Loader";
import EnTagSelect from '../../components/form/EnTagSelect';
import config from '../../config';
import "../../assets/css/jo-expense.css"
import EnText from "../../components/form/EnText";
import DocumentPicker from "../../components/form/DocumentPicker";
import swal from 'sweetalert2';
import moment from 'moment'
import history from '../../utils/history';
import { get, toNumber, sumBy } from "lodash";

const theme = {
  activityLogTextArea: {
    display: 'block',
    height: '152px',
    overflowY: 'scroll',
    padding: '6px 12px',
    fontSize: '19.2px',
    lineHeight: 1.42857143,
    color: '#555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)'
  }
}

export class JoExpense extends Component {
  constructor (props) {
    super(props)
    this.state = {
      editMode: true,
      loading: true,
      isUpdate: false,
      jobId: this.props.match.params.id,
      job: undefined,
      expenses: [
        {
          type: null,
          file: null,
          noted: '',
          total: '',
          oldExpenseTotal: '',
          vat: '',
          expenseBeforeVat: '',
          docUrl: '',
          docName: '',
          updatedAt: new Date()
        }
      ],
      expenseOptions: [],
      deletedExpenses: [],
      deletedExpensesReason: '',
    }
  }

  componentDidMount() {
    this.initData()
  }

  async initData() {
    const result = await this.props.job.getJobsById(this.state.jobId);
    if (!result) {
      swal.fire({
        icon: 'error',
        title: 'ไม่พบข้อมูลรายการนี้',
        confirmButtonText: 'ตกลง',
      });
      return;
    } else {
      const job = result;
      const otherExpenseOptions = await this.props.otherExpense.getOtherExpenseOptions();
      const joGrandTotal = (job.type_of_job === 'I' ? get(job.po_installation, '[0].before_other_expense', 0) : get(job.po_survey, '[0].before_other_expense', 0)) || 0;
      job.joGrandTotal = joGrandTotal;
      job.expenseLimit = joGrandTotal * 0.7;
      const expenseOptions = !otherExpenseOptions ? [] : otherExpenseOptions.map(item => {
        return {
          value: item._id,
          label: item.name
        }
      })
      if (get(job.other_expense, 'detail.length', 0) > 0) {
        const expenses = job.other_expense.detail.map((item) => {
          return {
            type: item.expense_id,
            noted: item.remark,
            total: item.expense_amount.toString(),
            oldExpenseTotal: item.expense_amount,
            vat: item.vat,
            expenseBeforeVat: item.expense_before_vat,
            docUrl: item.doc_url,
            docName: item.doc_name,
            file: {
              name: item.doc_name
            },
            updatedAt: moment(item.date).toDate()
          }
        })
        this.setState({ expenses, isUpdate: true, editMode: false })
      }
      this.setState({ job, expenseOptions, editMode: job.status != 'paid_to_contractor' && !(get(job.other_expense, 'detail.length', 0) > 0) })
    }
    this.setState({ loading: false })
  }

  activityLogInTextarea() {
    return this.state.job && this.state.job.activity_other_extends_logs && this.state.job.activity_other_extends_logs.length > 0
      ? this.state.job.activity_other_extends_logs.map((item) => item.remark).join('<br/>')
      : '';
  }

  addExpense() {
    this.setState((prevState) => {
      const expenses = prevState.expenses
      const newExpense = {
        type: null,
        file: null,
        noted: '',
        total: '',
        updatedAt: new Date()
      }
      return {
        expenses: [...expenses, newExpense]
      }
    })
  }

  removeExpense(i) {
    const expense = this.state.expenses[i]
    const total = this.isStringNumber(expense.total) ? toNumber(expense.total) : 0
    const joNo = this.getJoNo(this.state.job)
    swal.fire({
      title: 'ลบรายการ',
      html: `
        เมื่อทำการลบ [รายละเอียดค่าใช้จ่ายรายการที่ ${i+1}] [${total.toLocaleString()} บาท]
        <br>
        ระบบจะไม่หักรายการนี้จาก ${joNo}<br>หากต้องการดำเนินการต่อ กรุณากดปุ่ม "ลบรายการ"
        <br>
        <h4> สาเหตุ<label style="color:red">*</label> </h4>
        <input type="text" id="swal-input1" class="swal2-input" placeholder="ระบุสาเหตุ">
      `,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ลบรายการ',
      cancelButtonText: 'ยกเลิก',
      confirmButtonColor: "#F37C27",
      cancelButtonColor: "#F44236",
      reverseButtons: true,
      preConfirm: () => {
        const input = document.getElementById('swal-input1')
        input.addEventListener('keyup', (event) => {
            if (event.target.value.length >= 3 && event.target.value.length <= 150) {
              swal.resetValidationMessage()
            }
        })

        if (input.value.length >= 3 && input.value.length <= 150) {
          swal.resetValidationMessage()
          this.state.deletedExpensesReason = input.value
        }

        if (input.value.length > 150) {
          swal.showValidationMessage('สาเหตุต้องไม่เกิน 150 ตัวอักษร')
        }

        if (input.value.length < 3) {
          swal.showValidationMessage('สาเหตุต้องมากกว่า 3 ตัวอักษร')
        }
      },
    }).then((result) => {
      if (result.value){
        this.setState((prevState) => {
          const expenses = prevState.expenses
          const deletedExpenses = prevState.deletedExpenses
          deletedExpenses.push({ ...expense, reason: this.state.deletedExpensesReason, updatedAt: new Date() })
          expenses.splice(i, 1)
          return {
            expenses,
            deletedExpenses
          }
        })
      }
    })
  }

  async setExpenseValue(key, i, value) {
    this.setState((prevState) => {
      const expenses = prevState.expenses.map((expense, j) => {
        if (j == i) return {
          ...expense,
          [key]: value
        }
        return expense
      })
      return {
        expenses
      }
    })
  }

  async setExpenseType(i, value) {
    await this.setExpenseValue('type', i, value)
    const total = this.state.expenses[i].total
    await this.setExpenseTotal(i, total)
  }

  setExpenseNoted(i, value) {
    this.setExpenseValue('noted', i, value)
  }

  async setExpenseTotal(i, value) {
    const optionId = this.state.expenses[i].type
    const data = await this.props.otherExpense.calculateExpense(parseFloat(value), optionId)
    this.setExpenseValue('total', i, value)
    if (data) {
      this.setExpenseValue('expenseBeforeVat', i, data.expense_before_vat)
      this.setExpenseValue('vat', i, data.vat)
      this.setExpenseValue('updatedAt', i, data.updated_date)
    }
  }

  async setExpenseFile(i, value) {
    const data = await this.props.upload.uploadFile(value, `expense/${this.state.jobId}`)
    if (data && data.length > 0) {
      this.setExpenseValue('file', i, value)
      this.setExpenseValue('docUrl', i, data[0].path)
      this.setExpenseValue('docName', i, data[0].name)
    } else {
      swal.fire({
        icon: 'error',
        title: 'ไม่สามารถแนบไฟล์ได้',
        confirmButtonText: 'ตกลง',
      });
    }
  }

  backToPreviousPage() {
    const { _id: id } = this.state.job
    history.push(`${config.publicUrl}/superadmin/jobs/${id}`)
  }

  isStringNumber(str) {
    return !isNaN(+str) && str.trim() !== '';
  }

  validate() {
    return this.state.expenses.every((item) => item.type && item.docUrl && item.docName && this.isStringNumber(item.total))
  }

  getJoNo(job) {
    return job.type_of_job === 'I' ? get(job.po_installation, "[0].no", "") : get(job.po_survey, "[0].no", "");
  }

  async saveData() {
    try {
      const totalExpense = this.getTotalExpense();
      if (!this.validate()) {
        throw new Error('INVALID');
      }

      if (numeral(totalExpense).value() > numeral(this.state.job.expenseLimit).value()) {
        throw new Error('INVALID_TOTAL');
      }

      const result = await swal.fire({
        title: 'ยืนยันการแก้ไขรายการ',
        html: 'คุณทำการแก้ไขข้อมูลรายการนี้<br>ซึ่งอาจส่งผลให้ยอดที่ทำการหักเปลี่ยนแปลงไปเมื่อทำการบันทึก<br>หากต้องการดำเนินการต่อ กรุณาตรวจสอบความถูกต้องก่อนกดปุ่ม "บันทึก"',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'บันทึก',
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: "#F44236",
        reverseButtons: true
      })

      if (!result.value) return
      
      const userInfo = this.props.auth.getUserInfo();
      const email = userInfo.email || '';
      const joNo = this.getJoNo(this.state.job)
      this.setState({ loading: true });
      const otherExpense = await Promise.all(this.state.expenses.map((item) => {
        const expenseOption = this.state.expenseOptions.find(option => option.value === item.type)
        return {
          expense_id: item.type,
          expense_name: expenseOption && expenseOption.label,
          remark: item.noted,
          doc_url: item.docUrl,
          doc_name: item.docName,
          expense_amount: parseFloat(item.total),
          expense_before_vat: parseFloat(item.expenseBeforeVat),
          vat: parseFloat(item.vat),
          old_expense_amount: parseFloat(item.oldExpenseTotal),
          date: item.updatedAt,
          user_email: email,
        };
      }))
      const deletedOtherExpense = await Promise.all(this.state.deletedExpenses.map((item) => {
        const expenseOption = this.state.expenseOptions.find(option => option.value === item.type)
        return {
          expense_id: item.type,
          expense_name: expenseOption && expenseOption.label,
          remark: item.noted,
          reason: item.reason,
          doc_url: item.docUrl,
          doc_name: item.docName,
          expense_amount: parseFloat(item.total),
          expense_before_vat: parseFloat(item.expenseBeforeVat),
          vat: parseFloat(item.vat),
          old_expense_amount: parseFloat(item.oldExpenseTotal),
          date: item.updatedAt,
          user_email: email,
        };
      }))
      const isCreated = await this.props.otherExpense.createExpense(this.state.jobId, joNo, otherExpense, deletedOtherExpense, this.state.isUpdate);
      this.setState({ loading: false })
      if (isCreated) {
        this.noti.success('บันทึกข้อมูลสำเร็จ');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        const userInfo = this.props.auth.getUserInfo();
        const email = userInfo.email || '';
        const otherExpense = await Promise.all(this.state.expenses.map((item) => {
          const expenseOption = this.state.expenseOptions.find(option => option.value === item.type)
          return {
            expense_id: item.type,
            expense_name: expenseOption && expenseOption.label,
            remark: item.noted,
            doc_url: item.docUrl,
            doc_name: item.docName,
            expense_amount: parseFloat(item.total),
            expense_before_vat: parseFloat(item.expenseBeforeVat),
            vat: parseFloat(item.vat),
            old_expense_amount: parseFloat(item.oldExpenseTotal),
            date: item.updatedAt,
            user_email: email,
          };
        }))
        const isCreated = await this.props.otherExpense.createExpense(this.state.jobId, joNo, otherExpense, this.state.isUpdate);
        if (isCreated) {
          this.backToPreviousPage()
        } else {
          swal.fire({
            icon: 'error',
            title: 'ไม่สามารถทำการบันทึกได้',
            confirmButtonText: 'ตกลง',
          });
        }
      }
    } catch (e) {
      switch (get(e, 'message')) {
        case 'INVALID':
          swal.fire({
            icon: 'warning',
            title: 'ไม่สามารถทำการบันทึกได้',
            html: 'กรุณาระบุ ประเภทค่าใช้จ่าย / ยอดที่หัก / แนบเอกสาร<br>ให้เรียบร้อยก่อนทำการกดบันทึก',
            confirmButtonText: 'ตกลง',
          });
          break;
        case 'INVALID_TOTAL':
          swal.fire({
            icon: 'warning',
            title: 'แจ้งเตือนการหักค่าใช้จ่าย JO',
            html: 'ไม่สามารถบันทึกได้ เนื่องจากจำนวนเงินที่หักค่าใช้จ่าย JO<br>เกินวงเงินที่สามารถหักได้',
            confirmButtonText: 'ตกลง',
          });
          break;
        default:
          throw e;
      }
    }
  }

  getTotalExpense() {
    const total = this.state.expenses.reduce((accumulator, object) => {
      return parseFloat(accumulator) + parseFloat(object['total'] || 0);
    }, 0);
    return total
  }

  enterEditMode() {
    if (!this.state.job.status !== 'paid_to_contractor') this.setState({ editMode: true })
  }

  render() {
    return (
      <Container isAdmin>
        <Notification ref={(ref) => { this.noti = ref; }} />
        { this.state.loading ? ( 
            <Loader show={this.state.loading} />
          ) : (
            <div className="row">
              <div className="card">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">หักค่าใช้จ่ายจาก JO</h4>
                </div>
                <div className="card-content">
                  <div className="expense-table-container">
                    <div className="expense-table-container-header">
                      <b><span>รายการหักค่าใช้จ่าย</span></b>
                      <b className="ml-auto">เลขที่งาน : <span>{this.state.job.job_code}</span></b>
                      <b>มูลค่ารวมของงาน : <span>{`${numeral(this.state.job.joGrandTotal).format('0,0.00') || ''} บาท`}</span></b>
                      <b>ยอดรวมที่หัก : <span>{`${numeral(this.getTotalExpense()).format('0,0.00') || ''} บาท`}</span></b>
                      <b className="remark">({`ไม่เกิน ${numeral(this.state.job.expenseLimit).format('0,0.00') || ''} บาท`})</b>
                    </div>
                    <div className="expense-table-container-content">
                      <table>
                        <tr>
                          <th style={{ width: "48px" }}>#</th>
                          <th style={{ width: "20%" }}>ประเภทค่าใช้จ่าย*</th>
                          <th style={{ width: "20%" }}>หมายเหตุ</th>
                          <th style={{ width: "20%" }}>เอกสาร*</th>
                          <th style={{ width: "7%" }}>ยอดที่หัก*</th>
                          <th style={{ width: "7%" }}>ยอดก่อนหักภาษี</th>
                          <th style={{ width: "7%" }}>ภาษีมูลค่าเพิ่ม</th>
                          <th style={{ width: "128px" }} className="flexible whitespace-nowrap">วันเวลาแก้ไขล่าสุด</th>
                          <th style={{ width: "72px" }} className="flexible"></th>
                        </tr>
                        { 
                          this.state.expenses.map((expense, i) => 
                            (
                              <tr key={i}>
                                <td>{i+1}</td>
                                <td>
                                  <EnTagSelect multi={false} placeholder="เลือกประเภทค่าใช้จ่าย" className="pt-0" disabled={!this.state.editMode} value={expense.type} options={this.state.expenseOptions} onTagChange={(value) => this.setExpenseType(i, value)} />
                                </td>
                                <td>
                                  <EnText placeholder="ระบุหมายเหตุ (ถ้ามี)" disabled={!this.state.editMode} value={expense.noted} onChange={(e) => this.setExpenseNoted(i, e.target.value)} />
                                </td>
                                <td>
                                  {!this.state.editMode ? (
                                    <div className="document-picker">
                                      <span>
                                        <a href={expense.docUrl} target="_blank">
                                          {expense.docName}
                                        </a>
                                      </span>
                                    </div>
                                  ) : (
                                    <DocumentPicker
                                      file={expense.file}
                                      accept=".jpg,.png,.raw,.gif,.tif,.psd,.svg,.webp"
                                      disabled={!this.state.editMode}
                                      onChange={(file) => this.setExpenseFile(i, file)}
                                    />
                                  )}
                                </td>
                                <td>
                                  <EnText placeholder="0.00" value={expense.total} disabled={!this.state.editMode} onChange={(e) => this.setExpenseTotal(i, e.target.value)} />
                                </td>
                                <td>
                                  <EnText placeholder="0.00" readonly="true" value={expense.expenseBeforeVat} />
                                </td>
                                <td>
                                  <EnText placeholder="0.00" readonly="true" value={expense.vat} />
                                </td>
                                <td className="text-gray whitespace-nowrap">{moment(expense.updatedAt).format('DD-MM-YYYY HH:mm')}</td>
                                <td>
                                  {
                                    this.state.editMode ? 
                                    (
                                      <div className="btn btn-sm btn-danger" onClick={() => this.removeExpense(i)}>
                                        <i className="fa fa-trash" />
                                      </div>
                                    ) : ''
                                  }
                                </td>
                              </tr>
                            )
                          )
                        }
                      </table>
                      {
                        this.state.editMode ?
                        (
                          <div className="btn btn-success mt-8" onClick={() => this.addExpense()}>
                            <i className="fa fa-plus" />
                          </div>
                        ) : ''
                      }
                    </div>
                  </div>
                  <div className="expense-table-container mt-8">
                    <div className="expense-table-container-header">
                      <b><span>ประวัติการทำรายการ</span></b>
                    </div>
                    <div className="expense-table-container-content">
                      <div className='w-100'>
                        <div
                          style={theme.activityLogTextArea}
                          dangerouslySetInnerHTML={{ __html: this.activityLogInTextarea() }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex w-100 mt-8">
                    <button className="btn btn-danger ml-auto" onClick={() => this.backToPreviousPage()}>กลับ</button>
                    {
                      this.state.job.status !== 'paid_to_contractor' ?
                        !this.state.editMode ? 
                        (
                          <button className="btn btn-primary" onClick={this.enterEditMode.bind(this)}>
                            <i className="fa fa-pencil mr-2" />
                            แก้ไข
                          </button>
                        ) : 
                        (
                          <button className="btn btn-primary" onClick={this.saveData.bind(this)}>
                            <i className="fa fa-save mr-2" />
                            บันทึก
                          </button>
                        )
                      : ''
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Container>
    )
  }
}

export default inject('invoice', 'po', 'job', 'permission', 'otherExpense', 'upload', 'auth')(observer(JoExpense));