import * as _ from 'lodash';
import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';

export class OtherExpenseStore extends BaseStore {
  async getOtherExpenseOptions() {
    try {
      const res = await http.get(`${config.api.sims}/qchang/v2/otherExpense/options`);

      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async calculateExpense(expenseAmount, optionId) {
    try {
      const data = {
        id_expense_option: optionId,
        expense_amount: expenseAmount,
      };
      const res = await http.post(`${config.api.sims}/qchang/v2/otherExpense/calculate`, data);

      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async createExpense(jobId, joNo, otherExpenses, deletedOtherExpense, isUpdate) {
    try {
      const data = {
        job_id: jobId,
        jo_no: joNo,
        other_expenses: otherExpenses,
        deleted_other_expenses: deletedOtherExpense,
        is_update: isUpdate,
      };
      const res = await http.post(`${config.api.sims}/qchang/v2/otherExpense/create`, data);

      if (res.status === 200 && res.data && res.data.message === 'success') {
        return true;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }
}
export default new OtherExpenseStore();
