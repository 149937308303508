import * as _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { observer, inject } from 'mobx-react';
import { Modal } from 'react-bootstrap';

import { CustomCellWrapper, CustomCalendarEvent, CustomEndAccessor, CustomDateHeader, CustomEventView } from './CustomCalendar';
import { TypeOfJobs } from '../../utils/data';
import datetime from '../../utils/datetime';
import common from '../../utils/common';
import Container from '../../layouts/ContainerContractor';
import FormGroup from '../../components/form/FormGroup';
import EnDropDown from '../../components/form/EnDropDown';
import Calendar from '../../components/common/Calendar';
import Loader from '../../components/common/Loader';
import Notification from '../../components/common/Notification';
import SearchJob from '../../../src/pages/calendar/search/SearchJob';
import config from '../../config';

// import swal from 'sweetalert';
import '../../assets/css/contractorJob.css';
import '../../assets/css/vacation-page.css';

export class Contractor extends React.Component {
  constructor(props) {
    super(props);
    let parsed = queryString.parse(this.props.location.search);
    let currentDate = moment();
    if (parsed.date && parsed.date.length === 7) {
      currentDate = moment(`${parsed.date}-01`, 'YYYY-MM-DD');
    }

    this.state = {
      selectedDate: undefined,
      currentDate: currentDate,
      id: this.props.match.params.id,
      jobType: parsed && parsed.job_type && parsed.job_type !== 'A' ? parsed.job_type : 'S',
      showCalendar: true,
      showDuplicatedModal: false,
    };
  }

  keepStateFilter() {
    let filter = {
      date: moment(this.state.currentDate).format('YYYY-MM'),
      jobType: this.state.jobType,
    };

    if (this.state.contractorId) {
      filter.contractorId = this.state.contractorId;
    }

    this.props.calendar.setCalendarFilter(filter);
  }

  customCellWrapper(obj) {
    let calendar = this.props.calendar.toJS();
    return CustomCellWrapper(obj, calendar.holidays, calendar.vacation);
  }

  customDateHeader(obj) {
    return CustomDateHeader(obj, this.state.selectedDate, (date) => {
      this.setState(
        {
          selectedDate: date,
        },
        () => {
          this.createVacation({
            start: date,
            end: date,
          });
        },
      );
    });
  }

  changeQueryString() {
    const obj = {
      job_type: this.state.jobType,
    };

    if (this.state.currentDate) {
      obj.date = moment(this.state.currentDate).format('YYYY-MM');
    }

    const qs = common.urlBuilder(obj);
    this.props.history.push(`${config.publicUrl}/contractors/${this.state.id}/calendar?${qs}`);
  }

  onJobTypeChanged(e) {
    let jobType = e.target.value;
    this.setState({ jobType: jobType }, () => {
      this.changeQueryString();
      this.loadData();
      this.keepStateFilter();
    });
  }

  onSelectedEvent(event) {
    if (event && event.isVacation) {
      this.updateVacation(event);
    } else if (event && event.isJob) {
      this.props.history.push(`${config.publicUrl}/contractors/${this.state.id}/calendar/jobs/${event.id}`);
    } else if (event && event.isHoliday) {
      this.createVacation(event);
    }
  }

  updateVacation(event) {
    if (event && event.id) {
      let calendar = this.props.calendar.toJS();
      let vacation = calendar.vacation.find((v) => v.id === event.id);
      if (vacation) {
        this.props.history.push(
          `${config.publicUrl}/contractors/${this.state.id}/event?job_type=${this.state.jobType}&start=${moment(vacation.start).format(
            'YYYYMMDD',
          )}&end=${moment(vacation.end).format('YYYYMMDD')}&&vacation_id=${event.id}`,
        );
      }
    } else {
      this.createVacation(event);
    }
  }

  createVacation(event) {
    if (event && event.start && event.end) {
      let calendar = this.props.calendar.toJS();
      let vacation = calendar.vacation.find((v) => datetime.isBetween(event.start, v.start, v.end) && datetime.isBetween(event.end, v.start, v.end));
      if (!vacation || !vacation.id) {
        this.props.history.push(
          `${config.publicUrl}/contractors/${this.state.id}/event?job_type=${this.state.jobType}&start=${moment(event.start).format(
            'YYYYMMDD',
          )}&end=${moment(event.end).format('YYYYMMDD')}`,
        );

        // this.props
        // this.props.history.push(`${config.publicUrl}/contractors/${this.state.id}/calendar/vacation?job_type=${this.state.jobType}&start=${moment(event.start).format('YYYYMMDD')}&end=${moment(event.end).format('YYYYMMDD')}`);
      } else {
        this.updateVacation({
          id: vacation.id,
        });
      }
    }
  }

  async onMonthNext(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
      this.keepStateFilter();
    });
  }

  async onMonthBack(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
      this.keepStateFilter();
    });
  }

  async onDateCurrent(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
    });
  }

  async onMoveMonth(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
      this.keepStateFilter();
    });
  }

  async loadData() {
    try {
      const startDate = moment(this.state.currentDate).startOf('month').subtract(5, 'days');
      const endDate = moment(this.state.currentDate).endOf('month').add(5, 'days');
      const objFilter = {
        startDate,
        endDate,
        contractorId: this.state.id,
        jobType: this.state.jobType,
      };
      const typeOfWork = this.props.typeofwork.toJS();
      await this.props.calendar.getContractorCalendar(objFilter, typeOfWork);
    } catch (error) {
      this.noti.error(error.message, 'โหลดข้อมูลล้มเหลว');
    }
  }

  async componentDidMount() {
    await this.props.typeofwork.getTypeOfWorks();
    await this.props.contractor.getContractor({
      id: this.state.id,
    });
    let parsed = queryString.parse(this.props.location.search);
    const contractor = this.props.contractor.toJS().info;
    if (parsed && parsed.job_type) {
      this.setState({ jobType: parsed.job_type });
    } else {
      if (contractor && contractor.calendarType) {
        this.setState({ jobType: contractor.calendarType });
      }
    }
    await this.loadData();
    this.keepStateFilter();
  }

  getJobUrl = ({ id }) => {
    return `${config.publicUrl}/contractors/${this.state.id}/calendar/jobs/${id}`;
  };

  handleToggleSearchResult = (showResult) => {
    this.setState({
      showCalendar: !showResult,
    });
  };

  closeDuplicatedModal = () => {
    this.setState({ showDuplicatedModal: false });
  };

  handleBeforeCreateVacation = async (event) => {
    if (!event || !event.start || !event.end) {
      return;
    }

    const { calendar: calendarStore } = this.props;
    const { id: contractorId, jobType } = this.state;
    const startMoment = moment(event.start);
    const endMoment = moment(event.end);

    const { status } = await calendarStore.validateVacation({
      contractorId,
      startDate: startMoment.toISOString(),
      endDate: endMoment.endOf('day').toISOString(),
      jobType,
    });

    if (status === 409) {
      this.setState({ showDuplicatedModal: true });
    } else {
      this.createVacation(event);
    }
  };

  render() {
    const { showDuplicatedModal } = this.state;
    let calendar = this.props.calendar.toJS();
    let events = _.concat(calendar.vacation, calendar.holidays, calendar.jobs);
    let typeOfJobs = TypeOfJobs.map((item, idx) => {
      return (
        <option key={idx} value={item.id}>
          {item.label}
        </option>
      );
    });
    typeOfJobs = typeOfJobs.slice(1);

    return (
      <Container>
        <div className="row row-calendar">
          <Loader show={calendar.isCalendarLoading} />
          <Notification
            ref={(ref) => {
              this.noti = ref;
            }}
          />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">กรุณาระบุวันที่ท่านไม่สะดวกรับงาน</h4>
                <p className="category">กดค้างวันที่ท่านต้องการเพื่อกรอกรายละเอียด</p>
              </div>
              <div className="card-content">
                <FormGroup>
                  <EnDropDown id="type-of-job" value={this.state.jobType} onChange={this.onJobTypeChanged.bind(this)}>
                    {typeOfJobs}
                  </EnDropDown>
                </FormGroup>
                <FormGroup label="ค้นหางาน:">
                  <SearchJob contractorId={this.state.id} getHref={this.getJobUrl} onToggle={this.handleToggleSearchResult} />
                </FormGroup>
                {this.state.showCalendar && (
                  <Calendar
                    events={events}
                    date={moment(this.state.currentDate).toDate()}
                    onSelectEvent={this.onSelectedEvent.bind(this)}
                    onSelectSlot={this.handleBeforeCreateVacation.bind(this)}
                    endAccessor={CustomEndAccessor}
                    eventWrapper={CustomCalendarEvent}
                    eventView={CustomEventView}
                    dateHeader={this.customDateHeader.bind(this)}
                    cellWrapper={this.customCellWrapper.bind(this)}
                    customNext={this.onMonthNext.bind(this)}
                    customBack={this.onMonthBack.bind(this)}
                    customCurrent={this.onDateCurrent.bind(this)}
                    customMoveMonth={this.onMoveMonth.bind(this)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal className="vacation-page__modal-container" show={showDuplicatedModal}>
          <Modal.Header className="vacation-page__modal-header">
            <i className="fa fa-times-circle vacation-page__icon vacation-page__error-icon" />
            <Modal.Title className="vacation-page__modal-title">บันทึกวันหยุดรับงานไม่สำเร็จ</Modal.Title>
          </Modal.Header>
          <Modal.Body className="vacation-page__modal-body">
            วันที่คุณเลือกซ้ำกับวันหยุดรับงานที่มีในปฏิทินแล้ว กรุณายกเลิกวันหยุดรับงานเดิม และสร้างวันหยุดรับงานใหม่อีกครั้ง
          </Modal.Body>
          <Modal.Footer className="vacation-page__modal-footer">
            <button className="vacation-page__btn vacation-page__primary-btn" onClick={this.closeDuplicatedModal}>
              ตกลง
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default inject('contractor', 'calendar', 'typeofwork')(observer(Contractor));
