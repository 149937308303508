import React, { Component } from 'react';
import Input from 'react-validation/build/input';

export class EnText extends Component {
  value() {
    return this.input.value;
  }

  clear() {
    this.input.value = '';
  }

  render() {
    let propInput = Object.assign({}, this.props);
    propInput.className = `${propInput.className ? propInput.className : ''} form-control`;
    let typeTxt = this.props.type || 'text';
    if (propInput.validations && propInput.validations.length) {
      return <Input type={typeTxt} ref={(e) => { this.input = e; }} {...propInput} />;
    } else {
      return <input type={typeTxt} ref={(e) => { this.input = e; }} {...propInput} />;
    }
  }
}

export default EnText;
