import React from 'react';
import { Modal } from 'react-bootstrap';
import { EnTextFormGroup } from '../../../components/form/EnTextFormGroup';
import { EnButton } from '../../../components/form/EnButton';
// import { required } from '../../../components/form/FormValidation';
export class MyModal extends React.Component {
  render() {
    return (
      <Modal
        show={this.props.showModal}
        onHide={() => this.props.handleHide({ key: this.props.index, ...this.props.item })}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title">
            {this.props.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div key={`item-${this.props.index}`}>
            {this.props.costType ? <div>
              <EnTextFormGroup containerClassName="col-md-6" type="number" value={this.props.item.survey_cost} label={'ค่าแรงสำรวจ (รวม VAT)'} onChange={(e) => this.props.onSurveyCostChange(e.target.value, this.props.index)} />
              <EnTextFormGroup containerClassName="col-md-6" type="number" value={this.props.item.wage} label={'ค่าแรงติดตั้ง (รวม VAT)'} onChange={(e) => this.props.onWageChange(e.target.value, this.props.index)} />
              <EnTextFormGroup containerClassName="col-md-12" type="number" value={this.props.item.wage_with_price} label={'ค่าของพร้อมติดตั้ง (รวม VAT)'} onChange={(e) => this.props.onWageWithPriceChange(e.target.value, this.props.index)} />
            </div>
              : <div>
                <EnTextFormGroup type="number" value={this.props.item.survey_cost} label={'ค่าแรงสำรวจ (รวม VAT)'} onChange={(e) => this.props.onSurveyCostChange(e.target.value, this.props.index)} />
                <EnTextFormGroup type="number" value={this.props.item.wage} label={'ค่าแรงติดตั้ง (รวม VAT)'} onChange={(e) => this.props.onWageChange(e.target.value, this.props.index)} />
              </div>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <EnButton className="btn btn-danger" onClick={() => this.props.handleHide({ key: this.props.index, ...this.props.item })}>
            <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
          </EnButton>
          <EnButton className="btn-info pull-right" onClick={this.props.onSubmit}>
            <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
          </EnButton>
        </Modal.Footer>
      </Modal >);
  }
}

export default MyModal;
