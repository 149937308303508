import { isNil } from 'lodash';
import React, { Component } from 'react';
import Quill from 'react-quill';
import FormGroup from './FormGroup';

const modules = {
  toolbar: [
    // [{ 'font': [] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['align', { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    [{ 'color': ['black', 'gray', 'red', 'green', 'blue', 'yellow', 'orange', 'pink', 'purple'] }],
    ['clean'],
  ],
};

const formats = [
  'font', 'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'align',
  'list', 'bullet', 'indent',
  'link', 'image', 'video',
  'color',
];

export default class EnQuill extends Component {
  render() {
    let { label, value, validations, onChange, containerClassName } = this.props;
    let options = {
      label,
      validations: !isNil(validations) && Array.isArray(validations),
    };
    return (
      <FormGroup containerClassName={containerClassName} {...options} >
        <Quill
          modules={modules}
          formats={formats}
          value={value}
          onChange={onChange}
        />
      </FormGroup>
    );
  }
}
