import React from 'react';
import '../../assets/css/stepprogress.css';

export const EnStepButton = (props) => {
  let width = 100 / props.totalstep;
  let inputOptions = [];

  if (props.href) {
    // inputOptions['href'] = props.href;
    // inputOptions['target'] = '_blank';
  }

  return (
    <li className={props.className} index={props.index} style={{ width: `${width}%` }}>
      <a {...inputOptions} onClick={props.onClick}>
        <i className={props.icon} />
        <p>{props.title}</p>
      </a>
    </li>
  );
};

export const EnStepArrow = (props) => {
  return (
    <div>
      <div className="step-scroll left-scroll">
        <a onClick={props.leftEvent}>
          <i className={`arrow ${props.leftIcon}`} />
        </a>
      </div>
      <div className="step-scroll right-scroll">
        <a onClick={props.rightEvent}>
          <i className={`arrow ${props.rightIcon}`} />
        </a>
      </div>
    </div>
  );
};
