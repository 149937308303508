import Swal from 'sweetalert2';
import BaseStore from '../BaseStore';
import config from '../../config';
import { http } from '../../utils/http';
import { get } from 'lodash';

const PROMOTION_LIMIT = {
  heroBanner: 20,
  promotionList: 1,
  promotionBottom: 1,
}

export class PromotionConfig extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      channel: '',
      promotionData: {
        heroBanner: [],
        promotionBottom: {},
        promotionList: [],
        groupPromotionList: []
      },
      selected: {
        heroBanner: '',
        promotionBottom: '',
        promotionList: '',
      },
      options: [],
    });
  }

  setChannel = (value) => {
    this.channel = value;
  }

  validLimit = (promotionType, ids) => {
    const length = (ids || '').split(',').length;
    const limit = PROMOTION_LIMIT[promotionType];
    const isValid = length <= limit;
    const namePromotionType = promotionType === 'promotionList' ? 'Main Promotion List' : promotionType
    if (!isValid) {
      Swal.fire(`Promotion "${namePromotionType}" เกิน limit`, '', 'error');
    }
    return isValid;
  }

  setSelected = (promotionType, ids) => {
    if (!this.validLimit(promotionType, ids)) return;
    const allSelected = [...(this.selected[promotionType] || '').split(',')];
    const selectedLength = this.selected[promotionType] ? this.selected[promotionType].length : 0;
    const isInput = promotionType === 'promotionBottom' ? true : ids.length > selectedLength;
    if (ids !== '' && ids !== null && ids && isInput) {
      const selectedIn = (ids || '').split(',');
      let isFound = false;
      for (let i = 0; i < allSelected.length; i++) {
        if (selectedIn[selectedIn.length - 1] === allSelected[i]) {
          isFound = true;
          break;
        }
      }
      if (!isFound) {
        this.selected[promotionType] = ids;
      } else {
        Swal.fire('Promotion นี้ถูกเพิ่มแล้ว', '', 'error');
      }
    } else {
      this.selected[promotionType] = ids;
    }
  }

  getPromotionData = async () => {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const [
        heroBanner,
        promotionList,
        groupPromotionList,
        promotionBottom,
        allPromotion,
      ] = await Promise.all([
        http.get(`${config.api.qchang}/v2/home/heroBanner`, { params: { isAdmin: true, channel: this.channel } }),
        http.get(`${config.api.qchang}/v2/home/promotionList/${this.channel}`, { params: { isAdmin: true } }),
        http.get(`${config.api.qchang}/v2/home/promotions/section`, { params: { isAdmin: true, channel: this.channel } }),
        http.get(`${config.api.qchang}/v2/home/promotionBottom`, { params: { isAdmin: true, channel: this.channel } }),
        http.get(`${config.api.qchang}/v2/home/allPromotion/${this.channel}`, { params: { isAdmin: true } }),
      ]);
      if (
        heroBanner.status === 200 && heroBanner.data &&
        promotionList.status === 200 && promotionList.data &&
        groupPromotionList.status === 200 && groupPromotionList.data &&
        promotionBottom.status === 200 && promotionBottom.data &&
        allPromotion.status === 200 && allPromotion.data
      ) {
        this.promotionData.heroBanner = heroBanner.data.data;
        this.promotionData.promotionBottom = promotionBottom.data.data;
        this.promotionData.promotionList = promotionList.data.data;
        this.promotionData.groupPromotionList = groupPromotionList.data;
        this.options = (allPromotion.data.data || []).map(({ id, name, start_date, end_date }) => ({ value: id, label: name, start_date, end_date }));
        const herobannersRedirectUrl = (heroBanner.data.data || []).map(({ id, redirect_url }) => ({
          promotionId: id,
          url: redirect_url
        }));
        let heroBannerSelected = (heroBanner.data.data || []).map(({ id }) => id);
        heroBannerSelected = heroBannerSelected.join(',');

        let promotionListSelected = (promotionList.data.data || []).map(({ id }) => id);
        promotionListSelected = promotionListSelected.join(',');

        const promotionBottomOptions = [{
          value: promotionBottom.data.data.id || '',
          label: promotionBottom.data.data.name || '',
        }];

        this.selected.heroBanner = heroBannerSelected;
        this.selected.promotionList = promotionListSelected;
        this.selected.promotionBottom = promotionBottomOptions[0].value || '';
        return { groupPromotionList: groupPromotionList.data.data, herobannersRedirectUrl }
      }
    } catch (error) {
      console.error('error:', error.message);
      Swal.fire('ดึงรายการ promotion ไม่สำเร็จ', '', 'error');
    } finally {
      this.isLoading = false;
    }
  }

  transformPromotionPayload = (promotion, herobannersRedirectUrl) => {
    if (herobannersRedirectUrl) {
      const promotions = promotion.split(',')
      return promotions.map((promo, index) => {
        const isPromotionUrl = herobannersRedirectUrl.find((_promoUrl) => _promoUrl.promotionId === promo);
        return { id: promo, index, url: get(isPromotionUrl, 'redirectUrl', '') }
      })
    }
    return (promotion || '').split(',').map((id, index) => ({ id, index }));
  }

  updatePromotionDataToServer = async ({ groupPromotionList, herobannersRedirectUrl }) => {
    try {
      if (this.isLoading) return;
      const _groupPromotionList = groupPromotionList.map(section => {
        const promotions = this.transformPromotionPayload(section.promotions)
        return { ...section, promotions }
      })
      this.isLoading = true;
      console.log(this.transformPromotionPayload(this.selected.heroBanner, herobannersRedirectUrl))
      const response = await http.post(`${config.api.qchang}/v2/home/setPromotionType`, {
        heroBanner: this.transformPromotionPayload(this.selected.heroBanner, herobannersRedirectUrl),
        promotionBottom: this.transformPromotionPayload(this.selected.promotionBottom),
        promotionList: this.transformPromotionPayload(this.selected.promotionList),
        groupPromotionList: _groupPromotionList,
        channel: this.channel
      });
      if (response.status === 200) {
        Swal.fire('บันทึก Promotion สำเร็จ', '', 'success');
      } else {
        throw `status ${response.status}`;
      }
    } catch (error) {
      console.error('error:', error);
      Swal.fire('บันทึก promotion ไม่สำเร็จ', '', 'error');
    } finally {
      this.isLoading = false;
    }
  }
}

export default new PromotionConfig();
