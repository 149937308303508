import qs from 'query-string';
import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';

export class ContractorListStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      query: {},
      data: [],
      pagination: {},
    });
    this.setDefaultQuery();
  }

  setDefaultQuery() {
    this.query = {
      page_size: 10,
      page: 1,
      page_range: 5,
      store_id: '',
      // code: '',
      // status: '',
      // is_active: true,
      contractor_id: '',
      // customer_name: '',
      // type_of_job: '',
      // type_of_work: '',
      // start_date: undefined,
      // end_date: undefined,
      // available_time: '',
    };
  }

  setQueryValue(key, value) {
    this.query[key] = value;
  }

  async searchContractors() {
    if (this.isLoading) return;
    const { query } = this.toJS();
    try {
      this.isLoading = true;
      const res = await http.get(`${config.api.sims}/v1/contractors/admin/search?${qs.stringify(query)}`);
      // console.log('res.data >>>>>>>>', res.data);
      if (res && res.data && res.data.data) {
        this.data = res.data.data;
        this.pagination = res.data.pagination;
      }
    } catch (err) {
      //console.log(err);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new ContractorListStore();
