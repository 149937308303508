import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/ContainerContractor';
import { FormValidation } from '../../../components/form/FormValidation';
// import EnPicture from '../../../components/form/EnPicture';
// import EnButton from '../../../components/form/EnButton';
import Loader from '../../../components/common/Loader';
import Axios from 'axios';
import config from '../../../config';
// import Notification from '../../../components/common/Notification';
// import common from '../../../utils/common';
// import config from '../../../config';
// import { Link } from 'react-router-dom';

export class Agreement extends React.Component {
  // componentWillMount() {
  //   try {
  //     this.props.contractor.getContractor({
  //       id: this.state.id,
  //     });
  //   } catch (error) {
  //     // this.noti.error(error.message);
  //   }
  // }
  constructor(props) {
    super(props);
    this.state = {
      termAndCondition: [],
    };
  }

  async componentDidMount() {
    try {
      const termAndCondition = await this.getTermAndCondition();
      this.setState({termAndCondition});
    } catch(error) {
      this.noti.error(error.message);
    }
  }

  
  async getTermAndCondition() {
    try {
        let response = await Axios.get(`${config.api.sims}/v1/contractors/term-and-condition`);
        if (response.data) {
          return response.data;
        }
        return [];
    } catch (error) {
      this.noti.error(error.message);
    }
}

  render() {
    // let contractor = this.props.contractor.toJS().info;
    return (
      <Container>
        <div className="row">
          {/* <Notification ref={(ref) => { this.noti = ref; }} /> */}
          {/* <Loader show={contractor.isLoading} /> */}
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายละเอียดและข้อตกลง</h4>
                <p className="category">สามารถอ่านรายละเอียดและข้อตกลงได้จากลิงค์ที่แนบมา</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  {
                    Array.isArray(this.state.termAndCondition) && this.state.termAndCondition.map(tc =>
                      <>
                        <div className="col-md-12" />
                        <a href={tc.url}
                          target="_blank"><h4 className="title">{tc.display_text}</h4></a>
                      </>
                    )
                  }
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('contractor', 'calendar')(observer(Agreement));
