import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Container from '../../../layouts/Container';
import SearchForm from './components/SearchForm';
import config from '../../../config';
import { isAllow } from '../../../utils/permission';
import EnToggle from '../../../components/form/EnToggle';
import ModalQrcode from './components/ModalQrcode'
import Loader from '../../../components/common/Loader'
import swal from 'sweetalert2';
import Notification from '../../../components/common/Notification';
import QRCode from 'qrcode.react'


export class ManageTrainingCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modal: {
        title: '',
        url: ''
      }
    };
  }
  async componentWillMount() {
    await this.props.trainingcourse.getAllSubTrainingCourse();
  }

  onView(trainingDtUuid) {
    this.props.history.push(`${config.publicUrl}/superadmin/subCourse/${trainingDtUuid}`);
  }
  gotToMainPage() {
    this.props.history.push(`${config.publicUrl}/superadmin/mainCrouse`);
  }
  gotoCreateSubCourse() {
    this.props.history.push(`${config.publicUrl}/superadmin/createSubCourse/`);
  }

  setModal(value = false, data = {}) {
    let newModal = {
      title: data.trainingName,
      url: data.qrUrl
    }
    this.setState({ showModal: value, modal: newModal });

    // $('#myModal').modal('show')
  }

  async changeToggleActive(trainingDtUuid, isActive) {
    try {
      if (!isAllow('INACTIVATE_AND_ACTIVATE_SUB_COURSE_STATUS')) return;
      await this.props.trainingcourse.changeActiveSubCourse(trainingDtUuid, !isActive)
      await this.props.trainingcourse.getAllSubTrainingCourse();
    } catch (error) {
      throw error
    }
  }

  gotoRegister(trainingDtUuid) {
    this.props.history.push(`${config.publicUrl}/superadmin/training/${trainingDtUuid}/register`);
  }

  getMomentDateFromISODate(s) {
    var b = s.split(/\D+/);
    return moment(new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6])));
  }

  getTableRows(subCourse) {
    return subCourse.map((item, index) => {
      let Date = item.trainingDate ? this.getMomentDateFromISODate(item.trainingDate) : '-';
      let dateString = `${item.trainingDate ? Date.format('LL') : '-'}`;
      let count = `${item.traingOpen ? (item.traingOpen - item.trainingBalance) : ''}`;
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td width="12%" className="">
            {dateString}
            <br />
            {moment(item.trainingStartTime, "HH:mm:ss").format('HH:mm')} - {moment(item.trainingEndTime, "HH:mm:ss").format('HH:mm')} น.
          </td>
          <td width="15%">{item.trainingName || ''}</td>
          <td width="15%">{item.trainingDesc || ''}</td>
          <td width="10%">{item.trainingLocation.locationName}</td>
          <td width="3%" className="text-center">{count} / {item.traingOpen}</td>
          <td width="3%" className="text-center">
            <button className="btn btn-sm" title="Qr" data-tip="Qr" onClick={e => { this.setModal(true, item) }}>
              <span className="fa fa-qrcode" aria-hidden="true" />
            </button>
            {/* <a href={`${config.publicUrl}/superadmin/training/${item.trainingDtUuid}/register`} target="_self">{item.trainingBalance}</a> */}
          </td>
          <td width="3%" className="text-center">
            <div>
              <EnToggle
                checked={item.isActive === true}
                onChange={e => { this.changeToggleActive(item.trainingDtUuid, item.isActive) }}
              />
            </div>
          </td>
          <td>
            <div width="10%" className="text-center">
              <button className="btn btn-xs" title="ดู" data-tip="ดูรายละเอียด" onClick={this.onView.bind(this, item.trainingDtUuid)} >
                <span className="fa fa-eye" aria-hidden="true" />
              </button>
              <button className="btn btn-xs" title="ดูผู้เข้าร่วม" data-tip="ดูรายละเอียด"
                onClick={e => { this.gotoRegister(item.trainingDtUuid) }}>
                <span className="fa fa-user-circle-o" aria-hidden="true" />
              </button>
              {/* <button className="btn btn-warning btn-xs" title="Qr" data-tip="Qr" onClick={e => { this.setModal(true, item) }}>
                <span className="fa fa-qrcode" aria-hidden="true" />
              </button> */}
              <button
                className="btn btn-xs"
                title="Download Excel"
                data-tip="Download Excel"
                onClick={e => { this.downloadExcel(item.trainingDtUuid) }}
                disabled={!isAllow('DOWNLOAD_COURSE_EXCEL_FILE')}
              >
                <span className="fa fa-download" aria-hidden="true" />
              </button>
              <button className="btn btn-danger btn-xs"
                title="ลบ"
                data-tip="ลบ"
                onClick={e => { this.onDelete(item) }}
                disabled={!isAllow('DELETE_A_SUB_COURSE')}
              >
                <span className="fa fa-trash" aria-hidden="true" />
              </button>
            </div>
          </td>

        </tr>
      );
    });
  }

  // async onSearchClick(e) {
  //   await this.props.trainingcourse.searchSubTrainingCourse();
  // }

  // async onClearSearchClick(e) {
  //   this.props.trainingcourse.setDefaultQuery();
  //   await this.props.trainingcourse.getAllTrainingCourse();
  // }


  async onDelete(data) {

    const date = moment(data.trainingDate).format('LL')
    const time = `${moment(data.trainingStartTime, "HH:mm:ss").format('HH:mm')} - ${moment(data.trainingEndTime, "HH:mm:ss").format('HH:mm')}`
    swal.fire({
      title: "โปรดยืนยันการลบ",
      text: `ลบคอร์ส : ${data.trainingName}, ${date}, ${time}`,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {
      if (result.value) {

        this.props.trainingcourse.deleteSubCourse(data.trainingDtUuid);
      }
    })
  }

  async showDownloadFileDialog(downloadFileUrl, fileName) {
    // const { downloadFileUrl } = this.props.ordersummarySCB.toJS();
    let fileTag = '';
    // downloadFileUrl.forEach(file => {
    if (downloadFileUrl) {
      fileTag += ` <a href="${downloadFileUrl}" style={{ marginLeft: '15px' }}
        <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />${fileName}
      </a><p>`;
    }
    // });
    const downloadDialogOptions = {
      title: `ไฟล์ดาวน์โหลด`,
      html: fileTag,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'ปิด',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !swal.isLoading(),
    };

    await swal.fire(downloadDialogOptions);
  }

  async downloadExcel(trainingDtUuid) {
    this.setState({ showModal: false });
    try {
      const result = await this.props.trainingcourse.createScgSkillExcel(trainingDtUuid);

      if (result && result.url) {
        this.showDownloadFileDialog(result.url, result.file);
      } else {
        throw new Error(result);
      }
    } catch (error) {
      console.error('error >>>>>>>>>>>>>', error);
      this.noti.error(error.message, 'พบข้อผิดพลาด');
    }
  }

  render() {
    const { subCourse, query, isLoading } = this.props.trainingcourse.toJS();
    const rows = this.getTableRows(subCourse);

    return (
      <Container isAdmin>
        <Notification ref={(ref) => { this.noti = ref; }} />
        <Loader show={isLoading} />
        <div className="row">
          <ModalQrcode title={this.state.modal.title} setModal={e => { this.setModal() }} showModal={this.state.showModal} url={this.state.modal.url} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">คอร์สอบรมย่อย</h4>
              </div>
              <div className="card-content">
                <div className="row " >
                  <div className="col-md-12">
                    <button className="btn btn-primary" style={{}} title="สร้างใหม่" data-tip="สร้างใหม่" onClick={e => { this.gotToMainPage() }}>
                      <span className="fa fa-arrow-right btn-icon" aria-hidden="true" />คอร์สอบรมหลัก
                    </button>
                  </div>
                </div>
                {/* <SearchForm
                  query={query}
                  onSearch={() => { this.onSearchClick(); }}
                  onClear={() => { this.onClearSearchClick(); }}
                /> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="5%" className="text-center">ลำดับที่</th>
                            <th width="5%" className="text-center">วัน-เวลา</th>
                            <th width="15%" className="text-center">ชื่อคอร์ส</th>
                            <th width="15%" className="text-center">รายละเอียดคอร์ส</th>
                            <th width="10%" className="text-center">สถานที่</th>
                            <th width="10%" className="text-center">จำนวน<br />ที่เข้าร่วม / ที่นั่งเปิดรับ</th>
                            <th width="5%" className="text-center">Qr Code</th>
                            <th width="5%" className="text-center">สถานะคอร์ส</th>
                            <th width="10%" className="text-center" >
                              <button
                                className="btn btn-success"
                                title="สร้างใหม่"
                                data-tip="สร้างใหม่"
                                onClick={e => { this.gotoCreateSubCourse() }}
                                disabled={!isAllow('CREATE_A_SUB_COURSE')}
                              >
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างคอร์สย่อย
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-12">
                    <Pagination
                      pagination={pagination}
                      handlePageClick={this.onPageClick.bind(this)}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('trainingcourse')(observer(ManageTrainingCourse));
