
import React from 'react';
import { observer, inject } from 'mobx-react';
import { config } from '../../config';
import '../../assets/css/stepprogress.css';

export class NpsThankyouPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobId: this.props.match.params.jobId,
    };
  }

  async componentWillMount() {
    try {
      await this.props.nps.getJob(this.state.jobId);
    } catch (err) {
    }
  }

  getComponentMessage(icon, iconFeeling, color, message, messagetxh, ) {
    return (
      <div className="result-message">
        <div>
          <img src="https://storage.googleapis.com/prod-qchang-v1/static/images/logo-new.png" alt="" style={{ width: '100px' }} />
        </div>
        <h4>{message}</h4>
        <div>
          <i className={icon} style={{ fontSize: '60px', color: color }} />
        </div>
        <h6>{messagetxh}</h6>
      </div>
    );
  }

  render() {
    const { jobInfo } = this.props.nps.toJS();
    const { orderId, guaranteeFile, customerFirstname, customerLastname } = jobInfo;
    const customerFullName = `${customerFirstname} ${customerLastname}`;
    const showBackButton = jobInfo.channel !== 'SCGHOME Offline';

    return (
      <div className="container">
        <div className="card">
          <div className="success-page" style={{ padding: '50px 30px' }}>
            <div className="result-message">
              <div>
                <img src="https://storage.googleapis.com/prod-qchang-v1/static/images/logo-new.png" alt="" style={{ width: '100px' }} />
              </div>
              <h4>ขอบคุณสำหรับความคิดเห็นของคุณ!</h4>
              <h6>Q-CHANG จะนำทุกความเห็นไปพัฒนาสินค้าและบริการต่อไป</h6>
            </div>
            <br/>
            {
              (showBackButton) && (
                <div className="row">
                  <div className="col-md-12">
                    {
                      orderId && (
                        <a className="btn btn-danger"
                          href={`${config.qchang.url}/tracking/${orderId}`}
                          target="_blank"
                          style={{ fontSize: '20px' }}>
                          <i className="fa fa-external-link btn-icon" aria-hidden="true" />กลับหน้าหลัก
                        </a>
                      )
                    }
                    {
                      (guaranteeFile) && (
                        <a className="btn btn-warning"
                          href={guaranteeFile}
                          target="_blank"
                          style={{ fontSize: '20px' }}>
                          <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />แสดงใบรับประกัน
                        </a>
                      )
                    }
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default inject('nps')(observer(NpsThankyouPage));
