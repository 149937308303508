
import React from 'react';
import QueryString from 'query-string';
import { observer, inject } from 'mobx-react';
import { config } from '../../config';
import '../../assets/css/stepprogress.css';
import NpsDetailPage from '../nps/NpsDetailPage';
import MobileDetect from 'mobile-detect';

export class JobAcceptanceResult extends React.Component {
  constructor(props) {
    super(props);

    let result;
    const qs = QueryString.parse(this.props.location.search);
    if (qs && qs.r) {
      result = qs.r;
    }

    const md = new MobileDetect(window.navigator.userAgent);
    this.state = {
      jobId: this.props.match.params.jobId,
      result,
      isMobileOrTablet: !!(md.mobile() || md.tablet()),
    };
    // this.props.reviewsku.saveInfo('jobId', this.props.match.params.jobId);
  }

  async componentWillMount() {
    try {
      await this.props.reviewsku.getJobForAcceptanceResult(this.state.jobId);
      const {nps } = this.props.reviewsku.toJS();
      this.props.nps.setNpsAllInfo(nps);
    } catch (err) {}
  }

  getComponentMessage(icon, iconFeeling, color, message, messagetxh, ) {
    const widthIcon = this.state.isMobileOrTablet ? '50px' : '80px';
    return (
      <div className="result-message">
        <div className="row">
          <div className="col-md-12">
            <img src="https://storage.googleapis.com/prod-qchang-v1/static/images/logo-new.png" alt="" style={{ width: widthIcon }} />
          </div>
          <div className="col-md-12" style={{ textAlign: 'center' }}>
          {this.state.isMobileOrTablet ? <h5>{message}</h5> : <h4>{message}</h4>}
          </div>
        </div>
        {/* <h4>{message}</h4> */}
        {/* <div>
          <i className={icon} style={{ fontSize: '60px', color: color }} />
        </div>
        <h6>{messagetxh}</h6> */}
      </div>
    );
  }

  render() {
    const { result } = this.state;
    const { jobInfo, nps } = this.props.reviewsku.toJS();
    const { orderId, status, guaranteeFile, customerFirstname, customerLastname } = jobInfo;
    const customerFullName = `${customerFirstname} ${customerLastname}`;
    const showBackButton = jobInfo.channel !== 'SCGHOME Offline';
    return (
      <div className=" job-acceptance-result">
        <div className="card" style={{ margin: '20px 0px 0px 0px' }}>
          <div className="success-page" style={{ padding: '30px 50px' }}>
            {
              result === 'rejected' && (
                this.getComponentMessage('fa fa-smile-o', 'fa fa-frown-o', 'orange', 'ทีมงานคิวช่างกราบขออภัยในความไม่สะดวก ทั้งนี้ทีมงานจะให้ช่างรีบเข้าไปแก้ไขโดยเร็วที่สุด', 'คิวช่างยินดีให้บริการ')
              )
            }
            {
              (result === 'review' || result === 'accepted') && (
                this.getComponentMessage('fa fa-check-circle', 'fa fa-smile-o', 'green', 'ทีมงานคิวช่างขอขอบพระคุณที่ท่านได้ให้คะแนนและร่วมแสดงความคิดเห็นให้กับการบริการในครั้งนี้', 'คิวช่างยินดีให้บริการ')
              )
            }
            {
              (showBackButton) && (
                <div className="row">
                  <div className="col-md-12">
                    {
                      orderId && (
                        <a className="btn btn-danger back-button"
                          href={`${config.qchang.url}/tracking/${orderId}`}
                          target="_blank"
                          style={{ fontSize: '20px' }}>
                          <i className="fa fa-external-link btn-icon" aria-hidden="true" />กลับหน้าหลัก
                        </a>
                      )
                    }
                    {
                      ((result === 'review' || result === 'accepted') && status === 'installation_accepted' && guaranteeFile) && (
                        <a className="btn btn-warning guarantee-button"
                          href={guaranteeFile}
                          target="_blank"
                          style={{ fontSize: '20px' }}>
                          <i className="fa fa-file-text-o btn-icon" aria-hidden="true" />แสดงใบรับประกัน
                        </a>
                      )
                    }
                  </div>
                </div>
              )
            }
          </div>
        </div>
        {result === 'accepted' && (
          <div className="card container" style={{ margin: '10px 0px' }}>
            <NpsDetailPage jobId={this.state.jobId} npsData={nps} customerFullName={customerFullName}/>
          </div>)
        }
      </div>
    );
  }
}

export default inject('reviewsku', 'nps')(observer(JobAcceptanceResult));
