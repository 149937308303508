import React, { Component } from 'react';

export class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handlePageClick(pageNumber, e) {
    e.preventDefault();
    if (typeof this.props.handlePageClick === 'function') {
      this.props.handlePageClick(pageNumber);
    }
  }

  render() {
    const {
      current_page: currentPage,
      previous_page: previousPage,
      next_page: nextPage,
      has_previous: hasPrevious,
      has_next: hasNext,
      is_first_page: isFirstPage,
      is_last_page: isLastPage,
      total_items: totalItems,
      total_pages: totalPages,
      start_page_range: startPageRange,
      end_page_range: endPageRange,
    } = this.props.pagination;

    const firstButton = isFirstPage ? (
      <li className="disabled">
        <a title="หน้าแรก"><i className="fa fa-angle-double-left" aria-hidden="true" />&nbsp;</a>
      </li>
    ) : (
      <li>
        <a href="" onClick={this.handlePageClick.bind(this, 1)} title="หน้าแรก"><i className="fa fa-angle-double-left" aria-hidden="true" />&nbsp;</a>
      </li>
    );

    const prevButton = hasPrevious ? (
      <li>
        <a href="" onClick={this.handlePageClick.bind(this, previousPage)} title="หน้าย้อนกลับ"><i className="fa fa-caret-left" aria-hidden="true" />&nbsp;</a>
      </li>
    ) : (
      <li className="disabled">
        <a title="หน้าย้อนกลับ"><i className="fa fa-caret-left" aria-hidden="true" />&nbsp;</a>
      </li>
    );

    const nextButton = hasNext ? (
      <li>
        <a href="" onClick={this.handlePageClick.bind(this, nextPage)} title="หน้าถัดไป"><i className="fa fa-caret-right" aria-hidden="true" />&nbsp;</a>
      </li>
    ) : (
      <li className="disabled">
        <a title="หน้าถัดไป"><i className="fa fa-caret-right" aria-hidden="true" />&nbsp;</a>
      </li>
    );

    const lastButton = isLastPage ? (
      <li className="disabled">
        <a title="หน้าสุดท้าย"><i className="fa fa-angle-double-right" aria-hidden="true" />&nbsp;</a>
      </li>
    ) : (
      <li>
        <a href="" onClick={this.handlePageClick.bind(this, totalPages)} title="หน้าสุดท้าย"><i className="fa fa-angle-double-right" aria-hidden="true" />&nbsp;</a>
      </li>
    );

    let pagers = [];
    for (let i = startPageRange; i <= endPageRange; i++) {
      if (currentPage === i) {
        pagers.push(
          <li className="active" key={`page-${i}`}>
            <a>{i}</a>
          </li>
        );
      } else {
        pagers.push(
          <li key={`page-${i}`}>
            <a href="" onClick={this.handlePageClick.bind(this, i)}>{i}</a>
          </li>
        );
      }
    }

    return (
      <nav>
        <ul className="pagination pagination-bar">
          {firstButton}
          {prevButton}
          {pagers}
          {nextButton}
          {lastButton}
        </ul>
        <div className="pagination-summary">หน้า {currentPage} จาก {totalPages} (ทั้งหมด {totalItems} รายการ)</div>
      </nav>
    );
  }
}

export default Pagination;
