import BaseStore from '../BaseStore';
import config from '../../config';
import { http } from '../../utils/http';

export class Review extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      info: this.initInfo(),
    });
  }

  initInfo() {
    return {
      name: '',
      lastname: '',
      typeOfJob: '',
      typeOfWork: '',
      score: 0,
      message: '',
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  async addReview() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      const response = await http.post(`${config.api.qchang}/v1/products`, info);
      if (response.status !== 200) {
        if (response.data && response.data.message === 'DUPLICATED_SLUG') {
          throw new Error('Slug ซ้ำ');
        }
        throw new Error('เพิ่มสินค้าไม่สำเร็จ');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new Review();
