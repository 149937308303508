import _ from 'lodash';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import swal from 'sweetalert2';
import moment from 'moment';
import momentTz from 'moment-timezone';
import querystring from 'query-string';
import Slider from 'rc-slider/lib/Slider';
import config from '../../../config';
import { FormValidation, required } from '../../../components/form/FormValidation';
import { InputGroup, FormControl } from 'react-bootstrap/lib';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnTextAreaFormGroup from '../../../components/form/EnTextAreaFormGroup';
import EnButton from '../../../components/form/EnButton';
import EnDatePicker from '../../../components/form/EnDatePicker';
import GoogleMap from '../../../components/form/GoogleMap';
import EnDropDown from '../../../components/form/EnDropDown';
import liffHelper from '../../../utils/bestdeal/LiffHelper';
import Loader from '../../../components/form/Loader';
import '../../../assets/css/bestdeal.css';
import '../../../assets/css/loader.css';
import 'rc-slider/assets/index.css';
// import '../../../utils/bestdeal/VConsole';
import numeral from 'numeral';

class Quotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      distance: 0,
      directionUrl: '',
      status: 'edit',
    };
    liffHelper.getProfile()
      .then(async (profile) => {
        this.initialData(profile);
      })
      .catch(async (err) => {
        console.log('err', err);
        const profile = {
          userId: 'Uacf17a894d30ce32437e7d018d8f89aa',
        };
        this.initialData(profile);
      });
  }

  async initialData(profile) {
    this.setState({ profile, distance: this.state.distance });
    let parsed = querystring.parse(this.props.location.search);
    const orderId = parsed.order_id;
    const status = parsed.status;
    const channel = parsed.channel;
    if (status === 'view' && orderId) {
      const storeLineId = parsed.store_line_id;
      await this.props.bestdealQuotation.getQuotationByOrderAndLineUserId(orderId, storeLineId);
      this.setState({ status: 'view' });
    } else if (orderId) {
      await this.props.bestdealQuotation.getQuotationByOrderAndLineUserId(orderId, profile.userId);
    }

    // to do 
    // หาวิธีดึงข้อมูลร้านมาใส่ info (remark)
    // this.saveInfo('remark', '');
    // console.log('this.props', this.props)

    if (channel) {
      this.saveInfo('vatInclude', true);
    }
    this.saveInfo('validPeriod', 30);
  }

  saveInfo(key, value) {
    this.props.bestdealQuotation.saveInfo(key, value);
  }

  onTextChange(index, key, e) {
    const value = e.target.value || 0;
    const bestdealQuotation = this.props.bestdealQuotation.toJS();
    const info = bestdealQuotation.info;
    const items = info.items;
    items[index][key] = value;
    this.saveInfo('items', items);
    if (key === 'unitPrice') {
      const netTotal = items.map(item => item.qty * item.unitPrice).reduce((a, b) => { return a + b; }, 0) || 0;
      let newNetTotal = parseFloat(netTotal) + parseFloat(info.shippingCost || 0);
      this.saveInfo('netTotal', newNetTotal);
      newNetTotal = info.vatInclude ? newNetTotal * 1.07 : newNetTotal;
      const depositValue = info.depositPercent * newNetTotal / 100;
      this.saveInfo('depositValue', depositValue.toFixed(2));
      this.saveInfo('depositRemain', newNetTotal - depositValue);
    }
  }

  onInfoChange(key, e) {
    const value = e.target.value || 0;
    this.saveInfo(key, value);
  }

  onDepositValueChanged(e) {
    const depositValue = e.target.value || 0;
    const bestdealQuotation = this.props.bestdealQuotation.toJS();
    const info = bestdealQuotation.info;
    const netTotal = info.vatInclude ? info.netTotal * 1.07 : info.netTotal;
    const percent = depositValue / netTotal * 100;
    const depositRemain = ((netTotal.toFixed(2) - depositValue) > 0 ? netTotal.toFixed(2) - depositValue : 0);
    this.saveInfo('depositPercent', percent);
    this.saveInfo('depositValue', depositValue);
    this.saveInfo('depositRemain', depositRemain);
  }

  onDepositPercentChanged(percent) {
    const bestdealQuotation = this.props.bestdealQuotation.toJS();
    const info = bestdealQuotation.info;
    const netTotal = info.vatInclude ? info.netTotal * 1.07 : info.netTotal;
    const depositValue = percent * netTotal / 100;
    const depositRemain = ((netTotal.toFixed(2) - depositValue) > 0 ? netTotal.toFixed(2) - depositValue : 0);
    this.saveInfo('depositPercent', percent);
    this.saveInfo('depositValue', depositValue.toFixed(2));
    this.saveInfo('depositRemain', depositRemain);
  }

  onShippingChange(e) {
    let value = e.target.value || 0;
    const bestdealQuotation = this.props.bestdealQuotation.toJS();
    const info = bestdealQuotation.info;
    const items = info.items;
    const netTotal = items.map(item => item.qty * item.unitPrice).reduce((a, b) => { return a + b; }, 0) || 0;
    let newNetTotal = parseFloat(netTotal) + parseFloat(value);
    this.saveInfo('netTotal', newNetTotal);
    newNetTotal = info.vatInclude ? newNetTotal * 1.07 : newNetTotal;
    this.saveInfo('shippingCost', value);
    this.saveInfo('depositRemain', newNetTotal - info.depositValue);
  }

  onDeleteItem(index) {
    const bestdealQuotation = this.props.bestdealQuotation.toJS();
    const items = _.cloneDeep(bestdealQuotation.info.items);
    items.splice(index, 1);
    this.saveInfo('items', items);
  }

  onCheckBoxItemClick(index, e) {
    const value = e.target.checked;
    const bestdealQuotation = this.props.bestdealQuotation.toJS();
    const items = bestdealQuotation.info.items;
    items[index].checkItem = value;
    this.saveInfo('items', items);
  }

  onCheckBoxClick(key, e) {
    const value = e.target.checked;
    this.saveInfo(key, value);
    if (key === 'vatInclude') {
      const bestdealQuotation = this.props.bestdealQuotation.toJS();
      const info = bestdealQuotation.info;
      const netTotal = info.vatInclude ? info.netTotal * 1.07 : info.netTotal;
      const depositValue = info.depositPercent * netTotal / 100;
      this.saveInfo('depositValue', depositValue.toFixed(2));
      this.saveInfo('depositRemain', netTotal - depositValue);
    }
  }
  async sendMessageToUser(quotation, botIndex) {
    let items = quotation.items.map((item, index) => `${index + 1}. ${item.name} ${item.spec}\n[ ${item.unit_price}  บ. x ${item.qty} ${item.unit} ] ${item.remark || ''}`).join('\n');
    items = 'ได้รับการเสนอราคาเรียบร้อยแล้ว\n' + items;
    items = (items.length > 156) ? items.substring(0, 156) + ' ...' : items;
    const message = {
      type: 'template',
      altText: 'ได้รับการเสนอราคาเรียบร้อยแล้ว',
      template: {
        type: 'buttons',
        text: `${items}`,
        actions: [
          {
            type: 'uri',
            label: 'แก้ไข',
            uri: `${config.bestdeal.liff[botIndex].quotation}?order_id=${quotation.order_id}`,
          },
        ],
      },
    };
    return message;
  }

  async onSubmit() {
    if (this.state.status === 'view') {
      liffHelper.closeWindow();
    } else {
      await this.saveInfo('lineUserId', this.state.profile.userId);
      const bestdealQuotation = this.props.bestdealQuotation.toJS();
      const info = bestdealQuotation.info;
      const order = bestdealQuotation.order;
      const isEdit = info && info.id;
      let parsed = querystring.parse(this.props.location.search);
      const botIndex = parsed['bot_index'] || 0;
      this.saveInfo('botIndex', botIndex);
      let apiResult;
      const timeOption = [
        'ช่วงเช้า (9.00-12.00)',
        'ช่วงบ่าย (13.00-17.00)',
      ];
      moment.locale('th');
      const expectedDate = `${momentTz(order.expectedDate).utcOffset(7).format('ddd Do/M/')}${parseFloat(momentTz(order.expectedDate).utcOffset(7).format('YYYY')) + 543}`;
      const expectedTime = timeOption[order.expectedTime - 1];
      const textDate = `<b>วันรับของ</b> : ${expectedDate}<br />`;
      const textTime = `<b>เวลารับของ</b> : ${expectedTime}<br />`;
      const textShiping = `<b>วิธีรับสินค้า</b> :  ${order.delivery ? 'จัดส่งโดยร้านส่ง' : 'รับเองที่ร้าน'}<br />`;
      const textConfirm = info.vatInclude ? `<b>ราคาก่อน VAT</b> ${numeral(info.netTotal.toFixed(2)).format('0,0.00')} บาท<br /><b>ราคารวม VAT</b> ${numeral(((info.netTotal * 1.07)).toFixed(2)).format('0,0.00')} บาท` : `<b>ราคาก่อน VAT</b> ${numeral(info.netTotal.toFixed(2)).format('0,0.00')} บาท`;
      let confirmDialogOptions = {
        title: isEdit ? 'ยืนยันการแก้ไขราคา' : 'ยืนยันการ‌เสนอ‌ราคา',
        html: `<div class="bestdeal">${textDate + textTime + textShiping + textConfirm}</div>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: 'font-size-200',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            if (isEdit) {
              apiResult = await this.props.bestdealQuotation.updateQuotation(info.id);
            } else {
              apiResult = await this.props.bestdealQuotation.createQuotation();
            }
            return apiResult;
          } catch (error) {
            swal.showValidationMessage(`การบันทึกล้มเหลว ${error.message}`);
          }
        },
        allowOutsideClick: () => !swal.isLoading(),
      };
      let result = await swal.fire(confirmDialogOptions);
      if (result.value) {
        const swalTitle = isEdit ? 'บันทึกข้อมูลสำเร็จ' : `บันทึกข้อมูลสำเร็จ\nส่งข้อมูลให้ลูกค้าทราบเรียบร้อยแล้ว`;
        swal.fire({
          title: swalTitle,
          icon: 'success',
        }).then(async () => {
          // this.props.bestdealQuotation.clearInfo();
          // if (isEdit) {
          //   this.onCancel();
          // } else {
          //   this.sendMessageToUser(apiResult).then(this.onCancel);
          // }
          const templateMsg = await this.sendMessageToUser(apiResult, botIndex);
          liffHelper.sendMessages(templateMsg)
            .then(() => {
              this.props.bestdealQuotation.clearInfo();
              this.onCancel();
            })
            .catch((err) => {
              console.error('error', err);
            });
          // this.sendMessageToUser(apiResult, botIndex).then(this.onCancel);
        });
      }
    }
  }

  onCancel() {
    liffHelper.closeWindow();
  }

  isValid() {
    const bestdealQuotation = this.props.bestdealQuotation.toJS();
    const info = bestdealQuotation.info;
    const checkProduct = info.items.filter(item => item.unitPrice > 0).length === info.items.length;
    const validDeposit = info.leadTime >= 0 && info.validPeriod >= 0 && info.depositValue >= 0 && info.depositRemain >= 0;
    return checkProduct && validDeposit;
  }

  getDirectionUrl(origin, destination) {
    const query = {
      api: 1,
      origin,
      destination,
      travelmode: 'driving',
      key: config.service.googleAppKey,
    };
    const googleApiUrl = `https://www.google.com/maps/dir/?${querystring.stringify(query)}`;
    return googleApiUrl;
  }

  render() {
    moment.locale('th');
    const bestdealQuotation = this.props.bestdealQuotation.toJS();
    const info = bestdealQuotation.info;
    const order = bestdealQuotation.order;
    const isEdit = info && info.id;
    const addressLabel = `ที่อยู่ลูกค้า : ${order.googleAddress}`;
    const timeOption = [
      { label: 'ช่วงเช้า (9.00-12.00)', value: 1 },
      { label: 'ช่วงบ่าย (13.00-17.00)', value: 2 },
    ];
    let timeDropdownOption = timeOption.map((item, idx) => {
      return <option key={`time-${idx}`} value={item.value}>{item.label}</option>;
    });
    const depositMarkPercent = {
      0: {
        style: {
          color: 'red',
        },
        label: <strong>ไม่มีมัดจำ</strong>,
      },
      30: '30%',
      50: {
        style: {
          color: 'red',
        },
        label: <strong>50%</strong>,
      },
      70: '70%',
      100: {
        style: {
          color: 'red',
        },
        label: <strong>จ่ายก่อนเต็มจำนวน</strong>,
      },
    };
    const items = info.items.map((item, index) => {
      return (
        <div className="bsd-product-sets" key={index}>
          <div className="bsd-title">
            <span>รายการสินค้าที่ {index + 1}</span>
            {
              (info.items).length > 1 &&
              <div className="bsd-delete"
                onClick={this.onDeleteItem.bind(this, index)}>
                <strong><span className="fa fa-minus-circle" aria-hidden="true" /></strong>
              </div>
            }
          </div>
          <div className="row">
            <FormGroup containerClassName={'col-xs-12'}>
              <span>{`${item.name} ${item.spec}   [${item.qty} ${item.unit}]`}</span>
            </FormGroup>
          </div>
          <div className="row">
            <FormGroup containerClassName="col-md-7 col-xs-7" label={'ราคาต่อหน่วย (ก่อน VAT) *'}>
              <InputGroup >
                <FormControl disabled={this.state.status === 'view' ? 1 : 0} className="box-number" id="unitPrice" type="number" label={'ราคาต่อหน่วย (ก่อน VAT)'} value={item.unitPrice || ''} onChange={this.onTextChange.bind(this, index, 'unitPrice')} validations={[required]} />
                <InputGroup.Addon><b>บาท</b></InputGroup.Addon>
              </InputGroup>
            </FormGroup>
            <EnTextFormGroup disabled={this.state.status === 'view' ? 1 : 0} id="remark" containerClassName="col-md-5 col-xs-5" label={'หมายเหตุ'} value={item.remark} onChange={this.onTextChange.bind(this, index, 'remark')} />
          </div>
        </div>
      );
    });
    let content;
    if (order.expectedDate) {
      if (moment().diff(order.expectedDate, 'days') > 0) {
        content = <FormValidation ref="FormValidation">
          <div className="row">
            <center><h4>{'ไม่สามารถเสนอราคาได้'}</h4></center>
          </div>
          <div className="row">
            <FormGroup containerClassName={'col-xs-12 col-md-12'} label={'วันที่กำหนด'}>
              <EnDatePicker
                disabled
                id="expectedDate"
                initialDate={order.expectedDate ? moment(order.expectedDate) : moment()}
              />
            </FormGroup>
          </div>
          <div className="text-center">
            <EnButton className="btn-info" onClick={this.onCancel.bind(this)}>{'ตกลง'}</EnButton>
          </div>
        </FormValidation>
      } else {
        content = <FormValidation ref="FormValidation">
          <div className="row">
            <FormGroup containerClassName={'col-xs-6 col-md-6'} label={'วันที่'}>
              <EnDatePicker
                disabled
                id="expectedDate"
                initialDate={order.expectedDate ? moment(order.expectedDate) : moment()}
              />
            </FormGroup>
            <FormGroup containerClassName={'col-xs-6 col-md-6'} label={'ช่วงเวลา'}>
              <EnDropDown disabled value={order.expectedTime}>
                {timeDropdownOption}
              </EnDropDown>
            </FormGroup>
          </div>
          <div className="row">
            <EnTextFormGroup id="totalPrice" containerClassName="col-md-12 col-xs-12" label={'วิธีรับสินค้า'} value={order.delivery ? 'จัดส่งโดยร้านส่ง' : 'รับเองที่ร้าน'} disabled />
          </div>
          <hr />
          {items}
          {order.delivery &&
            <div className="row">
              <FormGroup containerClassName="col-md-12 col-xs-12" label={`ราคาค่าขนส่ง (${order.distance})`}>
                <InputGroup >
                  <FormControl disabled={this.state.status === 'view' ? 1 : 0} className="box-number" id="shipping" type="number" label={'ราคาค่าขนส่ง'} value={info.shippingCost || ''} onChange={this.onShippingChange.bind(this)} />
                  <InputGroup.Addon><b>บาท</b></InputGroup.Addon>
                </InputGroup>
              </FormGroup>
            </div>
          }
          <div className="row">
            <FormGroup containerClassName={'col-xs-12'} label={'*หมายเหตุ'} >
              <label>&nbsp; : &nbsp;ลูกค้า{order.vatInfo && order.vatInfo.isRequire ? '' : 'ไม่'}ต้องการบิล VAT</label>
            </FormGroup>
          </div>
          <div className="row">
            <FormGroup containerClassName={'col-xs-4 col-md-2'} label={'ออกบิล VAT'} >
              <div>
                <input disabled={this.state.status === 'view' ? 1 : 0} type="checkbox" checked={info.vatInclude} id={`check-vat`} name="vat" onChange={this.onCheckBoxClick.bind(this, 'vatInclude')} />
                <label htmlFor={`check-vat`}>&nbsp;&nbsp;&nbsp;</label>
              </div>
            </FormGroup>
            <FormGroup containerClassName="col-md-5 col-xs-8" label={'ราคาก่อน VAT'}>
              <InputGroup >
                <FormControl className="box-number" id="sumPrice" type="text" label={'ราคาก่อน VAT)'} value={numeral(info.netTotal.toFixed(2)).format('0,0.00') || ''} disabled />
                <InputGroup.Addon><b>บาท</b></InputGroup.Addon>
              </InputGroup>
            </FormGroup>
            {info.vatInclude &&
              <FormGroup containerClassName="col-md-5 col-xs-8 col-xs-offset-4 col-md-offset-0" label={'ราคารวม VAT'}>
                <InputGroup >
                  <FormControl className="box-number" id="totalPrice" type="text" label={'ราคารวม VAT'} value={numeral(((info.netTotal * 1.07)).toFixed(2)).format('0,0.00')} disabled />
                  <InputGroup.Addon><b>บาท</b></InputGroup.Addon>
                </InputGroup>
              </FormGroup>
            }
          </div>
          <hr />
          <div className="row">
            <FormGroup containerClassName="col-md-6 col-xs-6" label={'ระยะเวลาสั่งผลิต'}>
              <InputGroup >
                <FormControl disabled={this.state.status === 'view' ? 1 : 0} className="box-number" id="leadTime" type="number" label={'ระยะเวลาสั่งผลิต'} value={info.leadTime || ''} onChange={this.onInfoChange.bind(this, 'leadTime')} />
                <InputGroup.Addon><b>วัน</b></InputGroup.Addon>
              </InputGroup>
            </FormGroup>
            <FormGroup containerClassName="col-md-6 col-xs-6" label={'ยืนราคา *'}>
              <InputGroup >
                <FormControl disabled={this.state.status === 'view' ? 1 : 0} className="box-number" id="validPeriod" type="number" label={'ยืนราคา'} value={info.validPeriod || ''} onChange={this.onInfoChange.bind(this, 'validPeriod')} validations={[required]} />
                <InputGroup.Addon><b>วัน</b></InputGroup.Addon>
              </InputGroup>
            </FormGroup>
          </div>
          <div className="row">
            <FormGroup containerClassName="col-md-11 col-xs-11" label={'เงื่อนไขการจ่ายเงิน มัดจำ'}>
              <Slider
                disabled={this.state.status === 'view' ? 1 : 0}
                min={0}
                max={100}
                step={10}
                marks={depositMarkPercent}
                included
                dots
                value={info.depositPercent}
                onChange={this.onDepositPercentChanged.bind(this)}
              />
            </FormGroup>
          </div>
          <p />
          <div className="row">
            <FormGroup containerClassName="col-md-6 col-xs-6" label={'มัดจำ'}>
              <InputGroup >
                <FormControl disabled={this.state.status === 'view' ? 1 : 0} className="box-number" id="depositValue" type="number" label={'เป็นเงิน'} value={info.depositValue || ''} onChange={this.onDepositValueChanged.bind(this)} validations={[required]} placeholder="0" />
                <InputGroup.Addon><b>บาท</b></InputGroup.Addon>
              </InputGroup>
            </FormGroup>

          </div>
          <div className="row">
            <FormGroup containerClassName="col-md-6 col-xs-6" label={'ส่วนที่เหลือ'}>
              <InputGroup >
                <FormControl className="box-number" id="depositRemain" type="text" label={'ส่วนที่เหลือ'} value={numeral(info.depositRemain).format('0,0.00') || ''} disabled />
                <InputGroup.Addon><b>บาท</b></InputGroup.Addon>
              </InputGroup>
            </FormGroup>
            <EnTextFormGroup id="depositRemark" containerClassName="col-md-6 col-xs-6" label={'ส่วนที่เหลือ จ่ายเมื่อ'} value={info.depositRemark} onChange={this.onInfoChange.bind(this, 'depositRemark')} placeholder={`สินค้าขึ้นรถ, ส่งถึงหน้างาน`} disabled={this.state.status === 'view' ? 1 : (info.depositRemain === 0 ? 1 : 0)} />
            {/* <EnTextFormGroup id="depositOther" containerClassName="col-md-6 col-xs-6" label={'อื่นๆ'} value={info.depositOther} onChange={this.onInfoChange.bind(this, 'depositOther')} /> */}
          </div>
          <hr />
          <div className="row">
            {/* <a href={`${this.state.directionUrl}`} target="_blank">นำทาง</a> */}
            <FormGroup containerClassName={'col-md-12'} label={`${addressLabel} (${order.distance})`}>
              <GoogleMap
                center={order.location}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />} />
            </FormGroup>
          </div>
          <hr />
          <div className="row">
            <EnTextAreaFormGroup rows="5" id="remark" containerClassName="col-md-12 col-xs-12" label={'หมายเหตุ'} value={info.remark} onChange={this.onInfoChange.bind(this, 'remark')} disabled={this.state.status === 'view'} />
          </div>
          <hr />
          <div className="text-center">
            <EnButton className="btn-info" disabled={!this.isValid()} onClick={this.onSubmit.bind(this)}>{this.state.status === 'view' ? 'ปิด' : (isEdit ? 'แก้ไขใบเสนอราคา' : 'กดส่งราคา')}</EnButton>
          </div>
        </FormValidation>;
      }
    }

    return (
      <div className="content" >
        <Loader loading={bestdealQuotation.isLoading} />
        <div className="container-fluid">
          <div className="row">
            <div>
              <div className="card info">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">เสนอราคาสินค้า</h4>
                </div>
                <div className="card-content">
                  {content}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default inject('bestdealQuotation')(observer(Quotation));
