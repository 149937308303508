import React from 'react';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Notification from '../../../components/common/Notification';
import EnDropZone from '../../../components/form/EnDropZone';
import { Modal, Button } from 'react-bootstrap';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup'
import AuthStore from '../../../stores/AuthStore';
import { config } from '../../../config';
import FormGroup from '../../../components/form/FormGroup';
import readXlsxFile from 'read-excel-file'
import Loader from '../../../components/common/Loader';






export class ManageDownload extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {};
    this.state = {
      showModal: false,
      rows: [],
      uploadeType: this.props.match.params.type,
      files: [],
      fileName: '',
      description: ''
    };
  }

  async onAddNew() {
    this.setState({
      showModal: true,
      modalMode: 'create',
      file: null,
      modal: {
        courseName: '',
        courseDesc: ''
      },
    });
  }
  closeModal() {
    this.setState({
      showModal: false
    })
    this.setDefalutState()
  }
  onAddedFile(e) {
    if (this.dropZone) {
      this.setState({
        addedFiles: this.dropZone.getFiles(),
      });
    }
  }



  async componentDidMount() {
    const { uploadeType } = this.state;
    switch (uploadeType) {
      case 'transfer_updated':
        await this.props.transfer.getUploadeFiles(uploadeType);
        break;
      default:
        await this.props.transfer.getUploadeFiles();
        break;
    }
  }

  getTableRows(items) {
    return items.map((item, index) => {
      let status = <div />;
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          {/* <td >{item.file_url}</td> */}
          <td className="text-center">{item.file_name}</td>
          <td className="text-center">{moment(item.date).format('DD-MM-YYYY')}<br />{moment(item.date).format('HH:mm:ss')}</td>
          <td className="text-center">{item.description}</td>
          <td className="text-center" style={{ verticalAlign: 'middle' }}>
            {item.file_url ? (<a className="btn btn-xs" title="ดาวน์โหลด" data-tip="ดาวน์โหลด" href={item.file_url} target="_blank">
              <span className="fa fa-download" aria-hidden="true" /></a>) : status}
          </td>
        </tr>
      );
    });
  }
  getDropZone(ref, callback) {
    const url = `${config.api.sims}/v1/transfer/upload`;
    const token = AuthStore.getToken();

    return (
      <EnDropZone
        ref={ref}
        url={url}
        token={token}
        acceptedFiles={'*,.xls,.xlsx,.csv'}
        onAllComplete={callback}
        maxFileSize={3}
        maxFiles={1}
        onAddedFile={this.onAddedFile.bind(this)}
        onRemovedFile={this.setDefalutState.bind(this)}
        onError={this.onUploadError.bind(this)}
      />
    );
  };
  async onUploadComplete(files) {
    if (files && files.length) {
      // console.log('files',files[0].file);

      try {
        const rows = await readXlsxFile(files[0].file)
        this.setState({
          file: files[0],
          fileName: files[0].file.name,
          rows
        })
        // console.log('rows',rows);
        if (this.dropzone && this.dropzone.getFiles() && this.dropzone.getFiles().length) {
          console.log('dropzone', this.dropzone.getFiles());

        }

      } catch (error) {
        this.onUploadError(error.message)
      }
    }
  }
  async onChange(e) {
    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    const rows = await readXlsxFile(files[0])
    //   this.setState({
    //     file:files[0].res.data,
    //     fileName: files[0].file.name
    // })

  }
  setDefalutState() {
    this.setState({
      rows: [],
      files: [],
      fileName: '',
      description: ''
    })
  }
  async onSave() {
    const { uploadeType, description, file, rows, fileName } = this.state;
    try {
      const data = {
        uploadeType, description, file, rows, fileName
      }
      // console.log('data', data);
      this.closeModal()
      await this.props.transfer.updateTransferFile(data)
      await this.props.transfer.getUploadeFiles(uploadeType);
    } catch (error) {
      console.error('error', error);
      this.onUploadError(error.message)
    }
  }
  validateSave() {
    if (this.state.rows && this.state.file && this.state.description) {
      return false
    }
    return true
  }
  onUploadError(message) {
    swal.fire({
      icon: 'error',
      title: `${message}`,
      text: 'กรุณาลองใหม่อีกครั้ง',
    });
  }

  render() {
    const disableSave = this.validateSave()
    const { items, isLoading } = this.props.transfer.toJS();
    let rows = this.getTableRows(items);
    const dropzone = this.getDropZone(e => { this.dropzone = e; }, (e) => {
      this.onUploadComplete(e);
    });

    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <Modal show={this.state.showModal}
          onHide={e => { this.closeModal() }}
        >
          <Modal.Header closeButton={false} style={{ backgroundColor: '#f3873a', paddingTop: 12, paddingBottom: 12 }}>
            <Modal.Title style={{ color: 'white' }}>{
              "อัพโหลดไฟล์"
            }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {this.state.fileName &&
                <div style={{ marginLeft: 10 }}><i className="fa fa-file-excel-o"></i> {this.state.fileName}</div>

              }
              <FormGroup containerClassName="col-md-12" label="อัพโหลดไฟล์" >
                {dropzone}
                {/* <input type="file" multiple onChange={this.onChange} /> */}
              </FormGroup>
              <EnTextFormGroup
                id="courseDetail"
                containerClassName="col-md-12"
                type="text"
                label={'รายละเอียด *'}
                value={this.state.description}
                onChange={e => {
                  // this.onChangeModalData('courseDesc', e.target.value)
                  this.setState({
                    description: e.target.value
                  })
                }}
              // onChange={this.onInfoChanged.bind(this, 'name')}
              // validations={[required]}
              />

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-danger" title="ยกเลิก" data-tip="ยกเลิก" onClick={e => { this.closeModal() }} >
              <span className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
            </button>
            <button className="btn btn-info" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={e => { this.onSave() }} disabled={disableSave}>
              <span className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
            </button>

          </Modal.Footer>
        </Modal>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">อัพโหลดรายการจ่ายเงิน</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="10%" className="text-center">ลำดับที่</th>
                            <th width="30%" className="text-center">ชื่อไฟล์</th>
                            {/* <th width="10%" className="text-center">สถานะดาวน์โหลด</th> */}
                            <th width="30%" className="text-center">เวลา</th>
                            <th width="50%" className="text-center">รายละเอียด</th>
                            <th width="10%" className="text-center">
                              <button className="btn btn-success" title="อัพโหลด" data-tip="อัพโหลด" onClick={this.onAddNew.bind(this)}>
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />อัพโหลด
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('download', 'transfer')(observer(ManageDownload));
