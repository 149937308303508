import React, { Component } from "react";
import '../../assets/css/document-picker.css';
import { get } from "lodash";

export default class DocumentPicker extends Component {
  constructor(props) {
    super(props)
    this.inputRef = React.createRef()
    this.state = {
      file: props.file,
      disabled: props.disabled
    }
  }

  componentDidUpdate(prevProps) {
    if ((get(prevProps, 'file.name')) != get(this.props, 'file.name'))
      this.setState({ file: this.props.file })
    if ((get(prevProps, 'disabled')) != get(this.props, 'disabled'))
      this.setState({ disabled: this.props.disabled })
  }

  triggerInput() {
    this.inputRef.current.click();
  }

  handleFileChange (event) {
    const file = event.target.files[0];
    this.props.onChange(file)
    event.target.value = '';
    this.setState({
      file
    })
  }

  render () {
    return (
      <div className="document-picker">
       {
        this.state.file ? (
          <span className="file-selected">{this.state.file.docName || this.state.file.name}</span>
        ) : (
          <span>ไม่มีรูปถ่ายหรือไฟล์แนบ</span>
        )
       }
        {
          !this.state.disabled ? 
          (
            <div className="picker-btn" onClick={this.triggerInput.bind(this)}>
              <i className='fa fa-file-text-o btn-icon' aria-hidden='true' />
              แนบเอกสาร
            </div>
          ) : ''
        }
        <input ref={this.inputRef} type="file" accept={this.props.accept || ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"} style={{ display: 'none' }} onChange={this.handleFileChange.bind(this)} />
      </div>
    )
  }
}