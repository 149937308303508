import React from 'react';
import { observer, inject } from 'mobx-react';
import { NavLink, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import { History } from '../utils/history';
import config from '../config';
import { isIOS, isAndroid } from 'react-device-detect';
import moment from 'moment';
import stateInstallAppAlert, { Auth } from '../../src/stores/AuthStore';

export class SideMenuContractor extends React.Component {
  constructor(props) {
    super(props);
    let stateInstallAppAlert = new Auth();
    let thisTime = moment(new Date()).format('YYYYMMDD');

    if (stateInstallAppAlert.getStateInstallAppAlert() !== thisTime || stateInstallAppAlert.getStateInstallAppAlert() === undefined) {
      this.detectDeviceInstall(thisTime);
    }
  }

  timeResetStateInstallAppAlert() {
    stateInstallAppAlert.setStateInstallAppAlert(true);
  }

  detectDeviceInstall(thisTime) {
    if (isIOS) {
      let iosUrl = 'https://apps.apple.com/th/app/q-chang-buddy/id1527007014';
      this.getInstallApp(iosUrl);
    } else if (isAndroid) {
      let androidUrl = 'https://play.google.com/store/apps/details?id=com.qchang.qchang_app';
      this.getInstallApp(androidUrl);
    }
    stateInstallAppAlert.setStateInstallAppAlert(thisTime);
  }

  async onSignOut() {
    const { value } = await Swal.fire({
      title: 'ออกจากระบบ',
      text: 'คุณต้องการออกจากระบบหรือไม่?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
    });

    if (value) {
      Swal.close();
      this.props.auth.removeContractor();
      setTimeout(() => {
        History.replace(`${config.publicUrl}/contractors/signin`);
      }, 200);
    }
  }

  toggleMenu() {
    let doc = document.getElementsByClassName('perfect-scrollbar-on');
    if (doc && doc.length) {
      let hDoc = doc[0];
      hDoc.classList.toggle('nav-open', false);
    }
  }

  async getInstallApp(appUrl) {
    Swal.fire({
      title: 'อัพเกรดเป็น Q-chang Buddy ที่สะดวกกว่าเดิม',
      html: '<ul class="fa-ul" align="left">' +
        '<li><span class="fa-li"><i class="fa fa-check" style="color:green"></i></span><B>เข้าใจง่าย</B> ไม่ต้องล๊อคอินผ่านไลน์</li>' +
        '<li><span class="fa-li"><i class="fa fa-check" style="color:green"></i> </span><B>มีระบบแจ้งเตือน</B> ไม่ให้ช่างพลาดงาน</li>' +
        '<li><span class="fa-li"><i class="fa fa-check" style="color:green"></i> </span><B>ส่งงานสะดวก</B> อัพรูปเร็ว ได้หลายรูปในครั้งเดียว</li>' +
        '<li><span class="fa-li"><i class="fa fa-check" style="color:green"></i> </span><B>ช่างเก่าใช้ได้เลย</B> ข้อมูลเหมือนกันทั้ง 2 ระบบ</li>' +
        '</ul>',
      imageUrl: 'https://storage.googleapis.com/prod-qchang-v1/static/images/logo-new.png',
      imageWidth: 80,
      imageHeight: 110,
      showCancelButton: true,
      confirmButtonColor: '#f86901',
      cancelButtonColor: '#F5F5DC',
      confirmButtonText: '<div><h7>ดาวน์โหลด</h7></div>',
      cancelButtonText: '<div ><h7 style="color:#f86901">ใช้ในเว็บต่อไป</h7></div>',
    }).then(async (result) => {
      if (result.value) {
        window.location.assign(appUrl);
      }
    });
  }

  render() {
    let contractor = this.props.auth.getContractor();
    let contractorId = contractor._id;
    const isApproved = contractor.status === 'approved';
    return (
      <div className="sidebar-wrapper">
        <ul className="nav">
          <li>
            <NavLink to={`${config.publicUrl}/contractors/${contractorId}/calendar`} onClick={this.toggleMenu.bind(this)} activeClassName="active">
              <i className="fa fa-calendar" aria-hidden="true" />
              <p id="select-calendar">ปฏิทิน</p>
            </NavLink>
          </li>
          <li>
            <NavLink to={`${config.publicUrl}/contractors/${contractorId}/profile`} onClick={this.toggleMenu.bind(this)} activeClassName="active">
              <i className="fa fa-user" aria-hidden="true" />
              <p id="select-profile">ข้อมูลส่วนตัว</p>
            </NavLink>
          </li>
          <li>
            <NavLink to={`${config.publicUrl}/contractors/${contractorId}/typeofwork`} onClick={this.toggleMenu.bind(this)} activeClassName="active">
              <i className="fa fa-location-arrow" aria-hidden="true" />
              <p id="select-location">แก้ไขประเภทงาน</p>
            </NavLink>
          </li>
          <li>
            <NavLink to={`${config.publicUrl}/contractors/${contractorId}/workarea`} onClick={this.toggleMenu.bind(this)} activeClassName="active">
              <i className="fa fa-map-marker" aria-hidden="true" />
              <p id="select-workarea">แก้ไขขอบเขตรับงาน</p>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={`${config.publicUrl}/contractors/${contractorId}/pin`} onClick={this.toggleMenu.bind(this)} activeClassName="active">
              <i className="fa fa-key" aria-hidden="true" />
              <p id="select-pin">แก้ไข Pin</p>
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink to={`${config.publicUrl}/contractors/${contractorId}/profilepicture`} onClick={this.toggleMenu.bind(this)} activeClassName="active">
              <i className="fa fa-user-circle-o" aria-hidden="true" />
              <p id="select-profile-picture">แก้ไขรูปภาพ</p>
            </NavLink>
          </li> */}
          <li>
            <NavLink to={`${config.publicUrl}/contractors/${contractorId}/grade`} onClick={this.toggleMenu.bind(this)} activeClassName="active">
              <i className="fa fa-tachometer" aria-hidden="true" />
              <p id="select-profile-picture">เกรด</p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${config.publicUrl}/contractors/${contractorId}/jobs/list`}>
              <i className="fa fa-tasks" aria-hidden="true" />
              <p id="job-list">รายการคิวช่าง</p>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`${config.publicUrl}/contractors/${contractorId}/transfer/summary`}>
              <i className="fa fa-money" aria-hidden="true" />
              <p id="transfer-summary">สรุปเงินที่ได้รับ</p>
            </NavLink>
          </li>
          {isApproved &&
            (
              <li>
                <NavLink
                  to={`${config.publicUrl}/contractors/${contractorId}/agreement`}>
                  <i className="fa fa-list-alt" aria-hidden="true" />
                  <p id="transfer-summary">รายละเอียดและข้อตกลง</p>
                </NavLink>
              </li>
            )
          }

          <li>
            <a className="c-pointer" onClick={this.onSignOut.bind(this)}>
              <i className="fa fa-sign-out" aria-hidden="true" />
              <p id="select-logout">ออกจากระบบ</p>
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(inject('auth')(observer(SideMenuContractor)));
