import React from "react";
import { Modal } from "react-bootstrap";
import EnButton from "../form/EnButton";
import { observer, inject } from "mobx-react";

class ModalAuthWithReason extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reasonChanged: false,
    };
  }

  handleClose() {
    this.props.permission.setShowReasonModal(false);
    this.props.permission.setReason("");
    this.props.permission.setPassword("");
    this.setState({ reasonChanged: false });
  }

  toggleVisibility() {
    const passwordInput = document.getElementById("input-reason-password");
    const iconsInput = document.getElementById("input-icons");
    if (passwordInput.type === "password") {
      passwordInput.type = "text";
      iconsInput.innerText = "visibility_off";
    } else {
      passwordInput.type = "password";
      iconsInput.innerText = "visibility";
    }
  }

  handleSubmit() {
    this.setState({ reasonChanged: true });
    this.props.onSubmit();
  }

  render() {
    const { openReasonModal, passwordLogActivity, statusPassword, reason } =
      this.props.permission.toJS();
    return (
      <Modal show={openReasonModal}>
        <div className="position-modal-center">
          <h3>กรุณากรอกเหตุผล</h3>
          <div className="row" style={{ marginTop: "2vw" }}>
            <div className="col-md-12">
              <textarea
                id="input-reason"
                rows="2"
                placeholder="กรุณากรอกเหตุผล"
                value={reason}
                onChange={(e) => {
                  this.setState({ reasonChanged: true });
                  this.props.permission.setReason(e.target.value);
                }}
              />
              {this.state.reasonChanged && !reason && (
                <div className="input-text-error">
                  <span className="input-error">โปรดกรอกเหตุผล</span>
                </div>
              )}
            </div>
          </div>
          { // For prevent insert password section ref with task 2345
            !this.props.showReasonOnly &&
            <React.Fragment>
              <div className="row">
                <div className="col-md-12" style={{ marginTop: "1vw" }}>
                  <input
                    type="password"
                    id="input-reason-password"
                    placeholder="กรุณากรอกรหัสผ่าน"
                    value={passwordLogActivity}
                    onChange={(e) => {
                      this.props.permission.setPassword(e.target.value);
                    }}
                  />
                  <i id="visibilityBtn" onClick={this.toggleVisibility.bind(this)}>
                    <span id="input-icons" className="material-icons icons-eyes">
                      visibility
                    </span>
                  </i>
                </div>
              </div>
              {statusPassword.status && (
                <div className="input-text-error">
                  <span className="input-error">{statusPassword.text}</span>
                </div>
              )}
            </React.Fragment>
          }
          <div style={{ marginTop: "3vw" }}>
            <EnButton
              className="btn-danger"
              onClick={this.handleClose.bind(this)}
            >
              <i className="fa fa-times btn-icon" aria-hidden="true" />
              ยกเลิก
            </EnButton>
            <EnButton className="btn-info" onClick={this.handleSubmit.bind(this)}>
              <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
              บันทึก
            </EnButton>
          </div>
        </div>
      </Modal>
    );
  }
}

export default inject("permission")(observer(ModalAuthWithReason));
