import BaseStore from './BaseStore';

export class ModalStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      displayModalName: '',
      isLoadingJobWizard: false
    });
  }

  setDisplayModalName(value) {
    this.displayModalName = value;
  }

  setIsLoadingJobWizard(value) {
    this.isLoadingJobWizard = value;
  }
}

export default new ModalStore();
