import qs from 'query-string';
import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';

export class TransferDetailStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      query: {},
      data: [],
      // pagination: {},
    });
    this.setDefaultQuery();
  }

  setDefaultQuery() {
    this.query = {
      // page: 1,
      // page_size: 10,
      // page_range: 5,
      contractor_id: '',
      effective_date: undefined,
      gateway_name: '',
    };
  }

  setQueryValue(key, value) {
    this.query[key] = value;
  }

  async searchTranferDetail() {
    if (this.isLoading) return;
    this.isLoading = true;
    const { query } = this.toJS();
    const contractorId = query.contractor_id;
    // query.contractor_id = '';
    try {
      const res = await http.get(`${config.api.sims}/v1/contractors/${contractorId}/transfers/details?${qs.stringify(query)}`);
      if (res && res.data && res.data.data) {
        this.data = res.data.data;
        // this.pagination = res.data.pagination;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new TransferDetailStore();
