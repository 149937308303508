import React, { Component } from "react";
import '../../assets/css/simple-pagination.css'
import _ from "lodash";

export default class SimplePagination extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      limit: 30,
      totalItems: 0,
      totalPages: 1,
    }

  }
  
  componentDidMount() {
    this.initProps(this.props.pagination)
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props, prevProps)) {
      this.initProps(this.props)
    }
  }

  initProps(props) {
    this.setState({ ...props.pagination })
  }

  getPaginationLimit(currentPage, totalPages) {  
    let offset = (currentPage - 1) % this.state.limit
    let startPage = Math.max(currentPage - offset, 1)
    let endPage = Math.min(startPage + this.state.limit - 1, totalPages)

    return [startPage, endPage]
  }

  setPage = (page) => {
    if (this.props.onSetPage) this.props.onSetPage(page)
    this.setState({ page })
  }

  render() {
    const [start, end] = this.getPaginationLimit(this.state.page, this.state.totalPages)
    const { page, totalItems, totalPages } = this.state
    const firstButton = (
      <li className={ page <= 1 ? 'disabled' : ''}>
        <a onClick={() => this.setPage(1)}>
          <i className="fa fa-angle-double-left" aria-hidden="true" />
        </a>
      </li>
    );
    const lastButton = (
      <li className={ page >= totalPages ? 'disabled' : ''}>
        <a onClick={() => this.setPage(totalPages)}>
          <i className="fa fa-angle-double-right" aria-hidden="true" />
        </a>
      </li>
    );
    const prevButton = (
      <li className={ page <= 1 ? 'disabled' : '' }>
        <a onClick={() => this.setPage(page - 1)}>
          <i className="fa fa-caret-left" aria-hidden="true" />
        </a>
      </li>
    );
    const nextButton = (
      <li className={ page >= totalPages ? 'disabled' : '' }>
        <a onClick={() => this.setPage(page + 1)}>
          <i className="fa fa-caret-right" aria-hidden="true" />
        </a>
      </li>
    );
    const items = _.range(start, end + 1).map((i) => (
      <li key={i} className={i === page ? 'active' : ''}>
        <a onClick={() => this.setPage(i)}>{i}</a>
      </li>
    ))

    return (
      <nav className="navigation">
        <ul className="pagination simple-pagination">
          {firstButton}
          {prevButton}
          {items}
          {nextButton}
          {lastButton}
        </ul>
        <div className="pagination-summary">หน้า {page} จาก {totalPages} (ทั้งหมด {totalItems} รายการ)</div>
      </nav>
    )
  }
}
