import React, { Component } from 'react';

export class EnStickyFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: props.step ? props.step : '1',
      allStep: props.allStep ? props.allStep : '5',
      stepName: props.stepName ? props.stepName : '',
    };
  }

  render() {
    let button = this.props.button;

    return (
      <div className="bottom-fixed">
        <div className="col-md-8 col-xs-8">
          <h6> {this.state.stepName} (หน้า {this.state.step} จาก {this.state.allStep})</h6>
        </div>
        <div className="col-md-4 col-xs-4">
          {button}
        </div>
      </div>
    );
  }
}

export default EnStickyFooter;
