import React from 'react';
import { observer, inject } from 'mobx-react';
import EnTextFormGroup from '../../../../components/form/EnTextFormGroup';
import common from '../../../../utils/common';

export class SeoSection extends React.Component {
  onSeoChange(key, e) {
    if (e.target) {
      if (key === 'slug') {
        this.props.product.saveSeoInfo(common.slugify(e.target.value), key);
      }
      this.props.product.saveSeoInfo(e.target.value, key);
    }
  }

  onProductChange(e) {
    if (e.target) {
      this.props.product.saveProductType(e.target.value);
    }
  }

  render() {
    const { title, slug, keywords, description } = this.props.seo;
    return (
      <div className="seo">
        <div className="header-seo">
          <span>SEO</span>
        </div>
        <div className="seo-form">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <EnTextFormGroup
                  containerClassName="col-md-6"
                  type="text"
                  label="SEO Title"
                  value={title}
                  onChange={(e) => {
                    this.onSeoChange('title', e);
                  }}
                />
                <EnTextFormGroup
                  containerClassName="col-md-6"
                  type="text"
                  label="Slug"
                  value={slug}
                  onChange={(e) => {
                    this.onSeoChange('slug', e);
                  }}
                />
              </div>
              <div className="row">
                <EnTextFormGroup containerClassName="col-md-12" type="text" label="Keyword" placeholder="รางน้ำฝน, รางน้ำฝนพร้อมติดตั้ง, เอสซีจี รุ่น SMART สีขาว" value={keywords} onChange={this.onSeoChange.bind(this, 'keywords')} />
              </div>
              <div className="row">
                <EnTextFormGroup
                  containerClassName="col-md-12"
                  type="text"
                  label="Meta Description"
                  placeholder="ผลิตภัณฑ์รางน้ำไวนิล เอสซีจี เป็นวัสดุหนึ่งของรางน้ำฝนที่หลายบ้านนิยมใช้ เนื่องจากคุณสมบัติที่แข็งแรง ทนทาน ไม่ลามไฟ"
                  value={description}
                  onChange={this.onSeoChange.bind(this, 'description')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <label>ตัวอย่าง</label>
              <div className="seo seo-form">
                <div className="seo-preview-title">{title || ''}</div>
                <div className="seo-preview-link">
                  <cite>
                    {'https://partner.q-chang.com/products/'}
                    <mark>{slug || ''}</mark>
                  </cite>
                </div>
                <div className="seo-preview-description">
                  <div>
                    <span>{description || ''}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('product')(observer(SeoSection));
