import React, { Component } from "react";
import moment from "moment";
import { SingleDatePicker } from "react-dates";
import datetime from "../../utils/datetime";
import DatePicker, { registerLocale } from "react-datepicker";
import th from "date-fns/locale/th";
import "react-datepicker/dist/react-datepicker.css";
import { control } from 'react-validation';
import _ from 'lodash';
registerLocale('th', th);

export class EnSingleDatePicker extends SingleDatePicker {
  getDateString(date) {
    return datetime.ConvertToBuddhistYear(date);
  }
}

export class EnDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateValue: moment(),
      focused: false,
    };
  }

  value() {
    return this.state.dateValue;
  }

  onDateChange(date) {
    this.setState({
      dateValue: date,
    });

    if (this.props.onDateChange) {
      this.props.onDateChange(date);
    }
  }

  isDayBlocked(dateMoment) {
    if (this.props.isDayBlocked) {
      return this.props.isDayBlocked(dateMoment);
    } else {
      return false;
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState((prevState, props) => {
        return {
          dateValue: nextProps.value,
        };
      });
    }
  }

  render() {
    let {
      id,
      disabled,
      initialDate,
      isOutsideRange,
      openDirection,
      isPlaceholder,
      isBuddhistYear = true,
    } = this.props;
    return (
      <div className="en-datepicker">
        {
          isBuddhistYear ? (
            <EnSingleDatePicker
            id={id}
            isDayBlocked={this.isDayBlocked.bind(this)}
            ref={(e) => {
              this.input = e;
            }}
            renderMonth={(date) => datetime.ConvertToBuddhistYear(date, "MMMM ")}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            isOutsideRange={isOutsideRange}
            date={initialDate}
            onDateChange={this.onDateChange.bind(this)}
            focused={this.state.focused}
            onFocusChange={({ focused }) => this.setState({ focused })}
            disabled={disabled}
            placeholder={isPlaceholder}
            openDirection={openDirection}
          />
          ) : (
              <SingleDatePicker
              id={id}
              isDayBlocked={this.isDayBlocked.bind(this)}
              ref={(e) => {
                this.input = e;
              }}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel
              isOutsideRange={isOutsideRange}
              date={initialDate}
              onDateChange={this.onDateChange.bind(this)}
              focused={this.state.focused}
              onFocusChange={({ focused }) => this.setState({ focused })}
              disabled={disabled}
              placeholder={isPlaceholder}
              openDirection={openDirection}
            />
          )
        }
      </div>
    );
  }
}

const EnhanceMonthPicker = ({ onChangeStartDate, onChangeEndDate, initialDate, maxMonthSelected = 0 }) => {
  const customHeader = (props) => {
    const {
      date,
      increaseYear,
      decreaseYear,
      prevYearButtonDisabled,
      nextYearButtonDisabled,
    } = props;
    const getMonth = (date) => {
      return datetime.ConvertToBuddhistYear(moment(date), "MMMM ");
    };
    return (
      <div className="custom-month-picker-header">
        <button
          className="btn-next-prev-calendar"
          onClick={decreaseYear}
          disabled={prevYearButtonDisabled}
        >
          <span className="fa fa-long-arrow-left" aria-hidden="true" />
        </button>
        {getMonth(date)}
        <button
          className="btn-next-prev-calendar"
          onClick={increaseYear}
          disabled={nextYearButtonDisabled}
        >
          <span className="fa fa-long-arrow-right" aria-hidden="true" />
        </button>
      </div>
    );
  };
  
  return (
    <div className="d-flex">
      <DatePicker
        renderCustomHeader={customHeader}
        selected={initialDate.start_date}
        onChange={onChangeStartDate}
        selectsStart
        startDate={initialDate.start_date}
        endDate={initialDate.end_date}
        showMonthYearPicker
        showFullMonthYearPicker
        locale="th"
        calendarClassName="custom-month-picker"
        className="custom-month-picker-input"
        value={initialDate.start_date ? moment(initialDate.start_date).add(543, 'years').format("MM/YYYY") : ''}
        placeholderText="Month"
        minDate={initialDate.end_date ? moment(initialDate.end_date).subtract((maxMonthSelected - 1), 'months').endOf('month').toDate() : undefined}
        maxDate={initialDate.end_date}
        isClearable
      />
      <span className="fa fa-minus month-divider" aria-hidden="true" />
      <DatePicker
        renderCustomHeader={customHeader}
        selected={initialDate.end_date}
        onChange={onChangeEndDate}
        selectsEnd
        startDate={initialDate.start_date}
        endDate={initialDate.end_date}
        showMonthYearPicker
        showFullMonthYearPicker
        locale="th"
        calendarClassName="custom-month-picker"
        className="custom-month-picker-input"
        value={initialDate.end_date ? moment(initialDate.end_date).add(543, 'years').format("MM/YYYY") : ''}
        placeholderText="Month"
        maxDate={initialDate.start_date ? moment(initialDate.start_date).add((maxMonthSelected - 1), 'months').endOf('month').toDate() : undefined}
        minDate={initialDate.start_date}
        isClearable
      />
    </div>
  );
};

export const EnDatePickerValidation = control(({ error, isChanged, isUsed, ...props }) => {
  if ((error && isChanged, isUsed)) {
    props.className = `${props.className || ''} is-invalid-input`;
  }

  return (
    <div>
      <EnDatePicker {...props} />
      {(!_.isNil(error) && !_.isEmpty(error) && isChanged, isUsed ? error : null)}
    </div>
  );
});

EnDatePicker.MonthPicker = EnhanceMonthPicker;
export default EnDatePicker;
