import React, { Component } from 'react';

export class EnCheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateFields: {
        model: false
      }
    };
  }

  onChange = (event) => {
    return this.props.onChange(event);
  };

  render() {
    const { checked, className, disabled, style, text} = this.props;
    const classesDiv = `checkbox-form s-checkbox ${className || ''}`;

    return (
      <div
        className={classesDiv}
        style={{
          ...style || {}
        }}
      >
        <label
          className='custom-checkbox size-20x20'
          style={{
            display: 'block',
            cursor: disabled ? 'not-allowed' : 'pointer'
          }}
        >
          <input
            disabled={disabled || false}
            type="checkbox"
            checked={checked}
            onChange={this.onChange}
          />
          <span className="checkmark"/>
        </label>
        {
          text && (
            <div style={{ paddingLeft: '30px' }} >
              <span
                style={{
                  display: 'block',
                  fontWeight: '700',
                }}
              >{text}</span>
            </div>
          )
        }
      </div>
    );
  }
}

export default EnCheckBox;
