import React from 'react';
import '../../assets/css/stepprogress.css';
import { isArray } from 'lodash';

export class EnTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.props.index || 0,
      loadedIndex: new Set([this.index])
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.index !== this.props.index) {
      this.setState({ index: this.props.index });
    }
  }

  handleClick = (index) => {
    this.setState({ index });
    this.props.onChangeTab && this.props.onChangeTab('activeIndex', index);
    this.state.loadedIndex.add(index);
    if(this.props.onClick) {
      this.props.onClick(index);
    }
  }

  renderContent = (children) => {
    if (this.props.lazyLoad) {
      return children.map((component, index) => {
        if (this.state.index === index || this.state.loadedIndex.has(index)) {
          return (
            <div key={index} style={this.state.index === index ? {} : { display: 'none' }} >
              {component}
            </div>
          );
        } else {
          return (
            <div key={index} style={this.state.index === index ? {} : { display: 'none' }} >
            </div>
          );
        }
      });
    } else {
      return children.map((component, index) => {
        return (
          <div key={index} style={this.state.index === index ? {} : { display: 'none' }} >
            {component}
          </div>
        );
      });
    }
  }

  render() {    
    const children = this.props.children && isArray(this.props.children) ? this.props.children : [this.props.children];
    return (
      <div className="row" >
        <div className="box-tab-status">
          <ul className={`nav nav-tabs tabs-status`}>
            {children.map((c, index) => {
              return c.props.title && (
                <li
                  key={index}
                  disabled
                  className={this.state.index === index ? 'active' : ''}
                >
                  <a style={{ cursor: 'pointer' }} onClick={() => this.handleClick(index)}>
                    {c.props.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="tab-content" style={{ padding: '10px 20px' }}>
          {this.renderContent(children)}
        </div>
      </div>
    );
  }
}

EnTab.defaultProps = {
  style: {}
};
