import React from 'react'
import config from '../../../config'
import { isAllow } from '../../../utils/permission'

const BroadcastHeaderTable = ({ history }) => {
  return (
    <thead>
      <tr>
        <th width="15%" className="text-center">หัวข้อ</th>
        <th width="15%" className="text-center">พื้นที่</th>
        <th width="10%" className="text-center">ประเภทงาน</th>
        <th width="10%" className="text-center">วันที่สร้าง</th>
        <th width="10%" className="text-center">วันที่ให้มีผล</th>
        <th width="10%" className="text-center">สถานะประกาศ</th>
        <th width="10%" className="text-center">สถานะเผยแพร่</th>
        <th width="5%" className="text-center">
          <button
            disabled={isAllow("CREATE_NEW_BROADCAST") ? false : true}
            className="btn btn-success"
            title="สร้างใหม่"
            data-tip="สร้างใหม่"
            onClick={() => history.push(`${config.publicUrl}/superadmin/broadcasts/add`)}
          >
            <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
          </button>
        </th>
      </tr>
    </thead>
  )
}

export default BroadcastHeaderTable
