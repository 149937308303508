import React from 'react';

import { observer, inject } from 'mobx-react';
import { FormValidation } from '../../components/form/FormValidation';
import Container from '../../layouts/Container';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import Search from '../../components/user/search/SearchUser';

export class SearchUser extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container isAdmin>
        <Loader />
        <Notification ref={(ref) => { this.noti = ref; }} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ค้นหาสมาชิก</h4>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="card-content">
                    <Search />
                  </div>
                  <br />
                  <br />
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('admin', 'auth')(observer(SearchUser));
