import React from 'react';
import { observer, inject } from 'mobx-react';
import Loader from '../../../components/common/Loader';
import Container from '../../../layouts/ContainerContractor';
import { FormValidation, required, pin, pinConfirm } from '../../../components/form/FormValidation';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnButton from '../../../components/form/EnButton';
import Notification from '../../../components/common/Notification';
import common from '../../../utils/common';
import config from '../../../config';

export class ChangePin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      pin: '',
      newPin: '',
      newPinConfirm: '',
      maxPinLength: 6,
    };
  }

  validation() {
    return this.state.pin &&
      this.state.newPin &&
      this.state.newPinConfirm &&
      this.state.pin.length === this.state.maxPinLength &&
      this.state.newPin.length === this.state.maxPinLength &&
      this.state.newPin === this.state.newPinConfirm;
  }

  onSubmit() {
    const contractorId = this.state.id;
    const calendarFilter = this.props.calendar.toJS().calendarFilter;
    let qs = {};
    if (calendarFilter) {
      qs = calendarFilter;
    }
    this.noti.success('แก้ไขข้อมูลเรียบร้อย', 'สำเร็จ');
    setTimeout(() => {
      this.props.history.push(`${config.publicUrl}/contractors/${contractorId}/calendar?${common.urlBuilder(qs)}`);
    }, 1000);
  }

  async onClickSubmit() {
    try {
      await this.props.contractor.changePin(this.state.id, this.state.pin, this.state.newPin);
      this.onSubmit();
    } catch (err) {
      this.noti.error(err.message);
    }
  }
  componentWillMount() {
    this.props.contractor.getContractor({
      id: this.state.id,
    });
  }

  render() {
    let contractor = this.props.contractor.toJS();

    return (
      <Container>
        <div className="row">
          <Notification ref={(ref) => { this.noti = ref; }} />
          <Loader show={contractor.isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">แก้ไขข้อมูล</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="col-md-12" />
                  <EnTextFormGroup
                    containerClassName="col-md-12"
                    type="password"
                    name="originPin"
                    size={this.state.maxPinLength}
                    maxLength={this.state.maxPinLength}
                    label="รหัสผ่านเดิม"
                    value={this.state.pin}
                    onChange={(e) => this.setState({ pin: e.target.value })}
                    validations={[required, pin]} />

                  <EnTextFormGroup
                    containerClassName="col-md-12"
                    type="password"
                    name="pin"
                    size={this.state.maxPinLength}
                    maxLength={this.state.maxPinLength}
                    label="รหัสผ่านใหม่ (ตัวเลข 6 หลัก)"
                    value={this.state.newPin}
                    onChange={(e) => this.setState({ newPin: e.target.value })}
                    validations={[required, pin]} />

                  <EnTextFormGroup
                    containerClassName="col-md-12"
                    type="password"
                    name="confirm"
                    size={this.state.maxPinLength}
                    maxLength={this.state.maxPinLength}
                    label="ยืนยันรหัสผ่านใหม่"
                    value={this.state.newPinConfirm}
                    onChange={(e) => this.setState({ newPinConfirm: e.target.value })}
                    validations={[required, pin, pinConfirm]} />

                  <EnButton className="btn-info pull-right" disabled={!this.validation()} onClick={this.onClickSubmit.bind(this)}>
                    <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                  </EnButton>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('contractor', 'calendar')(observer(ChangePin));
