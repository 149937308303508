import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import Notification from '../../components/common/Notification';
import config from '../../config';
import Pagination from '../../components/common/Pagination';
import BroadcastSearchForm from './components/BroadcastSearchForm';
import BroadcastRowTable from './components/BroadcastRowTable';
import http from '../../utils/http';
import BroadcastHeaderTable from './components/BroadcastHeaderTable';
import swal from 'sweetalert2';

class BroadcastListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      broadcasts: {
        result: [],
      },
      query: {},
      provinces: [],
      typeofworks: [],
      pagination: {
        page: 1,
        page_range: 5,
        page_size: 10,
      }
    }
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    await this.fetchData()
    this.setState({ isLoading: false })
  }

  async fetchData() {
    try {
      const { page, page_range, page_size } = this.state.pagination
      const { data } = await http.get(`${config.api.sims}/v1/broadcast/?pageNumber=${page}&pageSize${page_range}pageRange${page_size}`)
      this.setState({ broadcasts: { result: data.result }, pagination: data.pagination })
      await this.props.misc.getAllProvince();
      await this.props.typeofwork.getTypeOfWorks()
      const miscStore = this.props.misc.toJS();
      const provinces = miscStore.miscData.province.map(province => ({ label: province.name, value: province.code }));
      const filterTypeofworks = this.props.typeofwork.items.filter(typeofwork => typeofwork.code && typeofwork.name)
      const sortTypeofworks = filterTypeofworks.sort((a, b) => a.code < b.code ? -1 : (a.code > b.code ? 1 : 0))
      const typeofworks = sortTypeofworks.map(typeofwork => ({ label: `${typeofwork.code}: ${typeofwork.name}`, value: typeofwork.value }))
      this.setState({ provinces, typeofworks })
    } catch (error) {
      console.log(error.message)
    }
  }

  async handleDelete(id) {
    this.setState({ isLoading: true })
    swal.fire({
      title: 'คุณแน่ใจใช่ไหมที่จะลบประกาศนี้',
      icon: 'warning',
      showCancelButton: true,
      showConfirmButton: true
    })
      .then(async (result) => {
        if (result.value) {
          await http.delete(`${config.api.sims}/v1/broadcast/${id}`)
          await this.fetchData()
          swal.fire({ title: `ลบข้อมูลเรียบร้อย`, icon: 'success', });
        }
      })
    this.setState({ isLoading: false })
  }

  async handleChangeQuery(key, event) {
    const newQuery = { ...this.state.query }
    if (key === 'announcement_date' && event) {
      return this.setState({ query: { ...newQuery, ['announcement_date']: event.toDate() } })
    }

    if (key === 'create_at' && event) {
      return this.setState({ query: { ...newQuery, ['create_at']: event.toDate() } })
    }

    if (event && event.target) {
      return this.setState({ query: { ...newQuery, [key]: event.target.value } })
    }
  }

  async handleClickSearch(pageNum) {
    this.setState({ isLoading: true })
    const cloneQery = this.state.query
    const clonePagination = this.state.pagination
    const _body = { ...cloneQery, ...clonePagination, pageNumber: pageNum }
    const { data } = await http.post(`${config.api.sims}/v1/broadcast/filter`, _body)
    this.setState({ broadcasts: { result: data.result }, pagination: data.pagination })
    this.setState({ isLoading: false })
  }

  handleClearSearch() {
    this.setState({
      query: {
        release_status: "all",
        is_published: "all",
        create_at: null,
        announcement_date: null,
        province: "all"
      }
    })
    this.fetchData()
  }

  render() {
    const { broadcasts, isLoading, provinces, typeofworks } = this.state
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รวมประกาศ</h4>
              </div>
              <div className="card-content">
                <BroadcastSearchForm
                  query={this.state.query}
                  handleChangeQuery={(key, values) => this.handleChangeQuery(key, values)}
                  handleClickSearch={this.handleClickSearch.bind(this)}
                  handleClearSearch={this.handleClearSearch.bind(this)}
                  provinces={this.state.provinces}
                />
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <BroadcastHeaderTable history={this.props.history} />
                        <tbody>
                          {broadcasts.result.map(broadcast => <BroadcastRowTable
                            key={broadcast._id}
                            broadcast={broadcast}
                            provinces={provinces}
                            typeofworks={typeofworks}
                            history={this.props.history}
                            deleteBroadcast={(id) => this.handleDelete(id)}
                          />)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Pagination
                      pagination={this.state.pagination}
                      handlePageClick={this.handleClickSearch.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container >);
  }
}

export default inject('misc', 'typeofwork', 'broadcasts')(observer(BroadcastListPage))