import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import momentTz from 'moment-timezone';
import swal from 'sweetalert2';
import Container from '../../layouts/Container';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import { FormValidation, required } from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import EnButton from '../../components/form/EnButton';

export class JobNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobId: this.props.match.params.jobId,
    };
  }

  async componentWillMount() {
    try {
      await this.props.jobnote.loadJobById(this.state.jobId);
    } catch (err) {
      console.log(err.message);
    }
  }

  onNoteChange(key, e) {
    if (e.target) {
      this.props.jobnote.setNoteValue(key, e.target.value);
    }
  }

  async onSubmitClick() {
    let confirmDialogOptions = {
      title: `เพิ่มประวัติการบันทึก`,
      text: 'กรุณายืนยันการบันทึกข้อมูล',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.props.jobnote.createNote(this.state.jobId);
          return 'success';
        } catch (error) {
          swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          return false;
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    console.log('confirm result ->', result);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  onBackClick(e) {
    this.props.history.goBack();
  }

  displayHistoryList(job) {
    if (job && Array.isArray(job.notes) && job.notes.length) {
      return job.notes.map(n => {
        return (
          <div className="panel panel-default">
            <div className="panel-heading">
              <i className="fa fa-user" aria-hidden="true" />&nbsp;&nbsp;{n.created_by}&nbsp;&nbsp;
              <i className="fa fa-calendar" aria-hidden="true" />&nbsp;&nbsp;{momentTz(n.created_date).utcOffset(7).format('dddd ที่ DD/MM/YYYY')}&nbsp;&nbsp;
              <i className="fa fa-clock-o" aria-hidden="true" />&nbsp;&nbsp;{momentTz(n.created_date).utcOffset(7).format('HH:mm')}
            </div>
            <div className="panel-body">
              {n.text}
            </div>
          </div>
        );
      });
    }
    return '';
  }

  render() {
    const { job, note, isLoading } = this.props.jobnote.toJS();
    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <Notification ref={(ref) => { this.noti = ref; }} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ประวัติการบันทึก</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    {this.displayHistoryList(job)}
                  </div>
                </div>
                <FormValidation ref={(el) => { this.frm = el; }}>
                  <div className="row">
                    <EnTextFormGroup
                      id="text"
                      containerClassName="col-md-12"
                      type="text"
                      label="ข้อความ"
                      value={note.text}
                      onChange={this.onNoteChange.bind(this, 'text')}
                      validations={[required]}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <EnButton className="btn-info pull-right" onClick={this.onSubmitClick.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-danger pull-right" onClick={this.onBackClick.bind(this)}>
                        <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                      </EnButton>
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('jobnote')(observer(JobNote));
