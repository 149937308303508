
import React from 'react';
import _ from 'lodash'
import QueryString from 'query-string';
import { observer, inject } from 'mobx-react';
import Swal from 'sweetalert2';
import MobileDetect from 'mobile-detect';
import '../../assets/css/stepprogress.css';
import '../../assets/css/homeowner-acceptance.css';
import NpsDetailPage from './NpsDetailPage';


export class NpsPage extends React.Component {
  constructor(props) {
    super(props);
    const md = new MobileDetect(window.navigator.userAgent);
    let score = false;
    const qs = QueryString.parse(this.props.location.search);
    if (qs && qs.score) {
      score = qs.score;
    }

    this.state = {
      isMobileOrTablet: !!(md.mobile() || md.tablet()),
      jobId: this.props.match.params.jobId,
      scoreEmail: score,
    };
  }

  async componentWillMount() {
    try {
      if (this.state.scoreEmail) {
        await this.props.nps.updateScore(this.state.jobId, this.state.scoreEmail);
      }
      await this.props.nps.getJob(this.state.jobId);
    } catch (err) {
    }
  }

  render() {
    const { jobInfo, nps } = this.props.nps.toJS();
    const { customerFirstname, customerLastname } = jobInfo;
    const customerFullName = `${customerFirstname} ${customerLastname}`;

    return (
      <div className="container">
        <div className="card">
          <div className="nps-page">
            <img src="https://storage.googleapis.com/prod-qchang-v1/static/images/logo-new-full.png" alt="" style={{ width: '250px' }} />
          </div>
          <NpsDetailPage jobId={this.state.jobId} npsData={nps} customerFullName={customerFullName} hideScore={this.state.scoreEmail} />
        </div>
      </div>
    );
  }
}

export default inject('nps')(observer(NpsPage));
