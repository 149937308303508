import React from "react";
import { observer, inject } from "mobx-react";
import Notification from "../../common/Notification";
import Suggestion from "./SearchResult";
import validator from 'validator';
import { checkEmoji } from '../../../components/form/FormValidation';

export class SearchUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      results: [],
      showMessage: false,
    };
  }

  handleInput = (e) => {
    if(checkEmoji(e.target.value)) {
      return;
    }

    this.setState({
      query: e.target.value,
    });
  };

  onPressSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.handleSearchEvent();
    }
  };

  handleSearchEvent = () => {
    if (this.state.query && this.state.query.length >= 1) {
      this.getInfo();
    } else {
      this.setState(
        {
          results: [],
          showMessage: true,
        },
        () => {
          this.callbackToParent();
        }
      );
    }
  };

  callbackToParent() {
    if (this.state.showMessage) {
      this.noti.error("ไม่พบรายการที่ค้นหา");
    }

    if (this.props.onToggle) {
      this.props.onToggle(!this.state.showMessage);
    }
  }

  getInfo = async () => {
    let options = {}
    if(!isNaN(this.state.query) && this.state.query.length === 10) {
      options.userPhoneNo =  this.state.query;
    } else if (validator.isEmail(this.state.query)) {
      options.userEmail =  this.state.query;
    } else if (this.state.query.split(" ").length > 1) {
      const name = this.state.query.split(" ");
      options.userFirstName = name[0];
      options.userLastName = name[1];
    } else {
      options.userFirstName = this.state.query;
    }

    this.props.customer
      .getUserStatus(options)
      .then((res) => {
        if (res.length === 0) {
          this.setState({ showMessage: true });
          return [];
        } else {
          this.setState({ showMessage: false });
        }
        return res;
      })
      .then((res) => {
        this.setState({ results: res }, () => {
          this.callbackToParent();
        });
      });
  };

  OnCancel = () => {
    this.setState(
      {
        results: [],
      },
      () => {
        if (this.props.onToggle) {
          this.props.onToggle(false);
        }
      }
    );
  };

  render() {
    return (
      <div>
        <Notification
          ref={(ref) => {
            this.noti = ref;
          }}
        />
        <div className="input-group">
          <input
            id="search-input"
            type="text"
            className="form-control"
            onKeyPress={this.onPressSearch}
            onChange={this.handleInput}
            ref={(input) => {
              this.search = input;
            }}
            placeholder="ค้นหาด้วย ชื่อ เบอร์โทร หรือ อีเมล"
            value={this.state.query}
          />
          <div className="input-group-btn">
            <button
              id="search-button"
              type="button"
              className="btn btn-primary btn-outline-secondary"
              style={{ margin: 0, padding: "6px 20px", fontSize: "20px" }}
              onClick={(e) => this.handleSearchEvent()}
            >
              <i className="fa fa-search" aria-hidden="true" />
            </button>
            {this.state.results.length && (
              <button
                id="cancel-button"
                type="button"
                className="btn btn-danger"
                style={{ margin: 0, padding: "6px 20px", fontSize: "20px" }}
                onClick={(e) => this.OnCancel()}
              >
                <i className="fa fa-undo" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
        <Suggestion results={this.state.results} />
      </div>
    );
  }
}

export default inject("auth", "typeofwork", "customer")(observer(SearchUser));
