import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';

export class ReviewStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      info: this.initInfo(),
      jobInfo: this.initJobInfo(),
      nps: this.initNps(),
    });
  }

  initInfo() {
    return {
      jobId: '',
      rating: 5,
      message: '',
      images: [],
    };
  }

  initJobInfo() {
    return {
      jobId: '',
      orderId: '',
      status: '',
      guaranteeFile: '',
      channel: '',
      customerFirstname: '',
      customerLastname:'',
    };
  }

  initNps() {
    return {
      score : '',
      comment : '',
      channel : 'web',
      send_date : undefined,
      submited_date : undefined,
    };
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  async addReview() {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const info = this.toJS().info;
      const jobId = info.jobId;
      await http.post(`${config.api.sims}/v1/reviews/${jobId}`, info);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  getLatestGuaranteeFile(files) {
    if (!Array.isArray(files) || !files.length) {
      return undefined;
    }
    if (files.length > 1) {
      files.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }
    const { url } = files[0];
    return url;
  }

  async getJobForAcceptanceResult(jobId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.get(`${config.api.sims}/v1/homeowners/job/${jobId}/acceptance-result`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      const { data } = response.data;
      this.jobInfo = {
        jobId: jobId,
        orderId: data.order_id,
        status: data.status,
        guaranteeFile: this.getLatestGuaranteeFile(data.guarantee),
        channel: data.channel,
        customerFirstname: data.customer_firstname,
        customerLastname: data.customer_lastname,
      };
      this.nps = {
        score : data.nps.score,
        comment : data.nps.comment,
        channel : data.nps.channel,
        send_date : data.nps.send_date,
        submited_date : data.nps.submited_date,
      };
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new ReviewStore();
