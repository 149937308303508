import qs from 'query-string';
import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';
import { finished } from 'stream';

export class OrderSummarySCBStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      query: {},
      data: [],
      downloadFileUrl: [],
    });
  }

  setDefaultQuery() {
    this.query = {
      job_code: '',
      customer_name: '',
      contractor_id: '',
      type_of_job: '',
      type_of_work: '',
      start_date: undefined,
      end_date: undefined,
      sale_model: '',
    };
  }

  setQueryValue(key, value) {
    this.query[key] = value;
  }

  async search() {
    if (this.isLoading) return;
    this.isLoading = true;

    const { query } = this.toJS();
    try {
      const res = await http.get(`${config.api.sims}/v1/jobs/ordersummarySCB/search?${qs.stringify(query)}`);
      if (res && res.data && res.data.data) {
        const resData = res.data.data.map(d=>{
          return {
            is_active:d.is_active,
            type_of_job:d.type_of_job,
            transfers:d.transfers.result,
            admin_approve_paid:d.admin_approve_paid,
            job_code:d.job_code,
            _id:d._id,
            sale_model:d.sale_model,
            customer_firstname:d.customer_firstname,
            customer_lastname:d.customer_lastname,
            contractor_team_name:d.contractor_team_name,
            type_of_work:d.type_of_work,
            customer_approved_date:d.customer_approved_date,
            contractor_detail:{
              is_juristic:d.contractor_detail && d.contractor_detail.is_juristic || false,
              signature:d.contractor_detail && d.contractor_detail.signature || ''
            },
            contractor_bank_account_no:d.contractor_bank_account_no,
            po_survey:d.po_survey || [],
            po_installation:d.po_installation || []
          }
        });
        this.data = resData
        return resData
      }
      return [];
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async download(isUpdate, paidDate = undefined, selectedIds,paidDateOtherBank = undefined) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.post(`${config.api.sims}/v1/transfer/generateTransferSCB`, { jobIds: selectedIds, isUpdate, paidDate,paidDateOtherBank });
      if (res.status === 200 && res.data && res.data.length) {
        this.downloadFileUrl = [];
        res.data.forEach(data => {
          if (data) {
            const { url: linkUrl } = data.result;
            this.downloadFileUrl.push(linkUrl);
          }
        });
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async updateFlagAdminApprovePaid(id, flag) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.put(`${config.api.sims}/v1/jobs/adminapprovepaid/${id}/${flag}`);
      if (res.status === 200 && res.data && res.data.data) {
        return res.data.data;
      }
      return [];
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new OrderSummarySCBStore();
