import config from '../config';
import http from '../utils/http';
import BaseStore from './BaseStore';

class AdditionalProductsStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      errorCount: undefined,
      errorList: undefined,
      errorMessage: undefined,
      list: undefined,
      pagination: undefined,
      toExportErrorList: undefined,
      toExportRedColorList: undefined,
    });
  }

  setErrorMessage = (errorMessage) => {
    this.errorMessage = errorMessage;
  };

  setErrorList = ({ errorCount, errorList, toExportErrorList, toExportRedColorList }) => {
    this.errorCount = errorCount;
    this.errorList = errorList;
    this.toExportErrorList = toExportErrorList;
    this.toExportRedColorList = toExportRedColorList;
  };

  clearErrorList = () => {
    this.errorCount = undefined;
    this.errorList = undefined;
    this.toExportErrorList = undefined;
    this.toExportRedColorList = undefined;
  };

  getAdditionalProducts = async (searchText, pageNumber = 1) => {
    let url = `${config.api.sims}/v1/additionalProducts?page=${pageNumber}`;
    if (searchText) {
      url += `&q=${searchText}`;
    }
    const { data: dataWithPagination } = await http.get(url);
    this.list = dataWithPagination.data;
    this.pagination = dataWithPagination.pagination;
  };

  uploadAdditionalProducts = async (file, userEmail) => {
    const formData = new FormData();
    formData.append('additional_products', file);
    formData.append('user_email', userEmail);
    const { status } = await http.postMultipartFormData(`${config.api.sims}/v1/additionalProducts`, formData);
    return { success: status === 200 };
  };
}

const additionalProductsStore = new AdditionalProductsStore();
export default additionalProductsStore;
