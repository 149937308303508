import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Dropzone from 'dropzone';
import PropTypes from 'prop-types';

export class IconComponent extends React.Component {
  render() {
    return <div data-filetype={this.props.filetype} className="filepicker-file-icon" />;
  }
}

export class EnDropZone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      response: [],
      error: false,
      callback: undefined,
    };
  }

  setAuthorization() {
    let headers = {};
    if (this.props.token) {
      headers.Authorization = `Bearer ${this.props.token}`;
    }

    return headers;
  }

  getFiles() {
    return this.state.files;
  }

 getDropZoneOptions(){
  let maxFileSize = this.props.maxFileSize || 2;
  let maxFiles = this.props.maxFiles || 20;
  const options = {
    url: this.props.url,
    maxFilesize: maxFileSize,
    maxFiles: maxFiles,
    parallelUploads: 1,
    autoProcessQueue: true,
    addRemoveLinks: true,
    headers: this.setAuthorization(),
    acceptedFiles: this.props.acceptedFiles,
    accept: this.props.accept || function (_file, done) { done(); },
    dictDefaultMessage: this.props.placeholder || 'กรุณาเลือกไฟล์',
  };
  return options;
 }

  componentDidMount() {
    const options =  this.getDropZoneOptions();
    Dropzone.autoDiscover = false;
    var dropzoneNode = ReactDOM.findDOMNode(this);
    this.dropzone = new Dropzone(dropzoneNode, options);
    this.setupEvents();
  }

  updateFilesCanUpload(){
    const { maxFiles }=  this.getDropZoneOptions();
    this.dropzone.options.maxFiles = maxFiles;
  }

  componentDidUpdate(){
    this.updateFilesCanUpload();
    this.disabledDropZone();
  }

  upload(callback) {
    this.setState({
      response: [],
      callback,
    }, () => {
      this.dropzone.processQueue();
    });
  }

  disabledDropZone(){
    const disabled = this.props.disabled || false;
    if(disabled) {
      this.dropzone.removeAllFiles();
      this.dropzone.disable();
    };
  }

  render() {
    const icons = [];
    const { files } = this.state;
    const { config, id } = this.props;
    const className = (this.props.className) ? 'filepicker dropzone ' + this.props.className : 'filepicker dropzone';
    let options = {};
    if (id) {
      options['id'] = id;
    }

    if (config && config.showFiletypeIcon && config.iconFiletypes && (!files || files.length < 1)) {
      for (var i = 0; i < config.iconFiletypes.length; i = i + 1) {
        icons.push(<IconComponent filetype={config.iconFiletypes[i]} key={'icon-component' + i} />);
      }
    }

    return <div id={'uploadFile'} {...options} className={className}> {icons} {this.props.children} </div>;
  }

  readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  getImageDimension(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = reject;
      img.src = src;
    });
  }

  setupEvents() {
    let self = this;
    this.dropzone.on('addedfile', async (file) => {
      if (!file) return;

      const mimeType = file.type;
      if (file && mimeType.substring(0, mimeType.indexOf('/')) === 'image') {
        const src = await this.readFileAsync(file);
        const img = await this.getImageDimension(src);
        // this.dropzone.options.resizeWidth = img.width / 2;
        this.dropzone.options.resizeMimeType = 'image/jpeg';
        // this.dropzone.options.resizeQuality = 0.70;
      } else {
        await this.readFileAsync(file);
      }

      self.setState({ error: false });
      const files = self.state.files || [];

      files.push(file);
      self.setState({ files });

      if (self.props.onAddedFile) {
        self.props.onAddedFile();
      }
    });

    this.dropzone.on('removedfile', (file) => {
      if (!file) return;

      const files = self.state.files || [];
      files.forEach((fileInFiles, i) => {
        if (fileInFiles.name === file.name && fileInFiles.size === file.size) {
          files.splice(i, 1);
        }
      });

      self.setState({ files });

      if (self.props.onRemovedFile) {
        self.props.onRemovedFile(file);
      }
    });

    this.dropzone.on('error', (file, msg) => {
      if (!file) return;

      self.setState({ error: true });

      if (self.props.onError && file.status === 'error') {
        self.props.onError(msg);
      }
    });

    this.dropzone.on('complete', (file) => {
      if (self.props.onComplete && file.status !== 'error') {
        self.props.onComplete(file);
      }
    });

    this.dropzone.on('success', (file, res) => {
      let result = self.state.response || [];
      if (!Array.isArray(result)) {
        result = [];
      };

      result.push({
        file,
        res,
      });

      self.setState({
        response: result,
      }, () => {
        if (self.props.onComplete) {
          self.props.onComplete(file, result);
        }
      });
    });

    this.dropzone.on('queuecomplete', () => {
      if (self.props.onAllComplete && !self.state.error) {
        self.props.onAllComplete(self.state.response);
        this.dropzone.removeAllFiles();
      }

      if (this.state.callback) {
        this.state.callback();
      }

      // clear response file after complete
      self.state.response = []
    });
  }
}

EnDropZone.propTypes = {
  url: PropTypes.string.isRequired,
  acceptedFiles: PropTypes.string,
  accept: PropTypes.func,
  maxFileSize: PropTypes.number,
  maxFiles: PropTypes.number,
  onError: PropTypes.func,
  onAddedFile: PropTypes.func,
  onRemovedFile: PropTypes.func,
  disabled:PropTypes.bool
};

export default EnDropZone;
