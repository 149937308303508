import { isNil } from 'lodash';
import React from 'react';
import EnButton from './EnButton';
import FormGroup from './FormGroup';

export class EnTextRadio extends React.Component {
  onButtonClick(value) {
    if (this.props.onClick) {
      this.props.onClick(value)
    }
  }

  render() {
    let {
      label,
      value,
      options,
      notRequired,
      validations,
      containerClassName,
    } = this.props;
    let fromGroupOption = {
      label,
      notRequired,
      validations: !isNil(validations) && Array.isArray(validations),
    };

    const optionList = options.map((option, index) => {
      const { defaultClassName, selectedClassName } = option;
      const _defaultClassName = defaultClassName || '';
      const _selectedClassName = selectedClassName || 'btn-info';
      return (
        <EnButton
          key={`option-${index}`}
          className={`${option.value === value ? _selectedClassName : _defaultClassName}`}
          disabled={option.disabled || false}
          onClick={this.onButtonClick.bind(this, option.value)}>
          {option.icon || ''}
          {option.icon ? ` ${option.label}` : option.label}
        </EnButton>
      );
    });
    return (
      <FormGroup containerClassName={containerClassName} {...fromGroupOption} >
        <div>{optionList}</div>
      </FormGroup>
    );
  }
}

export default EnTextRadio;
