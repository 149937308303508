import React from 'react'
import { observer, inject } from 'mobx-react';

import Container from '../../../layouts/Container';
import SearchForm from './components/SearchForm';
import config from '../../../config';
import { isAllow } from '../../../utils/permission';
import { Modal, Button } from 'react-bootstrap';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup'
import Swal from 'sweetalert2'
import Loader from '../../../components/common/Loader'
import _ from 'lodash'

export class ManageMainCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modal: {
        courseName: '',
        courseDesc: ''
      },
      modalMode: 'create'

    };
  }
  async componentWillMount() {
    await this.props.trainingcourse.getAllTrainingCourse();
  }

  onView(trainingDtUuid) {
    this.props.history.push(`${config.publicUrl}/superadmin/training/${trainingDtUuid}/register`);
  }
  gotTo(trainingDtUuid) {
    this.props.history.push(`${config.publicUrl}/superadmin/mainCrouse/`);
  }

  setModal(value = false) {
    // $('#myModal').modal('show')
    this.setState({
      showModal: value,
      modalMode: 'create',
      modal: {
        courseName: '',
        courseDesc: ''
      },
    });
  }
  createSubByMain(mainCourseId) {
    this.props.history.push(`${config.publicUrl}/superadmin/createSubCourse/${mainCourseId}`);
  }
  modalEdit(data) {

    let newModal = {
      ...data,
      courseName: data.trainingName,
      courseDesc: data.trainingDesc
    }
    this.setState({
      modal: newModal,
      modalMode: 'edit',
      showModal: true
    })
  }
  async onConFirmDeleteCourse(trainingUuid) {
    let a = trainingUuid

    try {
      await this.props.trainingcourse.deleteCourse(trainingUuid);
      await this.props.trainingcourse.getAllTrainingCourse();
    } catch (error) {
      console.error('error====onConFirmDeleteCourse');
      console.log(error.response);
      console.error('error====onConFirmDeleteCourse');

      // if (error.error_code == 'CT012') {
      //   Swal.fire({
      //     title: `${error.error_message_title}123`,
      //     text: `${error.error_message}`,
      //     icon: 'warning',
      //     showCancelButton: true,
      //     confirmButtonColor: '#3085d6',
      //     cancelButtonColor: '#d33',
      //     confirmButtonText: 'ยืนยันการลบ'
      //   }).then((result) => {
      //     if (result.value) {

      //       // this.onConFirmDeleteCourse(trainingUuid)
      //     }
      //   })
      // }
    }
  }

  onDeleteCourse(data) {

    Swal.fire({
      title: "โปรดยืนยันการลบ",
      text: `ลบคอร์ส : ${data.trainingName}`,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยันการลบ',
      cancelButtonText: 'ยกเลิก'
    }).then((result) => {

      if (result.value) {
        console.log('======result.isConfirmed====');

        this.onConFirmDeleteCourse(data.trainingUuid)
      }
    });
  }

  getTableRows(course) {
    return course.map((item, index) => {
      return (
        <tr key={index}>
          <td width="5%" className="text-center">{index + 1}</td>
          <td width="20%">{item.trainingName || ''}</td>
          <td width="20%">{item.trainingDesc || ''}</td>
          <td width="10%" className="text-center">
            <button
              className="btn btn-xs"
              title="สร้างคอร์สย่อยใหม่"
              data-tip="สร้างคอร์สย่อยใหม่"
              onClick={e => { this.createSubByMain(item.trainingUuid) }}
              disabled={!isAllow('CREATE_A_SUB_COURSE')}
              >
              <span className="fa fa-plus btn-icon" aria-hidden="true" /> <p style={{fontSize:'18px', display: 'inline'}}>คอร์สย่อย</p>
            </button>
              <button
                className="btn btn-xs"
                title="แก้ไข"
                data-tip="แก้ไข"
                onClick={e => { this.modalEdit(item) }}
                disabled={!isAllow('EDIT_A_MAIN_COURSE_INFORMATION')}
                >
                <span className="fa fa-edit " aria-hidden="true" />
              </button>
              <button 
                className="btn btn-danger btn-xs"
                title="ลบ"
                data-tip="ลบ"
                onClick={e => { this.onDeleteCourse(item) }}
                disabled={!isAllow('DELETE_A_MAIN_COURSE')}
                >
                <span className="fa fa-trash " aria-hidden="true" />
              </button>
          </td>
        </tr>
      );
    });
  }

  async onSearchClick(e) {
    await this.props.trainingcourse.searchTrainingCourse();
  }

  async onClearSearchClick(e) {
    this.props.trainingcourse.setDefaultQuery();
    await this.props.trainingcourse.getAllTrainingCourse();
  }

  async onSaveMOdal() {
    try {
      if (this.state.modalMode === 'create') {
        await this.props.trainingcourse.createMainCourse(this.state.modal.courseName, this.state.modal.courseDesc);
      }
      if (this.state.modalMode === 'edit') {

        await this.props.trainingcourse.EditMainCourse(this.state.modal);

      }
      await this.props.trainingcourse.getAllTrainingCourse();
      this.setModal(false)
    } catch (error) {

    }
  }

  onChangeModalData(key, value) {
    let newModal = this.state.modal
    newModal[key] = value
    this.setState({
      modal: newModal
    })

  }
  goback() {
    this.props.history.goBack()
  }
  checkValidStateCreateCourse() {
    let valid = true

    const keys = ['courseName', 'courseDesc']
    _.forEach(keys, key => {
      const valueState = this.state.modal[key]
      if (!valueState) {
        valid = false
      }
    })
    return valid
  }

  render() {
    const { course, query, isLoading } = this.props.trainingcourse.toJS();
    const rows = this.getTableRows(course);
    const { modal, modalMode } = this.state
    const valid = this.checkValidStateCreateCourse()

    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <Modal show={this.state.showModal}
          onHide={e => { this.setModal(false) }}
        >
          <Modal.Header closeButton={false} style={{ backgroundColor: '#f3873a',paddingTop:12,paddingBottom:12 }}>
            <Modal.Title  style={{color:'white'}}>{
              modalMode === 'edit' ? 'แก้ไขคอร์ส' : "สร้างคอร์ส"
            }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <EnTextFormGroup
                id="courseName"
                containerClassName="col-md-6"
                type="text"
                label={'ชื่อคอร์ส *'}
                value={modal.courseName}
                onChange={e => {
                  this.onChangeModalData('courseName', e.target.value)
                }}
              // validations={[required]}
              />
              <EnTextFormGroup
                id="courseDetail"
                containerClassName="col-md-6"
                type="text"
                label={'รายละเอียด *'}
                value={modal.courseDesc}
                onChange={e => {
                  this.onChangeModalData('courseDesc', e.target.value)
                }}
              // onChange={this.onInfoChanged.bind(this, 'name')}
              // validations={[required]}
              />

            </div>
          </Modal.Body>
          <Modal.Footer>
          <button className="btn btn-danger" title="ยกเลิก" data-tip="ยกเลิก" onClick={e => { this.setModal(false) }} >
              <span className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
            </button>
            <button className="btn btn-info" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={e => { this.onSaveMOdal() }}  disabled={!valid}>
              <span className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
            </button>
            
          </Modal.Footer>
        </Modal>
        <div className="row">

          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">คอร์สอบรมหลัก</h4>
              </div>
              <div className="card-content">
                <SearchForm
                  query={query}
                  onSearch={() => { this.onSearchClick(); }}
                  onClear={() => { this.onClearSearchClick(); }}
                />
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="5%" className="text-center">ลำดับที่</th>
                            <th width="15%" className="text-center">ชื่อคอร์ส</th>
                            <th width="15%" className="text-center">วัตถุประสงค์</th>
                            <th width="5%" className="text-center" >
                              <button
                                className="btn btn-success"
                                title="สร้างใหม่"
                                data-tip="สร้างใหม่"
                                onClick={e => { this.setModal(true) }}
                                disabled={!isAllow('CREATE_A_MAIN_COURSE')}
                                >
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างคอร์สหลัก
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
              <div className="card-footer text-right">
                <button className="btn btn-danger" title="สร้างใหม่" data-tip="สร้างใหม่"
                  onClick={e => { this.goback() }}
                >
                  <span className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                </button>

              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}


export default inject('trainingcourse')(observer(ManageMainCourse));
