import React from 'react';
import '../../assets/css/stepprogress.css';

class EnTabContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: undefined,
    };
  }

  getActiveItem(data) {
    const item = data.findIndex((item) => {
      return item.active === true;
    });
    return item;
  }

  componentDidMount() {
    const activeItem = this.getActiveItem(this.props.options);
    this.setState({ selectedItem: this.props.options[activeItem] });
  }

  onClickTab(index) {
    this.setState({ selectedItem: this.props.options[index] });
  }

  getTabItem(title, isActive, index) {
    return (
      <li ref={index} key={index} className={isActive ? 'active' : ''}><a data-toggle="tab" onClick={() => { this.onClickTab(index); }}>{title}</a></li>
    );
  }

  render() {
    const { selectedItem } = this.state;
    const { options, className, displayNone } = this.props;
    const tabItems = options.map((item, index) => {
      return (
        this.getTabItem(item.title, item.active, index)
      );
    });

    return (
      <div className="row" >
        <div className="row" >
          <div className="box-tab-status" style={{ display: (displayNone) ? 'none' : 'block' }}>
            <ul className={`nav nav-tabs tabs-status ${className}`}>
              {tabItems}
            </ul>
          </div>
        </div>
        <div className="tab-content">
          {selectedItem && selectedItem.content()}
        </div>
      </div>
    );
  }
}

export default EnTabContent;
