import React from "react";
import "./search.css";

class SearchResult extends React.Component {
  constructor(props) {
    super(props);
  }

  genCard = (results) => {
    return results.map((r, index) => {
      return (
        <div key={index} className="col-sm-6 col-xs-12 job-content">
          <div className="card bg-secondary mb-3">
            <ul className="list-group list-group-flush">
              <li className="list-group-item ellipsis" title="">
                <b>สมัครสมาชิกด้วย</b> : {r.registeredBy}
              </li>
              <li className="list-group-item ellipsis" title="">
                <b>สถานะ</b> : {r.status || "ยังไม่ได้เป็นสมาชิกกับคิวช่าง"}
              </li>
              <li className="list-group-item ellipsis" title="">
                <b>Last login</b> : {r.lastLogin}
              </li>
              <li className="list-group-item ellipsis" title="">
                <b>รหัสผ่าน</b> :{" "}
                <span
                  className={r.isPasswordSet ? "text-success" : "text-danger"}
                >
                  {r.isPasswordSet ? "ตั้งรหัสผ่านแล้ว" : "ยังไม่ตั้งรหัสผ่าน"}
                </span>
              </li>
              <li className="list-group-item" title="">
                <p className="text-danger">
                  *
                  ในกรณีที่ลูกค้ายังมีข้อมูลไม่ครบแอดมินสามารถแนะนำให้ลูกค้าเข้าไปใส่ข้อมูลใน
                  tab ข้อมูลผู้ใช้ /
                  ถ้ายังไม่มีรหัสผ่านให้แจ้งลูกค้าให้ไปกับอัพเดตเบอร์โทรศัพท์ก่อน
                  *
                </p>
              </li>
            </ul>
          </div>
        </div>
      );
    });
  };

  render() {
    let { results } = this.props;
    return <div className="row row-eq-height job">{this.genCard(results)}</div>;
  }
}

export default SearchResult;
