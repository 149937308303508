import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/ContainerContractor';
import { FormValidation } from '../../../components/form/FormValidation';
import EnPicture from '../../../components/form/EnPicture';
import EnButton from '../../../components/form/EnButton';
import Loader from '../../../components/common/Loader';
import Notification from '../../../components/common/Notification';
import common from '../../../utils/common';
import config from '../../../config';

export class ProfilePicture extends React.Component {
  constructor(props) {
    super(props);

    let id = this.props.match.params.id;
    this.state = {
      file: undefined,
      id: id,
    };
  }

  savePicture(file) {
    this.setState({
      file,
    });
  }

  validation() {
    if (this.picture) {
      let obj = this.picture.getFile();
      return obj && obj.file;
    } else {
      return false;
    }
  }

  onSubmit() {
    const contractorId = this.state.id;
    const calendarFilter = this.props.calendar.toJS().calendarFilter;
    let qs = {};
    if (calendarFilter) {
      qs = calendarFilter;
    }
    this.noti.success('แก้ไขข้อมูลเรียบร้อย', 'สำเร็จ');
    setTimeout(() => {
      this.props.history.push(`${config.publicUrl}/contractors/${contractorId}/calendar?${common.urlBuilder(qs)}`);
    }, 1000);
  }

  async clickSubmit(e) {
    try {
      await this.props.contractor.uploadImage(this.state.file, { isUpdate: true });
      this.onSubmit();
    } catch (error) {
      this.noti.error(error.message, 'บันทึกข้อมูลล้มเหลว');
    }
  }

  componentWillMount() {
    try {
      this.props.contractor.getContractor({
        id: this.state.id,
      });
    } catch (error) {
      this.noti.error(error.message);
    }
  }

  render() {
    let contractor = this.props.contractor.toJS().info;
    if (this.picture && contractor.profilePic) {
      console.log('contractor.profilePic---------->', contractor.profilePic);
      this.picture.defaultImage = contractor.profilePic;
    }

    return (
      <Container>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-profile" style={{ marginTop: 80 }}>
              <Loader show={contractor.isLoading} />
              <Notification ref={(ref) => { this.noti = ref; }} />
              <FormValidation>
                <div className="card-avatar">
                  <EnPicture ref={(e) => { this.picture = e; }} onChange={this.savePicture.bind(this)} />
                </div>
                <div className="content">
                  <h4>กดที่รูปเพื่ออัพโหลดรูปภาพ</h4>
                  <p className="text-center">*โดยรูปนี้จะถูกนำไปใช้เพื่อนำไปใช้แสดงในหน้าจอของเจ้าของบ้าน เมื่อท่านรับงานผ่านระบบ</p>
                  <EnButton className="btn-info center" disabled={!this.validation()} onClick={this.clickSubmit.bind(this)}>
                    <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                  </EnButton>
                </div>
              </FormValidation>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('contractor', 'calendar')(observer(ProfilePicture));
