import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import renderHTML from 'react-render-html';
import EnButton from '../../../../components/form/EnButton';
import DescriptionModal from './../DescriptionModal';

export class DescriptionOtherSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDescriptionModal: false,
      isEditDescription: false,
    };
  }

  onAddNewDescriptionClick() {
    const description = this.props.product.initNewDescription();
    this.setState({
      selectedDescription: description,
      showDescriptionModal: true,
      isEditDescription: false,
    });
  }

  onEditDescriptionClick(index, e) {
    e.preventDefault();
    const { info } = this.props.product.toJS();
    const description = info.descriptions[index];
    this.setState({
      selectedDescription: { ...description, index },
      showDescriptionModal: true,
      isEditDescription: true,
    });
  }

  onDeleteDescriptionClick(index, e) {
    e.preventDefault();
    const { descriptions } = this.props.product.toJS().info;
    descriptions.splice(index, 1);
    this.props.product.saveInfo('descriptions', descriptions);
  }

  onCloseDescriptionClick() {
    this.setState({ showDescriptionModal: false });
  }

  displayDescImages(images) {
    if (!Array.isArray(images) || !images.length) {
      return (
        <div className="col-md-3" key={`adddesc-image-no`}>
          ยังไม่มีรูปสินค้า
        </div>
      );
    }
    return images.map((image, index) => {
      return (
        <div className="col-md-3" key={`adddesc-image-${index + 1}`}>
          <img alt="" src={image.url} style={{ border: '1px solid #ccc' }} />
        </div>
      );
    });
  }

  displayDescriptions(descriptions) {
    return (descriptions || []).map((desc, index) => {
      return (
        <div key={`desc-${index + 1}`}>
          <div className="row">
            <div className="col-md-12">
              <button className="btn btn-xs pull-right" title="ลบ" data-tip="ลบ" onClick={this.onDeleteDescriptionClick.bind(this, index)}>
                <span className="fa fa-trash" aria-hidden="true" />
              </button>
              <label>{`รายละเอียดเพิ่มเติม ${index + 1}`}</label>
              <button className="btn btn-xs pull-right" title="แก้ไข" data-tip="แก้ไข" onClick={this.onEditDescriptionClick.bind(this, index)}>
                <span className="fa fa-pencil" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">{desc.content ? renderHTML(desc.content) : ''}</div>
          </div>
          <div className="row">
            <div className="col-md-12">{this.displayDescImages(desc.images)}</div>
          </div>
          <hr />
        </div>
      );
    });
  }

  render() {
    const { descriptions } = this.props;
    return (
      <Fragment>
        {this.displayDescriptions(descriptions)}
        <div className="row">
          <EnButton className="btn-info pull-right" onClick={this.onAddNewDescriptionClick.bind(this)}>
            <i className="fa fa-plus btn-icon" aria-hidden="true" />
            เพิ่มรายละเอียด
          </EnButton>
          {this.state.showDescriptionModal && (
            <DescriptionModal show={this.state.showDescriptionModal} data={this.state.selectedDescription || this.props.product.initNewDescription()} isEdit={this.state.isEditDescription} onClose={this.onCloseDescriptionClick.bind(this)} />
          )}
        </div>
      </Fragment>
    );
  }
}

export default inject('product')(observer(DescriptionOtherSection));
