import BaseStore from "./BaseStore";
import http from "../utils/http";
import config from "../config";

export class CustomerStore extends BaseStore {
  getUserStatus = async (options) => {
    try {
      let response = (response = await http.get(
        `${config.api.qchang}/v2/customer/userStatus`,
        { params: options }
      ));

      if (response && response.data && response.data.data) {
        return response.data.data;
      } else {
        return [];
      }
    } catch (error) {
      throw new Error(error);
    }
  };
}

export default new CustomerStore();
