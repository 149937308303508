import * as _ from 'lodash';
import BaseStore from './BaseStore';
import config from '../config';
// import datetime from '../utils/datetime';
import http from '../utils/http';
import address from '../utils/address';
// import common from '../utils/common';

export const vatRate = 7;

export class PurchaseOrderStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      isSubmitting: false,
      info: this.createDefaultInfo(),
      initInfo: {},
      poes: [],
      selectedPoIndex: 0,
    });
  }

  createDefaultInfo() {
    return {
      contractor: {
        companyName: '',
        address: '',
        phone: '',
        taxNo: '',
        branchNo: '',
        isJuristic: true,
      },
      details: [this.createDefaultDetail()],
      remark: '',
      subTotal: 0.00,
      discountTotal: 0.00,
      netTotal: 0.00,
      // subTotalExcludeVat: 0.00,
      vat: 0.00,
      vatRate,
      whTax: 0.00,
      requireWh: false,
      netExcludeVat: 0.00,
      discountCoupon: 0.00,
      grandTotal: 0.00,
      beforeOtherExpense: 0.00,
      // requireWh: false,
    };
  }

  createDefaultDetail() {
    return {
      code: '',
      name: '',
      unit: '',
      qty: 1,
      price: 0.00,
      contractor_discount: 0.00,
      contractor_amount: 0.00,
      contractor_cost: 0.00,
    };
  }

  clearPoes() {
    this.poes = [];
  }

  resetSelectedPoIndex() {
    this.selectedPoIndex = 0;
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  saveAddressInfo(key, value) {
    this.info.address[key] = value;
  }

  saveDetail(index, key, value) {
    this.info.details[index][key] = value;
  }

  saveDetailAndCalculate(index, key, value) {
    const info = this.info;
    const detail = info.details[index];
    if (key === 'wh_flag') {
      detail[key] = !!detail[key] !== true;
    } else {
      detail[key] = !isNaN(value) ? value : 0; // value.replace(/[^\d\.\-eE+]/g, '');
    }
    // detail[key] = !isNaN(value) ? value : 0; // value.replace(/[^\d\.\-eE+]/g, '');
    detail.contractor_amount = (detail.contractor_cost * detail.qty) - detail.contractor_discount;
    this.calculateTotal();
  }

  saveSelectedPoIndex(index) {
    this.selectedPoIndex = index;
    const { poes } = this.toJS();
    const po = this.getSelectedPo(poes, index);
    this.initInfo = po
    this.setPoToInfo(po);
  }

  calculateTotal() {
    const info = this.info;
    let vatRate;

    if (info.contractor) {
      vatRate = info.contractor.isJuristic ? 1 : 1.07;
    }

    const result = info.details.reduce((acc, cur) => {
      const contractorAmount = cur.contractor_amount
        ? cur.contractor_amount
        : ((cur.contractor_cost || 0) * cur.qty) - (cur.contractor_discount || 0);

      return {
        subTotal: cur.contractor_cost ? acc.subTotal + (parseFloat(cur.qty || '1') * parseFloat((cur.contractor_cost || 0) / vatRate).toFixed(2)) : acc.subTotal,
        discountTotal: cur.contractor_discount ? acc.discountTotal + parseFloat(cur.contractor_discount / vatRate) : acc.discountTotal,
        whTaxTotal: cur.wh_flag ? (acc.whTaxTotal || 0) + contractorAmount : (acc.whTaxTotal || 0),
      };
    }, {
      subTotal: 0.00,
      discountTotal: 0.00,
      whTaxTotal: 0.00,
    });
    const otherExpense = info && info.jobInfo && info.jobInfo.other_expense
    // const totalExpense = ( otherExpense && otherExpense.total_expense ) || 0;
    const totalExpense = this.selectedPoIndex === 0
      ? otherExpense && otherExpense.total_expense || 0 
      : 0;

    info.subTotal = result.subTotal;
    info.discountTotal = result.discountTotal;
    info.netTotal = info.subTotal - info.discountTotal;
    info.netExcludeVat = parseFloat((info.netTotal / 1.07).toFixed(2));
    // info.requireWh = result.whTaxTotal > 0;
    info.whTax = _.toLower(info.jobInfo.sale_model)==="retail" ? (result.whTaxTotal / 1.07) * (config.scg.whTaxRate / 100) : 0 ;
    info.vat = parseFloat((info.netTotal - info.netExcludeVat).toFixed(2));
    info.grandTotal = info.netTotal - (info.discountCoupon || 0) - (info.whTax || 0) - totalExpense;
  }

  addDetail(item) {
    if (item) {
      this.info.details.push(item);
    } else {
      this.info.details.push(this.createDefaultDetail());
    }
  }

  deleteDetail(index) {
    const info = this.toJS().info;
    const details = _.cloneDeep(info.details);
    details.splice(index, 1);
    this.info.details = details;
    this.calculateTotal();
  }

  parseFloatDetails(details) {
    for (let i = 0; i < details.length; i++) {
      const detail = details[i];
      if (typeof detail.qty !== 'number') detail.qty = parseFloat(detail.qty);
      // if (typeof detail.price !== 'number') detail.price = parseFloat(detail.price);
      if (typeof detail.contractor_discount !== 'number') detail.contractor_discount = parseFloat(detail.contractor_discount);
      if (typeof detail.contractor_amount !== 'number') detail.contractor_amount = parseFloat(detail.contractor_amount);
      if (typeof detail.contractor_cost !== 'number') detail.contractor_cost = parseFloat(detail.contractor_cost || 0);
    }
  }

  clearItems = () => {
    // this.info.details = [this.createDefaultDetail()];
    this.info = this.createDefaultInfo();
  }

  getSelectedPo(poes, index) {
    if (Array.isArray(poes) && poes.length) {
      return poes[index];
    }
    return null;
  }

  getPoAsArray(po) {
    return Array.isArray(po) ? po : po ? [po] : [];
  }

  setPoToInfo(po) {
    if (po) {
      this.info.remark = po.remark;
      this.info.subTotal = po.sub_total;
      this.info.discountTotal = po.discount_total;
      this.info.netTotal = po.net_total;
      // this.info.subTotalExcludeVat = sub_total_excludeVat;
      this.info.vat = po.vat;
      this.info.vatRate = po.vat_rate;
      this.info.whTax = po.wh_tax;
      this.info.requireWh = po.require_wh;
      this.info.netExcludeVat = po.net_exclude_vat;
      this.info.grandTotal = po.grand_total;
      this.info.beforeOtherExpense = po.before_other_expense;
      this.info.details = po.items || [this.createDefaultDetail()];
    }
  }

  toPoInfo(job, contractor) {
    if (job) {
      if (job.contractor_id) {
        this.info.contractor.companyName = contractor.companyName;
        this.info.contractor.address = address.genContractorTaxAddress(contractor.address);
        this.info.contractor.phone = contractor.phone;
        this.info.contractor.taxNo = contractor.taxNo;
        this.info.contractor.branchNo = contractor.branchNo;
        this.info.contractor.isJuristic = contractor.isJuristic;
      }

      const poes = this.getPoAsArray(job.type_of_job === 'S' ? job.po_survey : job.po_installation);
      this.poes = poes;
      const { selectedPoIndex } = this.toJS();
      const po = this.getSelectedPo(poes, selectedPoIndex);
      this.setPoToInfo(po);
      this.initInfo = po
    } else {
      this.info = this.createDefaultInfo();
    }
    this.info.jobInfo = job;
    this.info.requestTax = (job && job.request_tax) ? job.request_tax : false;
  }

  setInfoToPoData(poes, index, info) {
    const po = poes[index];
    po.remark = info.remark;
    po.sub_total = info.subTotal;
    po.discount_total = info.discountTotal;
    po.net_total = info.netTotal;
    po.vat = info.vat;
    po.vat_rate = info.vatRate;
    po.wh_tax = info.whTax;
    po.require_wh = info.requireWh;
    po.net_exclude_vat = info.netExcludeVat;
    po.grand_total = info.grandTotal;
    po.before_other_expense = info.beforeOtherExpense;
    po.items = info.details;
  }

  toSaveData(info) {
    this.parseFloatDetails(info.details);
    return {
      poInfo: this.info,
    };
  }

  async loadPoByJobId(jobId) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const res = await http.get(`${config.api.sims}/v1/jobs/${jobId}`);
      if (res && res.data && res.data.data) {
        const { data } = res.data;
        let conData;
        if (data.contractor_id) {
          const contractorData = await http.get(`${config.api.sims}/v1/contractors/${data.contractor_id}`);
          if (contractorData && contractorData.data && contractorData.data.data) {
            conData = [contractorData.data.data].map(c => {
              return {
                companyName: (!_.isNil(c.tax) && !_.isEmpty(c.tax)) ? c.tax.company_name : '',
                address: (!_.isNil(c.tax) && !_.isEmpty(c.tax)) ? c.tax.address : '',
                phone: (!_.isNil(c.tax) && !_.isEmpty(c.tax)) ? c.tax.phone : '',
                taxNo: (!_.isNil(c.tax) && !_.isEmpty(c.tax)) ? c.tax.tax_no : '',
                branchNo: (!_.isNil(c.tax) && !_.isEmpty(c.tax)) ? c.tax.branch_no : '',
                isJuristic: !!c.is_juristic,
              };
            });
          }
        }
        this.toPoInfo(data, ...conData);
      } else {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async savePo(jobId, sendEmail = false, user = "Admin User") {
    if (this.isSubmitting) return;
    this.isSubmitting = true;

    const { info, poes, selectedPoIndex } = this.toJS();
    const body = { info };
    body.user = user;
    if (sendEmail) {
      body.send_email = true;
    }
    if (Array.isArray(poes) && poes.length) {
      this.setInfoToPoData(poes, selectedPoIndex, info);
      body.po = poes;
      body.update_po_no = poes[selectedPoIndex].no;
    }
    try {
      const res = await http.put(`${config.api.sims}/v1/jobs/${jobId}/po`, body);
      this.isSubmitting = false;
      if (res && res.data) {
        return res.data
      }
    } catch (error) {
      this.isSubmitting = false;
      throw new Error(error);
    }
  }
}

export default new PurchaseOrderStore();
