import React from 'react';
import { observer, inject } from 'mobx-react';
import { required } from '../../../../components/form/FormValidation';
import FormGroup from '../../../../components/form/FormGroup';
import EnDropDown from '../../../../components/form/EnDropDown';
import { isAllow } from '../../../../utils/permission';
import Select from 'react-select';
import _ from 'lodash'

export class SkuMappingSection extends React.Component {
  onSkuMappingChanged(option) {
    this.props.product.saveInfo('skuMapping', _.get(option, 'value', null));
  }

  getSkuMappingOptions() {
    const skus = this.props.sku.mapIdAndLabel() || [];

    return skus.map((item) => ({
      value: item.id,
      label: item.label
    }))
  }

  render() {
    const { skuMapping } = this.props;
    return (
      <div className="row">
        <FormGroup containerClassName={`col-md-6`} label="SIMS SKU Mapping" validations={[required]}>
          <Select disabled={!isAllow('EDIT_SIMS_SKU_MAPPING')} id="sku-mapping" placeholder="กรุณาเลือก SKU จากระบบ SIMS" options={this.getSkuMappingOptions()} value={skuMapping} onChange={this.onSkuMappingChanged.bind(this)} searchable={true} clearable={false} />
        </FormGroup>
      </div>
    );
  }
}

export default inject('product', 'sku')(observer(SkuMappingSection));
