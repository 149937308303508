import humps from 'humps';
import moment from 'moment';
import BaseStore from '../BaseStore';
import config from '../../config';
import datetime from '../../utils/datetime';
import { http } from '../../utils/http';

export class Order extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      list: [],
      info: this.initInfo(),
    });
  }

  initInfo() {
    return {
      id: '',
      name: '',
      mobile: '',
      items: [{
        name: '',
        spec: '',
        qty: '',
        unit: '',
      }],
      address: {
        subDistrict: {
          code: '',
          name: '',
        },
        district: {
          code: '',
          name: '',
        },
        province: {
          code: '',
          name: '',
        },
        postcode: '',
      },
      googleAddress: '',
      location: [100.53791268803536, 13.805439700182204],
      delivery: true,
      lineUserId: '',
      lineDisplayName: '',
      linePictureUrl: '',
      expected_date: moment().toDate(),
      expected_time: 1,
      vatInfo: {
        isRequire: false,
        companyName: '',
        taxId: '',
        branchNo: 0,
        address: {
          no: '',
          subDistrict: {
            code: '',
            name: '',
          },
          district: {
            code: '',
            name: '',
          },
          province: {
            code: '',
            name: '',
          },
          postcode: '',
        },
      },
      botIndex: 0,
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    const info = this.toJS().info;
    info[key] = value;
    this.info = info;
  }

  async getOrderList() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/orders`);
      if (response.status === 200 && response.data) {
        this.list = response.data.data;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getOrderById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/orders/${id}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      this.info = humps.camelizeKeys(response.data.data);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getOrderByLineUserId(lineUserId) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/orders/line/${lineUserId}`);
      if (response.status === 200 && response.data && response.data.data) {
        this.info = humps.camelizeKeys(response.data.data);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async createOrder() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      info.expected_date = datetime.SendToApi(info.expected_date);
      const response = await http.post(`${config.api.bestdeal}/v1/orders`, info);
      if (response.status !== 200 && response.data) {
        throw new Error(response.data.message);
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateOrder(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      const response = await http.put(`${config.api.bestdeal}/v1/orders/${id}`, info);
      if (response.status !== 200 && response.data) {
        throw new Error(response.data.message);
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new Order();
