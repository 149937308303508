import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';
import moment from 'moment';
import qs from 'querystring'
import { pick, pickBy } from 'lodash';

export class AdminStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      storeArea: [],
      contractorId: [],
      store: [],
      storeInfo: this.getDefaultInfo(),
    });
  }

  getDefaultInfo() {
    return {
      name: '',
      userName: '',
      password: '',
      confirmPassword: '',
      oldPassword: '',
      paymentRequire: false,
      requestPayment: false,
      requestStorePayment: false,
      shareable: false,
      poRequire: false,
      quotationRequire: false,
      receiptRequire: false,
      trackingRequire: false,
      selectedTypeOfWorks: [],
      storePaymentNotiEmails: [],
      isBuAccount: false,
      isAttendSCGFamily: false,
      channel:"",
      mailingDate: {
        type: 'weekly',
        day: []
      },
      groupID: false,
      lineOARequire: false,
    };
  }

  setDefaultInfo() {
    this.storeInfo = this.getDefaultInfo();
  }

  saveStoreArea(value) {
    let data = value.map(v => {
      return ({
        provinceId: v.provinceCode,
        districtId: v.districtCode,
      });
    });
    this.storeArea = data;
  }

  togglePayment(value) {
    this.storeInfo.paymentRequire = value;
  }

  toggleShare(value) {
    this.storeInfo.shareable = value;
  }

  onToggleKey(key, value) {
    this.storeInfo[key] = value;
  }

  toggleEmailDate(value) {
    this.storeInfo.mailingDate = value;
  }

  toggleLineOA(value){
    this.storeInfo.lineOARequire = value;
  }

  saveContractor(value) {
    if (Array.isArray(value)) {
      this.contractorId = value;
    }
  }

  async getStore(query = { limit: 10, page: 1 }) {
    query = pickBy(query, (value) => !!value)
    const qstring = qs.stringify(pick(query, ['limit', 'page', 'store', 'channel']))
    const res = await http.get(`${config.api.sims}/v1/stores?${qstring}`);
    this.store = res.data.items;
    return res.data
  }

  async getStoreById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      let data;
      const res = await http.get(`${config.api.sims}/v1/stores/${id}`);
      if (res && res.status === 200 && res.data) {
        data = res.data.data;
        this.storeInfo = this.toInfo(data);
      }
      return data;
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  setStoreInfo(key, value) {
    
    this.storeInfo[key] = value;
  }

  toInfo(body) {
    return {
      storeId: body._id,
      name: body.name,
      paymentRequire: body.payment_require || false,
      shareable: body.shareable || false,
      requestPayment: body.request_payment || false,
      requestStorePayment: body.request_store_payment || false,
      storePaymentNotiEmails: body.store_payment_noti_emails || [],
      poRequire: body.po_require || false,
      quotationRequire: body.quotation_require || false,
      receiptRequire: body.receipt_require || false,
      trackingRequire: body.tracking_require || false,
      selectedTypeOfWorks: (body.type_of_works || []).map(tow => typeof tow === 'number' ? tow.toString() : tow),
      isBuAccount: body.is_bu_account || false,
      isAttendSCGFamily: body.is_attend_scg_family || false,
      channel:body.channel,
      mailingDate: body.mailing_date,
      productIds: body.product_ids || [],
      groupID: body.line_group_id || false,
      lineOARequire: body.line_oa_require || false,
    };
  }

  toApiBody(info) {
    return {
      name: info.name,
      user_name: info.userName,
      password: info.password,
      confirm_password: info.confirmPassword,
      payment_require: info.paymentRequire,
      shareable: info.shareable,
      request_payment: info.requestPayment,
      request_store_payment: info.requestStorePayment,
      store_payment_noti_emails: info.storePaymentNotiEmails,
      po_require: info.poRequire,
      quotation_require: info.quotationRequire,
      receipt_require: info.receiptRequire,
      tracking_require: info.trackingRequire,
      type_of_works: info.selectedTypeOfWorks.map(tow => typeof tow === 'string' ? +tow : tow),
      is_bu_account: info.isBuAccount,
      is_attend_scg_family: info.isAttendSCGFamily,
      channel: info.channel,
      mailing_date: info.mailingDate,
      line_oa_require: info.lineOARequire,
    };
  }

  async saveAdminStore() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const { storeInfo } = this.toJS();
      const body = this.toApiBody(storeInfo);
      let res = await http.post(`${config.api.sims}/v1/stores`, body);
      if (res.status !== 200) {
        throw new Error(res.data.message);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateAdminStore(storeId) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const {
        storeArea,
        contractorId,
        storeInfo,
      } = this.toJS();

      const options = {};
      if (storeArea && storeArea.length) {
        options.area = storeArea;
      }

      if (Array.isArray(contractorId) && contractorId.length) {
        options.contractors = contractorId;
      }

      if (storeInfo) {
        if (storeInfo.name) {
          options.name = storeInfo.name;
        }
        options.payment_require = storeInfo.paymentRequire;
        options.request_payment = storeInfo.requestPayment;
        options.request_store_payment = storeInfo.requestStorePayment;
        options.store_payment_noti_emails = storeInfo.storePaymentNotiEmails;
        options.shareable = storeInfo.shareable;
        options.po_require = storeInfo.poRequire;
        options.quotation_require = storeInfo.quotationRequire;
        options.receipt_require = storeInfo.receiptRequire;
        options.tracking_require = storeInfo.trackingRequire;
        options.type_of_works = storeInfo.selectedTypeOfWorks.map(tow => typeof tow === 'string' ? +tow : tow);
        options.is_bu_account = storeInfo.isBuAccount;
        options.is_attend_scg_family = storeInfo.isAttendSCGFamily;
        options.channel = storeInfo.channel;
        options.mailing_date = storeInfo.mailingDate;
        options.product_ids = storeInfo.productIds;
        options.line_oa_require = storeInfo.lineOARequire;
      }
      let result = await http.put(`${config.api.sims}/v1/admin?store_id=${storeId}`, options);
      return result
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async validateNewVersion(storeId, info) {
    const validate = await this.getStoreByIdForvalidate(storeId);
    if (
      (validate.submited_at && (moment(info.submited_at).toISOString() !== moment(validate.submited_at).toISOString()))
    ) {
      throw new Error('ข้อมูลมีการเปลี่ยนแปลง กรุณารีเฟรชก่อนทำการแก้ไข');
    }
    return true;
  }

  async getStoreByIdForValidate(id, query = { limit: 10, page: 1 }) {
    query = pickBy(query, (value) => !!value)
    const qstring = qs.stringify(pick(query, ['limit', 'page', 'search']))

    try {
      const res = await http.get(`${config.api.sims}/v1/stores/${id}/contractors?${qstring}&order=asc`);
      if (res && res.status === 200 && res.data) {
        return Promise.resolve(res.data)
      }
    } catch (err) {
      throw err;
    }
  }

  async editUser(storeUserId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const { storeInfo } = this.toJS();
      let response = await http.put(`${config.api.sims}/v1/stores`, {
        user_id: storeUserId,
        password: storeInfo.password,
        confirm_password: storeInfo.confirmPassword,
      });
      if (response.status !== 200) {
        throw new Error(response.data.message);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateProductStore() {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const { storeInfo } = this.toJS();
      let response = await http.post(`${config.api.sims}/v1/stores/updateProductStore`, {
        storeId: storeInfo.storeId,
        productIds: storeInfo.productIds,
      });
      if (response.status !== 200) {
        throw new Error(response.data.message);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new AdminStore();
