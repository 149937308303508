import moment from 'moment';
import momentTz from 'moment-timezone';
import * as _ from 'lodash';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Swal from 'sweetalert2';
import { TypeOfJobs, ProductList, AvailableTime } from '../../utils/data';
import { FormValidation, required } from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import EnDateRangePicker from '../../components/form/EnDateRangePicker';
import FormGroup from '../../components/form/FormGroup';
import EnButton from '../../components/form/EnButton';
import EnDropDown from '../../components/form/EnDropDown';
import Select from 'react-select';
import common from '../../utils/common';
import Notification from '../../components/common/Notification';
import datetime from '../../utils/datetime';
import { toListItemData, initListItemHeaderOption, onUploadItemClick, getListItem, listItemHeaderOpts, getDropZone, doUploadFiles, onUploadComplete, onError } from './step/stepCommon';
import ScrollButton from '../../components/common/ScrollButton';
import CallingButton from '../../components/common/CallingButton';
import AddressForm from '../../components/address/AddressForm';
import config from '../../config';
import Container from '../../layouts/ContainerContractor';

export class ContractorJobRequest extends Component {
  async componentWillMount() {
    const isUpdate = this.props.updateMode;
    if (isUpdate) {
      const jobStore = this.props.job.toJS().info;
      this.props.job.saveMultiInfo({
        startDate: jobStore.startDate,
        endDate: jobStore.endDate,
      });
      this.props.job.setInfo('jobType', jobStore.jobType);
      const contractor = this.props.auth.getContractor();
      this.state = {
        files: 'files',
        startDate: jobStore.startDate,
        endDate: jobStore.endDate,
        contractorId: jobStore.contractorId || '',
        jobType: jobStore.jobType,
        contractor,
        jobAvailable: jobStore.available,
      };
    } else {
      this.props.job.clearInfo();
      const { startDate, endDate } = this.state;
      this.props.job.saveMultiInfo({
        startDate,
        endDate,
      });
      this.props.job.setInfo('jobType', this.state.jobType);
    }
  }

  componentWillUnmount() {
    this.props.job.clearInfo();
  }

  saveInfo(key, e) {
    switch (key) {
      case 'available':
        this.props.job.setInfo(key, e.value);
        this.onCriteriaChange();
        break;
      case 'typeOfWork':
      case 'jobType':
      case 'startDate':
      case 'endDate':
      case 'sku':
        if (e.target) {
          this.props.job.setInfo(key, e.target.value);
        } else {
          this.props.job.setInfo(key, e);
        }
        this.onCriteriaChange();
        break;
      default:
        if (e.target) {
          this.props.job.setInfo(key, e.target.value);
        } else {
          this.props.job.setInfo(key, e);
        }
        break;
    }
  }
  saveAddress(e) {
    if (e.target) {
      this.saveInfo('address', e);
    }
  }

  checkDayBlock(date) {
    if (this.props.checkDayBlock) {
      return this.props.checkDayBlock(date);
    } else {
      const { unavailableDate } = this.props;
      if (Array.isArray(unavailableDate) && unavailableDate.length) {
        const dateBlock = unavailableDate.find(u => datetime.isEqual(date, u));
        return !!dateBlock;
      } else {
        return false;
      }
    }
  }

  onCriteriaChange(eventName) {
    if (this.props.onCriteriaChange) {
      const jobInfo = this.props.job.toJS().info;
      this.props.onCriteriaChange({
        eventName,
        workTypeId: jobInfo.typeOfWork[0],
        productId: jobInfo.typeOfWork.indexOf(1) >= 0 ? ProductList[0].id : '',
        jobType: jobInfo.jobType,
        available: jobInfo.available,
        startDate: jobInfo.startDate,
        endDate: jobInfo.endDate,
        contractorId: jobInfo.contractorId,
        districtCode: jobInfo.addressInfo.districtCode,
        skuCode: jobInfo.sku,
      });
    }
  }

  onUploadError(err) {
    this.noti.error(err);
  }

  onCancel() {
    const jobStore = this.props.job.toJS().info;
    const contractorId = (jobStore && jobStore.contractorId) ? jobStore.contractorId : this.state.contractorId;
    const calendarFilter = this.props.calendar.toJS().calendarFilter;
    let qs;
    if (this.props.onCancel) {
      this.props.onCancel();
    } else {
      if (calendarFilter) {
        qs = calendarFilter;
        this.props.history.push(`${config.publicUrl}/contractors/${contractorId}/calendar?${common.urlBuilder(qs)}`);
      } else {
        this.props.history.push(`${config.publicUrl}/contractors/${contractorId}/calendar`);
      }
    }
  }

  async onSubmitValue() {
    try {
      if (this.props.updateMode) {
        const job = this.props.job.toJS().info;
        const response = await this.props.job.updateContractorJob(job.contractorId, job.id);
        if (response && response.status === 'success') {
          this.noti.success('อัพเดตข้อมูลคิวช่างเรียบร้อย');
          setTimeout(() => {
            this.onCancel();
          }, 2000);
        }
      } else {
        const response = await this.props.job.createContractorJob(this.state.contractorId);
        if (response && response.status === 'success') {
          this.noti.success('บันทึกข้อมูลคิวช่างเรียบร้อย');
          setTimeout(() => {
            this.onCancel();
          }, 2000);
        }
      }
    } catch (error) {
      this.noti.error(error.message);
    }
  }

  async onSubmit() {
    try {
      const isAvailable = await this.validateJobPerDay();

      if (!isAvailable) {
        return;
      }
      await this.decreaseWorkPerDay();
      if (this.dropzone && this.dropzone.getFiles() && this.dropzone.getFiles().length) {
        doUploadFiles(this.dropzone, this.onSubmitValue.bind(this));
      } else {
        console.log('this.state >>>>>>>>>>>>>>>>', this.state);
        onUploadComplete([], this.state, this.props.contractorMode, this.onSubmitValue.bind(this));
      }
      // if (this.dropzone && this.dropzone.getFiles() && this.dropzone.getFiles().length) {
      //   doUploadFiles(this.dropzone);
      // } else {
      //   onUploadComplete([], this.state, this.props.contractorMode, this.props.onSubmit);
      // }

    } catch (error) {
      onError(error.message);
    }
  }

  onDelete() {
    const jobStore = this.props.job.toJS().info;
    Swal.fire({
      title: 'คุณต้องการลบคิวช่างนี้ใช่หรือไม่?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
    }).then(async (result) => {
      if (result.value) {
        await this.decreaseWorkPerDay();
        await this.props.job.deleteContractorJob(jobStore.id);
        this.noti.success('ลบคิวช่างเรียบร้อย');
        setTimeout(() => {
          this.onCancel();
        }, 2000);
      }
    });
  }

  changeAddressSet = (value) => {
    this.props.job.saveAddressData(value);
    this.onCriteriaChange('FILTER_CODE');
  }

  validateSubmitButton() {
    let reqInfo = this.props.job.toJS().info;
    let isValid =
      reqInfo.firstName &&
      reqInfo.phone;

    return isValid;
  }

  getAvailableTime() {
    const jobInfo = this.props.job.toJS().info;
    if (jobInfo && !datetime.isEqual(jobInfo.startDate, jobInfo.endDate)) {
      return AvailableTime.filter(a => a.id === 0);
    } else {
      return AvailableTime;
    }
  }

  conChageTime(e) {
    this.saveInfo('available', e);
    this.props.job.setInfo('timeLabel', e.label);
    this.setState({ editTime: false });
  }

  onDateRangChange(startDate, endDate) {
    this.props.job.saveMultiInfo({
      startDate,
      endDate,
    });
  }

  async validateJobPerDay() {
    const jobInfo = this.props.job.toJS().info;
    // const contractor = this.props.auth.getContractor();
    const startDate = jobInfo.startDate;
    const endDate = jobInfo.endDate;
    if (datetime.formatWithOffset(startDate, 'YYYYMMDD', 7) === datetime.formatWithOffset(this.state.startDate, 'YYYYMMDD', 7) &&
      datetime.formatWithOffset(endDate, 'YYYYMMDD', 7) === datetime.formatWithOffset(this.state.endDate, 'YYYYMMDD', 7)) {
      return true;
    }
    const { available } = jobInfo;
    let isUnavailable = false;
    const jobOption = {
      contractorId: jobInfo.contractorId,
      startDate: moment(startDate).add(-1, 'month').startOf('days'),
      endDate: moment(endDate).add(1, 'month').endOf('days'),
      jobType: jobInfo.jobType,
      contractorInfo: this.state.contractor,
    };
    const dateRange = datetime.GetBetweenDates(startDate, endDate, { format: 'YYYYMMDD' });
    const jobs = await this.props.contractor.getJob(jobOption);
    let jobDateRange = [];
    dateRange.map((checkDate) => {
      jobs.map((job) => {
        const dateRange = datetime.GetBetweenDates(job.start_date, job.end_date, { format: 'YYYYMMDD' });
        dateRange.map((date) => {
          if (checkDate === datetime.formatWithOffset(date, 'YYYYMMDD', 7)) {
            jobDateRange = _.concat(jobDateRange, {
              start_date: momentTz(date).utcOffset(7).toISOString(),
              end_date: momentTz(date).utcOffset(7).toISOString(),
              available: +job.available,
              type_of_job: job.type_of_job,
              contractor_id: job.contractor_id,
              start_date_text: datetime.formatWithOffset(date, 'YYYYMMDD', 7),
              end_date_text: datetime.formatWithOffset(date, 'YYYYMMDD', 7),
              start_end_text: datetime.formatWithOffset(date, 'YYYYMMDD', 7).concat(datetime.formatWithOffset(date, 'YYYYMMDD', 7)),
            });
          }
        });
        return jobDateRange;
      });
    });
    let jobGroupDate = _.orderBy(jobDateRange, 'format');
    jobGroupDate = _.groupBy(jobGroupDate, 'start_end_text');

    jobGroupDate = Object.values(jobGroupDate);

    const unavailableDate = await Promise.all(dateRange.map(async (date) => {
      const options = {
        contractorId: jobInfo.contractorId,
        startDate: moment(date).startOf('days'),
        endDate: moment(date).endOf('days'),
        jobType: jobInfo.jobType,
        contractorInfo: this.state.contractor,
      };
      const workCalendar = await this.props.contractor.getContractorWorkCalendar(options);
      const filterJob = jobGroupDate.filter((job) => {
        return job[0].start_date_text === date;
      });
      const flatJob = filterJob.flat();

      let jobMorning = 0;
      let jobAfternoon = 0;
      if (flatJob && flatJob.length) {
        flatJob.map(job => {
          jobMorning += job.available === 0 || job.available === 1 ? 1 : 0;
          jobAfternoon += job.available === 0 || job.available === 2 ? 1 : 0;
        });
        if ((available === 1 && jobMorning >= workCalendar.jobPerDay.morning) ||
          (available === 2 && jobAfternoon >= workCalendar.jobPerDay.afternoon) ||
          (available === 0 && (jobMorning >= workCalendar.jobPerDay.morning || jobAfternoon >= workCalendar.jobPerDay.afternoon))) {
          jobMorning += jobInfo.available === 0 || jobInfo.available === 1 ? 1 : 0;
          jobAfternoon += jobInfo.available === 0 || jobInfo.available === 2 ? 1 : 0;
          isUnavailable = isUnavailable || true;

          let surveyPerDay = workCalendar.surveyPerDay;
          let installationPerDay = workCalendar.installationPerDay;
          if (options.jobType === 'S') {
            surveyPerDay = { morning: jobMorning, afternoon: jobAfternoon };
          } else {
            installationPerDay = { morning: jobMorning, afternoon: jobAfternoon };
          }
          return {
            contractorId: options.contractorId,
            startDate: options.startDate,
            endDate: options.endDate,
            surveyPerDay: surveyPerDay,
            installationPerDay: installationPerDay,
          };
        }
      }
    }));
    if (isUnavailable) {
      let confirmDialogOptions = {
        title: 'ยืนยันการทำรายการ',
        text: 'จำนวนงานต่อวันของท่านเต็มแล้ว ท่านต้องการทำรายการต่อหรือไม่',
        // ระบบจะเพิ่มจำนวนงานที่รับได้เพิ่มให้อัตโนมัติ
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: 'font-size-200',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      };

      let result = await Swal.fire(confirmDialogOptions);
      if (result.value) {
        try {
          await this.increaseWorkPerDay(unavailableDate);
          return true;
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          return false;
        }
      } else {
        return false;
      }
    }
    return true;
  }

  async increaseWorkPerDay(unavailableDate) {
    try {
      // อัพเดทวันใหม่ที่ถูกเลือก
      await Promise.all(unavailableDate.map(async (data) => {
        if (data) {
          const updateOptions = {
            contractorId: data.contractorId,
            startDate: moment(data.startDate).startOf('day').toISOString(),
            endDate: moment(data.endDate).startOf('day').toISOString(),
            surveyPerDay: data.surveyPerDay,
            installationPerDay: data.installationPerDay,
          };
          await this.props.contractor.updateContractorWorkCalendarByDate(updateOptions);
        }
      }));
      return true;
    } catch (error) {
      throw error;
    }
  }

  async decreaseWorkPerDay() {
    try {
      let options = {
        contractorId: this.state.contractorId,
        startDate: momentTz(this.state.startDate).utcOffset(7).startOf('day').toISOString(),
        endDate: momentTz(this.state.endDate).utcOffset(7).startOf('day').toISOString(),
        jobType: this.state.jobType,
        contractorInfo: this.state.contractor,
      };

      const jobPerDay = this.state.jobType === 'S' ? this.state.contractor.survey_per_day : this.state.contractor.installation_per_day;
      const workCalendar = await this.props.contractor.getContractorWorkCalendarByDate(options);
      // อัพเดทวันเก่าที่ถูกเปลี่ยน
      if (workCalendar && workCalendar.data && workCalendar.data.length) {
        for (let i = 0; i < workCalendar.data.length; i++) {
          let workPerDay = this.state.jobType === 'S' ? workCalendar.data[i].survey_per_day : workCalendar.data[i].installation_per_day;
          switch (this.state.jobAvailable) {
            case 0:
              workPerDay = { morning: workPerDay.morning - 1, afternoon: workPerDay.afternoon - 1 };
              break;
            case 1:
              workPerDay = { morning: workPerDay.morning - 1, afternoon: workPerDay.afternoon };
              break;
            case 2:
              workPerDay = { morning: workPerDay.morning, afternoon: workPerDay.afternoon - 1 };
              break;
            default:
              workPerDay = { morning: workPerDay.morning, afternoon: workPerDay.afternoon };
              break;
          }
          workPerDay.morning = workPerDay.morning < jobPerDay.morning ? jobPerDay.morning : workPerDay.morning;
          workPerDay.afternoon = workPerDay.afternoon < jobPerDay.afternoon ? jobPerDay.afternoon : workPerDay.afternoon;
          const updateOptions = {
            contractorId: options.contractorId,
            startDate: moment(workCalendar.data[i].start_date).startOf('day').toISOString(),
            endDate: moment(workCalendar.data[i].end_date).startOf('day').toISOString(),
            surveyPerDay: this.state.jobType === 'S' ? workPerDay : workCalendar.data[i].survey_per_day,
            installationPerDay: this.state.jobType === 'I' ? workPerDay : workCalendar.data[i].installation_per_day,
          };
          await this.props.contractor.updateContractorWorkCalendarByDate(updateOptions);
        }
      }

      return true;
    } catch (error) {
      throw error;
    }
  }

  render() {
    const { contractorMode } = this.props;
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    let disabled = false;
    let showDate = datetime.ConvertISO2DisplayFullMonth(jobInfo.startDate || this.state.startDate);
    if (!datetime.isEqual(jobInfo.startDate || this.state.startDate, jobInfo.endDate || this.state.endDate)) {
      showDate += ` - ${datetime.ConvertISO2DisplayFullMonth(jobInfo.endDate || this.state.endDate)}`;
    }

    let typeOfJobs = [];
    typeOfJobs = TypeOfJobs.map((item, idx) => {
      return <option key={idx} value={item.id}>{item.label}</option>;
    });
    typeOfJobs = typeOfJobs.slice(1);

    let itemHeader = initListItemHeaderOption('ไฟล์เพิ่มเติม ( อัพโหลดได้สูงสุด 20 ไฟล์ต่อครั้ง )');
    let itemData = toListItemData(jobInfo.files);
    if (!contractorMode) {
      itemHeader.push(listItemHeaderOpts);
    }
    let listItem = getListItem(itemHeader, itemData, onUploadItemClick.bind(this, 'files', jobInfo));
    let dropzone = getDropZone(e => { this.dropzone = e; }, this.props.jobId, (e) => {
      onUploadComplete(e, this.state, this.props.contractorMode, this.props.onSubmit);
    });

    const timeSlot = this.getAvailableTime();
    let availableTime;
    if (timeSlot) {
      availableTime = timeSlot.map((item, index) => {
        return { value: item.id, label: `${item.title}` };
      });
    }

    return (
      <div>
        {
          this.props.updateMode ? (<FormValidation>
            <Notification ref={(ref) => { this.noti = ref; }} />
            <div >
              {/* <h4>วันที่ {showDate}</h4> */}
              <div className="row">
                <EnTextFormGroup id="customer-firstname" disabled={disabled} containerClassName="col-md-6" label="ชื่อลูกค้า" value={jobInfo.firstName} onChange={this.saveInfo.bind(this, 'firstName')} validations={[required]} />
                <EnTextFormGroup id="customer-lastname" disabled={disabled} containerClassName="col-md-6" label="นามสกุล" value={jobInfo.lastName} onChange={this.saveInfo.bind(this, 'lastName')} />
              </div>
              <div className="row">
                <EnTextFormGroup
                  id="customer-phone"
                  disabled={disabled}
                  containerClassName="col-md-6"
                  label="เบอร์ติดต่อ"
                  value={jobInfo.phone}
                  onChange={this.saveInfo.bind(this, 'phone')}
                  validations={[required]}>
                  <CallingButton phone={jobInfo.phone} isButtonGroup />
                </EnTextFormGroup>
                <EnTextFormGroup id="customer-email" disabled={disabled} containerClassName="col-md-6" label="อีเมล" value={jobInfo.email} onChange={this.saveInfo.bind(this, 'email')} />
              </div>
              <div className="row">
                <AddressForm
                  disabled={false}
                  addressData={jobInfo.addressInfo}
                  onChange={(addrData) => { this.props.job.clearAddressData(); }}
                  onAddressSelected={(addressObject) => this.changeAddressSet(addressObject)}
                />
              </div>

              <div className="row">
                <EnTextFormGroup id="address" disabled={disabled} containerClassName="col-md-12" label="ที่อยู่ (บ้านเลขที่ ซอย ถนน)" value={jobInfo.address} onChange={this.saveAddress.bind(this)} />
              </div>

              <div className="row">
                <FormGroup containerClassName="col-md-6" label="ลักษณะงาน">
                  <EnDropDown id="job-type" value={jobInfo.jobType} onChange={this.saveInfo.bind(this, 'jobType')}>
                    {typeOfJobs}
                  </EnDropDown>
                </FormGroup>
                <EnTextFormGroup id="other-work" containerClassName="col-md-6" label="ประเภทงาน" value={jobInfo.otherWork} onChange={this.saveInfo.bind(this, 'otherWork')} />
              </div>
              <div className="row">
                <FormGroup containerClassName="col-md-6" label={`วันที่เข้าทำงาน`}>
                  <EnDateRangePicker
                    withPortal
                    numberOfMonths={1}
                    disabled={disabled}
                    ref={(e) => { this.dptDateRange = e; }}
                    startDate={jobInfo.startDate ? moment(jobInfo.startDate) : null}
                    endDate={jobInfo.endDate ? moment(jobInfo.endDate) : null}
                    onDateChange={this.onDateRangChange.bind(this)}
                    // isDayBlocked={this.checkDayBlock.bind(this)}
                    minimumNights={0}
                    startDatePlaceholderText="วันที่เริ่มต้น"
                    endDatePlaceholderText="วันที่สิ้นสุด" />
                </FormGroup>
              </div>
              <div className="row">
                <FormGroup containerClassName="col-md-12" label="ช่วงเวลา">
                  <Select
                    multi={false}
                    searchable={false}
                    clearable={false}
                    value={jobInfo.available}
                    options={availableTime}
                    onChange={this.conChageTime.bind(this)}
                  />
                </FormGroup>
              </div>
              <div className="row">
                <EnTextFormGroup id="description" disabled={disabled} containerClassName="col-md-12" label="รายละเอียดเพิ่มเติม" value={jobInfo.description} onChange={this.saveInfo.bind(this, 'description')} />
              </div>

              <div className="row">
                <div className="col-md-12">
                  {listItem}
                  {dropzone}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <EnButton id="submit-button" className="btn-info pull-right" disabled={!this.validateSubmitButton()} onClick={this.onSubmit.bind(this)}>
                    <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                  </EnButton>
                  <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                    <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                  </EnButton>
                  <EnButton id="delete-button" className="btn-danger pull-left" onClick={this.onDelete.bind(this)}>
                    <i className="fa fa-trash btn-icon" aria-hidden="true" />ลบ
                  </EnButton>
                </div>
              </div>
              <ScrollButton scrollStepInPx="50" delayInMs="16.66" />
            </div>
          </FormValidation >) : (<Container>
            <FormValidation>
              <Notification ref={(ref) => { this.noti = ref; }} />
              <div className="card">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">ปฏิทิน</h4>
                  <p className="category">รายละเอียดการใช้บริการ</p>
                </div>
                <div className="card-content">
                  <h4>วันที่ {showDate}</h4>
                  <div className="row">
                    <EnTextFormGroup id="customer-firstname" disabled={disabled} containerClassName="col-md-6" label="ชื่อลูกค้า" value={jobInfo.firstName} onChange={this.saveInfo.bind(this, 'firstName')} validations={[required]} />
                    <EnTextFormGroup id="customer-lastname" disabled={disabled} containerClassName="col-md-6" label="นามสกุล" value={jobInfo.lastName} onChange={this.saveInfo.bind(this, 'lastName')} validations={[required]} />
                  </div>
                  <div className="row">
                    <EnTextFormGroup
                      id="customer-phone"
                      disabled={disabled}
                      containerClassName="col-md-6"
                      label="เบอร์ติดต่อ"
                      value={jobInfo.phone}
                      onChange={this.saveInfo.bind(this, 'phone')}
                      validations={[required]}>
                      <CallingButton phone={jobInfo.phone} isButtonGroup />
                    </EnTextFormGroup>
                    <EnTextFormGroup id="customer-email" disabled={disabled} containerClassName="col-md-6" label="อีเมล" value={jobInfo.email} onChange={this.saveInfo.bind(this, 'email')} />
                  </div>
                  <div className="row">
                    <AddressForm
                      disabled={false}
                      addressData={jobInfo.addressInfo}
                      onChange={(addrData) => { this.props.job.clearAddressData(); }}
                      onAddressSelected={(addressObject) => this.changeAddressSet(addressObject)}
                    />
                  </div>
                  <div className="row">
                    <EnTextFormGroup id="address" disabled={disabled} containerClassName="col-md-12" label="ที่อยู่ (บ้านเลขที่ ซอย ถนน)" value={jobInfo.address} onChange={this.saveAddress.bind(this)} />
                    {/* <EnTextFormGroup id="landmark" disabled={disabled} containerClassName="col-md-6" label="จุดสังเกต" value={jobInfo.addressInfo.landmark} onChange={this.saveLandmark.bind(this)} /> */}
                  </div>

                  <div className="row">
                    <FormGroup containerClassName="col-md-6" label="ลักษณะงาน">
                      <EnDropDown id="job-type" value={jobInfo.jobType} onChange={this.saveInfo.bind(this, 'jobType')}>
                        {typeOfJobs}
                      </EnDropDown>
                    </FormGroup>
                    <EnTextFormGroup id="other-work" containerClassName="col-md-6" label="ประเภทงาน" value={jobInfo.otherWork} onChange={this.saveInfo.bind(this, 'otherWork')} />
                  </div>
                  <div className="row">
                    <FormGroup containerClassName="col-md-12" label="ช่วงเวลา">
                      <Select
                        multi={false}
                        searchable={false}
                        clearable={false}
                        value={jobInfo.available}
                        options={availableTime}
                        onChange={this.conChageTime.bind(this)}
                      />
                    </FormGroup>
                  </div>
                  <div className="row">
                    <EnTextFormGroup id="description" disabled={disabled} containerClassName="col-md-12" label="รายละเอียดเพิ่มเติม" value={jobInfo.description} onChange={this.saveInfo.bind(this, 'description')} />
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      {listItem}
                      {dropzone}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <EnButton id="submit-button" className="btn-info pull-right" disabled={!this.validateSubmitButton()} onClick={this.onSubmit.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                        <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                      </EnButton>
                    </div>
                  </div>
                  <ScrollButton scrollStepInPx="50" delayInMs="16.66" />
                </div>
              </div>
            </FormValidation>
          </Container>)
        }
      </div>
    );
  }
}

export default inject('auth', 'job', 'typeofwork', 'sku', 'calendar', 'contact')(observer(ContractorJobRequest));
