import React from 'react';
import swal from 'sweetalert2';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Notification from '../../../components/common/Notification';
import Loader from '../../../components/common/Loader';
import { FormValidation, required } from '../../../components/form/FormValidation';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import FormGroup from '../../../components/form/FormGroup';
// import EnQuill from '../../../components/form/EnQuill';
import EnButton from '../../../components/form/EnButton';
import EnDropZone from '../../../components/form/EnDropZone';
// import EnDropDown from '../../../components/form/EnDropDown';
import ImagePicker from '../../../components/form/ImagePicker';
import AuthStore from '../../../stores/AuthStore';
import { config } from '../../../config';
import { EnTagSelectValidation } from '../../../components/form/EnTagSelect';
import common from '../../../utils/common';
import { isAllow } from '../../../utils/permission';
import Select from 'react-select'
import EnCKEditor from '../../../components/form/EnCKEditor';

export class ProductGroupPage extends React.Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    this.state = {
      id: id,
      isEdit: id && id.length,
      files: [],
      products: [],
      selectedProducts: [],
      bestSeller: '',
    };
  }

  async componentWillMount() {
    if (this.state.isEdit) {
      await this.props.productgroup.getProductForEdit(this.state.id);
    } else {
      this.props.productgroup.clearInfo();
    }

    await this.props.product.getProductList();
    const info = this.props.productgroup.toJS().info;
    const productItems = this.props.product.toJS().items;

    this.setState({
      products: productItems,
      bestSeller: info.bestSellerId,
      selectedProducts: info.productIds || []
    });
  }

  onNameChanged(e) {
    this.props.productgroup.saveInfo('name', { th: e.target.value });
  }

  onDescriptionChanged(value) {
    this.props.productgroup.saveInfo('description', { th: value });
  }

  onShortDescriptionChanged(value) {
    this.props.productgroup.saveInfo('shortDescription', { th: value });
  }

  onSeoChange(key, e) {
    if (e.target) {
      if (key === 'slug') {
        this.props.productgroup.saveSeoInfo(common.slugify(e.target.value), key);
      }
      this.props.productgroup.saveSeoInfo(e.target.value, key);
    }
  }

  deleteImage(imageIndex) {
    const productgroup = this.props.productgroup.toJS();
    const images = productgroup.info.images;
    images.splice(imageIndex, 1);
    this.props.productgroup.saveInfo('images', images);
  }

  onAddedFile() {
    if (this.dropzone) {
      this.setState({
        files: this.dropzone.getFiles(),
      });
    }
  }

  isValid() {
    const { info } = this.props.productgroup.toJS();
    const isValidName = info.name && !_.isEmpty(info.name.th);
    const isValidUnit = info.unit && !_.isEmpty(info.unit.th);
    const isValidDescription = !_.isEmpty(info.description);
    const validBestSeller = !_.isEmpty(this.state.bestSeller);
    let validImage;
    if (!_.isEmpty(info.images) || !_.isEmpty(this.state.files)) {
      validImage = true;
    } else {
      validImage = false;
    }
    return isValidName && isValidDescription && validImage && validBestSeller && isValidUnit;
  }

  async onSubmit() {
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไขกลุ่มสินค้า' : 'กรุณายืนยันการเพิ่มกลุ่มสินค้าใหม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.uploadAndSubmit();
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      this.props.product.clearInfo();

      setTimeout(() => {
        this.onCancel();
      }, 500);
    }
  }

  onCancel() {
    this.props.history.push(`${config.publicUrl}/qchang/productgroup`);
  }

  async uploadAndSubmit() {
    if (this.dropzone && this.dropzone.getFiles() && this.dropzone.getFiles().length) {
      return this.doUploadFiles(this.dropzone);
    } else {
      return this.submitForm();
    }
  }

  async doUploadFiles(dropzone) {
    return new Promise((resolve, reject) => {
      let files = dropzone.getFiles();
      for (let file of files) {
        if (file.status === 'error') {
          reject(new Error('ไม่สามารถอัพโหลดไฟล์ได้'));
        }
      }
      dropzone.upload(async () => {
        try {
          await this.submitForm();
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    });
  };

  async onDropZoneComplete(files) {
    const productgroup = this.props.productgroup.toJS();
    const images = productgroup.info.images;
    files.forEach(upload => {
      const uploadResult = upload.res ? upload.res.data : null;
      if (uploadResult) {
        images.push({
          url: uploadResult.path,
          thumbnail: uploadResult.thumbnail,
          alt: uploadResult.name,
        });
      }
    });
    this.props.productgroup.saveInfo('images', images);
  }

  async submitForm() {
    const options = {
      selectProductIds: this.state.selectedProducts,
      selectBestSellerId: this.state.bestSeller,
    };
    try {
      if (this.state.isEdit) {
        return this.props.productgroup.updateProductGroup(this.state.id, options);
      } else {
        return this.props.productgroup.createProductGroup(options);
      }
    } catch (error) {
      throw error;
    }
  }

  getDropZone(ref, callback) {
    const url = `${config.api.qchang}/v1/productgroup/upload`;
    const token = AuthStore.getToken();

    return (
      <EnDropZone
        ref={ref}
        url={url}
        token={token}
        acceptedFiles={'image/*'}
        onAllComplete={callback}
        maxFileSize={10}
        onAddedFile={this.onAddedFile.bind(this)}
      />
    );
  };

  displayImages(images) {
    if (!Array.isArray(images) || !images.length) {
      return (
        <div className="col-md-3" key={`image-no`}>
          ยังไม่มีรูปสินค้า
        </div>
      );
    }

    return images.map((image, index) => {
      return (
        <div className="col-md-3" key={`image-${index + 1}`}>
          <ImagePicker
            src={image.thumbnail}
            id={`image-${index + 1}`}
            onDelete={isAllow("EDIT_PRODUCT_GROUP_PHOTO") ? this.deleteImage.bind(this, index) : false}
          />
        </div>
      );
    });
  }

  onChangeProduct(selectedItems) {
    const ids = selectedItems.split(',');

    this.setState({
      selectedProducts: ids
    })
  }

  onChangeBestSeller(option) {
    this.setState({ bestSeller: _.get(option, 'value', '') });
  }

  showItem() {
    const productOptions = (this.state.products || []).map((p, index) => {
      return ({
        label: `${p.skuCode} ${p.name.th}`,
        value: p.id,
      });
    });

    return (
      <EnTagSelectValidation
        disabled={isAllow("EDIT_PRODUCT_GROUP_GENERAL_INFORMATION") ? false : true}
        placeholder="กรุณาเลือกสินค้า"
        closeOnSelect={false}
        options={productOptions}
        onTagChange={this.onChangeProduct.bind(this)}
        value={this.state.selectedProducts}
        searchable={false}
      />
    );
  }

  getBestSellerOptions() {
    const products = this.state.products || []
    const selectedProducts = this.state.selectedProducts
      .map((id) => products.find((where) => where.id === id))
      .filter((item) => !!item);

    return selectedProducts.map((item) => ({
      value: item.id,
      label: item.name.th
    }))
  }

  onUnitChanged = (e) => {
    this.props.productgroup.saveUnit(e.target.value);
  }

  render() {
    const { info } = this.props.productgroup.toJS();
    const formTitle = this.state.isEdit ? 'แก้ไขกลุ่มสินค้า' : 'เพิ่มกลุ่มสินค้าใหม่';
    let dropzone = this.getDropZone(e => { this.dropzone = e; }, (e) => {
      this.onDropZoneComplete(e);
    });
    const {
      title,
      slug,
      keywords,
      description,
    } = info.seo;
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{formTitle}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="row">
                    <EnTextFormGroup
                      disabled={isAllow("EDIT_PRODUCT_GROUP_GENERAL_INFORMATION") ? false : true}
                      containerClassName="col-md-12"
                      type="text"
                      label="ชื่อกลุ่มสินค้า"
                      value={info.name ? info.name.th : ''}
                      onChange={this.onNameChanged.bind(this)}
                      validations={[required]} />
                    {/* <div className="col-md-6">
                      <span className="form-label">สินค้าของ Solution *</span>
                      {this.showItem()}
                    </div> */}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <span className="form-label">รายการสินค้า *</span>
                      {this.showItem()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <span className="form-label">สินค้าขายดี *</span>
                      <Select
                        disabled={isAllow("EDIT_PRODUCT_GROUP_GENERAL_INFORMATION") ? false : true}
                        options={this.getBestSellerOptions()}
                        value={this.state.bestSeller}
                        onChange={this.onChangeBestSeller.bind(this)} searchable={true} clearable={false} />
                    </div>
                  </div>
                  <div className="row">
                    <EnTextFormGroup
                      disabled={isAllow("EDIT_PRODUCT_GROUP_GENERAL_INFORMATION") ? false : true}
                      containerClassName="col-md-12"
                      type="text"
                      label="หน่วย"
                      value={info.unit ? info.unit.th : ''}
                      onChange={this.onUnitChanged.bind(this)}
                      validations={[required]} />
                  </div>
                  <div className="seo">
                    <div className="header-seo"><span>SEO</span></div>
                    <div className="seo-form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <EnTextFormGroup
                              disabled={isAllow("EDIT_PRODUCT_GROUP_SEO") ? false : true}
                              containerClassName="col-md-6"
                              type="text"
                              label="SEO Title"
                              value={title}
                              onChange={this.onSeoChange.bind(this, 'title')} />
                            <EnTextFormGroup
                              disabled={isAllow("EDIT_PRODUCT_GROUP_SEO") ? false : true}
                              containerClassName="col-md-6"
                              type="text"
                              label="Slug"
                              value={slug}
                              onChange={this.onSeoChange.bind(this, 'slug')} />
                          </div>
                          <div className="row">
                            <EnTextFormGroup
                              disabled={isAllow("EDIT_PRODUCT_GROUP_SEO") ? false : true}
                              containerClassName="col-md-12"
                              type="text"
                              label="Keyword"
                              placeholder="รางน้ำฝน, รางน้ำฝนพร้อมติดตั้ง, เอสซีจี รุ่น SMART สีขาว"
                              value={keywords}
                              onChange={this.onSeoChange.bind(this, 'keywords')} />
                          </div>
                          <div className="row">
                            <EnTextFormGroup
                              disabled={isAllow("EDIT_PRODUCT_GROUP_SEO") ? false : true}
                              containerClassName="col-md-12"
                              type="text"
                              label="Meta Description"
                              placeholder="ผลิตภัณฑ์รางน้ำไวนิล เอสซีจี เป็นวัสดุหนึ่งของรางน้ำฝนที่หลายบ้านนิยมใช้ เนื่องจากคุณสมบัติที่แข็งแรง ทนทาน ไม่ลามไฟ"
                              value={description}
                              onChange={this.onSeoChange.bind(this, 'description')} />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <label>ตัวอย่าง</label>
                          <div className="seo seo-form">
                            <div className="seo-preview-title">{title || ''}</div>
                            <div className="seo-preview-link">
                              <cite>
                                {'https://partner.q-chang.com/products/solution/'}
                                <mark>
                                  {slug || ''}
                                </mark>
                              </cite>
                            </div>
                            <div className="seo-preview-description">
                              <div>
                                <span>{description || ''}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {/* <EnQuill
                      disabled={isAllow("EDIT_PRODUCT_GROUP_GENERAL_INFORMATION") ? false : true}
                      containerClassName="col-md-12"
                      label="ข้อมูลกลุ่มสินค้า"
                      value={info.shortDescription ? info.shortDescription.th : ''}
                      onChange={this.onShortDescriptionChanged.bind(this)}
                      validations={[required]}
                    /> */}

                    <EnCKEditor
                      label="ข้อมูลกลุ่มสินค้า"
                      type="productgroup"
                      containerClassName="col-md-12"
                      disabled={isAllow("EDIT_PRODUCT_GROUP_GENERAL_INFORMATION") ? false : true}
                      value={info.shortDescription ? info.shortDescription.th : ''}
                      onChange={(event, editor) => {this.onShortDescriptionChanged(editor.getData())}}
                      validations={[required]}
                    />
                  </div>
                  <div className="row">
                    {/* <EnQuill
                      disabled={isAllow("EDIT_PRODUCT_GROUP_GENERAL_INFORMATION") ? false : true}
                      containerClassName="col-md-12"
                      label="รายละเอียดเพิ่มเติม"
                      value={info.description ? info.description.th : ''}
                      onChange={this.onDescriptionChanged.bind(this)}
                      validations={[required]}
                    /> */}
                    <EnCKEditor
                      label="รายละเอียดเพิ่มเติม"
                      type="productgroup"
                      disabled={isAllow("EDIT_PRODUCT_GROUP_GENERAL_INFORMATION") ? false : true}
                      containerClassName="col-md-12"
                      value={info.description ? info.description.th : ''}
                      onChange={(event, editor) => {this.onDescriptionChanged(editor.getData())}}
                      validations={[required]}
                    />
                  </div>
                  <div className="row">
                    <FormGroup containerClassName="col-md-12" label="รูปภาพ" >
                      <div className="col-md-12">
                        {this.displayImages(info.images)}
                      </div>
                    </FormGroup>
                  </div>
                  <div className="row">
                    {isAllow("EDIT_PRODUCT_GROUP_PHOTO") && (
                      <FormGroup containerClassName="col-md-12" label="อัพโหลดรูปภาพ" >
                        <span style={{ color: 'red' }}> ( อัพโหลดได้สูงสุด 20 ไฟล์ต่อครั้ง ) </span>
                        {dropzone}
                      </FormGroup>
                    )}
                  </div>
                  <hr />
                  <div className="row">
                    <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={this.onSubmit.bind(this)}>
                      <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                    </EnButton>
                    <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                      <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                    </EnButton>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'typeofwork', 'sku', 'product', 'productgroup', 'typeofwork')(observer(ProductGroupPage));
