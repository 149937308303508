import React from 'react';
import NumberDisplay from './NumberDisplay';
import NumberButton from './NumberButton';
import FuncButton from './FuncButton';
import '../../assets/css/phone-pad.css';
import { KEY_NUMBERS } from '../../utils/common';

class PhonePad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayValue: props.phone ? props.phone : '',
      limitDisplayNumber: props.limitDisplayNumber,
      title: props.title,
    };
  }

  onClear() {
    this.props.savePhone('');
    this.setState({
      displayValue: '',
    });
  }

  onDeleteLast() {
    const { displayValue } = this.state;
    if (!displayValue || !displayValue.length) {
      return;
    }

    const newDisplayValue = displayValue.substring(0, displayValue.length - 1) || '';
    this.props.savePhone(newDisplayValue);
    this.setState({
      displayValue: newDisplayValue,
    });
  }

  onPressNumber = (num) => {
    const { displayValue, limitDisplayNumber } = this.state;
    if (displayValue && displayValue.length >= limitDisplayNumber) {
      return;
    }

    const newDisplayValue = displayValue === '' ? num : displayValue + num;
    this.props.savePhone(newDisplayValue);
    this.setState({
      displayValue: newDisplayValue,
    });
  }

  handleKeyDown = (e) => {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
      const num = KEY_NUMBERS[`${e.keyCode}`];
      this.onPressNumber(num);
    } else if (e.keyCode === 8) {
      // backspace
      this.onDeleteLast();
    } else if (e.keyCode === 27) {
      // esc
      this.onClear();
    } else if (e.keyCode === 13) {
      // enter
      this.props.onPressNext();
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    const { title, displayValue, limitDisplayNumber } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-md-offset-4 phone">
            <div className="row contractor-signin-logo">
              {
                // <p className="col-md-12 text-center phone-title">เข้าสู่ระบบ</p>
              }
              <img src="https://storage.googleapis.com/prod-qchang-v1/static/images/logo-new-full.png" alt="คิวช่าง" />
            </div>

            <div className="row">
              <div className="col-md-12">
                <NumberDisplay placeHolder={title} value={displayValue} size={limitDisplayNumber} />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-center">
                <div className="row">
                  <div className="col-md-3 col-md-offset-1">
                    <NumberButton text="1" onPress={() => this.onPressNumber('1')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="2" onPress={() => this.onPressNumber('2')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="3" onPress={() => this.onPressNumber('3')} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 col-md-offset-1">
                    <NumberButton text="4" onPress={() => this.onPressNumber('4')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="5" onPress={() => this.onPressNumber('5')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="6" onPress={() => this.onPressNumber('6')} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 col-md-offset-1">
                    <NumberButton text="7" onPress={() => this.onPressNumber('7')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="8" onPress={() => this.onPressNumber('8')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="9" onPress={() => this.onPressNumber('9')} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 col-md-offset-1">
                    <FuncButton className="fa fa-eraser" onPress={() => this.onClear()} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="0" onPress={() => this.onPressNumber('0')} />
                  </div>
                  <div className="col-md-3">
                    <FuncButton className="fa fa-times" onPress={() => this.onDeleteLast()} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div id="confirm" onClick={this.props.onPressNext} className="btn btn-warning btn-block flatbtn action-btn">
                  <i className="fa fa-check btn-icon" aria-hidden="true" />ตกลง
                </div>
              </div>
              <div className="col-md-12">
                <div id="back" onClick={this.props.onPressBack} className="btn btn-primary btn-block flatbtn action-btn">
                  <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับไปหน้าแรก
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PhonePad;
