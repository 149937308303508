import { isNil } from 'lodash';
import React from 'react';
import { Fragment } from 'react';
import EnButton from './EnButton';
import FormGroup from './FormGroup';

export class EnRadioScore extends React.Component {
  onButtonClick(value) {
    if (this.props.onClick) {
      this.props.onClick(value)
    }
  }

  render() {
    let {
      label,
      label2,
      value,
      options,
      notRequired,
      validations,
      containerClassName,
    } = this.props;
    let fromGroupOption = {
      label,
      notRequired,
      validations: !isNil(validations) && Array.isArray(validations),
    };

    const optionList = options.map((option, index) => {
      const { defaultClassName, selectedClassName } = option;
      const _defaultClassName = defaultClassName || '';
      const _selectedClassName = selectedClassName || 'btn-info';
      return (
        <div className={`col-md-1 col-xs-2 nps-btn option-${index} px-0`} key={index}>
          <EnButton
            key={`option-${index}`}
            className={`${option.value === value ? _selectedClassName : _defaultClassName}`}
            disabled={option.disabled || false}
            onClick={this.onButtonClick.bind(this, option.value)}>
            {option.icon || ''}
          </EnButton>
          <p className="score no-padding" style={{marginBottom: '0px', textAlign: 'center'}}>{option.label || ''}</p>
          {option.label2 ? (<p className="no-padding">{option.label2 ? `${option.label2}` : ''}</p>) : ''}
        </div>
      );
    });
    return (
      <FormGroup containerClassName={containerClassName} {...fromGroupOption} >
        {optionList}
      </FormGroup>
    );
  }
}

export default EnRadioScore;
