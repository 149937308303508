import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import EnToggle from '../../components/form/EnToggle';
import FormGroup from '../../components/form/FormGroup';
import moment from 'moment';
import EnTextArea from '../../components/form/EnTextArea';
import Pagination from '../../components/common/Pagination';
import { isAllow } from '../../utils/permission';

export class AdminBlockPaymentContractors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      toggleEnabled: false,
      reasonValues: this.initialReasonValues(),
      reasonValuesText: {
        noAtk: "ยังไม่ส่งผล ATK",
        jobNotcompleted: "งานเก่ามีปัญหายังแก้ไขไม่จบ",
        quotationDelayed: "ส่งใบเสนอราคาช้า",
        showTextarea: "อื่นๆ โปรดระบุ",
        cancelBlockedPayment: "ยกเลิกระงับการจ่ายเงินช่าง"
      },
      reasonTextarea: '',
      isEditMode: false
    };
  }

  async componentDidMount() {
    await this.props.contractor.getContractor({
      id: this.state.id,
      isAdmin: true
    });
    await this.props.contractorblockedpayment.getBlockedPaymentContractorHistories(
      this.state.id, this.props.contractorblockedpayment.query
    )
    await this.props.contractorblockedpayment.getLatestBlockedPaymentContractorHistory(
      this.state.id
    );
    await this.setDefaultValueToggle();
    await this.setDefaultReasonValues();
  }

  async setDefaultValueToggle() {
    const latestHistory = this.props.contractorblockedpayment.toJS().lastestBlockedPayment;
    if (Array.isArray(latestHistory) && latestHistory.length > 0 && latestHistory[0].blocked_payment) {
      this.setState({
        toggleEnabled: true
      });
    }
  }

  async setDefaultReasonValues() {
    if (this.props.contractorblockedpayment.toJS().lastestBlockedPayment.length === 0) {
      return;
    }
    const latestHistoryReason = this.props.contractorblockedpayment.toJS().lastestBlockedPayment[0].reason;
    const historyOptionValues = latestHistoryReason.split(', ');
    const reasonValues = this.initialReasonValues();

    historyOptionValues.map((optionValue) => {
      if (optionValue === this.state.reasonValuesText.noAtk) {
        reasonValues.noAtk = true;
        this.setState({ reasonValues });
      } else if (optionValue === this.state.reasonValuesText.jobNotcompleted) {
        reasonValues.jobNotcompleted = true;
        this.setState({ reasonValues });
      } else if (optionValue === this.state.reasonValuesText.quotationDelayed) {
        reasonValues.quotationDelayed = true;
        this.setState({ reasonValues });
      } else if (historyOptionValues[0] !== this.state.reasonValuesText.cancelBlockedPayment) {
        reasonValues.showTextarea = true;
        this.setState({ reasonValues, reasonTextarea: historyOptionValues[historyOptionValues.length - 1] });
      }
    });
  }

  initialReasonValues() {
    return {
      noAtk: false,
      jobNotcompleted: false,
      quotationDelayed: false,
      showTextarea: false
    };
  }

  async onToggleBlockingContractors(event) {
    this.setState({ toggleEnabled: event });
  }

  onChangeValue(propertyName, e) {
    const reasonValues = this.state.reasonValues;
    this.setState({
      reasonValues: {
        ...reasonValues,
        [propertyName]: !this.state.reasonValues[propertyName]
      }
    });
  }

  onchangeReasonTextarea(e) {
    this.setState({reasonTextarea: e.target.value});
  }

  async onSave() {
    const blockedPaymentHistoryProps = {
      "contractor_id": this.state.id,
      "user": this.props.auth.getUserInfo().user_name ?
        this.props.auth.getUserInfo().user_name : this.props.auth.getUserInfo().email,
      "created_date": new Date(),
      "contractor_phone": this.props.contractor.toJS().info.phone
    };
    if (this.state.toggleEnabled) {
      blockedPaymentHistoryProps.blocked_payment = true;
      blockedPaymentHistoryProps.reason = this.blockingReasonMessageForApi();
      this.props.contractor.saveInfo("blockedPayment", true);
    }
    else {
      blockedPaymentHistoryProps.blocked_payment = false;
      blockedPaymentHistoryProps.reason = this.state.reasonValuesText.cancelBlockedPayment;
      this.props.contractor.saveInfo("blockedPayment", false);
    }
    await Promise.all([
      this.props.contractor.updateContractorInfo(this.state.id, 'blockedPayment'),
      this.props.contractorblockedpayment.createBlockedPaymentHistory(blockedPaymentHistoryProps)
    ]);
    window.location.reload();
  }

  async onPageClick(pageNum) {
    this.props.contractorblockedpayment.setQueryValue('page', pageNum);
    await this.props.contractorblockedpayment.getBlockedPaymentContractorHistories(
      this.state.id , this.props.contractorblockedpayment.query
    )
  }

  blockingReasonMessageForApi() {
    let messages = [];
    if (this.state.reasonValues.noAtk) {
      messages.push(this.state.reasonValuesText.noAtk);
    }
    if (this.state.reasonValues.jobNotcompleted) {
      messages.push(this.state.reasonValuesText.jobNotcompleted);
    }
    if (this.state.reasonValues.quotationDelayed) {
      messages.push(this.state.reasonValuesText.quotationDelayed);
    }
    if (this.state.reasonValues.showTextarea) {
      messages.push(this.state.reasonTextarea);
    }
    return messages.join(", ");
  }

  disabledButtonForCreatingBlocking() {
    if (this.state.reasonValues.showTextarea && this.state.reasonTextarea.length >= 5) {
      return false;
    }
    else if (
      this.state.reasonValues.noAtk ||
      this.state.reasonValues.jobNotcompleted ||
      this.state.reasonValues.quotationDelayed
    ) {
      return false;
    }
    return true;
  }

  render() {
    const {
      listBlockedPayment,
      pagination,
      lastestBlockedPayment
    } = this.props.contractorblockedpayment.toJS();

    const styleContainer= {
      padding: '10px 20px'
    }
    const styleToggleForm = {
      display: 'flex',
      gap: '15px',
    };
    const styleRadiosField = {
      display: 'flex',
      flexDirection: 'column',
      visibility: this.state.toggleEnabled ?
        "visible" : "hidden",
    }
    const styleReasonTextarea = {
      resize: "vertical",
      visibility: this.state.reasonValues.showTextarea && this.state.toggleEnabled ?
        "visible" : "hidden",
      marginBottom: "25px"
    }
    const styleBlockingContractorForm = {
      visibility: this.state.toggleEnabled ?
        "visible" : "hidden",
    }
    const styleSubmitButtons = {
      display: "flex",
      padding: "0 15px 0 0",
      justifyContent: "end",
      visibility: lastestBlockedPayment.length > 0 || this.state.toggleEnabled ?
        "visible" : "hidden",
    }

    const willBeDisabledWhenNotEditMode = lastestBlockedPayment.length > 0 && this.state.isEditMode === false;

    return (
      <div className={styleContainer}>
        {isAllow("EDIT_BLOCK_PAYMENT") && (<FormGroup>
          <div style={styleToggleForm} className="col-md-4">
            <EnToggle
              checked={this.state.toggleEnabled}
              disabled={willBeDisabledWhenNotEditMode}
              onChange={this.onToggleBlockingContractors.bind(this)}
            />
            <span className="form-label">ระงับการจ่ายเงิน</span>
          </div>
          <div className="col-md-8">
            <div className="col-md-3 p-0" style={styleBlockingContractorForm}>
              <div style={{fontWeight: 700}}>กรุณาระบุเหตุผล <span style={{color: 'red'}}> *</span></div>
            </div>
            <div className="col-md-9 p-0">
              <div style={styleRadiosField}>
                <label>
                  <input
                    type="radio"
                    value={this.state.reasonValuesText.noAtk}
                    disabled={willBeDisabledWhenNotEditMode}
                    checked={this.state.reasonValues.noAtk}
                    onClick={this.onChangeValue.bind(this, "noAtk")}
                    readOnly />&nbsp;&nbsp;{this.state.reasonValuesText.noAtk}
                </label>
                <label>
                  <input
                    type="radio"
                    value={this.state.reasonValuesText.jobNotcompleted}
                    disabled={willBeDisabledWhenNotEditMode}
                    checked={this.state.reasonValues.jobNotcompleted}
                    onClick={this.onChangeValue.bind(this, "jobNotcompleted")}
                    readOnly />&nbsp;&nbsp;{this.state.reasonValuesText.jobNotcompleted}
                </label>
                <label>
                  <input
                    type="radio"
                    value={this.state.reasonValuesText.quotationDelayed}
                    disabled={willBeDisabledWhenNotEditMode}
                    checked={this.state.reasonValues.quotationDelayed}
                    onClick={this.onChangeValue.bind(this, "quotationDelayed")}
                    readOnly />&nbsp;&nbsp;{this.state.reasonValuesText.quotationDelayed}
                </label>
                <label>
                  <input
                    type="radio"
                    value="showTextarea"
                    disabled={willBeDisabledWhenNotEditMode}
                    checked={this.state.reasonValues.showTextarea}
                    onClick={this.onChangeValue.bind(this, "showTextarea")}
                    readOnly />&nbsp;&nbsp;{this.state.reasonValuesText.showTextarea}
                </label>
              </div>
              {(
                <EnTextArea
                  id="reasonTextarea"
                  rows="3"
                  size="30"
                  placeholder=""
                  onChange={this.onchangeReasonTextarea.bind(this)}
                  disabled={willBeDisabledWhenNotEditMode}
                  value={this.state.reasonTextarea}
                  style={styleReasonTextarea}
                />
              )}
              <div style={styleSubmitButtons}>
                {
                  lastestBlockedPayment && lastestBlockedPayment.length > 0 && !this.state.isEditMode ? 
                    <button
                      className="btn btn-warning"
                      onClick={e => { 
                        e.preventDefault(); 
                        this.setState({ isEditMode: true }); 
                      }}
                    >
                      <span className="fa fa-floppy-o btn-icon" aria-hidden="true" />แก้ไข
                    </button>
                  :
                    <button className="btn btn-danger" title="ยกเลิก" onClick={e => { window.location.reload(); }} >
                      <span className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                    </button>
                }
                <button
                  className="btn btn-info"
                  onClick={e => { e.preventDefault(); this.onSave(); }}
                  disabled={
                    this.disabledButtonForCreatingBlocking() || willBeDisabledWhenNotEditMode
                  }
                >
                  <span className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                </button>
              </div>
            </div>
          </div>
        </FormGroup>)
        }
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-bordered table-hover has-pagination">
                  <thead>
                    <tr>
                      <th width="10%" className="text-center">ลำดับที่</th>
                      <th width="10%" className="text-center">เหตุผลระงับการจ่ายเงิน</th>
                      <th width="10%" className="text-center">วันที่ทำรายการ</th>
                      <th width="10%" className="text-center">ทำรายการโดย</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    listBlockedPayment.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{pagination.start_index + index + 1}</td>
                          <td className="text-center">{data.reason}</td>
                          <td className="text-center">{
                            data.created_date ?
                            moment(data.created_date).utcOffset(7).format('DD/MM/YYYY HH:mm:ss') + ' น.' :
                            ''
                          }</td>
                          <td className="text-center">{data.user}</td>
                        </tr>
                      );
                    })
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Pagination
                pagination={pagination}
                handlePageClick={this.onPageClick.bind(this)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('contractor', 'auth', 'contractorblockedpayment')(observer(AdminBlockPaymentContractors));
