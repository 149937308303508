import * as _ from 'lodash';
import React from 'react';
import moment from 'moment';
import dedent from 'dedent';
import datetime from '../../utils/datetime';

export const CustomCellWrapper = (obj, holidays, vacation) => {
  let childrenClass = (obj.children.props.className || '').replace('rbc-today', ''); // .replace('rbc-off-range', '');
  let childrenStyle = obj.children.props.style;
  let className = '';

  if (Array.isArray(vacation) && vacation.length) {
    let vac = vacation.find(v => datetime.isBetween(obj.value, v.start, v.end));
    if (vac) {
      className = `vacation-date-${vac.unavailable} ${childrenClass}`;
    }
  }

  if (Array.isArray(holidays) && holidays.length) {
    let hol = holidays.find(v => datetime.isBetween(obj.value, v.start, v.end));
    if (hol) {
      className = `holiday-date ${childrenClass}`;
    }
  }

  if (className) {
    return <div className={className} style={childrenStyle} />;
  } else {
    return <div className={childrenClass} style={childrenStyle} />;
  }
};

export const CustomCalendarEvent = (event, start, end, isSelected) => {
  let className = '';
  if (event && event.isVacation && event.unavailable === 0) {
    className = 'vacation-all-day-event';
  } else if (event && event.isVacation && (event.unavailable === 1 || event.unavailable === 2)) {
    className = 'vacation-half-day-event';
  } else if (event && event.isHoliday) {
    className = 'holiday-event';
  } else if (event && event.isJob && event.isAssigned) {
    className = 'job-assigned-event';
  } else if (event && event.isJob && !event.isAssigned) {
    className = 'job-event';
  }
  if (event && event.isJob &&
    // event.typeOfJob === 'I' &&
    (!event.isPaid)) {
    className = 'job-unconfirm-event';
  }

  if (event && event.isJob && event.isContractorJob) {
    className = 'contractor-job-event';
  }

  if (event && event.id) {
    className += ` ${event.id}`;
  }

  if (className) {
    return { className };
  } else {
    return {};
  }
};

export const CustomStartAccessor = (obj) => {
  if (obj.start instanceof Date) {
    return obj.start;
  }
  return moment(obj.end).toDate();
};

export const CustomEndAccessor = (obj) => {
  if (obj.isVacation || (obj.isJob && obj.available === 0)) {
    return moment(moment(obj.end).format('YYYY-MM-DDT23:59:59.000')).toDate();
  } else {
    if (obj.end instanceof Date) {
      return obj.end;
    }
    return moment(obj.end).toDate();
  }
};

export const CustomEventView = (event) => {
  let title = '';
  if (event.title && Array.isArray(event.title)) {
    title = _.join(event.title.filter(t => typeof t === 'string'), '\n');
  }
  return <div title={dedent`${title}`} id={`event_${(event.event.customer_email || '')}`}>{event.title}</div>;
};

export const CustomDateHeader = (obj, selectedDate, fnCallback) => {
  let clickMe = () => {
    if (fnCallback) {
      fnCallback(obj.date);
    }
  };

  let className = datetime.isEqual(selectedDate, obj.date) ? 'p4-date date-circle' : 'p4-date';
  let spanId = 'date' + obj.label;
  return <span className={className} id={spanId}><a onClick={clickMe}>{obj.label}</a></span>;
};
