import * as _ from 'lodash';
import qs from 'query-string';
import humps from 'humps';
import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';
import datetime from '../utils/datetime';
import common from '../utils/common';
import AuthStore from './AuthStore';
import ReactDom from 'react-dom';
import { isContractorEmailValid } from '../components/form/FormValidation';

export class EmployeeStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      list: [],
      info: this.initInfo(),
      pagination: {},
      query: {
        status: 'registered',
        q: '',
      },
    });
  }

  initInfo() {
    return {
      code: '',
      branch: [],
      firstnameTh: '',
      lastnameTh: '',
      firstnameEn: '',
      lastnameEn: '',
      nicknameTh: '',
      nicknameEng: '',
      channel: '',
      phone1: '',
      phone2: '',
      email: '',
      emailQchang: '',
      organization: '',
      position: '',
      carRegistration: '',
      isActive: true,
      isAdmin: false,
      isSuperadminEmail: false,
      isContactEmail: false,
    }
  };

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  async getAllEmployeeForChannel(channel) {
    try {
      const response = await http.get(`${config.api.sims}/v1/employee/channel?channel=${channel}`);
      const data = humps.camelizeKeys(response.data.data);
      this.list = data || [];
      return data
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }

  }

  async getEmployeeById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/employee/${id}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      const data = humps.camelizeKeys(response.data.data);
      this.info = data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async createEmployee() {
    this.isLoading = true;
    try {
      this.saveInfo('isActive', true);
      const info = { ...this.info };
      info.branch = info.branch ? info.branch.toJS() : []
      const response = await http.post(`${config.api.sims}/v1/employee`, info);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateEmployee(id) {
    this.isLoading = true;
    try {
      const info = { ...this.info };
      info.branch = info.branch ? info.branch.toJS() : []
      const response = await http.put(`${config.api.sims}/v1/employee/${id}`, info);

      if (response.status !== 200) {
        if (response.data.message) {
          throw new Error(`${response.data.message}`);
        } else {
          throw new Error('การบันทึกล้มเหลว');
        }
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async changeActiveToggle(index, id, value) {
    this.isLoading = true;
    try {
      const res = await http.put(`${config.api.sims}/v1/employee/${id}/active`, { is_active: value });
      if (res.status === 200 && res.data) {
        const data = humps.camelizeKeys(res.data.data);
        this.list = this.list.map(item => {
          if (item.id === data.id) {
            item = data;
          }
          return item;
        });
      } else {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteEmployee(id) {
    this.isLoading = true;
    try {
      const response = await http.delete(`${config.api.sims}/v1/employee/${id}`);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new EmployeeStore();
