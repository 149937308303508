import React from 'react';
import { observer, inject } from 'mobx-react';
import FormGroup from '../../../../components/form/FormGroup';
import EnToggle from '../../../../components/form/EnToggle';

export class ContractorGradeSection extends React.Component {
  onSelectContractorGrade(key, value) {
    this.props.product.saveContractorGrade(key, value);
  }

  render() {
    const { contractorGrade } = this.props;
    return (
      <div>
        <FormGroup containerClassName="col-md-12" label="เกรดช่างที่รับงานได้">
          <div className="row">
            <FormGroup containerClassName="col-md-2" label="A">
              <EnToggle checked={contractorGrade.a} onChange={this.onSelectContractorGrade.bind(this, 'a')} />
            </FormGroup>
            <FormGroup containerClassName="col-md-2" label="New">
              <EnToggle checked={contractorGrade.new} onChange={this.onSelectContractorGrade.bind(this, 'new')} />
            </FormGroup>
            <FormGroup containerClassName="col-md-2" label="B">
              <EnToggle checked={contractorGrade.b} onChange={this.onSelectContractorGrade.bind(this, 'b')} />
            </FormGroup>
            <FormGroup containerClassName="col-md-2" label="C">
              <EnToggle checked={contractorGrade.c} onChange={this.onSelectContractorGrade.bind(this, 'c')} />
            </FormGroup>
            <FormGroup containerClassName="col-md-2" label="D">
              <EnToggle checked={contractorGrade.d} onChange={this.onSelectContractorGrade.bind(this, 'd')} />
            </FormGroup>
          </div>
        </FormGroup>
      </div>
    );
  }
}

export default inject('product', 'sku')(observer(ContractorGradeSection));
