import React, { Component } from 'react';
import { DateRangePicker, DateRangePickerInputController } from 'react-dates';
import { START_DATE, END_DATE } from 'react-dates/constants';
import { addEventListener, removeEventListener } from 'consolidated-events';
import datetime from '../../utils/datetime';

export class OutsideClickHandler extends React.Component {
  constructor(...args) {
    super(...args);

    // this.onOutsideClick = this.onOutsideClick.bind(this);
    this.setChildNodeRef = this.setChildNodeRef.bind(this);
  }

  componentDidMount() {
    // `capture` flag is set to true so that a `stopPropagation` in the children
    // will not prevent all outside click handlers from firing - maja
    this.clickHandle = addEventListener(
      document,
      'click',
      this.onOutsideClick,
      { capture: true },
    );
  }

  componentWillUnmount() {
    if (this.clickHandle) { removeEventListener(this.clickHandle); }
  }

  // onOutsideClick(e) {
  //   const { onOutsideClick } = this.props;
  //   const { childNode } = this;
  //   const isDescendantOfRoot = childNode && childNode.contains(e.target);
  //   if (!isDescendantOfRoot) {
  //     onOutsideClick(e);
  //   }
  // }

  setChildNodeRef(ref) {
    this.childNode = ref;
  }

  render() {
    return (
      <div ref={this.setChildNodeRef}>
        {this.props.children}
      </div>
    );
  }
}

export class EnDateRangePickerInputController extends DateRangePickerInputController {
  getDateString(date) {
    return datetime.ConvertToBuddhistYear(date);
  }
}

export class EnDateRangePicker extends DateRangePicker {
  renderCalendarDay(day) {
    return (<div />);
  }
  render() {
    const {
      startDate,
      startDateId,
      startDatePlaceholderText,
      endDate,
      endDateId,
      endDatePlaceholderText,
      focusedInput,
      screenReaderInputMessage,
      showClearDates,
      showDefaultInputIcon,
      inputIconPosition,
      customInputIcon,
      customArrowIcon,
      customCloseIcon,
      disabled,
      required,
      readOnly,
      openDirection,
      phrases,
      isOutsideRange,
      minimumNights,
      withPortal,
      withFullScreenPortal,
      displayFormat,
      reopenPickerOnClearDates,
      keepOpenOnDateSelect,
      onDatesChange,
      onClose,
      isRTL,
    } = this.props;

    const { isDateRangePickerInputFocused } = this.state;

    const onOutsideClick = (!withPortal && !withFullScreenPortal) ? this.onOutsideClick : undefined;

    return (
      <div className="DateRangePicker">
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
          <EnDateRangePickerInputController
            startDate={startDate}
            startDateId={startDateId}
            startDatePlaceholderText={startDatePlaceholderText}
            isStartDateFocused={focusedInput === START_DATE}
            endDate={endDate}
            endDateId={endDateId}
            endDatePlaceholderText={endDatePlaceholderText}
            isEndDateFocused={focusedInput === END_DATE}
            displayFormat={displayFormat}
            showClearDates={showClearDates}
            showCaret={!withPortal && !withFullScreenPortal}
            showDefaultInputIcon={showDefaultInputIcon}
            inputIconPosition={inputIconPosition}
            customInputIcon={customInputIcon}
            customArrowIcon={customArrowIcon}
            customCloseIcon={customCloseIcon}
            disabled={disabled}
            required={required}
            readOnly={readOnly}
            openDirection={openDirection}
            reopenPickerOnClearDates={reopenPickerOnClearDates}
            keepOpenOnDateSelect={keepOpenOnDateSelect}
            isOutsideRange={isOutsideRange}
            minimumNights={minimumNights}
            withFullScreenPortal={withFullScreenPortal}
            onDatesChange={onDatesChange}
            onFocusChange={this.onDateRangePickerInputFocus}
            onArrowDown={this.onDayPickerFocus}
            onQuestionMark={this.showKeyboardShortcutsPanel}
            onClose={onClose}
            phrases={phrases}
            screenReaderMessage={screenReaderInputMessage}
            isFocused={isDateRangePickerInputFocused}
            isRTL={isRTL}
          />

          {this.maybeRenderDayPickerWithPortal()}
        </OutsideClickHandler>
      </div>
    );
  }
}

export class EnDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
    };
  }

  onDateChange(date) {
    this.setState({
      startDate: date.startDate,
      endDate: date.endDate,
    });

    if (this.props.onDateChange) {
      this.props.onDateChange(date.startDate, date.endDate);
    }
  }

  isDayBlocked(dateMoment) {
    if (this.props.isDayBlocked) {
      return this.props.isDayBlocked(dateMoment);
    } else {
      return false;
    }
  }

  isOutsideRange(dateMoment) {
    if (this.props.isOutsideRange) {
      return this.props.isOutsideRange(dateMoment);
    } else {
      return false;
    }
  }

  componentWillMount() {
    const val = {};
    const { startDate, endDate } = this.props;

    if (startDate) {
      val.startDate = startDate;
    }

    if (endDate) {
      val.endDate = endDate;
    }

    if (val) {
      this.setState({
        ...val,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    let val = {};
    if (nextProps.startDate) {
      val.startDate = nextProps.startDate;
    }

    if (nextProps.endDate) {
      val.endDate = nextProps.endDate;
    }

    if (Object.keys(val).length) {
      this.setState((prevState, props) => {
        return val;
      });
    }
  }

  render() {
    const { disabled, openDirection, minimumNights, startDatePlaceholderText, endDatePlaceholderText, withPortal, numberOfMonths } = this.props;
    return (
      <div className="en-daterangpicker">
        <EnDateRangePicker
          withPortal={withPortal}
          numberOfMonths={numberOfMonths || 2}
          renderMonth={date => datetime.ConvertToBuddhistYear(date, 'MMMM ')}
          hideKeyboardShortcutsPanel
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          onDatesChange={this.onDateChange.bind(this)}
          focusedInput={this.state.focusedInput}
          onFocusChange={focusedInput => this.setState({ focusedInput })}
          isDayBlocked={this.isDayBlocked.bind(this)}
          isOutsideRange={this.isOutsideRange.bind(this)}
          disabled={disabled}
          openDirection={openDirection}
          minimumNights={minimumNights}
          startDatePlaceholderText={startDatePlaceholderText}
          endDatePlaceholderText={endDatePlaceholderText}
        />
      </div>
    );
  }
}

export default EnDatePicker;
