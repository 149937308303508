import React from 'react';
import '../../assets/css/phone-pad.css';

class NumberDisplay extends React.Component {
  render() {
    const { value, size, placeHolder } = this.props;
    return <input type="tel" readOnly className="num-display" placeholder={placeHolder} value={value} size={size} />;
  }
}

export default NumberDisplay;
