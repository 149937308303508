import React from 'react';
import { observer, inject } from 'mobx-react';
import QuotationForm  from "../../components/quotation/QuotationForm"
import Installments from "../../components/quotation/Installments"
import Container from '../../layouts/Container';
import moment from 'moment';
import { EnTab } from '../../components/form/EnTab';
import { Tab } from 'react-bootstrap';
import queryString from 'query-string';
import _ from 'lodash';

export class AdminQuotation extends React.Component {
    constructor(props) {
        super(props);
        const parsed = queryString.parse(this.props.location.search);
        this.state = {
            disabledQuotationForm: undefined,
            disabledInstallmentForm: undefined,
            isFinalQuery: !!(parsed && parsed.isFinal && parsed.isFinal === 'true') || false,
            isFinalFromDB: false,
            showInstallmentTab: undefined,
            hasSecondQuotation: false
        };
        this.props.quotation.clearInfo();
    }

    async componentDidMount() {
        const { match, job: jobStore, quotation: quotationStore } = this.props
        const parsed = queryString.parse(this.props.location.search);
        await this.props.job.getJobsById(this.props.match.params.id);
        const job = this.props.job.toJS();;
        const data = await this.props.quotation.getOptionQuotation(job.info.jobCode)
        if (parsed.quotationNo) {
            const isSubTotalValid = await jobStore.validateSubTotal({ jobId: match.params.id, quotationNo: parsed.quotationNo });
            quotationStore.showTabInstallment = isSubTotalValid;
        }
        /*  START COMMENT
            กดแก้ไขใบเสนอราคาจากหน้า quotation management ควรดึง job.quotation ใบแรก (business logic 11 oct 2023)
            กดแก้ไขใบเสนอราคาจากงานติดตั้งควรดึง job.quotation ใบล่าสุด 
            ปล. หน้าแก้ไขใบเสนอราคา ถ้ากดจาก quotation management url จะมี param isFinal & draftNo ในการอ้างถึงการแก้ไขใบเสนอราคาฉบับร่าง
        */
        const isFirstQuotationFromQuotationManagement = parsed.isFinal === 'true' && _.get(job, 'info.quotation.length') > 1;
        const jobInfoQuotation = isFirstQuotationFromQuotationManagement ? _.first(job.info.quotation) : _.last(job.info.quotation);
        /* END COMMENT */

        const isNewOrDraft = parsed.create || parsed.isNewDraft || !job.info.quotation.length || parsed.isFinal === 'false';
        const isNotInstallmentAndNotPaid = !job.info.installment && this.getArePeriodsStatus(jobInfoQuotation, 'pending');
        const isNotInstallmentAndPaid = !job.info.installment && this.getArePeriodsStatus(jobInfoQuotation, 'paid');
        const isNotInstallmentAndSomePaid = !job.info.installment && !isNotInstallmentAndNotPaid && !isNotInstallmentAndPaid;
        const isInstallmentAndSomePaid = !!job.info.installment && this.getIsFirstPeriodPaid(_.first(data && data.sub_quotation));
        this.setState({
            disabledQuotationForm: !isNewOrDraft && (isNotInstallmentAndSomePaid || isNotInstallmentAndPaid || isInstallmentAndSomePaid),
            disabledInstallmentForm: isNotInstallmentAndPaid || isInstallmentAndSomePaid,
            showInstallmentTab: isNotInstallmentAndNotPaid || isNotInstallmentAndSomePaid || job.info.installment,
            hasSecondQuotation: _.get(job, 'info.quotation.length') > 1 
        });
    }

    getArePeriodsStatus(quotation, status) {
        if (quotation && Array.isArray(quotation.periods)) {
          const periodsStatusMatched = quotation.periods.every((period) => period.status === status);
          return periodsStatusMatched;
        }
        return false;
      }

    getIsFirstPeriodPaid(quotation) {
        const firstPeriodStatus = _.get(quotation, 'periods[0].status');
        if (firstPeriodStatus) {
            return firstPeriodStatus === 'paid';
        }
        return false;
    };

    async checkAndBlockInstallationDate(jobInfo) {
        if (jobInfo) {
            const instDate = moment(jobInfo.endDate).add(1, 'days').toDate();
            let instCriteria = {
                contractorId: jobInfo.surveyContractor,
                workTypeId: jobInfo.typeOfWork[0],
                startInstDate: instDate,
                endInstDate: instDate,
                jobType: 'I',
                available: 0,
            };
            const instResult = await this.props.job.getUnavailableDateForInstallation(instCriteria);
            if (instResult) {
                return {
                instContractors: instResult.contractors,
                unavailableInstDate: instResult.unavailableDate || [],
                }
            }
            return {
                instContractors: "",
                unavailableInstDate: [],
            }
        }
    }

    async initInstallment(index) {
        if (index === 1) {
            const job = this.props.job.toJS();
            const { quotationInfo } = this.props.quotation
            if(quotationInfo && quotationInfo.sub_quotation) {
                this.props.quotation.subQuotationsInfo = quotationInfo.sub_quotation
                this.props.quotation.optionsQuotation = quotationInfo.main_quotation[quotationInfo.main_quotation.length -1].items
                this.props.quotation.mainQuotation = quotationInfo.main_quotation[0]
            } else {
                const quotations = job.info.quotation
                if (quotations && quotations.length > 0) {
                    this.props.quotation.optionsQuotation = quotations[quotations.length -1].items
                    this.props.quotation.mainQuotation = quotations[quotations.length -1]
                } else if (quotationInfo.main_quotation && quotationInfo.main_quotation.length > 0) {
                    this.props.quotation.optionsQuotation = quotationInfo.main_quotation[0].items
                    this.props.quotation.mainQuotation = quotationInfo.main_quotation[0]
                }
            }
            const { unavailableInstDate, instContractors } = await this.checkAndBlockInstallationDate(job.info);
            this.props.quotation.calendarSubQuotation = { unavailableInstDate, instContractors } 
            this.props.quotation.handleOptionListQuotations()
        }
    }

    render() {
        const { disabledQuotationForm, disabledInstallmentForm, showInstallmentTab, hasSecondQuotation } = this.state;
        return (
            <Container isAdmin>
                <div className="card">
                    <div className="col-md-12">
                        <div className="card-header" data-background-color="orange">
                            <h4 className="title">แบบฟอร์มใบเสนอราคา</h4>
                            <p className="category">{''}</p>
                        </div>
                        <div className="card-content">
                        {
                            this.props.quotation.showTabInstallment && showInstallmentTab && !hasSecondQuotation ? 
                            <EnTab onClick={(index) => this.initInstallment(index)}>
                                <Tab title="ใบเสนอราคา">
                                    <QuotationForm 
                                        disabled={disabledQuotationForm}
                                        {...this.props}
                                    />
                                </Tab>
                                <Tab title="แบ่งงวดงาน">
                                    <Installments
                                        disabled={disabledInstallmentForm}
                                        {...this.props}/>
                                </Tab>
                            </EnTab> : 
                            <QuotationForm
                                disabled={disabledQuotationForm}
                                {...this.props}
                            />
                        }          
                        </div>
                    </div>
                </div> 
            </Container>
        )
    }
}

export default inject('job', 'quotation')(observer(AdminQuotation));