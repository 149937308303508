import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import EnButton from '../../components/form/EnButton';
import config from '../../config';
import { isAllow } from '../../utils/permission';

class AdminExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async exportContractors() {
    // await this.props.exportdata.exportContractors();
    this.props.history.push(`${config.publicUrl}/superadmin/download/contractor`);
  }
  async importTransfer() {
    // await this.props.exportdata.exportContractors();
    this.props.history.push(`${config.publicUrl}/superadmin/uploade/transfer_updated`);
  }
  async exportContractorVacations() {
    // await this.props.exportdata.exportContractorVacations();
    this.props.history.push(`${config.publicUrl}/superadmin/download/vacation`);
  }

  async exportJobs() {
    await this.props.exportdata.exportJobs();
  }
  onDownload() {
    this.props.history.push(`${config.publicUrl}/superadmin/download/job`);
  }

  // async exportOrders() {
  //   await this.props.exportdata.exportOrders();
  // }

  async exportTransfers() {
    // await this.props.exportdata.exportTransfers();
    this.props.history.push(`${config.publicUrl}/superadmin/download/transfer`);
  }
  async exportVaccinesHistory() {
    // await this.props.exportdata.exportTransfers();
    this.props.history.push(`${config.publicUrl}/superadmin/download/vaccines`);
  }

  async exportPromotionHistory() {
    // await this.props.exportdata.exportTransfers();
    this.props.history.push(`${config.publicUrl}/superadmin/download/promotions`);
  }

  async exportRetailCash() {
    this.props.history.push(`${config.publicUrl}/superadmin/download/retail_cash`);
  }

  async exportOtherExpense() {
    this.props.history.push(`${config.publicUrl}/superadmin/download/other_expense`);
  }

  render() {
    let exportdata = this.props.exportdata.toJS();

    return (
      <Container isAdmin>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ดาวน์โหลด</h4>
              </div>
              <div className="card-content">
                <div className="container-fluid">
                  <Loader show={exportdata.isLoading} />
                  <div className="row">
                    <div className="col-md-3 text-center">
                      <EnButton
                        className="btn-info"
                        onClick={this.exportContractors.bind(this)}
                        disabled={!isAllow('VIEW_CONTRACTORS_FILE_LISTS')}
                        >ข้อมูลช่าง</EnButton>
                    </div>
                    <div className="col-md-3 text-center">
                      <EnButton
                        className="btn-info"
                        onClick={this.exportContractorVacations.bind(this)}
                        disabled={!isAllow('VIEW_CONTRACTORS_VACATION_FILE_LISTS')}
                        >วันหยุดช่าง</EnButton>
                    </div>
                    <div className="col-md-3 text-center">
                      <EnButton className="btn-info"
                        onClick={this.onDownload.bind(this)}
                        disabled={!isAllow('VIEW_CONTRACTORS_SCHEDULES_LISTS')}
                        >คิวช่าง</EnButton>
                    </div>
                    {/* <div className="col-md-3 text-center">
                      <EnButton className="btn-info" onClick={this.exportOrders.bind(this)}>ดาวน์โหลดออร์เดอร์</EnButton>
                    </div> */}
                    <div className="col-md-3 text-center">
                      <EnButton
                        className="btn-info"
                        onClick={this.exportTransfers.bind(this)}
                        disabled={!isAllow('VIEW_UPLOAD_PAYMENT_LISTS')}
                        >รายงานการจ่ายเงิน</EnButton>
                    </div>
                     <div className="col-md-3 text-center">
                      <EnButton
                        className="btn-info"
                        onClick={this.exportVaccinesHistory.bind(this)}
                        disabled={!isAllow('VIEW_CONTRACTORS_PROOF_OF_VACCINATION_LISTS')}
                        >ประวัติวัคซีน</EnButton>
                    </div>
                     <div className="col-md-3 text-center">
                      <EnButton
                        className="btn-info"
                        onClick={this.exportPromotionHistory.bind(this)}
                        >ข้อมูลโปรโมชั่น</EnButton>
                    </div>
                     <div className="col-md-3 text-center">
                      <EnButton
                        className="btn-info"
                        onClick={this.exportRetailCash.bind(this)}
                        >รายการ Retail เงินสด</EnButton>
                    </div>
                    <div className="col-md-3 text-center">
                      <EnButton
                        className="btn-info"
                        onClick={this.exportOtherExpense.bind(this)}
                        disabled={!isAllow('VIEW_UPLOAD_PAYMENT_LISTS')}
                        >รายการหักค่าใช้จ่าย</EnButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">อัพโหลด</h4>
              </div>
              <div className="card-content">
                <div className="container-fluid">
                  <Loader show={exportdata.isLoading} />
                  <div className="row">
                    <div className="col-md-3 text-center">
                      <EnButton
                        className="btn-success"
                        onClick={this.importTransfer.bind(this)}
                        disabled={!isAllow('UPLOAD_PAYMENT')}
                        >รายการจ่ายเงิน</EnButton>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('exportdata')(observer(AdminExport));
