import React from 'react';
import { defaultProps, withState, compose } from 'recompose';
import './styles.css';

const Typeahead = compose(
  defaultProps({
    onChange: () => { },
    option: [],
    maxVisible: 10,
  }),
  withState('open', 'setOpen', false),
)((props) => (
  <div className="typeahead typeahead-input-wrap">
    <input id={props.id} onBlur={() => setTimeout(() => props.setOpen(false), 400)}
      disabled={props.disabled}
      onFocus={() => props.setOpen(true)}
      type="text" value={props.value}
      className="form-control"
      onChange={props.onChange} />
    {props.options.length && props.value.length ? <input
      onChange={() => null}
      value={props.options[0][props.filterOption]}
      type="text" className="typeahead-input-hint form-control" /> : null}

    {props.open && props.options.length && props.value.length ? <ul className="typeahead-selector" >
      {props.options
        .filter((item, i) => i < props.maxVisible)
        .map(
          (item, i) => (
            <div
              key={i}
              onClick={() => {
                props.onOptionSelected(item);
                props.setOpen(false);
              }} >{props.displayOption(item)}
            </div>))}
    </ul> : null}
  </div>
));

export default Typeahead;
