import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';

export class Model extends BaseStore {
  constructor() {
    super();
    this.observable({
      info: undefined,
    });
  }

  async getAllModel() {
    const res = await http.get(`${config.api.sims}/v1/models`);
    if (res && res.data && res.data.data) {
      const data = res.data.data;
      this.info = data;
      return data;
    }
  }
}

export default new Model();
