import React from 'react';

export class EnTextLabel extends React.Component {
  render() {
    let { label, value, icon, tailingButton } = this.props;

    let valueCol = '';
    if (tailingButton) {
      valueCol = (
        <div>
          <div className="col-md-4 col-xs-4">
            {value}
          </div>
          <div className="col-md-2 col-xs-2 no-padding-left">
            {tailingButton}
          </div>
        </div>
      );
    } else {
      valueCol = (
        <div className="col-md-6 col-xs-6">
          {value}
        </div>
      );
    }

    return (
      <div className="col-md-6 col-sm-6">
        <div className="row">
          <div className="col-md-2 col-xs-2 text-center">
            <i className={icon} />
          </div>
          <div className="col-md-4 col-xs-4">
            <label>{label}</label>
          </div>
          {valueCol}
        </div>
      </div>
    );
  }
}

export default EnTextLabel;
