import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import Container from '../../layouts/Container';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import { FormValidation, required } from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import EnButton from '../../components/form/EnButton';
import FormGroup from '../../components/form/FormGroup';
import Select from 'react-select';
// import DragSortableList from 'react-drag-sortable';
import config from '../../config';
import { isAllow } from '../../utils/permission';
import _ from 'lodash'

const timesOptions = [
  { value: '', label: 'เลือกช่วงเวลารับงาน' },
  { value: 1, label: 'ช่วงเช้า' },
  { value: 2, label: 'ช่วงบ่าย' },
  { value: 0, label: 'ทั้งวัน' },
];

export class TypeOfWorkForm extends React.Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    this.state = {
      id: id,
      isEdit: id && id.length,
      isNewTypeOfWorkGroup: false,
    };
  }

  async componentWillMount() {
    try {
      if (this.state.isEdit) {
        await this.props.typeofwork.getTypeOfWorkForEdit(this.state.id);
        this.props.typeofwork.clearData();
      } else {
        this.props.typeofwork.clearInfo();
        this.props.typeofwork.clearData();
        // const auth = this.props.auth.getUserInfo();
        // this.props.typeofwork.info.channel = auth.channel ? auth.channel.channelName : '';
        const channel = this.props.auth.getChannelName() || '';
        this.props.typeofwork.info.channel = channel;
      }
      await this.props.typeofwork.getGroupTypeOfWorkFromApp();
      await this.props.channel.getAllChannel();
    } catch (err) {
      this.noti.error(err.message);
    }
  }

  async componentDidMount() {
    this.checkPermission();
  }

  checkPermission() {
    if (!isAllow("VIEW_A_LIST_OF_WORK_TYPE")) {
      this.props.history.push(`${config.publicUrl}`);
    }
  }

  onChangeName(e) {
    this.props.typeofwork.saveName(e.target.value);
  }

  getChannelOptions = () => {
    const channel = this.props.channel.toJS().info;
    return (channel || []).map((c, index) => {
      return {
        label: c.channel_name,
        value: c.channel_name,
      };
    });
  };

  getGroupTypeOfWork = () => {
    const groupTypeOfWork = this.props.typeofwork.toJS().itemGroupTypeOfWork;
    return (groupTypeOfWork || []).map((g, index) => {
      return {
        label: g.work_type_name,
        value: g.work_type_name,
      };
    });
  }

  findCodeGroupTypeOfWork = () => {
    const { info, itemGroupTypeOfWork } = this.props.typeofwork;
    const groupCode = itemGroupTypeOfWork.find((i) => i.work_type_name === info.group_name);
    const data = groupCode ? groupCode.work_type_code : '';
    this.props.typeofwork.saveInfo('code_group', data);
  }

  onSelectChannel = (option) => {
    this.props.typeofwork.saveInfo('channel', _.get(option, 'value', null));
  }

  onSaveGroupTypeOfWork = (option) => {
    this.props.typeofwork.saveInfo('group_name', _.get(option, 'value', null));
  }

  onChangeGroupTypeOfWork(e) {
    this.props.typeofwork.saveInfo('group_name', e.target.value);
  }
  // changeJobRatio(e) {
  //   this.props.typeofwork.saveData('job_ratio', e.target.value);
  //   this.props.typeofwork.saveInfo('job_ratio', e.target.value);
  // }

  changeInfo(key, e) {
    this.props.typeofwork.saveData(key, e.target.value);
    this.props.typeofwork.saveInfo(key, e.target.value);
  }

  isValid() {
    const typeofwork = this.props.typeofwork.toJS();
    const info = typeofwork.info;
    return info.time.length && info.name && info.name.length;
  }

  isAddTimeValid() {
    const { timeText, value } = this.props.typeofwork.toJS().data;
    // console.log('time text: ', timeText);
    // console.log('value: ', value);
    const timeTextNotBlank = (timeText && timeText.trim().length);
    const timeShouldSelect = (value && value.length);
    return timeTextNotBlank && timeShouldSelect;
  }

  addTime() {
    const { status, label, value } = this.props.typeofwork.addTime();
    if (status) {
      swal.fire({
        title: 'เพิ่มช่วงเวลาเรียบร้อย',
        icon: 'success',
        html: '<small style="color: red">กรุณาปุ่มกดบันทึกด้านล่างเพื่อบันทึกข้อมูล</small>',
      });
      this.props.typeofwork.clearData();
    } else {
      let description = '';
      description += label ? `${label}<br>` : '';
      description += value ? `${value}` : '';
      swal.fire('กรุณากรอกข้อมูลให้ครบถ้วน', description, 'error');
    }
  }

  toggleNewTypeOfWorkGroup() {
    this.props.typeofwork.saveInfo('group_name', '');
    this.setState({
      isNewTypeOfWorkGroup: !this.state.isNewTypeOfWorkGroup,
    });
  }

  addSurveyText() {
    const { status, message } = this.props.typeofwork.addSurvey();
    if (status) {
      swal.fire({
        title: 'เพิ่มหัวข้อสำรวจเรียบร้อย',
        icon: 'success',
        html: '<small style="color: red">กรุณาปุ่มกดบันทึกด้านล่างเพื่อบันทึกข้อมูล</small>',
      });
      this.props.typeofwork.clearData();
    } else {
      swal.fire('กรุณากรอกข้อมูลให้ครบถ้วน', message, 'error');
    }
  }

  onSort(sortedList) {
    console.log(sortedList);
    // this.props.productManager.updateSeqProducts(sortedList);
  }

  async onSubmit() {
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit
        ? 'กรุณายืนยันการแก้ไขประเภทงาน'
        : 'กรุณายืนยันการเพิ่มประเภทงานใหม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          this.findCodeGroupTypeOfWork();
          if (this.state.isEdit) {
            await this.props.typeofwork.updateTypeOfWork(this.state.id);
          } else {
            await this.props.typeofwork.createTypeOfWork();
          }
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      this.props.typeofwork.clearInfo();

      setTimeout(() => {
        this.onCancel();
      }, 500);
    }
  }

  onCancel() {
    if (isAllow("EDIT_A_WORK_TYPE_INFORMATION")) {
      this.props.history.push(`${config.publicUrl}/superadmin/typeofwork`);
    } else {
      // Go to main menu
      this.props.history.push(`${config.publicUrl}`);
    }
  }

  onDelete(index, e) {
    e.preventDefault();
    this.props.typeofwork.deleteTime(index);
  }

  onSurveyDelete(index, e) {
    e.preventDefault();
    this.props.typeofwork.deleteSurvey(index);
  }

  onTimeLabelChanged(e) {
    this.props.typeofwork.saveData('timeText', e.target.value);
  }

  onSurveyTextChanged(e) {
    this.props.typeofwork.saveData('surveyText', e.target.value);
  }

  getAvailableTime(value) {
    let result = '';
    switch (value) {
      case '0':
        result = 'ทั้งวัน';
        break;
      case '1':
        result = 'เช้า';
        break;
      case '2':
        result = 'บ่าย';
        break;
      default:
        break;
    }
    return result;
  }

  changeTime(e) {
    this.props.typeofwork.saveData('label', e.label.toString());
    this.props.typeofwork.saveData('value', e.value.toString());
  }

  getTableRows(items) {
    return items.map((item, index) => {
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td className="text-center">{item.label}</td>
          <td className="text-center">{this.getAvailableTime(item.value)}</td>
          <td className="text-center">
            <button
              className="btn btn-xs btn-danger"
              title="ลบ"
              data-tip="ลบ"
              onClick={this.onDelete.bind(this, index)}
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  getTableSurveyRows(items) {
    const surveyRows = items.map((item, index) => {
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td>{item}</td>
          <td className="text-center">
            <button
              className="btn btn-xs btn-danger"
              title="ลบ"
              data-tip="ลบ"
              onClick={this.onSurveyDelete.bind(this, index)}
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-bordered table-hover has-pagination">
              <thead>
                <tr>
                  <th width="10%" className="text-center">
                    ลำดับที่
                  </th>
                  <th width="80%" className="text-center">
                    หัวข้อสำรวจ
                  </th>
                  <th width="10%" className="text-center">
                    ลบ
                  </th>
                </tr>
              </thead>
              <tbody>{surveyRows}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const typeOfWorkJs = this.props.typeofwork.toJS();
    const typeofwork = typeOfWorkJs.info;
    const data = typeOfWorkJs.data;
    const rows = this.getTableRows(typeofwork.time);
    const surveyRows = (typeofwork.surveys && typeofwork.surveys.length) ? this.getTableSurveyRows(typeofwork.surveys) : '';
    const title = this.state.isEdit ? 'แก้ไขประเภทงาน' : 'เพิ่มประเภทงานใหม่';
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <Notification
            ref={ref => {
              this.noti = ref;
            }}
          />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{title}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-12"
                      type="text"
                      label="ชื่อประเภทงาน"
                      value={typeofwork.name}
                      onChange={this.onChangeName.bind(this)}
                    />
                  </div>
                  {
                    (!this.state.isNewTypeOfWorkGroup) &&
                    (
                      <div className="row">
                        <FormGroup label="กลุ่มประเภทงาน" containerClassName="col-md-6">
                          <Select
                            searchable={true}
                            clearable={false}
                            onChange={this.onSaveGroupTypeOfWork}
                            options={this.getGroupTypeOfWork()}
                            value={typeofwork.group_name} />
                        </FormGroup>
                        <div className="form-group col-md-1">
                          <EnButton
                            style={{ marginTop: '27px' }}
                            className="btn btn-success"
                            onClick={this.toggleNewTypeOfWorkGroup.bind(this)}
                            disabled={isAllow("CREATE_A_GROUP_WORK_TYPE") ? false : true}
                          >
                            <i
                              className="fa fa-plus btn-icon"
                              aria-hidden="true"
                            />
                            สร้างใหม่
                          </EnButton>
                        </div>
                      </div>
                    )
                  }
                  {
                    (this.state.isNewTypeOfWorkGroup) &&
                    (
                      <div className="row">
                        <EnTextFormGroup
                          containerClassName="col-md-6"
                          type="text"
                          label="ชื่อกลุ่มประเภทงานใหม่"
                          value={typeofwork.group_name}
                          onChange={this.onChangeGroupTypeOfWork.bind(this)}
                        />
                        <div className="form-group col-md-1">
                          <EnButton
                            style={{ marginTop: '27px' }}
                            className="btn btn-danger"
                            onClick={this.toggleNewTypeOfWorkGroup.bind(this)}
                          >
                            <i
                              className="fa fa-reply btn-icon"
                              aria-hidden="true"
                            />
                            เลือกกลุ่มประเภทงานเดิม
                          </EnButton>
                        </div>
                      </div>
                    )
                  }
                  <div className="row">
                    <FormGroup label="ช่องทางการขาย" containerClassName="col-md-6">
                      <Select
                        searchable={true}
                        clearable={false}
                        onChange={this.onSelectChannel}
                        options={this.getChannelOptions()}
                        value={typeofwork.channel} />
                    </FormGroup>
                  </div>
                  <div className="duration">
                    <div className="header-duration">
                      <span>เพิ่มช่วงเวลา</span>
                    </div>
                    <div className="duration-form">
                      <div className="row">
                        <EnTextFormGroup
                          containerClassName="col-md-5"
                          type="text"
                          label="ข้อความช่วงเวลา"
                          value={data.timeText}
                          onChange={this.onTimeLabelChanged.bind(this)}
                        />
                        <FormGroup
                          containerClassName="col-md-5"
                          label="ช่วงเวลารับงาน *"
                        >
                          <Select
                            multi={false}
                            searchable={false}
                            clearable={false}
                            value={data}
                            options={timesOptions}
                            onChange={this.changeTime.bind(this)}
                          />
                        </FormGroup>
                        <div className="form-group col-md-1">
                          <EnButton
                            style={{ marginTop: '27px' }}
                            className="btn btn-success"
                            disabled={!this.isAddTimeValid()}
                            onClick={this.addTime.bind(this)}
                          >
                            <i
                              className="fa fa-plus btn-icon"
                              aria-hidden="true"
                            />
                            เพิ่ม
                          </EnButton>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover has-pagination">
                          <thead>
                            <tr>
                              <th width="10%" className="text-center">
                                ลำดับที่
                              </th>
                              <th width="40%" className="text-center">
                                ข้อความช่วงเวลา
                              </th>
                              <th width="40%" className="text-center">
                                ช่วงเวลารับงาน
                              </th>
                              <th width="10%" className="text-center">
                                ลบ
                              </th>
                            </tr>
                          </thead>
                          <tbody>{rows}</tbody>
                        </table>
                        {/* <DragSortableList
                          type="vertical"
                          items={list}
                          moveTransitionDuration={0.3}
                          onSort={this.onSort.bind(this)}
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="duration">
                        <div className="header-duration">
                          <span>จำนวนงานสูงสุดที่ช่าง 1 คนจะได้รับต่อวัน</span>
                        </div>
                        <div className="duration-form">
                          <div className="row">
                            <EnTextFormGroup
                              containerClassName="col-md-5"
                              type="number"
                              label="เช้า"
                              value={typeofwork.max_job_per_contractor_morning}
                              onChange={this.changeInfo.bind(this, 'max_job_per_contractor_morning')}
                            />
                            <EnTextFormGroup
                              containerClassName="col-md-5"
                              type="number"
                              label="บ่าย"
                              value={typeofwork.max_job_per_contractor_afternoon}
                              onChange={this.changeInfo.bind(this, 'max_job_per_contractor_afternoon')}
                            />
                          </div>
                        </div>
                      </div>
                    </div> </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="duration">
                        <div className="header-duration">
                          <span>จำนวนงานสูงสุดที่รับได้ต่อวัน</span>
                        </div>
                        <div className="duration-form">
                          <div className="row">
                            <EnTextFormGroup
                              containerClassName="col-md-5"
                              type="number"
                              label="เช้า"
                              value={typeofwork.max_job_per_day_morning}
                              onChange={this.changeInfo.bind(this, 'max_job_per_day_morning')}
                            />
                            <EnTextFormGroup
                              containerClassName="col-md-5"
                              type="number"
                              label="บ่าย"
                              value={typeofwork.max_job_per_day_afternoon}
                              onChange={this.changeInfo.bind(this, 'max_job_per_day_afternoon')}
                            />
                          </div>
                        </div>
                      </div>
                    </div> </div>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-5"
                      type="number"
                      label="คะแนนของงาน (สำหรับ automatching)"
                      value={typeofwork.job_ratio}
                      onChange={this.changeInfo.bind(this, 'job_ratio')}
                      validations={[required]}
                    />
                  </div>
                  <div className="survey">
                    <div className="header-survey">
                      <span>รายการที่ต้องสำรวจ</span>
                    </div>
                    <div className="survey-form">
                      <div className="row">
                        <EnTextFormGroup
                          containerClassName="col-md-10"
                          type="text"
                          label="หัวข้อสำรวจ"
                          value={data.surveyText}
                          onChange={this.onSurveyTextChanged.bind(this)}
                        />
                        <div className="form-group col-md-2">
                          <EnButton
                            style={{ marginTop: '27px' }}
                            className="btn btn-success"
                            onClick={this.addSurveyText.bind(this)}
                            disabled={data.surveyText === ''}
                          >
                            <i
                              className="fa fa-plus btn-icon"
                              aria-hidden="true"
                            />
                            เพิ่ม
                          </EnButton>
                        </div>
                      </div>
                    </div>
                  </div>
                  {surveyRows}
                  <div className="row">
                    <EnButton
                      className="btn-info pull-right"
                      disabled={!this.isValid()}
                      onClick={this.onSubmit.bind(this)}
                    >
                      <i
                        className="fa fa-floppy-o btn-icon"
                        aria-hidden="true"
                      />
                      บันทึก
                    </EnButton>
                    <EnButton
                      className="btn-danger pull-right"
                      onClick={this.onCancel.bind(this)}
                    >
                      <i className="fa fa-times btn-icon" aria-hidden="true" />
                      ยกเลิก
                    </EnButton>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'typeofwork', 'channel')(observer(TypeOfWorkForm));
