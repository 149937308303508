import React, { useEffect, useRef } from 'react';
import { isNil } from 'lodash';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from 'ckeditor5-build-full';

import FormGroup from '../FormGroup';

import { ColorPlate } from './plugins/color';
// import { HighlightOptions } from './plugins/highlight';
import { EmojiPlugin } from './plugins/emoji';
import { TabKeyPlugin } from './plugins/tab';
import { UploadAdapter } from './plugins/upload';

import './plugins/highlight.css';
import config from '../../../config';

class CustomEditor extends FullEditor {
  constructor() {
    super();
  }

  static get builtinPlugins() {
    return [...FullEditor.builtinPlugins, EmojiPlugin, TabKeyPlugin];
  }

  static get defaultConfig() {
    return {
      ...FullEditor.defaultConfig,

      toolbar: {
        items: [
          'removeFormat',
          '|',
          'heading',
          'fontFamily',
          'fontSize',
          'fontColor',
          'fontBackgroundColor',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          '|',
          'subscript',
          'superscript',
          '|',
          'numberedList',
          'bulletedList',
          '|',
          'alignment',
          'indent',
          'outdent',
          '|',
          'link',
          'insertTable',
          'specialCharacters',
          "imageUpload",
          "mediaEmbed",
          "htmlEmbed",
          '|',
          // 'highlight',
          'code',
          'blockQuote',
          'horizontalLine',
          'pageBreak',
          '|',
          'undo',
          'redo',
        ],
      },

      // custom configuration for the link plugin
      link: {
        addTargetToExternalLinks: true,
        defaultProtocol: 'https://',
        decorators: [
          {
            mode: 'manual',
            label: 'Open in a new tab',
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer',
            },
          },
        ],
      },

      // custom configuration for the font size plugin
      fontSize: {
        options: [
          8,
          10,
          12,
          14,
          16,
          18,
          20,
          24,
          28,
          36,
          40,
          48
        ],
      },

      // Apply custom color palette to font colors
      fontColor: {
        colors: ColorPlate,
        column: 5,
      },
      // Apply custom color palette to font background colors
      fontBackgroundColor: {
        colors: ColorPlate,
        column: 5,
      },

      // Add custom emojis
      specialCharacters: {
        options: ['emoji'],
      },

      // Apply custom color palette to highlight text colors
      // highlight: {
      //   options: HighlightOptions,
      // },

      // Apply custom color palette to font background colors
      table: {
        ...FullEditor.defaultConfig.table,
        // Set the palettes for tables.
        tableProperties: {
          borderColors: ColorPlate,
          backgroundColors: ColorPlate,
        },

        // Set the palettes for table cells.
        tableCellProperties: {
          borderColors: ColorPlate,
          backgroundColors: ColorPlate,
        },
      },
    };
  }
}

const EnCKEditor = ({
  label = '',
  disabled = false,
  validations,
  containerClassName,
  type = '',
  value,
  onChange,
}) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.isReadOnly = disabled;
    }
  }, [disabled]);

  const handleReady = (editor) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      const uploadUrl = `${config.api.sims}/v1/editor/upload?path=${type}`;
      return new UploadAdapter(loader, uploadUrl);
    };

    editor.isReadOnly = disabled;
    editorRef.current = editor;
  };

  const handleChange = (event, editor) => {
    if (onChange) {
      onChange(event, editor);
    }
  };

  let options = {
    label,
    validations: !isNil(validations) && Array.isArray(validations),
  };

  return (
    <FormGroup containerClassName={containerClassName} {...options}>
      <CKEditor
        editor={CustomEditor}
        data={value}
        onChange={handleChange}
        onReady={handleReady} // Pass the function to handle the editor instance when ready
      />
    </FormGroup>
  );
};

export default EnCKEditor;
