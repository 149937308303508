import React from "react";
import { observer, inject } from 'mobx-react';
import firebase from "../../utils/firebase";
import { getAuth, signInWithPopup, SAMLAuthProvider } from "firebase/auth";
import history from '../../utils/history';
import Notification from '../../components/common/Notification';
import config from '../../config';

const auth = getAuth(firebase);
const provider = new SAMLAuthProvider(config.service.samlProvider);

class Login extends React.Component {
  onClick(event) {
    event.preventDefault();
    auth.useDeviceLanguage();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        const displayName = user && user.providerData && user.providerData[0] && user.providerData[0].displayName
        const emailFromProvider = user.providerData.find((data) => data.email) || {};

        const url = await this.props.auth.authenticateWithGoogle({
          token: user.accessToken,
          email: user.email || (emailFromProvider.email ? emailFromProvider.email : ""),
          displayName,
        });
        if (url) {
          history.replace(url);
        }
      })
      .catch((error) => {
        console.log('signInWithPopup error====>', error)
        this.noti.error('เกิดความผิดพลาดในการเข้าสู่ระบบ!');
      });
  }

  render() {
    return (
      <div>
        <Notification ref={e => { this.noti = e; }} />
        <button
          type="button"
          className="btn btn-ligth btn-block btn-space"
          onClick={this.onClick.bind(this)}
        >
          <div className="flex-row">
            <img
              alt=""
              src={require("../../assets/logo/google-logo.svg")}
              className="logo-center"
            />
            <div className="title-text-center">เฉพาะผู้ดูแลระบบคิวช่าง</div>
          </div>
        </button>
      </div>
    );
  }
}
export default inject('auth')(observer(Login));
