// src/components/autocomplete/index.js
import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';

const Autocomplete = ({
  options = [],
  type = 'input',
  field = '',
  disabled = false,
  placeholder = '',
  value: valueProps,
  onChange: onChangeProps,
  onSelect: onSelectProps,
}) => {
  const [value, setValue] = useState(valueProps);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    if (value !== valueProps) {
      setValue(valueProps);
    }
  }, [valueProps, value]);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    if (onChangeProps) {
      onChangeProps(newValue);
    }
  }

  const onSuggestionSelected = (event, { suggestion }) => {
    setValue(suggestion[field]);
    if (onSelectProps) {
      onSelectProps(suggestion);
    }
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    if (value && value.length >= 3) {
      setSuggestions(getSuggestions(value));
    }
  }

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : options.filter((item) => item[field].toLowerCase().includes(inputValue));
  };

  const getSuggestionValue = (suggestion) => suggestion[field];

  const renderSuggestion = (suggestion) => <div>{suggestion[field]}</div>;

  const inputProps = {
    placeholder,
    value,
    onChange: onChange,
    disabled,
    rows: type === 'textarea' ? 3 : 1, // Only apply rows if it's a textarea
    className: type === 'textarea' ? 'autosuggest-textarea' : 'autosuggest-input',
  };

  return (
    <div className="autocomplete-container">
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={onSuggestionSelected}
        inputProps={inputProps}
        theme={{
          container: 'autosuggest-container',
          input: type === 'textarea' ? 'autosuggest-textarea' : 'autosuggest-input',
          suggestionsContainer: !suggestions.length ? 'display-none' : `autosuggest-suggestions-container-${type}`,
          suggestion: 'autosuggest-suggestion',
          suggestionHighlighted: 'autosuggest-suggestion--highlighted',
        }}
        renderInputComponent={(inputProps) => {
          return type === 'textarea' ? <textarea {...inputProps} /> : <input {...inputProps} />;
        }}
      />
    </div>
  );
};

export default Autocomplete;