import * as _ from 'lodash';
import React from 'react';
import Swal from 'sweetalert2';

import { observer, inject } from 'mobx-react';
import { FormValidation, required } from '../../components/form/FormValidation';
import FormGroup from '../../components/form/FormGroup';
import EnButton from '../../components/form/EnButton';
import EnDropDown from '../../components/form/EnDropDown';
import { EnTagSelectValidation } from '../../components/form/EnTagSelect';
import Container from '../../layouts/Container';
import authStore from '../../stores/AuthStore';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import config from '../../config';
import { isAllow } from '../../utils/permission';

export class StoreArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areas: [],
      districts: [],
      oldAreas: [],
      isEdit: false,
    };
  }

  clickSubmitNoRedirect(index) {
    this.saveArea(this.state.areas, index, 'submit');
  }

  async saveArea(area, index, action) {
    try {
      this.props.admin.saveStoreArea(area);
      let store = authStore.getStore();
      let id = '';
      if (store) {
        id = store._id;
      }
      await this.props.admin.updateAdminStore(id);
      this.props.auth.setStoreArea(this.props.admin.toJS().storeArea);
      this.setState({ oldAreas: this.state.areas });
      this.noti.success('แก้ไขข้อมูลเรียบร้อย', 'สำเร็จ');
      setTimeout(() => {
        if (index >= 0) {
          if (action === 'submit') {
            this.onRowClick(index);
          }
        } else {
          this.props.history.push(`${config.publicUrl}/admin/calendar`);
        }
      }, 1000);
    } catch (error) {
      this.noti.error(`${error.message}`, 'ล้มเหลว');
    }
  }

  validation() {
    let check = this.state.areas.map(d => {
      return !(_.indexOf(d.districtCode, '') >= 0 || (_.isEmpty(d.districtCode)));
    });
    let isValid = (_.indexOf(check, false)) < 0;
    return !!isValid;
  }

  async componentWillMount() {
    try {
      const pathname = this.props.history.location.pathname;
      if (pathname.includes('/admin/store/area') && !isAllow('VIEW_LIST_OF_SERVICE_AREA')) {
        this.props.history.push(`${config.publicUrl}`);
      }
      const store = authStore.getStore();
      if (store) {
        await this.props.admin.getStoreById(store._id);
      }

      await this.props.misc.getAllProvince();
      const miscStore = this.props.misc.toJS();
      let item = [];
      if (Array.isArray(store.area) && store.area.length) {
        item = store.area.map((store) => {
          return {
            provinceCode: store.province_id,
            districtCode: store.district_id,
            provinceName: (miscStore.miscData.province.find(p => p.code === store.province_id) || {}).name,
          };
        });
        this.setState({
          areas: _.orderBy(item, ['provinceCode'], ['asc']),
          oldAreas: _.orderBy(item, ['provinceCode'], ['asc']),
        });
      } else {
        this.setState({
          areas: [
            {
              provinceCode: '10',
              districtCode: [],
              provinceName: miscStore.miscData.province.find(p => p.code === '10').name,
            },
          ],
        });
      }
    } catch (error) {
      throw error;
    }
  }

  async onProvinceChanged(selectedItem, e) {
    const miscStore = this.props.misc.toJS();
    let id = e.target.value;
    let areas = _.cloneDeep(this.state.areas);
    let item = areas.find(i => i.provinceCode === selectedItem.provinceCode);
    if (!_.isNil(item) && !_.isEmpty(item)) {
      item.provinceCode = id;
      item.districtCode = [];
      item.provinceName = miscStore.miscData.province.find(p => p.code === id).name;
    }
    this.setState({
      areas,
    });
  }

  onDistrictChanged(selectedItem, districtCode, e) {
    let items = _.cloneDeep(this.state.areas);
    if (e.indexOf('ALL') >= 0) {
      let district = districtCode.map(d => d.value);
      let item = items.find(i => i.provinceCode === selectedItem.provinceCode);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item.districtCode = district;
      }
    } else {
      let district = e.split(',');
      let item = items.find(i => i.provinceCode === selectedItem.provinceCode);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item.districtCode = district;
      }
    }
    this.setState({
      areas: items,
    });
  }

  getNotSelectedList(selectedId) {
    let miscStore = this.props.misc.toJS();
    let province = miscStore.miscData.province;
    return province.filter(c => {
      if (selectedId && c.code === selectedId) {
        return true;
      } else {
        let item = this.state.areas.find(i => i.provinceCode === c.code);
        return _.isNil(item) || _.isEmpty(item);
      }
    });
  }

  onCancel(item) {
    this.setState({ isEdit: false });
    if (this.state.oldAreas.length !== this.state.areas.length) {
      let areas = _.cloneDeep(this.state.areas);
      let districtData = _.cloneDeep(this.state.districts);
      let result = districtData.filter(d => d.provinceCode !== item.provinceCode);
      let data = areas.filter(d => d.provinceCode !== item.provinceCode);
      this.setState({
        areas: data,
        districts: result,
      });
    } else {
      const areas = this.state.areas;
      if (Array.isArray(areas) && areas.length) {
        let area = areas[item.key];
        area.districtCode = this.state.oldAreas[item.key].districtCode;
        area.isEdit = !area.isEdit;
        this.setState({
          area,
        });
      }
    }
  }

  onRowClick(index) {
    const areas = this.state.areas;
    if (Array.isArray(areas) && areas.length) {
      const area = areas[index];
      const isEdit = !this.state.isEdit;
      area.isEdit = !area.isEdit;
      this.setState({
        area,
        isEdit,
      });
    }
  }

  genRow = () => {
    const areas = this.state.areas;
    let disable = this.state.isEdit;
    return areas.map((item, index) => {
      return (
        <tr key={`district ${index}`}>
          {
            (item.isEdit) ? (<td> {this.showItem(item, index)} </td>) : (<td>{item.provinceName}</td>)
          }
          <td className="text-center">
            {
              (item.isEdit)
                ? <div>
                  <EnButton validations={_.indexOf(item.districtCode, '') >= 0 ? [required] : ''} className="btn btn-info" disabled={!this.validation()} onClick={this.clickSubmitNoRedirect.bind(this, index)}>
                    <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                  </EnButton>
                  <EnButton className="btn btn-danger" onClick={(e) => { e.preventDefault(); this.onCancel({ key: index, ...item }); }}>
                    <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                  </EnButton>
                </div>
                : <div>
                  <EnButton title="แก้ไข" disabled={disable} className="btn btn-xs" onClick={(e) => { e.preventDefault(); this.onRowClick(index); }}>
                    <i className="fa fa-pencil" aria-hidden="true" />
                  </EnButton>
                  {
                    this.state.areas.length > 1 &&
                    <EnButton title="ลบ" disabled={disable} className="btn btn-xs btn-danger" onClick={this.deleteItems.bind(this, { key: index, ...item })}>
                      <i className="fa fa-trash" aria-hidden="true" />
                    </EnButton>
                  }
                </div>
            }
          </td>
        </tr >
      );
    });
  }

  async addNewItem() {
    let areas = _.cloneDeep(this.state.areas);
    const miscStore = this.props.misc.toJS();
    if (this.getNotSelectedList().length > 0) {
      areas = [{
        provinceCode: this.getNotSelectedList()[0].code,
        districtCode: [],
        provinceName: miscStore.miscData.province.find(p => p.code === this.getNotSelectedList()[0].code).name,
      }, ...areas];
      const area = areas[0];
      area.isEdit = !area.isEdit;
      this.setState({
        areas,
        isEdit: !this.state.isEdit,
      });
    }
  }

  deleteItems(item, e) {
    Swal.fire({
      title: 'คุณต้องการลบพื้นที่จังหวัดนี้ใช่หรือไม่?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.value) {
        let areas = _.cloneDeep(this.state.areas);
        let districtData = _.cloneDeep(this.state.districts);
        let result = districtData.filter(d => d.provinceCode !== item.provinceCode);
        let data = areas.filter(d => d.provinceCode !== item.provinceCode);
        this.setState({
          areas: data,
          districts: result,
        });
        this.saveArea(data, item.key, 'delete');
      }
    });
  }

  getDistrictByCode = async (code) => {
    const province = this.state.districts.find(d => d.provinceCode === code);
    if (!province) {
      const response = await this.props.misc.getDistrictByid(code);
      let districts = _.cloneDeep(this.state.districts);
      districts.push({
        provinceCode: code,
        districts: response,
      });
      this.setState({
        districts,
      });
    }
  }

  showItem = (item, index) => {
    this.getDistrictByCode(item.provinceCode);
    let provinceOptions = this.getNotSelectedList(item.provinceCode).map((c, index) => {
      return <option key={index} value={c.code}>{c.name}</option>;
    });
    let districtOptions = [];
    let districtCode = [];
    if (item) {
      let districtInfo = (this.state.districts || []).find(d => d.provinceCode === item.provinceCode);
      if (districtInfo) {
        districtOptions = (districtInfo.districts || []).map(d => {
          return ({
            label: d.name,
            value: d.code,
          });
        });
      }
      districtCode = districtOptions;
      if (districtCode.length !== item.districtCode.length) {
        districtOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...districtOptions];
      }
    }

    return (
      <div key={`item-${index}`} className="card item-card">
        <div className="card-content">
          <FormGroup label="จังหวัด">
            <EnDropDown value={item.provinceCode} onChange={this.onProvinceChanged.bind(this, item)}>
              {provinceOptions}
            </EnDropDown>
          </FormGroup>
          <FormGroup label="อำเภอ">
            <EnTagSelectValidation
              closeOnSelect={false}
              options={districtOptions}
              onTagChange={this.onDistrictChanged.bind(this, { key: index, ...item }, districtCode)}
              value={item.districtCode}
              searchable={false}
              validations={[required]}
            />
          </FormGroup>
        </div>
      </div>
    );
  }

  render() {
    const { isLoading } = this.props.misc.toJS();
    let showAddButton = false;
    if (this.state.areas.length) {
      showAddButton = !!this.state.areas[0].districtCode;
    }
    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <Notification ref={(ref) => { this.noti = ref; }} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ระบุพื้นที่ขาย</h4>
                <p className="category">เพื่อให้ระบบกรองช่างที่รับงานในพื้นที่ขายของท่าน</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="card-content">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th className="text-center">ชื่อจังหวัด</th>
                            <th width="10%" className="text-center">
                              {
                                showAddButton &&
                                <EnButton disabled={this.state.isEdit} className="btn btn-success pull-right" onClick={this.addNewItem.bind(this)}>
                                  <i className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่มพื้นที่ใหม่
                                </EnButton>
                              }
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.genRow()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('admin', 'misc', 'auth')(observer(StoreArea));
