import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Swal from 'sweetalert2';

import Container from '../../../../layouts/Container';
import Loader from '../../../../components/common/Loader';
import Notification from '../../../../components/common/Notification';

import EnButton from '../../../../components/form/EnButton';
import EnTagSelect from '../../../../components/form/EnTagSelect';

import logo from '../../../../assets/logo/JOB_FW_LOGO_QCHANG_NEW.gif';

class AdminDownloadContractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadType: 'contractor',
      filterOption: [
        {
          value: '1',
          label: 'หัวหน้าทีมช่าง',
        },
        {
          value: '2',
          label: 'ลูกทีมช่าง',
        },
      ],
      filterSelection: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.props.download.getDownloadFiles(this.state.downloadType);
  }

  handleFilterSelection = (value) => {
    this.setState({ filterSelection: value });
  };

  async onDownload() {
    try {
      const createQueueDownloadId = await this.props.download.queueDownload({
        type: 'created',
        download_type: this.state.downloadType,
        query: this.state.filterSelection,
      });
      if (!createQueueDownloadId) {
        throw new Error('ไม่สามารถดาวน์โหลดไฟล์ได้');
      }
      console.log('createQueueDownloadId', createQueueDownloadId);

      // Show the SweetAlert2 dialog
      Swal.fire({
        html: `
          <div style="display: flex; flex-direction: column; align-items: center;">
            <img src="${logo}" alt="Logo" style="width: 200px; height: 200px;" />
            <h2 class="swal2-title">ระบบกำลังเตรียมข้อมูล</h2>
            <p>กรุณารอจนกว่าระบบจะเตรียมข้อมูลเสร็จ</p>
            <div class="custom-spinner"></div>
          </div>
        `,
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก',
        cancelButtonColor: '#d33',
        showConfirmButton: false,
        onOpen: () => {
          // Attach event listener to the cancel button
          const cancelButton = Swal.getCancelButton();
          if (cancelButton) {
            cancelButton.addEventListener('click', () => {
              Swal.close();
              Swal.fire({
                icon: 'error',
                title: 'การดาวน์โหลดถูกยกเลิก',
                text: 'คุณได้ยกเลิกการดาวน์โหลด',
                confirmButtonText: 'รับทราบ',
              });

              this.props.download.queueDownload({
                id: createQueueDownloadId,
                type: 'cancel',
                download_type: this.state.downloadType,
                query: this.state.filterSelection,
              });
            });
          }
        },
      });

      const options = {
        id: createQueueDownloadId,
        download_type: this.state.downloadType,
        query: this.state.filterSelection,
      };

      // Call the downloadFileV2 API
      const result = await this.props.download.downloadFileV2(options);

      // Close the SweetAlert2 dialog
      Swal.close();

      if (result && result.url) {
        await Swal.fire({
          icon: 'success',
          title: 'ระบบเตรียมข้อมูลสำเร็จ',
          text: '',
          confirmButtonText: 'รับทราบ',
        });
      } else {
        throw new Error(result.message || 'ไม่สามารถดาวน์โหลดไฟล์ได้');
      }
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'error',
        title: 'เกิดข้อผิดพลาด',
        text: 'ระบบไม่สามารถเตรียมข้อมูลชุดดังกล่าวได้ กรุณาลองใหม่อีกครั้ง',
        confirmButtonText: 'รับทราบ',
      });
    } finally {
      this.fetchData();
    }
  }

  pushBack = () => {
    window.history.back();
  };

  getTableRows(items) {
    return items.map((item, index) => {
      const allowDownload = item.status === 'success';

      let fileName = <span>{item.fileName}</span>;
      if (item.status === 'failed') {
        fileName = (
          <>
            <span>{item.fileName}</span>
            <span style={{ color: 'red' }}> (เกิดข้อผิดพลาด) </span>
          </>
        );
      } else if (item.status === 'cancel') {
        fileName = (
          <>
            <span>{item.fileName}</span>
            <span style={{ color: 'red' }}> (ถูกยกเลิก) </span>
          </>
        );
      }

      return (
        <tr key={index}>
          <td style={{ textAlign: 'center', padding: '4px' }}>{index + 1}</td>
          <td style={{ textAlign: 'left', padding: '4px', paddingLeft: '8px' }}>
            <span>{fileName}</span>
          </td>
          <td style={{ textAlign: 'center', padding: '4px' }}>
            {item.start_date ? moment(item.start_date).format('DD/MM/YYYY HH:mm:ss') : ''}
          </td>
          <td style={{ textAlign: 'center', padding: '4px' }}>
            {item.end_date ? moment(item.end_date).format('DD/MM/YYYY HH:mm:ss') : ''}
          </td>
          <td style={{ textAlign: 'center', padding: '4px' }}>
            <a href={item.file_url} target="_blank" disabled={!allowDownload}>
              {!allowDownload ? (
                <i
                  className="fa fa-download"
                  aria-hidden="true"
                  style={{ paddingTop: '4px', color: 'gray' }}
                />
              ) : (
                <i className="fa fa-download" aria-hidden="true" style={{ paddingTop: '4px' }} />
              )}
            </a>
          </td>
        </tr>
      );
    });
  }

  renderFilter() {
    const disabled = this.state.filterSelection === null;
    return (
      <div style={{ marginTop: '15px' }}>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="col-md-3 control-label">ประเภทช่าง</label>
            <div className="col-md-9">
              <EnTagSelect
                multi={false}
                closeOnSelect
                options={this.state.filterOption}
                onTagChange={(value) => {
                  this.handleFilterSelection(value);
                }}
                value={this.state.filterSelection}
                searchable
                className="coupon-search-select"
                placeholder="เลือก..."
              />
            </div>
          </div>
          <div className="form-group col-md-6"></div>
        </div>
        <div className="row">
          <div className="form-group col-md-6"></div>
          <div className="form-group col-md-6" style={{ textAlign: 'right' }}>
            <EnButton
              className="btn btn-success"
              onClick={() => {
                this.onDownload();
              }}
              disabled={disabled}
            >
              <span class="fa fa-file-text-o" aria-hidden="true"></span>
              <span style={{ marginLeft: '5px' }}>ดาวน์โหลดข้อมูล</span>
            </EnButton>
          </div>
        </div>
      </div>
    );
  }

  renderTable(rows) {
    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover" width="100%">
          <thead>
            <tr>
              <th width="10%" className="text-center">
                ลำดับที่
              </th>
              <th width="50%" className="text-center">
                ชื่อไฟล์
              </th>
              <th width="15%" className="text-center">
                เวลาเริ่ม
              </th>
              <th width="15%" className="text-center">
                เวลาสิ้นสุด
              </th>
              <th width="10%" className="text-center">
                ดาวน์โหลด
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        <div>
          <EnButton
            className="btn btn-primary btn-outline-secondary btn-custom"
            onClick={this.pushBack}
          >
            ย้อนกลับ
          </EnButton>
        </div>
      </div>
    );
  }

  render() {
    const { items, isLoading } = this.props.download.toJS();
    const rows = this.getTableRows(items);

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <Notification ref={(ref) => (this.noti = ref)} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายการดาวน์โหลดคิวช่าง</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    {this.renderFilter()}

                    <div className="table-responsive">{this.renderTable(rows)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('download', 'auth', 'channel')(observer(AdminDownloadContractor));
