import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
// import querystring from 'query-string';
import swal from 'sweetalert2';
import { FormControl } from 'react-bootstrap/lib';
import querystring from 'query-string';
import FormGroup from '../../../components/form/FormGroup';
import EnButton from '../../../components/form/EnButton';
import liffHelper from '../../../utils/bestdeal/LiffHelper';
import Loader from '../../../components/form/Loader';
import '../../../assets/css/bestdeal.css';
import '../../../assets/css/loader.css';
import 'rc-slider/assets/index.css';
// import '../../../utils/bestdeal/VConsole';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      message: '',
    };
    liffHelper.getProfile().then(async profile => {
      this.initialData(profile);
    });
    // .catch(async (err) => {
    //   console.log('err', err);
    //   const profile = {
    //     userId: 'Ufc91c03ef14b90d6f2d8c7e7219c9295',
    //     displayName: 'ชยากร เจนพิทักษ์',
    //     pictureUrl: 'https://profile.line-scdn.net/0hk0wG2u4xNB9KPh5FputLSHZ7OnI9EDJXMggpLW06ai4yD3BAdAp7eDtsaCoyCnBNd159fjs8bH1k',
    //   };
    //   this.initialData(profile);
    // });
  }

  initialData(profile) {
    this.saveInfo('lineUserId', profile.userId);
    this.saveInfo('lineDisplayName', profile.displayName);
    this.saveInfo('linePictureUrl', profile.pictureUrl);
  }

  saveInfo(key, value) {
    this.props.bestdealChat.saveInfo(key, value);
  }

  async onSubmit(e) {
    let parsed = querystring.parse(this.props.location.search);
    const toId = parsed.to;
    const botIndex = parsed.bot_index;
    if (toId) {
      this.saveInfo('to', toId);
      this.saveInfo('botIndex', botIndex);
      try {
        await this.props.bestdealChat.createChat();
        this.sendMessageToUser()
          .then(this.onCancel)
          .catch(lineerror => {
            swal.showValidationMessage(`ส่งข้อความล้มเหลว ${lineerror.message}`);
          });
      } catch (error) {
        swal.showValidationMessage(`บันทึกล้มเหลว ${error.message}`);
      }
    } else {
      swal.showValidationMessage(`ส่งข้อความล้มเหลว`);
    }
  }

  sendMessageToUser() {
    const bestdealChat = this.props.bestdealChat.toJS();
    return liffHelper.sendMessages({
      type: 'text',
      text: 'ส่งข้อความ : ' + bestdealChat.info.message,
    });
  }

  onCancel() {
    liffHelper.closeWindow();
  }

  onInputChange(e) {
    const value = e.target.value || '';
    this.saveInfo('message', value);
  }

  render() {
    const bestdealChat = this.props.bestdealChat.toJS();
    // console.log(info);
    return (
      <div className='content'>
        <Loader loading={bestdealChat.isLoading} />
        <div className='container-fluid'>
          <div className='row'>
            <div>
              <div className='card info'>
                <div className='card-header' data-background-color='orange'>
                  <h4 className='title'>พูดคุย</h4>
                </div>
                <div className='card-content'>
                  <div className='row'>
                    <FormGroup containerClassName='col-md-9 col-xs-9'>
                      <FormControl
                        className='box'
                        id='input-chat'
                        type='text'
                        label={'ข้อความ'}
                        onChange={this.onInputChange.bind(this)}
                      />
                    </FormGroup>
                    <FormGroup containerClassName='col-md-3 col-xs-3'>
                      <EnButton
                        style={{ margin: `0` }}
                        className='btn-info'
                        onClick={this.onSubmit.bind(this)}
                      >
                        ส่ง
                      </EnButton>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('bestdealChat')(observer(Chat));
