import React, { Component } from 'react';
import swal from 'sweetalert2';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Notification from '../../../components/common/Notification';
import Loader from '../../../components/common/Loader';
import { FormValidation, required } from '../../../components/form/FormValidation';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import FormGroup from '../../../components/form/FormGroup';
import EnQuill from '../../../components/form/EnQuill';
import EnButton from '../../../components/form/EnButton';
import EnDropZone from '../../../components/form/EnDropZone';
import EnDropDown from '../../../components/form/EnDropDown';
import { EnTagSelectValidation } from '../../../components/form/EnTagSelect';
import AuthStore from '../../../stores/AuthStore';
import { config } from '../../../config';
import common from '../../../utils/common';
import { isAllow } from '../../../utils/permission';
import EnCKEditor from '../../../components/form/EnCKEditor';

export class SolutionPage extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    this.state = {
      id: id,
      isEdit: id && id.length,
      products: [],
      productGroup: [],
      bestSeller: '',
      files: [],
      filterProduct: []
    };
  }

  async componentWillMount() {
    try {
      if (this.state.isEdit) {
        await this.props.solution.getSolutionById(this.state.id);
      } else {
        this.props.solution.clearInfo();
      }
      await this.props.channel.getAllChannel();
      await this.props.product.getProductList();
      await this.props.productgroup.getProductList({ limit: 1000, page: 1 });
      const info = this.props.solution.toJS().info;
      const productGroupinfo = this.props.productgroup.toJS().items;
      const productItems = this.props.product.toJS().items;
      const oldProductItems = this.props.product.toJS().items;
      oldProductItems['selected'] = [];

      if (info && Array.isArray(info.productIds) && info.productIds.length) {
        productItems['selected'] = info.productIds;
      } else {
        productItems['selected'] = [];
      }

      if (info && Array.isArray(info.productGroupIds) && info.productGroupIds.length) {
        productGroupinfo['selected'] = info.productGroupIds;
      } else {
        productGroupinfo['selected'] = [];
      }

      this.setState({
        productGroup: [productGroupinfo],
        products: [productItems],
        oldProduct: [oldProductItems],
        bestSeller: info.bestSellerId,
      });
    } catch (error) {
      this.noti.error(error.message, 'โหลดข้อมูลล้มเหลว');
    }
  }

  onChangeName(e) {
    if (e.target) {
      this.props.solution.saveNameTh(e.target.value);
    }
  }

  onChangeDescription(value) {
    this.props.solution.saveDescriptionTh(value);
  }

  onChangeBestSeller(e) {
    this.setState({ bestSeller: e.target.value });
  }

  onAddedFile() {
    if (this.dropzone) {
      this.setState({
        files: this.dropzone.getFiles(),
      });
    }
  }

  // onRemovedFile() {
  //   if (this.dropzone) {
  //     this.setState({
  //       files: this.dropzone.getFiles(),
  //     });
  //   }
  // }

  onUploadError(message) {
    swal.fire({
      icon: 'error',
      title: `${message}`,
    });
  }

  isValid() {
    const info = this.props.solution.toJS().info;
    const validNameTh = !_.isEmpty(info.name.th);
    const validDescriptionTh = !_.isEmpty(info.description.th);
    let validImage;
    if (!_.isEmpty(info.image) || !_.isEmpty(this.state.files)) {
      validImage = true;
    } else {
      validImage = false;
    }
    const validProductsGroup = !_.isEmpty(this.state.productGroup[0]) && !_.isEmpty(this.state.productGroup[0]['selected']);
    const validProducts = !_.isEmpty(this.state.products[0]) && !_.isEmpty(this.state.products[0]['selected']);

    const isValidProduct = (validProductsGroup || validProducts);
    // console.log('isValidProduct', isValidProduct);

    const validBestSeller = !_.isEmpty(this.state.bestSeller);
    const hasPermission = this.state.isEdit ? isAllow('EDIT_SOLUTIONS') : isAllow('CREATE_SOLUTIONS');
    return validNameTh && validDescriptionTh && validImage && isValidProduct && validBestSeller && hasPermission;
  }

  doUploadFiles = (dropzone) => {
    return new Promise((resolve, reject) => {
      let files = dropzone.getFiles();
      for (let file of files) {
        if (file.status === 'error') {
          throw new Error('ไม่สามารถอัพโหลดไฟล์ได้');
        }
      }
      dropzone.upload(async () => {
        try {
          await this.submitForm();
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    });
  }

  async submitForm() {
    try {
      const options = {
        selectProductGroupIds: this.state.productGroup[0]['selected'].length ? this.state.productGroup[0]['selected'] : [''],
        selectProductIds: this.state.products[0]['selected'].length ? this.state.products[0]['selected'] : [''],
        selectBestSellerId: this.state.bestSeller,
      };
      if (this.state.isEdit) {
        await this.props.solution.updateSolution(this.state.id, options);
      } else {
        await this.props.solution.createSolution(options);
      }
    } catch (error) {
      throw error;
    }
  }

  async uploadAndSubmit() {
    try {
      if (this.dropzone && this.dropzone.getFiles() && this.dropzone.getFiles().length) {
        await this.doUploadFiles(this.dropzone);
      } else {
        await this.submitForm();
      }
    } catch (error) {
      throw error;
    }
  }

  async onSubmit() {
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ Solution' : 'กรุณายืนยันการเพิ่ม Solution ใหม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.uploadAndSubmit();
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      setTimeout(() => {
        this.onCancel();
      }, 500);
    }
  }

  async onCancel() {
    this.props.history.replace(`${config.publicUrl}/qchang/solution`);
  }

  async onUploadComplete(files) {
    if (files && files.length) {
      this.props.solution.saveInfo('image', files[0].res.data.path);
    }
  }

  getDropZone(ref, callback) {
    const url = `${config.api.qchang}/v1/solutions/upload`;
    const token = AuthStore.getToken();

    return (
      <EnDropZone
        ref={ref}
        url={url}
        token={token}
        acceptedFiles={'image/*'}
        onAllComplete={callback}
        maxFileSize={3}
        maxFiles={1}
        onAddedFile={this.onAddedFile.bind(this)}
        // onRemovedFile={this.onRemovedFile.bind(this)}
        onError={this.onUploadError.bind(this)}
      />
    );
  };

  onChangeProductGroup(selectedItem, e) {
    const ids = e.split(',');
    const products = _.cloneDeep(this.state.productGroup);
    if (_.isEmpty(ids) || _.isEmpty(ids[0])) {
      products[0]['selected'] = [];
    } else {
      products[0]['selected'] = ids;
    }
    const updateState = {
      productGroup: products,
      products: this.state.oldProduct,
    };

    if (_.isEmpty(products[0]['selected'])) {
      // clear best seller id if no seleted products
      updateState.bestSeller = '';
    }
    this.setState(updateState);
  }

  onChangeProduct(selectedItem, e) {
    const ids = e.split(',');
    const products = _.cloneDeep(this.state.products);

    if (_.isEmpty(ids) || _.isEmpty(ids[0])) {
      products[0]['selected'] = [];
    } else {
      products[0]['selected'] = ids;
    }

    const updateState = {
      products,
    };

    if (_.isEmpty(products[0]['selected'])) {
      // clear best seller id if no seleted products
      updateState.bestSeller = '';
    }
    this.setState(updateState);
  }

  showProductGroupItems() {
    const items = (this.state.productGroup || []).map((item, index) => {
      const productOptions = (item || []).map(p => {
        return ({
          label: p.name ? p.name.th : '',
          value: p.id,
        });
      });
      return (
        <div key={`item${index}`}>
          <EnTagSelectValidation
            placeholder="กรุณาเลือกกลุ่มสินค้า"
            closeOnSelect={false}
            options={productOptions}
            onTagChange={this.onChangeProductGroup.bind(this, item)}
            value={item['selected']}
            searchable={false}
          />
        </div>
      );
    });
    return items;
  }

  showChannelGroupItems(channel) {
    return (
      <div key={`item`}>
        <EnTagSelectValidation
          placeholder="กรุณาเลือกช่องทางการขาย"
          closeOnSelect={false}
          options={this.getChannelOptions()}
          onTagChange={value => this.onChangeChannel(value)}
          value={channel}
          searchable={false}
        />
      </div>
    );
  }

  onChangeChannel(value) {
    if (value) {
      let channelGroup = value.split(',')
      this.props.solution.saveInfo('channel', channelGroup);
    } else {
      this.props.solution.saveInfo('channel', []);
    }
  }

  getChannelOptions = () => {
    const channel = this.props.channel.toJS().info;
    return (channel || []).map((c, index) => {
      return {
        label: c.channel_name,
        value: c.channel_name,
      };
    });
  };

  showProductItems = () => {
    const productGroupItems = this.state.productGroup[0];
    const product = this.state.products[0];

    const selectedProductGroup = (productGroupItems || []).reduce((total, amount) => {
      if (productGroupItems['selected'].includes(amount.id)) {
        total.push(amount);
      }
      return total;
    }, []).map(group => {
      return (product || []).filter(p => _.indexOf(group.productIds, p.id) >= 0);
    });

    const merged = [].concat.apply([], selectedProductGroup);
    const filterProduct = (product || []).filter(p => {
      if (merged.find(m => m.id === p.id)) {
        return false;
      }
      return true;
    });
    const selectedProducts = (product || []).map(p => {
      if (product['selected'].includes(p.id)) {
        return p.id;
      }
      return undefined;
    }).filter(function (item) {
      return typeof item === 'string';
    });

    if (selectedProducts && selectedProducts.length) {
      filterProduct['selected'] = selectedProducts;
    }

    const items = ([filterProduct] || this.state.products).map((item, index) => {
      const productOptions = (item || []).map(p => {
        return ({
          label: p.name ? p.name.th : '',
          value: p.id,
        });
      });
      return (
        <div key={`item${index}`}>
          <EnTagSelectValidation
            placeholder="กรุณาเลือกสินค้า"
            closeOnSelect={false}
            options={productOptions}
            onTagChange={this.onChangeProduct.bind(this, item)}
            value={item['selected']}
            searchable={false}
          />
        </div>
      );
    });
    return items;
  }

  displayBestSellerSelector() {
    const defaultOpts = [
      <option key={0} value="">กรุณาเลือกสินค้าขายดี</option>,
    ];

    let selectedOpts = [];
    if (Array.isArray(this.state.productGroup) && this.state.productGroup.length) {
      const items = this.state.productGroup[0];
      const productItems = this.state.products[0];
      const selectedProductGroup = items.filter(p => {
        return items['selected'].includes(p.id);
      });
      const selectedProducts = (productItems || []).filter(p => {
        return productItems['selected'].includes(p.id);
      });
      selectedProductGroup.push(...selectedProducts);
      selectedOpts = selectedProductGroup.map((p, idx) => {
        return <option key={idx + 1} value={p.id}>{p.name ? p.name.th : ''}</option>;
      });
    }
    return defaultOpts.concat(selectedOpts);
  }

  displayImage(imageUrl) {
    if (imageUrl) {
      return (
        <a href={imageUrl} target="_blank">
          <img src={imageUrl} style={{ width: '20%', height: '20%' }} alt="" />
        </a>
      );
    }
    return (
      <div>
        <em>
          <small>ไม่มีรูปภาพ</small>
        </em>
      </div>
    );
  }

  onSeoChange(key, e) {
    if (e.target) {
      if (key === 'slug') {
        this.props.solution.saveSeoInfo(common.slugify(e.target.value), key);
      }
      this.props.solution.saveSeoInfo(e.target.value, key);
    }
  }

  async onDelete() {
    let confirmDialogOptions = {
      title: 'ลบข้อมูล',
      text: 'คุณต้องการลบ Solution นี้ใช่หรือไม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.props.solution.deleteSolution(this.state.id);
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      setTimeout(() => {
        this.onCancel();
      }, 500);
    }
  }

  render() {
    const info = this.props.solution.toJS().info;
    const { title, slug, keywords, description } = info.seo;
    const formTitlte = this.state.isEdit ? 'แก้ไข Solution' : 'เพิ่ม Solution ใหม่';
    const dropzone = this.getDropZone(e => { this.dropzone = e; }, (e) => {
      this.onUploadComplete(e);
    });

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{formTitlte}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <div className="card-content">
                  <FormValidation>
                    <div className="row">
                      <EnTextFormGroup
                        containerClassName="col-md-6"
                        type="text"
                        label="ชื่อ Solution"
                        value={info.name ? info.name.th : ''}
                        onChange={this.onChangeName.bind(this)}
                        validations={[required]} />
                      <div className="col-md-6">
                        <p className="m-0">ช่องทางการขาย</p>
                        {this.showChannelGroupItems(info.channel)}
                      </div>
                    </div>
                    <div className="seo">
                      <div className="header-seo"><span>SEO</span></div>
                      <div className="seo-form">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="row">
                              <EnTextFormGroup
                                containerClassName="col-md-6"
                                type="text"
                                label="SEO Title"
                                value={title}
                                onChange={this.onSeoChange.bind(this, 'title')} />
                              <EnTextFormGroup
                                containerClassName="col-md-6"
                                type="text"
                                label="Slug"
                                value={slug}
                                onChange={this.onSeoChange.bind(this, 'slug')} />
                            </div>
                            <div className="row">
                              <EnTextFormGroup
                                containerClassName="col-md-12"
                                type="text"
                                label="Keyword"
                                placeholder="รางน้ำฝน, รางน้ำฝนพร้อมบริการติดตั้ง"
                                value={keywords}
                                onChange={this.onSeoChange.bind(this, 'keywords')} />
                            </div>
                            <div className="row">
                              <EnTextFormGroup
                                containerClassName="col-md-12"
                                type="text"
                                label="Meta Description"
                                placeholder="ผลิตภัณฑ์รางน้ำไวนิล เอสซีจี มีให้เลือกสองรุ่นคือ รุ่นเดอลุกซ์ (Deluxe) และ รุ่นสมาร์ท (Smart) ซึ่งทั้งสองรุ่นจะแตกต่างกันที่รูปทรง"
                                value={description}
                                onChange={this.onSeoChange.bind(this, 'description')} />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <label>ตัวอย่าง</label>
                            <div className="seo seo-form">
                              <div className="seo-preview-title">{title || ''}</div>
                              <div className="seo-preview-link">
                                <cite>
                                  {'https://partner.q-chang.com/solution/'}
                                  <mark>
                                    {slug || ''}
                                  </mark>
                                </cite>
                              </div>
                              <div className="seo-preview-description">
                                <div>
                                  <span>{description || ''}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {/* <EnQuill
                        containerClassName="col-md-12"
                        label="รายละเอียด"
                        value={info.description ? info.description.th : ''}
                        onChange={this.onChangeDescription.bind(this)}
                        validations={[required]}
                      /> */}
                      <EnCKEditor
                        label="รายละเอียด"
                        type="solution"
                        containerClassName="col-md-12"
                        value={info.description ? info.description.th : ''}
                        onChange={(event, editor) => {this.onChangeDescription(editor.getData())}}
                        validations={[required]}
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <span className="form-label">กลุ่มสินค้าของ Solution *</span>
                        {this.showProductGroupItems()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <span className="form-label">สินค้าของ Solution *</span>
                        {this.showProductItems()}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <span className="form-label">สินค้าขายดี *</span>
                        <EnDropDown
                          value={this.state.bestSeller}
                          onChange={this.onChangeBestSeller.bind(this)}>
                          {this.displayBestSellerSelector()}
                        </EnDropDown>
                      </div>
                    </div>
                    <div className="row">
                      <FormGroup containerClassName="col-md-12" label="รูปภาพ" >
                        <div className="col-md-12">
                          {this.displayImage(info.image)}
                        </div>
                      </FormGroup>
                    </div>
                    <div className="row">
                      <FormGroup containerClassName="col-md-12" label="อัพโหลดรูปภาพ" >
                        {dropzone}
                      </FormGroup>
                    </div>
                    <div className="row">
                      <EnButton className="btn-danger pull-left" onClick={this.onDelete.bind(this)}>
                        <i className="fa fa-trash btn-icon" aria-hidden="true" />ลบ
                      </EnButton>
                      <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={this.onSubmit.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                        <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                      </EnButton>
                    </div>
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('solution', 'product', 'productgroup', 'channel')(observer(SolutionPage));
