import * as _ from 'lodash';
import React from 'react';
import swal from 'sweetalert2';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import SignatureCanvas from 'react-signature-canvas';
import { Modal } from 'react-bootstrap';
import { Address } from './Address';
import Loader from '../../../components/common/Loader';
import Container from '../../../layouts/ContainerContractor';
import { FormValidation, required, email, phone } from '../../../components/form/FormValidation';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnDropzoneDialog from '../../../components/form/EnDropzoneDialog';
import EnButton from '../../../components/form/EnButton';
import Notification from '../../../components/common/Notification';
import common from '../../../utils/common';
import { thaiDate } from '../../../utils/datetime';
import config from '../../../config';
import FormGroup from '../../../components/form/FormGroup';
import Select from 'react-select';
import MaskInput from 'react-text-mask';
import ListItem from '../../../components/common/ListItem';
import '../style/contractor.css';
import EnDropDown from '../../../components/form/EnDropDown';

const BirthdayDropdowns = (props) => (
  <FormGroup id="contractor-birthday" containerClassName="col-md-6" label="วัน/เดือน/ปี เกิด *">
    {
      props.disabled !== true && 
        <div style={{display: 'flex', gap: '7px'}}>
          <EnDropDown 
            value={props.dropdownBirthday.day}
            onChange={e => props.onChangeBirthday(e, 'day')}
            placeholder="เลือกวัน"
            className="col-md-4"
          >
            <option key='placeholder' value='' disabled selected>เลือกวัน</option>
            <option key={'-'} value={'-'}>-</option>
            {
              [...Array(props.dropdownBirthday.daysOfMonth)].map((_, i) => <option key={i+1} value={i+1}>{i+1}</option>)
            }
          </EnDropDown>
          <EnDropDown 
            value={props.dropdownBirthday.month}
            onChange={e => props.onChangeBirthday(e, 'month')}
            placeholder="เลือกเดือน"
            className="col-md-4"
          >
            <option key='placeholder' value='' disabled selected>เลือกเดือน</option>
            <option key={'-'} value={'-'}>-</option>
            {
              [...Array(12)].map((_, i) => <option key={i+1} value={i+1}>{thaiDate.months[i]}</option>)
            }                   
          </EnDropDown>
          <EnDropDown 
            value={props.dropdownBirthday.year}
            onChange={e => props.onChangeBirthday(e, 'year')}
            placeholder="เลือกปี"
            className="col-md-4"
          >
            <option key='placeholder' value='' disabled selected>เลือกปี</option>
            {
              [...Array(100)].map((_, i) => 
                <option 
                  key={moment().year() - i}
                  value={moment().year() - i}>
                    {moment().year() - i}
                </option>
              )
            }
          </EnDropDown>
        </div>
    }
    {
    props.disabled === true && 
      <div style={{display: 'flex', gap: '7px'}}>
        <input type="text"
          className="form-control"
          value={props.dropdownBirthday.day}
          disabled={true}
        />
        <input type="text"
          className="form-control"
          value={props.dropdownBirthday.month}
          disabled={true}
        />
        <input type="text"
          className="form-control"
          value={props.dropdownBirthday.year}
          disabled={true}
        />
      </div>
    }
  </FormGroup>
)

export const JuristicInfo = (props) => {
  const selectedBank = (props.genBankOptions || []).find(bank => bank.value === props.contractorInfo.bankCode);
  const bankName = _.get(selectedBank, 'label.props.children') ? _.last(selectedBank.label.props.children) : '';
  return (
  <Container>
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header" data-background-color="orange">
            <h4 className="title">ข้อมูลส่วนตัว</h4>
            <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
          </div>
          <div className="card-content">
            <FormValidation>
              <div className="row" style={{ backgroundColor: '#F2F3F4', paddingLeft: '15px' }}>
                <h4>ข้อมูลส่วนตัว</h4>
              </div>
              <div className="row">
                <EnTextFormGroup disabled={true} id="contractor-firstname" containerClassName="col-md-6" label="ชื่อ" value={props.contractorInfo.firstName} onChange={props.saveInfo('firstName')} validations={[required]} />
                <EnTextFormGroup disabled={true} id="contractor-surename" containerClassName="col-md-6" label="นามสกุล" value={props.contractorInfo.lastName} onChange={props.saveInfo('lastName')} validations={[required]} />
              </div>
              <div className="row">
                <EnTextFormGroup disabled={true} id="contractor-phone" containerClassName="col-md-6" label="เบอร์โทรศัพท์" value={props.contractorInfo.phone} size="10" maxLength="10" onChange={props.saveInfo('phone')} validations={[required, phone]} />
                <BirthdayDropdowns disabled={true} onChangeBirthday={props.onChangeBirthday} dropdownBirthday={props.dropdownBirthday} />
              </div>
              <div className="row">
                <EnTextFormGroup disabled={true} id="contractor-email" containerClassName="col-md-6" label="อีเมล (สามารถเพิ่มได้สูงสุด 5 อีเมลโดยใส่ “,” คั่น เช่น email1@gmail.com,email2@gmail.com)" value={props.contractorInfo.email} onChange={props.saveInfo('email')} validations={[email]} />
                <EnTextFormGroup disabled={true} id="contractor-car-id" containerClassName="col-md-6" label="หมายเลขทะเบียนรถ" value={props.contractorInfo.carId} size="10" maxLength="10" onChange={props.saveInfo('carId')} notRequired={!props.carIdRequired} />
              </div>
              <div className="row">
                <div className="col-md-12">
                  {props.licenseCardFile}
                </div>
              </div>
              {/* <div className="row">
                <div className="form-group col-md-12">
                  <EnButton
                    className="btn-secondary"
                    onClick={props.onOpenDropzone('dropzoneLicenseCardOpen')}>
                    อัพโหลดรูปถ่ายบัตรประจำตัวช่าง
                  </EnButton>
                </div>
              </div> */}
              <div className="row" style={{ backgroundColor: '#F2F3F4', paddingLeft: '15px' }} >
                <h4>ที่อยู่สำหรับออกใบกำกับภาษี</h4>
              </div>
              <div className="row">
                <div style={{ paddingLeft: '15px', marginBottom: '5px', marginTop: '5px' }}>
                  <label>
                    <input
                      disabled={true}
                      type="radio"
                      value="juristic"
                      checked={props.contractorInfo.isJuristic === true}
                      onClick={props.onChangeType}
                      readOnly />&nbsp;&nbsp;นิติบุคคล/บริษัท
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label>
                    <input
                      disabled={true}
                      type="radio"
                      value="individual"
                      checked={props.contractorInfo.isJuristic === false}
                      onClick={props.onChangeType}
                      readOnly />&nbsp;&nbsp;บุคคลธรรมดา
                  </label>
                  
                </div>
              </div>
              <br />
              <div className="row">
                <EnTextFormGroup disabled={true} id="contractor-team" containerClassName="col-md-12" label="ชื่อบริษัท" value={props.contractorInfo.teamName} onChange={props.saveInfo('teamName')} validations={[required]} />
              </div>
              <Address
                provinceName={props.provinceName}
                districtName={props.districtName}
                subDistrictName={props.subDistrictName}
                contractor={props.contractorInfo}
                onProvinceChanged={props.onProvinceChanged}
                onDistrictChanged={props.onDistrictChanged}
                onSubDistrictChanged={props.onSubDistrictChanged}
                saveRegisterInfo={(key) => props.saveRegisterInfo(key)}
                disabled={true}
              />
              <div className="row">
                <EnTextFormGroup maxLength={13}
                  disabled={true}
                  containerClassName="col-md-6"
                  label={'หมายเลขประจำตัวผู้เสียภาษีอากร'}
                  value={props.contractorInfo.taxNo}
                  onChange={props.saveRegisterInfo('taxNo')}
                  validations={[required]} />
                <EnTextFormGroup
                  disabled={true}
                  placeholder="กรณีสำนักงานใหญ่ให้ระบุเป็น 00000 หรือ 00001"
                  containerClassName="col-md-6"
                  label={'หมายเลขสำนักงานใหญ่ / สาขา'}
                  maxLength={5}
                  value={props.contractorInfo.taxBranchNo}
                  onChange={props.saveRegisterInfo('taxBranchNo')}
                  validations={[required]} />
              </div>
              <div className="row">
                <div className="col-md-12">
                  {props.companyCertFile}
                </div>
              </div>
              {/* <div className="row">
                <div className="form-group col-md-12">
                  <EnButton
                    className="btn-secondary"
                    onClick={props.onOpenDropzone('dropzoneCompanyCertOpen')}
                  >
                    อัพโหลดหนังสือรับรองบริษัท
                  </EnButton>
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-12">
                  {props.vatFile}
                </div>
              </div>
              {/* <div className="row">
                <div className="form-group col-md-12">
                  <EnButton
                    className="btn-secondary"
                    onClick={props.onOpenDropzone('dropzoneVatOpen')}
                  >
                    อัพโหลด ภพ.20
                  </EnButton>
                </div>
              </div> */}

              &nbsp;
              <div>
                <div className="row" style={{ backgroundColor: '#F2F3F4', paddingLeft: '15px' }} >
                  <h4>บัญชีธนาคาร</h4>
                </div>
                <div className="row">
                  <FormGroup label="ธนาคาร" containerClassName="col-md-12">
                    <input type="text"
                      className="form-control"
                      value={bankName}
                      disabled={true}
                    />
                    {/* <Select
                      searchable={false}
                      clearable={false}
                      onChange={props.onSelectChange}
                      placeholder="กรุณาเลือก"
                      options={props.genBankOptions}
                      value={props.contractorInfo.bankCode} /> */}
                  </FormGroup>
                </div>
                <div className="row">
                  <EnTextFormGroup disabled={true} containerClassName="col-md-6" label="ชื่อบัญชี" value={props.contractorInfo.bankAccountName} onChange={props.saveInfo('bankAccountName')} />
                  <div className="col-md-6">
                    <label>เลขบัญชี</label>
                    <MaskInput
                      disabled={true}
                      mask={[/\d/, /\d/, /\d/, '-', /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                      placeholder=""
                      className="contractor-mask-input"
                      guide={false}
                      value={props.contractorInfo.bankAccountNo}
                      onChange={props.saveInfo('bankAccountNo')}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {props.bookBankFile}
                </div>
              </div>
              {/* <div className="row">
                <div className="form-group col-md-12">
                  <EnButton
                    className="btn-secondary"
                    onClick={props.onOpenDropzone('dropzoneBookBankOpen')}
                  >
                    อัพโหลดบุ้คแบ้งค์
                  </EnButton>
                </div>
              </div> */}

              {/* <div className="row">
                <EnButton id="contractor-edit" className="btn-info pull-right" disabled={props.validateBankInfo} onClick={props.clickSubmit}>
                  <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                </EnButton>
              </div> */}
            </FormValidation>
          </div>
        </div>
      </div>
    </div>
  </Container >
  )
};

export const IndividualInfo = (props) => {
  const selectedBank = (props.genBankOptions || []).find(bank => bank.value === props.contractorInfo.bankCode);
  const bankName = _.get(selectedBank, 'label.props.children') ? _.last(selectedBank.label.props.children) : '';
  return (
  <Container>
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header" data-background-color="orange">
            <h4 className="title">ข้อมูลส่วนตัว</h4>
            <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
          </div>
          <div className="card-content">
            <FormValidation>
              <h4>ข้อมูลส่วนตัว</h4>
              <div className="row">
                <EnTextFormGroup disabled={true} id="contractor-firstname" containerClassName="col-md-6" label="ชื่อ" value={props.contractorInfo.firstName} onChange={props.saveInfo('firstName')} validations={[required]} />
                <EnTextFormGroup disabled={true} id="contractor-surename" containerClassName="col-md-6" label="นามสกุล" value={props.contractorInfo.lastName} onChange={props.saveInfo('lastName')} validations={[required]} />
              </div>
              <div className="row">
                <EnTextFormGroup disabled={true} id="contractor-phone" containerClassName="col-md-6" disabled={true} label="เบอร์โทรศัพท์" value={props.contractorInfo.phone} size="10" maxLength="10" onChange={props.saveInfo('phone')} validations={[required, phone]} />
                <BirthdayDropdowns disabled={true} onChangeBirthday={props.onChangeBirthday} dropdownBirthday={props.dropdownBirthday} />
              </div>
              <div className="row">
                <EnTextFormGroup disabled={true} id="contractor-email" containerClassName="col-md-6" label="อีเมล (สามารถเพิ่มได้สูงสุด 5 อีเมลโดยใส่ “,” คั่น เช่น email1@gmail.com,email2@gmail.com)" value={props.contractorInfo.email} onChange={props.saveInfo('email')} validations={[email]} /> 
                <EnTextFormGroup disabled={true} maxLength={13}
                  containerClassName="col-md-6"
                  label={'หมายเลขทะเบียนรถ'}
                  value={props.contractorInfo.carId}
                  onChange={props.saveRegisterInfo('carId')}
                  notRequired={!props.carIdRequired}
                />
              </div>
              <div className="row">
                <div className="col-md-12">
                  {props.licenseCardFile}
                </div>
              </div>
              {/* <div className="row">
                <div className="form-group col-md-12">
                  <EnButton
                    className="btn-secondary"
                    onClick={props.onOpenDropzone('dropzoneLicenseCardOpen')}>
                    อัพโหลดรูปถ่ายบัตรประจำตัวช่าง
                  </EnButton>
                </div>
              </div> */}

              <h4>ที่อยู่สำหรับออกใบกำกับภาษี</h4>
              <br />
              <div className="row">
                <div style={{ paddingLeft: '15px', marginBottom: '5px', marginTop: '5px' }}>
                  <label>
                    <input
                      disabled={true}
                      type="radio"
                      value="juristic"
                      checked={props.contractorInfo.isJuristic === true}
                      onClick={props.onChangeType}
                      readOnly />&nbsp;&nbsp;นิติบุคคล/บริษัท
                  </label>
                  &nbsp;&nbsp;&nbsp;
                  <label>
                    <input
                      disabled={true}
                      type="radio"
                      value="individual"
                      checked={props.contractorInfo.isJuristic === false}
                      onClick={props.onChangeType}
                      readOnly />&nbsp;&nbsp;บุคคลธรรมดา
                  </label>
                </div>
              </div>
              <Address
                disabled={true}
                provinceName={props.provinceName}
                districtName={props.districtName}
                subDistrictName={props.subDistrictName}
                contractor={props.contractorInfo}
                onProvinceChanged={props.onProvinceChanged}
                onDistrictChanged={props.onDistrictChanged}
                onSubDistrictChanged={props.onSubDistrictChanged}
                saveRegisterInfo={(key) => props.saveRegisterInfo(key)}
              />
              <div className="row">
                <EnTextFormGroup maxLength={13}
                  disabled={true}
                  containerClassName="col-md-12"
                  label={'หมายเลขบัตรประชาชน'}
                  value={props.contractorInfo.taxNo}
                  onChange={props.saveRegisterInfo('taxNo')}
                  validations={[required]} />
              </div>

              <div>
                <div className="row">
                  <div className="form-group col-md-12">
                    <label>ลายเซ็น *</label>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-12">
                    {props.contractorInfo.signature && <img style={{ width: '200px', height: '50px', border: '1px solid #ccc', borderRadius: '4px' }} src={props.contractorInfo.signature} />
                    }
                  </div>
                </div>

                {/* <EnButton className={props.contractorInfo.signature ? 'btn btn-danger' : 'btn-secondary'} onClick={props.onOpenModal}>
                  {props.contractorInfo.signature ? 'แก้ไข' : 'เพิ่ม'}ลายเซ็น
                </EnButton> */}
              </div>
              <div className="row">
                <div className="col-md-12">
                  {props.idCardFile}
                </div>
              </div>
              {/* <div className="row">
                <div className="form-group col-md-12">
                  <EnButton
                    className="btn-secondary"
                    onClick={props.onOpenDropzone('dropzoneIdCardOpen')}>
                    อัพโหลดสำเนาบัตรประชาชน
                  </EnButton>
                </div>
              </div> */}

              &nbsp;
              <div className="row" style={{ backgroundColor: '#F2F3F4', paddingLeft: '15px' }} >
                <h4>บัญชีธนาคาร</h4>
              </div>
              <div className="row">
                <FormGroup label="ธนาคาร" containerClassName="col-md-12">
                  <input type="text"
                    className="form-control"
                    value={bankName}
                    disabled={true}
                  />
                  {/* <Select
                    searchable={false}
                    onChange={props.onSelectChange}
                    placeholder="กรุณาเลือก"
                    options={props.genBankOptions}
                    value={props.contractorInfo.bankCode} /> */}
                </FormGroup>
              </div>
              <div className="row">
                <EnTextFormGroup disabled={true} containerClassName="col-md-6" label="ชื่อบัญชี" value={props.contractorInfo.bankAccountName} onChange={props.saveInfo('bankAccountName')} />
                <div className="col-md-6">
                  <label>เลขบัญชี</label>
                  {/* 999-9-99999-9 */}
                  <MaskInput
                    disabled={true}
                    mask={[/\d/, /\d/, /\d/, '-', /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                    placeholder=""
                    className="contractor-mask-input"
                    guide={false}
                    value={props.contractorInfo.bankAccountNo}
                    onChange={props.saveInfo('bankAccountNo')}
                  // render={(ref, props) => (
                  //   <MyStyledInput innerRef={ref} {...props} />
                  // )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {props.bookBankFile}
                </div>
              </div>
              {/* <div className="row">
                <div className="form-group col-md-12">
                  <EnButton
                    className="btn-secondary"
                    onClick={props.onOpenDropzone('dropzoneBookBankOpen')}
                  >
                    อัพโหลดบุ้คแบ้งค์
                  </EnButton>
                </div>
              </div>

              <div className="row">
                <EnButton id="contractor-edit" className="btn-info pull-right" disabled={props.validateBankInfo} onClick={props.clickSubmit}>
                  <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                </EnButton>
              </div> */}
            </FormValidation>
          </div>
        </div>
      </div>
    </div>
  </Container>
)
};

export class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      showModal: false,
      files: [],
      dropzoneBookBankOpen: false,
      dropzoneIdCardOpen: false,
      dropzoneVatOpen: false,
      dropzoneCompanyCertOpen: false,
      dropzoneLicenseCardOpen: false,
      fileUploads: [],
      dropdownBirthday: {
        day: '',
        month: '',
        year: '',
        daysOfMonth: 28,
      },
    };
  }

  async setInfo(key, e) {
    if (e.target && e.target.value) {
      this.props.contractor.saveInfo(key, e.target.value);
    } else {
      this.props.contractor.saveInfo(key, e);
    }
  };

  onUploadItemClick(propName, contractor, key, data) {
    if (key === 'delete') {
      let result = contractor[propName].filter(f => f.name !== data.name);
      this.setInfo(propName, result);
    }
  };

  async onSaveDropzone(type, dropzoneFile) {
    try {
      const result = await this.props.contractor.uploadContractorFile(dropzoneFile, this.state.id);
      const contractor = this.props.contractor.toJS().info;
      let files = contractor['file'] || [];
      files = _.concat(files, result.map(f => {
        return {
          name: f.name,
          type,
          path: f.pathFile,
          date: f.date,
        };
      }));
      const fileUploads = _.concat(this.state.fileUploads, result.map(f => {
        return {
          fileName: f.name,
          fileUrl: f.path
        };
      }));
      this.setState({ fileUploads });
      this.setInfo('file', files);
    } catch (error) {
      swal.fire('อัพโหลดรูปไม่สำเร็จ', '', 'error');
    } finally {
      this.setState({
        dropzoneBookBankOpen: false,
        dropzoneIdCardOpen: false,
        dropzoneCompanyCertOpen: false,
        dropzoneVatOpen: false,
        dropzoneLicenseCardOpen: false,
      });
    }
  }

  initListItemHeaderOption = (title) => {
    return [
      {
        title: title,
        size: 9,
        key: 'filename',
        showIcon: true,
        downloadLink: true,
        appendDescription: true,
        descriptionKey: 'date',
      }, {
        title: '',
        size: 3,
        key: 'delete',
        className: 'text-right',
        itemClassName: 'text-right',
      },
    ];
  };

  toListItemData(files, longFileName) {
    if (Array.isArray(files) && files.length) {
      const fileUploads = this.state.fileUploads || [];
      return files.filter(f => (f && f.name)).map((f, idx) => {
        const fileUpload = fileUploads.find(item => item.fileName === f.name);
        const url = fileUpload ? fileUpload.fileUrl : f.url;
        return {
          filename: (idx + 1) + '_' + (longFileName ? f.name : f.name.substring(14, f.name.length)),
          name: f.name,
          url,
          date: f.date ? `${moment(f.date).format('DD/MM/YYYY HH:mm:ss')}` : '',
          // delete: <i className="fa fa-trash" />,
          icon: common.getFileIcon(f.name),
          downloadHref: url,
          type: f.type,
        };
      });
    }
    return [];
  };

  getListItem = (itemHeader, itemData, type, callback) => {
    const data = itemData.filter(i => i.type === type);
    return (
      <div>
        <ListItem
          header={itemHeader}
          data={data}
          onClick={callback}
          target="_blank" />
        {!data || !data.length ? this.getDisplayEmpty('ไม่มีรูปถ่ายหรือไฟล์แนบ') : ''}
      </div>
    );
  };

  getDisplayEmpty = (message) => {
    return <div><em><small>{message}</small></em></div>;
  };

  saveInfo(key, e) {
    this.props.contractor.saveInfo(key, e.target.value);
  }

  saveTypeOfWork(item, e) {
    if (item) {
      this.props.contractor.saveInfo('typeOfWork', item.id);
    }
  }

  validation() {
    return this.props.contractor.isValidRegisterInfo(1) && 
      !isNaN(parseInt(this.state.dropdownBirthday.year));
  }

  onChangeType(e) {
    let value = false;
    if (e.target.value === 'juristic') {
      value = true;
    }
    this.props.contractor.saveInfo('isJuristic', value);
  }

  onSubmit() {
    const contractorId = this.state.id;
    const calendarFilter = this.props.calendar.toJS().calendarFilter;
    let qs = {};
    if (calendarFilter) {
      qs = calendarFilter;
    }
    this.noti.success('แก้ไขข้อมูลเรียบร้อย', 'สำเร็จ');
    setTimeout(() => {
      this.props.history.push(`${config.publicUrl}/contractors/${contractorId}/calendar?${common.urlBuilder(qs)}`);
    }, 1000);
  }

  async componentWillUnmount() {
    await this.props.contractor.clearInfo();
  }

  async clickSubmit() {
    try {
      // await this.props.contractor.validateTeamName(this.state.id);
      await this.props.contractor.updateContractorInfo(this.state.id, 'profile');
      this.onSubmit();
    } catch (error) {
      this.noti.error(error.message);
    }
  }

  setDefaultValue() {
    this.props.contractor.saveMultiInfo({
      provinceId: '10',
      taxProvinceCode: '10',
      districtId: '1001',
      taxDistrictCode: '1001',
      subDistrictId: '100101',
      taxSubDistrictCode: '100101',
      zipCode: '10200',
      taxZipCode: '10200',
      taxProvinceName: 'กรุงเทพมหานคร',
      taxDistrictName: 'พระนคร',
      taxSubDistrictName: 'พระบรมมหาราชวัง',
    });
  }

  async componentDidMount() {
    try {
      const data = await this.props.contractor.getContractor({
        id: this.state.id,
      });
      this.setDefaultDropdownBirthday(data);
      await this.props.bank.getAllBank();
      await this.props.typeofwork.getTypeOfWorks({ sort: 'name' });
      await this.props.misc.getAllProvince();
      if (data && data.tax && data.tax.address && (data.tax.address.province &&
        data.tax.address.province.code) && (data.tax.address.district && data.tax.address.district.code)) {
        await this.props.misc.getDistrictByCode(data.tax.address.province.code);
        await this.props.misc.getSubDistrictByCode(data.tax.address.district.code);
      } else {
        await this.props.misc.getDistrictByCode('10');
        await this.props.misc.getSubDistrictByCode('1001');
        await this.setDefaultValue();
      }
      const fileUploads = await this.props.download.getAllSignUrl(`contractors/${this.state.id}/upload`);
      this.setState({ fileUploads })
    } catch (error) {
      this.noti.error(error.message);
    }
  }

  setDefaultDropdownBirthday(contractorData) {
    if (typeof contractorData.date_of_birth == 'string') {
      contractorData.date_of_birth = new Date(contractorData.date_of_birth);
    }
    if (contractorData.date_of_birth != 'Invalid Date') {
      const dateOfBirth = contractorData.date_of_birth;
      const year = parseInt(dateOfBirth.getFullYear());
      let month = parseInt(dateOfBirth.getMonth() + 1);
      let day = parseInt(dateOfBirth.getDate());
      const daysOfMonth = new Date(year, month, 0).getDate();
      if (contractorData.date_of_birth_flag === 1) {
        day = '-';
      } else if (contractorData.date_of_birth_flag === 2) {
        day = '-';
        month = '-';
      } else if (contractorData.date_of_birth_flag === 3) {
        month = '-';
      }
      this.setState({ 
        dropdownBirthday: {
          year,
          month,
          day,
          daysOfMonth,
        }
      });
    }
  }

  async saveSubDistrict() {
    const { miscData } = this.props.misc.toJS();
    const { code, name, zipcodes } = miscData.subDistrict[0];
    const taxZipCode = zipcodes[0].zipcode;
    this.props.contractor.saveMultiInfo({
      taxSubDistrictCode: code,
      taxSubDistrictName: name,
      taxZipCode,
    });
  }

  async onDistrictChanged(e) {
    const { miscData } = this.props.misc.toJS();
    const code = e.target.value;
    if (miscData && miscData.district) {
      const districtName = miscData.district.find(d => d.code === code);
      this.props.contractor.saveMultiInfo({
        taxDistrictCode: code,
        taxDistrictName: districtName.name,
      });
    }
    await this.props.misc.getSubDistrictByCode(code);
    await this.saveSubDistrict();
  }

  async saveDistrict() {
    const { miscData } = this.props.misc.toJS();
    const { code, name } = miscData.district[0];
    await this.props.misc.getSubDistrictByCode(code);
    this.props.contractor.saveMultiInfo({
      taxDistrictCode: code,
      taxDistrictName: name,
    });
  }

  async onProvinceChanged(e) {
    const { miscData } = this.props.misc.toJS();
    const code = e.target.value;
    if (miscData && miscData.province) {
      const provinceName = miscData.province.find(p => p.code === code);
      this.props.contractor.saveMultiInfo({
        taxProvinceCode: code,
        taxProvinceName: provinceName.name,
      });
    }
    await this.props.misc.getDistrictByCode(code);
    await this.saveDistrict();
    await this.saveSubDistrict();
  }

  async onSubDistrictChanged(e) {
    let miscStore = this.props.misc.toJS();
    let miscData = miscStore.miscData;
    let code = e.target.value;
    const subDistrictName = miscData.subDistrict.find(s => s.code === code);
    await this.props.contractor.saveMultiInfo({
      taxSubDistrictCode: code,
      taxSubDistrictName: subDistrictName.name,
    });
  }

  getBirthdayFlag(year, month, day) {
    const isNumDay = !isNaN(parseInt(day));
    const isNumMonth = !isNaN(parseInt(month));
    const isNumYear = !isNaN(parseInt(year));
    if (isNumDay && isNumMonth && isNumYear) {
      return 0;
    } else if (isNumMonth && isNumYear) {
      return 1;
    } else if (isNumDay && isNumYear) {
      return 3;
    } else if (isNumYear) {
      return 2;
    }
    return -1;
  }

  async onChangeBirthday(e, type) {
    e.persist();
    let dropdownBirthday;
    await this.setState(
      (prevState, _props) => {
        dropdownBirthday = {...prevState.dropdownBirthday};
        dropdownBirthday[type] = e.target.value;
        dropdownBirthday.daysOfMonth = new Date(
          dropdownBirthday.year === '-' ? 2000 : dropdownBirthday.year, 
          dropdownBirthday.month === '-' ? 1 : dropdownBirthday.month, 
          0
        ).getDate();
        return { dropdownBirthday };
      }
    );

    dropdownBirthday = {...dropdownBirthday};
    const dateOfBirthFlag = this.getBirthdayFlag(
      dropdownBirthday.year,
      dropdownBirthday.month,
      dropdownBirthday.day
    );
    if (isNaN(parseInt(dropdownBirthday.day))) {
      dropdownBirthday.day = '1';
    }
    if (isNaN(parseInt(dropdownBirthday.month))) {
      dropdownBirthday.month = '1';
    }
    const dateOfBirth = new Date(
      dropdownBirthday.year,
      Number(dropdownBirthday.month) - 1,
      dropdownBirthday.day,
    );
    await this.props.contractor.saveMultiInfo({
      dateOfBirth,
      dateOfBirthFlag
    });
  }

  saveRegisterInfo(key, e) {
    this.props.contractor.saveInfo(key, e.target.value);
  }

  genBankOptions = () => {
    const bank = this.props.bank.toJS().info;
    return (bank || []).map((b, index) => {
      return {
        label: <div><img src={b.image_url} alt="" style={{ backgroundColor: b.image_color, height: '30px', width: '30px' }} />&nbsp;&nbsp;&nbsp;{b.bank_name}</div>,
        value: b.bank_code,
      };
    });
  };

  onSelectChange = (e) => {
    this.props.contractor.saveInfo('bankCode', e.value);
  }
  onHide = () => {
    this.setState({ showModal: false });
  }

  showSignatureModal = () => (<div>
    <Modal
      className="custom-modal"
      show={this.state.showModal}
      onHide={this.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="ModalHeader">เซ็น</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-canvas">
          <div className="canvas-sig">
            <SignatureCanvas ref={(ref) => { this.sigCanvas = ref; }} canvasProps={{ className: 'canvas-pad' }} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ textAlign: 'center', paddingBottom: 20, paddingTop: 50 }}>
        <EnButton className="btn btn-success" onClick={this.clickSubmitSignature.bind(this)}>
          <i className="fa fa-pencil-square-o" aria-hidden="true" />  บันทึกลายเซ็น
        </EnButton>
        <EnButton className="btn btn-warning" onClick={this.clearSignature.bind(this)}>
          <i className="fa fa-eraser" aria-hidden="true" /> ลบ
        </EnButton>
      </Modal.Footer>
    </Modal>
  </div>)

  onOpenModal = () => {
    this.setState({ showModal: true });
  }

  async clickSubmitSignature() {
    this.props.contractor.saveInfo('signature', this.sigCanvas.getTrimmedCanvas().toDataURL('image/png'));
    this.setState({ showModal: false });
  }

  clearSignature = () => {
    this.sigCanvas.clear();
  }

  onOpenDropzone(key) {
    this.setState({
      [`${key}`]: true,
    });
  }

  closeDropzone(key) {
    this.setState({
      [`${key}`]: false,
    });
  }

  render() {
    const contractorStore = this.props.contractor.toJS();
    const contractorInfo = contractorStore.info;
    const miscStore = this.props.misc.toJS();
    const miscData = miscStore.miscData;
    const provinceName = miscData.province.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });
    const districtName = miscData.district.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });
    const subDistrictName = miscData.subDistrict.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });
    const fileData = this.toListItemData(contractorInfo.file);
    const bookBankHeader = this.initListItemHeaderOption('ไฟล์ บุ้คแบ้งค์');
    const idCardHeader = this.initListItemHeaderOption('ไฟล์ สำเนาบัตรประชาชน');
    const companyCertHeader = this.initListItemHeaderOption('ไฟล์ หนังสือรับรองบริษัท');
    const vatHeader = this.initListItemHeaderOption('ไฟล์ ภพ.20');
    const licenseCard = this.initListItemHeaderOption('รูปถ่ายบัตรประจำตัวช่าง (ถ้ามี)');
    const bookBankFile = this.getListItem(bookBankHeader, fileData, 'book_bank', this.onUploadItemClick.bind(this, 'file', contractorInfo));
    const idCardFile = this.getListItem(idCardHeader, fileData, 'id_card', this.onUploadItemClick.bind(this, 'file', contractorInfo));
    const vatFile = this.getListItem(vatHeader, fileData, 'vat', this.onUploadItemClick.bind(this, 'file', contractorInfo));
    const companyCertFile = this.getListItem(companyCertHeader, fileData, 'company_cert', this.onUploadItemClick.bind(this, 'file', contractorInfo));
    const licenseCardFile = this.getListItem(licenseCard, fileData, 'license_card', this.onUploadItemClick.bind(this, 'file', contractorInfo));

    return (
      <div>
        <Notification ref={(ref) => { this.noti = ref; }} />
        <Loader show={contractorStore.isLoading || contractorStore.isSumitting} />
        {this.showSignatureModal()}
        <EnDropzoneDialog
          dialogTitle="อัพโหลดบุ้คแบ้งค์"
          dropzoneText="เลือกไฟล์"
          open={this.state.dropzoneBookBankOpen}
          onSave={this.onSaveDropzone.bind(this, 'book_bank')}
          onClose={this.closeDropzone.bind(this, 'dropzoneBookBankOpen')}
        />
        <EnDropzoneDialog
          dialogTitle="หนังสือรับรองบริษัท"
          dropzoneText="เลือกไฟล์"
          open={this.state.dropzoneCompanyCertOpen}
          onSave={this.onSaveDropzone.bind(this, 'company_cert')}
          onClose={this.closeDropzone.bind(this, 'dropzoneCompanyCertOpen')}
        />
        <EnDropzoneDialog
          dialogTitle="ภพ.20"
          dropzoneText="เลือกไฟล์"
          open={this.state.dropzoneVatOpen}
          onSave={this.onSaveDropzone.bind(this, 'vat')}
          onClose={this.closeDropzone.bind(this, 'dropzoneVatOpen')}
        />
        <EnDropzoneDialog
          dialogTitle="สำเนาบัตรประชาชน"
          dropzoneText="เลือกไฟล์"
          open={this.state.dropzoneIdCardOpen}
          onSave={this.onSaveDropzone.bind(this, 'id_card')}
          onClose={this.closeDropzone.bind(this, 'dropzoneIdCardOpen')}
        />
        <EnDropzoneDialog
          dialogTitle="รูปถ่ายบัตรประจำตัวช่าง"
          dropzoneText="เลือกไฟล์"
          open={this.state.dropzoneLicenseCardOpen}
          onSave={this.onSaveDropzone.bind(this, 'license_card')}
          onClose={this.closeDropzone.bind(this, 'dropzoneLicenseCardOpen')}
        />

        {
          contractorInfo.isJuristic ? (<JuristicInfo
            provinceName={provinceName}
            districtName={districtName}
            subDistrictName={subDistrictName}
            saveInfo={(key) => this.saveInfo.bind(this, key)}
            saveRegisterInfo={(key) => this.saveRegisterInfo.bind(this, key)}
            contractorInfo={contractorInfo}
            onProvinceChanged={this.onProvinceChanged.bind(this)}
            onDistrictChanged={this.onDistrictChanged.bind(this)}
            onChangeBirthday={this.onChangeBirthday.bind(this)}
            dropdownBirthday={this.state.dropdownBirthday}
            onSubDistrictChanged={this.onSubDistrictChanged.bind(this)}
            clickSubmit={this.clickSubmit.bind(this)}
            onChangeType={this.onChangeType.bind(this)}
            genBankOptions={this.genBankOptions()}
            onSelectChange={this.onSelectChange.bind(this)}
            validateBankInfo={!this.validation()}
            bookBankFile={bookBankFile}
            vatFile={vatFile}
            companyCertFile={companyCertFile}
            licenseCardFile={licenseCardFile}
            onOpenDropzone={(key) => this.onOpenDropzone.bind(this, key)}
          />)
            : (<IndividualInfo
              provinceName={provinceName}
              districtName={districtName}
              subDistrictName={subDistrictName}
              saveInfo={(key) => this.saveInfo.bind(this, key)}
              saveRegisterInfo={(key) => this.saveRegisterInfo.bind(this, key)}
              contractorInfo={contractorInfo}
              onProvinceChanged={this.onProvinceChanged.bind(this)}
              onDistrictChanged={this.onDistrictChanged.bind(this)}
              onChangeBirthday={this.onChangeBirthday.bind(this)}
              dropdownBirthday={this.state.dropdownBirthday}
              onSubDistrictChanged={this.onSubDistrictChanged.bind(this)}
              clickSubmit={this.clickSubmit.bind(this)}
              onChangeType={this.onChangeType.bind(this)}
              genBankOptions={this.genBankOptions()}
              onSelectChange={this.onSelectChange.bind(this)}
              validateBankInfo={!this.validation()}
              onOpenModal={this.onOpenModal.bind(this)}
              bookBankFile={bookBankFile}
              showModal={this.state.showModal}
              idCardFile={idCardFile}
              licenseCardFile={licenseCardFile}
              onOpenDropzone={(key) => this.onOpenDropzone.bind(this, key)}
            />)
        }
      </div >
    );
  }
}

export default inject('contractor', 'auth', 'calendar', 'typeofwork', 'misc', 'bank', 'download')(observer(Profile));
