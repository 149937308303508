import React from 'react'
import QRCode from 'qrcode.react'
import { Modal, Button } from 'react-bootstrap';

export class ModalQrcode extends React.Component {
    render() {
        const { setModal, showModal = false, title = 'title', url = '' } = this.props
        return (
            <Modal show={showModal}
                onHide={e => { setModal(false) }}
            >
                <Modal.Header closeButton={false} style={{ backgroundColor: '#f3873a',paddingTop:12,paddingBottom:12 }}>
                    <Modal.Title style={{ color: 'white' }}>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!url &&
                        <div className="alert alert-danger" role="alert">
                            เกิดข้อผิดพลาด ไม่สามารถสร้าง QRCode ได้
                            โปรดติดต่อ ทีมพัฒนา
                    </div>
                    }
                    {url &&
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <QRCode value={url} size={256} />
                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary btn-danger" title="ปิด" data-tip="ปิด" onClick={e => { setModal(false) }} style={{ marginRight: 15 }}>
                        {/* <span className="fa fa-times btn-icon" aria-hidden="true" /> */}
                        ยกเลิก
                    </button>
                    {/* <button className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={e => { setModal(false) }} style={{ marginRight: 17 }}>
                        <span className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
               </button> */}
                </Modal.Footer>
            </Modal>
        )
    }
}

export default ModalQrcode