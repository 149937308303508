
export class Address {
  genContractorTaxAddress(addressInfo) {
    const province = addressInfo && addressInfo.province ? addressInfo.province.name : null;
    const district = addressInfo && addressInfo.district ? addressInfo.district.name : null;
    const subDistrict = addressInfo && addressInfo.sub_district ? addressInfo.sub_district.name : null;
    const postCode = addressInfo ? addressInfo.zip_code : null;
    let address;
    if (province && district && subDistrict && postCode) {
      address = `${addressInfo.address} ${subDistrict} ${district} ${province} ${postCode}`;
    } else if (addressInfo.address) {
      address = `${addressInfo.address}`;
    }
    return address;
  }
}

export const address = new Address();
export default address;
