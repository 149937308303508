import React from 'react';
import { Modal } from 'react-bootstrap';
import EnButton from '../form/EnButton';
import { observer, inject } from 'mobx-react';

class ModalAuth extends React.Component {
    
    handleClose() {
        this.props.permission.setShowModal(false)
        this.props.permission.setPassword('')
    }

    toggleVisibility() {
        const passwordInput = document.getElementById("input-password")
        const iconsInput = document.getElementById("input-icons")
        if(passwordInput.type === "password") {
            passwordInput.type = "text"
            iconsInput.innerText = "visibility_off"
        } else {
            passwordInput.type = "password"
            iconsInput.innerText = "visibility"
        }
    }

    render() {
        const { openModal, passwordLogActivity, statusPassword } = this.props.permission.toJS()
        return (
            <Modal show={openModal}>
                <div className="position-modal-center">
                        <h3>กรุณากรอกรหัสผ่าน</h3>
                    <input
                        type="password"
                        id="input-password"
                        placeholder=""
                        value={passwordLogActivity}
                        onChange={(e) => {
                            this.props.permission.setPassword(e.target.value)
                        }}
                        />
                    <i id="visibilityBtn" onClick={this.toggleVisibility.bind(this)}>
                        <span id="input-icons" className="material-icons icons-eyes">visibility</span>
                    </i>
                    { statusPassword.status &&
                        <div className="input-text-error">
                            <span className="input-error">{statusPassword.text}</span>
                        </div>
                    }
                    <div style={{ marginTop: "3vw"}}>
                        <EnButton className="btn-danger" onClick={this.handleClose.bind(this)}>
                            <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                        </EnButton>
                        <EnButton className="btn-info" onClick={this.props.onSubmit}>
                            <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                        </EnButton>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default inject('permission')(observer(ModalAuth));;