import React, { useEffect, useState } from 'react';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const CheckboxGroup = ({
  label = '',
  checked = false,
  indeterminate = false,
  isShowCounter = false,
  counter = { checked: 0, unChecked: 0 },
  isExpandAble = false,
  isExpand = false,
  disabled = false,
  onExpand,
  onChange,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleCheckboxChange = (event) => {
    const newChecked = event.target.checked;
    setIsChecked(newChecked);
    if (onChange) {
      onChange(newChecked);
    }
  };

  const handleExpand = () => {
    if (onExpand) {
      onExpand();
    }
  };

  return (
    <div className="checkbox-container">
      <input
        id="checkbox-input"
        className="checkbox-input"
        type="checkbox"
        disabled={disabled}
        checked={isChecked}
        onChange={handleCheckboxChange}
        ref={(el) => {
          if (el) {
            el.indeterminate = indeterminate;
          }
        }}
      />
      <label>
        <span>{label}</span>
        {isShowCounter && (
          <span className="counter">{`(${counter.checked}/${counter.unChecked})`}</span>
        )}
      </label>

      {isExpandAble &&
        (isExpand ? (
          <ExpandLessIcon className="expand-icon" onClick={handleExpand} />
        ) : (
          <ExpandMoreIcon className="expand-icon" onClick={handleExpand} />
        ))}
    </div>
  );
};

export default CheckboxGroup;
