import * as _ from 'lodash';
import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';

export class QocBroadcastStore extends BaseStore {
  async getAll(params) {
    try {
      const queryString = http.parseQueryString(params);
      const res = await http.get(`${config.api.gateway}/portal/v1/announcements/customers` + '?' + queryString);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async get(id) {
    try {
      const res = await http.get(`${config.api.gateway}/portal/v1/announcements/${id}/customers`);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async create(data) {
    try {
      const res = await http.post(`${config.api.gateway}/portal/v1/announcements/customers`, {}, data);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      if (!http.isValidStatus(res.status)) {
        throw new Error(res.data.message);
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async update(id, data) {
    try {
      const res = await http.patch(`${config.api.gateway}/portal/v1/announcements/${id}/customers`, {}, data);

      if (res.status === 200 && res.data) {
        return res.data;
      }

      if (!http.isValidStatus(res.status)) {
        throw new Error(res.data.message);
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async delete(id) {
    try {
      const res = await http.delete(`${config.api.gateway}/portal/v1/announcements/${id}/customers`);

      if (res.status === 200) {
        return true;
      }
      return false;
    } catch (err) {
      throw err;
    }
  }

  async getCouponGroupArguments() {
    try {
      const res = await http.get(`${config.api.gateway}/portal/v1/coupon-groups`);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async getArguments() {
    try {
      const res = await http.get(`${config.api.gateway}/portal/v1/announcements/arguments/customers`);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async getDesignations() {
    try {
      const res = await http.get(`${config.api.gateway}/portal/v1/announcements/destinations/customers`);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async getCategoryViews() {
    try {
      const res = await http.get(`${config.api.gateway}/portal/v1/category-views`);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }
  async getPromotions() {
    try {
      const res = await http.get(`${config.api.gateway}/portal/v1/promotions`);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async getProducts() {
    try {
      const res = await http.get(`${config.api.gateway}/portal/v1/products`);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async uploadThumbnail(file) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const res = await http.postMultipartFormData(`${config.api.gateway}/portal/v1/announcements/upload/thumbnail/customers`, formData);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }

  async uploadReceivers(file) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const res = await http.postMultipartFormData(`${config.api.gateway}/portal/v1/announcements/upload/receivers/customers`, formData);

      if (res.status === 200 && res.data) {
        return res.data;
      }
      return undefined;
    } catch (err) {
      throw err;
    }
  }
}
export default new QocBroadcastStore();
