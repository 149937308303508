// eslint-disable-next-line
import { createBrowserHistory } from 'history';

export class HistoryUtils {
  getObject() {
    try {
      return createBrowserHistory();
    } catch (error) {
      // eslint-disable-next-line
      return;
    }
  }

  replace(url) {
    const history = createBrowserHistory({ forceRefresh: true });
    history.replace(url);
  }

  push(url) {
    const history = createBrowserHistory({ forceRefresh: true });
    history.push(url);
  }
}

export const History = new HistoryUtils();
export default History.getObject();
