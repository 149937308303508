import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Loader from '../../../components/common/Loader';
import Container from '../../../layouts/Container';
import SetBannerWebsite from './SetBannerWebsite';
import SetBannerApplication from './SetBannerApplication';
import { Tab } from 'react-bootstrap';
import { EnTab } from '../../../components/form/EnTab';
class SetBannerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    }
  }

  render() {
    const { isLoading } = this.props.promotionconfig.toJS();

    return (
      <Container isAdmin>
        <div style={{ minHeight: '80vh' }} className="row">
          <Loader show={isLoading} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">Set Banner</h4>
              </div>
              <div className="col-md-12">
                <EnTab onClick={(index) => { this.setState({ tabIndex: index}); console.log(index); }} style={{marginTop: '15px'}}>
                  <Tab title="Website">
                    { this.state.tabIndex == 0 && <SetBannerWebsite /> }
                  </Tab>
                  <Tab title="Application">
                    { this.state.tabIndex == 1 && <SetBannerApplication /> }
                  </Tab>
                </EnTab>
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

export default inject('promotionconfig')(observer(SetBannerPage));
