import * as _ from 'lodash';
import React from 'react';
import { observer, inject } from 'mobx-react';
import EnButton from '../../../components/form/EnButton';
import EnToggle from '../../../components/form/EnToggle';
import Container from '../../../layouts/Container';
import authStore from '../../../stores/AuthStore';
import Notification from '../../../components/common/Notification';
import Loader from '../../../components/common/Loader';
import config from '../../../config';
import ModalAuth from '../../../components/common/ModalAuth';
import { isAllow } from '../../../utils/permission';
import FormGroup from '../../../components/form/FormGroup';
import EnText from '../../../components/form/EnText';
import SimplePagination from '../../../components/common/SimplePagination';

export class ContractorCost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      contractors: [],
      storeId: '',
      index: 0,
      search: '',
      query: {
        search: '',
        limit: 10,
        page: 1,
        totalPages: 1,
        totalItems: 1
      },
    };
    this.myRef = {}
  }

  async componentWillMount() {
    const store = authStore.getStore();
    this.getPaginatedContractors()

    if (store) {
      // const contractor = await this.props.contractor.toJS();
      // if (Array.isArray(store.contractors) && store.contractors.length && contractor.list && contractor.list.length) {
      //   const contractors = contractor.list.filter(c => {
      //     if (_.indexOf(store.contractors, c._id) >= 0) {
      //       return true;
      //     }
      //     return false;
      //   });
      //   this.setState({ contractors });
      // }
      this.setState({ storeId: store._id });
    }
  }

  getPaginatedContractors = async () => {
    this.setState({ loading: true })
    const store = authStore.getStore();
    const { items, limit, page, totalPages, totalItems } = await this.props.contractor.getPaginatedContractors({
      storeId: store._id,
      filterByArea: true,
      ...this.state.query
    });

    this.setState({
      contractors: items,
      query: {
        ...this.state.query,
        limit,
        page,
        totalPages,
        totalItems,
      }
    })

    this.setState({ loading: false })
  }

  onEdit = (id) => {
    this.props.history.push(`${config.publicUrl}/admin/contractor/cost/${id}`);
  }

  async onSubmitmodal(index, id, storeId, storeName) {
    const { initInfo } = this.props.permission.toJS()
    let toggle = this.myRef[index].current.props.checked
    initInfo.event = "SKU for shop"
    initInfo.function_name = "change_toggle"
    initInfo.reference_code = storeName
    initInfo.remark = `Toggle update price by SKU is changed from ${toggle ? 'on': 'off'} to ${!toggle ? 'on': 'off'} by `
    this.props.permission.setInfoLogActivity([initInfo])
    let status = await this.props.permission.verifyPassword()
    if(status) {
      this.onSkuToggle(index, id, storeId, !toggle)
      this.props.permission.saveUamActivityLogs()
    }
  }

  onSkuToggle = async (index, id, storeId, value) => {
      await this.props.contractor.toggleSkuCost(index, id, storeId, value);
       setTimeout(() => {
          window.location.reload();
       }, 500);
  }

  onSkuChecked = (contractor) => {
    if (!contractor.cost || contractor.cost.length === 0) {
      return true;
    }
    const [storeCost] = contractor.cost.filter(c => c.store_id === this.state.storeId);
    if (storeCost) {
      return storeCost.cost_adjustable;
    }
  }

  onUpdateSearch = (search) => {
    this.setState({
      search
    })
  }

  onUpdateQuery = (key, value) => {
    const query = this.state.query
    _.set(query, key, value)
    this.setState(query)
  }

  onClickSearch = () => {
    this.onUpdateQuery('search', this.state.search)
    this.onUpdateQuery('page', 1)
    this.getPaginatedContractors()
  }
  
  onSetPage = (page) => {
    this.onUpdateQuery('page', page)
    this.getPaginatedContractors()
  }

  genRow = () => {
    const store = authStore.getStore();

    return this.state.contractors.map((i, index) => {
      this.myRef[index] = React.createRef();
      return (
        <tr key={`name${index}`}>
          <td>{i.team_name}</td>
          {/* <td className="text-center">
            <EnToggle
              ref={this.myRef[index]}
              checked={this.onSkuChecked(i) || false}
              onClick={()=> {
                this.setState({ index: index })
                this.props.permission.setShowModal(true)
              }}
               />
          </td> */}
          {index === this.state.index  && <ModalAuth onSubmit={this.onSubmitmodal.bind(this, index, i._id, this.state.storeId, store.name)}/>}
          <td className="text-center">
            <EnButton title="แก้ไข" className="btn btn-xs" onClick={(e) => { e.preventDefault(); this.onEdit(i._id); }}>
              <span className="fa fa-pencil" aria-hidden="true" />
            </EnButton>
          </td>
        </tr>
      );
    });
  }

  render() {
    return (
      <Container isAdmin>
        <Loader show={this.state.loading} />
        <Notification ref={(ref) => { this.noti = ref; }} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">กรุณาเลือกทีมช่าง</h4>
                <p className="category">เพื่อระบุราคาจ่ายช่างแต่ละทีม</p>
              </div>
              <div className="card-content">
                <div className="row">
                  <FormGroup label="ค้นหา:" containerClassName="col-md-4">
                    <div className="form-group has-feedback has-clear">
                      <div className='input-group'>
                        <EnText className='form-control' placeholder='ค้นหาด้วยชื่อ ชื่อทีม หรือเบอร์โทร' value={this.state.search} onChange={(e) => this.onUpdateSearch(e.target.value)} onKeyPress={(e) => { if (e.key === 'Enter') this.onClickSearch() }}  />
                        <span className='input-group-btn'>
                          <button
                            id='search-button'
                            type='button'
                            className='btn btn-primary btn-outline-secondary btn-custom'
                            onClick={() => { this.onClickSearch() }}
                          >
                            <i className='fa fa-search' aria-hidden='true' />
                          </button>
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th className="text-center">ทีมช่าง</th>
                            {/* <th width="15%" className="text-center">ปรับราคาตาม sku</th> */}
                            <th width="10%" className="text-center" />
                          </tr>
                        </thead>
                        <tbody>
                          {this.genRow()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <SimplePagination pagination={this.state.query} onSetPage={this.onSetPage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('admin', 'contractor', 'permission')(observer(ContractorCost));
