import React, { Component } from 'react';
import MobileDetect from 'mobile-detect';
import _ from 'lodash';

export default class CallingButton extends Component {
  constructor(props) {
    super(props);
    const md = new MobileDetect(window.navigator.userAgent);
    this.state = {
      isMobileOrTablet: !!(md.mobile() || md.tablet()),
    };
  }

  render() {
    const { phone, isButtonGroup } = this.props;
    let btn = '';
    if (isButtonGroup) {
      btn = (
        <a className="btn btn-warning call-btn" href={`tel:${phone}`} >
          <i className="fa fa-phone fa-2" aria-hidden="true" />
        </a>
      );
    } else {
      btn = (
        <a className="btn btn-warning call-btn no-margin-top" href={`tel:${phone}`} >
          <i className="fa fa-phone fa-2" aria-hidden="true" />
        </a>
      );
    }
    return (this.state.isMobileOrTablet && !_.isEmpty(phone)) && btn;
  }
}
