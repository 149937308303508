import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import qs from 'query-string';

import Container from '../../../layouts/Container';
import Notification from '../../../components/common/Notification';
import Loader from '../../../components/common/Loader';
import EnToggle from '../../../components/form/EnToggle';
import config from '../../../config';
import { isAllow } from '../../../utils/permission';

export class ManageProject extends Component {
  constructor(props) {
    super(props);
    const query = qs.parse(this.props.history.location.search);
    this.state = {
      solutionId: query.solution_id,
    };
  }

  async componentDidMount() {
    if (!isAllow('VIEW_A_LIST_OF_PORTFOLIO_SOTLUIONS')) {
      return;
    }
    const { solutionId } = this.state;
    try {
      await this.props.project.getProjectsBySolutionId(solutionId);
      await this.props.solution.getSolutionById(solutionId);
    } catch (err) {
      this.noti.error(err.message);
    }
  }

  onAddNewClick(solutionId) {
    this.props.history.push(`${config.publicUrl}/qchang/project/add?solution_id=${solutionId}`);
  }

  onEditClick(id) {
    this.props.history.push(`${config.publicUrl}/qchang/project/${id}/edit`);
  }

  onActiveToggle(index, id, value) {
    this.props.project.toggleStatus(index, id, value ? true : false);
  }

  getTableRows(items) {
    return (items || []).map((item, index) => {
      return (
        <tr key={item._id}>
          <td className="text-center">{index + 1}</td>
          <td>{item.title}</td>
          <td className="text-center"><img src={item.image_url} style={{width: '30%'}} alt="" /></td>
          <td className="text-center">{item.active ? 'เปิด' : 'ปิด'}</td>
          <td className="text-center">
            <EnToggle
              checked={item.active === true}
              onChange={this.onActiveToggle.bind(this, index, item._id)}
              disabled={!isAllow('EDIT_PROJECT_SOLUIONS_STATUS')}
              />
          </td>
          <td className="text-center">
            <button
              className="btn btn-xs"
              title="แก้ไข"
              data-tip="แก้ไข"
              onClick={this.onEditClick.bind(this, item._id)}
              disabled={!isAllow('EDIT_PROJECT_SOLUTIONS')}>
              <span className="fa fa-pencil" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { items, isLoading } = this.props.project.toJS();
    const { info: solutionInfo } = this.props.solution.toJS();
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={isLoading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายการผลงานโซลูชั่น{solutionInfo ? solutionInfo.name.th : ''}</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    <table className="table table-bordered table-hover has-pagination">
                      <thead>
                        <tr>
                          <th width="7%" className="text-center">ลำดับที่</th>
                          <th width="50%" className="text-center">ชื่อผลงาน</th>
                          <th width="30%" className="text-center">รูปภาพหลัก</th>
                          <th width="7%" className="text-center">สถานะ</th>
                          <th width="10%" className="text-center">
                            <button className="btn btn-success"
                              title="สร้างใหม่"
                              data-tip="สร้างใหม่"
                              onClick={this.onAddNewClick.bind(this, this.state.solutionId)}
                              disabled={!isAllow('CREATE_PROJECT_SOLUTIONS')}
                            >
                              <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.getTableRows(items)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('project', 'solution')(observer(ManageProject));
