import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import Notification from '../../../components/common/Notification';
import config from '../../../config';

export class ManageGuarantee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onAddNew() {
    this.props.history.push(`${config.publicUrl}/qchang/guarantee/add`);
  }

  onEdit(item) {
    if (item) {
      this.props.history.push(`${config.publicUrl}/qchang/guarantee/${item.code}/edit`);
    }
  }

  async componentDidMount() {
    try {
      await this.props.guarantee.getAllGuarantee();
    } catch (err) {
      this.noti.error(err.message);
    }
  }

  getTableRows(items) {
    return items.map((item, index) => {
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td className="text-center">{item.code}</td>
          <td>{item.name}</td>
          <td className="text-center">
            <button className="btn btn-xs" title="แก้ไข" data-tip="แก้ไข" onClick={this.onEdit.bind(this, item)}>
              <span className="fa fa-pencil" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  render() {
    const { guaranteeData } = this.props.guarantee.toJS();
    // console.log('guaranteeData ==> ', guaranteeData);
    // let items = this.props.productgroup.toJS().items;
    let rows = this.getTableRows(guaranteeData);

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ใบรับประกัน</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="5%" className="text-center">ลำดับที่</th>
                            <th width="10%" className="text-center">รหัส</th>
                            <th width="40%" className="text-center">ชื่อใบรับประกัน</th>
                            <th width="5%" className="text-center">
                              <button className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={this.onAddNew.bind(this)}>
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('guarantee')(observer(ManageGuarantee));
