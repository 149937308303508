// import * as _ from 'lodash';
import React from 'react';

import { observer, inject } from 'mobx-react';
import EnButton from '../../../components/form/EnButton';
import Container from '../../../layouts/Container';
import Notification from '../../../components/common/Notification';
import Loader from '../../../components/common/Loader';
import config from '../../../config';
import EnTagSelect from '../../../components/form/EnTagSelect';
import EnText from '../../../components/form/EnText';
import { set } from 'lodash';
import SimplePagination from '../../../components/common/SimplePagination';

export class StoreArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stores: [],
      channels: [],
      channel: '',
      store: '',
      loading: true,
      query: {
        channel: '',
        store: '',
        limit: 10,
        page: 1,
        totalPages: 1,
        totalItems: 1
      }
    };
  }

  componentWillMount() {
    const isSuperAdmin = this.props.auth.isSuperAdmin();
    if (!isSuperAdmin) {
      this.props.history.push(`${config.publicUrl}/`);
    }
    this.getStoreInfo(this.state.query);
    this.getChannels()
  }

  onEdit = (id) => {
    this.props.history.push(`${config.publicUrl}/superadmin/store/${id}/edit`);
  }

  async getChannels() {
    const channels = await this.props.channel.getAllChannel();
    this.setState({ channels })
  }

  getStoreInfo = async (query) => {
    this.setState({ loading: true })
    try {
      const { items, limit, page, totalPages, totalItems } = await this.props.admin.getStore(query);
      this.setState({
        stores: items,
        query: {
          ...this.state.query,
          limit,
          page,
          totalPages,
          totalItems
        }
      });
    } finally {
      this.setState({ loading: false })
    }
  }

  onAddNew = () => {
    this.props.history.push(`${config.publicUrl}/superadmin/store/add`);
  }

  genRow = () => {
    const storeName = this.state.stores;
    return storeName.map((i, index) => {
      return (
        <tr key={`name ${index}`}>
          <td>{((this.state.query.page - 1) * this.state.query.limit) + index + 1}</td>
          <td>{i.name}</td>
          <td className="text-center">
            <EnButton title="แก้ไข" className="btn btn-xs" onClick={(e) => { e.preventDefault(); this.onEdit(i._id); }}>
              <span className="fa fa-pencil" aria-hidden="true" />
            </EnButton>
          </td>
        </tr>
      );
    });
  }

  onUpdateChannel = (channel) => {
    this.setState({ channel })
  }

  onUpdateSearch = (search) => {
    this.setState({ store: search })
  }

  onChangeQuery = (key, value) => {
    const query = this.state.query
    set(query, key, value)
    this.setState({ query })
  }

  onSetPage = (page) => {
    this.onChangeQuery('page', page)
    this.getStoreInfo({
      ...this.state.query,
      page
    })
  }

  onClickSearch = () => {
    this.onChangeQuery('store', this.state.store)
    this.onChangeQuery('channel', this.state.channel)
    this.onChangeQuery('page', 1)
    this.getStoreInfo(this.state.query)
  }

  render() {
    const channels = this.state.channels.map((each, i) => ({
      label: each.channel_name,
      value: each.channel_name
    }))

    return (
      <Container isAdmin>
        <Loader show={this.state.loading} />
        <Notification ref={(ref) => { this.noti = ref; }} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายการร้านค้า</h4>
              </div>
              <div className="card-content">
                <form className="form-horizontal">
                  <div className='row'>
                    <div className="form-group col-md-6" style={{ display: 'flex', alignItems: 'center' }}>
                      <label className='control-label col-md-3'>ช่องทางการขาย</label>
                      <div className='col-md-9'>
                        <EnTagSelect placeholder="เลือกช่องทางการขาย" value={this.state.channel} multi={true} options={channels} onTagChange={(value) => this.onUpdateChannel(value)} />
                      </div>
                    </div>
                    <div className="form-group col-md-6" style={{ display: 'flex', alignItems: 'center' }}>
                      <label className='control-label col-md-3'>ค้นหาชื่อร้านค้า</label>
                      <div className='col-md-9'>
                        <EnText className="form-control" placeholder="ค้นหาด้วยชื่อร้านค้า" value={this.state.store} onChange={(e) => this.onUpdateSearch(e.target.value) } onKeyPress={(e) => { if (e.key === 'Enter') this.onClickSearch() }} />
                      </div>
                    </div>
                  </div>
                  <div className='row' style={{ marginBottom: '16px' }}>
                    <div className='col-md-12' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <EnButton className="btn-warning" onClick={() => this.onClickSearch()}>ค้นหา</EnButton>
                    </div>
                  </div>
                </form>
                <div className="table-responsive">
                  <table className="table table-bordered table-hover has-pagination">
                    <thead>
                      <tr>
                        <th width="5%" className="text-center">ลำดับที่</th>
                        <th className="text-center">ชื่อร้านค้า</th>
                        <th width="10%" className="text-center">
                          <button className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={this.onAddNew.bind(this)}>
                            <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.genRow()}
                    </tbody>
                  </table>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <SimplePagination pagination={this.state.query} onSetPage={this.onSetPage}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('admin', 'auth', 'channel')(observer(StoreArea));
