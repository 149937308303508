import React from 'react';
import history from '../../utils/history';
import config from '../../config';
import '../../assets/css/landing-page.css';

export class Landing extends React.Component {
  goToNewRegister() {
    history.push(`${config.publicUrl}/contractors/register`);
  }

  goToLogin() {
    history.push(`${config.publicUrl}/contractors/signin`);
  }

  getButton() {
    return (
      <div className="home">
        <div className="color-overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <h1>ยินดีต้อนรับ</h1>
                <div className="row">
                  <div className="col-md-6" style={{float: 'none', margin:'auto'}}>
                    <div onClick={this.goToLogin} className="btn btn-warning btn-block flatbtn action-btn" id="login">
                      <i className="fa fa-lock btn-icon" aria-hidden="true" />เข้าสู่ระบบ
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.getButton()}
      </div>
    );
  }
}

export default Landing;
