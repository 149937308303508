import React from 'react';
// import * as _ from 'lodash';
import {observer, inject} from 'mobx-react';
import EnButton from '../../../../components/form/EnButton';

export class SearchForm extends React.Component {
  componentDidMount() {}

  handleQueryChange(key, value) {
    if (this.props.onQueryChange) {
      this.props.onQueryChange(key, value);
    } else {
      this.props.trainingcourse.setQueryValue(key, value);
    }
  }

  onClearSearchClick() {
    this.props.onClear();
  }

  onSearchClick() {
    this.props.onSearch();
  }

  render() {
    const {query} = this.props;

    return (
      <div className="row">
        <div className="col-md-12" style={{marginTop: '15px'}}>
          <div className="form-horizontal">
            <div className="form-group col-md-6">
              <label className="col-md-3 control-label" htmlFor="name">
                ค้นหาชื่อคอร์ส
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder=""
                  value={query.course_name || ''}
                  onChange={(e) => {
                    this.handleQueryChange('course_name', e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="col-md-offset-2 col-md-10">
                  <EnButton className="btn-warning pull-right" onClick={this.onClearSearchClick.bind(this)}>
                    <i className="fa fa-refresh btn-icon" aria-hidden="true" />
                    เคลียร์
                  </EnButton>
                  <EnButton className="btn-info pull-right" onClick={this.onSearchClick.bind(this)}>
                    <i className="fa fa-search btn-icon" aria-hidden="true" />
                    ค้นหา
                  </EnButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('trainingcourse')(observer(SearchForm));
