import BaseStore from './BaseStore';
import _ from 'lodash'
import config from '../config';
import datetime from '../utils/datetime';
import { http } from '../utils/http';

export class NpsStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      jobInfo: this.initJobInfo(),
      nps: this.initNps(),
      // showBtnSubmit: true,
    });
  }

  initNps() {
    return {
      score : '',
      comment : '',
      channel : 'web',
      send_date : undefined,
      submited_date : undefined,
    };
  }

  initJobInfo() {
    return {
      jobId: '',
      orderId: '',
      status: '',
      guaranteeFile: '',
      channel: '',
    };
  }

  setNpsInfo(key, value) {
    this.nps[key] = value;
  }

  setNpsAllInfo(data) {
    this.nps = data;
  }

  getLatestGuaranteeFile(files) {
    if (!Array.isArray(files) || !files.length) {
      return undefined;
    }
    if (files.length > 1) {
      files.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }
    const { url } = files[0];
    return url;
  }

  async getJob(jobId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.get(`${config.api.sims}/v1/homeowners/job/${jobId}/acceptance-result`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      const { data } = response.data;
      this.jobInfo = {
        jobId: jobId,
        orderId: data.order_id,
        status: data.status,
        guaranteeFile: this.getLatestGuaranteeFile(data.guarantee),
        channel: data.channel,
        customerFirstname: data.customer_firstname,
        customerLastname: data.customer_lastname,
      };
      this.nps = {
        score : data.nps.score,
        comment : data.nps.comment,
        channel : data.nps.channel,
        send_date : data.nps.send_date || new Date(),
        submited_date : data.nps.submited_date,
      };
      // if (!_.isEmpty(data.nps.score)) { this.showBtnSubmit = false }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateNps(jobId) {
    try {
      if (this.isLoading) return;
      this.isLoading = true;
      const body = {
        score : this.nps.score,
        comment : this.nps.comment,
        channel : this.nps.channel,
        sendDate: datetime.SendToApiWithTime(this.nps.send_date),
      };
      let response = await http.put(`${config.api.sims}/v1/nps/${jobId}`, body);
      console.log('response -->', response);
      if (response.status !== 200) {
        throw new Error('พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateScore(jobId, score) {
    try {
      if (this.isLoading) return;
      this.isLoading = true;
      const body = {
        score : score,
      };
      let response = await http.put(`${config.api.sims}/v1/nps/${jobId}/score`, body);
      console.log('response -->', response);
      if (response.status !== 200) {
        throw new Error('พบข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new NpsStore();
