import React, { Component } from 'react';
import * as _ from 'lodash';
import { control } from 'react-validation';
// import TextArea from 'react-validation/build/textarea';

const SimsTextArea = control(({ error, isChanged, isUsed, ...props }) => {
  const textAreaProps = _.cloneDeep(props);
  let className = textAreaProps.className || '';
  delete textAreaProps.className;

  if (error) {
    // className += ' is-invalid-input';
  }

  return (
    <div>
      <textarea {...props} className={className} />
      {isChanged && isUsed && error}
    </div>
  );
});

export class EnTextArea extends Component {
  value() {
    return this.input.value;
  }

  clear() {
    this.input.value = '';
  }

  render() {
    let propInput = Object.assign({}, this.props);
    propInput.className = `${propInput.className ? propInput.className : ''} form-control`;

    if (propInput.validations && propInput.validations.length) {
      return <SimsTextArea style={propInput.style} ref={(e) => { this.input = e; }} {...propInput} />;
    } else {
      return <textarea style={propInput.style} ref={(e) => { this.input = e; }} {...propInput} />;
    }
  }
}

export default EnTextArea;
