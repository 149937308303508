import React, { Component } from 'react';
import renderHTML from 'react-render-html';

export class FormGroup extends Component {
  render() {
    let { label, labelHtml, validations, children, containerClassName, labelClassName, notRequired, id, containerStyle } = this.props;
    labelClassName = labelClassName || '';
    return (
      <div id={id} className={`form-group ${containerClassName || ''}`} style={containerStyle}>
        {
          label &&
          <label className={`${labelClassName}`}>{label} {validations && !notRequired ? '*' : ''}</label>
        }
        {
          labelHtml &&
          <label className={`${labelClassName}`}>{renderHTML(labelHtml)} {validations && !notRequired ? '*' : ''}</label>
        }
        {children}
      </div>);
  }
}

export default FormGroup;
