import React from 'react';
import { observer, inject } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import SideMenu from './SideMenu';
import SideMenuContractor from './SideMenuContractor';
import config from '../config';

export class SideBar extends React.Component {
  render() {
    const { isAdmin } = this.props;

    let menu = null;
    let logoUrl = `${config.publicUrl}/`;
    if (isAdmin) {
      menu = <SideMenu />;
    } else {
      const contractor = this.props.auth.getContractor();
      menu = <SideMenuContractor />;
      logoUrl = `${config.publicUrl}/contractors/${contractor._id}/calendar`;
    }

    return (
      <div className="sidebar" data-color="red">
        <div className="logo">
          <Link to={logoUrl}>
            {
              // <img src="https://storage.googleapis.com/prod-qchang-v1/static/images/logo.png" />
            }
            <img src="https://storage.googleapis.com/prod-qchang-v1/static/images/logo-new-full.png" alt="คิวช่าง" />
          </Link>
        </div>
        {menu}
        {/* <div className="sidebar-background" /> */}
      </div>
    );
  }
}

export default withRouter(inject('auth')(observer(SideBar)));
