import React from 'react';
import { observer, inject } from 'mobx-react';
import queryString from 'query-string';
import Container from '../../layouts/Container';
import {
  FormValidation,
  required,
  password,
} from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import EnButton from '../../components/form/EnButton';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import history from '../../utils/history';
import config from '../../config';
import GoogleLogin from '../../components/googleLogin/Login'

export class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  getRedirectUrl() {

    let redirectUrl = `${config.publicUrl}/admin`;
    let parsed = queryString.parse(this.props.location.search);
    if (parsed && parsed.url) {
      redirectUrl = decodeURIComponent(parsed.url);
    }

    return redirectUrl;
  }

  // getRedirectUrlAsa() {
  //   let redirectUrl = `${config.publicUrl}/admin/calendarasa`;
  //   let parsed = queryString.parse(this.props.location.search);
  //   if (parsed && parsed.url) {
  //     redirectUrl = decodeURIComponent(parsed.url);
  //   }

  //   return redirectUrl;
  // }

  async onClickSignin() {
    try {
      if (this.frmSignin && this.frmSignin.isValid()) {
        let url = await this.props.auth.authenticate({
          username: this.state.username,
          password: this.state.password,
          redirectUrl: this.getRedirectUrl(),
        });

        if (url) {
          // const storeId = this.props.auth.getStoreId();
          // const isAdminAsa = storeId === config.asa.storeId;
          // if (isAdminAsa) {
          //   url = this.getRedirectUrlAsa();
          // }
          history.replace(url);
        }
      }
    } catch (err) {
      this.noti.error(err.message);
    }
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onClickSignin();
    }
  }

  render() {
    let auth = this.props.auth.toJS();
    // console.log('auth >>>>>>>>', auth);
    return (
      <Container hideSidebar>
        <Loader show={auth.isLoading} />
        <div className="row" style={{ marginTop: 50 }}>
          <Notification ref={e => { this.noti = e; }} />
          <div className="col-md-offset-3 col-md-6 col-lg-offset-4 col-lg-4">
            <div className="card">
              {
                // <div className="card-header" data-background-color="orange">
                //   <h4 className="title">เข้าสู่ระบบ</h4>
                // </div>
              }
              <div className="row">
                <div className="col-lg-12 text-center admin-signin-logo">
                  <img src="https://storage.googleapis.com/prod-qchang-v1/static/images/logo-new-full.png" alt="คิวช่าง" />
                </div>
              </div>
              <div className="card-content">
                <FormValidation ref={(e) => { this.frmSignin = e; }}>
                  <EnTextFormGroup
                    id="username"
                    label="ชื่อผู้ใช้งาน"
                    value={this.state.username}
                    onChange={(e) => { this.setState({ username: e.target.value }); }}
                    onKeyPress={this.handleKeyPress}
                    validations={[required]}
                  />

                  <EnTextFormGroup
                    id="password"
                    label="รหัสผ่าน"
                    type="password"
                    value={this.state.password}
                    onChange={(e) => { this.setState({ password: e.target.value }); }}
                    onKeyPress={this.handleKeyPress}
                    validations={[required, password]}
                  />

                  <div className="col-md-12 p-0">
                    <EnButton
                      id="login-button"
                      className="btn-warning btn-block btn-space"
                      validations
                      onClick={this.onClickSignin.bind(this)}
                    >
                      <i className="fa fa-sign-in btn-icon" aria-hidden="true" />เข้าสู่ระบบ
                    </EnButton>
                  </div>

                  <div className="col-md-12 p-0">
                    <hr/>
                    <GoogleLogin />
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth')(observer(Signin));
