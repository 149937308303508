import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import querystring from 'query-string';
import moment from 'moment';
import swal from 'sweetalert2';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import Container from '../../layouts/ContainerContractor';
import common from '../../utils/common';
import datetime from '../../utils/datetime';
import JobWizard from './JobWizard';
import config from '../../config';

export class ContractorJob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      jobId: this.props.match.params.jobId,
      contractorId: this.props.match.params.id,
      contractorInfo: undefined,
      instContractors: undefined,
      unavailableInstDate: undefined,
    };
  }

  async initData() {
    try {
      // await this.props.job.getJobsById(this.state.jobId, {
      //   isContractor: true,
      //   contractorId: this.state.contractorId,
      // });
      const result = await this.props.job.getJobsByIdAndCurrentActive(this.state.jobId, {
        isContractor: true,
        contractorId: this.state.contractorId,
      });
      const { data, current_active_data: currentActiveData } = result;
      if (!data && !currentActiveData) {
        swal.fire({
          icon: 'error',
          title: 'ไม่พบข้อมูลรายการนี้',
          confirmButtonText: 'ตกลง',
        });
        return;
      }

      if (!data && currentActiveData) {
        this.props.history.replace(`${config.publicUrl}/contractors/${this.state.contractorId}/calendar/jobs/${currentActiveData._id}`);
        window.location.reload();
      }

      await this.props.typeofwork.getTypeOfWorks();
      const jobInfo = this.props.job.toJS().info;
      await this.checkAndBlockInstallationDate(jobInfo);
    } catch (err) {
      // this.noti.error(err.message, 'พบข้อผิดพลาด');
      swal.fire({
        icon: 'error',
        title: err.message,
        confirmButtonText: 'ตกลง',
      });
    }
  }

  async checkAndBlockInstallationDate(jobInfo) {
    if (jobInfo) {
      let instContractors;
      let unavailableInstDate;
      if (jobInfo.jobType === 'S' && jobInfo.status === 'survey_finished') {
        const instDate = moment(jobInfo.endDate).add(1, 'days').toDate();
        let instCriteria = {
          contractorId: this.state.contractorId,
          workTypeId: jobInfo.typeOfWork[0],
          startInstDate: instDate,
          endInstDate: instDate,
          jobType: 'I',
          available: 0,
        };

        const instResult = await this.props.job.getUnavailableDateForInstallation(instCriteria);
        if (instResult) {
          instContractors = instResult.contractors;
          unavailableInstDate = instResult.unavailableDate;
        }
      }
      // // update job read when contractor open page
      if (jobInfo.status === 'assigned') {
        const parsed = querystring.parse(this.props.location.search);
        await this.props.job.updateJobRead(jobInfo, parsed);
      }

      this.setState({
        contractorInfo: this.props.auth.getContractor(),
        instContractors: instContractors || [],
        unavailableInstDate: unavailableInstDate || [],
      });
    }
  }

  validateJobInfo() {
    return true;
  }

  checkInstDayBlock(date) {
    let { unavailableInstDate, instContractors, contractorId } = this.state;
    if (contractorId) {
      const contractor = instContractors.find(c => c._id === contractorId);
      if (contractor) {
        unavailableInstDate = common.unavailableDate([contractor], contractor.unavailable_date);
      }
    }

    if (Array.isArray(unavailableInstDate) && unavailableInstDate.length) {
      const dateBlock = unavailableInstDate.find(u => datetime.isEqual(date, u));
      return !!dateBlock;
    } else {
      return false;
    }
  }

  onCancel() {
    const jobInfo = this.props.job.toJS().info;
    const calendarFilter = this.props.calendar.toJS().calendarFilter;
    let qs;
    if (calendarFilter) {
      qs = calendarFilter;
    } else {
      qs = {
        jobType: jobInfo.jobType,
        date: moment(jobInfo.startDate || jobInfo.endDate).format('YYYY-MM'),
      };
    }
    this.props.history.push(`${config.publicUrl}/contractors/${jobInfo.contractorId}/calendar?${common.urlBuilder(qs)}`);
  }

  async onSubmit(notiAdditionalCharge = false) {
    try {
      // const jobInfo = this.props.job.toJS().info;
      // if (jobInfo &&
      //   Array.isArray(jobInfo.original.files) &&
      //   Array.isArray(jobInfo.files) &&
      //   jobInfo.original.files.length !== jobInfo.files.length) {
      //   await this.props.job.updateJob(this.state.jobId, {
      //     isContractor: true,
      //     contractorId: this.state.contractorInfo._id,
      //   });
      //   this.noti.success('อัพโหลดไฟล์เรียบร้อย');
      // }
      await this.props.job.updateJob(this.state.jobId, {
        isContractor: true,
        contractorId: this.state.contractorInfo._id,
        notiAdditionalCharge,
      });
      this.noti.success('บันทึกข้อมูลคิวช่างเรียบร้อย');
      setTimeout(() => {
        // this.onCancel();
        window.location.reload();
      }, 1000);
    } catch (error) {
      this.noti.error(error.message, 'พบข้อผิดพลาด');
    }
  }

  render() {
    const jobStore = this.props.job.toJS();
    let contractors = [];
    if (this.state.contractorInfo) {
      contractors.push(this.state.contractorInfo);
    }
    let showJobStep = true;

    return (
      <Container isAdmin>
        <Notification ref={(ref) => { this.noti = ref; }} />
        <Loader show={jobStore.isSubmitting || jobStore.isLoading} />
        <div className="row">
          <div className="card">
            <div className="card-header" data-background-color="orange">
              <h4 className="title">ปฏิทิน</h4>
              <p className="category">รายละเอียดการใช้บริการ</p>
            </div>
            <div className="card-content">
              <div className="card-content">
                <JobWizard
                  showJobStep={showJobStep}
                  jobId={this.state.jobId}
                  contractorMode
                  contractors={contractors}
                  instContractors={this.state.instContractors}
                  unavailableInstDate={this.state.unavailableInstDate}
                  validation={this.validateJobInfo()}
                  checkInstDayBlock={this.checkInstDayBlock.bind(this)}
                  initial={this.initData.bind(this)}
                  onSubmit={this.onSubmit.bind(this)}
                  onCancel={this.onCancel.bind(this)}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'job', 'contractor', 'calendar', 'typeofwork')(observer(ContractorJob));
