import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../layouts/Container';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import Pagination from '../../components/common/Pagination';
import EnButton from '../../components/form/EnButton';
import { isAllow } from '../../utils/permission';

export class ContractorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store_id: undefined,
      stores: [],
    };
  }

  async componentWillMount() {
    try {
      if (!isAllow('SEARCH_AND_VIEW_A_LIST_OF_CONTACTORS')) {
        return;
      }
      // await this.props.contractor.getAll({ filterByArea: false });
      await this.getStoreInfo();
      if (this.state.store_id) {
        this.props.contractorlist.setQueryValue('store_id', this.state.store_id);
      }
      await this.props.contractorlist.searchContractors();
    } catch (err) {
      this.noti.error(err.message);
    }
  }

  getStoreInfo = async () => {
    await this.props.admin.getStore();
    let admin = this.props.admin.toJS();
    this.setState({
      stores: admin.store,
    });
  }

  async onPageClick(pageNum) {
    this.props.contractorlist.setQueryValue('page', pageNum);
    await this.props.contractorlist.searchContractors();
  }

  async onSearchClick(e) {
    this.props.contractorlist.setQueryValue('page', 1);
    await this.props.contractorlist.searchContractors();
  }

  async onClearSearchClick(e) {
    this.props.contractorlist.setDefaultQuery();
    if (this.state.store_id) {
      this.props.contractorlist.setQueryValue('store_id', this.state.store_id);
    }
    await this.props.contractorlist.searchContractors();
  }

  handleQueryChange(key, event) {
    this.props.contractorlist.setQueryValue(key, event.target.value);
    this.onSearchClick();
  }

  displaySearchForm(query, stores = [], contractors = []) {
    return (
      <form className="form-horizontal">
        <div className="col-md-8">
          <div className="form-group">
            <label className="col-md-2 control-label">ร้าน</label>
            <div className="col-md-4">
              <select className="form-control" id="store" value={query.store_id} onChange={this.handleQueryChange.bind(this, 'store_id')}>
                <option value={undefined}>--- เลือก ---</option>
                {stores.map((sto, index) => <option key={`sto-${index}`} value={sto._id}>{sto.name}</option>)}
              </select>
            </div>
          </div>
          {/* <div className="row">
            <label className="col-md-2 control-label">ทีมช่าง</label>
            <div className="col-md-4">
              <select className="form-control" id="contractor" value={query.contractor_id} onChange={this.handleQueryChange.bind(this, 'contractor_id')}>
                <option value={undefined}>--- เลือก ---</option>
                {contractors.map((con, index) => <option key={`con-${index}`} value={con._id}>{con.team_name}</option>)}
              </select>
            </div>
          </div> */}
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <div className="col-md-offset-2 col-md-10">
              <EnButton
                className="btn-warning pull-right"
                onClick={this.onClearSearchClick.bind(this)}
                disabled={!isAllow('SEARCH_AND_VIEW_A_LIST_OF_CONTACTORS')}
                >
                <i className="fa fa-refresh btn-icon" aria-hidden="true" />เคลียร์
              </EnButton>
              <EnButton
                className="btn-info pull-right"
                onClick={this.onSearchClick.bind(this)}
                disabled={!isAllow('SEARCH_AND_VIEW_A_LIST_OF_CONTACTORS')}
                >
                <i className="fa fa-search btn-icon" aria-hidden="true" />ค้นหา
              </EnButton>
            </div>
          </div>
        </div>
      </form>
    );
  }

  findJobCount(contractor, typeOfWork) {
    const jobStat = (contractor.job_statistic || []).find(j => j.type_of_work === typeOfWork);
    if (jobStat)
      return { jobCount: jobStat.job_point, jobPoint: jobStat.job_point };
    else
      return { jobCount: 0, jobPoint: 0 };
  }

  displayTableRows(contractors, typeOfWorks) {

    return contractors.map((c, index) => {
      const jobStat = this.findJobCount(c, typeOfWorks);
      return (
        <tr key={index}>
          <td>{`${c.first_name} ${c.last_name}`}</td>
          <td className="text-center">{`${c.team_name}`}</td>
          <td className="text-center">{`${c.phone}`}</td>
          <td className="text-center">{`${c.email}`}</td>
          <td className="text-center">{`${jobStat.jobCount || 0}`}</td>
          <td className="text-center">{`${jobStat.jobPoint || 0}`}</td>
        </tr>
      )
    });
  }

  render() {
    const { data, query, pagination, isLoading } = this.props.contractorlist.toJS();
    // const { statuses } = this.state;
    const stores = this.state.stores;

    const contractors = this.props.contractor.toJS().list;
    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <Notification ref={(ref) => { this.noti = ref; }} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ค้นหาช่าง</h4>
              </div>
              <div className="card-content">
                {this.displaySearchForm(query, stores, contractors)}
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="10%" className="text-center">ชื่อ-นามสกุล</th>
                            <th width="10%" className="text-center">ชื่อทีมช่าง</th>
                            <th width="10%" className="text-center">โทรศัพท์</th>
                            <th width="10%" className="text-center">email</th>
                            <th width="10%" className="text-center">จำนวนงานที่ได้รับ</th>
                            <th width="10%" className="text-center">คะแนน</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.displayTableRows(data)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Pagination
                      pagination={pagination}
                      handlePageClick={this.onPageClick.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('contractorlist', 'admin', 'contractor')(observer(ContractorList));
