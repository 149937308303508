import React from 'react'
import { observer, inject } from 'mobx-react';

import Container from '../../../layouts/Container';
import SearchForm from './components/SearchForm';
import config from '../../../config';
import { Modal, Button } from 'react-bootstrap';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup'
import FormGroup from '../../../components/form/FormGroup'
import EnDropDown from '../../../components/form/EnDropDown';
import moment from 'moment'
import EnDatePicker from '../../../components/form/EnDatePicker'
import _ from 'lodash'
import { set, orderBy } from 'lodash';
import EnTagSelect from '../../../components/form/EnTagSelect'
import axios from 'axios'
import EnToggle from '../../../components/form/EnToggle';
import EnTextArea from '../../../components/form/EnTextArea';
import Loader from '../../../components/common/Loader'
import { isAllow } from '../../../utils/permission';





const urlWorkType = `${config.api.training}/work-type`
let arrTime = [
    {}
]
function genTimes() {
    arrTime = [];
    new Array(19).fill().forEach((acc, index) => {
        if (index > 7) {
            arrTime.push(
                {
                    label: moment({ hour: index }).format('HH:mm'),
                    value: moment({ hour: index }).format('HH:mm:ss')
                }
            );
        }
        // arrTime.push(moment({ hour: index, minute: 30 }).format('h:mm A'));
    })

}
genTimes()

export class ManageMainCourse extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            locationSelected: '',
            locationOptions: [
               
            ],
            typeOfWorkOptions: [],
            typeOfWorkSelected: [],
            editMode: false,
            mainCourseSelectd: '',
            isActive: false,
            time: {
                start: '08:00:00',
                end: '16:00:00'
            },
            date: new Date(),
            balance: 0,
            remark: ''


        };
    }
    mapDataState(data) {
        // console.log("data",data);
        this.setState({
            locationSelected: data.trainingLocation.locationId,
            typeOfWorkSelected: _.map(data.workSubType, type => {
                return type.workSubTypeId
            }),
            mainCourseSelectd: data.trainingUuid,
            isActive: data.isActive,
            time: {
                start: data.trainingStartTime,
                end: data.trainingEndTime
            },
            date: data.trainingDate || new Date(),
            balance: data.traingOpen || 0,
            remark: data.trainingRemark
        })

    }

    async componentWillMount() {
        
        await this.props.trainingcourse.getAllTrainingCourse();
        await this.props.trainingcourse.getlocations()
        const { locationOptions } = this.props.trainingcourse.toJS();
        const id = this.props.match.params.id || null;
        const mainCourseId = this.props.match.params.mainCourseId || null;
        const { course } = this.props.trainingcourse.toJS();
        
        this.setState({ mainCourseSelectd: course[0].trainingUuid })
        this.setState({ locationSelected: locationOptions && locationOptions[0] && locationOptions[0].locationId.toString() || '' })
        

        // const { course } = this.props.trainingcourse.toJS();
        if (mainCourseId) {
            this.setState({ mainCourseSelectd: mainCourseId })
        }

        if (id && isAllow('VIEW_A_SUB_COURSE_INFORMATION')) {
            let data = await this.props.trainingcourse.getSubCoursDetails(id);
            this.mapDataState(data)

            this.setState({ editMode: true })
        }
        // awalet res it this.props.trainingcourse.getTrainingRegister(id);
    }


    async getTypeOfWorkOptions() {
        
        try {

            let res = await axios.get(urlWorkType)
            
            
            let options = []
            res.data.map((item) => {
                item.sub_type.map((i=>{
                    options.push( {
                        label: i.work_sub_type_name,
                        value: i.work_sub_type_id,
                    })
                }))
            });
            
            // let typeOfWorkOptions = [{ label: 'ทั้งหมด', value: '' }].concat(options);
            let typeOfWorkOptions = options


            this.setState({ typeOfWorkOptions });
            
        } catch (error) {
            console.error('error getTypeOfWorkOptions');
            console.log(error);
            console.error('error getTypeOfWorkOptions');
            // throw error
        }
    }
    
    selectTypeOfWork(val) {
        const value = val.split(',');
        let newData = _.uniq(value).filter(item => item)

        this.setState({
            typeOfWorkSelected: newData
        })


    }

    onChangeTime(key, value) {
        let newTime = this.state.time
        newTime[key] = value
        this.setState({
            time: newTime
        })
    }

    onTypeOfWorkChange(selectedItem, e) {
        let typeOfWork = e.split(',');
        let items = _.cloneDeep(this.state.typeOfWorkSelected);
        let item = items.find(i => {
            return i.key === selectedItem.key;
        });
        if (!_.isNil(item) && !_.isEmpty(item)) {
            item['value'] = typeOfWork;
        }
        this.setState({
            typeOfWorkSelected: items,
        });
    }
    onChangeMainCourseDropDown(value) {
        this.setState({ mainCourseSelectd: value })


    }

    onChangeLocation(value) {
        this.setState({
            locationSelected: value
        })
    }

    onChangeStatus(isActive) {
        this.setState({
            isActive: !isActive
        })
    }

    handleDateChange(key, date) {
        this.setState({ [key]: date.toDate() })
    }

    mapSubTypeArr(typeOfWorkSelected = []) {
        return _.map(typeOfWorkSelected, typeCode => {
            return {
                work_sub_type_id: parseInt(typeCode)
            }
        })
    }

    async onCreateSubCourse() {
        let arr_train_wk_sb_type = this.mapSubTypeArr(this.state.typeOfWorkSelected)

        const data = {
            "location_id": parseInt(this.state.locationSelected),
            "training_date": moment(this.state.date).toISOString(),
            "training_start_time": this.state.time.start,
            "training_end_time": this.state.time.end,
            "traing_open": this.state.balance,
            "is_active": this.state.isActive,
            "training_uuid": this.state.mainCourseSelectd,
            "train_wk_sb_type": arr_train_wk_sb_type,
            "traing_remark":this.state.remark
        }

        await this.props.trainingcourse.createSubCourse(data)
        this.props.history.push(`${config.publicUrl}/superadmin/training`);
    }

    async componentDidMount() {
        try {
            // await this.props.typeofwork.getTypeOfWorks({ visible: true });
            this.getTypeOfWorkOptions();
        } catch (error) {
            // this.noti.error(error.message);
            console.log(error);
        }
    }

    checkValidState() {
        let valid = true
        const keyForCheckState = [
            'locationSelected',
            'typeOfWorkSelected',
            'mainCourseSelectd',
            ]
        _.forEach(keyForCheckState, key => {
            const valueState = this.state[key]
            
            
            if (_.isEmpty(valueState)) {
                valid = false
            }
        })

        return valid
    }

    render() {
        const viewMode = (this.props.match.params.id || null)
        const createMode = (this.props.match.params.mainCourseId || null);

        const { course, query, isLoading,locationOptions } = this.props.trainingcourse.toJS();
        const {
            balance,
            date,
            isActive,
            typeOfWorkOptions,
            typeOfWorkSelected,
            mainCourseSelectd,
            locationSelected,
            time,
            remark
        } = this.state
        const valid = this.checkValidState()


        return (
            <Container isAdmin>
                <Loader show={isLoading} />
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header" data-background-color="orange">
                                <h4 className="title">สร้างคอร์สย่อย</h4>
                            </div>

                            <div className="card-content">
                                <div className="row" >

                                    <FormGroup label="คอร์สหลัก" containerClassName="col-md-12">
                                        <EnDropDown value={mainCourseSelectd}
                                            disabled={viewMode}
                                            onChange={e => { this.onChangeMainCourseDropDown(e.target.value) }}
                                        >
                                            {
                                                _.map(course, c => {
                                                    return (
                                                        <option key={c.trainingName} value={c.trainingUuid}>{c.trainingName}</option>
                                                    )
                                                })
                                            }
                                        </EnDropDown>
                                    </FormGroup>


                                    <FormGroup label="ประเภทงาน *" containerClassName="col-md-12 approve-tow-form">
                                        <EnTagSelect
                                            disabled={viewMode}
                                            className="approve-tow-select"
                                            multi={true}
                                            value={typeOfWorkSelected}
                                            options={typeOfWorkOptions}
                                            onTagChange={(value) => this.selectTypeOfWork(value)}
                                            placeholder="เลือกประเภทงาน..."
                                        />
                                    </FormGroup>
                                    <FormGroup label="วันที่" containerClassName="col-md-12">
                                        <EnDatePicker id="date"
                                            disabled={viewMode}
                                            initialDate={date ? moment(date) : undefined}
                                            onDateChange={this.handleDateChange.bind(this, 'date')}
                                            isOutsideRange={() => false}
                                        // initialDate={date}
                                        // // initialDate={query.start_date ? moment(query.start_date) : undefined}
                                        // onDateChange={e => { this.handleDateChange(e.target.value) }}
                                        // isOutsideRange={() => false}
                                        />
                                    </FormGroup>


                                    <FormGroup label="สถานที่" containerClassName="col-md-12">

                                        <EnDropDown value={locationSelected}
                                            disabled={viewMode}
                                            onChange={e => this.onChangeLocation(e.target.value)}
                                        >

                                            {
                                                _.map(locationOptions, locate => {
                                                    return (
                                                        <option key={locate.locationName} value={locate.locationId}>{locate.locationName}</option>
                                                    )
                                                })
                                            }
                                        </EnDropDown>
                                    </FormGroup>
                                    <FormGroup label="เวลา" containerClassName="col-sm-6 col-md-6">
                                        <EnDropDown value={time.start}
                                            disabled={viewMode}
                                            onChange={e => { this.onChangeTime('start', e.target.value) }}
                                        >
                                            {
                                                _.map(arrTime, time => {
                                                    return (
                                                        <option key={time.label} value={time.value}>{time.label}</option>
                                                    )
                                                })
                                            }
                                        </EnDropDown>
                                    </FormGroup>
                                    <FormGroup label="ถึง" containerClassName="col-sm-6 col-md-6">
                                        <EnDropDown value={time.end}
                                            disabled={viewMode}
                                            onChange={e => { this.onChangeTime('end', e.target.value) }}
                                        >
                                            {
                                                _.map(arrTime, time => {
                                                    return (
                                                        <option key={time.label} value={time.value}>{time.label}</option>
                                                    )
                                                })
                                            }
                                        </EnDropDown>
                                    </FormGroup>
                                    <EnTextFormGroup
                                        disabled={viewMode}
                                        id="count"
                                        containerClassName="col-sm-6 col-md-6"
                                        type="number"
                                        label={'จำนวนคน'}
                                        value={balance}
                                        onChange={e => {
                                            this.setState({
                                                balance: parseInt(e.target.value)
                                            })
                                        }}

                                    />


                                    <FormGroup label="เปิด/ปิด คอร์ส" containerClassName="col-sm-6 col-md-6">
                                        <EnToggle
                                            disabled={viewMode}
                                            checked={isActive}
                                            // checked={item.isActive === true}
                                            onChange={e => { this.onChangeStatus(isActive) }}
                                        // checked={item.contractor_visible === true}
                                        // onChange={this.onContractorVisibleToggle.bind(this, index, item)}


                                        />
                                    </FormGroup>
                                    <FormGroup label="หมายเหตุ" containerClassName="col-sm-6 col-md-6">
                                        <EnTextArea
                                            disabled={viewMode}
                                            rows={3}
                                            // cols={cols} 
                                            // disabled={this.props.disabled} 
                                            // ref={(e) => { this.input = e; }} 
                                            value={remark || ''}
                                            onChange={e => {
                                                if (e.target.value.length <= 100) {
                                                    this.setState({ remark: e.target.value })
                                                }
                                            }}
                                        // validations={validations}
                                        />
                                    </FormGroup>



                                </div>

                            </div>
                            {
                                (createMode || (!viewMode)) && <div className="card-footer text-right">
                                <button className="btn btn-danger" title="ยกเลิก" data-tip="ยกเลิก"  onClick={e => { this.props.history.goBack() }}>
                                        <span className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                                 </button>
                                    <button 
                                      className="btn btn-info"
                                      title="สร้างใหม่"
                                      data-tip="สร้างใหม่" 
                                      onClick={e => { this.onCreateSubCourse() }}
                                      disabled={!valid || !isAllow('CREATE_A_SUB_COURSE')}
                                    >
                                            <span className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                                    </button>
                                    
                                    
                                </div>
                            }
                            {
                                (viewMode) && <div className="card-footer text-right">
                                    <button className="btn btn-danger" title="ยกเลิก" data-tip="ยกเลิก" style={{ marginRight: 15 }} onClick={e => { this.props.history.goBack() }}>
                                        <span className="fa fa-arrow-left btn-icon" aria-hidden="true" />กลับ
                                 </button>
                                    {/* <button className="btn btn-danger" title="ยกเลิก" data-tip="ยกเลิก" style={{ marginRight: 15 }}>
                                        <span className="fa fa-trash btn-icon" aria-hidden="true" />ลบ
                                 </button> */}

                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}


export default inject('trainingcourse')(observer(ManageMainCourse));
