import React, { Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import FormGroup from '../../../../components/form/FormGroup';
import ImagePicker from '../../../../components/form/ImagePicker';
import EnDropZone from '../../../../components/form/EnDropZone';

import { config } from '../../../../config';
import AuthStore from '../../../../stores/AuthStore';

export class ImageSection extends React.Component {
  onAddedFile() {
    if (this.dropzone) {
      this.setState({
        files: this.dropzone.getFiles(),
      });
    }
  }

  deleteImage(imageIndex) {
    const product = this.props.product.toJS();
    const images = product.info.images;
    images.splice(imageIndex, 1);
    this.props.product.saveInfo('images', images);
  }

  displayImages(images) {
    if (Array.isArray(images) && !images.length) {
      return (
        <div className="col-md-3" key={`image-no`}>
          ยังไม่มีรูปสินค้า
        </div>
      );
    }

    return images.map((image, index) => {
      return (
        <div className="col-md-3" key={`image-${index + 1}`}>
          <ImagePicker src={image.thumbnail} id={`image-${index + 1}`} onDelete={this.deleteImage.bind(this, index)} />
        </div>
      );
    });
  }

  getDropZone(ref, callback) {
    const url = `${config.api.qchang}/v1/products/upload`;
    const token = AuthStore.getToken();
    return <EnDropZone ref={ref} url={url} token={token} acceptedFiles={'image/*'} onAllComplete={callback} maxFileSize={10} onAddedFile={this.onAddedFile.bind(this)} />;
  }

  async onDropZoneComplete(files) {
    const product = this.props.product.toJS();
    const images = product.info.images;
    files.forEach((upload) => {
      const uploadResult = upload.res ? upload.res.data : null;
      if (uploadResult) {
        images.push({
          url: uploadResult.path,
          thumbnail: uploadResult.thumbnail,
          alt: uploadResult.name,
        });
      }
    });
    this.props.product.saveInfo('images', images);
  }

  render() {
    const { images } = this.props;
    let dropzone = this.getDropZone(
      (e) => {
        this.dropzone = e;
      },
      (e) => {
        this.onDropZoneComplete(e);
      }
    );
    return (
      <Fragment>
        <div className="row">
          <FormGroup containerClassName="col-md-12" label="รูปภาพสินค้า">
            <div className="col-md-12">{this.displayImages(images)}</div>
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup containerClassName="col-md-12" label="อัพโหลดรูปภาพ">
            <span style={{ color: 'red' }}> ( อัพโหลดได้สูงสุด 20 ไฟล์ต่อครั้ง ขนาด 650 pixel ขึ้นไป ) </span>
            {dropzone}
          </FormGroup>
        </div>
      </Fragment>
    );
  }
}

export default inject('product')(observer(ImageSection));
