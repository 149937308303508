import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';

export class SetupCalendarStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      info: [],
    });
  }

  initInfo() {
    return {
      dayOff: '',
      dayName: '',
    };
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  async saveCalendarDayOff(info) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      await http.post(`${config.api.sims}/v1/calendar/calendarDayOff`, info);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteCalendarDayOff(info) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      await http.post(`${config.api.sims}/v1/calendar/deleteCalendarDayOff`, info);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getCalendarDayOff() {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let res = await http.get(`${config.api.sims}/v1/calendar/getCalendarDayOff`);
      if (res && res.status === 200 && res.data) {
        this.info = res.data
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new SetupCalendarStore();
