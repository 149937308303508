import React, { Component, Fragment } from 'react';
import swal from 'sweetalert2';
import _, { get, intersection } from 'lodash';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import XLSX from 'xlsx-js-style';

import Container from '../../../layouts/Container';
import Notification from '../../../components/common/Notification';
import Loader from '../../../components/common/Loader';
import { FormValidation, required } from '../../../components/form/FormValidation';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnToggle from '../../../components/form/EnToggle';
import EnDateRangePicker from '../../../components/form/EnDateRangePicker';
import EnButton from '../../../components/form/EnButton';
import EnTagSelect from '../../../components/form/EnTagSelect';
import EnCKEditor from '../../../components/form/EnCKEditor';
import EnDropzoneDialog from '../../../components/form/EnDropzoneDialog';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import { EnTab } from '../../../components/form/EnTab';
import { Tab } from 'react-bootstrap';
import { EnDropDown } from '../../../components/form/EnDropDown';
import { isAllow, isCampaignManager } from '../../../utils/permission';
import { config } from '../../../config';
import { DateCheckBox } from './components/DateCheckBox';
import { forEach } from 'lodash'
import { getBackboneSkuFromCategory } from '../../../utils/category';
import { CHANNEL_GROUP, channelGroupOptions } from '../../../utils/data';
import common from '../../../utils/common';
import EnCheckBox from '../../../components/form/EnCheckBox';

export class CouponGroupPage extends Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id;
    this.inputRef = React.createRef();
    this.state = {
      activeIndex: 0,
      id: id,
      isEdit: id && id.length,
      products: [],
      newProducts: [],
      items: [],
      selectedProduct: [],
      allProvince: [],
      allBranch: [],
      provinceItems: [],
      branchItems: [],
      isSelectBranch: false,
      coupons: {
        items: [],
        selectedChannel: [],
      },
      newCode: false,
      //QBC-297
      mainCategoryOptions: {}, // { 'key': [] }
      subCategoryOptions: {}, // { 'key': [] }
      selectedMainCategory: {}, // { 'key': [] }
      selectedSubCategory: {}, // { 'key': [] }
      selectedSkuCode: {}, // { 'key': [] }
      // END QBC-297
      initLoading: true,
      displayChannelGroupErrorMessage: false,
      originalChannelGroup: undefined,
      fileUpload: null,
      dropzone: {
        adBanner: false,
        heroBanner: false,
        heroBannerSquare: false,
        promotionBottomBanner: false,
        promotionThumbnail: false,
      },
      limitedUsePerMemberError: false,
      isCampaignManager: isCampaignManager(),
    };
  }

  async componentDidMount() {
    try {
      await this.props.product.getProductList(undefined, undefined, true, 'coupon');
      await this.props.model.getAllModel();
      await this.props.misc.getAllProvince();
      await this.props.channel.getAllChannel();
      await this.props.coupongroup.clearUpdateFields();

      if (this.state.isEdit) {
        await this.props.coupongroup.getCouponGroupById(this.state.id);
        this.props.coupongroup.initNewCouponInfoStores();

        // QBC-297
        await this.getCouponItems();

        const { newCouponInfo, couponInfo } = this.props.coupongroup.toJS().info
        // END QBC-297
        this.setState({
          originalChannelGroup: newCouponInfo.productChannel,
        });
      } else {
        this.props.coupongroup.clearInfo();
      }

      // QBC-297
      await this.handleStateChange();

      // END QBC-297
    } catch (error) {
      this.noti.error(error.message, 'โหลดข้อมูลล้มเหลว');
    } finally {
      this.setState({ initLoading: false });
    }
  }

  // QBC-297
  async handleStateChange() {
    const couponGroup = this.props.coupongroup.toJS().info;
    const { couponInfo: info } = couponGroup;

    // get Stores
    let branch = [];
    if (couponGroup.newCouponInfo.channel.length) {
      branch = await this.props.coupon.getStoreByID({ channel: couponGroup.newCouponInfo.channel, field: 'coupon' })
      branch = branch && branch.length ? branch : [];
    }

    this.props.misc.saveId('miscData.branch', branch);

    const productItems = this.props.product.toJS().items;
    let items = [];
    if (info && Array.isArray(info.items) && info.items.length) {
      info.items.map((i, index) => {
        let range = [];
        const selectedItems = i.products.map(p => {
          const product = productItems.find(x => x.id === p.id);
          const price = _.get(product, 'calculator.unitPrice', 0);
          return {
            label: p.label,
            value: p.id,
            price: price,
          };
        });
        if (info && Array.isArray(i.range) && i.range.length) {
          range = i.range.map((r, index) => {
            const contractor_discount = r.contractorDiscount || 0
            const bu_discount = r.buDiscount || 0
            const partner_discount = r.partnerDiscount || 0
            let platform_discount = r.platformDiscount || 0
            const sumDiscount = contractor_discount + bu_discount + partner_discount + platform_discount
            if ((r.value !== sumDiscount) && (platform_discount == 0)) {
              platform_discount = r.value - contractor_discount
            }
            return {
              key: r.key,
              min: r.min,
              max: r.max,
              value: r.value,
              contractor_discount,
              bu_discount,
              partner_discount,
              platform_discount,
              date: r.date || [],
            };

          });
        };
        let promotionRange = false;
        if (i.promotionRange) {
          promotionRange = i.promotionRange;
        } else {
          promotionRange = range.length > 0 ? true : false;
        }
        items.push({
          key: index,
          selectedId: selectedItems,
          product: productItems,
          value: i.value,
          range: range,
          daily_promotion: i.dailyPromotion || false,
          date: i.date || [],
          promotion_range: promotionRange,
        });
      });
    } else {
      items.push({
        key: (new Date()).getTime(),
        selectedId: [],
        product: productItems,
        range: [],
        value: null,
        daily_promotion: false,
        date: [],
        promotion_range: false,
      });
    }

    let productId = [];
    _.forEach(items, (item, index) => {
      if (item.selectedId && item.selectedId.length) {
        const id = item.selectedId.map(i => {
          if (typeof i === 'object') {
            return i.value;
          }
          return i;
        });
        productId.push(...id);
      }
    });

    const misc = this.props.misc.toJS().miscData;
    if (info && info.provinceId && info.provinceId.length) {
      misc.province['selected_id'] = info.provinceId;
      misc.branch['selected_id'] = couponGroup.newCouponInfo.stores;
    } else {
      misc.province['selected_id'] = [];
      misc.branch['selected_id'] = [];
    }

    const { mainCategoryOptions: m, subCategoryOptions: s } = this.getCategoryOptions([])
    let mainCategoryOptions = {}, subCategoryOptions = {};
    for (let i = 0; i < items.length; i++) {
      mainCategoryOptions = { ...mainCategoryOptions, [items[i].key]: m }
      subCategoryOptions = { ...subCategoryOptions, [items[i].key]: s }
    }

    this.setState({
      products: [productItems],
      items,
      selectedProduct: productId,
      provinceItems: [misc.province],
      allProvince: misc.province,
      branchItems: [misc.branch],
      allBranch: misc.branch,
      backboneCategory: this.props.product.toJS().backboneCategory,
      mainCategoryOptions,
      subCategoryOptions,
    });
  }

  getCategoryOptions = (mainCate) => {
    const backboneCategory = this.props.product.toJS().backboneCategory;
    let mainCategoryOptions = _.get(backboneCategory, ['categories'], []).map(({ cateUUID, cateNameLocal }) => ({
      disabled: false,
      value: cateUUID,
      label: cateNameLocal,
    }));
    mainCategoryOptions = [
      { label: 'เลือกทั้งหมด', value: 'ALL' },
      ...mainCategoryOptions,
    ]
    let subCategoryOptions = []
    if (mainCate && mainCate.length > 0) {
      subCategoryOptions.push({ label: 'เลือกทั้งหมด', value: 'ALL' })
      for (let i = 0; i < mainCate.length; i++) {
        const mainCategory = _.get(backboneCategory, ['categories'], []).find(({ cateUUID }) => cateUUID === mainCate[i]);
        const subCategories = _.get(mainCategory, ['subCategories'], []);
        if (subCategories && subCategories.length > 0) {
          for (let j = 0; j < subCategories.length; j++) {
            const subCategory = subCategories[j];
            subCategoryOptions.push({
              disabled: false,
              value: subCategory.subCateUUID,
              label: subCategory.subCateNameLocal,
            })
          }
        }
      }
    } else {
      subCategoryOptions = [];
    }
    return { mainCategoryOptions, subCategoryOptions };
  }

  onChangeMainCategory = (selectedItem) => async (e) => {
    const ids = _.isEmpty(e) ? [] : e.split(',');
    let selectedMainCategory;
    if (ids.includes('ALL')) {
      const backboneCategory = this.props.product.toJS().backboneCategory;
      const mainCategoryOptions = _.get(backboneCategory, ['categories'], []).map(({ cateUUID }) => cateUUID);
      selectedMainCategory = mainCategoryOptions;
    } else {
      selectedMainCategory = ids;
    }
    const { mainCategoryOptions, subCategoryOptions } = this.getCategoryOptions(selectedMainCategory);
    const validSubCate = subCategoryOptions.map(({ value }) => value);

    let selectedSkuCode = []
    const validSelectedSubCate = _.intersection(validSubCate, this.state.selectedSubCategory[selectedItem.key]);

    if (selectedMainCategory.length > 0) {
      let skuList = [];
      if (validSelectedSubCate.length === 0) {
        skuList = await getBackboneSkuFromCategory({ cateUUID: selectedMainCategory });
      } else {
        skuList = await getBackboneSkuFromCategory({ subCateUUID: validSelectedSubCate });
      }
      skuList = skuList.map(({ skuCode }) => skuCode);
      selectedSkuCode = skuList;
    }
    this.setState((prev) => ({
      selectedMainCategory: { ...prev.selectedMainCategory, [selectedItem.key]: selectedMainCategory },
      selectedSubCategory: { ...prev.selectedSubCategory, [selectedItem.key]: validSelectedSubCate },
      selectedSkuCode: { ...prev.selectedSkuCode, [selectedItem.key]: selectedSkuCode },
      subCategoryOptions: { ...prev.subCategoryOptions, [selectedItem.key]: subCategoryOptions },
    }));
  }

  onChangeSubCategory = (selectedItem) => async (e) => {
    const ids = _.isEmpty(e) ? [] : e.split(',');
    let selectedSubCategory;
    if (ids.includes('ALL')) {
      let subCategoryOptions = [];
      const backboneCategory = this.props.product.toJS().backboneCategory;
      for (let i = 0; i < this.state.selectedMainCategory[selectedItem.key].length; i++) {
        const mainCategoryId = this.state.selectedMainCategory[selectedItem.key][i];
        const mainCategory = _.get(backboneCategory, ['categories'], []).find(({ cateUUID }) => cateUUID === mainCategoryId);
        const subCategories = _.get(mainCategory, ['subCategories'], []);
        if (subCategories && subCategories.length > 0) {
          for (let j = 0; j < subCategories.length; j++) {
            const subCategory = subCategories[j];
            subCategoryOptions.push(subCategory.subCateUUID);
          }
        }
      }
      selectedSubCategory = subCategoryOptions;
    } else {
      selectedSubCategory = ids;
    }
    let selectedSkuCode = [];
    if (selectedSubCategory.length > 0 || this.state.selectedMainCategory[selectedItem.key].length > 0) {
      let skuList = [];
      if (selectedSubCategory.length > 0) {
        skuList = await getBackboneSkuFromCategory({ subCateUUID: selectedSubCategory });
      } else {
        skuList = await getBackboneSkuFromCategory({ cateUUID: this.state.selectedMainCategory[selectedItem.key] });
      }
      skuList = skuList.map(({ skuCode }) => skuCode);
      selectedSkuCode = skuList;
    }
    this.setState((prev) => ({
      selectedSubCategory: { ...prev.selectedSubCategory, [selectedItem.key]: selectedSubCategory },
      selectedSkuCode: { ...prev.selectedSkuCode, [selectedItem.key]: selectedSkuCode },
    }));
  }
  // END QBC-297

  async onCopyGroupPromotion() {
    const info = this.props.coupongroup.toJS().info;
    let confirmDialogOptions = {
      title: 'คัดลอกข้อมูล',
      text: 'กรุณายืนยันการคัดลอกกลุ่มส่วนลดนี้',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          let nameCopy = info.name + ' (Copy)';
          this.props.coupongroup.saveInfo('name', nameCopy);
          this.props.coupongroup.saveInfo('id', undefined);
          this.props.coupongroup.saveInfo('adBanner', null);
          this.props.coupongroup.saveInfo('coupons', []);
          this.props.coupongroup.deleteInformation();
          this.setState({
            isEdit: undefined,
            id: undefined,
          });
          this.props.history.push(`${config.publicUrl}/qchang/coupongroup/add`);
          // await this.submitForm();
          return true;
        } catch (error) {
          swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          let nameCopy = info.name;
          this.props.coupon.saveInfo('name', nameCopy);
          return false;
          // swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'คัดลอกข้อมูลเรียบร้อย',
        icon: 'success',
      }).then((result) => {
        if (result.value) {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 200);
        } else {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 200);
        }
      });
    }
  }

  onPromotionRange = (index) => {
    const items = _.cloneDeep(this.state.items);
    const item = items[index];
    if (item.promotion_range && item.range.length > 0) {
      swal.fire({
        title: 'หากคุณเปลี่ยนแปลงเป็นโปรโมชั่นเดียว \n ข้อมูลช่วงราคาจะถูก Reset',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
      }).then(async (result) => {
        if (result.value) {
          item.range = [];
          items[index].promotion_range = !items[index].promotion_range;
          this.setState({
            items,
            promotionRange: !this.state.promotionRange
          });
        }
      });
    } else {
      items[index].promotion_range = !items[index].promotion_range;
      this.setState({
        items,
        promotionRange: !this.state.promotionRange
      });
    }
  }

  onTextCouponInfoChange(key, e) {
    if (e.target) {
      const { couponInfo } = this.props.coupongroup.toJS().info;
      this.props.coupongroup.saveCouponInfo(key, e.target.value);
      this.isValidUseLimit(couponInfo.limitedUsePerMember);
    }
  }

  onDateChange(startDate, endDate) {
    this.props.coupongroup.saveCouponInfo('startDate', startDate ? startDate.toDate() : startDate);
    this.props.coupongroup.saveCouponInfo('endDate', endDate ? endDate.toDate() : endDate);
  }

  onToggle = (value) => {
    this.props.coupongroup.saveCouponInfo('status', value ? 'active' : 'inactive');
  }

  onLimitCouponToggle = (value) => {
    if (value) {
      this.props.coupongroup.saveCouponInfo('isLimit', value);
      const { couponInfo } = this.props.coupongroup.toJS().info;
      this.isValidUseLimit(couponInfo.limitedUsePerMember);
    } else {
      this.props.coupongroup.saveCouponInfo('useLimit', 0);
      this.props.coupongroup.saveCouponInfo('isLimit', value);
      this.setState({ limitedUsePerMemberError: false });
    }
  }

  onRequestPaymentToggle = (value) => {
    this.props.coupongroup.saveCouponInfo('requestPayment', value);
  }

  onRequestNotiToggle = (value) => {
    this.props.coupongroup.saveCouponInfo('requestNotification', value);
    // set request noti when request payment
    if (value === false) {
      this.props.coupongroup.saveCouponInfo('requestPayment', value);
    }
  }

  onRequestStorePaymentToggle = (value) => {
    this.props.coupongroup.saveCouponInfo('requestStorePayment', value);
  }

  onChangeEmails(tags) {
    let emails = tags.map(item => item.split(','));
    emails = _.uniq(_.flatten(emails)).filter(item => item);
    this.props.coupongroup.saveCouponInfo('storePaymentNotiEmails', emails);
  }

  isValid() {
    const { initLoading } = this.state;
    const { info } = this.props.coupongroup.toJS();
    // const loadProduct = this.props.product.toJS().isLoading;
    // const loadCoupon = this.props.coupon.toJS().isLoading;
    const validName = !_.isEmpty(info.name);
    const startDate = info.couponInfo.startDate;
    const endDate = info.couponInfo.endDate;
    const selectedBranch = this.state.branchItems[0]
    const isSelectBranch = this.state.isSelectBranch;

    if (isSelectBranch && selectedBranch && selectedBranch.length && selectedBranch.selected_id) {
      // if type is BizAccount, then must select products
      return validName
        && endDate
        && startDate
        && !initLoading
        && (selectedBranch.selected_id.length != 0
          && selectedBranch.selected_id[0] !== '');
    }
    else {
      return validName
        && endDate
        && startDate
        && !initLoading;
    }
  }

  isValidUseLimit(value) {
    const { couponInfo } = this.props.coupongroup.toJS().info;
    const { isLimit, useLimit } = couponInfo

    if (isLimit && parseInt(useLimit) < parseInt(value)) {
      this.setState({ limitedUsePerMemberError: true })
    } else {
      this.setState({ limitedUsePerMemberError: false })
    }

    this.props.coupongroup.saveCouponInfo('limitedUsePerMember', value);
  }

  async getCouponItems() {
    const getCouponHistory = true;
    let res = await this.props.coupon.getListResult('groupCoupon', this.state.id, getCouponHistory);
    res = res.map(item => {
      return _.pick(item, '_id', 'code', 'channel', 'type', 'model');
    });
    const coupons = this.state.coupons;
    _.set(coupons, 'items', res);
    this.setState({ coupons });
  }

  getProductName = (id) => {
    const product = this.state.products[0].find(p => p.id === id);
    if (product) {
      return product.name.th;
    }
    return '';
  }

  convertItemsToDb = (options) => {
    const productItems = (options || []).map(i => {
      i.range.map(data => { data.date = i.date });
      return {
        products: i.selectedId.map(s => {
          if (typeof s === 'string') {
            return {
              id: s,
              label: this.getProductName(s),
            };
          }
          return {
            id: s.value,
            label: s.label,
          };
        }
        ),
        value: i.value,
        range: i.range,
        daily_promotion: i.daily_promotion || false,
        date: i.date || [],
        promotion_range: i.promotion_range || false,
      };
    });
    return productItems;
  }

  validationCondition() {
    const items = this.state.items;
    let rangeItem = (items || []).filter((r) => {
      if (r.promotion_range) {
        return r.range.find(i => (i.min <= 0) || (i.max <= 0) || (i.value <= 0) || !i.min || !i.max || !i.value);
      }
    });
    let valueItem = (items || []).filter((r) => {
      if (r.promotion_range === false) {
        return (r.value === 0 || r.value === undefined);
      }
    });
    if (rangeItem.length > 0 || valueItem.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async submitForm() {
    if (this.validationCondition()) {
      try {
        let selectedBranch = this.state.branchItems[0].selected_id;

        if (!selectedBranch.length) {
          selectedBranch = [];
          forEach(this.state.allBranch, item => {
            selectedBranch.push(item._id);
          })
        }

        // if (!this.state.isSelectBranch) {
        //   selectedBranch=[];
        // }

        const productOptions = {
          selectProductIds: this.convertItemsToDb(this.state.items),
          provinceItems: this.state.provinceItems[0].selected_id,
          branchItems: selectedBranch
        };

        if (!this.validate.isValid()) {
          let error = 'เลือกวันซ้ำ';
          throw error;
        }

        const { updateFields } = this.props.coupongroup.toJS();
        const jsonFromFile = _.get(this.state.fileUpload, 'json', []);
        let payload = {
          updateFields: updateFields,
          json: jsonFromFile,
        };

        if (this.state.isEdit) {
          await this.props.coupongroup.update(this.state.id, productOptions);
          await this.props.coupongroup.updateCouponByGroupId(this.state.id, payload);
        } else {
          const created = await this.props.coupongroup.create(productOptions);
          await this.props.coupongroup.updateCouponByGroupId(created._id, payload);
          this.setState({ id: created._id });
        }
      } catch (error) {
        throw error;
      }
    } else {
      let error = 'กรุณากรอกช่วงราคาให้ครบถ้วน';
      throw error;
    }
  }

  async onSubmit() {
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: `${this.state.isEdit ? 'ต้องการแก้ไขกลุ่มรหัสส่วนลด ?' : 'ต้องการเพิ่มกลุ่มรหัสส่วนลดใหม่ ?'}`,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      footer: '<span style="color:red;">รหัสส่วนลดที่อยู่ในกลุ่มนี้จะถูกแก้ไขด้วย</span>',
      preConfirm: async () => {
        try {
          if (this.state.isCampaignManager) {
            await this.uploadFile();
            await this.submitForm();
          }
          await this.onSubmitBanner();
          this.setState({ fileUpload: null });
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      setTimeout(() => {
        this.onCancel();
      }, 1000);
    }
  }

  async onCancel() {
    this.props.history.replace(`${config.publicUrl}/qchang/coupongroup`);
  }

  async selectProductAll(key) {
    try {
      const { coupongroup: couponGroupStore } = this.props;
      const { productChannel: channelGroup, channel: selectedChannelNames } = couponGroupStore.toJS().info.newCouponInfo;
      const items = _.cloneDeep(this.state.items);
      let item = items.find(i => i.key === key);
      const products = get(item, 'product', []);
      const channelNames = this.getChannelOptions().map(({ value }) => value);
      let productId = [];

      if (selectedChannelNames.length === 0) {
        return;
      } else if (channelGroup === CHANNEL_GROUP.V1) {
        const filteredProducts = products.filter(({ productChannel }) => common.hasCommonElements(channelNames, productChannel));
        const productIds = filteredProducts.map(({ id }) => id);
        productId = [...productIds];
        item.selectedId = [...productIds];
      } else {
        let selectedSkuCode = _.get(this.state.selectedSkuCode, [`${key}`], [])
        if (_.isEmpty(this.state.selectedMainCategory) && _.isEmpty(this.state.selectedSubCategory)) {
          const backboneCategory = this.props.product.toJS().backboneCategory;
          const mainCategoryOptions = _.get(backboneCategory, ['categories'], []).map(({ cateUUID }) => cateUUID);
          const skuList = await getBackboneSkuFromCategory({ cateUUID: mainCategoryOptions });
          selectedSkuCode = skuList.map(({ skuCode }) => skuCode);
          this.setState({
            selectedSkuCode: {
              ...this.state.selectedSkuCode,
              [key]: selectedSkuCode
            }
          })
        }
        const filteredProducts = products.filter(({ productChannel, skuCode }) => {
          const isInChannels = common.hasCommonElements(channelNames, productChannel);
          const isInSkuList = selectedSkuCode.length ? selectedSkuCode.includes(skuCode) : true;
          return isInChannels && isInSkuList;
        });
        const productIds = filteredProducts.map(({ id }) => id);
        productId = [...productIds];
        item.selectedId = [...productIds];
      }
      this.setState({
        items,
        selectedProduct: productId,
      });
    } catch (error) {
      console.log('selectProductAll/error', error)
    }
  }

  onChangeProduct(selectedItem, e) {
    const ids = _.isEmpty(e) ? [] : e.split(',');
    const items = _.cloneDeep(this.state.items);
    let item = items.find(i => i.key === selectedItem.key);
    if (e.indexOf('ALL') >= 0) {
      this.selectProductAll(selectedItem.key)
      return
    }
    if (!_.isNil(item) && !_.isEmpty(item)) {
      item.selectedId = ids;
    }

    let productId = [];
    _.forEach(items, (item, index) => {
      if (item.selectedId && item.selectedId.length) {
        const id = item.selectedId.map(i => {
          if (typeof i === 'object') {
            return i.value;
          }
          return i;
        });
        productId.push(...id);
        if (item.promotion_range && item.selectedId.length > 1) {
          swal.fire({
            title: 'หากคุณเปลี่ยนแปลงสินค้าที่ร่วมรายการ \n ข้อมูลช่วงราคาจะถูก Reset',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'ตกลง',
            cancelButtonText: 'ยกเลิก',
          }).then(async (result) => {
            if (result.value) {
              item.range = [];
              item.promotion_range = false;
              this.setState({
                items,
                selectedProduct: productId,
              });
            }
          });
        } else {
          this.setState({
            items,
            selectedProduct: productId,
          });
        }
      } else {
        item.value = 0;
        item.date = [];
        item.daily_promotion = false;
        item.range = [];
        item.promotion_range = false;
        this.setState({
          items,
          selectedProduct: productId,
        });
      }
    });
  }

  deleteItems(item, index) {
    const data = this.state.items.filter(d => d.key !== item.key);
    let selectedProduct = [];
    _.forEach(data, (item, index) => {
      if (item.selectedId && item.selectedId.length) {
        const id = item.selectedId.map(i => {
          if (typeof i === 'object') {
            return i.value;
          }
          return i;
        });
        selectedProduct.push(...id);
      }
    });
    this.setState({
      items: data,
      selectedProduct,
    });
  }

  deleteItemsRange(range, e) {
    const items = _.cloneDeep(this.state.items);
    if (e && e.target) {
      let rangeItem = (items || []).map((r) => {
        r.range = r.range.filter(i => i.key !== range.key);
        return r;
      });
      this.setState({
        items: rangeItem,
      });
    }
  }

  onAddItems = () => {
    let items = _.cloneDeep(this.state.items);
    const productItems = this.state.products[0];
    const key = (new Date()).getTime()
    items.unshift({
      key,
      selectedId: [],
      product: productItems,
      value: 0,
      range: [],
      daily_promotion: false,
      date: [],
      promotion_range: false,
    });
    const { newCouponInfo } = this.props.coupongroup.toJS().info
    if (newCouponInfo.productChannel === CHANNEL_GROUP.V2) {
      const { mainCategoryOptions: m, subCategoryOptions: s } = this.getCategoryOptions([])
      this.setState((prev) => ({
        items,
        mainCategoryOptions: { ...prev.mainCategoryOptions, [key]: m },
        subCategoryOptions: { ...prev.subCategoryOptions, [key]: s },
      }));
    } else {
      this.setState({ items });
    }
  }

  onAddRange = (selectItem, e) => {
    const items = _.cloneDeep(this.state.items);
    if (e && e.target) {
      let item = items.find(i => i.key === selectItem.key);
      item.range.push({
        key: (new Date()).getTime(),
        min: 0,
        max: 0,
        date: [0, 1, 2, 3, 4, 5, 6],
      });
    }
    this.setState({ items });
  }

  // onContractorDiscountChange = (range, e) => {
  //   const items = _.cloneDeep(this.state.items);
  //   if (e && e.target) {
  //     let rangeItem = (items || []).filter((r) => {
  //       rangeItem = r.range.find(i => i.key === range.key);
  //       if (rangeItem) {
  //         rangeItem.contractor_discount = +e.target.value;
  //       }
  //     });
  //   }
  //   this.setState({ items });
  // }

  onDateCheckBoxChange(dateIndex, itemIndex, e) {
    const dateValue = +e.target.value;
    let items = _.cloneDeep(this.state.items);
    if (items[itemIndex].date.indexOf(dateValue) >= 0) {
      items[itemIndex].date = items[itemIndex].date.filter(i => i !== dateValue);
    } else {
      items[itemIndex].date.push(dateValue);
    }
    this.setState({ items });
  }

  onPromotionCheck = (index) => {
    const items = _.cloneDeep(this.state.items);
    items[index].daily_promotion = !items[index].daily_promotion;
    if (!items[index].daily_promotion) {
      items[index].range = items[index].range.map(r => {
        return {
          ...r,
          date: [],
        };
      });
    }
    this.setState({ items });
  }

  showItem() {
    const { newCouponInfo } = this.props.coupongroup.toJS().info;
    const items = (this.state.items || []).map((item, index) => {
      const products = get(item, 'product', []);
      const channelNames = this.getChannelOptions().map(({ value }) => value);
      let productOptions = [];

      if (newCouponInfo.channel.length && newCouponInfo.productChannel === CHANNEL_GROUP.V1) {
        productOptions = products.filter(({ productChannel }) => common.hasCommonElements(channelNames, productChannel));
      } else if (newCouponInfo.channel.length && newCouponInfo.productChannel === CHANNEL_GROUP.V2) {
        const selectedSkuCode = _.get(this.state.selectedSkuCode, [`${item.key}`], []);
        productOptions = products.filter(({ id, productChannel, skuCode }) => {
          const isAlreadySelected = (item.selectedId || []).includes(id);
          const isInChannels = common.hasCommonElements(channelNames, productChannel);
          const isInSkuList = selectedSkuCode.length ? selectedSkuCode.includes(skuCode) : true;
          return isAlreadySelected || (isInChannels && isInSkuList);
        });
      }
      productOptions = (productOptions || []).map(p => {
        return ({
          label: `${_.get(p, 'skuCode', '')} ${_.get(p, 'name.th', '')}`,
          value: p.id,
          disabled: _.indexOf(this.state.selectedProduct, p.id) >= 0,
        });
      });
      productOptions = _.orderBy(productOptions, ['label'])
      if (item.product.length !== this.state.selectedProduct.length) {
        productOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...productOptions];
      }
      const orderProduct = [
        ...productOptions
      ]
      let price = _.get(item, `selectedId.0.price`);
      if (!price) {
        const id = _.get(item, `selectedId.0`, false);
        const productList = _.get(item, 'product', []);
        const product = productList.find(x => x.id === id);
        price = _.get(product, 'calculator.unitPrice', 0);
      }

      const priceRange = (item.range || []).map((r, indexRange) => {
        const platform_discount = r.platform_discount || 0
        const bu_discount = r.bu_discount || 0
        const partner_discount = r.partner_discount || 0
        const contractor_discount = r.contractor_discount || 0
        const sumDiscount = r.value || 0
        r.min = r.min || (price * indexRange) + 1;
        r.max = r.max || price * (indexRange + 1);

        return (
          <div className="duration-range" key={`range-${index}-${indexRange}`} >
            <h4>ช่วงราคาที่ {indexRange + 1}</h4>
            <div className="row">
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="เริ่มต้น">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={r.min}
                      onChange={this.onStartChange.bind(this, r)}
                      disabled={!this.state.isCampaignManager}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="สิ้นสุด">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={r.max}
                      onChange={this.onEndChange.bind(this, r)}
                      disabled={!this.state.isCampaignManager}
                    />
                  </div>
                </FormGroup>
              </div>
            </div >
            <div className="row">
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="ส่วนลด platform">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={platform_discount}
                      onChange={this.onValueChange.bind(this, r, 'platform_discount')}
                      disabled={!this.state.isCampaignManager}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="ส่วนลด  BU">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={bu_discount}
                      onChange={this.onValueChange.bind(this, r, 'bu_discount')}
                      disabled={!this.state.isCampaignManager}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="ส่วนลด Partner">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={partner_discount}
                      onChange={this.onValueChange.bind(this, r, 'partner_discount')}
                      disabled={!this.state.isCampaignManager}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="ส่วนลดช่าง">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={contractor_discount}
                      onChange={this.onValueChange.bind(this, r, 'contractor_discount')}
                      disabled={!this.state.isCampaignManager}
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <FormGroup containerClassName="" label="ส่วนลดรวม">
                  <div style={{ paddingTop: '5px' }}>
                    <EnTextFormGroup
                      type="number"
                      value={sumDiscount || 0}
                      disabled
                    />
                  </div>
                </FormGroup>
              </div>
              <div className="col-md-2 col-xs-4">
                <EnButton
                  className="btn-danger"
                  onClick={this.deleteItemsRange.bind(this, r)}
                  style={{ marginTop: '35px' }}
                  disabled={indexRange + 1 < item.range.length ? true : false || !this.state.isCampaignManager}
                >
                  <i className="fa fa-trash" aria-hidden="true" />
                </EnButton>
              </div>
            </div>
          </div >
        );
      });

      return (
        <div key={`item-${index}`} className="card item-card" style={{ border: '1px solid  #BCC5CC' }} >
          <div className="card-content">
            {/* show main & sub category */}
            {newCouponInfo.productChannel === CHANNEL_GROUP.V2 && (
              <>
                <FormGroup containerClassName="col-md-6" label="หมวดหมู่หลัก">
                  <EnTagSelect
                    withHeight
                    placeholder="กรุณาเลือกหมวดหมู่หลัก"
                    closeOnSelect={false}
                    options={_.get(this.state.mainCategoryOptions, [`${item.key}`], [])}
                    value={_.get(this.state.selectedMainCategory, [`${item.key}`], [])}
                    onTagChange={this.onChangeMainCategory(item)}
                    searchable
                    disabled={!this.state.isCampaignManager}
                  />
                </FormGroup>
                <FormGroup containerClassName="col-md-6" label="หมวดหมู่ย่อย">
                  <EnTagSelect
                    withHeight
                    placeholder="กรุณาเลือกหมวดหมู่ย่อย"
                    closeOnSelect={false}
                    options={_.get(this.state.subCategoryOptions, [item.key], [])}
                    value={_.get(this.state.selectedSubCategory, [item.key], [])}
                    onTagChange={this.onChangeSubCategory(item)}
                    searchable
                    disabled={!this.state.isCampaignManager}
                  />
                </FormGroup>
              </>
            )}
            <FormGroup containerClassName="col-md-8" label="สินค้าที่ร่วมรายการ">
              <EnTagSelect
                withHeight
                placeholder="กรุณาเลือกสินค้า"
                closeOnSelect={false}
                options={orderProduct}
                value={item.selectedId}
                onTagChange={this.onChangeProduct.bind(this, item)}
                searchable={false}
                disabled={!this.state.isCampaignManager}
              />
            </FormGroup>
            <FormGroup containerClassName="col-md-4" label="ราคาส่วนลด default">
              <div style={{ paddingTop: '5px' }}>
                <EnTextFormGroup
                  type="number"
                  value={item.value}
                  onChange={this.onPriceChange.bind(this, item)}
                  disabled={!this.state.isCampaignManager}
                />
              </div>
            </FormGroup>

            <div className="col-md-12 switch-field">
              <input
                type="radio"
                key={`switch_right-${index}`}
                id={`switch_right-${index}`}
                name={`switch_right-${index}`}
                value={false}
                onChange={this.onPromotionRange.bind(this, index)}
                checked={!item.promotion_range}
                disabled={!this.state.isCampaignManager}
              />
              <label
                htmlFor={`switch_right-${index}`}
                disabled={!this.state.isCampaignManager}
                style={{
                  cursor: !this.state.isCampaignManager ? 'not-allowed' : 'pointer',
                  pointerEvents: !this.state.isCampaignManager ? 'none' : 'auto',
                }}
              >
                {!item.promotion_range && <i className="fa fa-check" aria-hidden="true" />} โปรโมชั่นเดียว
              </label>
              <input
                type="radio"
                key={`switch_left-${index}`}
                id={`switch_left-${index}`}
                name={`switch_left-${index}`}
                value={true}
                onChange={this.onPromotionRange.bind(this, index)}
                checked={item.promotion_range}
                disabled={
                  item.selectedId.length > 1 ? 1 : 0 && !this.state.isCampaignManager
                }
              />
              <label
                htmlFor={`switch_left-${index}`}
                disabled={item.selectedId.length > 1 ? 1 : 0 && !this.state.isCampaignManager}
                style={{
                  cursor: !this.state.isCampaignManager ? 'not-allowed' : 'pointer',
                  pointerEvents: !this.state.isCampaignManager ? 'none' : 'auto',
                }}
              >
                {item.promotion_range && <i className="fa fa-check" aria-hidden="true" />} โปรโมชั่นช่วงราคา
              </label>
            </div>

            <FormGroup containerClassName="col-md-12">
              <EnToggle
                checked={item.daily_promotion}
                onChange={this.onPromotionCheck.bind(this, index)}
                label={'โปรโมชั่นรายวัน'}
                disabled={!this.state.isCampaignManager}
              />
            </FormGroup>
            {item.daily_promotion && (
              <Fragment>
                <div className="col-md-12" style={{ paddingLeft: '10px', paddingBottom: '25px' }}>
                  <DateCheckBox
                    items={item}
                    index={index}
                    onDateCheckBoxChange={this.onDateCheckBoxChange.bind(this, index, index)}
                    disabled={!this.state.isCampaignManager}
                  />
                </div>
              </Fragment>
            )}
            {item.promotion_range && (item.selectedId.length < 2) && (
              <Fragment>
                <div className="" style={{ float: 'left', width: '100%', marginBottom: '20px' }}>
                  <div className=" duration-form">
                    {priceRange}
                  </div>
                </div>
                <div className="col-md-12 text-center" style={{ margin: '0 0 30px 0' }}>
                  <EnButton
                    className="btn-success"
                    onClick={this.onAddRange.bind(this, item)}
                    disabled={!this.state.isCampaignManager}
                  >
                    <i className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่มช่วงราคา
                  </EnButton>
                </div>
              </Fragment>
            )}
            {this.state.items.length > 1 &&
              <EnButton
                className="pull-right"
                onClick={this.deleteItems.bind(this, item)}
                disabled={!this.state.isCampaignManager}
              >
                <i className="fa fa-trash btn-icon" aria-hidden="true" />ลบ
              </EnButton>
            }
          </div>
        </div >
      );
    });
    return items;
  }

  onTypeChange = (e) => {
    if (e && e.target) {
      this.props.coupongroup.saveCouponInfo('type', e.target.value);

      const productItems = this.props.product.toJS().items;
      let items = [
        {
          key: (new Date()).getTime(),
          selectedId: [],
          product: productItems,
          range: [],
          value: null,
          daily_promotion: false,
          date: [],
          promotion_range: false,
        }
      ];
      this.handleDeleteFile();
      const { mainCategoryOptions: m, subCategoryOptions: s } = this.getCategoryOptions([])
      let mainCategoryOptions = {}, subCategoryOptions = {};
      for (let i = 0; i < items.length; i++) {
        mainCategoryOptions = { ...mainCategoryOptions, [items[i].key]: m }
        subCategoryOptions = { ...subCategoryOptions, [items[i].key]: s }
      }
      this.setState({
        items,
        mainCategoryOptions,
        subCategoryOptions,
      })
    }
  }

  onPriceChange = (selectedItems, e) => {
    const items = _.cloneDeep(this.state.items);
    if (e && e.target) {
      let item = items.find(i => i.key === selectedItems.key);
      item.value = e.target.value;
    }
    this.setState({ items });
  }

  onStartChange = (range, e) => {
    const items = _.cloneDeep(this.state.items);
    if (e && e.target) {
      let rangeItem = (items || []).filter((r) => {
        rangeItem = r.range.find(i => i.key === range.key);
        if (rangeItem) {
          rangeItem.min = +e.target.value;
        }
      });
    }
    this.setState({ items });
  }

  onEndChange = (range, e) => {
    const items = _.cloneDeep(this.state.items);
    if (e && e.target) {
      let rangeItem = (items || []).filter((r) => {
        rangeItem = r.range.find(i => i.key === range.key);
        if (rangeItem) {
          rangeItem.max = +e.target.value;
        }
      });
    }
    this.setState({ items });
  }

  calculateSumDiscount(rangeItem) {
    let sumDiscount = 0
    const listDiscount = ["contractor_discount", 'bu_discount', 'partner_discount', 'platform_discount']

    _.forEach(listDiscount, key => {
      if (rangeItem[key]) {
        sumDiscount += rangeItem[key] || 0
      }
    })
    return sumDiscount
  }

  onValueChange = (range, key = "", e) => {
    const items = _.cloneDeep(this.state.items);
    if (e && e.target) {
      let rangeItem = (items || []).filter((r) => {
        rangeItem = r.range.find(i => i.key === range.key);
        if (rangeItem && key) {
          rangeItem[key] = +e.target.value;
          rangeItem.value = this.calculateSumDiscount(rangeItem)
        }
      });

    }
    this.setState({ items });
  }

  showProvinceItem() {
    let provinceOptions;
    let items = (this.state.provinceItems || []).map((item, index) => {
      provinceOptions = (item || []).map(d => {
        return ({
          label: d['name'],
          value: d['code'],
        });
      });

      if (this.state.allProvince.length !== this.state.provinceItems[0].selected_id.length) {
        provinceOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...provinceOptions];
      }

      return (
        <div key={`item-${index}`}>
          <div>
            <FormGroup label="เลือกจังหวัดที่เข้าร่วมโปรโมชั่น">
              <EnTagSelect
                closeOnSelect={false}
                options={provinceOptions}
                onTagChange={this.onProvinceChange.bind(this, item)}
                value={item['selected_id']}
                disabled={!isAllow('EDIT_COUPON_GROUP_PROMOTIONAL_PROVINCE') && this.state.isEdit}
                searchable
              />
            </FormGroup>
          </div>
        </div>
      );
    });
    return items;
  }

  showBranchItem() {
    let branchOptions;
    let items = (this.state.branchItems || []).map((item, index) => {
      branchOptions = (item || []).map(d => {
        return ({
          label: d['name'],
          value: d['_id'],
        });
      });

      if (this.state.allBranch.length !== this.state.branchItems[0].selected_id.length) {
        branchOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...branchOptions];
      }

      return (
        <div key={`item-${index}`}>
          <div>
            <FormGroup label="สาขา">
              <EnTagSelect
                className={this.state.branchItems[0]
                  && this.state.branchItems[0].selected_id[0]
                  && this.state.branchItems[0].selected_id.length
                  ? '' : 'error-select-branch'}
                closeOnSelect={false}
                options={branchOptions}
                onTagChange={this.onBranchChange.bind(this, item)}
                value={item['selected_id']}
                searchable
              />
            </FormGroup>
          </div>
        </div>
      );
    });
    return items;
  }

  onBranchChange(selectedItem, e) {
    let branchItems = _.cloneDeep(this.state.branchItems);
    if (e.indexOf('ALL') >= 0) {
      const branchId = this.state.allBranch.map(b => b._id);
      let item = branchItems.find(i => i.key === selectedItem.key);
      item['selected_id'] = branchId;
    } else {
      let branchId = e.split(',');
      let item = branchItems.find(i => i.key === selectedItem.key);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item['selected_id'] = branchId;
      }
    }

    this.setState({
      branchItems,
    });
  }

  onProvinceChange(selectedItem, e) {
    let provinceItems = _.cloneDeep(this.state.provinceItems);
    if (e.indexOf('ALL') >= 0) {
      const code = this.state.allProvince.map(p => p.code);
      let item = provinceItems.find(i => i.key === selectedItem.key);
      item['selected_id'] = code;
    } else {
      let code = e.split(',');
      let item = provinceItems.find(i => i.key === selectedItem.key);
      if (!_.isNil(item) && !_.isEmpty(item)) {
        item['selected_id'] = code;
      }
    }
    this.setState({
      provinceItems,
    });
  }

  onTextChange(key, e) {
    if (e.target) {
      this.props.coupongroup.saveInfo(key, e.target.value);
    }
  }

  onChangeCoupon(val) {
    const value = val.split(',');
    this.props.coupongroup.saveInfo('coupons', _.uniq(value).filter(item => item));
  }

  async onChangeNewCouponCodes(tags) {
    let codes = tags.map(item => item.split(','));
    codes = _.uniq(_.flatten(codes)).filter(item => item);
    this.props.coupongroup.saveNewCouponInfo('codes', codes);
    const newCouponInfo = this.props.coupongroup.toJS().info.newCouponInfo;
    if (!newCouponInfo.codes.length > 0) {
      // const { coupongroup: couponGroupStore } = this.props;
      // couponGroupStore.saveNewCouponInfo('productChannel', null);
      // await this.props.coupongroup.saveNewCouponInfo('channel', []);
      this.setState({ items: [] })
    }
  }

  async onSetMisc(selectedChannelNames) {
    const couponGroup = this.props.coupongroup.toJS().info;
    const branch = selectedChannelNames.length ? await this.props.coupon.getStoreByID({ channel: selectedChannelNames }) : [];
    this.props.misc.saveId('miscData.branch', branch);

    const info = this.props.coupon.toJS().info;
    const misc = this.props.misc.toJS().miscData;

    if (info && info.provinceId && info.provinceId.length) {
      misc.province['selected_id'] = info.provinceId;
      misc.branch['selected_id'] = couponGroup.newCouponInfo.stores;
    } else {
      misc.province['selected_id'] = [];
      misc.branch['selected_id'] = [];
    }

    this.setState({
      branchItems: [misc.branch],
      allBranch: misc.branch
    });
  }

  onSelectChannel = async (selectedOptions) => {
    const selectedChannelNames = selectedOptions.map(({ value }) => value);
    this.props.coupongroup.saveNewCouponInfo('channel', selectedChannelNames);
    await this.onSetMisc(selectedChannelNames);
    // QBC-297
    // await this.props.product.getProductList(undefined, undefined, true, 'coupon');
    await this.handleStateChange();
    // END QBC-297
  }

  handleSelectChannelGroup = async ({ value: selectedChannelGroup }) => {
    const { coupongroup: couponGroupStore } = this.props;
    const currentChannelGroup = couponGroupStore.toJS().info.productChannel;

    if (currentChannelGroup !== selectedChannelGroup) {
      couponGroupStore.saveNewCouponInfo('productChannel', selectedChannelGroup);
      await this.onSelectChannel([]);
    }
  };

  handleFocusChannel = () => {
    const { coupongroup: couponGroupStore } = this.props;
    const { productChannel: channelGroup } = couponGroupStore.toJS().info.newCouponInfo;
    this.setState({ displayChannelGroupErrorMessage: !channelGroup });
  };

  onSelectModel = async ({ value }) => {
    this.props.coupongroup.saveNewCouponInfo('model', value);
    this.props.coupon.saveInfo('requestStorePayment', false);
    this.props.coupon.saveInfo('storePaymentNotiEmails', []);
  }
  validate = {
    isValid: () => {
      const items = this.state.items;
      let isValid = true;
      items.map((item, index) => {
        // check if daily promotion
        if (item.daily_promotion) {
          // find duplicate min max in range
          item.range.map(i => {
            const min = i.min;
            const max = i.max;
            const findDuplicate = item.range.filter(i => i.min === min && i.max === max);
            if (findDuplicate && findDuplicate.length > 1) {
              let result = [];
              findDuplicate.map(i => {
                if (i.date && i.date.length) {
                  result.push(...i.date);
                }
              });
              // check duplicate
              const isDuplicate = this.validate.isDuplicate(result);
              if (isDuplicate) {
                isValid = false;
              }
            }
          });
        }
      });
      return isValid;
    },
    isDuplicate: (array) => {
      return (new Set(array)).size !== array.length;
    },
  }

  getAllChannelOptions = () => {
    const { channel: channelStore } = this.props;
    const channels = channelStore.toJS().info || [];
    const channelOptions = channels.map(({ channel_name: channelName }) => ({ label: channelName, value: channelName }));

    return channelOptions;
  };

  /**
   * Get channel options depends on selected channel group.
   */
  getChannelOptions = () => {
    const { coupongroup: couponGroupStore, channel: channelStore } = this.props;
    const { productChannel: channelGroup } = couponGroupStore.toJS().info.newCouponInfo;
    const channels = channelStore.toJS().info || [];
    const v2Channels = channels.filter(item => item.product_channel.toLowerCase() === 'v2').map(item => item.channel_name);

    if (channelGroup === CHANNEL_GROUP.V1) {
      return channels.reduce((prevVal, { channel_name: channelName }) => {
        if (!v2Channels.includes(channelName)) {
          prevVal.push({ label: channelName, value: channelName });
        }
        return prevVal;
      }, []);
    } else if (channelGroup === CHANNEL_GROUP.V2) {
      return v2Channels.map((channelName) => ({ label: channelName, value: channelName }));
    }
    return [];
  };

  getModelOptions = () => {
    const model = this.props.model.toJS().info;
    return (model || []).map((c, index) => {
      return {
        label: c.model_name,
        value: c.model_name,
      };
    });
  };

  getCouponOptions = (isCodesFromFile) => {
    if (isCodesFromFile) {
      return []
    }

    const { items } = this.state.coupons;
    return (items || []).map((item) => {
      let label = `${item.code}`;
      if (Array.isArray(item.channel) && item.channel.length) {
        label += ` (${item.channel.join()})`;
      }
      let type = '';
      let className = '';
      if (item.type === 'I') {
        type = 'ส่วนลดค่าติดตั้ง';
        className = 'installation-class';
      } else if (item.type === 'S') {
        type = 'ส่วนลดค่าสำรวจ';
        className = 'survey-class';
      }

      return {
        label,
        value: item._id,
        className,
        title: `ประเภท: ${type}, Model: ${item.model}`,
      };
    });
  }

  addCouponByChannel() {
    const { selectedChannel: selectedChannelNames, items } = this.state.coupons;
    const { info } = this.props.coupongroup.toJS();
    const couponIds = items.reduce((prevVal, currentVal) => {
      if (Boolean(intersection(currentVal.channel, selectedChannelNames).length)) {
        prevVal.push(currentVal._id);
      }
      return prevVal;
    }, [])

    const coupons = _.uniq([...info.coupons, ...couponIds]);
    this.props.coupongroup.saveInfo('coupons', coupons);
    this.onChangeCouponChannel('');
  }

  removeCouponByChannel() {
    const { selectedChannel: selectedChannelNames, items } = this.state.coupons;
    const { info } = this.props.coupongroup.toJS();
    const toRemoveCouponIds = items.reduce((prev, current) => {
      if (Boolean(intersection(current.channel, selectedChannelNames).length)) {
        prev.push(current._id);
      }
      return prev;
    }, []);
    const newCoupons = info.coupons.filter(couponId => !toRemoveCouponIds.includes(couponId));
    this.props.coupongroup.saveInfo('coupons', _.uniq(newCoupons));
    this.onChangeCouponChannel('');
  }

  onChangeCouponChannel = (joinedChannelNames) => {
    const { coupons } = this.state;
    const channelNames = joinedChannelNames.length ? joinedChannelNames.split(',') : [];
    this.setState({ coupons: { ...coupons, selectedChannel: channelNames } });
  };

  onChangeUpdateFields(key, value) {
    this.props.coupongroup.saveUpdateFields(key, value);
  }

  async filterProductsDup() {
    const items = _.cloneDeep(this.state.items);
    const productOptions = {
      selectProductIds: this.convertItemsToDb(this.state.items),
      provinceItems: this.state.provinceItems[0].selected_id,
    };
    let productId = [];
    try {
      let productsDup = []
      productsDup = await this.props.coupongroup.getProductsDup(productOptions);

      _.forEach(items, (item, index) => {
        if (item.product && item.product.length) {
          const newProducts = []

          item.product.forEach(i => {
            if (productsDup.findIndex((p) => p.id === i.id) === -1) {
              if (typeof i === 'object') {
                newProducts.push(i)
              }
            }
          });
          item.product = newProducts
        }
        if (item.selectedId && item.selectedId.length) {

          const id = []
          item.selectedId.forEach(i => {
            if (productsDup.findIndex((p) => p.id === i) === -1) {
              if (typeof i === 'object') {
                id.push(i.value)
              }
              id.push(i)
            }
          });

          item.selectedId = id
          productId.push(...id);
        }
      });

      swal.fire({
        icon: 'success',
        title: 'กรองสินค้าสำเร็จ',
        showConfirmButton: false,
        timer: 2000
      })

    } catch (error) {
      swal.fire({
        icon: 'warning',
        title: 'กรองสินค้าไม่สำเร็จ',
        text: `${error}`
      })

    }
    this.setState({
      items,
      selectedProduct: productId,
    });
  }

  disableBtnFilterProducts() {
    let disabled = true
    const { coupons, newCouponInfo } = this.props.coupongroup.toJS().info
    if ((coupons.length > 0) || (newCouponInfo.codes.length > 0)) {
      disabled = false
    }
    return disabled
  }

  openDropzone(key) {
    this.setState({
      dropzone: {
        ...this.state.dropzone,
        [key]: true,
      }
    });
  }

  renderBannerImage(banner, key, defaultMessage) {
    if (!banner) {
      return (
        <div className="no-ad-banner-image">{defaultMessage}</div>
      );
    }
    return (
      <div>
        <div
          className="ad-banner-delete"
          onClick={() => { this.props.coupongroup.saveInfo(key, null); }}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <img src={banner} className={{ key } + "-banner-image"} />
      </div>
    );
  }

  async onSaveBannerDropzone(files, key) {
    try {
      const file = files[0];
      const result = await this.props.coupongroup.uploadBannerImage(file);
      this.props.coupongroup.saveInfo(key, result.path);
      this.setState({
        dropzone: { ...this.state.dropzone, [key]: false }
      });
    } catch (error) {
      swal.fire('อัพโหลดรูปไม่สำเร็จ', '', 'error');
      this.setState({
        dropzone: { ...this.state.dropzone, [key]: false }
      });
    }
  }

  closeBannerDropzone(key) {
    this.setState({
      dropzone: {
        ...this.state.dropzone,
        [key]: false,
      }
    });
  }

  onInfomationChange(key, e) {
    const value = e.target ? e.target.value : e;
    this.props.coupongroup.saveInformation(key, value);
  }

  onSelectBranch = (value) => {
    this.setState({ 'isSelectBranch': value });
  }

  onDeleteInfomationClick(e) {
    e.preventDefault();
    this.props.coupongroup.deleteInformation();
  }

  async onSubmitBanner() {
    try {
      const info = this.props.coupongroup.toJS().info;
      await this.props.coupongroup.updateBannerInfo(this.state.id, info);
      this.noti.success('แก้ไขข้อมูลเรียบร้อย', 'สำเร็จ');
    } catch (error) {
      this.noti.error(`${error.message}`, 'ล้มเหลว');
    }
  }

  renderInformation(info) {
    const index = 1;
    return (
      <div className="coupon-information">
        <div className="row" style={{ paddingLeft: '15px' }} >
          <label>{`ข้อมูลและรายละเอียด`}</label>
        </div>

        <div className="row">
          <EnTextFormGroup
            containerClassName="col-sm-10 col-md-11"
            type="text"
            placeholder="หัวข้อ"
            value={info.title}
            onChange={this.onInfomationChange.bind(this, 'title')}
            validations={[required]}
            label="หัวข้อ"
            notRequired
          />
          <div className="col-sm-2 col-md-1 information-button-desktop">
            <EnButton
              className="btn-danger"
              style={{ marginTop: '0' }}
              onClick={this.onDeleteInfomationClick.bind(this)}
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </EnButton>
          </div>
        </div>

        <div className="row">
          <EnTextFormGroup
            containerClassName="col-md-12"
            type="text"
            placeholder="รายละเอียด"
            value={info.description}
            onChange={this.onInfomationChange.bind(this, 'description')}
            validations={[required]}
            label="รายละเอียด"
            notRequired
          />
        </div>

        <div className="row">
          <EnTextFormGroup
            containerClassName="col-md-12"
            type="text"
            placeholder="หัวข้อคูปอง"
            value={info.couponHeader}
            onChange={this.onInfomationChange.bind(this, 'couponHeader')}
            // validations={[couponHeader]}
            label={<span>หัวข้อคูปอง <span style={{ color: 'red' }}> *จำกัด 12ตัวอักษร</span></span>}
            notRequired
            maxLength={12}
          />
        </div>

        <div className="row">
          <EnTextFormGroup
            containerClassName="col-md-12"
            type="text"
            placeholder="เงื่อนไขโดยย่อ"
            value={info.termsBrief}
            onChange={this.onInfomationChange.bind(this, 'termsBrief')}
            // validations={[limitText25]}
            label="เงื่อนไขโดยย่อ"
            notRequired
          />
        </div>

        <div className="row">
          <EnTextFormGroup
            containerClassName="col-md-12"
            type="text"
            placeholder="เงื่อนไขการใช้งาน"
            value={info.termsUse}
            onChange={this.onInfomationChange.bind(this, 'termsUse')}
            // validations={[limitText100]}
            label="เงื่อนไขการใช้งาน"
            notRequired
          />
        </div>

        <div className="row">
          <div className="col-md-6" style={{ padding: '0px' }}>
            <FormGroup containerClassName="col-md-12" label="ใช้สำหรับหน้า promotion detail" >
              <EnToggle
                checked={info.isOnlyPromotionDetail}
                onChange={this.onInfomationChange.bind(this, 'isOnlyPromotionDetail')}
                noPadding={true}
              />
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <FormGroup containerClassName="col-md-12">
            <EnCKEditor
              type='coupongroup'
              value={info.content}
              onChange={(event, editor) => {
                this.onInfomationChange('content', editor.getData());
              }}
            />
          </FormGroup>
        </div>

        <div className="row information-button-mobile">
          <div className="col-md-12">
            <EnButton
              className="btn-danger"
              style={{ marginTop: '0' }}
              onClick={this.onDeleteInfomationClick.bind(this, index)}
            >
              <i className="fa fa-trash" aria-hidden="true" />
            </EnButton>
          </div>
        </div>
      </div>
    );
  }

  async validateFileUpload(json) {
    try {
      if (!json || !json.length) {
        swal.fire('อัพโหลดไฟล์ไม่สำเร็จ', 'ไม่พบข้อมูลในไฟล์', 'error');
        return false;
      }

      const couponInfo = this.props.coupongroup.toJS().info;
      const couponType = couponInfo.couponInfo.type;
      // validate key
      const keysForValidate = ['customer_uuid', 'code'];
      const keys = Object.keys(json[0]).toString().split(',');

      if (keys.length !== 2 || !keysForValidate.every(key => keys.includes(key))) {
        swal.fire('อัพโหลดไฟล์ไม่สำเร็จ', 'ข้อมูลในไฟล์ไม่ถูกต้อง', 'error');
        return false;
      }

      if (json.length > 3000) {
        swal.fire('อัพโหลดไฟล์ไม่สำเร็จ', 'ข้อมูลในไฟล์เกิน 3000 รายการ', 'error');
        return false;
      }
      // validate value
      const result = json.every((item) => {
        if (!item.code || !item.customer_uuid) {
          return false;
        }

        const code = item.code.trim().split(',');
        const customer_uuid = item.customer_uuid.trim().split(',');

        if (code.length > 1) {
          return false;
        }

        if (customer_uuid.length > 1) {
          return false;
        }

        return item.code;
      });

      if (!result) {
        swal.fire('อัพโหลดไฟล์ไม่สำเร็จ', 'ข้อมูลในไฟล์ไม่ถูกต้อง', 'error');
        return false;
      }

      // check duplicate code
      const codes = json.map(item => item.code);

      // find duplicate code in array
      const grouped = _.groupBy(codes.map(item => item.toLowerCase()));
      const duplicates = _.pickBy(grouped, (group) => group.length > 1);
      const duplicateValues = _.flatMap(duplicates);
      if (duplicateValues.length) {
        swal.fire('อัพโหลดไฟล์ไม่สำเร็จ', 'รหัสส่วนลดในไฟล์ซ้ำกัน', 'error');
        return false;
      }

      // check duplicate code in db
      const isDuplicate = await this.props.coupon.checkDuplicateCode(codes, couponType);
      if (isDuplicate) {
        swal.fire('อัพโหลดไฟล์ไม่สำเร็จ', 'รหัสส่วนลดซ้ำกับที่มีอยู่', 'error');
        return false;
      }

      return true;
    } catch (error) {
      swal.fire('อัพโหลดไฟล์ไม่สำเร็จ', `${error.message}`, 'error');
      return false;
    }
  }

  handleChangInfo = (key, event) => {
    const value = event.target ? event.target.value : event;
    if (key === 'limitedUsePerMember' && value) {
      this.isValidUseLimit(value);
    }

    if (key === 'isLimitedUseMember' && !value) {
      // clear isLimitedUseMember
      this.props.coupongroup.saveCouponInfo('limitedUsePerMember', 1);
      this.setState({ limitedUsePerMemberError: false });
    }

    if (key === 'isMember' && !value) {
      // clear isLimitedUseMember
      this.props.coupongroup.saveCouponInfo('isLimitedUseMember', false);
      this.props.coupongroup.saveCouponInfo('limitedUsePerMember', 1);
      this.setState({ limitedUsePerMemberError: false });
    }

    this.props.coupongroup.saveCouponInfo(key, value);
  }

  handleClickUpload = () => {
    this.inputRef.current.click();
  };

  handleFileChange = (event) => {
    const file = event.target.files[0];

    // allow xlsx, xls
    if (!file || !file.name.match(/\.(xlsx|xls)$/)) {
      swal.fire('อัพโหลดไฟล์ไม่สำเร็จ', 'กรุณาเลือกไฟล์ xlsx หรือ xls', 'error');
      this.inputRef.current.value = '';
      return;
    }

    // convert file xlsx to json
    const reader = new FileReader();
    reader.onload = async (e) => {
      const binaryString = e.target.result;
      const workbook = XLSX.read(binaryString, { type: 'binary' });
      const json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
      const validate = await this.validateFileUpload(json);
      if (validate) {
        this.setState({ fileUpload: { file, json } });
        const codes = json.map(item => item.code);
        this.onChangeNewCouponCodes(codes)
      } else {
        this.inputRef.current.value = '';
      }
    };

    // Read the file as buffer
    reader.readAsArrayBuffer(file);
  };

  handleDeleteFile = () => {
    this.setState({ fileUpload: null });
    this.props.coupongroup.clearNewCouponInfo();
    this.inputRef.current.value = '';
  };

  handleChangeState = (key, value) => {
    this.setState({ [key]: value });
  };

  async uploadFile() {
    const { fileUpload } = this.state;
    if (fileUpload && fileUpload.file) {
      try {
        const result = await this.props.coupongroup.uploadFile(fileUpload.file);
        if (result) {
          this.props.coupongroup.saveInfo('couponFile', {
            path: result.path,
            name: result.name,
          });
        }
      } catch (error) {
        swal.fire('อัพโหลดไฟล์ไม่สำเร็จ', `${error.message}`, 'error');
      }
    }
  }

  renderFileUpload() {
    const { fileUpload } = this.state;
    const { info } = this.props.coupongroup.toJS();

    if (info.couponFile.path) {
      return (
        <div className="row">
          <div className="col-md-6" style={{ padding: '0px' }}>
            <FormGroup containerClassName="col-md-12" label="">
              <a href={info.couponFile.path} download={info.couponFile.name}>
                {info.couponFile.name}
              </a>
            </FormGroup>
          </div>
        </div>
      )
    }

    if (fileUpload) {
      return (
        <div className="row">
          <div className="col-md-6" style={{ padding: '0px' }}>
            <FormGroup containerClassName="col-md-12" label="">
              <a href={URL.createObjectURL(fileUpload.file)} download={fileUpload.file.name}>
                {fileUpload.file.name}
              </a>
              <i
                style={{
                  paddingLeft: '5px',
                  cursor: 'pointer',
                }}
                className="fa fa-trash btn-icon"
                aria-hidden="true"
                onClick={this.handleDeleteFile}
              />
            </FormGroup>
          </div>
        </div>
      )
    }

    return null;
  }

  render() {
    const { isEdit, displayChannelGroupErrorMessage, originalChannelGroup, fileUpload } = this.state;
    const { items } = this.state.coupons;

    const { isLoading, updateFields, info } = this.props.coupongroup.toJS();
    const { couponInfo, newCouponInfo } = info;

    const { couponType } = this.props.coupon.toJS();

    const title = this.state.isEdit ? 'แก้ไขกลุ่มส่วนลด' : 'เพิ่มกลุ่มส่วนลดใหม่';

    const type = couponType.map((item, index) => {
      return (<option key={`coupon-${index}`} value={item.value}>{item.label}</option>);
    });

    const isCodesFromFile = !!fileUpload || !!info.couponFile.path

    const disableInputCoupon = !!this.state.isEdit
      ? !!info.couponFile.path
      : !!fileUpload

    const disableButtonUpload = !!this.state.isEdit
      ? (!!items.length || !!info.couponFile.path || !!newCouponInfo.codes.length)
      : !!newCouponInfo.codes.length

    const disabledBtnFilterProducts = this.disableBtnFilterProducts()

    return (
      <Container isAdmin className="coupon-container">
        <div className="row">
          <Loader show={isLoading} />
          <Notification
            ref={(ref) => {
              this.noti = ref;
            }}
          />
          <div className="col-md-12">
            <div className="card coupon-group">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{title}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <div className="card-content">
                  <FormValidation>
                    <EnTab
                      index={this.state.activeIndex}
                      onChangeTab={this.handleChangeState}
                    >
                      {/* Section Tab One */}
                      <Tab title="รายละเอียดส่วนลด">
                        <div className="row">
                          {
                            this.state.isEdit ? (
                              <>
                                <FormGroup label="ชื่อกลุ่มส่วนลด *" containerClassName="col-md-12">
                                  <EnTextFormGroup
                                    type="text"
                                    value={info.name || ''}
                                    onChange={this.onTextChange.bind(this, 'name')}
                                    validations={[required]}
                                    disabled={!this.state.isCampaignManager}
                                  />
                                </FormGroup>
                              </>
                            ) : (
                              <>
                                <FormGroup label="ชื่อกลุ่มส่วนลด *" containerClassName="col-md-6">
                                  <EnTextFormGroup
                                    type="text"
                                    value={info.name || ''}
                                    onChange={this.onTextChange.bind(this, 'name')}
                                    validations={[required]}
                                    disabled={!this.state.isCampaignManager}
                                  />
                                </FormGroup>

                                <FormGroup label="ประเภทส่วนลด" containerClassName="col-md-6">
                                  <EnDropDown
                                    value={couponInfo.type}
                                    disabled={!updateFields.type || !this.state.isCampaignManager}
                                    onChange={this.onTypeChange.bind(this)}
                                  >
                                    {type}
                                  </EnDropDown>
                                </FormGroup>
                              </>
                            )
                          }
                        </div>
                        {/* QBC-297 */}
                        {
                          this.state.isEdit && (
                            <div className="row">
                              <FormGroup label="เลือกจากรหัสส่วนลดที่มีอยู่" containerClassName="col-md-6">
                                <EnTagSelect
                                  disabled={disableInputCoupon || !this.state.isCampaignManager}
                                  className="coupons-select"
                                  closeOnSelect={false}
                                  options={this.getCouponOptions(isCodesFromFile)}
                                  placeholder={(items.length > 0 || (items.length === 0 && isLoading === false)) ? undefined : 'Loading..'}
                                  onTagChange={this.onChangeCoupon.bind(this)}
                                  value={info.coupons}
                                  multi
                                  searchable
                                />
                              </FormGroup>
                              <FormGroup label="ประเภทส่วนลด" containerClassName="col-md-6">
                                <EnDropDown
                                  style={{ marginTop: '5px' }}
                                  value={couponInfo.type}
                                  disabled={!updateFields.type || !this.state.isCampaignManager}
                                  onChange={this.onTypeChange.bind(this)} >
                                  {type}
                                </EnDropDown>
                              </FormGroup>
                            </div>
                          )
                        }
                        {/* END QBC-297 */}

                        <div className="row">
                          <FormGroup
                            containerClassName={`col-md-6 new-codes ${couponInfo.type === 'I' ? 'installation' : 'survey'}-tags`}
                            label="สร้างรหัสส่วนลดใหม่"
                          >
                            <TagsInput
                              className={disableInputCoupon || !this.state.isCampaignManager ? 'react-tagsinput react-tagsinput-disabled' : undefined}
                              disabled={disableInputCoupon || !this.state.isCampaignManager}
                              value={!isCodesFromFile && newCouponInfo.codes || []}
                              inputProps={{
                                placeholder: 'Enter...',
                              }}
                              onChange={this.onChangeNewCouponCodes.bind(this)}
                              addOnBlur={this.onChangeNewCouponCodes.bind(this)}
                            />
                            <div className="remark-coupon">
                              <ul>
                                <li className="installation-rc"><i className="fa fa-circle" aria-hidden="true" /> ส่วนลดค่าติดตั้ง</li>
                                <li className="survey-rc"><i className="fa fa-circle" aria-hidden="true" /> ส่วนลดค่าสำรวจ</li>
                              </ul>
                            </div>
                          </FormGroup>

                          <FormGroup label="วันที่" containerClassName="col-md-6" validations={[required]}>
                            <EnDateRangePicker
                              withPortal
                              numberOfMonths={1}
                              disabled={!updateFields.date || !this.state.isCampaignManager}
                              ref={(e) => { this.instDateRange = e; }}
                              openDirection="up"
                              minimumNights={0}
                              startDate={couponInfo.startDate ? moment(couponInfo.startDate) : null}
                              endDate={couponInfo.endDate ? moment(couponInfo.endDate) : null}
                              // isDayBlocked={this.checkInstDayBlock.bind(this)}
                              onDateChange={this.onDateChange.bind(this)}
                              startDatePlaceholderText="วันที่เริ่มต้น"
                              endDatePlaceholderText="วันที่สิ้นสุด"
                              isOutsideRange={(day) => moment().startOf('day').isAfter(day)}
                            />
                          </FormGroup>
                        </div>

                        {/* Section Upload File */}
                        {/* render file name */}
                        {this.renderFileUpload()}
                        <div className="row">
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-12" label="" >
                              <input
                                type="file"
                                ref={this.inputRef}
                                accept=".xls,.xlsx"
                                style={{ display: 'none' }}
                                onChange={this.handleFileChange}
                                disabled={!this.state.isCampaignManager}
                              />

                              <EnButton
                                className="btn btn-info"
                                onClick={this.handleClickUpload}
                                disabled={disableButtonUpload || !this.state.isCampaignManager}
                              >
                                Upload File
                              </EnButton>
                            </FormGroup>
                          </div>
                        </div>
                        {/* Section Upload File */}

                        {/* Section Product Type */}
                        <hr />
                        <div className="row">
                          <div className="col-md-12" style={{ padding: '0px' }}>
                            <FormGroup label="ประเภทสินค้า" containerClassName="col-md-3" containerStyle={{ paddingRight: '5px' }}>
                              <Select
                                className="select-custom-height"
                                clearable={false}
                                disabled={(!!isEdit && !!originalChannelGroup) || !this.state.isCampaignManager}
                                options={channelGroupOptions}
                                searchable={true}
                                value={newCouponInfo.productChannel}
                                onChange={this.handleSelectChannelGroup}
                              />
                            </FormGroup>
                            <FormGroup label="ช่องทางการขาย" containerClassName="col-md-3" containerStyle={{ paddingRight: '5px' }}>
                              <Select
                                className="p4-select pt-0"
                                disabled={!this.state.isCampaignManager}
                                searchable={true}
                                clearable={false}
                                multi
                                onChange={this.onSelectChannel}
                                options={this.getChannelOptions()}
                                style={displayChannelGroupErrorMessage ? { backgroundColor: 'rgba(236,88,64,.1)', borderColor: '#e53935' } : undefined}
                                value={newCouponInfo.channel.join()}
                                onFocus={this.handleFocusChannel}
                              />
                              <div hidden={!displayChannelGroupErrorMessage} style={{ color: '#e53935' }}>
                                กรุณาเลือกประเภทสินค้า
                              </div>
                            </FormGroup>
                          </div>

                          <div className="col-md-12" style={{ padding: '0px' }}>
                            <FormGroup label="Model การขาย" containerClassName="col-md-3" containerStyle={{ paddingRight: '5px' }}>
                              <Select
                                className="select-custom-height"
                                disabled={!this.state.isCampaignManager}
                                searchable={false} clearable={false}
                                onChange={this.onSelectModel}
                                options={this.getModelOptions()}
                                value={newCouponInfo.model}
                              />
                            </FormGroup>
                          </div>

                        </div>
                        {/* Section Product Type  */}

                        {/* Section Private Code */}
                        <div className="row">
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-12" label="โค้ดลับ" >
                              <span> (โค้ดจะไม่ถูกแสดงในหน้า PDP) </span>
                              <EnToggle
                                disabled={!this.state.isCampaignManager}
                                checked={couponInfo.isPrivateCode}
                                onChange={this.handleChangInfo.bind(this, 'isPrivateCode')}
                                noPadding={true}
                              />
                            </FormGroup>
                          </div>
                        </div>
                        {/* Section Private Code */}

                        <div className="row">
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-12" label="เลือกสาขา" >
                              <EnToggle
                                disabled={!this.state.isCampaignManager}
                                checked={this.state.isSelectBranch}
                                onChange={this.onSelectBranch.bind(this)}
                                noPadding={true}
                              />
                            </FormGroup>
                          </div>
                        </div>

                        {
                          this.state.isSelectBranch &&
                          <div className="row">
                            <div className="col-md-12">
                              {this.showBranchItem()}
                              {!(this.state.branchItems[0]
                                && this.state.branchItems[0].selected_id[0]
                                && this.state.branchItems[0].selected_id.length)
                                && <span className="input-error">กรุณาเลือกสาขาอย่างน้อย 1 สาขา</span>
                              }
                            </div>
                          </div>
                        }

                        <div className="row">
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-12" label="สถานะการใช้งาน">
                              <EnToggle
                                checked={couponInfo.status === 'active'}
                                onChange={this.onToggle.bind(this)}
                                disabled={(!isAllow('EDIT_COUPON_GROUP_STATUS') && this.state.isEdit) || !this.state.isCampaignManager}
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-4" label="จำกัดจำนวนการใช้">
                              <EnToggle
                                checked={couponInfo.isLimit}
                                onChange={this.onLimitCouponToggle.bind(this)}
                                disabled={(!isAllow('EDIT_COUPON_GROUP_NUMBER_OF_TIMES_LIMIT') && this.state.isEdit) || !this.state.isCampaignManager}
                              />
                            </FormGroup>
                            <EnTextFormGroup
                              label="จำนวนการใช้ (ครั้ง)"
                              containerClassName="col-md-8"
                              type="text"
                              value={couponInfo.useLimit}
                              onChange={this.onTextCouponInfoChange.bind(this, 'useLimit')}
                              disabled={!couponInfo.isLimit || !this.state.isCampaignManager}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-12" label="การแจ้งเตือน (SMS/Email)">
                              <EnToggle
                                checked={couponInfo.requestNotification}
                                onChange={this.onRequestNotiToggle.bind(this)}
                                disabled={(!isAllow('EDIT_COUPON_GROUP_STATUS_OF_NOTIFICATION') && this.state.isEdit) || !this.state.isCampaignManager}
                              />
                            </FormGroup>
                          </div>

                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-4" label="ใช้สำหรับสมาชิกเท่านั้น" >
                              <EnToggle
                                disabled={!this.state.isCampaignManager}
                                checked={couponInfo.isMember}
                                onChange={this.handleChangInfo.bind(this, 'isMember')}
                                noPadding={true}
                              />
                            </FormGroup>
                            <EnTextFormGroup
                              disabled={!couponInfo.isMember || !couponInfo.isLimitedUseMember || !this.state.isCampaignManager}
                              label="จำนวนการใช้ต่อคน"
                              containerClassName="col-md-8"
                              type="number"
                              value={couponInfo.limitedUsePerMember}
                              onChange={this.handleChangInfo.bind(this, 'limitedUsePerMember')}
                              isError={this.state.limitedUsePerMemberError}
                              errorMessage={'จำนวนการใช้ต่อสมาชิก มากกว่า จำนวนคูปองทั้งหมดที่ใช้ได้'}
                            />
                            <FormGroup label="" containerClassName="col-md-4"></FormGroup>
                            <FormGroup label="" containerClassName="col-md-6">
                              <EnCheckBox
                                style={{ margin: '0', padding: '0' }}
                                disabled={!couponInfo.isMember || !this.state.isCampaignManager}
                                checked={couponInfo.isLimitedUseMember}
                                onChange={(event) => {
                                  this.handleChangInfo('isLimitedUseMember', event.target.checked)
                                }}
                                text="จำกัดสิทธิการใช้งาน"
                              />
                            </FormGroup>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6" style={{ padding: '0px' }}>
                            <FormGroup containerClassName="col-md-12" label="Marketplace เงินสด">
                              <EnToggle
                                disabled={
                                  !couponInfo.requestNotification ||
                                  !this.state.isCampaignManager ||
                                  (!isAllow('EDIT_COUPON_GROUP_STATUS_OF_PAYMENT_TERM') && this.state.isEdit)
                                }
                                checked={couponInfo.requestPayment}
                                onChange={this.onRequestPaymentToggle.bind(this)} />
                            </FormGroup>
                          </div>
                        </div>

                        {/* Section ?? */}
                        {couponInfo.model !== "Retail" && (
                          <div className="row">
                            <div className="col-md-6" style={{ padding: '0px' }}>
                              <FormGroup containerClassName="col-md-4" label="Marketplace เงินเชื่อ">
                                <EnToggle
                                  checked={couponInfo.requestStorePayment}
                                  onChange={this.onRequestStorePaymentToggle.bind(this)}
                                  disabled={(!isAllow('EDIT_COUPON_GROUP_STATUS_OF_PAYMENT_TERM') && this.state.isEdit) || !this.state.isCampaignManager}
                                />
                              </FormGroup>
                              {
                                couponInfo.requestStorePayment &&
                                <FormGroup containerClassName="col-md-8" label="อีเมลที่จะใช้เรียกเก็บเงิน">
                                  <TagsInput
                                    disabled={!this.state.isCampaignManager}
                                    width="150px"
                                    value={couponInfo.storePaymentNotiEmails || []}
                                    inputProps={{
                                      placeholder: 'Enter Emails...',
                                    }}
                                    onChange={this.onChangeEmails.bind(this)}
                                    addOnBlur={this.onChangeEmails.bind(this)}
                                  />
                                </FormGroup>
                              }
                            </div>

                          </div>
                        )}
                        {/* Section ?? */}

                        <hr />

                        <div className="row">
                          <div className="col-md-12">{this.showProvinceItem()}</div>
                        </div>

                        <hr />

                        <div className="row">
                          <div className="col-md-12">
                            <EnButton className="btn btn-success pull-left"
                              onClick={this.filterProductsDup.bind(this)}
                              disabled={disabledBtnFilterProducts || !this.state.isCampaignManager}
                            >
                              <i className="fa fa-filter btn-icon" aria-hidden="true" />กรองสินค้า
                            </EnButton>
                            <EnButton
                              className="btn btn-success pull-right"
                              onClick={this.onAddItems.bind(this)}
                              disabled={!isAllow('CREATE_COUPON_GROUP_PERIOD') || !this.state.isCampaignManager}>
                              <i className="fa fa-plus btn-icon" aria-hidden="true" />
                              เพิ่ม
                            </EnButton>
                            {/* QBC-297 */}
                            {(newCouponInfo.codes.length > 0 || this.state.isEdit) && this.showItem()}
                            {/* END QBC-297 */}
                          </div>
                        </div>
                        <div className="row coupon-button-box">
                          {
                            (this.state.isEdit) && (
                              <EnButton
                                id="open-copy-promotion-button"
                                className="btn-success pull-left"
                                onClick={this.onCopyGroupPromotion.bind(this)}
                                disabled={!this.state.isCampaignManager}
                              >
                                <i className="fa fa-copy btn-icon" aria-hidden="true" />คัดลอกกลุ่มส่วนลดนี้
                              </EnButton>
                            )}
                          <EnButton
                            className="btn-info pull-right"
                            disabled={!this.isValid() && this.state.limitedUsePerMemberError}
                            onClick={() => this.handleChangeState('activeIndex', 1)}
                          >
                            <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
                            ถัดไป
                          </EnButton>
                          <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                            <i className="fa fa-times btn-icon" aria-hidden="true" />
                            ยกเลิก
                          </EnButton>
                        </div>
                      </Tab>

                      {/* Section Tab Two */}
                      <Tab title="Banner">

                        {/* Ad Banner */}
                        <div className="row">
                          <div className="col-md-5">
                            <EnButton
                              className="btn-success"
                              onClick={this.openDropzone.bind(this, 'adBanner')}
                            >
                              <i className="fa fa-plus btn-icon" aria-hidden="true" />อัพโหลดรูป Ad Banner
                            </EnButton>
                          </div>
                          <div className="col-md-6">
                            {this.renderBannerImage(info.adBanner, 'adBanner', 'ไม่มีรูป Ad Banner')}
                          </div>
                          <EnDropzoneDialog
                            dialogTitle="กรุณาอัพโหลดรูปขนาด 960x190"
                            open={this.state.dropzone.adBanner}
                            onSave={(files) => this.onSaveBannerDropzone(files, 'adBanner')}
                            onClose={() => this.closeBannerDropzone('adBanner')}
                          />
                        </div>

                        {/* Hero Banner */}
                        <div className="row">
                          <div className="col-md-5">
                            <EnButton
                              className="btn-success"
                              onClick={this.openDropzone.bind(this, 'heroBanner')}
                            >
                              <i className="fa fa-plus btn-icon" aria-hidden="true" />อัพโหลดรูปสำหรับการแสดงผลบน Hero banner
                            </EnButton>
                          </div>
                          <div className="col-md-6">
                            {this.renderBannerImage(info.heroBanner, 'heroBanner', 'ไม่มีรูป Hero Banner')}
                          </div>
                          <EnDropzoneDialog
                            dialogTitle="กรุณาอัพโหลดรูปขนาด 1920 x 650"
                            open={this.state.dropzone.heroBanner}
                            onSave={(files) => this.onSaveBannerDropzone(files, 'heroBanner')}
                            onClose={() => this.closeBannerDropzone('heroBanner')}
                          />
                        </div>

                        {/* Promotion Card Promotion Banner */}
                        <div className="row">
                          <div className="col-md-5">
                            <EnButton
                              className="btn-success"
                              onClick={this.openDropzone.bind(this, 'heroBannerSquare')}
                            >
                              <i className="fa fa-plus btn-icon" aria-hidden="true" />อัพโหลดรูปสำหรับการแสดงผลบน Card promotion
                            </EnButton>
                          </div>
                          <div className="col-md-6">
                            {this.renderBannerImage(info.heroBannerSquare, 'heroBannerSquare', 'ไม่มีรูป Card Promotion Banner')}
                          </div>
                          <EnDropzoneDialog
                            dialogTitle="กรุณาอัพโหลดรูปขนาด 900 x 900"
                            open={this.state.dropzone.heroBannerSquare}
                            onSave={(files) => this.onSaveBannerDropzone(files, 'heroBannerSquare')}
                            onClose={() => this.closeBannerDropzone('heroBannerSquare')}
                          />
                        </div>

                        {/* Promotion Bottom Banner */}
                        <div className="row">
                          <div className="col-md-5">
                            <EnButton
                              className="btn-success"
                              onClick={this.openDropzone.bind(this, 'promotionBottomBanner')}
                            >
                              <i className="fa fa-plus btn-icon" aria-hidden="true" />อัพโหลดรูปสำหรับการแสดงผลบน Promotion Bottom
                            </EnButton>
                          </div>
                          <div className="col-md-6">
                            {this.renderBannerImage(info.promotionBottomBanner, 'promotionBottomBanner', 'ไม่มีรูป Promotion Bottom Banner')}
                          </div>
                          <EnDropzoneDialog
                            dialogTitle="กรุณาอัพโหลดรูปขนาด 900 x 900"
                            open={this.state.dropzone.promotionBottomBanner}
                            onSave={(files) => this.onSaveBannerDropzone(files, 'promotionBottomBanner')}
                            onClose={() => this.closeBannerDropzone('promotionBottomBanner')}
                          />
                        </div>

                        {/* Promotion Thumbnail */}
                        <div className="row">
                          <div className="col-md-5">
                            <EnButton
                              className="btn-success"
                              onClick={this.openDropzone.bind(this, 'promotionThumbnail')}
                            >
                              <i className="fa fa-plus btn-icon" aria-hidden="true" />อัพโหลดรูปสำหรับการแสดงผลบน Promotion Thumbnail
                            </EnButton>
                          </div>
                          <div className="col-md-6">
                            {this.renderBannerImage(info.promotionThumbnail, 'promotionThumbnail', 'ไม่มีรูป Promotion Thumbnail')}
                          </div>
                          <EnDropzoneDialog
                            dialogTitle="กรุณาอัพโหลดรูปขนาด 900 x 900"
                            maxFileSize={1000000}
                            open={this.state.dropzone.promotionThumbnail}
                            onSave={(files) => this.onSaveBannerDropzone(files, 'promotionThumbnail')}
                            onClose={() => this.closeBannerDropzone('promotionThumbnail')}
                          />
                        </div>

                        <hr />
                        {this.renderInformation(info.information)}
                        <div className="row">
                          <EnButton className="btn-info pull-right"
                            disabled={!this.isValid() || !isAllow('EDIT_COUPON_GROUP_BANNER')}
                            onClick={this.onSubmit.bind(this)}
                          >
                            <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                          </EnButton>

                          <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                            <i className="fa fa-times btn-icon" aria-hidden="true" />
                            ยกเลิก
                          </EnButton>

                        </div>
                      </Tab>

                    </EnTab>
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('coupon', 'coupongroup', 'product', 'misc', 'channel', 'model')(observer(CouponGroupPage));
