import { http } from '../../utils/http';
import { config } from '../../config';
const JQL = require('./jql.js');

const preprocess = (data) => {
  if (!data.length) {
    return data;
  }
  // compacted database in hierarchical form of:
  // [["province",[["amphur",[["district",["zip"...]]...]]...]]...]
  const expanded = [];
  data.forEach((provinceEntry) => {
    const provinceCode = provinceEntry[0];
    const provinceName = provinceEntry[1];
    const amphurList = provinceEntry[2];
    if (provinceName.includes('*')) return;
    amphurList.forEach((amphurEntry) => {
      const amphurCode = amphurEntry[0];
      const amphurName = amphurEntry[1];
      const districtList = amphurEntry[2];
      if (amphurName.includes('*')) return;
      districtList.forEach((districtEntry) => {
        const districtCode = districtEntry[0];
        const districtName = districtEntry[1];
        const zipCodeList = districtEntry[2];
        if (districtName.includes('*')) return;
        zipCodeList.forEach((zipCode) => {
          expanded.push({
            dcode: districtCode,
            acode: amphurCode,
            pcode: provinceCode,
            zcode: zipCode[0],
            dname: districtName,
            aname: amphurName,
            pname: provinceName,
            zname: zipCode[0],
          });
        });
      });
    });
  });
  return expanded;
};

async function getAddress() {
  let response = await http.get(`${config.api.sims}/v1/address`);
  return response ? response.data : '';
}

export const resolveResultbyField = (type, searchStr) => {
  let possibles = [];
  try {
    possibles = DB.select('*').where(type)
      .match(`^${searchStr}`)
      .orderBy(type)
      .fetch();
  } catch (e) {
    return [];
  }
  return possibles;
};

export const fieldsEnum = {
  DISTRICT: 'd',
  AMPHOE: 'a',
  PROVINCE: 'p',
  ZIPCODE: 'z',
};

var DB;
getAddress().then(function (data) {
  if (data) {
    DB = new JQL(preprocess(data));
  }
});
