import React, { Component } from 'react';
import DropZone from 'react-dropzone';

import '../../assets/css/image-picker.css';

export default class ImagePicker extends Component {
  render() {
    let remove;
    let content = (
      <button type="button" className="btn btn-block btn-default">
        <i className="fa fa-plus" /> เพิ่มรูป
      </button>
    );

    if (this.props.src) {
      content = (
        <img alt="" src={this.props.src} />
      );
      remove = 
      (this.props.onDelete ?
        <div
          className="image-picker-delete"
          onClick={this.props.onDelete}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        :<div></div>
      );
    }

    return (
      <div className="image-picker">
        {remove}
        <DropZone
          style={{ width: '100%', border: '0px', cursor: 'pointer' }}
          accept={this.props.accept}
          multiple={false}
          onDrop={this.props.onDrop}
          inputProps={{
            id: this.props.id,
          }}
        >
          {content}
        </DropZone>
      </div>
    );
  }
}
