import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';

export class ExportStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
    });
  }

  showDownload(linkUrl) {
    let anchor = document.createElement('a');
    anchor.style = 'display: none';
    anchor.href = linkUrl;

    document.body.appendChild(anchor);
    anchor.click();

    // clean up
    setTimeout(function () {
      window.URL.revokeObjectURL(linkUrl);
      document.body.removeChild(anchor);
    }, 1000);
  }

  async doExport(apiUrl) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let response = await http.get(apiUrl);
      if (response.status === 200 && response.data && response.data.data) {
        this.showDownload(response.data.data.url);
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async exportContractors() {
    await this.doExport(`${config.api.sims}/v1/downloads/contractors`);
  }

  async exportJobs(storeId, workTypeId, channel) {
    let url = `${config.api.sims}/v1/downloads/jobs`;
    let checkParam = () => {
      if (url.indexOf('?') >= 0) {
        return `&`;
      } else {
        return `?`;
      }
    };
    if (storeId) {
      url += `${checkParam()}store_id=${storeId}`;
    }
    if (workTypeId) {
      url += `${checkParam()}work_type_id=${workTypeId}`;
    }
    if (channel) {
      url += `${checkParam()}channel=${channel}`;
    }
    await this.doExport(url);
  }

  async exportContractorVacations() {
    await this.doExport(`${config.api.sims}/v1/downloads/contractors/vacations`);
  }

  async exportOrders() {
    await this.doExport(`${config.api.sims}/qchang/v1/downloads/orders`);
  }

  async exportTransfers() {
    await this.doExport(`${config.api.sims}/v1/transfer/generateTransfersDetail`);
  }
}

export default new ExportStore();
