import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import _ from 'lodash'
import { isAllow } from '../../../utils/permission';

export class ManageTrainingRegister extends React.Component {
  async componentWillMount() {
    const id = this.props.match.params.id;
    if (isAllow('VIEW_LIST_OF_ATTENDEE_INFORMATION')) {
      await this.props.trainingcourse.getTrainingRegister(id);
    }
  }

  getTableRows(register) {
    // let register = [
    //   {firstName:'abc',lastName:'abc',phoneNumber:'0954827941'},
    //   {firstName:'abc',lastName:'abc',phoneNumber:'0954827941'},
    //   {firstName:'abc',lastName:'abc',phoneNumber:'0954827941'},
    //   {firstName:'abc',lastName:'abc',phoneNumber:'0954827941'},
    // ]
    if (register.length === 0) {
      return (
        <tr>
          <td colSpan={3} align="center">ไม่พบข้อมูล</td>
        </tr>
      );
    }
    let no = 0
    return register.map((item, index) => {
      if (_.isEmpty(item)) {
        return
      }
      no += 1
      return (
        <tr key={index}>
          {/* <td width="5%" className="text-center">{no || ''}</td> */}
          <td width="5%" className="text-center">
            {no}
          </td>
          <td width="15%">
          {item.prefixName || ''} {item.firstName || ''} {item.lastName || ''}
          </td>
          <td width="10%">{item.phone}</td>
        </tr>
      );
    });
  }
  goback() {
    this.props.history.goBack()
  }

  render() {
    const { register } = this.props.trainingcourse.toJS();
    const rows = this.getTableRows(register);

    return (
      <Container isAdmin>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายชื่อผู้เข้าอบรม</h4>
              </div>
              <div className="card-content">
                {/* <div className="row" style={{marginTop: 20, marginBottom: 25}}>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-2"><b>ชื่อคอร์ส:</b></div>
                      <div className="col-md-4">อบรมการใช้งาน App</div>
                    </div>
                    <div className="row">
                      <div className="col-md-2"><b>วัน / เวลา:</b></div>
                      <div className="col-md-4">30 สิงหาคม 2020, 09:00 - 12:00</div>
                    </div>
                    <div className="row">
                      <div className="col-md-2"><b>สถานที่:</b></div>
                      <div className="col-md-4">คิวช่าง (สำนักงานใหญ่)</div>
                    </div>
                    <div className="row">
                      <div className="col-md-2"><b>จำนวนที่นั่งเปิดรับ:</b></div>
                      <div className="col-md-4">30</div>
                    </div>
                  </div>
                </div> */}
                <div className="row text-left">
                  
                    <button className="btn btn-danger" title="สร้างใหม่" data-tip="สร้างใหม่"
                      onClick={e => { this.goback() }}
                      style={{marginLeft:15}}
                    >
                      <span className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                </button>
                </div>
                <div className="row" style={{ marginTop: 20, marginBottom: 25 }}>
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="5%" className="text-center">
                              ลำดับที่
                            </th>
                            <th width="15%" className="text-center">
                              ชื่อ - นามสกุล
                            </th>
                            <th width="10%" className="text-center">
                              เบอร์โทร
                            </th>
                          </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('trainingcourse')(observer(ManageTrainingRegister));
