import React, { Component } from 'react';
import logo from '../../assets/logo/JOB_FW_LOGO_QCHANG_NEW.gif';

export class Loader extends Component {
  render() {
    return (this.props.show ? (
      // <div className="loader-wrapper" >
      //   <div className="loader">
      //     <div className="loader_overlay" />
      //     <div className="loader_cogs">
      //       <div className="loader_cogs__top">
      //         <div className="top_part" />
      //         <div className="top_part" />
      //         <div className="top_part" />
      //         <div className="top_hole" />
      //       </div>
      //       <div className="loader_cogs__left">
      //         <div className="left_part" />
      //         <div className="left_part" />
      //         <div className="left_part" />
      //         <div className="left_hole" />
      //       </div>
      //       <div className="loader_cogs__bottom">
      //         <div className="bottom_part" />
      //         <div className="bottom_part" />
      //         <div className="bottom_part" />
      //         <div className="bottom_hole" />
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div className="loader-wrapper" >
        <div className="loader-img">
          <img src={logo} alt="" />
        </div>
      </div>
    ) : null);
  }
}

export default Loader;
