import React from 'react';
import * as _ from 'lodash';
import swal from 'sweetalert2';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import Container from '../../layouts/Container';
import HeaderSeachImg from '../../assets/img/headerSeach.png'
import '../../assets/css/tabbuaccount.css'
import { MultiSelect } from 'react-multi-select-component';
import Pagination from '../../components/common/Pagination';
import momentTz from 'moment-timezone';
import config from '../../config';
import iconSurvey from '../../assets/img/iconNewสำรวจ.svg'
import iconInstall from '../../assets/img/iconNewติดตั้ง.svg'
import iconCartOrder from '../../assets/img/icon-cart-order.png'
import EnToggle from '../../components/form/EnToggle';
import Axios from 'axios'
import Loader from '../../components/common/Loader'
import AuthStore from '../../stores/AuthStore'

export class JobsTracking extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {};
    this.state = {
      data: [],
      tab: 'customer-tab',
      sotresSelected: [],
      productsSelected: [],
      typeOfJobsSelected: [],
      isActive: true,
      optionStores: [],
      optionProducts: [],
      optionTypeOfJob: [
        { label: "สำรวจ", value: "S" },
        { label: "ติดตั้ง", value: "I" },
      ],
      pagination: {
        "page": "1",
        "page_range": "5",
        "page_size": "10",
      },
      query: {},
      searchText: '',
      isLoading: false
    };
  }
  async componentDidMount() {
    this.setDefaultQuery()
    this.getOptionData()
    this.searchJobByFilter()
  }

  mapDataOptionProduct(listProduct = []) {
    return _.map(listProduct, p => {
      return {
        label: p.name && p.name.th || '',
        value: p._id,
      }
    })
  }
  mapDataOptionStore(listStore = []) {
    return _.map(listStore, s => {
      return {
        label: s.name,
        value: s._id,
      }
    })
  }
  setLoading(VALUE) {
    this.setState({
      isLoading: VALUE || false
    })
  }
  async getOptionData() {
    try {
      const channel = this.props.auth.getChannelName() || '';
      const storeId = this.props.auth.getStoreId() || '';
      const urlStroes = `${config.api.sims}/v1/bizaccount/stores/searchByChannel`;
      const urlProducts = `${config.api.sims}/v1/bizaccount/products/searchByChannel`;
      const resStore = await Axios.post(urlStroes, { channel })
      const resProduct = await Axios.post(urlProducts, { channel, storeId })
      const newOptionStores = this.mapDataOptionStore(resStore.data.data)
      const newOptionProducts = this.mapDataOptionProduct(resProduct.data.data)
      this.setState({
        optionStores: newOptionStores,
        optionProducts: newOptionProducts,
      })

    } catch (error) {
      console.error('error getOptionData', error);
      console.error('error', error);
    }
  }
  setDefaultQuery(channel = '') {
    this.query = {
      page_size: 10,
      page: 1,
      page_range: 5,
      sort: 'submited_at|desc',
      store_id: [],
      product_id: [],
      is_active: true,
      type_of_job: '',
      channel: channel
    };
  }
  setTab(tabname) {
    this.setState({
      tab: tabname
    })
  }

  setSelected(e, key) {
    if (e) {
      this.setState({
        [key]: e,
      });
    }
  }
  getTypeOfJob(typeOfJob) {
    switch (typeOfJob) {
      case 'S':
        return iconSurvey;
      case 'I':
        return iconInstall;
      default:
        return iconCartOrder;
    }
  }
  async onPageClick(pageNum) {
    this.props.joblist.setQueryValue('page', pageNum);
    await this.props.joblist.searchJobs();
  }
  setActive(value = true) {
    this.setState({ isActive: value })
  }

  getStatus(status) {
    const colorSuccess = '#3E9342'
    const colorNormal = '#0F7272'
    const colorDanger = '#950000'
    const colorDel = '#950000'
    switch (status) {
      case 'created': return <p className="" style={{ color: colorNormal }}>สร้างใหม่</p>;
      case 'assigned': return <p className="" style={{ color: colorNormal }}>ได้รับมอบหมาย</p>;
      case 'survey_started': return <p className="" style={{ color: colorNormal }}>เริ่มสำรวจ</p>;
      case 'survey_finished': return <p className="" style={{ color: colorNormal }}>สิ้นสุดการสำรวจ</p>;
      case 'quotation_management': return <p className="" style={{ color: colorNormal }}>ใบเสนอราคา</p>;
      case 'installation_requested': return <p className="" style={{ color: colorNormal }}>นัดติดตั้ง</p>;
      case 'installation_confirmed': return <p className="" style={{ color: colorNormal }}>ยืนยันการติดตั้ง</p>;
      case 'installation_started': return <p className="" style={{ color: colorNormal }}>เริ่มการติดตั้ง</p>;
      case 'installation_finished': return <p className="" style={{ color: colorNormal }}>สิ้นสุดการติดตั้ง</p>;
      case 'installation_accepted': return <p className="" style={{ color: colorSuccess }}>ลูกค้ายอมรับ</p>;
      case 'installation_rejected': return <p className="" style={{ color: colorDanger }}>ลูกค้าปฏิเสธ</p>;
      case 'paid': return <p className="" style={{ color: colorNormal }}>ชำระเงิน</p>;
      case 'deleted': return <p className="" style={{ color: colorDel }}>ย้ายงาน/Inactive</p>;
      case 'paid_to_contractor': return <p className="" style={{ color: colorSuccess }}>จ่ายเงินให้ช่างแล้ว</p>;
      case 'request_quotation': return <p className="" style={{ color: colorDanger }}>รอทำใบเสนอราคา</p>;
      case 'quotation': return <p className="" style={{ color: colorSuccess }}>สร้างใบเสนอราคาแล้ว</p>;
      case 'contractor_no_view': return <p className="" style={{ color: colorDanger }}>ช่างยังไม่ทราบ</p>;
      case 'no_paid': return <p className="" style={{ color: colorDanger }}>ลูกค้ายังไม่ได้ชำระเงิน</p>;
      default: return '';
    }
  }
  cutext(text = '', length) {
    // return text

    return text.slice(0, length) + '....'
  }

  displayTableRows(jobs) {
    let prefixUri;
    prefixUri = `${config.qchang.url}/tracking`;
    const b2bUrl = `${config.api.b2b}/tracking`;
    return jobs.map((j, index) => {
      const fullName = j.customer_firstname + " " + j.customer_lastname || " "
      let contractorName = j.contractor_name || ''
      let contractorPhone = j.contractor_phone || ''
      const storePaymentPaid = j.store_payment_info ? j.store_payment_info.is_paid : true
      if (j.contractor_detail) {
        contractorName = j.contractor_detail.team_name || ''
        contractorPhone = j.contractor_detail.phone || ''
      }
      // switch (statusSearch) {
      //   case 'request_quotation': j.status = 'request_quotation'; break;
      //   case 'paid_to_contractor': j.status = 'paid_to_contractor'; break;
      // }
      return (
        <tr key={index}>
          <td className="text-left">
            <i className="" aria-hidden="true" >
              <img alt="" src={this.getTypeOfJob(j.type_of_job)} style={{ paddingLeft: '4px', width: '29px' }} />
            </i>
          </td>
          { j.job_code ? 
            <td className="text-left">
              {
                j.is_active
                  ? (<a href={`${prefixUri}/${j.orderId}`} title={j.job_code} target="_blank">{j.job_code || j._id}</a>)
                  : <a>{j.job_code || j._id}</a>
              }
            </td> :
            <td className="text-left">
              <a className="text-danger" href={`${b2bUrl}/${j.cart_order_no}`} title={j.job_code} target="_blank">{j.cart_order_no || j._id}</a>
            </td> 
          }
          <td className="text-left">{fullName}</td>
          <td className="text-left">{j.customer_phone || ''}</td>
          <td className="text-left" title={j.description}>{this.cutext(j.description, 30) || ''}</td>


          <td className="text-left">{contractorName}</td>
          <td className="text-left">{contractorPhone}</td>
          <td className="text-left">
            {this.getStatus(j.status)}
            {storePaymentPaid === false && j.sale_model !== 'Retail' &&
              <p className="" style={{ color: "#950000" }}> ( หน้าร้านยังไม่ได้ชำระเงิน ) </p>
            }
          </td>

        </tr>
      );
    });
  }

  mapListToBody(listSelected = []) {
    return _.map(listSelected, l => {
      return l.value || ''
    })
  }
  filterOptions(options, filter) {
    if (!filter) {
      return options;
    }
    const re = new RegExp(filter, "i");
    let newOptions = options.filter(({ label }) => {
      return label && label.match(re)
    });
    return newOptions
  }
  async searchJobByCustomer() {
    this.setLoading(true)
    const channel = this.props.auth.getChannelName() || '';
    const { pagination, searchText } = this.state
    let body = {
      "channel": channel,
      "q": searchText,
      "page": "1",
      "page_range": "10",
      "page_size": "20"
    }

    if (pagination) {
      body = {
        ...body,
        "page": pagination.page || '1',
        "page_range": pagination.page_range || '10',
        "page_size": pagination.page_size || '20',
      }
    }
    const url = `${config.api.sims}/v1/jobs/search/status/customerInfo`;
    try {
      const res = await Axios.post(url, body)
      if (res.data) {
        this.setState({
          data: res.data.data || [],
          pagination: res.data.pagination || {},
        })
      }
    } catch (error) {
      console.error('error', error)
    }
    this.setLoading(false)

  }

  async searchJobByFilter(page) {
    this.setLoading(true)

    const channel = this.props.auth.getChannelName() || '';
    const { isActive, pagination, typeOfJobsSelected, sotresSelected, productsSelected } = this.state

    try {
      let body = {}
      body.is_active = isActive
      if (channel) {
        body.channel = channel
      }

      if(this.state.searchText) {
        body.code = this.state.searchText
      }

      if (typeOfJobsSelected) {
        body.type_of_job = this.mapListToBody(typeOfJobsSelected)
      }
      if (sotresSelected) {
        body.store_id = this.mapListToBody(sotresSelected)

      }
      if (productsSelected) {
        body.product_id = this.mapListToBody(productsSelected)
      }
      if (pagination) {
        body = {
          ...body,
          "page": pagination.page || page,
          "page_range": pagination.page_range,
          "page_size": pagination.page_size,
        }
      }
      const storeUser = AuthStore.getStore()
      body.bu_store_id = storeUser._id
      const url = `${config.api.sims}/v1/jobs/search/status/adminFilter`;
      const res = await Axios.post(url, body)
      if (res.data) {
        this.setState({
          data: res.data.data,
          pagination: res.data.pagination,
        })
      }
    } catch (error) {
      console.error('error', error)
    }
    this.setLoading(false)

  }
  async onPageClick(pageNum) {
    let newPagination = {
      ...this.state.pagination,
      page: pageNum
    }
    this.setState({ pagination: newPagination })

    await this.searchJobByFilter(pageNum);
  }
  onKeyPress(e) {
    // if (e.keyCode === 13) {
    //   this.searchJobByFilter();
    // }
  }

  render() {
    const overrideStrings = {
      "allItemsAreSelected": "เลือกทั้งหมดแล้ว",
      "search": "ค้นหา",
      "selectAll": "เลือกทั้งหมด",
      "selectSomeItems": "ทั้งหมด"
    }
    const { tab, pagination, optionTypeOfJob, optionProducts, optionStores, data = [], isLoading } = this.state
    const { sotresSelected = [], productsSelected = [], typeOfJobsSelected = [], } = this.state
    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{ marginBottom: '0px' }}>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-5 col-sm-12">
                    <img alt="" src={HeaderSeachImg} style={{ paddingLeft: '4px', width: '100%' }} />
                  </div>
                  <div className="col-sm-12 col-md-7">
                    {/* <img alt="" src={HeaderSeachImg} style={{paddingLeft:'4px',width:'100%'}} /> */}
                    <ul className="row">
                      <li className={`${tab === 'customer-tab' ? 'tab-bu ontab' : 'tab-bu'}`} onClick={e => { this.setTab('customer-tab') }}>
                        <h4 className={`${tab === 'customer-tab' ? 'in-clicked' : 'tab-bu-a'}`} href="#" id="h4-job-tracking">ค้นหาด้วยข้อมูลลูกค้า</h4>
                      </li>
                      <li className={`${tab === 'filter-tab' ? 'tab-bu ontab' : 'tab-bu'}`} onClick={e => { this.setTab('filter-tab') }}>
                        <h4 className={`${tab === 'filter-tab' ? 'in-clicked' : 'tab-bu-a'}`} href="#" id="h4-job-tracking">ค้นหาด้วยตัวกรอง</h4>
                      </li>
                      <hr className="job-tracking" style={{ marginLeft: `${tab === 'customer-tab' ? '1%' : '45%'}` }} />
                    </ul>
                    {(tab === 'customer-tab') &&
                      <div className="row" style={{ paddingLeft: '45px', paddingTop: '20px' }}>
                        {/* <div className="col-md-12">
                                            
                                        </div> */}
                        <div className="col-md-12" style={{ paddingRight: '10%' }}>
                          <h4 >กรุณากรอกชื่อและ เบอร์โทรศัพท์ลูกค้า</h4>

                          <div className="input-group">
                            <input id="search-input" type="text" className="form-control" style={{ borderRadius: '10px 0px 0px 10px' }} onKeyPress={e => this.onKeyPress(e)} onChange={e => { this.setState({ searchText: e.target.value }) }} placeholder="ค้นหาด้วย ชื่อหรือเบอร์โทร ลูกค้า" />
                            <div className="input-group-btn">
                              <button id="search-button" type="button" className="btn btn-primary btn-outline-secondary"
                                style={{ borderRadius: "0px 10px 10px 0px", margin: 0, padding: '6px 20px', fontSize: '20px', backgroundColor: '#f86901' }} onClick={(e) => this.searchJobByFilter()}>
                                <i className="fa fa-search" aria-hidden="true" />
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    {(tab === 'filter-tab') &&
                      <div className="row" style={{ paddingLeft: '45px', }}>
                        <div className="col-md-6" >
                          <h5 style={{ fontWeight: 'bold', marginBottom: '2px' }}>ค้นหาลักษณะงาน:</h5>
                          {/* <pre>{JSON.stringify(typeOfJobsSelected)}</pre> */}
                          <MultiSelect
                            options={optionTypeOfJob}
                            value={typeOfJobsSelected}
                            filterOptions={this.filterOptions}
                            onChange={e => { this.setSelected(e, 'typeOfJobsSelected') }}
                            labelledBy="ทั้งหมด"
                            overrideStrings={overrideStrings}
                          />
                        </div>
                        {/* 
                          <div className="col-md-6" >
                            <h5 className="job-tracking" style={{fontWeight:'bold',marginBottom:'2px'}}>ค้นหาชื่อร้านค้า / สาขา:</h5>
                            <pre>{JSON.stringify(sotresSelected)}</pre>
                            <MultiSelect
                              options={optionStores}
                              value={sotresSelected}
                              filterOptions={this.filterOptions}
                              onChange={e=>{this.setSelected(e,'sotresSelected')}}
                              labelledBy="ทั้งหมด"
                              overrideStrings={overrideStrings}
                            />  
                          </div> 
                        */}
                        <div className="col-md-6" >
                          <h5 style={{ fontWeight: 'bold', marginBottom: '2px' }}>ค้นหาสินค้า / บริการ:</h5>
                          {/* <pre>{JSON.stringify(productsSelected)}</pre> */}
                          <MultiSelect
                            options={optionProducts}
                            value={productsSelected}
                            filterOptions={this.filterOptions}
                            onChange={e => { this.setSelected(e, 'productsSelected') }}
                            labelledBy="ทั้งหมด"
                            overrideStrings={overrideStrings}
                          />
                        </div>
                        <div className="col-md-6" style={{ paddingTop: 30 }}>
                          <div className="row">
                            <div className="col-md-6">
                              <span id="label-toggle" className="form-label">Active</span>
                              <EnToggle
                                checked={this.state.isActive}
                                onChange={e => { this.setActive(!this.state.isActive) }}
                              />
                            </div>
                            <div className="col-md-6">
                              <button className="btn btn-primary" id="searcBtn" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={e => { this.searchJobByFilter() }}>
                                <span className="fa fa-search btn-icon" aria-hidden="true" />ค้นหา
                              </button>
                            </div>

                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>


              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive table-freeze">
                      <table className="table-job-tracking table  table-hover has-pagination table-freeze-header ">
                        <thead>
                          <tr>
                            <th width="2%" className="text-left" style={{ minWidth: '25px' }}></th>
                            <th width="10%" className="text-left" style={{ minWidth: '97px' }}>เลขอ้างอิง</th>
                            <th width="10%" className="text-left" style={{ minWidth: '100px' }}>ชื่อลูกค้า</th>
                            <th width="12%" className="text-left" style={{ minWidth: '120px' }}>เบอร์ติดต่อลูกค้า</th>
                            <th width="16%" className="text-left" style={{ minWidth: '220px' }}>สินค้า / บริการ</th>
                            <th width="7%" className="text-left" style={{ minWidth: '125px' }}>ชื่อทีมช่าง</th>
                            {/* <th width="7%" className="text-left" style={{ minWidth: '50px' }}>เกรดช่าง</th> */}
                            <th width="10%" className="text-left" style={{ minWidth: '100px' }}>เบอร์ติดต่อช่าง</th>

                            <th width="6%" className="text-left" style={{ minWidth: '97px' }}>สถานะ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.displayTableRows(data)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Pagination
                      pagination={pagination}
                      handlePageClick={this.onPageClick.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth')(observer(JobsTracking));
