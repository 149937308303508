import React from 'react';
import { observer, inject } from 'mobx-react';
import { FormValidation, required } from '../../../components/form/FormValidation';
import Loader from '../../../components/common/Loader';
import EnButton from '../../../components/form/EnButton';
import EnDropDown from '../../../components/form/EnDropDown';
import EnTextAreaFormGroup from '../../../components/form/EnTextAreaFormGroup';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import Container from '../../../layouts/ContainerContractor';
import Notification from '../../../components/common/Notification';
// import moment from 'moment';
import common from '../../../utils/common';
import config from '../../../config';

export class location extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
    };
  }

  async onProvinceChanged(e) {
    let id = e.target.value;
    this.props.contractor.saveInfo('provinceId', id);
    await this.props.misc.getDistrictByCode(id);
    await this.saveDistrict();
    await this.saveSubDistrict();
  }

  async saveDistrict() {
    let miscStore = this.props.misc.toJS();
    let miscData = miscStore.miscData;
    let districtId = miscData.district[0].code;

    await this.props.misc.getSubDistrictByCode(districtId);
    this.props.contractor.saveInfo('districtId', districtId);
  }

  async saveSubDistrict() {
    let miscStore = this.props.misc.toJS();
    let miscData = miscStore.miscData;
    let subDistrictId = miscData.subDistrict[0].code;
    let zipCode = miscData.subDistrict[0].zipcodes[0].zipcode;
    this.props.contractor.saveMultiInfo({
      subDistrictId,
      zipCode,
    });
  }

  setDefaultValue() {
    this.props.contractor.saveMultiInfo({
      provinceId: '10',
      districtId: '1001',
      subDistrictId: '100101',
      zipCode: '10200',
    });
  }

  async onDistrictChanged(e) {
    let id = e.target.value;
    this.props.contractor.saveInfo('districtId', id);
    await this.props.misc.getSubDistrictByCode(id);
    await this.saveSubDistrict();
  }

  async onSubDistrictChanged(e) {
    let id = e.target.value;
    await this.props.contractor.saveInfo('subDistrictId', id);
  }

  saveRegisterInfo(key, e) {
    this.props.contractor.saveInfo(key, e.target.value);
  }

  validation() {
    return this.props.contractor.isValidRegisterInfo(2);
  }

  onSubmit() {
    const contractorId = this.state.id;
    const calendarFilter = this.props.calendar.toJS().calendarFilter;
    let qs = {};
    if (calendarFilter) {
      qs = calendarFilter;
    }
    this.noti.success('แก้ไขข้อมูลเรียบร้อย', 'สำเร็จ');
    setTimeout(() => {
      this.props.history.push(`${config.publicUrl}/contractors/${contractorId}/calendar?${common.urlBuilder(qs)}`);
    }, 1000);
  }

  clickSubmit() {
    try {
      this.props.contractor.updateContractorInfo(this.state.id, 'location');
      this.onSubmit();
    } catch (error) {
      this.noti.error(error.message);
    }
  }

  async componentDidMount() {
    this.loadData();
  }

  async loadData() {
    try {
      let data = await this.props.contractor.getContractor({
        id: this.state.id,
      });
      await this.props.misc.getAllProvince();
      if (data.province_id && data.district_id) {
        await this.props.misc.getDistrictByCode(data.province_id);
        await this.props.misc.getSubDistrictByCode(data.district_id);
      } else {
        await this.props.misc.getDistrictByCode('10');
        await this.props.misc.getSubDistrictByCode('1001');
        await this.setDefaultValue();
      }
    } catch (error) {
      this.noti.error(error.message);
    }
  }

  render() {
    let miscStore = this.props.misc.toJS();
    let miscData = miscStore.miscData;
    let contractorStore = this.props.contractor.toJS();
    let contractor = contractorStore.info;

    let provinceName = miscData.province.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });
    let districtName = miscData.district.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });
    let subDistrictName = miscData.subDistrict.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });

    return (
      <Container>
        <div className="row">
          <Notification ref={(ref) => { this.noti = ref; }} />
          <Loader show={contractorStore.isLoading || contractorStore.isSumitting} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">แก้ไขข้อมูล</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="row">
                    <FormGroup containerClassName="col-md-12" label="จังหวัด">
                      <EnDropDown onChange={this.onProvinceChanged.bind(this)} value={contractor.provinceId}>
                        {provinceName}
                      </EnDropDown>
                    </FormGroup>
                  </div>
                  <div className="row">
                    <FormGroup containerClassName="col-md-12" label="อำเภอ / เขต">
                      <EnDropDown onChange={this.onDistrictChanged.bind(this)} value={contractor.districtId}>
                        {districtName}
                      </EnDropDown>
                    </FormGroup>
                  </div>
                  <div className="row">
                    <FormGroup containerClassName="col-md-12" label="ตำบล">
                      <EnDropDown onChange={this.onSubDistrictChanged.bind(this)} value={contractor.subDistrictId}>
                        {subDistrictName}
                      </EnDropDown>
                    </FormGroup>
                  </div>
                  <div className="row">
                    <FormGroup containerClassName="col-md-12">
                      <EnTextFormGroup label="รหัสไปรษณีย์" value={contractor.zipCode} onChange={this.saveRegisterInfo.bind(this, 'zipCode')} validations={[required]} />
                    </FormGroup>
                  </div>
                  <div className="row">
                    <EnTextAreaFormGroup containerClassName="col-md-12" label="ที่อยู่ (บ้านเลขที่ ซอย ถนน)" rows="5" value={contractor.address} onChange={this.saveRegisterInfo.bind(this, 'address')} validations={[required]} />
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <EnButton className="btn-info pull-right" disabled={!this.validation()} onClick={this.clickSubmit.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('contractor', 'misc', 'calendar')(observer(location));
