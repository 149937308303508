import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import Container from '../../layouts/ContainerContractor';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import Pagination from '../../components/common/Pagination';
import EnButton from '../../components/form/EnButton';
import EnDatePicker from '../../components/form/EnDatePicker';
import config from '../../config';
import { JobStatuses } from '../../utils/data';

export class ContractorJobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      statuses: JobStatuses.filter(s => {
        const exceptStatuses = ['deleted', 'contractor_no_view', 'waiting_for_store_payment'];
        return !exceptStatuses.includes(s.value);
      }),
    };
  }

  async componentWillMount() {
    try {
      await this.props.typeofwork.getTypeOfWorks();
      if (this.state.id) {
        this.props.joblist.setQueryValue('contractor_id', this.state.id);
      }
      await this.props.joblist.searchJobs();
    } catch (err) {
      this.noti.error(err.message);
    }
  }

  onJobCodeClick(id, e) {
    e.preventDefault();
    this.props.history.push(`${config.publicUrl}/contractors/${this.state.id}/calendar/jobs/${id}`);
  }

  async onPageClick(pageNum) {
    this.props.joblist.setQueryValue('page', pageNum);
    await this.props.joblist.searchJobs();
  }

  async onSearchClick(e) {
    this.props.joblist.setQueryValue('page', 1);
    await this.props.joblist.searchJobs();
  }

  async onClearSearchClick(e) {
    this.props.joblist.setDefaultQuery();
    if (this.state.id) {
      this.props.joblist.setQueryValue('contractor_id', this.state.id);
    }
    await this.props.joblist.searchJobs();
  }

  handleQueryChange(key, event) {
    this.props.joblist.setQueryValue(key, event.target.value);
  }

  handleActiveCheck(key, event) {
    const value = event.target.checked;
    this.props.joblist.setQueryValue(key, value);
  }

  handleDateChange(key, date) {
    this.props.joblist.setQueryValue(key, date.toDate());
  }

  getTypeOfJob(typeOfJob) {
    return typeOfJob === 'S'
      ? <div>
        <i className="fa fa-search" aria-hidden="true" /> สำรวจ
      </div>
      : <div>
        <i className="fa fa-gavel" aria-hidden="true" /> ติดตั้ง
      </div>;
  }

  getTypeOfWorkText(typeOfWorks, typeOfWorkItems) {
    if (Array.isArray(typeOfWorks) && typeOfWorks.length) {
      const typeOfWork = +typeOfWorks[0];
      const item = typeOfWorkItems.find(tow => tow.value === typeOfWork);
      if (item) return item.name;
    }
    return '';
  }

  getAvailableTime(available) {
    switch (available) {
      case 0:
        return (
          <div>
            <i className="fa fa-clock-o" aria-hidden="true" style={{ color: '#A569BD' }} /> ทั้งวัน
          </div>
        );
      case 1:
        return (
          <div>
            <i className="fa fa-clock-o" aria-hidden="true" style={{ color: '#5DADE2' }} /> เช้า
          </div>
        );
      case 2:
        return (
          <div>
            <i className="fa fa-clock-o" aria-hidden="true" style={{ color: '#F5B041' }} /> บ่าย
          </div>
        );
      default: return '';
    }
  }

  getStatus(status) {
    switch (status) {
      case 'created': return <span className="status status-created">สร้างใหม่</span>;
      case 'assigned': return <span className="status status-assigned">ได้รับมอบหมาย</span>;
      case 'survey_started': return <span className="status status-survey-started">เริ่มสำรวจ</span>;
      case 'survey_finished': return <span className="status status-survey-finished">สิ้นสุดการสำรวจ</span>;
      case 'quotation_management': return <span className="status status-quotation-management">ใบเสนอราคา</span>;
      case 'installation_requested': return <span className="status status-installation-requested">นัดติดตั้ง</span>;
      case 'installation_confirmed': return <span className="status status-installation-confirmed">ยืนยันการติดตั้ง</span>;
      case 'installation_started': return <span className="status status-installation-started">เริ่มการติดตั้ง</span>;
      case 'installation_finished': return <span className="status status-installation-finished">สิ้นสุดการติดตั้ง</span>;
      case 'installation_accepted': return <span className="status status-installation-accepted">ลูกค้ายอมรับ</span>;
      case 'installation_rejected': return <span className="status status-installation-rejected">ลูกค้าปฏิเสธ</span>;
      case 'paid': return <span className="status status-paid">ชำระเงิน</span>;
      case 'deleted': return <span className="status status-deleted">ย้ายงาน/Inactive</span>;
      case 'paid_to_contractor': return <span className="status status-paid-to-contractor">จ่ายเงินให้ช่างแล้ว</span>;
      default: return '';
    }
  }

  displaySearchForm(query, statuses = [], typeOfWorks = []) {
    return (
      <form className="form-horizontal">
        <div className="col-md-6">
          <div className="form-group">
            <label className="col-md-2 control-label" htmlFor="jobCode">รหัสอ้างอิง</label>
            <div className="col-md-10">
              <input type="text"
                className="form-control"
                id="jobCode"
                placeholder="Q-xxxxxxxxxx"
                value={query.code}
                onChange={this.handleQueryChange.bind(this, 'code')}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="col-md-2 control-label" htmlFor="customerName">ชื่อลูกค้า</label>
            <div className="col-md-10">
              <input type="text"
                className="form-control"
                id="customerName"
                placeholder=""
                value={query.customer_name}
                onChange={this.handleQueryChange.bind(this, 'customer_name')}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="col-md-2 control-label" htmlFor="typeOfJob">ลักษณะงาน</label>
            <div className="col-md-10">
              <select className="form-control" id="typeOfJob" value={query.type_of_job} onChange={this.handleQueryChange.bind(this, 'type_of_job')}>
                <option value="">--- เลือก ---</option>
                <option value="S">สำรวจ</option>
                <option value="I">ติดตั้ง</option>
              </select>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="col-md-2 control-label" htmlFor="typeOfJob">ประเภทงาน</label>
            <div className="col-md-10">
              <select className="form-control" id="typeOfWork" value={query.type_of_work} onChange={this.handleQueryChange.bind(this, 'type_of_work')}>
                <option value="">--- เลือก ---</option>
                {typeOfWorks.map((tow, index) => <option key={`tow-${index}`} value={tow.value}>{tow.name}</option>)}
              </select>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="col-md-2 control-label" htmlFor="status">สถานะ</label>
            <div className="col-md-10">
              <select className="form-control" id="status" value={query.status} onChange={this.handleQueryChange.bind(this, 'status')}>
                <option value="">--- เลือก ---</option>
                {statuses.map((s, index) => <option key={`js-${index}`} value={s.value}>{s.name}</option>)}
              </select>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="col-md-2 control-label" htmlFor="startDate">วันที่เริ่ม</label>
            <div className="col-md-10">
              <EnDatePicker id="startDate"
                initialDate={query.start_date ? moment(query.start_date) : undefined}
                onDateChange={this.handleDateChange.bind(this, 'start_date')}
                isOutsideRange={() => false}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="col-md-2 control-label" htmlFor="endDate">วันที่สิ้นสุด</label>
            <div className="col-md-10">
              <EnDatePicker id="endDate"
                initialDate={query.end_date ? moment(query.end_date) : undefined}
                onDateChange={this.handleDateChange.bind(this, 'end_date')}
                isOutsideRange={() => false}
              />
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <label className="col-md-2 control-label" htmlFor="availableTime">ช่วงเวลา</label>
            <div className="col-md-10">
              <select className="form-control" id="availableTime" value={query.available_time} onChange={this.handleQueryChange.bind(this, 'available_time')}>
                <option value="">--- เลือก ---</option>
                <option value="0">ทั้งวัน</option>
                <option value="1">เช้า</option>
                <option value="2">บ่าย</option>
              </select>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group">
            <div className="col-md-offset-2 col-md-10">
              <EnButton className="btn-warning" onClick={this.onClearSearchClick.bind(this)}>
                <i className="fa fa-refresh btn-icon" aria-hidden="true" />เคลียร์
              </EnButton>
              <EnButton className="btn-info" onClick={this.onSearchClick.bind(this)}>
                <i className="fa fa-search btn-icon" aria-hidden="true" />ค้นหา
              </EnButton>
            </div>
          </div>
        </div>
      </form>
    );
  }

  displayTableRows(jobs, typeOfWorks, statusSearch) {
    return jobs.map((j, index) => (
      <tr key={index}>
        <td className="text-center">
          {
            j.status !== 'deleted'
              ? (<a href="" title={j.job_code} onClick={this.onJobCodeClick.bind(this, j._id)}>{j.job_code}</a>)
              : <span>{j.job_code}</span>
          }
        </td>
        <td className="text-center">{this.getTypeOfJob(j.type_of_job)}</td>
        <td>{this.getTypeOfWorkText(j.type_of_work, typeOfWorks)}</td>
        <td>{j.contractor_name || ''}</td>
        <td>{`${j.customer_firstname || ''} ${j.customer_lastname || ''}`}</td>
        <td className="text-center">{this.getAvailableTime(+j.available)} {j.time_label || ''}</td>
        <td className="text-center">{momentTz(j.start_date).utcOffset(7).format('ddd Do/M/YYYY')}</td>
        <td className="text-center">{momentTz(j.end_date).utcOffset(7).format('ddd Do/M/YYYY')}</td>
        <td className="text-center">
          {
            statusSearch === 'paid_to_contractor'
              ? this.getStatus('paid_to_contractor')
              : this.getStatus(j.status)
          }</td>
      </tr>
    ));
  }

  render() {
    const { data, query, pagination, isLoading, statusSearch } = this.props.joblist.toJS();
    const { statuses } = this.state;
    const typeOfWorks = this.props.typeofwork.toJS().items;
    return (
      <Container>
        <Loader show={isLoading} />
        <Notification ref={(ref) => { this.noti = ref; }} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ค้นหาคิวงาน</h4>
              </div>
              <div className="card-content">
                {this.displaySearchForm(query, statuses, typeOfWorks)}
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="10%" className="text-center">เลขอ้างอิง</th>
                            <th width="10%" className="text-center">ลักษณะงาน</th>
                            <th width="10%" className="text-center">ประเภทงาน</th>
                            <th width="10%" className="text-center">ชื่อทีมช่าง</th>
                            <th width="10%" className="text-center">ชื่อลูกค้า</th>
                            <th width="10%" className="text-center">ช่วงเวลา</th>
                            <th width="10%" className="text-center">วันที่เริ่ม</th>
                            <th width="10%" className="text-center">วันที่สิ้นสุด</th>
                            <th width="15%" className="text-center">สถานะ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.displayTableRows(data, typeOfWorks, statusSearch)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <Pagination
                      pagination={pagination}
                      handlePageClick={this.onPageClick.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('joblist', 'typeofwork')(observer(ContractorJobList));
