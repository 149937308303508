import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';
import AuthStore from './AuthStore';

export class JobNoteStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      note: this.initDefaultNote(),
      job: undefined,
    });
  }

  initDefaultNote() {
    const userInfo = AuthStore.getUserInfo();
    const createdBy = userInfo ? userInfo.name : 'ไม่ระบุ';
    return {
      text: '',
      files: [],
      createdBy,
    };
  }

  initDefaultFile() {
    return {
      name: '',
      url: '',
      date: undefined,
    };
  }

  setNoteValue(key, value) {
    this.note[key] = value;
  }

  async loadJobById(id) {
    if (this.isLoading) return;

    try {
      const res = await http.get(`${config.api.sims}/v1/jobs/${id}`);
      if (res && res.data && res.data.data) {
        this.job = res.data.data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

  async createNote(id) {
    if (this.isLoading) return;

    try {
      const { note } = this.toJS();
      const res = await http.post(`${config.api.sims}/v1/jobs/${id}/notes`, note);
      if (res && res.data && res.data.data) {
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }
}

export default new JobNoteStore();
