import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import FormGroup from '../../components/form/FormGroup';
import moment from 'moment';
import EnDropDown from '../../components/form/EnDropDown';
import Swal from 'sweetalert2';
import { isAllow } from '../../utils/permission';

export class AdminViewContractorCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      options: [
        { value: 'กรุณาระบุเหตุผล', label: 'กรุณาระบุเหตุผล' },
        { value: 'ผ่าน Incubation', label: 'ผ่าน Incubation' },
        { value: 'ทดลองงานเพิ่ม', label: 'ทดลองงานเพิ่ม' },
        { value: 'ปรับเกรดเพื่อรองรับงาน SCG Home Online', label: 'ปรับเกรดเพื่อรองรับงาน SCG Home Online' },
        { value: 'ปรับเกรดเพื่อรองรับงานในพื้นที่, B2B, Partner และอื่นๆ', label: 'ปรับเกรดเพื่อรองรับงานในพื้นที่, B2B, Partner และอื่นๆ' },
        { value: 'IM พิจารณาปรับลดเกรด', label: 'IM พิจารณาปรับลดเกรด' },
        { value: 'IM พิจารณาคืนเกรดเดิม', label: 'IM พิจารณาคืนเกรดเดิม' },
        { value: 'ปรับเกรดลงเนื่องจากติดโควิด/อุบัติเหตุ', label: 'ปรับเกรดลงเนื่องจากติดโควิด/อุบัติเหตุ' },
        { value: 'คืนเกรดเดิมจากการหายโควิด/อุบัติเหตุ', label: 'คืนเกรดเดิมจากการหายโควิด/อุบัติเหตุ' },
        { value: 'อื่นๆ โปรดระบุเหตุผล', label: 'อื่นๆ โปรดระบุเหตุผล' },
      ],
    };
  }

  componentDidMount() {
    this.initialData()
  }

  initialData = async () => {
    const { contractorgrade: contractorGradeStore } = this.props;

    await contractorGradeStore.initGradeMap();
    await this.props.contractor.getContractor({ id: this.state.id });
  };

  async onGradeChange(payload, event) {
    const user = this.props.auth.getUsernameOrEmail()
    const oldGrade = payload.item.grade;
    const newGrade = event.target.value;
    const { contractorgrade: contractorGradeStore } = this.props;
    const showOldGrade = contractorGradeStore.getDisplayedGrade(payload.item.grade);
    const showNewGrade = contractorGradeStore.getDisplayedGrade(event.target.value);

    let inputText = ''
    let reason = ''

    if (payload.findHistory) {
      const find = this.state.options.find(item => item.value === payload.findHistory.change_grade_reason)
      if (!find) {
        reason = 'อื่นๆ โปรดระบุเหตุผล'
        inputText = payload.findHistory.change_grade_reason
      } else {
        reason = payload.findHistory.change_grade_reason
        inputText = payload.findHistory.change_grade_reason
      }
    }

    const htmlRender = (condition, text) => {
      const options = this.state.options.map((item) => {
        const isSelected = item.value === condition || item.value === 'กรุณาระบุเหตุผล';
        const isDisabled = item.value === 'กรุณาระบุเหตุผล';
        return `<option value="${item.value}" ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}>${item.label}</option>`;
      });

      let body = `
      <p>${`( ${showOldGrade || 'ไม่มี'} > ${showNewGrade || 'ไม่มี'} )`}</p>
      <select id="swal-input1" class="swal2-input">
        ${options.join('\n')}
      </select>`;

      if (condition === 'อื่นๆ โปรดระบุเหตุผล') {
        body += `<input id="swal-input2" class="swal2-input" placeholder="กรุณาระบุเหตุผล" value="${text}"/>`;
      }
  
      return body;

    //   let body = `
    //   <p>${`( ${oldGrade || 'ไม่มี'} > ${newGrade || 'ไม่มี'} )`}</p>
    //   <select id="swal-input1" class="swal2-input">
    //     ${this.state.options.map((item) => {
    //       return (
    //         `<option 
    //           value="${item.value}" 
    //           ${item.value === condition ? 'selected' 
    //             : item.value === 'กรุณาระบุเหตุผล' 
    //             ? 'selected' 
    //             : ''
    //           }
    //           ${item.value === 'กรุณาระบุเหตุผล' ? 'disabled' : ''}
    //         >
    //           ${item.label}
    //         </option>`
    //       )
    //     })}
    //   </select>
    // `
    //   if (condition === 'อื่นๆ โปรดระบุเหตุผล') {
    //     body = `
    //     <p>${`( ${oldGrade || 'ไม่มี'} > ${newGrade || 'ไม่มี'} )`}</p>
    //     <select id="swal-input1" class="swal2-input">
    //     ${this.state.options.map((item) => {
    //       return (
    //         `<option 
    //           value="${item.value}" 
    //           ${item.value === condition ? 'selected' 
    //             : item.value === 'กรุณาระบุเหตุผล' 
    //             ? 'selected' 
    //             : ''
    //           }
    //           ${item.value === 'กรุณาระบุเหตุผล' ? 'disabled' : ''}
    //         >
    //           ${item.label}
    //         </option>`
    //       )
    //     })}
    //     </select>
    //     <input id="swal-input2" class="swal2-input" placeholder="กรุณาระบุเหตุผล" value="${text}"/>
    //   `
    //   }
    //   return body
    }

    Swal.fire({
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      title: 'กรุณายืนยันการเปลี่ยนเกรดของช่าง',
      inputAttributes: { autocapitalize: 'off' },
      allowOutsideClick: () => !Swal.isLoading(),
      html: htmlRender(),
      onRender: () => {
        const selectElement = document.getElementById('swal-input1')
        return selectElement.addEventListener('change', async (event) => {
          const value = event.target.value || reason
          return await Swal.update({
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            customClass: 'font-size-200',
            showLoaderOnConfirm: true,
            title: 'กรุณายืนยันการเปลี่ยนเกรดของช่าง',
            inputAttributes: { autocapitalize: 'off' },
            allowOutsideClick: () => !Swal.isLoading(),
            html: htmlRender(value, ''),
          })

        });
      },
      preConfirm: async () => {
        const selectValue = document.getElementById('swal-input1')
        const inputElement = document.getElementById('swal-input2')

        if (!selectValue.value) {
          return Swal.showValidationMessage(`กรุณาระบุเหตุผล`);
        }

        if (selectValue.value === 'กรุณาระบุเหตุผล') {
          return Swal.showValidationMessage(`กรุณาระบุเหตุผล`);
        }

        if (selectValue.value === 'อื่นๆ โปรดระบุเหตุผล' && !inputElement.value) {
          return Swal.showValidationMessage(`กรุณาระบุเหตุผลมากกว่า 5 ตัวอักษร`);
        }

        if (selectValue.value === 'อื่นๆ โปรดระบุเหตุผล' && inputElement.value.length < 5) {
          return Swal.showValidationMessage(`กรุณาระบุเหตุผลมากกว่า 5 ตัวอักษร`);
        }

        const remark = selectValue.value === 'อื่นๆ โปรดระบุเหตุผล' ? inputElement.value : selectValue.value

        return await this.props.contractor.changeGrade({ ...payload.item, _id: this.state.id }, newGrade, remark, user)
      }
    }).then((result) => {
      if (result && result.value) {
        this.initialData()
        Swal.fire({ title: `แก้ไขข้อมูลเรียบร้อย`, icon: 'success' });
        return
      }
    }).catch((error) => {
      Swal.fire({ title: `ไม่สามารถแก้ไขข้อมูลได้`, icon: 'error' });
    });
  }


  getTableRows(info) {
    const { contractorgrade: contractorGradeStore } = this.props;
    const { gradeMap } = contractorGradeStore.toJS();
    const { grade, historyGrade } = info;

    return grade.map((item, index) => {
      const findHistory = historyGrade.find(history => history.type_of_work === item.type_of_work)
      const prevGrade = findHistory && contractorGradeStore.getDisplayedGrade(findHistory.old_grade);
      const updatedGrade = findHistory && contractorGradeStore.getDisplayedGrade(findHistory.new_grade);

      return (
        <tr key={index}>
          <td className="text-center">{item.name || ''}</td>

          <td>
            <div>
              <EnDropDown disabled={!isAllow("ADJUST_A_GRADE_OF_CONTRACTOR")} value={item.grade || ''} onChange={this.onGradeChange.bind(this, { item, findHistory })}>
                {/* <EnDropDown style={{ width: 70, display: 'inline', marginLeft: 6, textAlignLast: 'center' }} value={item.grade || ''} onChange={this.onGradeChange.bind(this, index, item)}> */}
                {gradeMap && Object.entries(gradeMap).map(([originalGrade, displayedGrade], index) => (
                  <option key={index} value={originalGrade}>
                    {displayedGrade}
                  </option>
                ))}
              </EnDropDown>
            </div>
          </td>

          <td>
            {
              findHistory ? (
                <>
                  <p>
                    {`[${moment(findHistory.date_change || '-').format('YYYY-MM-DD')}] user เปลี่ยนเกรด ${prevGrade} เป็น ${updatedGrade} เนื่องจาก ${findHistory.change_grade_reason || '-'}`}
                  </p>
                  <p>{`โดย: ${findHistory.created_by || '-'}`}</p>
                </>
              ) : ('-')
            }
          </td>
        </tr>
      );
    })
  }

  render() {
    const info = this.props.contractor.toJS().info;
    const rows = this.getTableRows(info);

    return (
      <div>
        <div className="card item-card">
          <div className="card-content">
            <FormGroup label="ประเภทงานและเกรดช่าง">
              <div style={{ padding: '15px 22px' }} className="card-content">
                {/* <div className="row">สำรวจ</div> */}
                {/* <div className="col-md-12 col-xs-12" >
                  <div className="col-md-2 col-xs-6" >
                    เช้า  <b style={{ fontSize: '25px' }}>{contractor.surveyPerDay.morning}</b> งาน
                  </div>
                  <div className="col-md-2 col-xs-6" >
                    บ่าย <b style={{ fontSize: '25px' }}>{contractor.surveyPerDay.afternoon}</b> งาน
                  </div>
                </div> */}
                {/* <div className="row">ติดตั้ง</div>
                <div className="col-md-12 col-xs-12" >
                  <div className="col-md-2 col-xs-6">
                    เช้า  <b style={{ fontSize: '25px' }}>{contractor.installationPerDay.morning}</b> งาน
                  </div>
                  <div className="col-md-2 col-xs-6" >
                    บ่าย <b style={{ fontSize: '25px' }}>{contractor.installationPerDay.afternoon}</b> งาน
                  </div>
                </div> */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="5%" className="text-center">ประเภทงาน</th>
                            <th width="5%" className="text-center">เกรด</th>
                            <th width="5%" className="text-center">หมายเหตุ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </FormGroup>
          </div>
        </div>
        <div className="card-content">
          <div className="row row-calendar">
          </div>
        </div>
      </div>
    );
  }
}

export default inject('contractor', 'calendar', 'typeofwork', 'auth', 'contractorgrade')(observer(AdminViewContractorCalendar));
