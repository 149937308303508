import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import MobileDetect from 'mobile-detect';
import Container from '../../layouts/Container';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import { FormValidation } from '../../components/form/FormValidation';
import EnButton from '../../components/form/EnButton';
import history from '../../utils/history';
import config from '../../config';
import authStore from '../../stores/AuthStore';
import ModalAuth from '../../components/common/ModalAuth';
import * as _ from 'lodash';

export class CreditSaleForm extends React.Component {
  constructor(props) {
    super(props);
    const md = new MobileDetect(window.navigator.userAgent);
    this.state = {
      jobId: this.props.match.params.id,
      pdf: undefined,
      isMobileOrTablet: !!(md.mobile() || md.tablet()),
      requestTax: false,
      paymentChannel: '',
    };
  }

  async componentWillMount() {
    const job = this.props.job.toJS().info.jobType;
    if (!job) {
      await this.props.job.getJobsById(this.state.jobId);
    }
    // await this.props.po.loadPoByJobId(this.state.jobId);
  }

  // async componentWillUnmount() {
  //   await this.props.po.clearItems();
  // }

  validateForm(info) {
    return true;
  }

  async onSubmit(e) {
    e.preventDefault();
    const info = this.props.po.toJS().info;
    if (await this.props.job.showJobUpdatedDialog(info)) {
      return;
    }
    const isFormValid = this.validateForm(info);
    if (!isFormValid) {
      swal.fire({
        icon: 'error',
        title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        confirmButtonText: 'ตกลง',
      });
      return;
    }
    let status = this.props.permission.getUserFromEmail()
    if (!status) {
      return
    }
    if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
      return;
    }
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไข' : 'กรุณายืนยันการสร้าง',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const { data } = await this.props.job.saveCreditSale(this.state.jobId, this.props.auth.getUsernameOrEmail());
          if (data.credit_sale.no) {
            const { userName } = this.props.permission.toJS()
            const nameFileCS = data.credit_sale.no
            const remark = `[${nameFileCS}]  is edited by ${userName}`
            this.props.permission.setInfoLogActivity([this.saveModelEventLogs(`update_cs`, nameFileCS, remark)])
            this.props.permission.saveUamActivityLogs()
          }
          if (data && data.message === 'error') {
            throw new Error('ไม่สามารถสร้างใบเสร็จรับเงินได้');
          }
          return data;
        } catch (error) {
          swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          return false;
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      this.onCancel();
    }
  }

  saveModelEventLogs(event, referenceCode, remark) {
    const dataPermission = {}
    dataPermission.event = event
    dataPermission.function_name = event
    dataPermission.reference_code = referenceCode
    dataPermission.remark = remark
    return dataPermission
  }

  onCancel() {
    const isAdmin = authStore.getStore();
    if (!_.isNil(isAdmin) && !_.isEmpty(isAdmin)) {
      history.push(`${config.publicUrl}/calendar/admin/jobs/${this.state.jobId}`);
    } else {
      history.push(`${config.publicUrl}/superadmin/jobs/${this.state.jobId}`);
    }
  }

  // async onSubmitAndSendMail(e) {
  //   e.preventDefault();
  //   // const isFormValid = this.frm.isValid();
  //   const info = this.props.po.toJS().info;
  //   const isFormValid = this.validateForm(info);
  //   if (!isFormValid) {
  //     swal.fire({
  //       icon: 'error',
  //       title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
  //       confirmButtonText: 'ตกลง',
  //     });
  //     return;
  //   }

  //   let confirmDialogOptions = {
  //     title: 'บันทึกข้อมูล',
  //     text: this.state.isEdit ? 'กรุณายืนยันการแก้ไข และส่งเมล' : 'กรุณายืนยันการสร้าง และส่งเมล',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'ยืนยัน',
  //     cancelButtonText: 'ยกเลิก',
  //     customClass: 'font-size-200',
  //     showLoaderOnConfirm: true,
  //     preConfirm: async () => {
  //       try {
  //         const data = await this.props.job.saveContractorReceipt(this.state.jobId, true);
  //         if (data && data.message === 'error') {
  //           throw new Error('ไม่สามารถสร้างใบเสร็จรับเงินได้');
  //         }
  //         return data;
  //       } catch (error) {
  //         swal.fire({
  //           icon: 'error',
  //           title: error.message,
  //           confirmButtonText: 'ตกลง',
  //         });
  //         return false;
  //       }
  //     },
  //     allowOutsideClick: () => !swal.isLoading(),
  //   };

  //   let result = await swal.fire(confirmDialogOptions);
  //   if (result.value) {
  //     swal.fire({
  //       title: 'บันทึกข้อมูลเรียบร้อย',
  //       icon: 'success',
  //     });
  //     this.onCancel();
  //   }
  // }

  render() {
    const poStore = this.props.po.toJS();
    const info = poStore.info;
    let isSurvey;
    if (info && info.jobInfo && info.jobInfo.type_of_job) {
      isSurvey = info.jobInfo.type_of_job === 'S';
    }
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={poStore.isLoading || poStore.isSubmitting} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="card">
            <div className="col-md-12">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">แบบฟอร์มใบเสร็จรับเงิน</h4>
                <p className="category">{isSurvey ? 'สำรวจ' : 'ติดตั้ง'}</p>
              </div>
              <div className="card-content">

                <FormValidation ref={(el) => {
                  this.frm = el;
                }}>
                  <div className="row">
                    <div className="col-md-12">
                      {/* <EnButton className="btn-warning pull-right" disabled={!this.validateForm(info)} onClick={this.onSubmitAndSendMail.bind(this)}>
                        <i className="fa fa-paper-plane-o btn-icon" aria-hidden="true" />บันทึกและส่งเมล
                      </EnButton> */}
                      <EnButton className="btn-info pull-right" disabled={!this.validateForm(info)} onClick={this.onSubmit.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                        <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                      </EnButton>
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container >
    );
  }
}

export default inject('invoice', 'po', 'job', 'permission', 'auth')(observer(CreditSaleForm));
