import _ from 'lodash';
import moment from 'moment';
import momentTz from 'moment-timezone';

export const thaiDate = {
  months: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม'],
  monthsShort: [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.'],
  weekdays: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
  weekdaysShort: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],
  weekdaysMin: ['อ', 'จ', 'อ', 'พ', 'พ', 'ศ', 'ส'],
};

class DateTime {
  momentDateFormat = 'DD/MM/YYYY';
  momentDateTimeFormat = 'DD/MM/YYYY HH:mm:ss';
  momentTimeFormat = 'HH:mm:ss';
  momentTimeNoSecondFormat = 'HH:mm';

  registerRegion() {
    moment.locale('th', thaiDate);
  }

  isEqual(dateA, dateB, isDebug) {
    let momentA = moment(dateA);
    let momentB = moment(dateB);
    let format = 'YYYYMMDD';

    if (isDebug) {
      console.log(momentA.format(format), momentB.format(format), momentA.format(format) === momentB.format(format));
    }

    return (momentA.format(format) === momentB.format(format));
  }

  isEqualWithTime(dateA, dateB) {
    let momentA = moment(dateA);
    let momentB = moment(dateB);
    let format = 'YYYYMMDDHHmmss';

    return (momentA.format(format) === momentB.format(format));
  }

  isBetween(dateCheck, dateFrom, dateTo, isDebug) {
    let formatDate = 'YYYY-MM-DD';
    let momentFrom = moment(dateFrom).format(formatDate);
    let momentTo = moment(dateTo || dateFrom).format(formatDate);
    let momentCheck = moment(moment(dateCheck).format(formatDate));

    if (isDebug) {
      console.log(`${momentCheck.format(formatDate)} BETWEEN ${momentFrom} AND ${momentTo} => ${momentCheck.isSameOrAfter(momentFrom) && momentCheck.isSameOrBefore(momentTo)}`);
    }

    return (momentCheck.isSameOrAfter(momentFrom) && momentCheck.isSameOrBefore(momentTo));
  }

  GetBetweenDates(startDate, endDate, options) {
    const dates = [];
    let currentDate = moment(startDate);
    const eDate = moment(endDate);
    const addDays = (d, days) => {
      const newDate = moment(d);
      return newDate.add(days, 'days');
    };

    while (currentDate <= eDate) {
      const cDate = currentDate;
      if (options && options.format) {
        dates.push(cDate.format(options.format));
      } else {
        dates.push(cDate);
      }

      currentDate = addDays(currentDate, 1);
    }
    return dates;
  }

  ConvertToBuddhistYear(date, formatWithoutYear) {
    if (date) {
      return `${date.format(formatWithoutYear || this.momentDateFormat.replace('YYYY', ''))}${date.year() + 543}`;
    } else {
      return null;
    }
  }

  ConvertISO2Display(iso) {
    if (!iso) return '';

    return this.ConvertToBuddhistYear(moment(iso));
  }

  ConvertISO2DisplayFullMonth(iso) {
    if (!iso) return '';

    return this.ConvertToBuddhistYear(moment(iso), 'DD MMMM ');
  }

  ConvertISO2DisplayWithTime(iso, options) {
    if (!iso) return '';

    let date = moment(iso);
    return `${this.ConvertToBuddhistYear(date)} ${date.format(this.momentTimeFormat)}`;
  }

  ConvertISO2DisplayTime(iso) {
    if (!iso) return '';

    let date = moment(iso);
    return `${date.format(this.momentTimeNoSecondFormat)}`;
  }

  SendToApi(date) {
    return moment(date).startOf('day').toISOString();
  }

  SendToApiWithTime(date) {
    return moment(date).toISOString();
  }

  formatWithOffset(date, pattern, offset) {
    return momentTz(date).utcOffset(offset).format(pattern);
  }

  toTextString(startDate, endDate, formatPattern, zoneOffset = 7) {
    if (this.isEqual(startDate, endDate)) {
      return this.formatWithOffset(startDate, formatPattern, zoneOffset);
    }
    return `${this.formatWithOffset(startDate, formatPattern, zoneOffset)} - ${this.formatWithOffset(endDate, formatPattern, zoneOffset)}`;
  }

  /**
   * Formats an array of numbers into a string representation of consecutive ranges.
   *
   * @param {number[]} numbers An array of numbers to format.
   * @returns {string} A formatted string with consecutive ranges separated by commas.
   *
   * @example
   * const numbers = [1, 3, 4, 5, 6, 9, 10, 11, 12, 13, 16, 17, 18, 19, 20, 23, 24, 25, 26, 27, 30, 31];
   * const formattedResult = formatDuration(numbers);
   * console.log(formattedResult); // Output: "1, 3-6, 9-13, 16-20, 23-27, 30-31"
   */
  formatDuration = (numbers) => {
    if (numbers.length === 0) {
      return '';
    }

    const formattedRanges = [];
    let currentRange = [numbers[0]];

    for (let i = 1; i < numbers.length; i++) {
      if (numbers[i] === numbers[i - 1] + 1) {
        currentRange.push(numbers[i]);
      } else {
        formattedRanges.push(currentRange);
        currentRange = [numbers[i]];
      }
    }

    formattedRanges.push(currentRange);

    const formattedStrings = formattedRanges.map((range) => {
      if (range.length === 1) {
        return range[0].toString();
      } else {
        return `${range[0]}-${range[range.length - 1]}`;
      }
    });

    return formattedStrings.join(', ');
  };

  /**
   * Format and group ISO date strings by month and year, returning a formatted string.
   *
   * @param {string[]} isoDateStrings - An array of ISO date strings to process.
   * @returns {string} - A formatted string containing months and dates.
   */
  formatDatesInMonth = (isoDateStrings) => {
    const sortedIsoDateStrings = _.sortBy(isoDateStrings);

    const monthDates = sortedIsoDateStrings.reduce((prevVal, currentVal) => {
      const [date, month, year] = this.formatWithOffset(currentVal, this.momentDateFormat, 7).split('/');
      const thaiMonth = `${thaiDate.months[Number(month) - 1]} ${Number(year) + 543}`;
      if (prevVal[thaiMonth]) {
        prevVal[thaiMonth] = [...prevVal[thaiMonth], Number(date)];
      } else {
        prevVal[thaiMonth] = [Number(date)];
      }
      return prevVal;
    }, {});

    let toDisplayDate = '';
    for (const month in monthDates) {
      if (toDisplayDate.length) {
        toDisplayDate += ',';
      }
      toDisplayDate += `${this.formatDuration(monthDates[month])} ${month}`;
    }

    return toDisplayDate;
  };
}

export const datetime = new DateTime();
export default datetime;
