import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Notification from '../../../components/common/Notification';
import Loader from '../../../components/common/Loader';
import datetime from '../../../utils/datetime';
import Suggestion from '../search/SearchResult';
import InfiniteScroll from 'react-infinite-scroll-component';
// import config from '../../../config';

export class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: '',
      results: [],
      showMessage: false,
      loading: false,
      nextCursor: null,
    };
  }

  handleInput = (e) => {
    this.setState({ query: e.target.value });
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  };

  onPressSearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSearchEvent();
    }
  };

  handleSearchEvent = () => {
    if (this.state.query && this.state.query.length >= 1) {
      this.getInfo(true);
    } else {
      this.setState(
        {
          results: [],
          showMessage: true,
        },
        () => {
          this.callbackToParent();
        },
      );
    }
  };

  callbackToParent() {
    if (this.state.showMessage) {
      this.noti.error('ไม่พบรายการที่ค้นหา');
    }

    if (this.props.onToggle) {
      this.props.onToggle(!this.state.showMessage);
    }
  }

  genTypeName(value) {
    const typeofwork = this.props.typeofwork.toJS();
    const result = typeofwork.items.find((t) => t.value === value);
    if (result) {
      return result.name;
    }
  }

  genAvailableTime = (available) => {
    switch (available) {
      case 0:
        return 'ทั้งวัน';
      case 1:
        return 'เช้า';
      case 2:
        return 'บ่าย';
      default:
        return 'ทั้งวัน';
    }
  };

  genDate(job) {
    // job.start_date, job.end_date, job.available, job.type_of_work[0]
    let date = datetime.isEqual(job.start_date, job.end_date);
    // let isServiceCard = false;
    // if (config.svCardTypeId && config.svCardTypeId.includes(job.type_of_work.values().next().value)) {
    //   isServiceCard = true;
    // }

    if (date) {
      return `${moment(job.start_date).format('DD/MM/YYYY')} ( ${job.time_label ? job.time_label : this.genAvailableTime(job.available)} )`;
    } else {
      return `${moment(job.start_date).format('DD/MM/YYYY')} - ${moment(job.end_date).format('DD/MM/YYYY')} ( ${this.genAvailableTime(0)} )`;
    }
  }

  getInfo = async (reset) => {
    const isBuAccount = this.props.auth.isBuAccount();
    let store = this.props.auth.getStore();
    let options = {};
    if (this.state.query) {
      options['query'] = this.state.query;
    }
    if (store) {
      options['store'] = store;
    }
    if (isBuAccount) {
      const storeId = this.props.auth.getStoreId();
      const userInfo = this.props.auth.getUserInfo();
      const channel = this.props.auth.getChannelName() || '';
      options['channel'] = channel;
      options['store_id'] = storeId;
    }

    if (this.props.contractorId) {
      options['contractorId'] = this.props.contractorId;
    }
    if (this.props.isSuperAdmin) {
      options['isSuperAdmin'] = this.props.isSuperAdmin;
    }
    if (this.state.nextCursor && !reset) {
      options['nextCursor'] = this.state.nextCursor;
    }
    this.setState({ loading: true });
    await this.props.job.getJobBySearch(options).then((res) => {
      if (res.data.length === 0) {
        this.setState({ showMessage: true });
        return [];
      } else {
        this.setState({ showMessage: false });
      }

      const mapValue = res.data.map((job) => ({
        ...job,
        typeName: this.genTypeName(job.type_of_work[0]),
        date: this.genDate(job),
      }));
      if (reset) {
        this.setState({ results: mapValue, nextCursor: res.next_cursor });
        return;
      } else {
        this.setState((prevState) => ({ results: prevState.results.concat(mapValue), nextCursor: res.next_cursor }));
      }
    });
    this.setState({ loading: false }, this.callbackToParent);
  };

  OnCancel = () => {
    this.setState(
      {
        query: '',
        results: [],
        nextCursor: null,
      },
      () => {
        if (this.props.onToggle) {
          this.props.onToggle(false);
        }
      },
    );
  };

  render() {
    const { disabled } = this.props;
    const { loading, results, nextCursor } = this.state;

    const disabledSearch = disabled || !this.state.query;
    return (
      <div>
        <Notification
          ref={(ref) => {
            this.noti = ref;
          }}
        />
        <div className="input-group">
          <input
            id="search-input"
            type="text"
            className="form-control"
            onKeyPress={this.onPressSearch}
            onChange={this.handleInput}
            value={this.state.query}
            placeholder="ค้นหาด้วย ชื่อหรือเบอร์โทร ลูกค้า"
          />
          <div className="input-group-btn">
            <button
              id="search-button"
              type="button"
              className="btn btn-primary btn-outline-secondary"
              style={{ margin: 0, padding: '6px 20px', fontSize: '20px' }}
              disabled={disabledSearch}
              onClick={(e) => this.handleSearchEvent()}
            >
              <i className="fa fa-search" aria-hidden="true" />
            </button>
            {this.state.results.length && (
              <button
                id="cancel-button"
                type="button"
                className="btn btn-danger"
                style={{ margin: 0, padding: '6px 20px', fontSize: '20px' }}
                onClick={(e) => this.OnCancel()}
              >
                <i className="fa fa-undo" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
        <InfiniteScroll
          dataLength={results.length}
          next={() => this.getInfo(false)}
          hasMore={nextCursor}
          loader={<h5 className="text-center">Loading...</h5>}
          refreshFunction={this.handleSearchEvent}
        >
          <Suggestion getHref={this.props.getHref} results={results} />
        </InfiniteScroll>
        <Loader show={loading} />
      </div>
    );
  }
}

export default inject('auth', 'typeofwork', 'job')(observer(Admin));
