import React from 'react';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import TagsInput from 'react-tagsinput';
import FormGroup from '../../../components/form/FormGroup';

export class NotiEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: true,
            timeFrame: 'weekly',
            dateMonth: null,
            days: [
            {
                key:'ทุกวันจันทร์', value: 'monday', checked: false
            }, {
                key:'ทุกวันอังคาร', value: 'tuesday', checked: false
            }, {
                key:'ทุกวันพุธ', value: 'wednesday', checked: false
            }, {
                key:'ทุกวันพฤหัส', value: 'thursday', checked: false
            }, {
                key:'ทุกวันศุกร์', value: 'friday', checked: false
            }]
        }
    }

    componentDidMount() {
        if(this.props.info.mailingDate) {
            this.setState({timeFrame: this.props.info.mailingDate.type, 
                checked: this.props.info.mailingDate.type === 'weekly' ? true : false,
                dateMonth: this.props.info.mailingDate.day || null
            })
            this.setState({
                days: this.state.days.map((dataDay)=> {
                    return this.props.info.mailingDate.day.filter((data)=> {
                        return dataDay.value === data
                    })[0] === dataDay.value ? {...dataDay, checked : true }: dataDay
                 })
            });
        }
    }

    onChangeEmails = (tags) => {
        let emails = tags.map(item => item.split(','));
        emails = _.uniq(_.flatten(emails)).filter(item => item);
        this.props.admin.setStoreInfo('storePaymentNotiEmails', emails);
    }
    handleChange = (event) => {
        this.props.info.mailingDate.type = event.target.value
        this.props.info.mailingDate.day = []
        this.props.admin.toggleEmailDate(this.props.info.mailingDate)
        this.setState({ ...this.state, [event.target.name]: event.target.value, checked: event.target.value === 'weekly' ? true: false});
    }
    numberDate = () => {
        var  event = document.getElementById("days").value;
        this.props.info.mailingDate.day = [event]
        this.setState({dateMonth: event})
        this.props.admin.toggleEmailDate(this.props.info.mailingDate)
    }
    addDate = (event) => {
        this.setState({days: this.state.days.map((data)=> (event.target.value === data.value) ? {...data, checked :event.target.checked }: data)});
        var index = this.props.info.mailingDate.day.indexOf(event.target.value)
        if (index > -1) {
            this.props.info.mailingDate.day.splice(index, 1);
        } else {
            this.props.info.mailingDate.day.push(event.target.value)
        }
        this.props.admin.toggleEmailDate(this.props.info.mailingDate)
    }

    render() {
        return (
            <div>
                <FormGroup containerClassName="col-md-6" label="อีเมลที่จะใช้เรียกเก็บเงิน">
                    <TagsInput
                        width="150px"
                        value={this.props.info.storePaymentNotiEmails || []}
                        inputProps={{
                            placeholder: 'Enter Emails...',
                        }}
                        onChange={this.onChangeEmails.bind(this)}
                        addOnBlur={this.onChangeEmails.bind(this)}
                    />
                </FormGroup>
                <div className="row" style={{ paddingLeft: '10%' }}>
                    <div className="col-md-offset-3">
                        <FormGroup containerClassName="col-md-9" label="วันที่ต้องการให้ส่งอีเมล">
                            <div className="box-border">
                                <div>
                                    <div className="form-check">
                                        <input className="form-check-input" onClick={this.handleChange.bind(this)} type="radio" name="timeFrame" id="radioWeekly" value="weekly" checked={this.state.checked} />
                                        <label className="form-check-label" for="exampleRadios1">
                                            &nbsp;&nbsp;Weekly
                                        </label>
                                    </div>
                                    {this.state.timeFrame === 'weekly' ? <div className="box-border checkbox-form">
                                    {
                                        this.state.days.map((data, index) => {
                                            return (
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value={data.value} onChange={this.addDate.bind(this)} checked={data.checked}  id="defaultCheck1" />
                                                    <label className="form-check-label" for="defaultCheck1">
                                                        &nbsp;&nbsp;{data.key}
                                                    </label>
                                                </div>
                                            )
                                        })
                                    }
                                    </div> : null
                                 }  
                                    <div className="form-check">
                                        <input className="form-check-input" onClick={this.handleChange.bind(this)} type="radio" name="timeFrame" id="radioMonthly" value="monthly" checked={!this.state.checked} />
                                        <label className="form-check-label" for="exampleRadios2">
                                            &nbsp;&nbsp;Monthly
                                        </label>
                                    </div>
                                    { this.state.timeFrame === 'monthly' ? (
                                    <div className="row">
                                        <div className="col-md-3"><p>ทุกวันที่</p></div>
                                        <div className="col-md-6">
                                        <select value={parseInt(this.state.dateMonth)} onChange={this.numberDate.bind(this)} name="days" id="days"className="col-md-12">
                                        {
                                            Array.from({length: 25}, (_, i) => i + 1).map((data)=> {
                                                return <option value={data} >{data}</option>
                                            })
                                        }
                                        </select>
                                        </div>
                                        <div className="col-md-3"><p>ของเดือน</p></div>
                                    </div>
                                ) : null}
                                </div>     
                            </div>
                        </FormGroup>
                    </div>
                </div>
            </div>
        )
    }
}
export default inject('admin')(observer(NotiEmail));