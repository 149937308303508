import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Notification from '../../../components/common/Notification';
import Loader from '../../../components/common/Loader';
import { FormValidation, required } from '../../../components/form/FormValidation';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import FormGroup from '../../../components/form/FormGroup';
import EnButton from '../../../components/form/EnButton';
import EnToggle from '../../../components/form/EnToggle';
import config from '../../../config';
import Pagination from '../../../components/common/Pagination';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { isAllow } from '../../../utils/permission';

export class ChannelPage extends React.Component {
  constructor(props) {
    super(props);
    const channelName = this.props.match.params.id;
    this.state = {
      channelName,
      isEdit: !!channelName,
      pagination: {
        page: 1,
        page_range: 20,
        page_size: 20,
      },
    };
  }

  async componentWillMount() {
    this.props.channel.clearItemInfo();
    if (this.state.channelName) {
      await this.props.channel.getChannelByName(this.state.channelName);
      await this.props.channel.getStoreByChannel(this.state.channelName, this.state.pagination);
    }
  }

  onItemInfoChanged(key, e) {
    const value = e.target ? e.target.value : e;
    this.props.channel.saveItemInfo(key, value);

    if (key === 'notification' && !value) {
      this.props.channel.saveItemInfo('is_send_flexMsg', false);
      this.props.channel.saveItemInfo('is_send_lon', false);
      this.props.channel.saveItemInfo('is_send_sms', false);
      this.props.channel.saveItemInfo('is_send_email', false);
      this.props.channel.saveItemInfo('is_send_in_app_noti', false);
      this.props.channel.saveItemInfo('is_show_price', false);
    }

    if (key === 'notification' && value) {
      this.props.channel.saveItemInfo('is_send_flexMsg', false);
      this.props.channel.saveItemInfo('is_send_lon', false);
      this.props.channel.saveItemInfo('is_send_sms', true);
      this.props.channel.saveItemInfo('is_send_email', true);
      this.props.channel.saveItemInfo('is_send_in_app_noti', true);
      this.props.channel.saveItemInfo('is_show_price', false);
    }

    if (key === 'is_send_flexMsg' || key === 'is_send_lon' || key === 'is_send_sms' || key === 'is_send_email' || key === 'is_send_in_app_noti' || key === 'is_show_price') {
      const flexMsg = this.props.channel.toJS().item;
      const {
        is_send_flexMsg,
        is_send_lon,
        is_send_sms,
        is_send_email,
        is_send_in_app_noti,
        is_show_price,
      } = flexMsg;

      if (!is_send_flexMsg && !is_send_lon && !is_send_sms && !is_send_email && !is_send_in_app_noti && !is_show_price) {
        this.props.channel.saveItemInfo('notification', false);
      }
    }
  }

  onSelectContractorGrade(key, value) {
    this.props.channel.saveContractorGrade(key, value);
  }

  isValid() {
    let isValid = false;
    const item = this.props.channel.toJS().item;
    isValid = !!item.channelName;
    const isNotSpace = item.channelName.length === item.channelName.trim().length;
    return isValid && isNotSpace;
  }

  hasPermission() {
    return this.state.isEdit ? isAllow('EDIT_CHANNEL_DETAILS') : isAllow('CREATE_A_NEW_CHANNEL');
  }

  validateName() {
    return <span className="input-error">format ไม่ถูกต้อง</span>;
  }

  async onChangePage(page) {
    this.setState({ pagination: { ...this.state.pagination, page_number: page } });
    this.setState({ isLoading: true });
    const pagination = {
      page: page,
      page_range: 20,
      page_size: 20,
    };
    await this.props.channel.getStoreByChannel(this.state.channelName, pagination);
    this.setState({ isLoading: false });
  }

  storeProduct() {
    const item = this.props.channel.toJS().infoStore;
    if (!item.data) return;
    return (
      <div className="col-md-6">
        <label>ร้านค้า</label>
        <p>
          ร้านค้าทั้งหมด : <b>{get(item, 'pagination.total_items', 0)}</b>
        </p>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>ลำดับ</th>
              <th>ร้านค้า</th>
              <th>จำนวน Product</th>
            </tr>
          </thead>
          <tbody>
            {item.data.length > 0 ? (
              get(item, 'data', []).map((data, index) => {
                return (
                  // Add key prop into element, Cause of Each child on a list should have uniq "key"
                  <tr key={index}>
                    <td>{index + 1 + (item.pagination.current_page - 1) * this.state.pagination.page_size}</td>
                    <td>
                      <NavLink to={`${config.publicUrl}/superadmin/store/${data._id}/edit`} target="_blank">
                        {data.name}
                      </NavLink>
                    </td>
                    {/* For make sure product_ids was not undefined or null */}
                    <td>
                      {data.product_ids ? data.product_ids.length : 0} / {item.numberOfProducts}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-center">ไม่มีร้านค้า</tr>
            )}
          </tbody>
        </table>
        <div className="row">
          <div className="col-md-12">
            <Pagination pagination={item.pagination} handlePageClick={this.onChangePage.bind(this)} />
          </div>
        </div>
      </div>
    );
  }

  async onSubmit() {
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไข Channel' : 'กรุณายืนยันการเพิ่ม Channel ใหม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          if (this.state.isEdit) {
            await this.props.channel.updateChannel(this.state.channelName);
          } else {
            await this.props.channel.createChannel();
          }
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      setTimeout(() => {
        this.onCancel();
      }, 500);
    }
  }

  onCancel() {
    this.props.history.push(`${config.publicUrl}/superadmin/channel`);
  }

  render() {
    const item = this.props.channel.toJS().item;
    const title = this.state.isEdit ? 'แก้ไข Channel' : 'เพิ่ม Channel ใหม่';
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <Notification
            ref={(ref) => {
              this.noti = ref;
            }}
          />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{title}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className="card-content">
                <FormValidation>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="ชื่อ Channel"
                      value={item.channelName}
                      onChange={this.onItemInfoChanged.bind(this, 'channelName')}
                      disabled={this.state.isEdit}
                      validations={[required]}
                    >
                      {!this.isValid() && item.channelName.trim() ? this.validateName() : null}
                    </EnTextFormGroup>

                    <FormGroup>
                      <EnTextFormGroup
                        containerClassName="col-md-12"
                        type="text"
                        label="ชื่อ ที่อยู่สำหรับติดต่อ"
                        placeholder="ชื่อ นามสกุล โทร. อีเมล์."
                        value={item.contactChannel}
                        onChange={this.onItemInfoChanged.bind(this, 'contactChannel')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <EnTextFormGroup
                        containerClassName="col-md-4"
                        type="number"
                        label="Credit Term"
                        value={item.creditTerm}
                        onChange={this.onItemInfoChanged.bind(this, 'creditTerm')}
                      />
                    </FormGroup>

                    <FormGroup containerClassName="col-md-12">
                      <FormGroup containerClassName="col-md-12" label="Notification">
                        <EnToggle
                          disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                          checked={item.notification}
                          onChange={this.onItemInfoChanged.bind(this, 'notification')}
                        />
                      </FormGroup>

                      {item.notification && (
                        <FormGroup containerClassName="col-md-12">
                          <FormGroup
                            containerClassName="col-md-12"
                            containerStyle={{ border: '3px solid #f1f1f1', borderRadius: '5px', paddingLeft: '10px' }}
                          >
                            {/* FIRST ROW */}
                            <FormGroup containerClassName="col-md-12" containerStyle={{ paddingLeft: '5px' }}>
                              <FormGroup containerClassName="col-md-4" label="Line Flex Message">
                                <EnToggle
                                  disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                                  checked={item.is_send_flexMsg}
                                  onChange={this.onItemInfoChanged.bind(this, 'is_send_flexMsg')}
                                />
                              </FormGroup>
                              <FormGroup containerClassName="col-md-4" label="LON">
                                <EnToggle
                                  disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                                  checked={item.is_send_lon}
                                  onChange={this.onItemInfoChanged.bind(this, 'is_send_lon')}
                                />
                              </FormGroup>
                              <FormGroup containerClassName="col-md-4" label="SMS">
                                <EnToggle
                                  disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                                  checked={item.is_send_sms}
                                  onChange={this.onItemInfoChanged.bind(this, 'is_send_sms')}
                                />
                              </FormGroup>
                            </FormGroup>

                            {/* SECOND ROW */}
                            <FormGroup containerClassName="col-md-12" containerStyle={{ paddingRight: '5px' }}>
                              <FormGroup containerClassName="col-md-4" label="Email">
                                <EnToggle
                                  disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                                  checked={item.is_send_email}
                                  onChange={this.onItemInfoChanged.bind(this, 'is_send_email')}
                                />
                              </FormGroup>
                              <FormGroup containerClassName="col-md-4" label="In App Notification">
                                <EnToggle
                                  disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                                  checked={item.is_send_in_app_noti}
                                  onChange={this.onItemInfoChanged.bind(this, 'is_send_in_app_noti')}
                                />
                              </FormGroup>
                              <FormGroup containerClassName="col-md-4" label=""></FormGroup>
                            </FormGroup>

                            {/* THIRD ROW */}
                            <FormGroup containerClassName="col-md-12" containerStyle={{ paddingRight: '5px' }}>
                              <FormGroup containerClassName="col-md-12" label="หมายเหตุ" containerStyle={{ marginBottom: 0 }}>
                                <p>
                                  ระบบจะส่งการแจ้งเตือนด้วย Line Flex Message, LON, และ SMS อย่างไดอย่างหนึ่ง ตามลำดับ
                                  กรณีผู้ใช้ไม่ได้รับการแจ้งเตือนลำดับแรก ระบบจะส่งการแจ้งเตือนในลำดับถัดไปแทน
                                </p>
                                <hr style={{ padding: 0, margin: 0 }} />
                              </FormGroup>
                            </FormGroup>

                            {/* FOURTH ROW */}
                            <FormGroup containerClassName="col-md-12" containerStyle={{ paddingRight: '5px' }}>
                              <FormGroup containerClassName="col-md-12" label="แสดงค่าบริการ">
                                <EnToggle
                                  disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                                  checked={item.is_show_price}
                                  onChange={this.onItemInfoChanged.bind(this, 'is_show_price')}
                                />
                              </FormGroup>
                            </FormGroup>
                          </FormGroup>
                        </FormGroup>
                      )}
                    </FormGroup>

                    <FormGroup containerClassName="col-md-12">
                      <FormGroup containerClassName="col-md-12" label="Marketplace on Marketplace">
                        <EnToggle
                          disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                          checked={item.mom}
                          onChange={this.onItemInfoChanged.bind(this, 'mom')}
                        />
                      </FormGroup>
                    </FormGroup>

                    <FormGroup containerClassName="col-md-12">
                      <FormGroup containerClassName="col-md-12" label="Product *">
                        <FormGroup containerClassName="col-md-12">
                          <input
                            type="radio"
                            value="v1"
                            checked={item.productChannel === 'v1'}
                            onChange={this.onItemInfoChanged.bind(this, 'productChannel')}
                          />
                          Qchang V1
                        </FormGroup>

                        <FormGroup containerClassName="col-md-12">
                          <input
                            type="radio"
                            value="v2"
                            checked={item.productChannel === 'v2'}
                            onChange={this.onItemInfoChanged.bind(this, 'productChannel')}
                          />
                          Qchang V2
                        </FormGroup>
                      </FormGroup>
                    </FormGroup>

                    <div>
                      <FormGroup containerClassName="col-md-12" label="เกรดช่างที่รับงานได้">
                        <div className="row">
                          <FormGroup containerClassName="col-md-2" label="A">
                            <EnToggle
                              disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                              checked={item.contractorGrade.a}
                              onChange={this.onSelectContractorGrade.bind(this, 'a')}
                            />
                          </FormGroup>
                          <FormGroup containerClassName="col-md-2" label="New">
                            <EnToggle
                              disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                              checked={item.contractorGrade.new}
                              onChange={this.onSelectContractorGrade.bind(this, 'new')}
                            />
                          </FormGroup>
                          <FormGroup containerClassName="col-md-2" label="B">
                            <EnToggle
                              disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                              checked={item.contractorGrade.b}
                              onChange={this.onSelectContractorGrade.bind(this, 'b')}
                            />
                          </FormGroup>
                          <FormGroup containerClassName="col-md-2" label="C">
                            <EnToggle
                              disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                              checked={item.contractorGrade.c}
                              onChange={this.onSelectContractorGrade.bind(this, 'c')}
                            />
                          </FormGroup>
                          <FormGroup containerClassName="col-md-2" label="D">
                            <EnToggle
                              disabled={!isAllow('EDIT_CHANNEL_CONTRACTOR_GRADE')}
                              checked={item.contractorGrade.d}
                              onChange={this.onSelectContractorGrade.bind(this, 'd')}
                            />
                          </FormGroup>
                        </div>
                      </FormGroup>
                    </div>
                  </div>

                  <div className="row">{this.storeProduct()}</div>
                  <div className="row">
                    <EnButton
                      className="btn-info pull-right"
                      disabled={!this.isValid() || !this.hasPermission() || item.productChannel === ''}
                      onClick={this.onSubmit.bind(this)}
                    >
                      <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
                      บันทึก
                    </EnButton>
                    <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                      <i className="fa fa-times btn-icon" aria-hidden="true" />
                      ยกเลิก
                    </EnButton>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('channel')(observer(ChannelPage));
