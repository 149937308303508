import React, { Component } from 'react';
import NumericInput from 'react-numeric-input';

export class EnNumeric extends Component {
  value() {
    return this.input.value;
  }

  clear() {
    this.input.value = '';
  }

  render() {
    let propInput = Object.assign({}, this.props);
    // propInput.className = `${propInput.className ? propInput.className : ''} form-control`;

    return (
      <div>
        <NumericInput
          ref={(e) => { this.input = e; }}
          min={0}
          mobile
          snap
          strict
          {...propInput} />
      </div>
    );
  }
}

export default EnNumeric;
