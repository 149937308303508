import React from 'react';
import { PulseLoader } from 'halogenium';

class Loader extends React.Component {
  render() {
    return (
      <PulseLoader className="loader no-print"
        loading={this.props.loading || false}
        color="#3b3b3b"
        size="16px"
      />
    );
  }
}

export default Loader;
