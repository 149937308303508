import React, { Component } from 'react';
import * as _ from 'lodash';
import Select from 'react-select';
import { control } from 'react-validation';

export class EnTagSelect extends Component {
  handleSelectChange(value) {
    if (this.props.onTagChange) {
      this.props.onTagChange(value);
    }
  }

  render() {
    let { closeOnSelect, disabled, placeholder, value, options, searchable, className, withHeight, multi } = this.props;
    // ถ้าไม่ได้ส่งค่า multi มาให้เซ็ตเป็น true
    multi = multi !== false;

    /**
     * options = [ { label: string, value: string } ]
     */
    if (withHeight) {
      className = 'coupon-container';
    }

    return (
      <Select
        className={`p4-select ${className}`}
        closeOnSelect={closeOnSelect}
        disabled={disabled}
        multi={multi}
        onChange={this.handleSelectChange.bind(this)}
        options={options}
        placeholder={placeholder}
        simpleValue
        value={value}
        searchable
      />
    );
  }
}

export const EnTagSelectValidation = control(({ error, isChanged, isUsed, ...props }) => {
  if (error && isChanged && isUsed) {
    props.className = `${props.className || ''} is-invalid-input`;
  }

  return (
    <div>
      <EnTagSelect {...props} />
      {(!_.isNil(error) && !_.isEmpty(error) && isChanged, isUsed ? error : null)}
    </div>
  );
});

export default EnTagSelect;
