import React, { Fragment } from 'react';
import swal from 'sweetalert2';
import _ from 'lodash';
import moment from 'moment';
import { observer, inject } from 'mobx-react';
import EnButton from '../../../../components/form/EnButton';
import EnDropzoneDialog from '../../../../components/form/EnDropzoneDialog';
import EnDatePicker from '../../../../components/form/EnDatePicker';

export class BannerSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adBannerDropzoneOpen: false,
      adBannerWithTimerDropzoneOpen: false,
    };
  }

  openAdBannerDropzone() {
    this.setState({
      adBannerDropzoneOpen: true,
    });
  }

  closeAdBannerDropzone() {
    this.setState({
      adBannerDropzoneOpen: false,
    });
  }

  openAdBannerWithTimerDropzone() {
    this.setState({
      adBannerWithTimerDropzoneOpen: true,
    });
  }

  closeAdBannerWithTimerDropzone() {
    this.setState({
      adBannerWithTimerDropzoneOpen: false,
    });
  }

  onAddBannerWithTimerClick() {
    this.props.product.addNewBannerWithTimer();
  }

  onDeleteBannerWithTimerClick(index, e) {
    e.preventDefault();
    this.props.product.deleteBannerWithTimer(index);
  }

  onDateBannerWithTimerChange(index, key, e) {
    this.props.product.saveBannerWithTimer(index, key, e.target.value);
  }

  onBannerWithTimerImageChange(index, value) {
    this.props.product.saveBannerWithTimer(index, 'banner', value);
  }

  async onSaveAdBannerDropzone(files) {
    try {
      const result = await this.props.product.uploadProductImage(files[0]);
      this.props.product.saveInfo('adBanner', result.path);
    } catch (error) {
      swal.fire('อัพโหลดรูปไม่สำเร็จ', '', 'error');
    } finally {
      this.setState({
        adBannerDropzoneOpen: false,
      });
    }
  }

  async onSaveAdBannerWithTimerDropzone(index, files) {
    // console.log('save ad banner files ->', files);
    try {
      const result = await this.props.product.uploadProductImage(files[0]);
      this.onBannerWithTimerImageChange(index, result.path);
    } catch (error) {
      swal.fire('อัพโหลดรูปไม่สำเร็จ', '', 'error');
    } finally {
      this.setState({
        adBannerWithTimerDropzoneOpen: false,
      });
    }
  }

  renderAdBannerImage(adBanner) {
    if (!adBanner) {
      return <div className="no-ad-banner-image">ไม่มีรูป Ad Banner</div>;
    }
    return (
      <div>
        <div
          className="ad-banner-delete"
          onClick={() => {
            this.props.product.saveInfo('adBanner', null);
          }}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <img src={adBanner} className="ad-banner-img" />
      </div>
    );
  }

  renderBannerWithTimer(bannerWithTimers) {
    return (bannerWithTimers || []).map((info, index) => (
      <div className={`card-body-${index}`} key={`banner-with-timer-${index}`}>
        <div className="col-sm-12 col-md-12">
          <div className="col-sm-3 col-md-3">
            <EnDatePicker
              id="dateBanner"
              initialDate={info.dateUpload ? moment(info.dateUpload) : undefined}
              isPlaceholder="เลือกวันที่ต้องการอัพเดท Banner"
              onDateChange={(date) => {
                if (date) {
                  const value = date.format('YYYY-MM-DD');
                  this.onDateBannerWithTimerChange(index, 'dateUpload', { target: { value } });
                }
              }}
            />
          </div>
          <div className="col-sm-3 col-md-3">
            <EnDatePicker
              id="dateBanner"
              initialDate={info.dateDelete ? moment(info.dateDelete) : undefined}
              isPlaceholder="เลือกวันที่ต้องการลบ Banner"
              onDateChange={(date) => {
                if (date) {
                  const value = date.format('YYYY-MM-DD');
                  this.onDateBannerWithTimerChange(index, 'dateDelete', { target: { value } });
                }
              }}
            />
          </div>
          <EnButton className="btn-danger pull-right" style={{ marginTop: '0' }} onClick={this.onDeleteBannerWithTimerClick.bind(this, index)}>
            <i className="fa fa-trash" aria-hidden="true" />
          </EnButton>
        </div>
        <div className="col-sm-12 col-md-12">
          <div className="col-sm-3 col-md-3">
            <EnButton className="btn-default" style={{ marginLeft: '15px' }} onClick={this.openAdBannerWithTimerDropzone.bind(this)}>
              <i className="fa fa-plus btn-icon" aria-hidden="true" />
              อัพโหลดรูป Ad Banner
            </EnButton>
          </div>
          <div className="col-md-6">{this.renderAdBannerWithTimerImage(info.banner, index)}</div>
          <EnDropzoneDialog dialogTitle="กรุณาอัพโหลดรูปขนาด 960x190" open={this.state.adBannerWithTimerDropzoneOpen} onSave={this.onSaveAdBannerWithTimerDropzone.bind(this, index)} onClose={this.closeAdBannerWithTimerDropzone.bind(this)} />
        </div>
      </div>
    ));
  }

  renderAdBannerWithTimerImage(banner, index) {
    if (!banner) {
      return <div className="no-ad-banner-image">ไม่มีรูป Ad Banner</div>;
    }
    return (
      <div>
        <div
          className="ad-banner-delete"
          onClick={() => {
            this.onBannerWithTimerImageChange(index, null);
          }}>
          <i className="fa fa-times" aria-hidden="true" />
        </div>
        <img src={banner} className="ad-banner-img" />
      </div>
    );
  }

  render() {
    const { info } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-3">
            <EnButton className="btn-default" style={{ marginLeft: '15px' }} onClick={this.openAdBannerDropzone.bind(this)}>
              <i className="fa fa-plus btn-icon" aria-hidden="true" />
              อัพโหลดรูป Ad Banner
            </EnButton>
          </div>
          <div className="col-md-6">{this.renderAdBannerImage(info.adBanner)}</div>
          <EnDropzoneDialog dialogTitle="กรุณาอัพโหลดรูปขนาด 960x190" open={this.state.adBannerDropzoneOpen} onSave={this.onSaveAdBannerDropzone.bind(this)} onClose={this.closeAdBannerDropzone.bind(this)} />
        </div>
        <hr />
        <div className="row"></div>
        <EnButton className="btn-info" style={{ marginTop: '15px' }} onClick={this.onAddBannerWithTimerClick.bind(this)}>
          <i className="fa fa-plus btn-icon" aria-hidden="true" />
          เพิ่ม Ad Bannerแบบตั้งเวลา
        </EnButton>
        <div className="card" style={{ marginTop: '0px' }}>
          <div className="card-body">
            {/* <h5 class="card-title border-dark">อัพโหลดรูป Ad Bannerแบบตั้งเวลา</h5> */}
            {this.renderBannerWithTimer(info.bannerWithTimers)}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default inject('product')(observer(BannerSection));
