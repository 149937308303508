import React from 'react';
import _ from 'lodash'
import { observer, inject } from 'mobx-react';
import swal from 'sweetalert2';
import { set, orderBy, isEmpty } from 'lodash';
import config from '../../config';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import Notification from '../../components/common/Notification';
import EnToggle from '../../components/form/EnToggle';
import { isAllow } from '../../utils/permission';

const PAGE_SIZE = 20;

export class ManageEmployeePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      channel: null,
      pagination: {},
      query: {
        q: '',
        page_size: PAGE_SIZE,
        page: 1,
        type_of_work: undefined,
      },
    };
  }

  async componentWillMount() {
    try {
      if (!isAllow('VIEW_LIST_OF_EMPLOYEE_BY_USER')) {
        return;
      }
      const channelName = this.props.auth.getChannelName();
      this.setState({ channel: channelName })
      await this.props.employee.getAllEmployeeForChannel(channelName);
    } catch (err) {
      throw new Error('โหลดข้อมูลล้มเหลว');
    }
  }

  onChangeActiveToggle(index, item) {
    this.props.employee.changeActiveToggle(index, item.id, !item.isActive);
  }

  getEmployeeList() {
    const { list } = this.props.employee.toJS();
    if (list.length === 0) {
      return (
        <tr key={0}>
          <td colSpan='7' className="text-center">
            ไม่มีข้อมูล
          </td>
        </tr>
      );
    }
    const items = list.map((item, index) => {
      return (
        <tr key={item.id}>
          <td className="text-center">
            {index + 1}
          </td>
          <td>
            {item.firstnameTh + ' ' + item.lastnameTh}<br />
            {item.firstnameEn + ' ' + item.lastnameEn}
          </td>
          <td>
            {item.nicknameTh}<br />
            {item.nicknameEng}
          </td>
          <td>
            {item.phone1}{', ' + item.phone2}<br />
            {item.email}
          </td>
          <td>
            {item.position}<br />
          </td>
          <td className="text-center">
            <EnToggle
              checked={item.isActive === true}
              onChange={this.onChangeActiveToggle.bind(this, index, item)}
              disabled={!isAllow('EDIT_EMPLOYEE_STATUS')}
            />
          </td>
          <td className="text-center">
            <button
              className='btn btn-sm btn-warning'
              title='แก้ไข'
              data-tip='แก้ไข'
              onClick={this.onEditData.bind(this, item)}
              disabled={!isAllow('EDIT_EMPLOYEE_DETAILS')}
            >
              <i className='fa fa-pencil' aria-hidden='true' />
            </button>
            <button
              className='btn btn-sm btn-danger'
              title='ลบ'
              data-tip='ลบ'
              onClick={this.onDelete.bind(this, index, item)}
              disabled={!isAllow('DELETE_EMPLOYEE')}
            >
              <i className='fa fa-trash' aria-hidden='true' />
            </button>
          </td>
        </tr>
      );
    });
    return items;
  }

  onEditData(item) {
    if (item && item.id) {
      this.props.history.push(
        `${config.publicUrl}/employee/${item.id}/edit`
      );
    }
  }

  async onDelete(index, item) {
    let swalOption = {
      title: 'ลบข้อมูล',
      text: 'คุณต้องการลบ รายชื่อพนักงาน นี้ใช่หรือไม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.props.employee.deleteEmployee(item.id);
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    swal.fire(swalOption).then((result) => {
      if (result && result.value) {
        swal.fire({
          title: `ลบข้อมูลเรียบร้อย`,
          icon: 'success',
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  }

  onAddNew() {
    this.props.history.push(`${config.publicUrl}/employee/add`);
  }

  render() {
    return (
      <Container isAdmin>
        <Loader show={this.state.isLoading} />
        <Notification ref={(ref) => { this.noti = ref; }} />
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-header' data-background-color='orange'>
                <h4 className='title'>รายชื่อพนักงาน</h4>
              </div>
              <div className='card-content'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='table-responsive'>
                      <table className='table table-bordered table-hover has-pagination'>
                        <thead>
                          <tr>
                            <th width='5%' className='text-center'>ลำดับ</th>
                            <th width='20%' className='text-center'>ชื่อ-นามสกุล</th>
                            <th width='15%' className='text-center'>ชื่อเล่น</th>
                            <th width='15%' className='text-center'>ข้อมูลติดต่อ</th>
                            <th width='15%' className='text-center'>ตำแหน่ง</th>
                            <th width='10%' className='text-center'>สถานะ</th>
                            <th width='15%' className='text-center'>
                              <button
                                className="btn btn-success"
                                title="สร้างใหม่"
                                data-tip="สร้างใหม่"
                                onClick={this.onAddNew.bind(this)}
                                disabled={!isAllow('CREATE_NEW_EMPLOYEE_BY_USER')}
                              >
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>{this.getEmployeeList()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'employee')(observer(ManageEmployeePage));
