import React from 'react';
import '../../assets/css/stepprogress.css';
import { EnStepButton, EnStepArrow } from './EnStepComponent';

class EnstepProgress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: undefined,
      selectIndex: 0,
      options: [],
      doForceQuotationManagement: false,
      countRenderContent: 1
    };
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.options.length !== this.state.options.length) {
      this.setState({
        options: nextProps.options,
        doForceQuotationManagement: nextProps.doForceQuotationManagement
      }, () => {
        const { selectedItem, options, doForceQuotationManagement, countRenderContent } = this.state;
        const activeItems = this.getActive(options);
        const indexActiveItems = activeItems.length - 1;
        if (
          (selectedItem === undefined ||
          (selectedItem && selectedItem.status === activeItems[indexActiveItems] )) ||
          (doForceQuotationManagement && countRenderContent < 2)
        )
        {
          this.showContent(options[indexActiveItems], indexActiveItems);
          if (doForceQuotationManagement === true) {
            this.setState(prevState => {
              return {
                countRenderContent: prevState.countRenderContent + 1,
              }
            });
          }
        }
      });
    // }
  }

  showContent(item, index) {
    this.setState({
      selectedItem: item,
      selectIndex: index,
    });
  }

  onClickLeft(event) {
    event.preventDefault();
    this.stepEn.scrollLeft = (this.stepEn.scrollLeft || 0) - 50;
  }

  onClickRight(event) {
    event.preventDefault();
    this.stepEn.scrollLeft = (this.stepEn.scrollLeft || 0) + 50;
  }

  getActive(data) {
    const activeData = data.filter((item, index) => item.active);
    return activeData;
  }

  render() {
    const { selectedItem, selectIndex, options } = this.state;
    const jobStore = this.props.job.toJS().info;
    const { contractorMode } = this.props;
    let isContractorJob = false;
    if (jobStore) {
      isContractorJob = (!jobStore.storeId && contractorMode);
    }
    let totalStep = options.length;
    const stepItems = options.map((item, index) => {
      return <EnStepButton key={index} title={item.title} className={selectIndex === index ? 'active' : item.className} icon={item.icon} totalstep={totalStep} onClick={() => { this.showContent(item, index); }} href={item.url ? item.url : ''} />;
    });

    return (
      <div className="step-container" id={this.props.jobId}>
        <div>
          {(this.props.showJobStep && !isContractorJob) && (
            <div>
              <EnStepArrow leftIcon="fa fa-chevron-left" rightIcon="fa fa-chevron-right" leftEvent={this.onClickLeft.bind(this)} rightEvent={this.onClickRight.bind(this)} />
              <div className="status-progress" ref={e => { this.stepEn = e; }}>
                <div className="col-xs-12">
                  <ul className="nav nav-tabs process-model more-icon-preocess">
                    {stepItems}
                  </ul>
                </div>
              </div>
            </div>
          )}
          <div className="tab-content">
            {selectedItem && selectedItem.content()}
          </div>
        </div>
      </div>
    );
  }
}

export default EnstepProgress;
