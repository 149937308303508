import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import SideBar from './SideBar';
import NavBar from './NavBar';

export class Container extends React.Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { isAdmin, hideSidebar } = this.props;

    let contenWithSideBar = (
      <div className="main-panel p4-main">
        <NavBar />
        <div className="content">
          <div className="container-fluid">
            {this.props.children}
          </div>
        </div>
      </div>
    );

    let contentWithoutSideBar = (
      <div className="content" style={{ marginTop: 0 }}>
        <div className="container-fluid">
          {this.props.children}
        </div>
      </div>
    );

    return (
      <div>
        {
          !hideSidebar && <SideBar isAdmin={isAdmin} />
        }
        {
          hideSidebar ? contentWithoutSideBar : contenWithSideBar
        }
      </div>
    );
  }
}

export default withRouter(inject()(observer(Container)));
