import React from 'react';
import { observer, inject } from 'mobx-react';
import { FormValidation, required } from '../../../components/form/FormValidation';
import EnButton from '../../../components/form/EnButton';
import EnDropDown from '../../../components/form/EnDropDown';
import EnTextAreaFormGroup from '../../../components/form/EnTextAreaFormGroup';
import FormGroup from '../../../components/form/FormGroup';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnStickyFooter from '../../../components/form/EnStickyFooter';
import Notification from '../../../components/common/Notification';
import config from '../../../config';

export class location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 2,
      stepName: 'ระบุที่อยู่',
    };
  }

  async onProvinceChanged(e) {
    let id = e.target.value;
    this.props.contractor.saveInfo('provinceId', id);
    await this.props.misc.getDistrictByCode(id);
    await this.saveDistrict();
    await this.saveSubDistrict();
  }

  async saveDistrict() {
    let miscStore = this.props.misc.toJS();
    let miscData = miscStore.miscData;
    let districtId = miscData.district[0].code;

    await this.props.misc.getSubDistrictByCode(districtId);
    this.props.contractor.saveInfo('districtId', districtId);
  }

  async saveSubDistrict() {
    let miscStore = this.props.misc.toJS();
    let miscData = miscStore.miscData;
    let subDistrictId = miscData.subDistrict[0].code;
    let zipCode = miscData.subDistrict[0].zipcodes[0].zipcode;
    this.props.contractor.saveMultiInfo({
      subDistrictId,
      zipCode,
    });
  }

  setDefaultValue() {
    this.props.contractor.saveMultiInfo({
      provinceId: '10',
      districtId: '1001',
      subDistrictId: '100101',
      zipCode: '10200',
    });
  }

  async onDistrictChanged(e) {
    let id = e.target.value;
    this.props.contractor.saveInfo('districtId', id);
    await this.props.misc.getSubDistrictByCode(id);
    await this.saveSubDistrict();
  }

  async onSubDistrictChanged(e) {
    let id = e.target.value;
    await this.props.contractor.saveInfo('subDistrictId', id);
  }

  saveRegisterInfo(key, e) {
    this.props.contractor.saveInfo(key, e.target.value);
  }

  validation() {
    return this.props.contractor.isValidRegisterInfo(2);
  }

  async clickNext() {
    try {
      this.props.contractor.validateInput(this)
        .then(() => {
          this.props.history.push(`${config.publicUrl}/contractors/register/workarea`);
        })
        .catch((err) => {
          this.noti.error(err.message);
        });
    } catch (err) {
      this.noti.error(err.message);
    }
  }

  async componentWillMount() {
    await this.props.misc.getAllProvince();
    await this.props.misc.getDistrictByCode('10');
    await this.props.misc.getSubDistrictByCode('1001');
    await this.setDefaultValue();
  }

  render() {
    let miscStore = this.props.misc.toJS();
    let miscData = miscStore.miscData;
    let contractorStore = this.props.contractor.toJS();
    let contractor = contractorStore.info;

    let provinceName = miscData.province.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });
    let districtName = miscData.district.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });
    let subDistrictName = miscData.subDistrict.map((item, idx) => {
      return <option key={idx} value={item.code}>{item.name}</option>;
    });

    let button = (
      <EnButton className="btn-info pull-right" onClick={this.clickNext.bind(this)}>
        <i className="fa fa-arrow-right btn-icon" aria-hidden="true" />ถัดไป
      </EnButton>
    );

    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <Notification ref={e => { this.noti = e; }} />
            <div className="col-md-offset-2 col-md-8">
              <div className="card">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">ลงทะเบียน</h4>
                  <p className="category">กรุณาระบุที่อยู่</p>
                </div>
                <div className="card-content">
                  <FormValidation ref="FormValidation">
                    <div className="row">
                      <FormGroup containerClassName="col-md-12" label="จังหวัด">
                        <EnDropDown value={contractor.provinceId} onChange={this.onProvinceChanged.bind(this)}>
                          {provinceName}
                        </EnDropDown>
                      </FormGroup>
                    </div>
                    <div className="row">
                      <FormGroup containerClassName="col-md-12" label="อำเภอ / เขต">
                        <EnDropDown value={contractor.districtId} onChange={this.onDistrictChanged.bind(this)}>
                          {districtName}
                        </EnDropDown>
                      </FormGroup>
                    </div>
                    <div className="row">
                      <FormGroup containerClassName="col-md-12" label="ตำบล">
                        <EnDropDown value={contractor.subDistrictId} onChange={this.onSubDistrictChanged.bind(this)}>
                          {subDistrictName}
                        </EnDropDown>
                      </FormGroup>
                    </div>
                    <div className="row">
                      <FormGroup containerClassName="col-md-12">
                        <EnTextFormGroup label="รหัสไปรษณีย์" value={contractor.zipCode} onChange={this.saveRegisterInfo.bind(this, 'zipCode')} validations={[required]} />
                      </FormGroup>
                    </div>
                    <div className="row">
                      <EnTextAreaFormGroup containerClassName="col-md-12" label="ที่อยู่ (บ้านเลขที่ ซอย ถนน)" rows="3" value={contractor.address} onChange={this.saveRegisterInfo.bind(this, 'address')} validations={[required]} />
                    </div>
                    <EnStickyFooter step={this.state.step} stepName={this.state.stepName} button={button} />
                  </FormValidation>
                </div>
              </div>
            </div>
          </div >
        </div>
      </div >
    );
  }
}

export default inject('contractor', 'misc')(observer(location));
