import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';
import _ from 'lodash';

export class Channel extends BaseStore {
  constructor() {
    super();
    this.observable({
      info: undefined,
      item: undefined,
      infoStore: {},
    });
  }

  initItemInfo() {
    return {
      channelName: '',
      contactChannel: '',
      creditTerm: 1,
      notification: false,
      mom: false,
      productChannel: '',
      contractorGrade: {
        a: true,
        b: true,
        c: true,
        d: false,
        new: true,
      },
      is_send_email: false,
      is_send_flexMsg: false,
      is_send_in_app_noti: false,
      is_send_lon: false,
      is_send_sms: false,
      is_show_price: false,
    };
  }

  clearItemInfo() {
    this.item = this.initItemInfo();
  }

  async getAllChannel() {
    const res = await http.get(`${config.api.sims}/v1/channels`);
    if (res && res.data && res.data.data) {
      const data = res.data.data;
      this.info = data;
      return data;
    }
  }

  mapChannelItemInfo(data) {
    this.item = {
      channelName: data.channel_name || '',
      contactChannel: data.contact_channel || '',
      creditTerm: data.credit_term || 1,
      notification: data.notification || false,
      mom: data.mom || false,
      productChannel: data.product_channel || '',
      contractorGrade: data.contractor_grade || {
        a: true,
        b: true,
        c: true,
        d: false,
        new: true,
      },
      is_send_email: _.get(data, 'is_send_email', false),
      is_send_flexMsg: _.get(data, 'is_send_flexMsg', false),
      is_send_in_app_noti: _.get(data, 'is_send_in_app_noti', false),
      is_send_lon: _.get(data, 'is_send_lon', false),
      is_send_sms: _.get(data, 'is_send_sms', false),
      is_show_price: _.get(data, 'is_show_price', false),
    };
  }

  async getChannelByName(channelName) {
    const res = await http.post(`${config.api.sims}/v1/channels/channel`, { channel: channelName });
    if (res && res.data && res.data.data) {
      const data = res.data.data;
      this.mapChannelItemInfo(data);
    }
    return this.item;
  }

  saveItemInfo(key, value) {
    this.item[key] = value;
  }

  saveContractorGrade(key, value) {
    this.item.contractorGrade[key] = value;
  }

  async createChannel() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.post(`${config.api.sims}/v1/channels`, this.item);
      if (response.status !== 200) {
        throw new Error(response.data.message);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateChannel(id, options) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.put(`${config.api.sims}/v1/channels/${id}`, this.item);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async deleteChannel(channelName) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.put(`${config.api.sims}/v1/channels/delete/${channelName}`);
      if (response.status !== 200) {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getStoreByChannel(channelName, pagination) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const response = await http.post(`${config.api.sims}/v1/channels/store/getStoreByChannel`,{
        channel: channelName,
        pagination: pagination,
      });
      if(response.status === 200) {
        this.infoStore = response.data;
        this.isLoading = false
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new Channel();
