import React, { Fragment } from 'react';
import { config } from '@fortawesome/fontawesome-svg-core';
import * as solid from '@fortawesome/free-solid-svg-icons';
import * as regular from '@fortawesome/free-regular-svg-icons';
import * as brand from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

export const IconTypes = {
  Solid: 'Solid',
  Regular: 'Regular',
  Brand: 'Brand',
};

export class FontAwesome extends React.Component {
  render() {
    const { iconName, className, iconType } = this.props;
    let icon;
    if (iconType) {
      switch (iconType) {
        case IconTypes.Regular:
          icon = regular[iconName];
          break;
        case IconTypes.Brand:
          icon = regular[iconName];
          break;
        default:
          icon = solid[iconName];
      }
    } else {
      icon = solid[iconName] || regular[iconName] || brand[iconName];
    }
    return (
      <Fragment>
        <FontAwesomeIcon icon={icon} className={className} />
      </Fragment>
    );
  }
}

export default FontAwesome;
