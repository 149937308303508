import http from '../utils/http';
import config from '../config';
import BaseStore from './BaseStore';
import _ from 'lodash'

export class TransferStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      data: undefined,
      items:[]
    });
  }

  getTransferByJob = async ({ id: jobId }) => {
    try {
      const response = await http.get(`${config.api.sims}/v1/transfer/getTransferByJob`, {
        params: {
          job_id: jobId,
        },
      });

      if (_.get(response, 'data.data.transfer')) {
        this.data = response.data.data.transfer;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  async getTransferByJobId(job) {
    try {
      const response = await http.get(`${config.api.sims}/v1/transfer/getTransferByJobId/${job.id}`);
      if (_.get(response, 'data.data.transfer')) {
        this.data = response.data.data.transfer;
      }
    } catch ({ message }) {
      throw new Error({ message });
    }
  };

  async getUploadeFiles(downloadType, channel, storeId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let url = `${config.api.sims}/v1/downloads/file?type=${downloadType || ''}&channel=${channel || ''}&storeId=${storeId || ''}`;
      const response = await http.get(url);
      if (response.status === 200 && response.data && response.data.data) {
        this.items = response.data.data;
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }


  updateTransferFile = async (data) => {
    this.isLoading = true
    try {
      const response = await http.post(`${config.api.sims}/v1/transfer/updateTransFerFile`,data);
      if (response && response.data && response.data.data) {
        this.data = response.data.data;
      }
      // this.isLoading = false
    } catch (error) {
      // this.isLoading = false
      throw new Error(error);
    } finally {
      this.isLoading = false;
    }
  }
}
export default new TransferStore();
