import humps from 'humps';
import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';
import { pick, pickBy } from 'lodash';
import qs from 'querystring'

export class Sku extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      listFileUploade:[],
      info: this.initInfo(),
      initialData: this.initInfo()
    });
  }

  initInfo() {
    return {
      code: '',
      name: {},
      typeOfWork: 0,
      costType: 0,
      wage: '',
      surveyCost: '',
      wageWithPrice: '',
      channel: '',
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  mapIdAndLabel() {
    const items = this.toJS().items;
    return (items || []).map(i => {
      return { id: i.code, label: i.name ? i.name.th : '' };
    });
  }

  async getSkuList() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/skus`);
      if (response.status === 200 && response.data) {
        this.items = humps.camelizeKeys(response.data.data);
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getSkuListPagination(query = { limit: 10, page: 1 }) {
    query = pickBy(query, (value) => !!value)
    const qstring = qs.stringify(pick(query, ['limit', 'page', 'search']))

    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/skus/pagination?${qstring}`);
      if (response.status === 200 && response.data) {
        const items = humps.camelizeKeys(response.data.items);
        this.items = items
        return Promise.resolve({
          ...response.data,
          items
        })
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
  

  async getSkuById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/skus/${id}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      return humps.camelizeKeys(response.data.data);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getSkuByType(type) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.sims}/v1/skus/type/${type}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      this.items = humps.camelizeKeys(response.data.data);
      return this.items;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getSkuForEdit(id) {
    try {
      const data = await this.getSkuById(id);
      this.initialData = data;
      this.info = data;
    } catch (error) {
      throw error;
    }
  }

  async createSku() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      const response = await http.post(`${config.api.sims}/v1/skus`, info);
      if (response.status !== 200 && response.data) {
        const { message } = response.data;
        throw new Error(message || 'เกิดข้อผิดพลาด');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateSku(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      const response = await http.put(`${config.api.sims}/v1/skus/${id}`, info);
      if (response.status !== 200 && response.data) {
        const { message } = response.data;
        throw new Error(message || 'เกิดข้อผิดพลาด');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getSkuByMultiType(id) {
    try {
      this.isLoading = true;
      if (Array.isArray(id) && id) {
        const typeOfWorkId = id.toString();
        const response = await http.get(`${config.api.sims}/v1/skus/list/${typeOfWorkId}`);
        if (response.status !== 200 && response.data) {
          throw new Error('ไม่สามารถโหลดข้อมูลได้');
        }
        return response.data.data;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
  async getUploadeFiles(downloadType, channel, storeId) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      let url = `${config.api.sims}/v1/downloads/file?type=${downloadType || ''}&channel=${channel || ''}&storeId=${storeId || ''}`;
      const response = await http.get(url);
      if (response.status === 200 && response.data && response.data.data) {
        this.listFileUploade = response.data.data;
        console.log('listFileUploade',response.data.data);
      }
    } catch (err) {
      throw err;
    } finally {
      this.isLoading = false;
    }
  }
  updateSkuFile = async (data) => {
    this.isLoading = true
    try {
      const response = await http.post(`${config.api.sims}/v1/skus/updateDataSkuFile`,data);
      if (response && response.data && response.data.data) {
        return response.data.data;
      }
      // this.isLoading = false
    } catch (error) {
      // this.isLoading = false
      throw new Error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async createSkuHistory(previousInfo, info, user = "") {
    try {
      if (
        previousInfo.surveyCost == info.surveyCost &&
        previousInfo.wage == info.wage &&
        previousInfo.wageWithPrice == info.wageWithPrice
      ) {
        return;
      }
      let requestBody = [
        {
            sku_id: info.id,
            code: info.code,
            sku_name: info.name.th,
            previous_cost: {
                survey_cost: previousInfo.surveyCost,
                wage: previousInfo.wage,
                wage_with_price: previousInfo.wageWithPrice
            },
            new_cost: {
                survey_cost: info.surveyCost,
                wage: info.wage,
                wage_with_price: info.wageWithPrice
            },
            user: user,
        }
      ];
      await http.post(`${config.api.sims}/v1/skus/history`, requestBody);
    } catch (error) {
      console.log("===>>error createSkuHistory", error);
    }
  }
}

export default new Sku();
