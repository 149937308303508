import * as _ from 'lodash';
import BaseStore from './BaseStore';
import config from '../config';
// import datetime from '../utils/datetime';
import http from '../utils/http';
// import common from '../utils/common';

export const vatRate = 7;

export class ClaimStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      isSubmitting: false,
      info: this.createDefaultInfo(),
      claims: [],
      selectedClaimIndex: 0,
    });
  }

  createDefaultInfo() {
    return {
      jobCode: '',
      rcNo: '',
      claimType: '',
      reason: '',
      resource: '',
      approveBy: '',
      createBy: '',
      type: 'individual',
      name: '',
      address: {
        no: '',
        province: {
          name: '',
          code: '',
        },
        district: {
          name: '',
          code: '',
        },
        subDistrict: {
          name: '',
          code: '',
        },
        postcode: '',
      },
      taxId: '',
      branch: '',
      phone: '',
      details: [this.createDefaultDetail()],
      remark: '',
      subTotal: 0.00,
      discountTotal: 0.00,
      netTotal: 0.00,
      // subTotalExcludeVat: 0.00,
      vat: 0.00,
      vatRate,
      netExcludeVat: 0.00,
      grandTotal: 0.00,
      paymentChannel: 'Credit and debit cards',
      paymentOther: '',
      promotionText: '',
    };
  }

  createDefaultDetail() {
    return {
      code: '',
      name: '',
      unit: '',
      qty: 1,
      price: 0.00,
      discount: 0.00,
      amount: 0.00,
    };
  }

  toAddressData(address) {
    return {
      subDistrict: address.subDistrict.name,
      subDistrictCode: address.subDistrict.code,
      district: address.district.name,
      districtCode: address.district.code,
      province: address.province.name,
      provinceCode: address.province.code,
      postCode: address.postcode,
    };
  }

  clearClaims() {
    this.claims = [];
  }

  resetSelectedClaimIndex() {
    this.selectedClaimIndex = 0;
  }

  saveAddressObject(obj) {
    this.info.address.province.name = obj.pname;
    this.info.address.province.code = obj.pcode;
    this.info.address.district.name = obj.aname;
    this.info.address.district.code = obj.acode;
    this.info.address.subDistrict.name = obj.dname;
    this.info.address.subDistrict.code = obj.dcode;
    this.info.address.postcode = obj.zcode;
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  saveAddressInfo(key, value) {
    this.info.address[key] = value;
  }

  saveDetail(index, key, value) {
    this.info.details[index][key] = value;
  }

  saveDetailAndCalculate(index, key, value) {
    const info = this.info;
    const detail = info.details[index];
    detail[key] = !isNaN(value) ? value : 0;
    detail.amount = (detail.price * detail.qty) - detail.discount;
    this.calculateTotal();
  }

  saveDiscountCoupon(value) {
    this.info.discountCoupon = value;
    this.info.grandTotal = this.info.netTotal - this.info.discountCoupon;
  }

  saveSelectedClaimIndex(index) {
    this.selectedClaimIndex = index;
    const { claims } = this.toJS();
    const claim = this.getSelectedClaim(claims, index);
    this.setClaimToInfoAndCalculate(claim);
  }

  calculateTotal() {
    const info = this.info;
    if (info.details) {
      const result = info.details.reduce((acc, cur) => {
        return {
          subTotal: cur.price ? acc.subTotal + (parseFloat(cur.qty || '1') * parseFloat(cur.price || 0)) : acc.subTotal,
          discountTotal: cur.discount ? acc.discountTotal + parseFloat(cur.discount) : acc.discountTotal,
        };
      }, { subTotal: 0.00, discountTotal: 0.00 });
      info.subTotal = result.subTotal;
      info.discountTotal = result.discountTotal;
      info.netTotal = info.subTotal - info.discountTotal;
      info.netExcludeVat = parseFloat((info.netTotal / 1.07).toFixed(2));
      info.vat = parseFloat((info.netTotal - info.netExcludeVat).toFixed(2));
      info.grandTotal = info.netTotal - (info.discountCoupon || 0);
    }
  }

  addDetail() {
    this.info.details.push(this.createDefaultDetail());
  }

  deleteDetail(index) {
    const info = this.toJS().info;
    const details = _.cloneDeep(info.details);
    details.splice(index, 1);
    this.info.details = details;
    this.calculateTotal();
  }

  async generatePdf() {
    const { info } = this.toJS();
    let body = this.toSaveData(info);
    body.job_info = info.jobInfo;
    try {
      const res = await http.post(`${config.api.sims}/v1/pdf/claim`, body);
      if (res && res.data) {
        return res.data;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  parseFloatDetails(details) {
    for (let i = 0; i < details.length; i++) {
      const detail = details[i];
      if (typeof detail.qty !== 'number') detail.qty = parseFloat(detail.qty);
      if (typeof detail.price !== 'number') detail.price = parseFloat(detail.price);
      if (typeof detail.discount !== 'number') detail.discount = parseFloat(detail.discount);
      if (typeof detail.amount !== 'number') detail.amount = parseFloat(detail.amount);
    }
  }

  getSelectedClaim(claims, index) {
    if (Array.isArray(claims) && claims.length) {
      return claims[index];
    }
    return null;
  }

  getClaimAsArray(claim) {
    return Array.isArray(claim) ? claim : claim ? [claim] : [];
  }

  setClaimToInfoAndCalculate(claim) {
    if (claim) {
      this.info.subTotal = claim.sub_total;
      this.info.discountTotal = claim.discount_total;
      this.info.vat = claim.vat;
      this.info.vatRate = claim.vat_rate;
      this.info.netExcludeVat = claim.net_exclude_vat;
      this.info.discountCoupon = claim.discount_coupon;
      this.info.grandTotal = claim.grand_total;
      this.info.remark = claim.remark;
      this.info.paymentChannel = claim.payment_channel;
      this.info.paymentOther = claim.payment_other;
      this.info.promotionText = claim.promotion_text;
      this.info.no = claim.no;
      this.info.jobCode = claim.job_code;
      this.info.rcNo = claim.rc_no;
      this.info.claimType = claim.claim_type;
      this.info.reason = claim.reason;
      this.info.resource = claim.resource;
      this.info.approveBy = claim.approve_by;
      this.info.createBy = claim.create_by;
      if (claim.items) {
        this.info.details = claim.items;
        this.calculateTotal();
      }
    }
  }

  toClaimInfo(job) {
    if (job) {
      const customerTax = job.customer_tax;
      if (job.customer_tax) {
        const { address } = customerTax;
        this.info.type = customerTax.type;
        this.info.name = customerTax.name;
        this.info.taxId = customerTax.tax_id;
        this.info.branch = customerTax.branch;
        this.info.phone = customerTax.phone;
        this.info.address.no = address.no;
        this.info.address.province.code = address.province.code;
        this.info.address.province.name = address.province.name;
        this.info.address.district.code = address.district.code;
        this.info.address.district.name = address.district.name;
        this.info.address.subDistrict.code = address.sub_district.code;
        this.info.address.subDistrict.name = address.sub_district.name;
        this.info.address.postcode = address.postcode;
      }
      if (job.claim) {
        const claim = job.claim;
        this.setClaimToInfoAndCalculate(claim);
      } else {
        this.info = this.createDefaultInfo();
      }
    }
    this.info.jobInfo = job;
    this.info.requestTax = (job && job.request_tax) ? job.request_tax : false;
  }

  toSaveData(info) {
    this.parseFloatDetails(info.details);
    return {
      request_tax: info.requestTax,
      customer_tax: {
        type: info.type,
        name: info.name,
        tax_id: info.taxId,
        branch: info.branch,
        phone: info.phone,
        address: {
          no: info.address.no,
          province: {
            code: info.address.province.code,
            name: info.address.province.name,
          },
          district: {
            code: info.address.district.code,
            name: info.address.district.name,
          },
          sub_district: {
            code: info.address.subDistrict.code,
            name: info.address.subDistrict.name,
          },
          postcode: info.address.postcode,
        },
      },
      claim: {
        job_code: info.jobCode,
        rc_no: info.rcNo,
        claim_type: info.claimType,
        reason: info.reason,
        resource: info.resource,
        approve_by: info.approveBy,
        create_by: info.createBy,
        items: info.details,
        sub_total: info.subTotal,
        discount_total: info.discountTotal,
        net_total: info.netTotal,
        // sub_total_exclude_vat: info.subTotalExcludeVat,
        vat: info.vat,
        vat_rate: vatRate,
        net_exclude_vat: info.netExcludeVat,
        discount_coupon: info.discountCoupon,
        grand_total: info.grandTotal,
        remark: info.remark,
        payment_channel: info.paymentChannel,
        payment_other: info.paymentOther,
        no: info.no,
        promotion_text: info.promotionText,
      },
    };
  }

  async loadClaimByJobId(jobId) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const res = await http.get(`${config.api.sims}/v1/jobs/${jobId}`);
      if (res && res.data && res.data.data) {
        const { data } = res.data;
        this.toClaimInfo(data);
      } else {
        throw new Error('โหลดข้อมูลล้มเหลว');
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      this.isLoading = false;
    }
  }

  async saveClaim(jobId) {
    if (this.isSubmitting) return;
    this.isSubmitting = true;

    const { info } = this.toJS();
    const body = this.toSaveData(info);
    try {
      const res = await http.put(`${config.api.sims}/v1/jobs/${jobId}/claimdoc`, body);
      this.isSubmitting = false;
      if (res && res.data) {
      }
    } catch (error) {
      this.isSubmitting = false;
      throw new Error(error);
    }
  }
}

export default new ClaimStore();
