import React from 'react';
import { observer, inject } from 'mobx-react';
import querystring from 'query-string';
import Loader from '../../../components/common/Loader';
import config from '../../../config';
import history from '../../../utils/history';
import swal from 'sweetalert2';
import NumberDisplay from '../../../components/pad/NumberDisplay';
import NumberButton from '../../../components/pad/NumberButton';
import FuncButton from '../../../components/pad/FuncButton';
import '../../../assets/css/phone-pad.css';
import { KEY_NUMBERS } from '../../../utils/common';

export class PinConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: '',
    };
  }

  savePin = (pin) => {
    this.setState({
      pin: pin,
    });
  }

  goToLandingPage() {
    history.push(`${this.props.location.search}`);
  }

  async submitPin() {
    if (!this.state.pin) throw new Error('กรุณาระบุรหัสผ่าน');

    const parsed = querystring.parse(this.props.location.search);
    let params = new URLSearchParams(parsed);
    let ctrUuid = params.get('ctr_uuid');
    let trainingUuid = params.get('training_dt_uuid');

    try {
      let result = await this.props.trainingcourse.confirmAdmin(this.state.pin, ctrUuid, trainingUuid);
      console.log(`result-- ${result}`);

      if (result) {
        swal.fire({
          title: 'ช่างถูกอนุมัติเข้าระบบแล้ว',
          icon: 'success',
          confirmButtonText: 'ตกลง',
          timer: 5000,
        }).then((result) => {
          console.log(result.value);
          if (result.value) {
            history.replace(`${config.publicUrl}/buddy/training/pin`);
          }
        });
      }
    } catch (error) {
      console.log(`error-- ${error} `);
      swal.fire({
        title: error,
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        timer: 5000,
      }).then((result) => {
        console.log(result.value);
        // if (result.value) {
        //   history.replace(`${config.publicUrl}/buddy/training/pin`);
        // }
      });
    }
  }

  getPinPad() {
    return (
      <Pin
        title="กรุณากดรหัสผ่าน"
        pin={this.state.pin}
        limitDisplayNumber={6}
        savePin={this.savePin}
        hidePressBack={true}
        onPressSubmit={this.submitPin.bind(this)} />
    );
  }

  render() {
    const course = this.props.trainingcourse.toJS();
    return (
      <div>
        <Loader show={course.isLoading} />
        {this.getPinPad()}
      </div>
    );
  }
}

class Pin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.pin ? props.pin : '',
      displayValue: this.getHideNumber(props.pin),
      limitDisplayNumber: props.limitDisplayNumber,
      title: props.title,
    };
  }

  getHideNumber(num) {
    let result = '';
    if (num && num.length > 0) {
      for (let i = 0; i < num.length; i++) {
        result = result + '*';
      }
    }
    return result;
  }

  onClear() {
    this.props.savePin('');
    this.setState({
      value: '',
      displayValue: '',
    });
  }

  onDeleteLast() {
    const { value, displayValue } = this.state;
    if (!value || !value.length) {
      return;
    }

    const newValue = value.substring(0, value.length - 1) || '';
    const newDisplayValue = displayValue.substring(0, displayValue.length - 1) || '';
    this.props.savePin(newValue);
    this.setState({
      value: newValue,
      displayValue: newDisplayValue,
    });
  }

  onPressNumber = (num) => {
    const { value, displayValue, limitDisplayNumber } = this.state;
    if (value && value.length >= limitDisplayNumber) {
      return;
    }

    const newValue = value === '' ? num : value + num;
    const newDisplayValue = value === '' ? '*' : displayValue + '*';
    this.props.savePin(newValue);
    this.setState({
      value: newValue,
      displayValue: newDisplayValue,
    });
  }

  handleKeyDown = (e) => {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) {
      const num = KEY_NUMBERS[`${e.keyCode}`];
      this.onPressNumber(num);
    } else if (e.keyCode === 8) {
      // backspace
      this.onDeleteLast();
    } else if (e.keyCode === 27) {
      // esc
      this.onClear();
    } else if (e.keyCode === 13) {
      // enter
      this.props.onPressSubmit();
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    const { title, displayValue, limitDisplayNumber } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-md-offset-4 phone">
            <div className="row contractor-signin-logo">
              {
                // <p className="col-md-12 text-center phone-title">เข้าสู่ระบบ</p>
              }
              <img src="https://storage.googleapis.com/prod-qchang-v1/static/images/logo-new-full.png" alt="คิวช่าง" />
            </div>

            <div className="row">
              <div className="col-md-12">
                <NumberDisplay placeHolder={title} value={displayValue} size={limitDisplayNumber} />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-center">
                <div className="row">
                  <div className="col-md-3 col-md-offset-1">
                    <NumberButton text="1" onPress={() => this.onPressNumber('1')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="2" onPress={() => this.onPressNumber('2')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="3" onPress={() => this.onPressNumber('3')} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 col-md-offset-1">
                    <NumberButton text="4" onPress={() => this.onPressNumber('4')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="5" onPress={() => this.onPressNumber('5')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="6" onPress={() => this.onPressNumber('6')} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 col-md-offset-1">
                    <NumberButton text="7" onPress={() => this.onPressNumber('7')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="8" onPress={() => this.onPressNumber('8')} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="9" onPress={() => this.onPressNumber('9')} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 col-md-offset-1">
                    <FuncButton className="fa fa-eraser" onPress={() => this.onClear()} />
                  </div>
                  <div className="col-md-3">
                    <NumberButton text="0" onPress={() => this.onPressNumber('0')} />
                  </div>
                  <div className="col-md-3">
                    <FuncButton className="fa fa-times" onPress={() => this.onDeleteLast()} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div id="confirm" onClick={this.props.onPressSubmit} className="btn btn-warning btn-block flatbtn action-btn">
                  <i className="fa fa-sign-in btn-icon" aria-hidden="true" />เข้าสู่ระบบ
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('trainingcourse')(observer(PinConfirm));
