import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { CustomCellWrapper, CustomCalendarEvent, CustomEndAccessor, CustomEventView } from '../calendar/CustomCalendar';
import Notification from '../../components/common/Notification';
import EnDropDown from '../../components/form/EnDropDown';
import Calendar from '../../components/common/Calendar';
import FormGroup from '../../components/form/FormGroup';
import { TypeOfJobs } from '../../utils/data';
import moment from 'moment';
import config from '../../config';

export class AdminViewContractorCalendar extends React.Component {
  constructor(props) {
    super(props);
    let currentDate = moment();
    this.state = {
      selectedDate: undefined,
      currentDate: currentDate,
      id: this.props.id,
      jobType: 'S',
      showCalendar: true,
    };
  }

  keepStateFilter() {
    let filter = {
      date: moment(this.state.currentDate).format('YYYY-MM'),
      jobType: this.state.jobType,
    };
    if (this.state.contractorId) {
      filter.contractorId = this.state.contractorId;
    }
    this.props.calendar.setCalendarFilter(filter);
  }

  customCellWrapper(obj) {
    let calendar = this.props.calendar.toJS();
    return CustomCellWrapper(obj, calendar.holidays, calendar.vacation);
  }

  changeQueryString = () => {
    let props = {
      jobType: this.state.jobType,
    };

    if (this.state.currentDate) {
      props.currentDate = moment(this.state.currentDate).format('YYYY-MM');
    }
    this.props.changeQueryString(props);
  }

  onJobTypeChanged(e) {
    let jobType = e.target.value;
    this.setState({ jobType: jobType }, () => {
      this.changeQueryString();
      this.loadData();
      this.keepStateFilter();
    });
  }

  async onMonthNext(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
      this.keepStateFilter();
    });
  }

  async onMonthBack(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
      this.keepStateFilter();
    });
  }

  async onDateCurrent(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
    });
  }

  async onMoveMonth(date) {
    this.setState({ currentDate: date }, () => {
      this.changeQueryString();
      this.loadData();
      this.keepStateFilter();
    });
  }

  loadData() {
    try {
      const startDate = moment(this.state.currentDate).startOf('month').subtract(5, 'days');
      const endDate = moment(this.state.currentDate).endOf('day').add(2, 'months');
      const objFilter = {
        startDate,
        endDate,
        contractorId: this.state.id,
        jobType: this.state.jobType,
      };
      const typeOfWork = this.props.typeofwork.toJS();
      this.props.calendar.getContractorCalendar(objFilter, typeOfWork);
    } catch (error) {
      this.noti.error(error.message, 'โหลดข้อมูลล้มเหลว');
    }
  }

  async componentDidMount() {
    await this.props.typeofwork.getTypeOfWorks();
    this.loadData();
    this.keepStateFilter();
  }

  onJobDetail = (id) => {
    this.keepStateFilter();
    this.props.history.push(`${config.publicUrl}/contractors/${this.state.id}/calendar/jobs/${id}`);
  }

  handleToggleSearchResult = (showResult) => {
    this.setState({
      showCalendar: !showResult,
    });
  }

  render() {
    let calendar = this.props.calendar.toJS();
    let contractor = this.props.contractor.toJS().info;
    let events = _.concat(calendar.vacation, calendar.holidays, calendar.jobs);
    let typeOfJobs = TypeOfJobs.map((item, idx) => {
      return <option key={idx} value={item.id}>{item.label}</option>;
    });
    typeOfJobs = typeOfJobs.slice(1);

    return (
      <div>
        {contractor.isAvailable === false &&
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-danger col-md-4" role="alert" >
                <i className="fa fa-coffee fa-2" aria-hidden="true" style={{ marginRight: '5px' }} />
                ช่างปิดปฎิทิน ไม่พร้อมรับงาน { 
                  contractor.isAvailableUpdateDate ?
                  moment(contractor.isAvailableUpdateDate).utcOffset(7).format('DD/MM/YYYY HH:mm:ss') + ' น.' :
                  ''
                }
              </div>
            </div>
          </div>
        }
        <div className="card item-card">
          <div className="card-content">
            <FormGroup label="งานต่อวัน">
              <div style={{ padding: '15px 22px' }} className="card-content">
                <div className="row">สำรวจ</div>
                <div className="col-md-12 col-xs-12" >
                  <div className="col-md-2 col-xs-6" >
                    เช้า  <b style={{ fontSize: '25px' }}>{contractor.surveyPerDay.morning}</b> งาน
                  </div>
                  <div className="col-md-2 col-xs-6" >
                    บ่าย <b style={{ fontSize: '25px' }}>{contractor.surveyPerDay.afternoon}</b> งาน
                  </div>
                </div>
                <div className="row">ติดตั้ง</div>
                <div className="col-md-12 col-xs-12" >
                  <div className="col-md-2 col-xs-6">
                    เช้า  <b style={{ fontSize: '25px' }}>{contractor.installationPerDay.morning}</b> งาน
                  </div>
                  <div className="col-md-2 col-xs-6" >
                    บ่าย <b style={{ fontSize: '25px' }}>{contractor.installationPerDay.afternoon}</b> งาน
                  </div>
                </div>
              </div>
            </FormGroup>
          </div>
        </div>
        <div className="card-content">
          <div className="row row-calendar">
            <Notification ref={(ref) => { this.noti = ref; }} />
            <div className="col-md-12">
              <div className="card">
                <FormGroup>
                  <EnDropDown id="type-of-job" value={this.state.jobType} onChange={this.onJobTypeChanged.bind(this)}>
                    {typeOfJobs}
                  </EnDropDown>
                </FormGroup>
                {
                  this.state.showCalendar &&
                  <Calendar
                    events={events}
                    date={moment(this.state.currentDate).toDate()}
                    endAccessor={CustomEndAccessor}
                    eventWrapper={CustomCalendarEvent}
                    eventView={CustomEventView}
                    cellWrapper={this.customCellWrapper.bind(this)}
                    customNext={this.onMonthNext.bind(this)}
                    customBack={this.onMonthBack.bind(this)}
                    customCurrent={this.onDateCurrent.bind(this)}
                    customMoveMonth={this.onMoveMonth.bind(this)} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('contractor', 'calendar', 'typeofwork')(observer(AdminViewContractorCalendar));
