import React from 'react';
import './search.css';

class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  genCardColor = (data) => {
    if (data.status === 'created') {
      return '#e0faff';
    } else if (data.status === 'claim') {
      return '#EF4E30'
    } else if (!data.is_paid) {
      return '#D0B3E1';
    } else {
      return '#8be28b';
    }
  }

  genCardHeader = (data) => {
    if (data.status === 'created' && data.type_of_job === 'S') {
      return 'งานสำรวจ ( รอนัดหมายช่าง )';
    } else if (data.status === 'created' && data.type_of_job === 'I') {
      return 'งานติดตั้ง ( รอนัดหมายช่าง )';
    } else if (data.contractor_id !== null && data.type_of_job === 'I' && data.status !== 'installation_requested') {
      return 'งานติดตั้ง';
    } else if (data.contractor_id !== null && data.type_of_job === 'I' && data.status === 'installation_requested') {
      return 'งานติดตั้ง ( รอยืนยัน )';
    } else if (data.contractor_id !== null && data.type_of_job === 'S') {
      return 'งานสำรวจ';
    }
  }

  genJobId = (data) => {
    if (data.job_code) {
      return data.job_code;
    } else {
      return data._id;
    }
  }

  /**
   * Retrieves the href for an anchor tag that, when clicked, opens a link in a new tab.
   * The href is obtained from the `getHref` prop, which should be a function.
   */
  getHref = ({ id, status, isActive }) => {
    const { getHref } = this.props;
    if (getHref) {
      return getHref({ id, status, isActive });
    }
    return undefined;
  }

  genCard = (results) => {
    return results.map((r, index) => {
      return (
        <div key={index} className="col-md-4 col-sm-6 col-xs-12 job-content">
          <a href={this.getHref({ id: r._id, status: r.status, isActive: r.is_active })} target="_blank">
            <div className="card  bg-secondary mb-3 user-select-none">
              <div className="card-header" id={`${r.type_of_job}_${r.customer_email}`} style={{ backgroundColor: this.genCardColor(r) }}>
                {this.genCardHeader(r)}</div>
              <ul className="list-group list-group-flush">
                <li className="list-group-item ellipsis adjust-flex" title={this.genJobId(r)}>รหัสงาน : {this.genJobId(r)} 
                  {r.installment && (<span className='split-pay'>งวดที่ {r.installment}</span>)}
                </li>
                <li className="list-group-item ellipsis" title={`${r.customer_firstname} ${r.customer_lastname}`} >ชื่อ : {r.customer_firstname} {r.customer_lastname}</li>
                <li className="list-group-item">เบอร์โทร : {r.customer_phone}</li>
                <li className="list-group-item ellipsis" title={r.typeName}>งาน : {r.typeName}</li>
                <li className="list-group-item ellipsis" title={r.date}>วันที่ : {r.date}</li>
              </ul>
            </div>
          </a >
        </div >
      );
    });
  }

  render() {
    let { results } = this.props;
    return (
      <div className="job" >
        {this.genCard(results)}
      </div>);
  }
}

export default SearchResult;
