import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import EnButton from '../form/EnButton';
import { observer, inject } from 'mobx-react';

class ModalReasonsWithEtc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      valueSelectElm: _.get(this.props, 'reasons.0.text'),
      reason: _.get(this.props, 'reasons.0.text'),
      optionReasonForInput: this.props.reasons.find(reason => reason.isReasonInput === true),
      showInputOtherReason: false
    };
  }

  onChangeSelect(e) {
    this.setState({
      valueSelectElm: e.target.value,
      showInputOtherReason: e.target.value === this.state.optionReasonForInput.text
    }, () => {
      if (this.state.showInputOtherReason) {
        this.setState({ reason: "" });
      } else {
        this.setState({ reason: this.state.valueSelectElm });
      }
    })
  }

  onChangeInput(e) {
    this.setState({ reason: e.target.value });
  }

  saveModal() {
    this.props.onSave(this.state);
    this.props.modal.setDisplayModalName(false);
  }

  closeModal() {
    const notOptionReason = this.props.reasons.find(reason => reason.notOption === true);
    if (notOptionReason) {
      this.setState({ reason: notOptionReason, showInputOtherReason: false });
    }
    this.props.onCancel();
    this.props.modal.setDisplayModalName(false);
  }

  isNotOption() {
    const selectedReason = this.props.reasons.find(reason => reason.text === this.state.reason);
    return !!_.get(selectedReason, 'notOption');
  }

  render() {
    const modalStore = this.props.modal.toJS();
    return (
      <Modal
        show={this.props.modalName === modalStore.displayModalName}
        onHide={() => this.closeModal()}
      >
        <div className="position-modal-center">
          <h3>{this.props.title}</h3>
          <select style={{ width: "75%" }} className="swal2-input" onChange={this.onChangeSelect.bind(this)}>
            {this.props.reasons.map((reason, index) =>
              <option key={index} value={reason.text} selected={reason.notOption || false} disabled={reason.notOption}>{reason.text}</option>
            )}
          </select>
          {
            this.state.showInputOtherReason &&
            <div style={{ width: "75%", margin: "0 auto" }}>
              <input maxLength={100} className="swal2-input" onChange={this.onChangeInput.bind(this)} />
              <div style={{ textAlign: "right" }}>ไม่เกิน 100 ตัวอักษร</div>
            </div>
          }
          <div style={{ marginTop: "3vw" }}>
            <EnButton disabled={!(_.get(this.state, 'reason.length') >= 5) || this.isNotOption()} className="btn-info" onClick={this.saveModal.bind(this)}>
              ยืนยัน
            </EnButton>
            <EnButton className="btn-danger" onClick={this.closeModal.bind(this)}>
              ยกเลิก
            </EnButton>
          </div>
        </div>
      </Modal>
    )
  }
}

ModalReasonsWithEtc.defaultProps = {
  reasons: [
    {
      text: "กรุณาระบุเหตุผล",
      notOption: true
    },
    {
      text: "ลูกค้าระบุทีมช่างเข้าให้บริการ"
    },
    {
      text: "ลูกค้าขอเปลี่ยนแปลงที่อยู่รับบริการ"
    },
    {
      text: "ต้องการรวมทีมเข้าหน้างานเพียงทีมเดียว"
    },
    {
      text: "ลูกค้าขอเลื่อนวัน"
    },
    {
      text: "ทีมช่างโดนพักงาน"
    },
    {
      text: "ทีมช่างขอยกเลิกงาน"
    },
    {
      text: "เป็นงานต่อเนื่องของช่างทีมเก่าอื่นๆ"
    },
    {
      text: "ไม่มีทีมช่าง"
    },
    {
      text: "ช่างปฏิเสธงาน"
    },
    {
      text: "งานถูก Auto-reject"
    },
    {
      text: "อื่นๆ",
      isReasonInput: true,
      inputValue: ""
    }
  ]
};

ModalReasonsWithEtc.propTypes = {
  title: PropTypes.string,
  modalName: PropTypes.string,
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      isReasonInput: PropTypes.bool,
      inputValue: PropTypes.string,
    })
  ).isRequired,
};

export default inject('modal')(observer(ModalReasonsWithEtc));