import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import numeral from 'numeral';
import moment from 'moment';
import MobileDetect from 'mobile-detect';
import { Checkbox } from 'react-bootstrap/lib';
import Container from '../../layouts/Container';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import { FormValidation, required, taxid, branchcode } from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import EnTextAreaFormGroup from '../../components/form/EnTextAreaFormGroup';
import EnButton from '../../components/form/EnButton';
import EnDropDown from '../../components/form/EnDropDown';
import AddressForm from '../../components/address/AddressForm';
import FormGroup from '../../components/form/FormGroup';
import history from '../../utils/history';
import config from '../../config';
import * as _ from 'lodash';
import authStore from '../../stores/AuthStore';

export class ClaimForm extends React.Component {
  constructor(props) {
    super(props);
    const md = new MobileDetect(window.navigator.userAgent);
    this.state = {
      jobId: this.props.match.params.id,
      pdf: undefined,
      isMobileOrTablet: !!(md.mobile() || md.tablet()),
      requestTax: false,
      paymentChannel: '',
    };
  }

  async componentWillMount() {
    this.props.claim.clearClaims();
    this.props.claim.resetSelectedClaimIndex();
    await this.props.claim.loadClaimByJobId(this.state.jobId);
  }

  validateForm(info) {
    const paymentOtherError = info.paymentChannel === 'others' ? required(info.paymentOther) : null;

    if (!info.requestTax && !paymentOtherError) {
      return true;
    }
    const nameError = required(info.name);
    const taxIdError = required(info.taxId);
    const branchError = info.type === 'juristic' ? required(info.branch) : null;
    const { address } = info;
    const addrNoError = required(address.no);
    const provinceError = required(address.province.name);
    const districtError = required(address.district.name);
    const subDistrictError = required(address.subDistrict.name);
    const postcodeError = required(address.postcode);
    return !nameError &&
      !taxIdError &&
      !branchError &&
      !addrNoError &&
      !provinceError &&
      !districtError &&
      !subDistrictError &&
      !postcodeError &&
      !paymentOtherError;
  }

  async onSubmit(e) {
    e.preventDefault();
    // const isFormValid = this.frm.isValid();
    const info = this.props.claim.toJS().info;
    if (await this.props.job.showJobUpdatedDialog(info.jobInfo)) {
      return;
    }
    const isFormValid = this.validateForm(info);
    if (!isFormValid) {
      swal.fire({
        icon: 'error',
        title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        confirmButtonText: 'ตกลง',
      });
      return;
    }

    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไขใบเคลม' : 'กรุณายืนยันการสร้างใบเคลม',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const data = await this.props.claim.saveClaim(this.state.jobId);
          return data;
        } catch (error) {
          swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          return false;
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      this.onCancel();
    }
  }

  onCancel() {
    const isAdmin = authStore.getStore();
    if (!_.isNil(isAdmin) && !_.isEmpty(isAdmin)) {
      history.push(`${config.publicUrl}/calendar/admin/jobs/${this.state.jobId}`);
    } else {
      history.push(`${config.publicUrl}/superadmin/jobs/${this.state.jobId}`);
    }
  }

  async onPreview() {
    try {
      const pdf = await this.props.claim.generatePdf();
      this.setState({
        pdf,
      });
    } catch (error) {
      console.log(error);
    }
  }

  onInfoChanged(key, e) {
    if (e.target) {
      this.props.claim.saveInfo(key, e.target.value);
    }
  }

  onAddressNoChanged(e) {
    if (e.target) {
      this.props.claim.saveAddressInfo('no', e.target.value);
    }
  }

  onAddressChanged(addressObject) {
    this.props.claim.saveAddressObject(addressObject);
  }

  onTypeChanged(e) {
    if (e.target) {
      this.props.claim.saveInfo('type', e.target.value);
    }
  }

  setRequestTaxClaim(e) {
    if (e.target) {
      this.props.claim.saveInfo('requestTax', e.target.checked);
      this.setState({
        requestTax: e.target.checked,
      });
    }
  }

  onColumnInputChanged(index, key, e) {
    if (e.target) {
      this.props.claim.saveDetail(index, key, e.target.value);
    }
  }

  onInputChangedAndCalculate(index, key, e) {
    if (e.target) {
      const value = !isNaN(e.target.value) ? e.target.value : 0.00;
      this.props.claim.saveDetailAndCalculate(index, key, value);
    }
  }

  onDiscountCouponChanged(e) {
    if (e.target) {
      const value = e.target.value && !isNaN(e.target.value) ? parseFloat(e.target.value) : 0;
      this.props.claim.saveDiscountCoupon(value);
    }
  }

  onInputNumberFocus(e) {
    if (e.target) {
      e.target.select();
    }
  }

  onInputNumberClick(e) {
    if (e.target) {
      e.target.select();
    }
  }

  onChangePaymentChannel(key, e) {
    if (e.target) {
      let value = e.target.value;
      this.props.claim.saveInfo(key, value);
      if (key === 'paymentChannel') {
        this.setState({
          paymentChannel: value,
        });
      }
    }
  }

  onSelectClaimChange(e) {
    this.props.claim.saveSelectedClaimIndex(e.target.value);
  }

  onAddRow() {
    this.props.claim.addDetail();
  }

  onDeleteRow(index, e) {
    this.props.claim.deleteDetail(index);
  }

  displayTableRow(details) {
    return (details || []).map((d, index) => {
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td>
            <input
              type="text"
              id={`code-${index}`}
              name={`code-${index}`}
              value={d.code}
              size="15"
              onChange={this.onColumnInputChanged.bind(this, index, 'code')}
            />
          </td>
          <td>
            <input
              type="text"
              id={`name-${index}`}
              name={`name-${index}`}
              value={d.name}
              size="40"
              onChange={this.onColumnInputChanged.bind(this, index, 'name')}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`unit-${index}`}
              name={`unit-${index}`}
              className="text-center"
              size="4"
              value={d.unit}
              onChange={this.onColumnInputChanged.bind(this, index, 'unit')}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`qty-${index}`}
              name={`qty-${index}`}
              className="text-right"
              size="4"
              value={d.qty}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'qty')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
            />
          </td>
          <td className="text-right">
            <input type="text"
              id={`price-${index}`}
              name={`price-${index}`}
              className="text-right"
              size="10"
              value={d.price}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'price')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
            />
          </td>
          <td className="text-right">
            <input
              type="text"
              id={`discount-${index}`}
              name={`discount-${index}`}
              className="text-right"
              size="10"
              value={d.discount}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'discount')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
            />
          </td>
          <td className="text-right">
            {numeral(d.amount || 0).format('0,0.00')}
          </td>
          <td className="text-center">
            {
              (details.length > 1) && (
                <EnButton
                  className="btn-primary btn-xs"
                  style={{ padding: '5px 10px', marginTop: '0' }}
                  onClick={this.onDeleteRow.bind(this, index)}>
                  <i className="fa fa-trash" aria-hidden="true" />
                </EnButton>
              )
            }
          </td>
        </tr>
      );
    });
  }

  saveInfo(key, e) {
    this.props.claim.saveInfo(key, e.target.value);
  }

  displayCustomerInfo(data) {
    const {
      customer_firstname: firstname,
      customer_lastname: lastname,
      address_info: addressInfo,
      customer_address: addressNo,
      customer_phone: phone,
    } = data;
    const { district, province, sub_district, post_code: postcode } = addressInfo;
    const address = `${addressNo} ${sub_district.name} ${district.name} ${province.name} ${postcode}`;
    return (
      <div>
        <div className="row">
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="ชื่อ"
            value={firstname}
            disabled
          />
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="นามสกุล"
            value={lastname}
            disabled
          />
        </div>
        <div className="row">
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="ที่อยู่"
            value={address}
            disabled
          />
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="โทรศัพท์"
            value={phone}
            disabled
          />
        </div>
      </div>
    );
  }

  render() {
    const claimStore = this.props.claim.toJS();
    const { info } = claimStore;
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={claimStore.isLoading || claimStore.isSubmitting} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="card">
            <div className="col-md-12">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">แบบฟอร์มการเคลม</h4>
                {/* <p className="category">{(info && info.jobInfo && info.jobInfo.type_of_job === 'S') ? 'ค่าสำรวจ' : 'ค่าติดตั้ง'}</p> */}
              </div>
              <div className="card-content">
                {info && info.jobInfo && (
                  this.displayCustomerInfo(info.jobInfo)
                )}
                <div className="row">
                  <EnTextFormGroup
                    id="jobCode"
                    containerClassName="col-md-6"
                    label="เลข Job Code เดิม"
                    value={info.jobCode}
                    onChange={this.saveInfo.bind(this, 'jobCode')}
                  />
                  <EnTextFormGroup
                    id="rcNo"
                    containerClassName="col-md-6"
                    label="เลขที่ใบเสร็จเดิม"
                    value={info.rcNo}
                    onChange={this.saveInfo.bind(this, 'rcNo')}
                  /></div>
                <div className="row">
                  <EnTextFormGroup
                    id="claimType"
                    containerClassName="col-md-6"
                    label="ประเภทการเคลม"
                    value={info.claimType}
                    onChange={this.saveInfo.bind(this, 'claimType')}
                  />
                  <EnTextFormGroup
                    id="reason"
                    containerClassName="col-md-6"
                    label="สาเหตุการเคลม"
                    value={info.reason}
                    onChange={this.saveInfo.bind(this, 'reason')}
                  />
                </div>
                <div className="row">
                  <EnTextFormGroup
                    id="resource"
                    containerClassName="col-md-6"
                    label="แหล่งที่มาทรัพยากรการเคลม"
                    value={info.resource}
                    onChange={this.saveInfo.bind(this, 'resource')}
                  />
                </div>
                <div className="row">
                  <EnTextFormGroup
                    id="approveBy"
                    placeholder="ชือ นามสกุล ตำแหน่ง"
                    containerClassName="col-md-12"
                    label="ผู้อนุมัติ"
                    value={info.approveBy}
                    onChange={this.saveInfo.bind(this, 'approveBy')}
                  />
                </div>
                <div className="row">
                  <EnTextFormGroup
                    id="createBy"
                    placeholder="ชือ นามสกุล ตำแหน่ง"
                    containerClassName="col-md-12"
                    label="ผู้ออกเอกสาร"
                    value={info.createBy}
                    onChange={this.saveInfo.bind(this, 'createBy')}
                  />
                </div>

                <FormValidation ref={(el) => {
                  this.frm = el;
                }}>
                  <div className="row" />
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-hover col-md-12">
                          <thead>
                            <tr>
                              <th width="5%" className="text-center">#</th>
                              <th width="15%" className="text-center">รหัสสินค้า</th>
                              <th width="20%" className="text-center">รายการสินค้า</th>
                              <th width="10%" className="text-center">หน่วย</th>
                              <th width="10%" className="text-center">จำนวน</th>
                              <th width="10%" className="text-center">ราคา</th>
                              <th width="10%" className="text-center">ส่วนลด</th>
                              <th width="10%" className="text-right">จำนวนเงิน</th>
                              <th className="text-center">&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.displayTableRow(info.details)}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="9">
                                <EnButton
                                  className="btn-success btn-xs"
                                  style={{ padding: '5px 10px' }}
                                  onClick={this.onAddRow.bind(this)}>
                                  <i className="fa fa-plus" aria-hidden="true" />
                                </EnButton>
                              </td>
                            </tr>
                            <tr>
                              <td rowSpan="7" colSpan="5">
                                <EnTextAreaFormGroup
                                  id="remark"
                                  label="หมายเหตุ"
                                  rows="5"
                                  value={info.remark}
                                  onChange={this.onInfoChanged.bind(this, 'remark')}
                                />
                              </td>
                              <th colSpan="2">
                                รวมจำนวนเงิน
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.subTotal).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                รวมส่วนลด
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.discountTotal).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                จำนวนเงินสุทธิ
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.netTotal).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                ราคาสินค้า/บริการ
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.netExcludeVat).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                ภาษีมูลค่าเพิ่ม {info.vatRate}%
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.vat).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                ส่วนลดคูปอง
                              </th>
                              <td colSpan="2" className="text-right">
                                <input
                                  type="text"
                                  id="discount-coupon"
                                  name="discount-coupoon"
                                  value={info.discountCoupon}
                                  className="text-right"
                                  size="10"
                                  onChange={this.onDiscountCouponChanged.bind(this)}
                                  onFocus={this.onInputNumberFocus.bind(this)}
                                  onClick={this.onInputNumberClick.bind(this)}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                จำนวนเงินรวมทั้งสิ้น
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.grandTotal).format('0,0.00')}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <EnButton className="btn-info pull-right" disabled={!this.validateForm(info)} onClick={this.onSubmit.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                        <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                      </EnButton>
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container >
    );
  }
}

export default inject('claim', 'job')(observer(ClaimForm));
