import React from 'react';

const DateCheck = (item, value, index) => {
  if (item.date.indexOf(value) >= 0) {
    return true;
  }
  return false;
};

export const DateCheckBox = (props) => (
  <div style={{ fontSize: '24px', lineHeight: '20px' }}>
    <label className="checkbox-inline">
      <input
        className="checkbox-scale-12"
        type="checkbox"
        value={0}
        onChange={props.onDateCheckBoxChange}
        checked={DateCheck(props.items, 0, props.index)} />
      Sun
    </label>
    <label className="checkbox-inline">
      <input
        className="checkbox-scale-12"
        type="checkbox"
        value={1}
        onChange={props.onDateCheckBoxChange}
        checked={DateCheck(props.items, 1, props.index)} />
      Mon
    </label>
    <label className="checkbox-inline">
      <input
        className="checkbox-scale-12"
        type="checkbox"
        value={2}
        onChange={props.onDateCheckBoxChange}
        checked={DateCheck(props.items, 2, props.index)} />
      Tue
    </label>
    <label className="checkbox-inline">
      <input
        className="checkbox-scale-12"
        type="checkbox"
        value={3}
        onChange={props.onDateCheckBoxChange}
        checked={DateCheck(props.items, 3, props.index)} />
      Wed
    </label>
    <label className="checkbox-inline">
      <input
        className="checkbox-scale-12"
        type="checkbox"
        value={4}
        onChange={props.onDateCheckBoxChange}
        checked={DateCheck(props.items, 4, props.index)} />
      Thu
    </label>
    <label className="checkbox-inline">
      <input
        className="checkbox-scale-12"
        type="checkbox"
        value={5}
        onChange={props.onDateCheckBoxChange}
        checked={DateCheck(props.items, 5, props.index)} />
      Fri
    </label>
    <label className="checkbox-inline">
      <input
        className="checkbox-scale-12"
        type="checkbox"
        value={6}
        onChange={props.onDateCheckBoxChange}
        checked={DateCheck(props.items, 6, props.index)} />
      Sat
    </label>
  </div>

);
