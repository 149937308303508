import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import numeral from 'numeral';
import moment from 'moment';
import MobileDetect from 'mobile-detect';
import { Checkbox } from 'react-bootstrap/lib';
import Container from '../../layouts/Container';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import { FormValidation, required, taxid, branchcode } from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import EnTextAreaFormGroup from '../../components/form/EnTextAreaFormGroup';
import EnButton from '../../components/form/EnButton';
import EnDropDown from '../../components/form/EnDropDown';
import AddressForm from '../../components/address/AddressForm';
import FormGroup from '../../components/form/FormGroup';
import history from '../../utils/history';
import config from '../../config';
import * as _ from 'lodash';
import authStore from '../../stores/AuthStore';
import ModalAuth from '../../components/common/ModalAuth';

export class InvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    const md = new MobileDetect(window.navigator.userAgent);
    this.state = {
      jobId: this.props.match.params.id,
      pdf: undefined,
      isMobileOrTablet: !!(md.mobile() || md.tablet()),
      requestTax: false,
      paymentChannel: '',
      isEdit: false
    };
  }

  async componentWillMount() {
    this.props.invoice.clearInvoices();
    this.props.invoice.resetSelectedInvoiceIndex();
    await this.props.invoice.loadInvoiceByJobId(this.state.jobId);
    const { info, invoices } = this.props.invoice.toJS()
    if (invoices.length > 0) {
      this.setState({ isEdit: true })
    }

  }

  validateForm(info) {
    const paymentOtherError = info.paymentChannel === 'others' ? required(info.paymentOther) : null;

    if (!info.requestTax && !paymentOtherError) {
      return true;
    }
    const nameError = required(info.name);
    const taxIdError = required(info.taxId);
    const branchError = info.type === 'juristic' ? required(info.branch) : null;
    const { address } = info;
    const addrNoError = required(address.no);
    const provinceError = required(address.province.name);
    const districtError = required(address.district.name);
    const subDistrictError = required(address.subDistrict.name);
    const postcodeError = required(address.postcode);
    return !nameError &&
      !taxIdError &&
      !branchError &&
      !addrNoError &&
      !provinceError &&
      !districtError &&
      !subDistrictError &&
      !postcodeError &&
      !paymentOtherError;
  }

  async checkChangeData() {
    const { info, initInfo } = this.props.invoice.toJS()
    if (await this.props.job.showJobUpdatedDialog(info.jobInfo)) {
      return;
    }
    if (!this.state.isEdit) {
      this.onSubmit(true)
      return
    } else {
      const permission = [
        { key: 'code', name: 'รหัสสินค้า' },
        { key: 'name', name: 'รายการสินค้า' },
        { key: 'unit', name: 'หน่วย' },
        { key: 'qty', name: 'จำนวน' },
        { key: 'price', name: 'ราคา' },
        { key: 'discount', name: 'ส่วนลด' },
      ]
      const typeJob = info.no.slice(0, 2).toLowerCase()
      let permissionFilter = []
      permissionFilter = initInfo.items.map((item, index) => {
        if (!info.details[index]) {
          const remark = `[${initInfo.no}] is remove at “sku ${item.code} , รายการสินค้า ${item.name}” by `
          return [this.saveModelEventLogs(`update_${typeJob}`, initInfo.no, remark)]
        }
        return permission.map((data) => {
          const key = data.key
          if (info.details[index][key] != item[key]) {
            const remark = `[${initInfo.no}] is edited at “${data.name} from ${item[key]} to ${info.details[index][key]}” by `
            return this.saveModelEventLogs(`update_${typeJob}`, initInfo.no, remark)
          }
        }).filter(notUndefined => notUndefined !== undefined);
      }).filter(notUndefined => notUndefined !== undefined);
      if (info.details.length > initInfo.items.length) {
        for (let i = initInfo.items.length; i < info.details.length; i++) {
          const remark = `${initInfo.no} is added product at “sku ${info.details[i].code} , รายการสินค้า ${info.details[i].name}” by `
          permissionFilter.push([this.saveModelEventLogs(`update_${typeJob}`, initInfo.no, remark)])
        }
      }
      if (initInfo.remark !== info.remark) {
        const remark = `[${initInfo.no}] is edited at “remark from ${initInfo.remark} to ${info.remark}” by `
        permissionFilter.push([this.saveModelEventLogs(`update_${typeJob}`, initInfo.no, remark)])
      }
      if (initInfo.discountCoupon !== info.discountCoupon) {
        const remark = `[${initInfo.no}] is edited at “ส่วนลดคูปอง from ${initInfo.discountCoupon} to ${info.discountCoupon}” by `
        permissionFilter.push([this.saveModelEventLogs(`update_${typeJob}`, initInfo.no, remark)])
      }
      const sumArray = permissionFilter.reduce(function (previousValue, currentValue) {
        return previousValue.concat(currentValue);
      });
      if (sumArray.length > 0) {
        this.props.permission.setInfoLogActivity(sumArray)
        this.onSubmit(true)
      } else {
        this.onSubmit(false)
      }
    }
  }

  saveModelEventLogs(event, referenceCode, remark) {
    const dataPermission = {}
    dataPermission.event = event
    dataPermission.function_name = event
    dataPermission.reference_code = referenceCode
    dataPermission.remark = remark
    return dataPermission
  }

  saveUam(data) {
    if (!this.state.isEdit) {
      const no = data.invoice[0].no
      const { userName } = this.props.permission.toJS()
      const typeJob = no.slice(0, 2).toLowerCase()
      const remark = `[${no}] is created manually by ${userName}`
      this.props.permission.setInfoLogActivity([this.saveModelEventLogs(`create_${typeJob}`, no, remark)])
    }
    this.props.permission.saveUamActivityLogs()
  }

  async onSubmit(verify) {
    // e.preventDefault();
    let status = false
    if (verify) {
      status = this.props.permission.getUserFromEmail()
      if (!status) {
        return
      }
    }
    // const isFormValid = this.frm.isValid();
    const info = this.props.invoice.toJS().info;
    const isFormValid = this.validateForm(info);
    if (!isFormValid) {
      swal.fire({
        icon: 'error',
        title: 'กรุณากรอกข้อมูลให้ถูกต้อง',
        confirmButtonText: 'ตกลง',
      });
      return;
    }

    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไขใบแจ้งหนี้' : 'กรุณายืนยันการสร้างใบแจ้งหนี้',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const data = await this.props.invoice.saveInvoice(this.state.jobId);
          if (data && status) {
            this.saveUam(data.data)
          }
          return data;
        } catch (error) {
          swal.fire({
            icon: 'error',
            title: error.message,
            confirmButtonText: 'ตกลง',
          });
          return false;
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      this.onCancel();
    }
  }

  onCancel() {
    const isAdmin = authStore.getStore();
    if (!_.isNil(isAdmin) && !_.isEmpty(isAdmin)) {
      history.push(`${config.publicUrl}/calendar/admin/jobs/${this.state.jobId}`);
    } else {
      history.push(`${config.publicUrl}/superadmin/jobs/${this.state.jobId}`);
    }
  }

  async onPreview() {
    try {
      const pdf = await this.props.invoice.generatePdf();
      this.setState({
        pdf,
      });
    } catch (error) {
      console.log(error);
    }
  }

  onInfoChanged(key, e) {
    if (e.target) {
      this.props.invoice.saveInfo(key, e.target.value);
    }
  }

  onAddressNoChanged(e) {
    if (e.target) {
      this.props.invoice.saveAddressInfo('no', e.target.value);
    }
  }

  onAddressChanged(addressObject) {
    this.props.invoice.saveAddressObject(addressObject);
  }

  onTypeChanged(e) {
    if (e.target) {
      this.props.invoice.saveInfo('type', e.target.value);
    }
  }

  setRequestTaxInvoice(e) {
    if (e.target) {
      this.props.invoice.saveInfo('requestTax', e.target.checked);
      this.setState({
        requestTax: e.target.checked,
      });
    }
  }

  onColumnInputChanged(index, key, e) {
    if (e.target) {
      this.props.invoice.saveDetail(index, key, e.target.value);
    }
  }

  onInputChangedAndCalculate(index, key, e) {
    if (e.target) {
      const value = !isNaN(e.target.value) ? e.target.value : 0.00;
      this.props.invoice.saveDetailAndCalculate(index, key, value);
    }
  }

  onDiscountCouponChanged(e) {
    if (e.target) {
      const value = e.target.value && !isNaN(e.target.value) ? parseFloat(e.target.value) : 0;
      this.props.invoice.saveDiscountCoupon(value);
    }
  }

  onInputNumberFocus(e) {
    if (e.target) {
      e.target.select();
    }
  }

  onInputNumberClick(e) {
    if (e.target) {
      e.target.select();
    }
  }

  onChangePaymentChannel(key, e) {
    if (e.target) {
      let value = e.target.value;
      this.props.invoice.saveInfo(key, value);
      if (key === 'paymentChannel') {
        this.setState({
          paymentChannel: value,
        });
      }
    }
  }

  onSelectInvoiceChange(e) {
    this.props.invoice.saveSelectedInvoiceIndex(e.target.value);
    this.props.permission.setInfoLogActivity([])
  }

  onAddRow() {
    this.props.invoice.addDetail();
  }

  onDeleteRow(index, e) {
    this.props.invoice.deleteDetail(index);
  }

  displayTableRow(details) {
    return (details || []).map((d, index) => {
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td>
            <input
              type="text"
              id={`code-${index}`}
              name={`code-${index}`}
              value={d.code}
              size="15"
              onChange={this.onColumnInputChanged.bind(this, index, 'code')}
            />
          </td>
          <td>
            <input
              type="text"
              id={`name-${index}`}
              name={`name-${index}`}
              value={d.name}
              size="40"
              onChange={this.onColumnInputChanged.bind(this, index, 'name')}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`unit-${index}`}
              name={`unit-${index}`}
              className="text-center"
              size="4"
              value={d.unit}
              onChange={this.onColumnInputChanged.bind(this, index, 'unit')}
            />
          </td>
          <td className="text-center">
            <input
              type="text"
              id={`qty-${index}`}
              name={`qty-${index}`}
              className="text-right"
              size="4"
              value={d.qty}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'qty')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
            />
          </td>
          <td className="text-right">
            <input type="text"
              id={`price-${index}`}
              name={`price-${index}`}
              className="text-right"
              size="10"
              value={d.price}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'price')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
            />
          </td>
          <td className="text-right">
            <input
              type="text"
              id={`discount-${index}`}
              name={`discount-${index}`}
              className="text-right"
              size="10"
              value={d.discount}
              onChange={this.onInputChangedAndCalculate.bind(this, index, 'discount')}
              onFocus={this.onInputNumberFocus.bind(this)}
              onClick={this.onInputNumberClick.bind(this)}
            />
          </td>
          <td className="text-right">
            {numeral(d.amount || 0).format('0,0.00')}
          </td>
          <td className="text-center">
            {
              (details.length > 1) && (
                <EnButton
                  className="btn-primary btn-xs"
                  style={{ padding: '5px 10px', marginTop: '0' }}
                  onClick={this.onDeleteRow.bind(this, index)}>
                  <i className="fa fa-trash" aria-hidden="true" />
                </EnButton>
              )
            }
          </td>
        </tr>
      );
    });
  }

  displayCustomerInfo(data) {
    const {
      customer_firstname: firstname,
      customer_lastname: lastname,
      address_info: addressInfo,
      customer_address: addressNo,
      customer_phone: phone,
    } = data;
    const { district, province, sub_district, post_code: postcode } = addressInfo;
    const address = `${addressNo} ${sub_district.name} ${district.name} ${province.name} ${postcode}`;
    return (
      <div>
        <div className="row">
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="ชื่อ"
            value={firstname}
            disabled
          />
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="นามสกุล"
            value={lastname}
            disabled
          />
        </div>
        <div className="row">
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="ที่อยู่"
            value={address}
            disabled
          />
          <EnTextFormGroup
            id="name"
            containerClassName="col-md-6"
            type="text"
            label="โทรศัพท์"
            value={phone}
            disabled
          />
        </div>
      </div>
    );
  }

  render() {
    const invoiceStore = this.props.invoice.toJS();
    const { info, invoices, selectedInvoiceIndex } = invoiceStore;
    const addressData = this.props.invoice.toAddressData(info.address);
    const channel = [
      'Credit and debit cards',
      'QR Gateway -Thai QR',
      'Cash payment channel',
      'others',
    ];

    // case '001': return 'Credit and debit cards';
    // case '002': return 'Cash payment channel';
    // case '003': return 'Direct debit';
    // case '004': return 'Others';
    // case '005': return 'IPP transaction';
    let paymentChannel = channel.map((item, idx) => {
      return <option key={idx} value={item}>{item}</option>;
    });
    const showDropDownIvList = Array.isArray(invoices) && !!invoices.length;
    // const iframeStyle = {
    //   position: 'absolute',
    //   top: '0',
    //   left: '0',
    //   width: '100%',
    //   height: '100%',
    //   border: '0',
    // };
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={invoiceStore.isLoading || invoiceStore.isSubmitting} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="card">
            <div className="col-md-12">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">แบบฟอร์มใบรับเงิน</h4>
                <p className="category">{(info && info.jobInfo && info.jobInfo.type_of_job === 'S') ? 'ค่าสำรวจ' : 'ค่าติดตั้ง'}</p>
              </div>
              <div className="card-content">
                {showDropDownIvList && (
                  <div className="row">
                    <FormGroup containerClassName="col-md-6" label="ใบรับเงิน">
                      <EnDropDown onChange={this.onSelectInvoiceChange.bind(this)} value={selectedInvoiceIndex}>
                        {invoices.map((iv, index) => {
                          const { files } = iv;
                          const name = Array.isArray(files) && files.length ? files[files.length - 1].name : iv.no;
                          return (
                            <option key={`iv-${index}`} value={index}>{name}</option>
                          );
                        })}
                      </EnDropDown>
                    </FormGroup>
                  </div>
                )}
                {info && info.jobInfo && (
                  this.displayCustomerInfo(info.jobInfo)
                )
                }
                <FormValidation ref={(el) => {
                  this.frm = el;
                }}>
                  <div className="row">
                    <FormGroup containerClassName="col-md-6" label="ช่องทางการชำระเงิน">
                      <EnDropDown onChange={this.onChangePaymentChannel.bind(this, 'paymentChannel')} value={info.paymentChannel}>
                        {paymentChannel}
                      </EnDropDown>
                    </FormGroup>
                    {
                      info.paymentChannel === 'others' && (
                        <EnTextFormGroup
                          id="name"
                          containerClassName="col-md-6"
                          type="text"
                          label="โปรดระบุช่องทางการชำระเงิน"
                          value={info.paymentOther}
                          onChange={this.onChangePaymentChannel.bind(this, 'paymentOther')}
                          validations={[required]}
                        />
                      )
                    }
                  </div>
                  <div className="row">
                    <div className="col-md-6" style={{ marginLeft: '20px' }}>
                      <Checkbox
                        id="requestTax"
                        name="requestTax"
                        title="ต้องการใบกำกับภาษีเต็มรูปแบบ"
                        checked={info.requestTax}
                        onClick={this.setRequestTaxInvoice.bind(this)}>ต้องการใบกำกับภาษีเต็มรูปแบบ</Checkbox>
                    </div>
                  </div>

                  {
                    info.requestTax && (
                      <div>
                        <div className="row">
                          <div className="col-md-10 col-md-offset-2">
                            <label>
                              <input
                                type="radio"
                                value="individual"
                                checked={info.type === 'individual'}
                                onClick={this.onTypeChanged.bind(this)}
                                readOnly
                              />
                              &nbsp;&nbsp;บุคคลธรรมดา
                            </label>
                            &nbsp;&nbsp;&nbsp;
                            <label>
                              <input
                                type="radio"
                                value="juristic"
                                checked={info.type === 'juristic'}
                                onClick={this.onTypeChanged.bind(this)}
                                readOnly
                              />
                              &nbsp;&nbsp;นิติบุคคล/บริษัท
                            </label>
                          </div>
                        </div>
                        <div className="row">
                          <EnTextFormGroup
                            id="name"
                            containerClassName="col-md-6"
                            type="text"
                            label={info.type === 'individual' ? 'ชื่อ-นามสกุล' : 'ชื่อบริษัท'}
                            value={info.name}
                            onChange={this.onInfoChanged.bind(this, 'name')}
                            validations={[required]}
                          />
                          <EnTextFormGroup
                            id="address"
                            containerClassName="col-md-6"
                            type="text"
                            label="ที่อยู่ (บ้านเลขที่ ซอย ถนน)"
                            value={info.address.no}
                            onChange={this.onAddressNoChanged.bind(this)}
                            validations={[required]}
                          />
                        </div>
                        <div className="row">
                          <AddressForm
                            addressData={addressData}
                            onAddressSelected={(addressObject) => this.onAddressChanged(addressObject)}
                            validations={[required]} />
                        </div>
                        <div className="row">
                          <EnTextFormGroup
                            id="taxId"
                            containerClassName="col-md-6"
                            type="text"
                            label={info.type === 'individual' ? 'หมายเลขบัตรประชาชน' : 'หมายเลขประจำตัวผู้เสียภาษีอากร'}
                            value={info.taxId}
                            onChange={this.onInfoChanged.bind(this, 'taxId')}
                            maxLength={13}
                            validations={[taxid]}
                          />
                          {
                            (info.type === 'juristic') &&
                            <EnTextFormGroup
                              id="branch"
                              containerClassName="col-md-6"
                              type="text"
                              label={'หมายเลขสำนักงานใหญ่ / สาขา'}
                              value={info.branch}
                              placeholder="กรณีสำนักงานใหญ่ให้ระบุเป็น 00000 หรือ 00001"
                              onChange={this.onInfoChanged.bind(this, 'branch')}
                              maxLength={5}
                              validations={[required, branchcode]}
                            />
                          }
                          {
                            (info.type === 'individual') &&
                            <EnTextFormGroup
                              id="phone"
                              containerClassName="col-md-6"
                              type="text"
                              label="เบอร์ติดต่อ"
                              value={info.phone}
                              onChange={this.onInfoChanged.bind(this, 'phone')}
                            />
                          }
                        </div>
                        {
                          (info.type === 'juristic') &&
                          <div className="row">
                            <EnTextFormGroup
                              id="phone"
                              containerClassName="col-md-6"
                              type="text"
                              label="เบอร์ติดต่อ"
                              value={info.phone}
                              onChange={this.onInfoChanged.bind(this, 'phone')}
                            />
                          </div>
                        }
                      </div>
                    )
                  }
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table table-hover col-md-12">
                          <thead>
                            <tr>
                              <th width="5%" className="text-center">#</th>
                              <th width="15%" className="text-center">รหัสสินค้า</th>
                              <th width="20%" className="text-center">รายการสินค้า</th>
                              <th width="10%" className="text-center">หน่วย</th>
                              <th width="10%" className="text-center">จำนวน</th>
                              <th width="10%" className="text-center">ราคา</th>
                              <th width="10%" className="text-center">ส่วนลด</th>
                              <th width="10%" className="text-right">จำนวนเงิน</th>
                              <th className="text-center">&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.displayTableRow(info.details)}
                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan="9">
                                <EnButton
                                  className="btn-success btn-xs"
                                  style={{ padding: '5px 10px' }}
                                  onClick={this.onAddRow.bind(this)}>
                                  <i className="fa fa-plus" aria-hidden="true" />
                                </EnButton>
                              </td>
                            </tr>
                            <tr>
                              <td rowSpan="8" colSpan="5">
                                <EnTextAreaFormGroup
                                  id="remark"
                                  label="หมายเหตุ"
                                  rows="5"
                                  value={info.remark}
                                  onChange={this.onInfoChanged.bind(this, 'remark')}
                                />
                              </td>
                              <th colSpan="2">
                                รวมจำนวนเงิน
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.subTotal).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                รวมส่วนลด
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.discountTotal).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                จำนวนเงินสุทธิ
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.netTotal).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                ราคาสินค้า/บริการ
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.netExcludeVat).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                ภาษีมูลค่าเพิ่ม {info.vatRate}%
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.vat).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                ส่วนลดคูปอง
                              </th>
                              <td colSpan="2" className="text-right">
                                <input
                                  type="text"
                                  id="discount-coupon"
                                  name="discount-coupoon"
                                  value={info.discountCoupon}
                                  className="text-right"
                                  size="10"
                                  onChange={this.onDiscountCouponChanged.bind(this)}
                                  onFocus={this.onInputNumberFocus.bind(this)}
                                  onClick={this.onInputNumberClick.bind(this)}
                                />
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                เงินชำระล่วงหน้า
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.advancePayment).format('0,0.00')}
                              </td>
                            </tr>
                            <tr>
                              <th colSpan="2">
                                จำนวนเงินรวมทั้งสิ้น
                              </th>
                              <td colSpan="2" className="text-right">
                                {numeral(info.grandTotal).format('0,0.00')}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <EnButton className="btn-info pull-right" disabled={!this.validateForm(info)} onClick={this.checkChangeData.bind(this)}>
                        <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                      </EnButton>
                      <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                        <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                      </EnButton>
                    </div>
                  </div>
                </FormValidation>
              </div>
            </div>
            <div className="col-md-5">
              {/* {
                this.state.isMobileOrTablet &&
                <h5 style={{
                  fontWeight: 'bold',
                  color: 'red',
                }}>ไม่สามารถแสดงตัวอย่างเอกสารบนมือถือ</h5>
              } */}
              {/* {
                !this.state.isMobileOrTablet &&
                <div>
                  <EnButton className="btn-warning" onClick={this.onPreview.bind(this)} style={{ marginLeft: '0', marginRight: '0' }}>
                    <i className="fa fa-eye btn-icon" aria-hidden="true" />แสดงตัวอย่าง
                  </EnButton>
                  <div className="embed-responsive embed-responsive-4by3"
                    style={{
                      height: '750px',
                    }}>
                    <iframe
                      ref="iframe"
                      title="ดูตัวอย่าง"
                      className="embed-responsive-item"
                      src={this.state.pdf}
                      frameBorder="0"
                      allowFullScreen
                      width={'100%'}
                      height={'100%'}
                      style={iframeStyle}
                    />
                  </div>
                </div>
              } */}
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('invoice', 'permission', 'job')(observer(InvoiceForm));
