import React, { Component } from 'react';
import Form from 'react-validation/build/form';
import validator from 'validator';

export const required = (value) => {
  if (!value || !value.toString().trim().length) {
    return <span className="input-error">กรุณากรอกข้อมูล</span>;
  }
};

export const positiveIntegers = (value) => {
  if (value < 1) {
    return <span className="input-error">ลำดับต้องมากกว่า 0</span>;
  }
};

export const isPositiveNumber = (value) => {
  const regExp = /^\d+(\.)*\d{0,9}$/;
  return regExp.test(value);
}

export const wagesErrMsg = (value) => {
  if (value && !isPositiveNumber(value)) {
    return <span className="input-error">Format ไม่ถูกต้อง</span>
  }
}

export const email = (value) => {
  if (value) {
    const filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    const emails = value.split(',');
    if (emails.length > 5) {
      return <span className="input-error">อีเมลเกินจำนวนที่กำหนด</span>;
    }
    for (let i = 0; i < emails.length; i++) {
      if (String(emails[i]).search(filter) === -1) {
        return <span className="input-error">รูปแบบอีเมลไม่ถูกต้อง</span>;
      }
    }
  } 
};

export const isContractorEmailValid = (value) => {
  if (value) {
    const filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
    const emails = value.split(',');
    if (emails.length > 5) {
      return false;
    }
    for (let i = 0; i < emails.length; i++) {
      if (String(emails[i]).search(filter) === -1) {
        return false;
      }
    }
    return true;
  }
};

export const password = (value) => {
  let mediumRegex = new RegExp('^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})');

  if (!mediumRegex.test(value)) {
    return <span className="input-error">รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร และประกอบไปด้วยตัวเลขและตัวอักษร</span>;
  }
};

export const passwordConfirm = (value, props, components) => {
  if (value !== components['confirm'][0].value) {
    return <span className="input-error">รหัสผ่านไม่ตรงกัน</span>;
  }
};

export const pin = (value) => {
  let sixDigitsRegex = new RegExp('^[0-9]{6,6}$');
  if (!sixDigitsRegex.test(value)) {
    return <span className="input-error">Pin ต้องเป็นตัวเลข 6 หลัก</span>;
  }
};

export const pinConfirm = (value, props, components) => {
  if (value !== components['pin'][0].value) {
    return <span className="input-error">Pin ไม่ตรงกัน</span>;
  }
};

export const phone = (value) => {
  let regex = new RegExp('^[0-9]{10,10}$');
  if (!regex.test(value)) {
    return <span className="input-error">เบอร์โทรศัพท์ต้องเป็นตัวเลข 10 หลัก</span>;
  }
};

export const phone2 = (value) => {
  if (!value) return
  let regex = new RegExp('^[0-9]{9,10}$');
  if (!regex.test(value)) {
    return <span className="input-error">เบอร์โทรศัพท์ต้องเป็นตัวเลข 9 - 10 หลัก</span>;
  }
};

export const idcard = (value) => {
  const regex = new RegExp('^[0-9]{13,13}$');
  if (!regex.test(value)) {
    return <span className="input-error">หมายเลขบัตรประชาชนต้องเป็นตัวเลข 13 หลัก</span>;
  }
};

export const taxid = (value) => {
  const regex = new RegExp('(^$|[0-9]{13}$)');
  if (!regex.test(value)) {
    return <span className="input-error">หมายเลขประจำตัวผู้เสียภาษีอากรต้องเป็นตัวเลข 13 หลัก</span>;
  }
};

export const postcode = (value) => {
  const regex = new RegExp('^[0-9]{5,5}$');
  if (!regex.test(value)) {
    return <span className="input-error">รหัสไปรษณีย์ต้องเป็นตัวเลข 5 หลัก</span>;
  }
};

export const branchcode = (value) => {
  let regex = new RegExp('(^$|[0-9]{5}$)');
  if (!regex.test(value)) {
    return <span className="input-error">หมายเลขสำนักงานใหญ่หรือรหัสสาขาต้องเป็นตัวเลข 5 หลัก</span>;
  }
};

export const bankAccountNo = (value) => {
  let regex = new RegExp('^[0-9]{10,12}$');
  if (!regex.test(value)) {
    return <span className="input-error">หมายเลขบัญชีไม่ถูกต้อง</span>;
  }
};

export const checkEmoji = (value) => {
  const regExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi
  if (regExp.test(value)) {
    return <span className="input-error">จะใส่ข้อความที่มี emoji ไม่ได้</span>;
  }
}

export const validateUrl = (value) => {
  if (!value) return
  const regExp = /^((http|https|ftp):\/\/)/;
  if (!regExp.test(value)) {
    return <span className="input-error">ลิ้งค์แนบต้องมี http หรือ https</span>
  }
}
export const limitText25 = (value) => {
  if (!value) return
  if (value.length > 25) {
    return <span className="input-error">แสดงข้อความสูงสุดได้ 25 ตัวอักษร</span>
  }
}
export const limitText100 = (value) => {
  if (!value) return
  if (value.length > 100) {
    return <span className="input-error">แสดงข้อความสูงสุดได้ 100 ตัวอักษร</span>
  }
}

export const maxLength = (length) => (value) => {
  // Count text without html tag
  const removeTag = /<[^>]*>[^<]*<\/[^>]*>/g;
  const removeExceptContent = /<[^>]*>/g;
  const removeDynamicField = /<div.*?<\/div>/g;
  const formatedValue = value.replace(removeDynamicField, '|')
  .replace(removeExceptContent, '')
  .replace(/&nbsp;/g, '')
  .replace(removeTag, '')
  if (!formatedValue) return

  if (formatedValue.length > length) {
    return <span className="input-error">ข้อความเกินกำหนด กรุณาใส่ข้อมูลให้ถูกต้อง</span>
  }
}

export const couponHeader = (value) => {
  if (value.length > 12) {
    return <span className="input-error">แสดงข้อความสูงสุดได้ 12 ตัวอักษร</span>
  }
}
export class FormValidation extends Component {
  isValid() {
    let validateInput = this.form.validateAll();
    return Object.keys(validateInput || []).length === 0;
  }

  hideError(name) {
    this.form.hideError(name);
  }

  render() {
    let cls = this.props.className || '';
    return (
      <Form ref={c => { this.form = c; }} className={cls} style={this.props.style}>
        {this.props.children} <div className="clearfix" />
      </Form>
    );
  }
}

export default FormValidation;
