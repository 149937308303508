import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { observer, inject } from 'mobx-react';
import EnButton from '../../../components/form/EnButton';
import config from '../../../config';
import * as stCommon from './stepCommon';
import datetime from '../../../utils/datetime';
import Swal from 'sweetalert2';

export class EnInstalledRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateReasonNoQuotation: true,
    };
  }

  async componentWillMount() {
    const jobInfo = this.props.job.toJS().info;
    if (jobInfo.reasonNoQuotation) {
      this.setState({
        stateReasonNoQuotation: false,
      });
    }
    if (jobInfo.jobType === 'S') {
      const jobI = await this.props.job.getInstallationJobInfo(jobInfo.jobCode, false);
      if (jobI) {
        this.setState({
          installationJobId: jobI._id.toString(),
        });
      }
    }
  }
  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  onSubmit() {
    try {
      if (this.props.onSubmit) {
        this.props.onSubmit();
      }
    } catch (error) {
      this.noti.error(error.message);
    }
  }

  onShowNoQuotation() {
    const jobInfo = this.props.job.toJS().info;
    const regexUrl = /^(http|https):\/\/[^ "]+$/;

    Swal.fire({
      title: 'ระบุสาเหตุ',
      html:
        `
        <h4> เหตุผล<label style="color:red">*</label> </h4>
        <input id="swal-input1" class="swal2-input">

        <h4> กรุณาแนบ Link URL Ticket<label style="color:red">*</label> </h4>
        <input id="swal-input2" class="swal2-input">
        `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: 'ตกลง',
      cancelButtonText: 'ยกเลิก',
      preConfirm: () => {
        const reason = document.getElementById('swal-input1').value;
        const url = document.getElementById('swal-input2').value;

        if (reason.trim() === '' || url.trim() === '' ) {
          Swal.showValidationMessage('กรุณากรอกข้อมูลให้ครบ');
          return
        }

        if (reason.length < 1 || !regexUrl.test(url)) {
          Swal.showValidationMessage('กรุณากรอกข้อมูลให้ครบ');
          return
        }

        return { reason: reason ? reason : '-', url: url };
      }
    }).then((result) => {
      if (result.value) {
        const { reason, url } = result.value;
        const emailUser = this.props.auth.getUsernameOrEmail();
        const dateTimeFormat = moment().format('HH:mm DD/MM/YYYY');
        const activityLog = {
          event: "admin_no_quotation",
          function_name: "jobs",
          reference_code: jobInfo.jobCode || jobInfo.id,
          type_of_job: 'S',
          user: emailUser,
          reason: reason,
          link_ticket: url,
          remark: `[${dateTimeFormat}] : ${emailUser} ไม่สร้างใบเสนอราคา ด้วยเหตุผล ${reason} ${url}`,
          created_date: dateTimeFormat,
        }

        this.props.permission.setInfoLogActivity([activityLog]);
        this.props.permission.saveUamActivityLogs();

        const toDay = moment().format();
        this.props.job.saveReasonNoQuotation(reason);
        this.props.job.saveCustomerApprovedDate(toDay);
        this.onSubmit();
      };
    });
  }

  getUrl(contractorMode, superAdminMode, jobId, contractorId) {
    let url = config.publicUrl;
    if (contractorMode) {
      url += '/contractors/' + contractorId + '/calendar';
    } else if (superAdminMode) {
      url += '/superadmin';
    } else {
      url += '/admin/calendar';
    }
    url += '/jobs/' + jobId;
    return url;
  }

  render() {
    // const userInfo = this.props.auth.getUserInfo();
    // const channel = userInfo.channel ? userInfo.channel.channelName : '';
    const channel = this.props.auth.getChannelName();
    let isSCGHome = (channel === 'SCGHOME Online');
    const jobStore = this.props.job.toJS();
    const jobInfo = jobStore.info;
    const { jobId, contractorMode, superAdminMode, transfer } = this.props;
    const contractorId = jobInfo.contractorId;
    const isShowNoQuotationButton = (jobInfo.jobType === 'S') && this.state.stateReasonNoQuotation && !isSCGHome;
    const isShowNoQuotationAlert = !isSCGHome && (jobInfo.jobType === 'S') && !this.state.stateReasonNoQuotation;
    const url = this.getUrl(contractorMode, superAdminMode, this.state.installationJobId, contractorId);
    let stepDetail = <div />;
    if (jobInfo.customer_approved === true) {
      if (transfer && transfer.effective_date) {
        const transferAmount = transfer && transfer.amount_exclude_wht ? transfer.amount_exclude_wht : '0';
        const amountTxt = numeral(transferAmount).format('0,0.00');
        let title = 'เตรียมการจ่ายเงินให้ช่าง';
        let textDate = '';
        const today = moment(moment().format('YYYY/MM/DD'));
        const compareDate = moment(transfer.effective_date).add('days', 1).format('YYYY/MM/DD');
        if (today.isSameOrAfter(compareDate)) {
          title = 'จ่ายเงินให้ช่างเรียบร้อยแล้ว';
          textDate = `เมื่อวันที่ ${datetime.formatWithOffset(transfer.effective_date, 'DD/MM/YYYY', 7)}`;
        } else {
          textDate = `ช่างจะได้รับเงินภายในวันที่ ${datetime.formatWithOffset(transfer.effective_date, 'DD/MM/YYYY', 7)}`;
        }
        let textSpan = <div><p>จำนวนเงิน <b>{amountTxt}</b> บาท</p><p>{textDate}</p></div>;
        stepDetail = stCommon.showContentStepStatus('fa fa-usd', title, textSpan, 'orange');
      } else {
        // step customer review finished
        stepDetail = stCommon.showStepStatus('fa fa-check-circle-o', 'ลูกค้ายอมรับการสำรวจเรียบร้อย', jobInfo.customer_approved_date ? stCommon.formatDate(jobInfo.customer_approved_date) : '', '#0fad00');
      }
    }
    return (
      <div>
        {stepDetail}
        <div className="row" style={{ paddingLeft: '15px' }}>
          {
            isShowNoQuotationAlert && (
              <div className="alert alert-warning col-md-4" style={{ backgroundColor: '#ae5d0e', padding: '6px 15px' }}>
                <span><i className="fa fa-exclamation-circle" aria-hidden="true" /> ไม่ต้องกรอกใบเสนอราคา</span>
              </div>
            )
          }
        </div>
        {stCommon.showBasicInfo(jobInfo, contractorMode)}
        <div className="row">
          <div className="col-md-12">
            {(isShowNoQuotationButton) && (
              <EnButton id="open-no-quotation-button" className="btn-danger pull-left" onClick={this.onShowNoQuotation.bind(this)}>
                <i className="fa fa-ban btn-icon" aria-hidden="true" />ไม่ต้องมีใบเสนอราคา
              </EnButton>
            )}
            <a href={url} target="_blank">
              <EnButton id="back-to-installation" className="btn-warning pull-right">
                <i className="fa fa-external-link btn-icon" aria-hidden="true" />ดูงานติดตั้ง
              </EnButton>
            </a>
            {/* {
              (isShowNoQuotationButton) &&
              <EnButton id="submit-button" className="btn-info pull-right" onClick={this.onSubmit.bind(this)}>
                <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
              </EnButton>
            } */}
            <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
              <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
            </EnButton>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('auth', 'job', 'permission')(observer(EnInstalledRequest));
