import BaseStore from './BaseStore';
import config from '../config';
import { http } from '../utils/http';

export class ContactStore extends BaseStore {
    constructor() {
        super();
        this.observable({
            info: [],
        });
    }

    async getAllContact() {
        let response = await http.get(`${config.api.sims}/v1/contacts`);
        if (response && response.data && response.data.data) {
            const contact = response.data.data.map(contact => {
                return {
                    id: contact.emp_id,
                    name: `${contact.first_name} ${contact.last_name}`,
                    phone: contact.phone
                }
            })
            if (contact && contact.length) {
                this.info = contact;
                return contact;
            } else {
                return [];
            }
        }
    }
}

export default new ContactStore();
