import React from 'react'
import EnButton from '../../../components/form/EnButton'
import EnDatePicker from '../../../components/form/EnDatePicker'
import EnDropDown from '../../../components/form/EnDropDown'
import moment from 'moment';
const BroadcastSearchForm = ({ provinces, handleClearSearch, handleClickSearch, handleChangeQuery, query }) => {

  const releaseStatusOption = [
    { label: 'ฉบับร่าง', value: 'draft' },
    { label: 'พร้อมเผยแพร่', value: 'published' },
  ]

  const isPublished = [
    { label: 'ประกาศแล้ว', value: true },
    { label: 'ยังไม่ประกาศ', value: false },
  ]
  return (
    <div>
      <div className="row" style={{ margin: '1rem' }}>
        {/* column1 */}
        <div className="col-md-4 row" >
          <label className="col-md-3 control-label">พื้นที่</label>
          <div className="col-md-8" >
            <EnDropDown
              id="provinceQuery"
              value={query['province']}
              onChange={(value) => handleChangeQuery('province', value)}
            >
              <option value={''}>--- ทุกจังหวัด ---</option>
              {provinces.map((province, index) => (
                <option key={`pv-${index}`} value={province.value}>{province.label}</option>
              ))}
            </EnDropDown>
          </div>
        </div>
        {/* column 2 */}
        <div className="col-md-4" >
          <div className="row" style={{ marginBottom: '1rem' }}>
            <label className="col-md-4 control-label">วันที่สร้าง</label>
            <div className="col-md-8" >
              <EnDatePicker
                id="create_at"
                initialDate={query.create_at ? moment(query.create_at) : undefined}
                onDateChange={(value) => handleChangeQuery('create_at', value)}
                isOutsideRange={() => false}
              />
            </div>
          </div>
          <div className="row" style={{ marginBottom: '1rem' }}>
            <label className="col-md-4 control-label">วันให้มีผล</label>
            <div className="col-md-8" >
              <EnDatePicker
                id="announcement_date"
                initialDate={query.announcement_date ? moment(query.announcement_date) : undefined}
                onDateChange={(value) => handleChangeQuery('announcement_date', value)}
                isOutsideRange={() => false}
              />
            </div>
          </div>
        </div>
        {/* column 3 */}
        <div className="col-md-4" >
          <div className="row" style={{ marginBottom: '1rem' }}>
            <label className="col-md-4 control-label">สถานะประกาศ</label>
            <div className="col-md-8" >
              <EnDropDown
                id="releaseStatus"
                value={query['release_status']}
                onChange={(value) => handleChangeQuery('release_status', value)}
              >
                <option value={'all'}>--- ทั้งหมด ---</option>
                {releaseStatusOption.map(option => <option key={`releaseStatusOption-${option.value}`} value={option.value}>{option.label}</option>)}
              </EnDropDown>
            </div>
          </div>
          <div className="row" style={{ marginBottom: '1rem' }}>
            <label className="col-md-4 control-label">สถานะเผยแพร่</label>
            <div className="col-md-8" >
              <EnDropDown
                id="is_published"
                value={query['is_published']}
                onChange={(value) => handleChangeQuery('is_published', value)}
              >
                <option value={'all'}>--- ทั้งหมด ---</option>
                {isPublished.map(option => <option key={`isPublished-${option.value}`} value={option.value}>{option.label}</option>)}
              </EnDropDown>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ paddingRight: '1rem' }}>
        <EnButton className="btn-warning pull-right" onClick={() => handleClearSearch()}>
          <i className="fa fa-refresh btn-icon" aria-hidden="true" />เคลียร์
        </EnButton>
        <EnButton className="btn-info pull-right" onClick={() => handleClickSearch()}>
          <i className="fa fa-search btn-icon" aria-hidden="true" />ค้นหา
        </EnButton>
      </div>
    </div >
  )
}

export default BroadcastSearchForm
