import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import NavBarContractor from './NavBarContractor';
import SideBar from './SideBar';

export class ContainerContractor extends React.Component {
  componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <SideBar />
        <div className="main-panel p4-main-contractor">
          <NavBarContractor />
          <div className="content">
            <div className="container-fluid">
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(inject()(observer(ContainerContractor)));
