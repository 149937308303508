import React from 'react';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Select from 'react-select';

import Container from '../../layouts/Container';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import EnDropDown from '../../components/form/EnDropDown';
import Pagination from '../../components/common/Pagination';
import { ContractorStatus, ContractorType } from '../../utils/data';
import * as moment from 'moment';
import { set, orderBy, isEmpty, get } from 'lodash';
import 'moment/locale/th';
import config from '../../config';
import EnText from '../../components/form/EnText';
import EnButton from '../../components/form/EnButton';
import EnTagSelect from '../../components/form/EnTagSelect';
import { groupBy } from '../../utils/common';
import { isAllow } from '../../utils/permission';
import EnToggle from '../../components/form/EnToggle';
const PAGE_SIZE = 10;

export class ToggleApprove extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isApprove: this.props.isApprove
    };
  }

  async componentDidUpdate( _, prevState ) {
    
    const { id, notiRef, update } = this.props
    if( (prevState.isApprove !== this.state.isApprove)){
      if( id && notiRef && update){
        const msg = this.state.isApprove ? 'อนุมัติ' : 'ไม่อนุมัติ';
        try {
          await update(id, { is_profile_pic_approved: this.state.isApprove });
          notiRef.success(`เปลี่ยนสถานะ เป็น ${msg} สำเร็จ`, 'สำเร็จ');
        } catch (error) {
          notiRef.error(`เปลี่ยนสถานะ เป็น ${msg} ล้มเหลว`, 'ล้มเหลว'); 
        }       
      }
    }
  }

  onToggle = () => {
    this.setState((prevState) => ({
      isApprove: !prevState.isApprove,
    }));
  }

  render() {

    const { id, notiRef, update, isApprove, ...rest } = this.props
    return (
      <div style={{ width: '110px' }}>
        <EnToggle
          checked={this.state.isApprove} 
          onChange={this.onToggle}
          {...rest}
        />
        {
          !!notiRef ? 
          (
            <div>
              {this.state.isApprove ? <p>ผ่านการตรวจสอบ</p> : <p>ไม่ผ่านการตรวจสอบ</p>}
            </div>
          ):
          (
            <div>
              {isApprove ? <p>ผ่านการตรวจสอบ</p> : <p>ไม่ผ่านการตรวจสอบ</p>}
            </div>
          )
        }
      </div>
    )
  }
}

export class AdminApproveContractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoading: true,
      status: 'all',
      pagination: {},
      query: {
        q: '',
        page_size: PAGE_SIZE,
        page: 1,
        type_of_work: undefined,
        team_grade: 'ALL',
      },
      typeOfWorkOptions: [],
      provinceOptions: [],
      provinceSelected: '',
      districtOptions: [],
      districtSelected: [],
      storeOptions: [
        { label: 'ทุกร้าน', value: '0' },
        { label: 'Q-Chang', value: '1' },
        { label: 'SCG Home', value: '2' },
        { label: 'XP', value: '3' },
        { label: 'ช่างยังไม่ถูกเลือกเข้าร้าน', value: '4' }
      ],
      storeSelected: '',
      actionStatus: '',
    };
  }

  onEditData(item) {
    if (item) {
      this.props.history.push(
        `${config.publicUrl}/superadmin/contractors/${item._id}/taxaddress`
      );
    }
  }

  async onEdit(index, contractor, isApprove) {
    let swalOption = {
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: (remark) => {
        if (!isApprove && !remark) {
          Swal.showValidationMessage(`กรุณาระบุเหตุผล`);
          return;
        }
        return this.props.contractor
          .approveOrReject(contractor._id, isApprove, remark)
          .then(_response => {
            const user = this.props.auth.getUsernameOrEmail();
            const dateTimeFormat = moment().format('DD/MM/YYYY HH:mm');
            const activityLog = {
              event: isApprove ? "approve_contractors" : "reject_contractors",
              function_name: "contractors",
              contractor_id: contractor._id,
              user,
              remark: `${isApprove ? 'อนุมัติ' : 'ไม่อนุมัติ'}โดย: ${user} เมื่อ ${dateTimeFormat}`,
              created_date: new Date(),
            }
            this.props.permission.setInfoLogActivity([activityLog]);
            this.props.permission.saveUamActivityLogs();
            return true;
          })
          .catch((error) => {
            console.log(`preConfirm: ${error}`);
            Swal.showValidationMessage(`บันทึกไม่สำเร็จ`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    };

    if (isApprove) {
      swalOption.title = 'อนุมัติช่าง';
      swalOption.text = 'กรุณายืนยันการอนุมัติ?';
      swalOption.icon = 'question';
    } else {
      swalOption.title = 'กรุณาระบุเหตุผลที่ไม่อนุมัติ';
      swalOption.input = 'text';
      swalOption.inputAttributes = { autocapitalize: 'off' };
    }
    Swal.fire(swalOption).then((result) => {
      if (result && result.value) {
        this.state.items.splice(index, 1);
        this.setState({
          items: this.state.items,
          isLoading: this.state.isLoading,
        });
        Swal.fire({
          title: `บันทึกเรียบร้อย`,
          icon: 'success',
        });
      }
    });
  }

  async onDelete(index, contractor) {
    let swalOption = {
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      title: 'กรุณายืนยันการลบข้อมูลช่าง',
      inputAttributes: { autocapitalize: 'off' },

      preConfirm: (remark) => {
        return this.props.contractor
          .delete(contractor._id.toString())
          .then((_response) => {
            const user = this.props.auth.getUsernameOrEmail();
            const dateTimeFormat = moment().format('DD/MM/YYYY HH:mm');
            const activityLog = {
              event: "delete_contractors",
              function_name: "contractors",
              contractor_id: contractor._id,
              user,
              remark: `ลบโดย: ${user} เมื่อ ${dateTimeFormat}`,
              created_date: new Date(),
            }
            this.props.permission.setInfoLogActivity([activityLog]);
            this.props.permission.saveUamActivityLogs();
            return true;
          })
          .catch((error) => {
            console.log(`preConfirm: ${error}`);
            Swal.showValidationMessage(`บันทึกไม่สำเร็จ`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    };
    Swal.fire(swalOption).then((result) => {
      if (result && result.value) {
        this.state.items.splice(index, 1);
        this.setState({
          items: this.state.items,
          isLoading: this.state.isLoading,
        });
        Swal.fire({
          title: `ลบข้อมูลเรียบร้อย`,
          icon: 'success',
        });
      }
    });
  }

  async componentDidMount() {
    try {
      const { contractorgrade: contractorGradeStore } = this.props;

      await contractorGradeStore.initGradeMap();
      // set default query
      const { query } = this.props.contractor.toJS();
      this.setState({
        status: 'all',
        actionStatus: 'all',
      });
      this.changeQuery('q', query.q);
      this.changeQuery('type_of_work', query.type_of_work);

      if (!this.state.typeOfWorkOptions.length) {
        await this.props.typeofwork.getTypeOfWorks({ visible: true });
        this.getTypeOfWorkOptions();
      }

      if (!this.state.provinceOptions.length) {
        await this.props.misc.getAllProvince();
        this.getAllProvince();
      }

      // load content
      //await this.getAreaContent(true);
      const url = new URL(window.location.href);
      url.search === ''? await this.getAreaContent(true): await this.onClickSearch(true)
    } catch (error) {
      this.noti.error(error.message);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // if (this.state.provinceSelected && prevState.provinceSelected !== this.state.provinceSelected) {
    //   await this.props.misc.getDistrictByCode(this.state.provinceSelected);
    //   this.getDistrictOptions();
    // }

    const url = new URL(window.location.href);
    url.searchParams.get('provinceSelected')
    if (url.searchParams.get('provinceSelected') && prevState.provinceSelected !== url.searchParams.get('provinceSelected')) {
      await this.props.misc.getDistrictByCode(url.searchParams.get('provinceSelected'));
      this.getDistrictOptions();
    }
  }

  getMomentDateFromISODate(s) {
    var b = s.split(/\D+/);
    return moment(
      new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
    );
  }

  async seeMoreLogs(item, index, area, areaIndex, isHide, e) {
    e.preventDefault();
    let items = this.state.items;
    item.workArea[areaIndex].isShow = !isHide;
    if (!isHide && !area.districtArea) {
      this.setState({ isLoading: true });
      const districts = await this.props.misc.getDistrictByCodes(
        area.districtIds
      );
      const districtArea = districts.map((d, i) =>
        d !== null ? (
          <span key={`item-${index}-area-${areaIndex}-dis-${i}`}>
            {d.name || '-'}
            {i !== districts.length - 1 ? ', ' : ''}
          </span>
        ) : (
          ''
        )
      );
      item.workArea[areaIndex].districtArea = districtArea;
      this.setState({ isLoading: false });
    }
    items[index] = item;
    this.setState({
      items: items,
    });
  }

  async seeMoreLogs(item, index, area, areaIndex, isHide, e) {
    e.preventDefault();
    let items = this.state.items;
    item.logsArea[areaIndex].isShow = !isHide;
    if (!isHide && !area.districtArea) {
      this.setState({ isLoading: true });
      const districts = await this.props.misc.getDistrictByCodes(
        area.districtIds
      );
      const districtArea = districts.map((d, i) =>
        d !== null ? (
          <span key={`item-${index}-area-${areaIndex}-dis-${i}`}>
            {d.name || '-'}
            {i !== districts.length - 1 ? ', ' : ''}
          </span>
        ) : (
          ''
        )
      );
      item.logsArea[areaIndex].districtArea = districtArea;
      this.setState({ isLoading: false });
    }
    items[index] = item;
    this.setState({
      items: items,
    });
  }

  async seeMore(item, index, area, areaIndex, isHide, e) {
    e.preventDefault();
    let items = this.state.items;
    item.workArea[areaIndex].isShow = !isHide;
    if (!isHide && !area.districtArea) {
      this.setState({ isLoading: true });
      const districts = await this.props.misc.getDistrictByCodes(
        area.districtIds
      );
      const districtArea = districts.map((d, i) =>
        d !== null ? (
          <span key={`item-${index}-area-${areaIndex}-dis-${i}`}>
            {d.name || '-'}
            {i !== districts.length - 1 ? ', ' : ''}
          </span>
        ) : (
          ''
        )
      );
      item.workArea[areaIndex].districtArea = districtArea;
      this.setState({ isLoading: false });
    }
    items[index] = item;
    this.setState({
      items: items,
    });
  }

  seeMoreWorkType(item, index, isHide, e) {
    e.preventDefault();
    let items = this.state.items;
    item.isShowWorkTypes = !isHide;
    items[index] = item
    this.setState({
      items: items
    })
  }

  async getProvinceContent(item, index, areaList) {
    const miscStore = this.props.misc;
    const miscData = miscStore.toJS().miscData;
    return new Promise(function (resolve, reject) {
      let provinceName = 'กำลังโหลด';
      if (miscData && miscData.province) {
        const area = areaList[index];
        const province = miscData.province.find(
          (p) => p.code === area.province_id
        );
        if (province) {
          provinceName = province.name;
          resolve({
            provinceName,
            districtIds: area.district_id,
          });
        }
      }
      resolve({});
    });
  }

  onEventLogChanged(value) {
    this.changeQuery('status_log', value);
    this.setState({ status_log: value });
    const url = new URL(window.location.href);
    url.searchParams.set('status_log', value); 
    window.history.replaceState({}, '', url.toString());  
  }

  //QBC-2310
  onEventContractorTypeChanged(value) {
    this.changeQuery('contractor_type', value);
    this.setState({ contractor_type: value });
    const url = new URL(window.location.href);
    url.searchParams.set('contractor_type', value);
    window.history.replaceState({}, '', url.toString());  
  }

  async onGradeChange(index, item, e) {
    const oldGrade = item.grade;
    const newGrade = e.target.value;
    let swalOption = {
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      title: 'กรุณายืนยันการเปลี่ยนเกรดของช่าง',
      text: `( ${oldGrade || 'ไม่มี'} > ${newGrade || 'ไม่มี'} )`,
      inputAttributes: { autocapitalize: 'off' },
      input: 'text',
      inputLabel: 'กรุณาระบุเหตุผล',
      inputPlaceholder: 'กรุณาระบุเหตุผล',
      preConfirm: (remark) => {
        if (!remark) {
          Swal.showValidationMessage(`กรุณาระบุเหตุผล`);
          return;
        }

        return this.props.contractor
          .changeGrade(item, newGrade, remark)
          .then((response) => {
            return true;
          })
          .catch((error) => {
            console.log(`preConfirm: ${error}`);
            Swal.showValidationMessage(`บันทึกไม่สำเร็จ`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    };
    Swal.fire(swalOption).then((result) => {
      if (result && result.value) {
        Swal.fire({
          title: `แก้ไขข้อมูลเรียบร้อย`,
          icon: 'success',
        });
        const contractors = this.state.items;
        contractors[index].grade = newGrade;
        this.setState({ items: contractors });
      }
    });
  }

  generateArea(areas) {
    return areas.map((area, i, arr) => {
      return <span>{area.province_id}</span>;
    });
  }

  generateLog(item) {
    if (item.logs.logs)
      return item.logs.logs.map((log) => {
        if (log.event === 'vaccine') {
          return (
            <span>
              <br />
              มีการเปลี่ยนแปลงข้อมูลวัคซีน
              <br />
            </span>
          )
        }
        if (log.event === 'is_juristic')
          return (
            <span>
              <br />
              สถานะช่าง : <br />
              {log.values
                ? 'บุคคลธรรมดา -> นิติบุคคล'
                : 'นิติบุคคล -> บุคคลธรรมดา'}
              <br />
            </span>
          );
        if (log.event === 'file' && log.values && log.values.length > 0)
          return (
            <span>
              <br />
              สถานะการอัพโหลด : <br />
              {log.values.filter((tag, index, array) => array.findIndex(t => t.type == tag.type && t.type == tag.type) == index).map((value, index) => {
                return (
                  <span key={'f' + index}>
                    - {this.getFileTypeName(value.type)}
                    <br />
                  </span>
                );
              })}
            </span>
          );
        if (log.event === 'type_of_work' && log.values && log.values.length > 0)
          return (
            <span>
              {' '}
              ประเภทงานที่เพิ่ม : <br />
              {log.values.map((wt, i, arr) => {
                const typeOfWorks = this.props.typeofwork.mapIdAndLabel();
                const workType = typeOfWorks.filter((w) => w.id === wt);
                let workTypeName =
                  workType.length > 0 ? workType[0].label : '-';
                let divider = i < arr.length - 1 && <br />;
                return (
                  <span key={'wt' + i}>
                    - {workTypeName}
                    {divider}
                  </span>
                );
              })}
            </span>
          );
      });
  }

  getFileTypeName(key) {
    if (key === 'license_card') return 'รูปถ่ายบัตรประจําตัวช่าง'
    if (key === 'id_card') return 'ไฟล์ สำเนาบัตรประชาชน';
    if (key === 'book_bank') return 'ไฟล์ บุ้คแบ้งค์';
    if (key === 'vat') return 'ไฟล์ ภพ.20';
    if (key === 'company_cert') return 'ไฟล์ หนังสือรับรองบริษัท';
  }

  getFileType(file) {
    let list = groupBy(file, 'type');
    const keys = Object.keys(list);
    return keys.map((key) => {
      return (
        <span>
          - {this.getFileTypeName(key)} <br />
        </span>
      );
    });
  }

  async getAreaContent(notLoadContractor) {
    await this.props.misc.getAllProvince();
    if (!notLoadContractor) {
      await this.props.contractor.getContractorByStatus(
        this.state.status,
        this.state.query
      );
    }

    const typeOfWorks = this.props.typeofwork.mapIdAndLabel();
    const _this = this;
    const contractor = this.props.contractor.toJS();
    let items = contractor.pendingList.map((item, index) => {
      item.isShowWorkTypes = false;
      return new Promise(function (resolve, reject) {
        let workTypes = item.type_of_work.map((wt, i, arr) => {
          const workType = typeOfWorks.filter((w) => w.id === wt);
          let workTypeName = workType.length > 0 ? workType[0].label : '';
          let divider = i < arr.length - 1 && <br />;
          if (!isEmpty(workTypeName)) {
            return (
              <span key={'wt' + i}>
                - {workTypeName}
                {divider}
              </span>
            );
          }
        });
        let workArea = _.get(item, 'area_of_work', []).map((aw, i, arr) => {
          return _this.getProvinceContent(item, i, arr);
        });
        let areas = [];
        if (item && item.logs && item.logs.logs && item.logs.logs.length > 0) {
          areas = item.logs.logs.filter((log) => {
            if (log.event === 'area_of_work_approved') return log;
          });
          areas = areas[0] ? areas[0].values : [];
        }
        let logsArea = areas.map(async (aw, i, arr) => {
          return _this.getProvinceContent(item, i, arr);
        });
        if (areas.length > 0) {
          Promise.all(workArea).then((wa) => {
            item.workTypes = workTypes;
            item.workArea = wa;

            resolve(item);
          });
          Promise.all(logsArea).then((wa) => {
            item.logsArea = wa;
            resolve(item);
          });
        } else {
          Promise.all(workArea).then((wa) => {
            item.workTypes = workTypes;
            item.workArea = wa;

            resolve(item);
          });
        }
      });
    });

    Promise.all(items).then((itemsWithAreaName) => {
      this.setState({
        items: itemsWithAreaName,
        isLoading: false,
        pagination: contractor.pagination,
      });
    });
  }

  updateAreaLogContent(item, index) {
    let workArea = item.logsArea.map((area, areaIndex) => {
      if (area.provinceName) {
        return (
          <span key={`aw-${areaIndex}`}>
            <b>[{area.provinceName}]</b>&nbsp;&nbsp;
            {area.isShow && (
              <a
                href=''
                onClick={this.seeMoreLogs.bind(
                  this,
                  item,
                  index,
                  area,
                  areaIndex,
                  true
                )}
              >
                ย่อ
              </a>
            )}
            {!area.isShow && (
              <a
                href=''
                onClick={this.seeMoreLogs.bind(
                  this,
                  item,
                  index,
                  area,
                  areaIndex,
                  false
                )}
              >
                เพิ่มเติม
              </a>
            )}
            {area.isShow && (
              <span>
                <br />
                {area.districtArea || ''}
                <br />
              </span>
            )}
            <br />
          </span>
        );
      } else {
        return <span key={`aw-${areaIndex}`} />;
      }
    });
    return workArea;
  }
  updateAreaContent(item, index) {
    let workArea = item.workArea.map((area, areaIndex) => {
      if (area.provinceName) {
        return (
          <span key={`aw-${areaIndex}`}>
            <b>[{area.provinceName}]</b>&nbsp;&nbsp;
            {area.isShow && (
              <a
                href=''
                onClick={this.seeMore.bind(
                  this,
                  item,
                  index,
                  area,
                  areaIndex,
                  true
                )}
              >
                ย่อ
              </a>
            )}
            {!area.isShow && (
              <a
                href=''
                onClick={this.seeMore.bind(
                  this,
                  item,
                  index,
                  area,
                  areaIndex,
                  false
                )}
              >
                เพิ่มเติม
              </a>
            )}
            {area.isShow && (
              <span>
                <br />
                {area.districtArea || ''}
                <br />
              </span>
            )}
            <br />
          </span>
        );
      } else {
        return <span key={`aw-${areaIndex}`} />;
      }
    });
    return workArea;
  }

  updateWorkTypesContent(item, index) {
    const workTypes = [];
    if (item.workTypes.length > 3) {
      if (item.isShowWorkTypes) {
        for (let i = 0; i < item.workTypes.length; i++) {
          workTypes.push(item.workTypes[i]);
        }
        workTypes.push((
          <div key={`wt-${index}-less`} style={{ textAlign: 'end' }}>
            <a
              href=''
              onClick={this.seeMoreWorkType.bind(
                this,
                item,
                index,
                true
              )}
            >
              ย่อ
            </a>
          </div>
        ))
      } else {
        for (let i = 0; i < 3; i++) {
          workTypes.push(item.workTypes[i]);
        }
        workTypes.push((
          <div key={`wt-${index}-more`} style={{ textAlign: 'end' }}>
            <a
              href=''
              onClick={this.seeMoreWorkType.bind(
                this,
                item,
                index,
                false
              )}
            >
              เพิ่มเติม
            </a>
          </div>
        ))
      }
    } else {
      workTypes.push(...item.workTypes);
    }
    return workTypes;
  }

  getAreaDisplay() {
    const { contractorgrade: contractorGradeStore } = this.props;
    let items = this.state.items.map((item, index) => {
      let registerDate = item.registered_date
        ? this.getMomentDateFromISODate(item.registered_date)
        : '-';
      let dateString = `${item.registered_date ? registerDate.format('LL') : '-'
        }`;
      let timeString = `${item.registered_date ? registerDate.format('HH:mm') : '- '
        } น.`;
      let updateDateString = '-';
      if (item.update_date) {
        let updateDate = item.update_date
          ? this.getMomentDateFromISODate(item.update_date)
          : '-';
        updateDateString = `${updateDate.format('DD/MM/YYYY HH:mm')} น.`;
      }
      let area = this.updateAreaContent(item, index);
      let workTypes = this.updateWorkTypesContent(item, index);
      let emailTextArr = get(item, 'email', '-') ? get(item, 'email', '-').split(',') : [];
      let emailTexts = [];
      for (let emailText of emailTextArr) {
        let maxStringLength = 50;
        if (typeof emailText === 'string' && emailText.length > maxStringLength) {
          emailText = emailText.substring(0, maxStringLength - 3);
          emailText += '...';
        }
        emailTexts.push(emailText);
      }
      return (
        <tr key={item._id}>
          <td>
            ชื่อ : {item.first_name + ' ' + item.last_name}
            <br />
            ทีม : {item.team_name}
            <br />
            โทร : {item.phone}
            <br />
            อีเมล : {emailTexts.join('\n')}
            <br />
            เกรด : {contractorGradeStore.getDisplayedGrade(item.team_grade)}
            <br />
            สถานะช่าง : {
              item.status === 'registered'
                ? 'Registered - ช่างลงทะเบียนใหม่'
                : item.status === 'rejected'
                  ? 'Rejected - ไม่อนุมัติ'
                  : item.status === 'approved'
                    ? 'Approved - อนุมัติแล้ว'
                    : 'ไม่พบสถานะ'
            }
            {
              _.get(item, 'approve_reject_log.remark') && _.get(item, 'approve_reject_log.user') && (
                <div style={{ marginTop: '15px', fontWeight: 600 }}>
                  <div dangerouslySetInnerHTML={{
                    __html: item.approve_reject_log.remark.replaceAll(
                      item.approve_reject_log.user,
                      `<span style="
                        color: #0498A8;
                        text-decoration: underline;
                      ">${item.approve_reject_log.user}</span>`
                    ),
                  }} />
                </div>
              )
            }
            {
              item.is_available == false && (
                <div className='text-center'>
                  <span className='label label-danger'>
                    ช่างปิดปฎิทิน ไม่พร้อมรับงาน
                  </span>
                </div>
              )
            }
            {
              item.is_permitted == false && (
                <div className='text-center'>
                  <span className='label label-danger'>
                    พักงานชั่วคราว
                  </span>
                </div>
              )
            }
          </td>
          <td>
            วันที่ : {dateString}
            <br />
            เวลา : {timeString}
            <br />
            <p style={{ color: item.has_updated ? '#a94442' : 'inherit' }}>
              แก้ไขล่าสุด : <br /> {updateDateString}
            </p>
            {item.logs && (
              <p style={{ color: item.has_updated ? '#a94442' : 'inherit' }}>
                {this.generateLog(item)}
              </p>
            )}
          </td>
          <td>
            {item.stores.length ? item.stores.map(store => (
              <>
                {`- ${store.name}`}
                <br />
              </>
            )) : <p style={{ color: 'gray' }}>ช่างยังไม่ถูกเลือกเข้าร้าน</p>}
          </td>
          <td>
            <img width='100%' src={item.profile_pic} alt='รูปโปรไฟล์' />
            <ToggleApprove 
              key={item._id}
              isApprove={ _.get(item, 'is_profile_pic_approved', false )}
              id={item._id}
              notiRef={this.noti}
              update={this.props.contractor.approveProfilePic}
              disabled={!isAllow("APPROVE_OR_REJECT_PROFILE_PIC")}
            />
          </td>
          <td>{workTypes}</td>
          <td>{area}</td>
          <td className='text-center'>
            {(
              item.status === 'registered' ||
              item.status === 'approved' ||
              item.blocked_payment === true ||
              item.is_permitted === true) && (
                <a href={`${config.publicUrl}/superadmin/contractors/${item._id}/taxaddress`}>
                  <button
                    className='btn btn-sm btn-warning'
                    title='แก้ไข'
                    data-tip='แก้ไข'
                    onClick={this.onEditData.bind(this, item)}
                    disabled={!isAllow("VIEW_ALL_OF_CONTRACTOR_INFORMATION")}
                  >
                  <i className='fa fa-pencil' aria-hidden='true' />
                  </button>
                </a>
              )}

            {(item.status === 'registered' || item.status === 'rejected') && (
              <button
                className='btn btn-sm btn-success'
                title='อนุมัติ'
                data-tip='อนุมัติ'
                onClick={this.onEdit.bind(this, index, item, true)}
                disabled={!isAllow("APPROVE_OR_REJECT_CONTRACTOR")}
              >
                <i className='fa fa-check' aria-hidden='true' />
              </button>
            )}

            {(item.status === 'registered' || item.status === 'approved') && (
              <button
                className='btn btn-sm btn-danger'
                title='ไม่อนุมัติ'
                data-tip='ไม่อนุมัติ'
                onClick={this.onEdit.bind(this, index, item, false)}
                disabled={!isAllow("APPROVE_OR_REJECT_CONTRACTOR")}
              >
                <i className='fa fa-times' aria-hidden='true' />
              </button>
            )}

            {item.status === 'rejected' && (
              <button
                className='btn btn-sm btn-danger'
                title='ลบ'
                data-tip='ลบช่าง'
                onClick={this.onDelete.bind(this, index, item)}
                disabled={!isAllow("APPROVE_OR_REJECT_CONTRACTOR")}
              >
                <i className='fa fa-times' aria-hidden='true' />
              </button>
            )}
          </td>
        </tr>
      );
    });
    return items;
  }

  async onStatusChanged(e) {
    this.setState({ status: e.target.value });
    const url = new URL(window.location.href);
    url.searchParams.set('status', e.target.value); 
    window.history.replaceState({}, '', url.toString());  
  }

  onChangeTypeOfWork(value) {
    this.changeQuery('type_of_work', value);
    const url = new URL(window.location.href);
    url.searchParams.set('type_of_work', value); 
    window.history.replaceState({}, '', url.toString());  
  }

  onChangeAddressType(key, value) {
    console.log('onChangeAddressType ---->', key, value)

    const url = new URL(window.location.href)
    if (key === 'province') {
      if (!value) {
        url.searchParams.set('provinceSelected', '')
        window.history.replaceState({}, '', url.toString())
        return this.setState({
          provinceSelected: '',
          districtSelected: [],
          districtOptions: []
        })
      }

      this.setState({ provinceSelected: value })
      url.searchParams.set('provinceSelected', value)
      window.history.replaceState({}, '', url.toString()) 
    }

    if (key === 'district') {
      if (!value) {
        return this.setState({ districtSelected: [] })
      }

      const splitValue = value.split(',')
      this.setState({ districtSelected: splitValue })
      url.searchParams.set('districtSelected', splitValue)
      window.history.replaceState({}, '', url.toString())
    }

    if (key === 'store') {
      if (!value) {
        return this.setState({ storeSelected: '' })
      }

      this.setState({ storeSelected: value })
      url.searchParams.set('storeSelected', value)
      window.history.replaceState({}, '', url.toString())
    }
  }

  onTeamGradeChanged(item) {
    this.changeQuery('team_grade', item);
    const url = new URL(window.location.href);
    url.searchParams.set('teamGradeSelected', item.value); 
    window.history.replaceState({}, '', url.toString());  
  }

  async onChangePage(page) {
    this.setState({ isLoading: true });
    this.changeQuery('page', page);
    localStorage.setItem('page', page);
    await this.getAreaContent();
  }

  changeQuery(key, value) {
    const query = this.state.query;
    set(query, key, value);
    this.setState({ query });
  }

  getTypeOfWorkOptions() {
    let data = this.props.typeofwork.toJS().items || [];
    let options = orderBy(data, 'name').map((item) => {
      return {
        label: item.name,
        value: item.value,
      };
    });
    let typeOfWorkOptions = [{ label: 'ทั้งหมด', value: '' }].concat(options);
    this.setState({ typeOfWorkOptions });
  }

  getAllProvince() {
    const data = this.props.misc.toJS().miscData.province || [];
    const templates = [{ name: 'ทุกจังหวัด', code: '' }, ...data];
    const provinces = templates.map((item) => {
      return {
        label: item.name,
        value: item.code,
      };
    });
    this.setState({ provinceOptions: provinces });
  }

  getDistrictOptions() {
    const data = this.props.misc.toJS().miscData.district || [];
    const districts = data.map((item) => {
      return {
        label: item.name,
        value: item.code,
      };
    });

    this.setState({
      districtSelected: [],
      districtOptions: districts
    });
  }

  onUploadGrade() {
    if (isAllow("DOWNLOAD_GRADING_TEMPLATE_AND_UPLOAD_GRADE")) {
      this.props.history.push(`${config.publicUrl}/superadmin/approve/upload`);
    } else {
      //Go Back to Main Menu
      this.props.history.push(`${config.publicUrl}`);
    }
  }

  onDownloadGradeTemplate = () => {
    window.location.href = config.excelTemplate.contractorGrades.url;
  };

  onUpdateSearch(e) {
    this.changeQuery('q', e)
    const url = new URL(window.location.href);
    url.searchParams.set('q', e); 
    window.history.replaceState({}, '', url.toString());  
  }

  async onClickSearch(hasPage) {
    const url = new URL(window.location.href);
    hasPage === true ? this.changeQuery('page', localStorage.getItem('page')) : this.changeQuery('page', 1);
    this.changeQuery('q', url.searchParams.get('q') || this.state.query.q);
    this.changeQuery('type_of_work', url.searchParams.get('type_of_work') || this.state.query.type_of_work);
    this.changeQuery('status_log', url.searchParams.get('status_log') || this.state.query.status_log);
    this.changeQuery('province', url.searchParams.get('provinceSelected') || this.state.provinceSelected);
    this.changeQuery('district', url.searchParams.get('districtSelected') || this.state.districtSelected);
    this.changeQuery('store_id', url.searchParams.get('storeSelected') || this.state.storeSelected);
    this.changeQuery('status', url.searchParams.get('status') || this.state.status);
    this.changeQuery('team_grade', url.searchParams.get('teamGradeSelected') || this.state.teamGradeSelected);
    this.changeQuery('contractor_type', url.searchParams.get('contractor_type') || this.state.contractor_type);
    
    this.setState({
      isLoading: true,
      actionStatus: url.searchParams.get('status') || this.state.status,
      status: url.searchParams.get('status') || this.state.status
    });
    localStorage.setItem('page', this.state.query.page);
    await this.getAreaContent()
  }

  onClickClear() {
    this.removeSearchParams();
    localStorage.removeItem('page');
    window.location.reload();
  }

  removeSearchParams() {
    const url = new URL(window.location.href);
    url.searchParams.delete('q');
    url.searchParams.delete('status');
    url.searchParams.delete('status_log');
    url.searchParams.delete('type_of_work');
    url.searchParams.delete('provinceSelected');
    url.searchParams.delete('districtSelected');
    url.searchParams.delete('storeSelected');
    url.searchParams.delete('teamGradeSelected');
    url.searchParams.delete('contractor_type');
    window.history.replaceState({}, '', url.toString());
  }

  getGradeOptions = () => {
    const { contractorgrade: contractorGradeStore } = this.props;
    const { gradeMap } = contractorGradeStore.toJS();
    if (gradeMap) {
      const gradeOptions = Object.entries(gradeMap).map(([originalGrade, displayedGrade]) => ({ label: displayedGrade, value: originalGrade }));
      return [{ label: 'ทุกเกรด', value: 'ALL' }, ...gradeOptions];
    }
    return [];
  };

  render() {
    const url = new URL(window.location.href);
    let items = this.getAreaDisplay();
    let { pagination, query, typeOfWorkOptions } = this.state;
   
    let status = ContractorStatus.map((item, idx) => {
      return (
        <option key={`status-${idx}`} value={item.id}>
          {item.label}
        </option>
      );
    });
    if (items.length === 0) {
      items = (
        <tr key={0}>
          <td colSpan='7' style={{ textAlign: 'center' }}>
            ไม่มีข้อมูล
          </td>
        </tr>
      );
    }

    return (
      <Container isAdmin>
        <Loader show={this.state.isLoading} />
        <Notification
          ref={(ref) => {
            this.noti = ref;
          }}
        />
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-header' data-background-color='orange'>
                <h4 className='title'>ช่าง</h4>
              </div>
              <div className="col-md-12" style={{ marginTop: 10 }}>
                <button className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={() => this.onUploadGrade()} disabled={!isAllow("DOWNLOAD_GRADING_TEMPLATE_AND_UPLOAD_GRADE")}>
                  อัพโหลดเกรดช่าง
                </button>
                <button className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={this.onDownloadGradeTemplate} disabled={!isAllow("DOWNLOAD_GRADING_TEMPLATE_AND_UPLOAD_GRADE")}>
                  ดาวโหลดไฟล์เทมเพลต
                </button>
              </div>

              <div className='card-content'>
                <div className='row' style={{ marginBottom: '7px' }}>
                  {/* 1 */}
                  <div className="form-group col-md-12">
                    <div className="form-group col-md-6">
                      <label className="col-md-3 control-label contractor-filter">สถานะช่าง:</label>
                      <div className='col-md-9'>
                        <EnDropDown
                          value={url.searchParams.get('status') || this.state.status}
                          onChange={this.onStatusChanged.bind(this)}
                        >
                          {status}
                        </EnDropDown>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-md-3 control-label contractor-filter" >ประเภทงาน:</label>
                      <div className='col-md-9 approve-tow-form'>
                        <EnTagSelect
                          className='approve-tow-select'
                          multi={false}
                          value={url.searchParams.get('type_of_work') || 
                          (typeOfWorkOptions || []).find(
                            (item) => item.value === query.type_of_work || ''
                          )}
                          options={typeOfWorkOptions}
                          onTagChange={(value) => this.onChangeTypeOfWork(value)}
                          placeholder='เลือกประเภทงาน...'
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <div className="form-group col-md-6">
                      {/*
                      <label className="col-md-3 control-label contractor-filter" >สถานะแก้ไข</label>
                      <div className='col-md-9'>
                        <EnTagSelect
                          multi={false}
                          value={url.searchParams.get('status_log') || query.status_log}
                          options={EventLogs}
                          onTagChange={this.onEventLogChanged.bind(this)}
                          placeholder="เลือกสถานะแก้ไข..."
                        />
                      </div> 
                      */}

                      {/* QBC-2310 */}
                      <label className="col-md-3 control-label contractor-filter" >ประเภทช่าง:</label>
                      <div className='col-md-9'>
                        <EnTagSelect
                          multi={false}
                          value={url.searchParams.get('contractor_type') || query.contractor_type || ''}
                          options={ContractorType}
                          onTagChange={this.onEventContractorTypeChanged.bind(this)}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-md-3 control-label contractor-filter" >ร้านค้า:</label>
                      <div className='col-md-9 approve-tow-form'>
                        <EnTagSelect
                          className='approve-tow-select'
                          multi={false}
                          value={url.searchParams.get('storeSelected') || this.state.storeSelected}
                          options={this.state.storeOptions}
                          onTagChange={(value) => this.onChangeAddressType('store', value)}
                          placeholder='เลือกร้านค้า'
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <div className="form-group col-md-6">
                      <label className="col-md-3 control-label contractor-filter" >จังหวัด:</label>
                      <div className='col-md-9 approve-tow-form'>
                        <EnTagSelect
                          className='approve-tow-select'
                          multi={false}
                          value={url.searchParams.get('provinceSelected') || this.state.provinceSelected}
                          options={this.state.provinceOptions}
                          onTagChange={(value) => this.onChangeAddressType('province', value)}
                          placeholder='เลือกจังหวัด'
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-md-3 control-label contractor-filter" >อำเภอ:</label>
                      <div className='col-md-9 approve-tow-form'>
                        <EnTagSelect
                          closeOnSelect={false}
                          className='approve-tow-select'
                          multi={true}
                          value={url.searchParams.get('districtSelected') || this.state.districtSelected}
                          options={this.state.districtOptions}
                          onTagChange={(value) => this.onChangeAddressType('district', value)}
                          placeholder='เลือกอำเภอ'
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <div className="form-group col-md-6">
                      <label className="col-md-3 control-label contractor-filter">เกรดทีมช่าง:</label>
                      <div className="col-md-9 approve-tow-form">
                        <Select
                          className="p4-select approve-tow-select"
                          multi={false}
                          options={this.getGradeOptions()}
                          placeholder="เลือกเกรดทีมช่าง"
                          searchable
                          value={url.searchParams.get('teamGradeSelected') || query.team_grade}
                          onChange={(value) => this.onTeamGradeChanged(value)}
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label className="col-md-3 control-label contractor-filter" >ค้นหา:</label>
                      <div className='col-md-9'>
                        <EnText className="form-control" placeholder="ค้นหาด้วย ชื่อ ชื่อทีม หรือเบอร์โทร" value={url.searchParams.get('q') || query.q} onChange={(e) => this.onUpdateSearch(e.target.value)} onKeyPress={(e) => { if (e.key === 'Enter') this.onClickSearch() }} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className='form-group col-md-12' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <EnButton className="btn-info pull-right" onClick={this.onClickSearch.bind(this)}>
                      <i className="fa fa-search btn-icon" aria-hidden="true" />ค้นหา
                    </EnButton>
                    <EnButton className="btn-warning pull-right" onClick={this.onClickClear.bind(this)}>
                      <i className="fa fa-refresh btn-icon" aria-hidden="true" />เคลียร์
                    </EnButton>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='table-responsive'>
                      <table className='table table-bordered table-hover has-pagination'>
                        <thead>
                          <tr>
                            <th width='15%' className='text-center'>
                              ชื่อ-นามสกุล / ชื่อทีม
                            </th>
                            <th width='15%' className='text-center'>
                              วัน-เวลาที่ลงทะเบียน
                            </th>
                            <th width='15%' className='text-center'>
                              ร้านค้า
                            </th>
                            <th width='10%' className='text-center'>
                              รูป
                            </th>
                            <th width='20%' className='text-center'>
                              ประเภทงานที่รับ
                            </th>
                            <th width='20%' className='text-center'>
                              พื้นที่รับงาน
                            </th>
                            <th width='5%' className='text-center' />
                          </tr>
                        </thead>
                        <tbody>{items}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <Pagination
                      pagination={pagination}
                      handlePageClick={this.onChangePage.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container> 
    );
  }
}

export default inject(
  'contractor',
  'misc',
  'typeofwork',
  'permission',
  'auth',
  'contractorgrade'
)(observer(AdminApproveContractor));
