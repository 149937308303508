import _ from 'lodash';
import humps from 'humps';
import BaseStore from '../BaseStore';
import config from '../../config';
import { http } from '../../utils/http';

export class Quotation extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      list: [],
      info: this.initInfo(),
      order: this.initOrder(),
    });
  }

  initInfo() {
    return {
      id: '',
      items: [{
        name: '',
        spec: '',
        qty: 1,
        unit: 'ชิ้น',
        unitPrice: 0,
        remark: '',
        checkItem: false,
      }],
      shippingCost: 0,
      checkShipping: false,
      lineUserId: '',
      orderId: '',
      checkDate: false,
      checkDelivery: false,
      vatInclude: false,
      netTotal: 0,
      leadTime: 0,
      validPeriod: 0,
      depositValue: 0,
      depositPercent: 100,
      depositRemain: 0,
      depositRemark: '',
      depositOther: '',
      location: [100.53791268803536, 13.805439700182204],
      distance: 0,
      remark: '',
    };
  }

  initOrder() {
    return {
      id: '',
      name: '',
      mobile: '',
      items: [{
        name: '',
        spec: '',
        qty: 1,
        unit: 'ชิ้น',
      }],
      address: {
        subDistrict: {
          code: '',
          name: '',
        },
        district: {
          code: '',
          name: '',
        },
        province: {
          code: '',
          name: '',
        },
        postcode: '',
      },
      location: [100.53791268803536, 13.805439700182204],
      delivery: true,
      lineUserId: '',
      lineDisplayName: '',
      linePictureUrl: '',
      expectedDate: '',
      expectedTime: 1,
      vatInfo: {},
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    const info = this.toJS().info;
    info[key] = value;
    this.info = info;
  }

  async getQuotationList() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/quotations`);
      if (response.status === 200 && response.data) {
        this.list = response.data.data;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getQuotationById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/quotations/${id}`);
      if (response.status !== 200 || !response.data || !response.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      this.info = humps.camelizeKeys(response.data.data);
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getQuotationByOrderAndLineUserId(orderId, lineUserId) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const response = await http.get(`${config.api.bestdeal}/v1/quotations/order/${orderId}/line/${lineUserId}`);
      if (response.status === 200 && response.data) {
        let quotation = humps.camelizeKeys(response.data.data);
        const order = humps.camelizeKeys(response.data.order);
        if (!quotation) {
          quotation = this.initInfo();
          quotation.items = _.cloneDeep(order.items);
        }
        if (order) {
          quotation.orderId = order.id;
          quotation.location = order.location;
          quotation.distance = order.distance;
        }
        this.info = quotation;
        this.info.remark = response.data.store.remark;
        this.order = order;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async createQuotation() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      const response = await http.post(`${config.api.bestdeal}/v1/quotations`, info);
      if (response.status !== 200 && response.data) {
        throw new Error(response.data.message);
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateQuotation(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const info = this.toJS().info;
      const response = await http.put(`${config.api.bestdeal}/v1/quotations/${id}`, info);
      if (response.status !== 200 && response.data) {
        throw new Error(response.data.message)
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new Quotation();
