import React from 'react';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import SearchBar from '../../../components/search';
import Notification from '../../../components/common/Notification';
import EnButton from '../../../components/form/EnButton';
import { isAllow } from '../../../utils/permission';
import CheckboxGroup from '../../../components/checkbox/group';
import CheckboxItem from '../../../components/checkbox';
import Loader from '../../../components/common/Loader';

export class AdminTypeOfWorkContractorV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      search: '',
      typeOfWorkGroups: [],
      isAllowEdit: isAllow('EDIT_WORK_TYPE'),
    };
  }

  async componentDidMount() {
    this.getTypeOfWorks();
  }

  async getTypeOfWorks() {
    try {
      this.setState({ isLoading: true });
      const typeOfWorks = await this.props.typeofwork.getTypeOfWorks();
      if (!typeOfWorks || !typeOfWorks.length) {
        return this.notification.error('TYPE OF WORK NOT FOUND');
      }

      const contractorInfo = this.props.contractor.toJS().info;
      if (!contractorInfo) {
        return this.notification.error('CONTRACTOR NOT FOUND');
      }

      const contractorTypeOfWork = _.get(contractorInfo, 'typeOfWork', []);
      const contractorTypeOfWorkApproved = _.get(contractorInfo, 'typeOfWorkApproved', []);

      const filteredWorkList = _.filter(typeOfWorks, (work) =>
        _.includes(contractorTypeOfWork, work.value),
      );

      const groupedWorkList = _.groupBy(
        filteredWorkList,
        (work) => `${work.group_name}-${work.code_group}`,
      );

      const result = _.chain(groupedWorkList)
        .map((items, key) => {
          const [group_name, code_group] = key.split('-');

          // Sort the items by 'name'
          const sortedItems = _.orderBy(items, ['name'], ['asc']);

          return {
            group_name,
            code_group,
            expand: true,
            items: _.map(sortedItems, (item) => ({
              _id: item._id,
              value: item.value,
              code: item.code,
              name: item.name,
              checked: _.includes(contractorTypeOfWorkApproved, item.value),
            })),
          };
        })
        // Sort the result by 'group_name'
        .orderBy(['group_name'], ['asc'])
        .value();

      this.setState({
        typeOfWorkGroups: result,
      });
    } catch (error) {
      this.notification.error(error.message);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  // Handle checkbox change for group or individual items
  handleCheckedBox = (type, item, value) => {
    if (type === 'group') {
      const updatedGroups = this.state.typeOfWorkGroups.map((group) => {
        if (group.group_name === item.group_name) {
          return {
            ...group,
            items: group.items.map((subItem) => ({ ...subItem, checked: value })),
          };
        }
        return group;
      });

      this.setState({ typeOfWorkGroups: updatedGroups });
    } else if (type === 'item') {
      const updatedGroups = this.state.typeOfWorkGroups.map((group) => {
        return {
          ...group,
          items: group.items.map((subItem) => {
            if (subItem._id === item._id) {
              return { ...subItem, checked: value };
            }
            return subItem;
          }),
        };
      });

      this.setState({ typeOfWorkGroups: updatedGroups });
    }
  };

  // Filter groups based on search input
  handleSearchChange = (value) => {
    this.setState({ search: value });
  };

  // Handle Expand/Collapse group
  handleExpandGroup = (group) => {
    const updatedGroups = this.state.typeOfWorkGroups.map((item) => {
      if (item.group_name === group.group_name) {
        return { ...item, expand: !item.expand };
      }
      return item;
    });

    this.setState({ typeOfWorkGroups: updatedGroups });
  };

  // Filter groups based on search input
  filterGroups = () => {
    const { typeOfWorkGroups, search } = this.state;
    const lowercasedSearch = search.toLowerCase();

    if (!lowercasedSearch) return typeOfWorkGroups;

    return typeOfWorkGroups
      .map((group) => {
        const filteredGroup = group.group_name.toLowerCase().includes(lowercasedSearch);
        if (filteredGroup) {
          return group;
        }

        const filteredItems = group.items.filter((item) =>
          item.name.toLowerCase().includes(lowercasedSearch),
        );
        if (filteredItems.length) {
          // if found items, return group and all items
          return group;
        } else {
          // if not found any items, return group with empty items
          return { ...group, items: [] };
        }
      })
      .filter((group) => group.items.length > 0);
  };

  // Get Count of checked items
  getCheckedCount = (group) => {
    return group.items.reduce(
      (acc, item) => {
        if (item.checked) acc.checked += 1;
        acc.unChecked += 1;
        return acc;
      },
      { checked: 0, unChecked: 0 },
    );
  };

  // Submit type of work
  clickSubmitTypeOfWork = async () => {
    try {
      const contractorId = this.props.id;
      const typeOfWorksApproved = this.state.typeOfWorkGroups.reduce((acc, group) => {
        group.items.forEach((item) => {
          if (item.checked) {
            acc.push(item.value);
          }
        });
        return acc;
      }, []);

      await this.props.contractor.saveInfo('typeOfWorkApproved', typeOfWorksApproved);
      await this.props.contractor.updateContractorInfo(contractorId, 'typeOfWorkApproved');
      await this.notification.success('บันทึกข้อมูลสำเร็จ');
      window.location.reload();
    } catch (error) {
      this.notification.error(error.message);
    }
  };

  render() {
    const { isLoading } = this.state;
    return (
      <div className="card-content" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
        <Loader show={isLoading} />
        <Notification ref={(ref) => (this.notification = ref)} />

        <div className="seo">
          <div className="header-seo">
            <span>ประเภทงาน</span>
          </div>

          <div style={{ margin: '1rem' }}>
            {/* SECTION SEARCH */}
            <div style={{ margin: '10px' }}>
              <SearchBar
                placeholder="ค้นหาประเภทงาน"
                value={this.state.search}
                onChange={(value) => this.handleSearchChange(value)}
              />
            </div>

            {/* SECTION ITEMS LIST */}
            <div style={{ margin: '10px' }}>
              {this.filterGroups().map((group, index) => {
                const allChecked = group.items.every((subItem) => subItem.checked);
                const someChecked = group.items.some((subItem) => subItem.checked);
                const indeterminate = someChecked && !allChecked;

                // count checked items
                const counter = this.getCheckedCount(group);

                return (
                  <div key={index}>
                    <CheckboxGroup
                      label={group.group_name}
                      isShowCounter
                      counter={counter}
                      isExpandAble
                      isExpand={group.expand}
                      onExpand={() => this.handleExpandGroup(group)}
                      disabled={!this.state.isAllowEdit}
                      indeterminate={indeterminate}
                      checked={allChecked}
                      onChange={(checked) => this.handleCheckedBox('group', group, checked)}
                    />

                    {group.expand &&
                      group.items.map((subItem, subIndex) => (
                        <div key={subIndex} style={{ paddingLeft: '20px' }}>
                          <CheckboxItem
                            label={subItem.name}
                            checked={subItem.checked}
                            disabled={!this.state.isAllowEdit}
                            onChange={(checked) => this.handleCheckedBox('item', subItem, checked)}
                          />
                        </div>
                      ))}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <EnButton
          className="btn-info pull-right"
          disabled={!this.state.isAllowEdit}
          onClick={this.clickSubmitTypeOfWork}
        >
          <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />
          บันทึก
        </EnButton>
      </div>
    );
  }
}

export default inject('contractor', 'typeofwork')(observer(AdminTypeOfWorkContractorV2));
