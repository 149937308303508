import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import Notification from '../../../components/common/Notification';
import config from '../../../config';
import EnToggle from '../../../components/form/EnToggle'
export class ManageMaterial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onAddNew() {
    this.props.history.push(`${config.publicUrl}/superadmin/material/add`);
  }

  onEdit(item) {
    if (item) {
      this.props.history.push(`${config.publicUrl}/superadmin/material/${item.id}/edit`);
    }
  }

  onToggle(index, value) {
    this.props.material.toggleStatus(index, value ? 'active' : 'inactive');
  }

  async componentDidMount() {
    try {
      await this.props.material.getMaterialList();
      await this.props.typeofwork.getTypeOfWorks();
    } catch (err) {
      console.log('err >>>>>>>>>>>>>>>>', err);
    }
  }

  getTableRows(items) {
    return _.orderBy(items, ['code']).map((item, index) => {
      return (
        <tr key={item.id}>
          <td className="text-center">{index + 1}</td>
          <td>{item.code}</td>
          <td>{item.name ? item.name.th : ''}</td>
          <td width="5%" className="text-center">
            <EnToggle checked={item.status === 'active'} onChange={this.onToggle.bind(this, index)} />
          </td>
          <td className="text-center">
            <button className="btn btn-xs" title="แก้ไข" data-tip="แก้ไข" onClick={this.onEdit.bind(this, item)}>
              <span className="fa fa-pencil" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  toUploadMaterial() {
    this.props.history.push(`${config.publicUrl}/superadmin/material/upload`);
  }

  toDownloadTemplate() {
    window.location.href = config.excelTemplate.fileSku.path
  }

  mapTypeOfWork() {
    const typeOfWorks = this.props.typeofwork.mapIdAndLabel();
    let materialItems = this.props.material.toJS().items;

    _.forEach(materialItems, matItem => {
      const res = _.map(matItem.typeOfWorks, matTypeOfWorks => {
        return _.find(typeOfWorks, type => {
          return type.id === matTypeOfWorks
        });
      });
      matItem.mapTypeOfWorks = res;
    });
    return materialItems;
  }

  render() {
    let items = this.mapTypeOfWork();
    const userInfo = this.props.auth.getUserInfo();
    const channel = userInfo.channel ? userInfo.channel.channelName : '';
    const isSuperadmin = this.props.auth.isSuperAdmin();
    const listMaterial = isSuperadmin ? items : items.filter(t => t.channel === channel);
   
    let rows = this.getTableRows(listMaterial);
    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.isLoading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายการวัสดุ</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                     <button className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={e=>{this.toUploadeSku()}} disabled={true}>
                      {/* <span className="fa fa-plus btn-icon" aria-hidden="true" /> */}
                      อัพโหลดรายการวัสดุ
                    </button>
                    <button className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={e=>{this.toDownloadTemplate()}} disabled={true}>
                      {/* <span className="fa fa-plus btn-icon" aria-hidden="true" /> */}
                      ดาวน์โหลดเทมเพลต
                      </button>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="10%" className="text-center">ลำดับที่</th>
                            <th width="25%" className="text-center">รหัสวัสดุ</th>
                            <th width="50%" className="text-center">ชื่อวัสดุ</th>
                             <th width="10%" className="text-center">สถานะ</th>
                            <th width="5%" className="text-center">
                              <button className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={this.onAddNew.bind(this)}>
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'material', 'typeofwork')(observer(ManageMaterial));
