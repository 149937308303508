import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import EnButton from '../../../../components/form/EnButton';
import EnDatePicker from '../../../../components/form/EnDatePicker';
import EnTagSelect from '../../../../components/form/EnTagSelect';
import EnText from '../../../../components/form/EnText'
import EnDropDown from '../../../../components/form/EnDropDown';

export class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: this.initQuery(),
    };
  }

  componentDidMount() {
    const { query } = this.props;
    this.setState({
      query,
    });
  }

  initQuery() {
    return {
      page_size: 10,
      page: 1,
      page_range: 5,
      channel: '',
      product: '',
      province: '',
      code: '',
      status: '',
      effectiveDate: undefined,
    };
  }

  onQueryChange(key, value) {
    const query = this.state.query;
    _.set(query, key, value)
    this.setState({
      query,
    });
  }

  resetQuery() {
    this.setState({
      query: this.initQuery(),
    });
  }

  onSearchClick() {
    this.props.onSearchClick(this.state.query);
  }

  onClearSearchClick() {
    this.resetQuery();
    this.props.onClearSearchClick();
  }

  onSelectChannel(value) {
    this.onQueryChange('channel', value)
  }

  onSelectProvince(value) {
    this.onQueryChange('province', value)
  }

  render() {
    const { product = [], status = [] } = this.props;
    const channels = (this.props.channel || []).map((item) => ({
      label: item.channel_name,
      value: item.channel_name
    }))
    const provinces = (this.props.province || []).map((item) => ({
      label: item.name,
      value: item.code
    }))
    const { query } = this.state;
    const productOptions = (product || []).map((d) => {
      return {
        label: `${_.get(d, 'skuCode')}: ${_.get(d, 'name.th', '')}`,
        value: d['id'],
      };
    });

    return (
      <div className="row">
        <div className="col-md-12" style={{ marginTop: '15px' }}>
          <div className="form-horizontal">
            <div className="form-group col-md-6">
              <label className="col-md-3 control-label">ช่องทาง</label>
              <div className="col-md-8">
                <EnTagSelect className="coupon-search-select" placeholder="--- เลือก ---" options={channels} value={query.channel} multi={false} searchable={true} onTagChange={this.onSelectChannel.bind(this)} />
              </div>
            </div>
            <div className="form-group col-md-6">
              <label className="col-md-3 control-label">สินค้า</label>
              <div className="col-md-8">
                <EnTagSelect
                  multi={false}
                  closeOnSelect
                  options={productOptions}
                  onTagChange={(selectedItem) => {
                    this.onQueryChange('product', selectedItem);
                  }}
                  value={query.product}
                  searchable
                  className="coupon-search-select"
                />
              </div>
            </div>
            <div className="form-group col-md-6">
              <label className="col-md-3 control-label">จังหวัด</label>
              <div className="col-md-8">
                <EnTagSelect
                  className="coupon-search-select"
                  id="province"
                  placeholder="--- เลือก ---"
                  options={provinces}
                  value={query.province}
                  multi={false}
                  onTagChange={this.onSelectProvince.bind(this)} />
              </div>
            </div>
            <div className="form-group col-md-6">
              <label className="col-md-3 control-label" htmlFor="customerName">
                โค้ดส่วนลด
              </label>
              <div className="col-md-8">
                <EnText
                  type="text"
                  className="form-control"
                  id="code"
                  placeholder=""
                  value={query.code}
                  onChange={(e) => {
                    this.onQueryChange('code', e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="form-group col-md-6">
              <label className="col-md-3 control-label">สถานะ</label>
              <div className="col-md-8">
                <EnDropDown
                  className="form-control"
                  id="status"
                  value={query.status}
                  onChange={(e) => {
                    this.onQueryChange('status', e.target.value);
                  }}>
                  <option value={''}>--- เลือก ---</option>
                  {status.map((st, index) => (
                    <option key={`st-${index}`} value={st.value}>
                      {st.name}
                    </option>
                  ))}
                </EnDropDown>
              </div>
            </div>
            <div className="form-group col-md-6">
              <label className="col-md-3 control-label" htmlFor="startDate">
                วันที่มีผล
              </label>
              <div className="col-md-8">
                <EnDatePicker
                  id="effectiveDate"
                  initialDate={query.effectiveDate ? moment(query.effectiveDate) : undefined}
                  onDateChange={(date) => {
                    if (date) this.onQueryChange('effectiveDate', date.format('YYYY-MM-DD'));
                  }}
                  isOutsideRange={() => false}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <div className="col-md-offset-2 col-md-10">
                  <EnButton className="btn-warning pull-right" onClick={this.onClearSearchClick.bind(this)}>
                    <i className="fa fa-refresh btn-icon" aria-hidden="true" />
                    เคลียร์
                  </EnButton>
                  <EnButton className="btn-info pull-right" onClick={this.onSearchClick.bind(this)}>
                    <i className="fa fa-search btn-icon" aria-hidden="true" />
                    ค้นหา
                  </EnButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('coupon')(observer(SearchForm));
