import React from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import Container from '../../../layouts/Container';
import SearchForm from './components/SearchForm';
import config from '../../../config';

export class ManageTrainingCourseOld extends React.Component {
  async componentWillMount() {
    await this.props.trainingcourse.getAllTrainingCourse();
  }

  onView(trainingDtUuid) {
    this.props.history.push(`${config.publicUrl}/superadmin/training/${trainingDtUuid}/register`);
  }

  getTableRows(course) {
    return course.map((item, index) => {
      return (
        <tr key={index}>
          <td width="5%" className="text-center">{moment(item.trainingDate).format('LL')}</td>
          <td width="5%" className="text-center">{item.trainingStartTime} - {item.trainingEndTime}</td>
          <td width="15%">{item.trainingName || ''}</td>
          <td width="15%">{item.trainingDesc || ''}</td>
          <td width="10%">{item.locationName}</td>
          <td width="5%" className="text-center">{item.traingOpen}</td>
          <td width="5%" className="text-center">{item.trainingBalance}
            {/* <a href={`${config.publicUrl}/superadmin/training/${item.trainingDtUuid}/register`} target="_self">{item.trainingBalance}</a> */}
          </td>
          <td width="5%" className="text-center">{}</td>
          <td width="5%" className="text-center">
            <button className="btn btn-primary" title="ดูรายละเอียด" data-tip="ดูรายละเอียด" onClick={this.onView.bind(this, item.trainingDtUuid)}>
              <span className="fa fa-user-circle-o" aria-hidden="true" />
            </button>
          </td>
        </tr>
      );
    });
  }

  async onSearchClick(e) {
    await this.props.trainingcourse.searchTrainingCourse();
  }

  async onClearSearchClick(e) {
    this.props.trainingcourse.setDefaultQuery();
    await this.props.trainingcourse.getAllTrainingCourse();
  }

  render() {
    const {course, query} = this.props.trainingcourse.toJS();
    const rows = this.getTableRows(course);

    return (
      <Container isAdmin>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">คอร์สอบรม</h4>
              </div>
              <div className="card-content">
                <SearchForm
                  query={query}
                  onSearch={() => { this.onSearchClick(); }}
                  onClear={() => { this.onClearSearchClick(); }}
                />
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            {/* <th width="5%" className="text-center">ลำดับที่</th> */}
                            <th width="5%" className="text-center">วัน</th>
                            <th width="5%" className="text-center">เวลา</th>
                            <th width="15%" className="text-center">ชื่อคอร์ส</th>
                            <th width="15%" className="text-center">รายละเอียดคอร์ส</th>
                            <th width="10%" className="text-center">สถานที่</th>
                            <th width="10%" className="text-center">จำนวนที่นั่งเปิดรับ</th>
                            <th width="10%" className="text-center">จำนวนช่างที่เข้าร่วม</th>
                            <th width="10%" className="text-center">สถานะคอร์ส</th>
                            <th width="5%" className="text-center" />
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-12">
                    <Pagination
                      pagination={pagination}
                      handlePageClick={this.onPageClick.bind(this)}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('trainingcourse')(observer(ManageTrainingCourseOld));
