import axios from 'axios';
import querystring from 'querystring';
import config from '../../config';

export class GoogleMap {
  constructor() {
    this.httpReq = axios.create({
      validateStatus: status => {
        return true;
      },
    });
    this.StatusOK = 200;
  }

  transformResponse(response) {
    return {
      status: response.status,
      statusText: response.statusText,
      data: response.data,
    };
  }

  async get(url) {
    let response = await this.httpReq.get(url);
    return this.transformResponse(response);
  }

  async post(url, data = {}) {
    let response = await this.httpReq.post(url, data);
    return this.transformResponse(response);
  }

  getLocationFromAddress = async (address) => {
    const query = {
      // components: `country:TH|postal_code:${address.postcode}`,
      components: `country:TH|locality:${address.subDistrict.name}|administrative_area:${address.district.name}`,
      language: 'th',
      key: config.service.googleAppKey,
    };
    const googleApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?${querystring.stringify(query)}`;
    return this.get(googleApiUrl);
  }

  getDistance = async (latlngA, latlngB) => {
    const query = {
      origins: `${latlngA[1]},${latlngA[0]}`,
      destinations: `${latlngB[1]},${latlngB[0]}`,
      key: config.service.googleAppKey,
    };
    const googleApiUrl = `https://maps.googleapis.com/maps/api/distancematrix/json?${querystring.stringify(query)}`;
    return this.get(googleApiUrl);
  }
}
const GMap = new GoogleMap();
export default GMap;
