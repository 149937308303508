import BaseStore from '../BaseStore';
import config from '../../config';
import { http } from '../../utils/http';

export class ProjectConfig extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      projects: [],
      selectedIds: '',
    });
  }

  setSelectedIds(ids) {
    this.selectedIds = ids;
  }

  async getActiveProjects() {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.get(`${config.api.qchang}/v1/projects?active=true&skip=0&page_size=100&sort=_id|desc`);
      if (res.status === 200 && res.data) {
        this.projects = res.data.data || [];
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getRecentProjectIds() {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.get(`${config.api.qchang}/v1/projects/recent?show=id`);
      if (res.status === 200 && res.data) {
        const ids = res.data.data || [];
        this.selectedIds = ids.join();
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateRecentProjectIds() {
    if (this.isLoading) return;
    this.isLoading = true;
    const { selectedIds } = this.toJS();
    const ids = selectedIds.split(',');
    try {
      const res = await http.put(`${config.api.qchang}/v1/projects/recent`, {
        id: ids,
      });
      if (res.status !== 200) {
        if (res.data && res.data.message === 'ARRAY_OF_ID_REQUIRED') {
          throw new Error('กรุณาเลือกผลงาน');
        }
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new ProjectConfig();
