import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import Container from '../../../layouts/Container';
import Loader from '../../../components/common/Loader';
import Notification from '../../../components/common/Notification';
import config from '../../../config';
import swal from 'sweetalert2';
import { isAllow } from '../../../utils/permission';
import FormGroup from '../../../components/form/FormGroup';
import EnText from '../../../components/form/EnText';
import SimplePagination from '../../../components/common/SimplePagination';

export class ManageProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      search: '',
      items: [],
      query: {
        search: '',
        limit: 10,
        page: 1,
        totalPages: 1,
        totalItems: 1
      },
    };
  }

  async componentDidMount() {
    if (!isAllow('VIEW_A_LIST_OF_PRODUCT')) {
      this.props.history.push(`${config.publicUrl}/`);
    }
    try {
      this.getProductList()
    } catch (err) {
      this.noti.error(err.message);
    }
  }

  onAddNew() {
    this.props.history.push(`${config.publicUrl}/qchang/product/add`);
  }

  onEdit(item) {
    if (item) {
      this.props.history.push(`${config.publicUrl}/qchang/product/${item.id}/edit`);
    }
  }

  async onDelete(item) {
    let confirmDialogOptions = {
      title: 'ลบข้อมูล',
      text: 'คุณต้องการลบ Product นี้ใช่หรือไม่',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.props.product.deleteProduct(item.id);
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });

      setTimeout(() => {
        window.location.reload();
        // this.onCancel();
      }, 1000);
    }
  }

  getProductList() {
    this.setState({ loading: true })

    return this.props.product
      .getPaginatedProducts(this.state.query)
      .then(({ items, limit, page, totalPages, totalItems }) => {
        this.setState({
          loading: false,
          items,
          query: {
            ...this.state.query,
            limit,
            page,
            totalPages,
            totalItems,
          },
        });
      });
  }

  onUpdateQuery = (key, value) => {
    const query = this.state.query
    _.set(query, key, value)
    this.setState(query)
  }

  onUpdateSearch = (search) => {
    this.setState({ search })
  }

  onSetPage = (page) => {
    this.onUpdateQuery('page', page)
    this.getProductList()
  }

  onClickSearch = () => {
    this.onUpdateQuery('search', this.state.search)
    this.onUpdateQuery('page', 1)
    this.getProductList()
  }


  getTableRows(items) {
    const itemsOrder = _.orderBy(items, 'skuCode');
    // const userInfo = this.props.auth.getUserInfo();
    // const channel = userInfo.channel ? userInfo.channel.channelName : '';
    const channel = this.props.auth.getChannelName();
    let isSCGHome = (channel === 'SCGHOME Online');
    return itemsOrder.map((item, index) => {
      return (
        <tr key={item.id}>
          <td className="text-center">{((this.state.query.page - 1) * this.state.query.limit) + index + 1}</td>
          <td>{item.skuCode}</td>
          <td>{item.name ? item.name.th : ''}</td>
          <td>{(item.seo && item.seo.slug) ? item.seo.slug : ''}</td>
          <td className="text-center">
            <button className="btn btn-xs" title="แก้ไข" data-tip="แก้ไข" onClick={this.onEdit.bind(this, item)}>
              <span className="fa fa-pencil" aria-hidden="true" />
            </button>
            {(!isSCGHome) && (
              <button className="btn btn-xs" title="ลบ" data-tip="ลบ" onClick={this.onDelete.bind(this, item)}>
                <span className="fa fa-trash btn-icon" aria-hidden="true" />
              </button>
            )}
          </td>
        </tr>
      );
    });
  }

  toUploadeProduct() {
    this.props.history.push(`${config.publicUrl}/qchang/product/upload`);
  }
  async toDownloadTemplate() {
    const { fileUrl = '' } = await this.props.download.getSignUrl('20210802110852_PRODUCT_UPLOADE_EXAM.xlsx', 'product');
    if (fileUrl) {
      window.location.href = fileUrl;
    }
  }

  render() {
    // const userInfo = this.props.auth.getUserInfo();
    // const channel = userInfo.channel ? userInfo.channel.channelName : '';
    const channel = this.props.auth.getChannelName();
    const isSuperadmin = this.props.auth.isSuperAdmin();
    // const activeItem = items.filter(t => t.isActive);
    // const listProduct = isSuperadmin ? activeItem : activeItem.filter(t => (t.channel === channel || (t.channel || []).includes(channel)));

    let rows = this.getTableRows(this.state.items);

    return (
      <Container isAdmin>
        <div className="row">
          <Loader show={this.state.loading} />
          <Notification ref={(ref) => { this.noti = ref; }} />
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายการสินค้า</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    <button className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={e => { this.toUploadeProduct() }}>
                      {/* <span className="fa fa-plus btn-icon" aria-hidden="true" /> */}
                      อัพโหลดรายการสินค้า
                    </button>
                    <button className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={e => { this.toDownloadTemplate() }}>
                      {/* <span className="fa fa-plus btn-icon" aria-hidden="true" /> */}
                      ดาวโหลดไฟล์ตัวอย่างไฟล์ สินค้า
                    </button>
                  </div>
                </div>
                <div className='row'>
                  <FormGroup label="ค้นหา:" containerClassName="col-md-4">
                    <div className='form-group has-feedback has-clear'>
                      <div className='input-group'>
                        <EnText className='form-control' placeholder='ค้นหาด้วยรหัสสินค้า หรือ ชื่อสินค้า' value={this.state.search} onChange={(e) => this.onUpdateSearch(e.target.value)} onKeyPress={(e) => { if (e.key === 'Enter') this.onClickSearch() }} />
                        <span className='input-group-btn'>
                          <button
                            id='search-button'
                            type='button'
                            className='btn btn-primary btn-outline-secondary btn-custom'
                            onClick={() => { this.onClickSearch() }}
                          >
                            <i className='fa fa-search' aria-hidden='true' />
                          </button>
                        </span>
                      </div>
                    </div>
                  </FormGroup>
                </div>
                <div className='row'>
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="10%" className="text-center">ลำดับที่</th>
                            <th width="15%" className="text-center">รหัส product</th>
                            <th width="40%" className="text-center">ชื่อสินค้า</th>
                            <th width="30%" className="text-center">Slug</th>
                            <th width="5%" className="text-center">
                              <button className="btn btn-success" title="สร้างใหม่" data-tip="สร้างใหม่" onClick={this.onAddNew.bind(this)}>
                                <span className="fa fa-plus btn-icon" aria-hidden="true" />สร้างใหม่
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-12'>
                    <SimplePagination pagination={this.state.query} onSetPage={this.onSetPage} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'product', 'download')(observer(ManageProduct));
