export const ColorPlate = [
  { color: '#0e1f2e', label: '#0e1f2e' },
  { color: '#ef4e30', label: '#ef4e30' },
  { color: '#d1d3d4', label: '#d1d3d4' },
  { color: '#002b4d', label: '#002b4d' },
  { color: '#6c4f8e', label: '#6c4f8e' },
  { color: '#456c9b', label: '#456c9b' },
  { color: '#e16a61', label: '#e16a61' },
  { color: '#2d5e58', label: '#2d5e58' },
  { color: '#000000', label: '#000000' },
  { color: '#ffffff', label: '#ffffff' },
  { color: '#ff0000', label: '#ff0000' },
  { color: '#707070', label: '#707070' },
  { color: '#ffea00', label: '#ffea00' },
  { color: '#42ff00', label: '#42ff00' },
  { color: '#ff00fc', label: '#ff00fc' },
  { color: '#ff6600', label: '#ff6600' },
  { color: '#ff00ff', label: '#ff00ff' },
  { color: '#9dd4a3', label: '#9dd4a3' },
  { color: '#a4ddde', label: '#a4ddde' },
  { color: '#e9f0ad', label: '#e9f0ad' },
  { color: '#adb5f0', label: '#adb5f0' },
  { color: '#f0adad', label: '#f0adad' },
  { color: '#fac5a2', label: '#fac5a2' },
  { color: '#d4a5ab', label: '#d4a5ab' },
  { color: '#419afa', label: '#419afa' },
];