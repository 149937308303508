import React from 'react';
import '../../assets/css/phone-pad.css';

class NumberButton extends React.Component {
  render() {
    const { text, onPress } = this.props;
    return (
      <div className="pad-btn">
        <div className="num-btn" id={text} onClick={onPress}>
          <div className="num-txt noselect">{text}</div>
        </div>
      </div>
    );
  }
}

export default NumberButton;
