import axios from 'axios'
import { config } from '../config';

export async function getBackboneSkuFromCategory({ cateUUID = [], subCateUUID = [] }) {
  try {
    const url = `${config.api.sims}/qchang/v2/product/backboneSkuFromCategory`;
    let data
    if (cateUUID.length > 0) {
      data = { cateUUID };
    } else if (subCateUUID.length > 0) {
      data = { subCateUUID };
    } else {
      throw new Error('this api required cateUUID or subCateUUID');
    }

    const reponse = await axios({
      method: 'post',
      url,
      data,
      headers: { 'content-type': 'application/json' },
    });
    return reponse.data;
  } catch (error) {
    console.log('error getBackboneSkuFromCategory ------------->>', error);
    throw error;
  }
}
