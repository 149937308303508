import React, { Fragment } from 'react';
import { config } from '../../config';

export function submitPaymentForm(data, paymentUrl) {
  const form = document.getElementById('form2c2p');
  form.version.value = data.version
  form.merchant_id.value = data.merchant_id
  form.payment_description.value = data.payment_description
  form.order_id.value = data.order_id
  form.user_defined_1.value = data.user_defined_1
  form.user_defined_2.value = data.user_defined_2
  form.user_defined_3.value = data.user_defined_3
  form.user_defined_4.value = data.user_defined_4
  form.user_defined_5.value = data.user_defined_5
  form.paymentId.value = data.paymentId
  form.amount.value = data.amount
  form.currency.value =  data.currency
  form.email.value = data.email
  form.result_url_1.value = data.result_url_1
  form.result_url_2.value = data.result_url_2
  form.payment_option.value = data.payment_option
  form.ipp_interest_type.value = data.ipp_interest_type
  form.ipp_period_filter.value = data.ipp_period_filter
  form.hash_value.value = data.hash_value
  form.action = paymentUrl
  form.submit()
}


export class Payment2C2P extends React.Component {

    render() {
        return (
            <Fragment>
              <form id="form2c2p" method="post">
                <input type="hidden" name="version" />
                <input type="hidden" name="merchant_id" />
                <input type="hidden" name="payment_description"/>
                <input type="hidden" name="order_id" />
                <input type="hidden" name="user_defined_1" />
                <input type="hidden" name="user_defined_2"/>
                <input type="hidden" name="user_defined_3"/>
                <input type="hidden" name="user_defined_4"/>
                <input type="hidden" name="user_defined_5" />
                <input type="hidden" name="paymentId"/>
                <input type="hidden" name="amount" />
                <input type="hidden" name="currency" />
                <input type="hidden" name="email" />
                <input type="hidden" name="result_url_1" />
                <input type="hidden" name="result_url_2"/>
                <input type="hidden" name="payment_option"/>
                <input type="hidden" name="ipp_interest_type" />
                <input type="hidden" name="default_lang" value={`th`} />
                <input type="hidden" name="ipp_period_filter"  />
                <input type="hidden" name="hash_value" />
              </form>
            </Fragment>
          );
    }

}
export default Payment2C2P;