import React from 'react';

export const FileContainer = ({ children, index, handleDragStart, handleDrop }) => {
  return (
    <div
      key={index}
      style={{
        width: '200px',
        height: '200px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'grab',
        position: 'relative',
      }}
      draggable
      onDragStart={(e) => handleDragStart(e, index)}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => handleDrop(e, index)}
    >
      {children}
    </div>
  );
};

export const FileContent = ({ file }) => {
  if (file instanceof File) {
    return (
      <img
        src={URL.createObjectURL(file)}
        alt={file.name}
        style={{
          minWidth: '100%',
          minHeight: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
      />
    );
  }

  return (
    <img
      src={file}
      alt={file}
      style={{
        minWidth: '100%',
        minHeight: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      }}
    />
  );
};
