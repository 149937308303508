import React from 'react';
import { observer, inject } from 'mobx-react';
import { FormValidation, required, pin, pinConfirm } from '../../../components/form/FormValidation';
import EnButton from '../../../components/form/EnButton';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import EnStickyFooter from '../../../components/form/EnStickyFooter';
import Notification from '../../../components/common/Notification';
import config from '../../../config';

class Pin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: '',
      pinConfirm: '',
      maxPinLength: 6,
      step: 4,
      stepName: 'ระบุรหัสผ่านเพื่อใช้งานระบบ',
    };
  }

  async clickNext() {
    try {
      this.props.contractor.saveInfo('pin', this.state.pin);
      this.props.contractor.validateInput(this)
        .then(() => {
          if (this.state.pin) {
            this.props.history.push(`${config.publicUrl}/contractors/register/profilepicture`);
          }
        })
        .catch((err) => {
          this.noti.error(err.message);
        });
    } catch (err) {
      this.noti.error(err.message);
    }
  }

  validation() {
    return this.props.contractor.isValidRegisterInfo(4) &&
      this.state.pin &&
      this.state.pinConfirm &&
      this.state.pin.length === this.state.maxPinLength &&
      this.state.pin === this.state.pinConfirm;
  }

  render() {
    if (this.state.isRegistered) {
      return null;
    }

    let button = (
      <EnButton className="btn-info pull-right" onClick={this.clickNext.bind(this)}>
        <i className="fa fa-arrow-right btn-icon" aria-hidden="true" />ถัดไป
      </EnButton>
    );
    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <Notification ref={e => { this.noti = e; }} />
            <div className="col-md-offset-2 col-md-8">
              <div className="card">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">ลงทะเบียน</h4>
                  <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
                </div>

                <div className="card-content">
                  <FormValidation ref="FormValidation">
                    <h5>กรุณาระบุรหัสผ่านเพื่อใช้เข้าระบบ</h5>
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="password"
                      name="pin"
                      size={this.state.maxPinLength}
                      maxLength={this.state.maxPinLength}
                      label="รหัสผ่าน (ตัวเลข 6 หลัก)"
                      value={this.state.pin}
                      onChange={(e) => this.setState({ pin: e.target.value })}
                      validations={[required, pin]} />

                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="password"
                      name="confirm"
                      size={this.state.maxPinLength}
                      maxLength={this.state.maxPinLength}
                      label="ยืนยันรหัสผ่าน"
                      value={this.state.pinConfirm}
                      onChange={(e) => this.setState({ pinConfirm: e.target.value })}
                      validations={[required, pin, pinConfirm]} />

                    <EnStickyFooter step={this.state.step} stepName={this.state.stepName} button={button} />
                  </FormValidation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject('contractor')(observer(Pin));
