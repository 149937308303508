import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
// import moment from 'moment';
import momentTz from 'moment-timezone';
import numeral from 'numeral';
import qs from 'query-string';
import Container from '../../../layouts/ContainerContractor';
import Notification from '../../../components/common/Notification';
import Loader from '../../../components/common/Loader';
import EnButton from '../../../components/form/EnButton';
import config from '../../../config';

export class TransferDetail extends Component {
  constructor(props) {
    super(props);
    const parsed = qs.parse(this.props.location.search);
    this.state = {
      id: this.props.match.params.id,
      effectiveDate: parsed.effective_date,
      gatewayName: parsed.gateway_name,
      backPage: parsed.back_page,
      backDate: parsed.back_date,
    };
  }

  async componentWillMount() {
    try {
      this.props.transferdetail.setDefaultQuery();
      const { id, effectiveDate, gatewayName } = this.state;
      if (id) {
        this.props.transferdetail.setQueryValue('contractor_id', id);
      }
      if (effectiveDate) {
        this.props.transferdetail.setQueryValue('effective_date', effectiveDate);
      }
      if (gatewayName) {
        this.props.transferdetail.setQueryValue('gateway_name', gatewayName);
      }
      await this.props.transferdetail.searchTranferDetail();
    } catch (err) {
      this.noti.error(err.message);
    }
  }

  onGoBackClick() {
    const { backDate, backPage } = this.state;
    const params = {
      effective_date: backDate,
      page: backPage,
    };
    const queryString = qs.stringify(params, { sort: false, skipNull: true });
    this.props.history.push(`${config.publicUrl}/contractors/${this.state.id}/transfer/summary?${queryString}`);
  }

  displayTableRows(data) {
    return data.map((d, index) => {
      // let joGrandTotal = 0;
      // if (Array.isArray(d.jobs) && d.jobs.length) {
      //   const [job] = d.jobs;
      //   if (Array.isArray(job.jo) && job.jo.length) {
      //     const [jo] = job.jo;
      //     joGrandTotal = jo.grand_total;
      //   }
      // }
      return (
        <tr>
          <td className="text-center">{index + 1}</td>
          <td className="text-center">{d.job_code}</td>
          <td className="text-center">{d.type_of_job === 'S' ? 'สำรวจ' : 'ติดตั้ง'}</td>
          <td className="text-center">{d.customer_name}</td>
          <td className="text-right">{numeral(d.amount).format('0,0.00')}</td>
          <td className="text-right">{numeral(d.wht_discount).format('0,0.00')}</td>
          <td className="text-right">{numeral(d.amount_exclude_wht).format('0,0.00')}</td>
          <td className="text-center">{momentTz(d.effective_date).utcOffset(7).format('ddd Do/M/YYYY')}</td>
        </tr>
      );
    });
  }

  displaySummaryRow(data) {
    let summary = {
      // totalAmount: 0,
      // totalWhtDiscount: 0,
      totalAmountExcludeWht: 0,
    };
    if (Array.isArray(data)) {
      summary = data.reduce((sum, d) => {
        return {
          // totalAmount: (sum.totalAmount + d.amount),
          // totalWhtDiscount: (sum.totalWhtDiscount + d.wht_discount),
          totalAmountExcludeWht: (sum.totalAmountExcludeWht + d.amount_exclude_wht),
        };
      }, summary);
    }
    return (
      <tr>
        <th colSpan={6} className="text-right" style={{ fontSize: '30px' }}>รวมทั้งหมด:</th>
        <th className="text-right" style={{ fontSize: '30px' }}>{numeral(summary.totalAmountExcludeWht).format('0,0.00')}</th>
        <td />
      </tr>
    );
  }

  render() {
    const { effectiveDate, gatewayName } = this.state;
    const { data, isLoading } = this.props.transferdetail.toJS();
    return (
      <Container>
        <Loader show={isLoading} />
        <Notification ref={(ref) => { this.noti = ref; }} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">รายละเอียดเงินที่ได้รับ</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <div className="col-md-12">
                    <h4>วันที่รับเงิน: &nbsp;&nbsp;{momentTz(effectiveDate).utcOffset(7).format('ddd Do/M/YYYY')}</h4>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover has-pagination">
                        <thead>
                          <tr>
                            <th width="5%" className="text-center">ลำดับที่</th>
                            <th width="10%" className="text-center">Job Code</th>
                            <th width="10%" className="text-center">ลักษณะงาน</th>
                            <th width="20%" className="text-center">ชื่อลูกค้า</th>
                            <th width="10%" className="text-center">JO</th>
                            <th width="10%" className="text-center">WHT ของส่วนลด</th>
                            <th width="10%" className="text-center">เงินที่ได้รับ</th>
                            <th width="10%" className="text-center">วันที่ได้รับเงิน</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.displayTableRows(data)}
                          {this.displaySummaryRow(data)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <EnButton className="btn-danger pull-left" onClick={this.onGoBackClick.bind(this)}>
                      <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                    </EnButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('transferdetail')(observer(TransferDetail));
