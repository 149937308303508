import * as _ from 'lodash';
import React, { Component } from 'react';

export class ListItem extends Component {
  onItemClick(key, data) {
    if (this.props.onClick) {
      this.props.onClick(key, data);
    }
  }

  render() {
    let { header, data, target } = this.props;

    let headerOption = [];
    let listHeaderItem = [];
    _.forEach(header, (h, ix) => {
      // h.title
      // h.size
      // h.className
      // h.itemClassName
      // h.showIcon
      // h.downloadLink
      // h.key

      headerOption.push({
        key: h.key,
        size: h.size,
        className: h.itemClassName,
        showIcon: h.showIcon,
        downloadLink: h.downloadLink,
        appendDescription: h.appendDescription,
        descriptionKey: h.descriptionKey,
      });
      listHeaderItem.push(<div key={ix} style={{ fontWeight: 700 }} className={`col-xs-${h.size} ${h.className ? h.className : ''}`}>{h.title}</div>);
    });

    let listDataItem = [];
    _.forEach(data, (d, ix) => {
      // className
      // icon
      // downloadHref

      let dataItem = [];
      _.forEach(headerOption, (o, ixItem) => {
        let linkOption = {};
        if (o.downloadLink) {
          linkOption['download'] = true;
          linkOption['href'] = d.downloadHref;
        } else {
          linkOption['onClick'] = this.onItemClick.bind(this, o.key, d);
        }

        if (target) {
          linkOption['target'] = target;
        }

        dataItem.push(
          <div key={ixItem} className={`col-xs-${o.size} ${o.className ? o.className : ''}`}>
            <a {...linkOption}>
              {o.showIcon ? <i className={d.icon} /> : ''}
              {o.showIcon ? ' ' : ''}
              {d[o.key]}
            </a>
            {o.appendDescription ? ' ' : ''}
            {o.appendDescription ? <small style={{ color: '#808B96' }}>{d[o.descriptionKey]}</small> : ''}
            {d.isShowDelete &&
              <a
                onClick={this.onItemClick.bind(this, 'delete', d)}>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fa fa-trash" />
              </a>
            }
          </div>
        );
      });

      listDataItem.push(
        <li key={ix} className={`list-group-item`}>
          <div className="row">
            {dataItem}
          </div>
        </li>
      );
    });

    return (
      <div>
        <ul className="list-group list-group-header">
          <li className="list-group-item list-group-body">
            <div className="row">
              {listHeaderItem}
            </div>
          </li>
        </ul>
        {
          (listDataItem && listDataItem.length)
            ? (
              <ul className="list-group list-group-body">
                {listDataItem}
              </ul>
            )
            : ''
        }
      </div>
    );
  }
}

export default ListItem;
