import humps from 'humps';
import BaseStore from '../BaseStore';
import config from '../../config';
import { http } from '../../utils/http';

export class Project extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      items: [],
      info: this.getDefaultInfo(),
    });
  }

  getDefaultInfo() {
    return {
      title: '',
      description: '',
      imageUrl: '',
      solutionId: '',
      productId: '',
      location: '',
      duration: '',
      area: '',
      ratingPoint: 5,
      reviewMessage: '',
      images: [],
      active: true,
      seo: {
        title: '',
        slug: '',
        keywords: '',
        description: '',
      },
    };
  }

  resetInfo() {
    this.info = this.getDefaultInfo();
  }

  saveInfo(key, value) {
    this.info[key] = value;
  }

  saveSeoInfo(key, value) {
    this.info.seo[key] = value;
  }

  async getProjectsBySolutionId(solutionId) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const res = await http.get(`${config.api.qchang}/v1/projects?solution_id=${solutionId}`);
      if (res.status === 200 && res.data) {
        this.items = res.data.data;
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getProjectById(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const res = await http.get(`${config.api.qchang}/v1/projects/${id}`);
      if (res.status !== 200 || !res.data || !res.data.data) {
        throw new Error('ไม่พบข้อมูล');
      }
      const data = humps.camelizeKeys(res.data.data);
      if (data.active === undefined || data.active == null) {
        data.active = false;
      }
      if (!data.seo) {
        data.seo = {
          title: '',
          slug: '',
          keywords: '',
          description: '',
        };
      }
      this.info = data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async createProject() {
    if (this.isLoading) return;
    this.isLoading = true;

    const { info } = this.toJS();
    try {
      const res = await http.post(`${config.api.qchang}/v1/projects`, info);
      if (res.status !== 200) {
        if (res.data && res.data.message === 'DUPLICATED_SLUG') {
          throw new Error('Slug ซ้ำ');
        }
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateProject(id) {
    if (this.isLoading) return;
    this.isLoading = true;

    const { info } = this.toJS();
    try {
      const res = await http.put(`${config.api.qchang}/v1/projects/${id}`, info);
      if (res.status !== 200) {
        if (res.data && res.data.message === 'DUPLICATED_SLUG') {
          throw new Error('Slug ซ้ำ');
        }
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async toggleStatus(index, id, active) {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const res = await http.put(`${config.api.qchang}/v1/projects/${id}/status`, { active });
      if (res.status === 200 && res.data) {
        this.items[index] = res.data.data;
      } else {
        throw new Error('การบันทึกล้มเหลว');
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new Project();
