import React, { Component, createRef } from 'react';
import { inject, observer } from 'mobx-react';
import readXlsxFile from 'read-excel-file';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import Container from '../../layouts/Container';
import { Alert, Col, Modal, Row } from 'react-bootstrap';
import config from '../../config';
import Loader from '../../components/common/Loader';
import Pagination from '../../components/common/Pagination';
import Notification from '../../components/common/Notification';

import '../../assets/css/additional-products-page.css';

const INVALID_FORMAT_ERROR_MESSAGE = 'ไม่สามารถอัปโหลดรายการสินค้าส่วนเพิ่มได้';

class AdditionalProductsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
      isInitializing: true,
      isUploading: false,
      showModal: false,
    };
    this.searchInputRef = createRef();
  }

  componentDidMount = () => {
    const { additionalProducts: additionalProductsStore } = this.props;
    additionalProductsStore.getAdditionalProducts().then(() => {
      this.setState({ isInitializing: false });
    });
  };

  handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleSearch();
    }
  };

  handleSearch = () => {
    const { additionalProducts: additionalProductsStore } = this.props;
    additionalProductsStore.getAdditionalProducts(this.searchInputRef.current.value);
  };

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ file: undefined, showModal: false });
  };

  downloadTemplateFile = () => {
    const link = document.createElement('a');
    link.href = config.additionalProducts.templateFileUrl;
    link.download = 'template_add_on_product_list.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handlePageClick = (pageNumber) => {
    const { additionalProducts: additionalProductsStore } = this.props;
    additionalProductsStore.getAdditionalProducts(this.searchInputRef.current.value, pageNumber);
  };

  preventDefaultAndStopPropagation = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDropFile = (event) => {
    this.preventDefaultAndStopPropagation(event);
    const file = event.dataTransfer.files[0];
    this.setState({ file });
  };

  handleFileChange = async (event) => {
    const file = event.target.files[0];
    this.setState({ file });
  };

  validateFile = async (file) => {
    const allowedFileExtensions = ['.xlsx', '.xls'];
    const invalidFileExtension = !allowedFileExtensions.some((ext) => file.name.endsWith(ext));
    const allowedFileSize = 5 * 1024 * 1024;
    const invalidFileSize = file.size > allowedFileSize;

    if (invalidFileExtension || invalidFileSize) {
      throw new Error(INVALID_FORMAT_ERROR_MESSAGE);
    }
  };

  validateRows = (rows) => {
    const { additionalProducts: additionalProductsStore } = this.props;
    const expectedHeaderRow = ['รหัสสินค้า', 'รายการสินค้า', 'หน่วย', 'ราคามาตราฐาน', 'ราคาจ่ายช่าง'];
    let errorCount = 0;
    const errorList = [];
    const toExportErrorList = [[...expectedHeaderRow, 'สาเหตุความผิดพลาด']];
    const toExportRedColorList = [];
    const [headerRow, ...dataRows] = rows;

    if (!_.isEqual(headerRow, expectedHeaderRow)) {
      throw new Error(INVALID_FORMAT_ERROR_MESSAGE);
    }

    dataRows.forEach((row, index) => {
      const [productCode, productName, unit, unitPrice, contractorCost] = row;
      const errorTags = [];
      const toExportIndex = index + 2;

      if (
        row.length !== expectedHeaderRow.length ||
        !_.isString(productCode) ||
        !_.isString(productName) ||
        !_.isString(unit) ||
        isNaN(unitPrice) ||
        isNaN(contractorCost)
      ) {
        throw new Error(INVALID_FORMAT_ERROR_MESSAGE);
      }
      if (
        dataRows.some(
          ([checkingProductCode], checkingIndex) => checkingIndex !== index && checkingProductCode.toLowerCase() === productCode.toLowerCase(),
        )
      ) {
        errorTags.push('รหัสสินค้าซ้ำ');
      }
      if (!productCode.match(/^[a-zA-Z]+-\d{3}$/)) {
        errorTags.push('รหัสสินค้าไม่ถูกต้อง');
      }
      if (['รหัสสินค้าซ้ำ', 'รหัสสินค้าไม่ถูกต้อง'].includes(_.last(errorTags))) {
        toExportRedColorList.push(`A${toExportIndex}`);
      }
      if (
        dataRows.some(
          ([_, checkingProductName], checkingIndex) => checkingIndex !== index && checkingProductName.toLowerCase() === productName.toLowerCase(),
        )
      ) {
        errorTags.push('รายการสินค้าซ้ำ');
        toExportRedColorList.push(`B${toExportIndex}`);
      }
      if (errorTags.length) {
        errorCount += 1;
        errorList.push({ productCode, productName, errorTags });
      }

      toExportErrorList.push([productCode, productName, unit, unitPrice, contractorCost, errorTags.join(',')]);
    });

    if (errorCount > 0) {
      additionalProductsStore.setErrorList({ errorCount, errorList, toExportErrorList, toExportRedColorList });
      throw new Error('อัปโหลดไฟล์ไม่สำเร็จ กรุณาตรวจสอบและแก้ไข');
    }
  };

  handleConfirmBtnClick = async () => {
    const { additionalProducts: additionalProductsStore, auth: authStore } = this.props;
    try {
      const { file } = this.state;
      const { email } = authStore.getUserInfo();

      this.setState({ isUploading: true });
      additionalProductsStore.clearErrorList();

      this.validateFile(file);
      const rows = await readXlsxFile(file);
      this.validateRows(rows);

      const { success } = await additionalProductsStore.uploadAdditionalProducts(file, email);
      if (success) {
        this.notification.success('เพิ่มรายการสินค้าส่วนเพิ่มสำเร็จ');
      } else {
        this.notification.error('อัปโหลดไฟล์ไม่สำเร็จ กรุณาลองใหม่อีกครั้ง');
      }

      additionalProductsStore.getAdditionalProducts();
      this.searchInputRef.current.value = '';
      additionalProductsStore.setErrorMessage(undefined);
    } catch ({ message }) {
      additionalProductsStore.setErrorMessage(message);
    } finally {
      this.setState({ file: undefined, isUploading: false, showModal: false });
    }
  };

  render = () => {
    const { additionalProducts: additionalProductsStore, auth: authStore } = this.props;
    const { list: additionalProducts, pagination } = additionalProductsStore.toJS();
    const { file, isInitializing, isUploading, showModal } = this.state;
    const isProductsReady = Array.isArray(additionalProducts);
    const isProductsEmpty = isProductsReady && additionalProducts.length === 0;
    const isSuperAdmin = authStore.isSuperAdmin();

    return (
      <Container isAdmin>
        <Notification
          ref={(ref) => {
            this.notification = ref;
          }}
        />
        <Loader show={isInitializing} />
        {additionalProductsStore.errorMessage && (
          <Alert className="additional-products-page__alert-container" bsStyle="danger">
            <i className="fa fa-exclamation-triangle additional-products-page__alert-icon"></i>
            <span>{additionalProductsStore.errorMessage}</span>
            {additionalProductsStore.errorCount && (
              <Link className="additional-products-page__alert-link" to={`${window.location.pathname}/errors`}>
                รายการที่ผิดพลาด
              </Link>
            )}
          </Alert>
        )}
        <div className="card">
          <div className="card-header" data-background-color="orange">
            <h4 className="title additional-products-page__card-header-title">รายการสินค้าส่วนเพิ่ม</h4>
          </div>
          <div className="card-content">
            <div className="additional-products-page__header-row">
              <div className="additional-products-page__header-col additional-products-page__margin-items">
                <span>รายการสินค้าส่วนเพิ่ม:</span>
                <button
                  type="button"
                  className="additional-products-page__btn additional-products-page__secondary-btn"
                  disabled={!isSuperAdmin}
                  onClick={this.downloadTemplateFile}
                >
                  <span>ดาวน์โหลดไฟล์ Template</span>
                  <img className="additional-products-page__btn-icon" src={require('../../assets/img/download-icon.svg')} />
                </button>
                <button
                  type="button"
                  className="additional-products-page__btn additional-products-page__primary-btn"
                  disabled={!isSuperAdmin}
                  onClick={this.openModal}
                >
                  <span>อัปโหลดรายการสินค้าส่วนเพิ่ม</span>
                  <img className="additional-products-page__btn-icon" src={require('../../assets/img/white-upload-icon.svg')} />
                </button>
              </div>
              <div className="input-group additional-products-page__header-col">
                <input
                  className="form-control"
                  placeholder="ค้นหารายการสินค้า"
                  ref={this.searchInputRef}
                  type="text"
                  onKeyDown={this.handleInputKeyDown}
                />
                <div className="input-group-btn additional-products-page__search-btn-wrapper">
                  <button type="button" className="btn btn-primary additional-products-page__search-btn" onClick={this.handleSearch}>
                    <i className="fa fa-search" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <Row>
              <Col md={12}>
                <table className="table table-bordered table-hover has-pagination">
                  <thead>
                    <tr>
                      <th>รหัสสินค้า</th>
                      <th>รายการสินค้า</th>
                      <th className="text-right">หน่วย</th>
                      <th className="text-right">ราคามาตราฐาน (บาท)</th>
                      <th className="text-right">ราคาจ่ายช่าง (บาท)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isProductsReady &&
                      additionalProducts.map((product) => (
                        <tr key={product._id}>
                          <td>{product.product_code}</td>
                          <td>{product.product_name}</td>
                          <td className="text-right">{product.unit}</td>
                          <td className="text-right">{product.unit_price}</td>
                          <td className="text-right">{product.contractor_cost}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Col>
            </Row>
            {(!isProductsReady || isProductsEmpty) && (
              <Row>
                <Col className="additional-products-page__empty-container" md={12}>
                  <img className="additional-products-page__empty-img" src={require('../../assets/img/empty-box.svg')} />
                  <div className="additional-products-page__empty-text">ไม่มีข้อมูลรายการสินค้าส่วนเพิ่ม</div>
                </Col>
              </Row>
            )}
            {pagination && (
              <Row>
                <Col md={12}>
                  <Pagination pagination={pagination} handlePageClick={this.handlePageClick} />
                </Col>
              </Row>
            )}
          </div>
        </div>
        <Modal show={showModal}>
          <Modal.Header className="additional-products-page__modal-header" closeButton={false}>
            <Modal.Title className="additional-products-page__modal-title">อัปโหลดรายการสินค้าส่วนเพิ่ม</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label
              className="additional-products-page__upload-label"
              htmlFor="additional-products-page__upload-input"
              onDragEnter={this.preventDefaultAndStopPropagation}
              onDragLeave={this.preventDefaultAndStopPropagation}
              onDragOver={this.preventDefaultAndStopPropagation}
              onDrop={this.handleDropFile}
            >
              <img className="additional-products-page__upload-icon" src={require('../../assets/img/upload-icon.svg')} />
              <div>อัปโหลดหรือลากไฟล์มาที่นี่</div>
              <div className="additional-products-page__upload-subtle">ไฟล์ที่รองรับ: xlsx, xls ขนาดไฟล์ต้องไม่เกิน 5 MB</div>
            </label>
            <input
              id="additional-products-page__upload-input"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              disabled={isUploading}
              type="file"
              onChange={this.handleFileChange}
            />
            {file && (
              <div className="additional-products-page__file-list">
                <strong>รายการอัปโหลด</strong>
                <div className="additional-products-page__file-container">
                  <img className="additional-products-page__file-icon" src={require('../../assets/img/circle-google-sheets-icon.svg')} />
                  <span>{file.name}</span>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className="additional-products-page__modal-footer additional-products-page__margin-items">
            <button
              className="additional-products-page__btn additional-products-page__secondary-btn"
              disabled={isUploading}
              onClick={this.closeModal}
            >
              ยกเลิก
            </button>
            <button
              className="additional-products-page__btn additional-products-page__primary-btn"
              disabled={!file || isUploading}
              onClick={this.handleConfirmBtnClick}
            >
              ยืนยัน
            </button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  };
}

export default inject('auth', 'additionalProducts')(observer(AdditionalProductsPage));
