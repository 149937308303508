import React from 'react';
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import numeral from 'numeral';
import MobileDetect from 'mobile-detect';
// import { Checkbox } from 'react-bootstrap/lib';
import Container from '../../layouts/Container';
import Notification from '../../components/common/Notification';
import Loader from '../../components/common/Loader';
import { FormValidation, required } from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
// import EnTextAreaFormGroup from '../../components/form/EnTextAreaFormGroup';
import EnButton from '../../components/form/EnButton';
import history from '../../utils/history';
import config from '../../config';
import common from '../../utils/common';
import authStore from '../../stores/AuthStore';
import * as _ from 'lodash';
import momentTz from 'moment-timezone';
import EnTextArea from '../../components/form/EnTextArea';
// import { isNullOrUndefined } from 'util';

export class GuaranteeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobId: this.props.match.params.id,
      pdf: undefined,
      guaranteeInfo: [],
      notGuaranteeInfo: [],
      name: '',
      totalDate: ''
    };
  }

  async componentWillMount() {
    await this.initialLoad();
  }

  async initialLoad() {
    let jobInfo = this.props.job.toJS().info.jobType;
    if (!(jobInfo.jobType && jobInfo.saleOrderCode)) {
      await this.props.job.getJobsById(this.state.jobId);
    }
    await this.props.po.loadPoByJobId(this.state.jobId);
    let guaranteeInfo = [];
    let notGuaranteeInfo = [];
    jobInfo = this.props.job.toJS().info;
    const getGuaranteeData = await this.props.guarantee.getGuaranteeByFilter({ saleOrderCode: jobInfo.saleOrderCode });
    guaranteeInfo = (getGuaranteeData.guaranteeInfo || []).map((g, index) => {
      return {
        key: index,
        value: g.value,
      };
    });
    notGuaranteeInfo = (getGuaranteeData.notGuaranteeInfo || []).map((g, index) => {
      return {
        key: index,
        value: g.value,
      };
    });
    this.setState({
      guaranteeInfo,
      notGuaranteeInfo,
      name: getGuaranteeData.name,
      totalDate: getGuaranteeData.total_date
    });

  }

  async componentWillUnmount() {
    await this.props.po.clearItems();
  }

  async onSubmit() {
    if (await this.props.job.showJobUpdatedDialog(this.props.job.toJS().info)) {
      return;
    }
    try {
      const notGuaranteeRemoveEmpty = this.state.notGuaranteeInfo.filter((g) => g.value !== '');

      const data = await this.props.job.saveGuarantee(this.state.jobId, {
        guarantee: this.mapValueToDb(this.state.guaranteeInfo),
        not_guarantee: this.mapValueToDb(notGuaranteeRemoveEmpty),
        total_date: this.state.totalDate,
      });
      if (data && data.message === 'error') {
        throw new Error('ไม่สามารถสร้างใบรับประกันได้');
      }
      if (data && data.message === 'NO_GUARANTEE_CODE') {
        throw new Error('สินค้านี้ยังไม่เลือกใบรับประกัน');
      }
      await this.saveGuaranteeActivityLog();
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      history.push(`${config.publicUrl}/superadmin/jobs/${this.state.jobId}`);
    } catch (error) {
      swal.fire({
        icon: 'error',
        title: error.message,
        confirmButtonText: 'ตกลง',
      });
      return false;
    }
  }

  async saveGuaranteeActivityLog() {
    const jobInfo = this.props.job.toJS().info;
    let log = {};
    log.event = "warranty_document";
    log.function_name = "jobs";
    log.reference_code = jobInfo.jobCode;
    log.job_id = jobInfo.id;
    log.user = this.props.auth.getUsernameOrEmail();
    log.remark = `[${momentTz().utcOffset(7).format('HH:mm DD/MM/YYYY')}] : ${log.user} ได้ทำการแก้ไขใบรับประกัน`;
    log.created_date = new Date();
    this.props.permission.setInfoLogActivity([log]);
    await this.props.permission.saveUamActivityLogs();
  }

  async onCancel() {
    history.goBack();
  }

  mapValueToDb = (info) => {
    return (info || []).map(i => {
      return {
        value: i.value,
      };
    });
  }

  onNameChange = (e) => {
    if (e && e.target) {
      this.setState({ name: +e.target.value });
    }
  }
  onDateChange = (e) => {
    if (e && e.target) {
      this.setState({ totalDate: +e.target.value });
    }
  }

  onAddGuaranteeItems = () => {
    const guaranteeInfo = _.cloneDeep(this.state.guaranteeInfo);
    guaranteeInfo.push({
      key: (new Date()).getTime(),
      value: '',
    });
    this.setState({
      guaranteeInfo,
    });
  }

  onAddNotGuaranteeItems = () => {
    const notGuaranteeInfo = _.cloneDeep(this.state.notGuaranteeInfo);
    notGuaranteeInfo.push({
      key: (new Date()).getTime(),
      value: '',
    });
    this.setState({
      notGuaranteeInfo,
    });
  }

  isValid = () => {
    const { info } = this.props.guarantee.toJS();
    const { guaranteeInfo, notGuaranteeInfo } = this.state;
    const validateGuarantee = guaranteeInfo.find(g => _.isEmpty(g.value));
    // QBC-2238 remove notGuarantee validation
    // const validatenotGuarantee = notGuaranteeInfo.find(g => _.isEmpty(g.value));
    // const result = !!(info.name && info.totalDate && !validateGuarantee && !validatenotGuarantee);
    const result = !!(info.name && info.totalDate && !validateGuarantee);
    return result;
  }

  onGuaranteeChange = (range, e) => {
    const guaranteeInfo = _.cloneDeep(this.state.guaranteeInfo);
    if (e && e.target) {
      let item = (guaranteeInfo || []).filter((r) => {
        if (r.key === range.key) {
          r.value = e.target.value;
        }
        return r;
      });
      this.setState({ guaranteeInfo });
    }
  }

  onNotGuaranteeChange = (range, e) => {
    const notGuaranteeInfo = _.cloneDeep(this.state.notGuaranteeInfo);
    if (e && e.target) {
      let item = (notGuaranteeInfo || []).filter((r) => {
        if (r.key === range.key) {
          r.value = e.target.value;
        }
        return r;
      });
      this.setState({ notGuaranteeInfo });
    }
  }

  deleteGuarantee(selectedItem, e) {
    let guaranteeInfo = [...this.state.guaranteeInfo];
    if (e && e.target) {
      let item = (guaranteeInfo || []).filter((r) => {
        return r.key !== selectedItem.key;
      });
      this.setState({
        guaranteeInfo: item,
      });
    }
  }

  deleteNotGuarantee(selectedItem, e) {
    let notGuaranteeInfo = [...this.state.notGuaranteeInfo];
    if (e && e.target) {
      let item = (notGuaranteeInfo || []).filter((r) => {
        return r.key !== selectedItem.key;
      });
      this.setState({
        notGuaranteeInfo: item,
      });
    }
  }

  beforeSubmit = async () => {
    const confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไขใบรับประกัน' : 'กรุณายืนยันการสร้าง',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => await this.onSubmit(),
      allowOutsideClick: () => !swal.isLoading(),
    };

    const result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      setTimeout(this.onCancel(), 500);
    }
  }

  render() {
    const { notGuaranteeInfo, guaranteeInfo } = this.state;
    const guarantee = (guaranteeInfo || []).map((r, indexRange) => {
      const inputClass = (guaranteeInfo.length > 1) ? 'col-md-10 col-xs-8' : 'col-md-11 col-xs-9';
      return (
        <div key={indexRange}>
          <div className="row">
            <div style={{ marginTop: '50px', paddingLeft: '40px' }} className="col-md-1 col-xs-1"> {indexRange + 1}.</div>
            <div className={inputClass} style={{ marginTop: '40px' }}>
              <EnTextArea value={r.value} rows="1" onChange={this.onGuaranteeChange.bind(this, r)} />
            </div>
            {
              (guaranteeInfo.length > 1) &&
              <EnButton className="col-xs-1 col-md-1 btn-danger" onClick={this.deleteGuarantee.bind(this, r)} style={{ margin: '40px 0 0 0', padding: '9px 0px' }}>
                <i className="fa fa-trash" aria-hidden="true" />
              </EnButton>
            }
          </div>
        </div>
      );
    });
    const notGuarantee = (notGuaranteeInfo || []).map((r, indexRange) => {
      const inputClass = (notGuaranteeInfo.length > 1) ? 'col-md-10 col-xs-8' : 'col-md-11 col-xs-9';
      return (
        <div key={indexRange}>
          <div className="row">
            <div style={{ marginTop: '50px', paddingLeft: '40px' }} className="col-md-1 col-xs-1"> {indexRange + 1}.</div>
            <div className={inputClass} style={{ marginTop: '40px' }}>
              <EnTextArea value={r.value} rows="1" onChange={this.onNotGuaranteeChange.bind(this, r)} />
            </div>
            {
              (notGuaranteeInfo.length > 1) &&
              <EnButton className="col-xs-1 col-md-1 btn-danger" onClick={this.deleteNotGuarantee.bind(this, r)} style={{ margin: '40px 0 0 0', padding: '9px 0px' }}>
                <i className="fa fa-trash" aria-hidden="true" />
              </EnButton>
            }
          </div>
        </div >
      );
    });
    return (
      <>
        <Container isAdmin>
          <div className="row">
            <Loader show={this.state.isLoading} />
            <Notification ref={(ref) => { this.noti = ref; }} />
            <div className="col-md-12">
              <div className="card">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">แก้ไขใบรับประกัน</h4>
                  <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
                </div>
                <div className="card-content">
                  <div className="card-content">
                    <FormValidation>
                      <div className="row">
                        <EnTextFormGroup
                          containerClassName="col-md-6"
                          type="text"
                          label="ชื่อใบรับประกัน"
                          disabled={true}
                          value={this.state.name}
                          onChange={this.onNameChange.bind(this)}
                          validations={[required]} />
                        <EnTextFormGroup
                          containerClassName="col-md-6"
                          type="number"
                          label="จำนวนวันที่รับประกัน"
                          value={this.state.totalDate}
                          onChange={this.onDateChange.bind(this)}
                          validations={[required]} />
                      </div>
                      <div className="header-duration" style={{ height: '70px', marginTop: '20px' }}>
                        เงื่อนไขและขอบเขตการรับประกัน
                        <EnButton className="btn-success pull-right" onClick={this.onAddGuaranteeItems.bind(this, guaranteeInfo)} style={{ margin: '0 10px 0 0' }}>
                          <i className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่ม
                        </EnButton>
                      </div>
                      {guarantee}
                      <br />
                      <div className="header-duration" style={{ height: '70px' }}>
                        ขอสงวนสิทธิ์ไม่รับประกันในกรณีต่อไปนี้
                        <EnButton className="btn-success pull-right" onClick={this.onAddNotGuaranteeItems.bind(this, notGuaranteeInfo)} style={{ margin: '0 10px 0 0' }}>
                          <i className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่ม
                        </EnButton>
                      </div>
                      {notGuarantee}
                      <br />
                      <div className="row">
                        <EnButton className="btn-danger" onClick={() => { this.onCancel() }}>
                          <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                        </EnButton>
                        <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={() => this.beforeSubmit(false)}>
                          <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                        </EnButton>
                      </div>
                    </FormValidation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container >
      </>
    );
  }
}

export default inject('auth', 'invoice', 'po', 'job', 'guarantee', 'permission')(observer(GuaranteeForm));
