import React from 'react';
import * as _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { CustomCellWrapper, CustomCalendarEvent, CustomEndAccessor, CustomEventView } from '../calendar/CustomCalendar';
import Notification from '../../components/common/Notification';
import EnDropDown from '../../components/form/EnDropDown';
import Calendar from '../../components/common/Calendar';
import FormGroup from '../../components/form/FormGroup';
import { TypeOfJobs } from '../../utils/data';
import moment from 'moment';
import config from '../../config';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import EnButton from '../../components/form/EnButton';
import { Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { isAllow } from '../../utils/permission';

export class AdminVaccineApproove extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      filesImg:[]
    };
  }

  

  async componentDidMount() {
    
  }


  showModal(value,filesImg) {
    this.setState({
      showModal: value,
      filesImg:filesImg || []
    })
  }



  vaccineRowItems(data, index) {
    console.log('data',data)
    const label = `วัคซีนเข็มที่ ${index + 1}`
    const filesImg = data.files
    
    return (
      <div className="seo-form">
           <label class="">{label}</label>
              <div className="row">
                <EnTextFormGroup
                  disabled={true}
                  containerClassName="col-md-6"
                  type="text"
                  label={`วันที่ได้รับวัคซีน เข็มที่ ${index+1}`}
                  value={moment(data.date).format("DD/MM/YYYY")}
                />
                <EnTextFormGroup
                  containerClassName="col-md-6"
                  type="text"
                  label={`วัคซีนเข็มที่ ${index+1} ที่ท่านได้รับคือ`}
                  value={data.name}
                  disabled={true}
                />
        </div>
        <div className="row">
          <div className="col-md-12">
            <EnButton className="btn-info pull-left" onClick={e => { this.showModal(true,filesImg) }}>
              <i className="fa fa-image btn-icon" aria-hidden="true" />แสดงรูปทั้งหมด
            </EnButton>
          </div>
        </div>
    </div>
    )
  }
  onGoBack() {
    if(isAllow("SEARCH_AND_VIEW_A_LIST_OF_CONTRACTORS")){
      this.props.history.push(`${config.publicUrl}/superadmin/approve`);
    }else{
      //Go Back to Main Menu
      this.props.history.push(`${config.publicUrl}`);
    }
  }
  

  async approove(value) {
    try {
      const id = this.props.id
      const option = {id,isAdmin:true}
      await this.props.contractor.vaccineApproove(id,value)
      if (value) {
        const vaccine = this.props.vaccine
        const items = vaccine.items
        const lenghVaccine = items.length
        const lastItem = items[lenghVaccine-1]
        console.log('vaccine',vaccine);
        console.log('lastItem',lastItem);
          if (lenghVaccine >= 1) {
            for (let index = 0; index < vaccine.items.length; index++) {
              const vaccineCurrent = vaccine.items[index];
              const history = {
                vaccine_id : vaccineCurrent.id ,
                contractor_id : id,
                approved: value,
                qty:index+1,
              }
              await this.props.contractor.vaccineHistory(history)
            }
              
          }
      
      }
      Swal.fire(
              'บันทึกสำเร็จ!',
              '',
              'success'
            )
      await this.props.contractor.getContractor(option)
    } catch (error) {
      Swal.fire(
            `${error}`,
            '',
            'error'
          )
    }
    

  }

  render() {
    const vaccine = this.props.vaccine
    const items =vaccine.items
    console.log('vaccine',vaccine);
    const { filesImg } = this.state
    const adminApproved = vaccine.admin_approved ? vaccine.admin_approved.is_approved : false
    let dateStatus = vaccine.update_at ? moment(vaccine.update_at).format('HH:mm DD/MM/YYYY') : ''
    let textStatus = 'รอการอนุมัติ'
    if (adminApproved) {
      textStatus = 'อนุมัติ'
      dateStatus = vaccine.admin_approved.date ? `${moment(vaccine.admin_approved.date).format('HH:mm')}  ${moment(vaccine.admin_approved.date).format('DD/MM/YYYY')}` : ''
    }
    return (
      <div>
        <Modal
          show={this.state.showModal}
          onHide={e => { this.showModal(false) }}
          // bsSize="lg"

        >
          <Modal.Header  closeButton={true} style={{ backgroundColor: '#f3873a',paddingTop:12,paddingBottom:12 }}>
            <Modal.Title  style={{color:'white'}}>รูปภาพทั้งหมด</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              {filesImg && filesImg.length && 
                _.map(filesImg, file => {
                  const src = file.path
                  return (
                    <div className="col-md-6">
                      <img alt="รูปโปรไฟล์" src={src} width="100%"/>
                    </div>
                  )
                })
              }

            </div>
          </Modal.Body>
          <Modal.Footer>
         
            
          </Modal.Footer>
        </Modal>
        <div className="card-content">
          <div className="seo">
              <div className="header-seo"><span>สถานะการฉีด Vaccine</span>
            </div>
            <div className="seo-form">
              <div class="row">
              <div className="col-md-4" style={{display:'flex'}}>
                    <h4 class="" style={{ color: adminApproved ? '#68FF33' : '#56D308',marginRight:10 }}>{textStatus}</h4>
                  <h4 class="" style={{ color: 'grey'}}>{ dateStatus}</h4>
                    
                </div>
                <div className="col-md-2">
                </div>
            </div>
            </div>
            
              {
                _.map(items, (item,index) => {
                  return this.vaccineRowItems(item,index)
                })
              }
            
            <div className="row">
            <div className="col-md-12">
              {
                !adminApproved &&
                <EnButton id="back-button" className="btn-success pull-right" onClick={ e=> {this.approove(!adminApproved)}}>
                  <i className="fa fa-check btn-icon" aria-hidden="true" />อนุมัติ
                </EnButton>
              }
              {
                adminApproved &&
                <EnButton id="back-button" className="btn-grey pull-right" onClick={ e=> {this.approove(!adminApproved)}}>
                  <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิกอนุมัติ
                </EnButton>
              }
              
            </div>
            
          </div>
          </div>
          
          
          
             <div className="row">
                          <div className="col-md-12">
                            <EnButton id="back-button" className="btn-danger pull-right" onClick={this.onGoBack.bind(this)}>
                              <i className="fa fa-reply btn-icon" aria-hidden="true" />กลับ
                            </EnButton>
                          </div>
                        </div>
        </div>
        <div></div>
      </div>
    );
  }
}

export default inject('contractor')(observer(AdminVaccineApproove));
