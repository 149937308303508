import BaseStore from '../BaseStore';
import humps from 'humps';
import config from '../../config';
import { http } from '../../utils/http';
import { isAllow } from '../../utils/permission';
import qs from 'query-string';
import Swal from 'sweetalert2'
import _ from 'lodash'
import axios from 'axios'

export class TrainingCourse extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      subCourse: [],
      course: [],
      register: [],
      query: {},
      isAdmin: false,
      locationOptions: []
    });
    this.setDefaultQuery();
  }

  setDefaultQuery() {
    this.query = {
      // page_size: 10,
      // page: 1,
      // page_range: 5,
      course_name: '',
    };
  }

  setQueryValue(key, value) {
    this.query[key] = value;
  }

  async searchTrainingCourse() {
    if (this.isLoading) return;
    const { query } = this.toJS();
    const data = {
      training_name: query.course_name
    }

    try {
      this.isLoading = true;
      const endpoint = `${config.api.training}/training?${qs.stringify(data)}`;

      const response = await http.get(endpoint);

      if (response && response.status === 200 && response.data) {
        this.course = humps.camelizeKeys(response.data);

      } else {
        this.course = [];
      }
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isLoading = false;
    }
  }

  async createScgSkillExcel(trainingDtUuid) {
    if (this.isLoading) return;

    try {
      this.isLoading = true;
      const endpoint = `${config.api.sims}/v1/downloads/contractors/scgskill?trainingDtUuid=${trainingDtUuid}`;

      const response = await http.get(endpoint);
      if (response && response.data && response.status === 200) {
        return response.data.result;
      } else {
        console.log('response.data.message >>>>>>>>>>>>>', response.data.message);
        throw Error(response.data.message);
      }
    } catch (err) {
      console.log('err >>>>>>>>>>>>>>>>', err);
      return err.message;
    } finally {
      this.isLoading = false;
    }
  }

  async getAllTrainingCourse() {
    if (this.isLoading || !isAllow('SEARCH_AND_VIEW_A_LIST_OF_MAIN_COURSES')) return;
    this.isLoading = true;

    try {
      const endpoint = `${config.api.training}/training`;
      const response = await http.get(endpoint);
      if (response.status === 200 && response.data) {
        this.course = humps.camelizeKeys(response.data);
      } else {
        this.course = [];
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
  async getAllTrainingCourseOld() {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const endpoint = `${config.api.training}/training`;
      const response = await http.get(endpoint);
      if (response.status === 200 && response.data) {
        this.course = humps.camelizeKeys(response.data);
      } else {
        this.course = [];
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getAllSubTrainingCourse() {
    if (this.isLoading || !isAllow('VIEW_A_LIST_OF_SUB_COURSE')) return;
    this.isLoading = true;

    try {
      const endpoint = `${config.api.training}/training/time`;
      const response = await http.get(endpoint);
      if (response.status === 200 && response.data) {
        this.subCourse = humps.camelizeKeys(response.data);
        this.subCourse = _.orderBy(this.subCourse, ['trainingDate'], ['desc'])
      } else {
        this.subCourse = [];
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async createMainCourse(courseName, courseDesc) {
    const data = {
      "training_name": courseName,
      "training_desc": courseDesc,
      "is_active": true
    }
    this.isLoading = true;

    try {
      const endpoint = `${config.api.training}/training`;
      const response = await http.post(endpoint, data);
      if (response.status === 200 && response.data) {

        Swal.fire(
          'สร้างคอร์สสำเร็จ',
          '',
          'success'
        )

      }

    } catch (error) {
      console.error('error=====createMainCourse');
      console.log(error);
      console.error('error=====createMainCourse');

      throw error;
    } finally {
      this.isLoading = false;
    }
  }
  async createSubCourse(data) {
    this.isLoading = true;
    try {
      const endpoint = `${config.api.training}/training/time`;
      const response = await http.post(endpoint, data);
      if (response.status === 200 && response.data) {
        Swal.fire(
          'สร้างคอร์สย่อยสำเร็จ',
          '',
          'success'
        )
      } else {
        Swal.fire(
          'เกิดข้อผิดพลาดบางประการ',
          response.statusText,
          'error'
        )
        throw response.data;
      }

    } catch (error) {
      console.error('error=====createSubCourse');
      console.log(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async confirmDeleteCourse(training_uuid) {
    this.isLoading = true;
    // const data = {
    //   'training_uuid': training_uuid
    // }
    try {
      const endpoint = `${config.api.training}/training/confirm?training_uuid=${training_uuid}`;
      const response = await http.delete(endpoint);
      if (response.status === 200 && response.data) {

        Swal.fire(
          'ลบคอร์สสำเร็จ',
          '',
          'success'
        )
      }

    } catch (error) {
      console.error('error=====deleteCourse');
      console.log(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }


  async deleteSubCourse(training_detail_uuid) {
    try {
      const endpoint = `${config.api.training}/training/time?training_detail_uuid=${training_detail_uuid}`;
      const response = await axios.delete(endpoint)
      if (response.status === 200 && response.data) {
        Swal.fire(
          'ลบคอร์สสำเร็จ',
          '',
          'success'
        )
        this.getAllSubTrainingCourse()
      }

    } catch (error) {
      console.error('error=====deleteCourse');
      console.log(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
  async deleteCourse(training_uuid) {
    this.isLoading = true;
    // const data = {
    //   'training_uuid': training_uuid
    // }

    try {
      const endpoint = `${config.api.training}/training?training_uuid=${training_uuid}`;
      const response = await http.delete(endpoint);

      if (response.status === 200 && response.data) {
        Swal.fire(
          'ลบคอร์สสำเร็จ',
          '',
          'success'
        )
      }
      if (response.data.error_code == 'CT012') {
        Swal.fire({
          title: `${response.data.error_message_title}`,
          text: `${response.data.error_message}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'ยืนยันการลบ'
        }).then((result) => {
          if (result.value) {
            this.confirmDeleteCourse(training_uuid)
          }
        })
      }

    } catch (error) {
      console.error('error=====deleteCourse');
      console.log(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async EditMainCourse(course) {
    // let a = course

    this.isLoading = true;
    const data = {
      "training_uuid": course.trainingUuid,
      "training_name": course.courseName,
      "training_desc": course.courseDesc,
      // "is_active": false
    }

    try {
      const endpoint = `${config.api.training}/training/editcourse`;
      const response = await http.put(endpoint, data);
      if (response.status === 200 && response.data) {

        Swal.fire(
          'แก้ไขสำเร็จ',
          '',
          'success'
        )
        // await this.getAllTrainingCourse()

      }

    } catch (error) {
      console.error('error=====EditMainCourse');
      console.log(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }


  async getlocations(trainingDtUuid) {
    this.isLoading = true;

    try {
      const endpoint = `${config.api.training}/training/location`;
      const response = await http.get(endpoint);

      if (response.status === 200 && response.data) {
        this.locationOptions = humps.camelizeKeys(response.data);
        console.log("====locationOptions===", this.locationOptions);

        return this.locationOptions
      } else {
        this.locationOptions = [];
      }
    } catch (error) {
      console.log(error);
      this.register = [];
    } finally {
      this.isLoading = false;
    }
  }

  async getTrainingRegister(trainingDtUuid) {
    if (this.isLoading || !trainingDtUuid) return;
    this.isLoading = true;

    try {
      const endpoint = `${config.api.training}/training/time/${trainingDtUuid}`;
      const response = await http.get(endpoint);
      if (response.status === 200 && response.data) {
        this.register = humps.camelizeKeys(response.data);
      } else {
        this.register = [];
      }
    } catch (error) {
      console.log(error);
      this.register = [];
    } finally {
      this.isLoading = false;
    }
  }

  async getSubCoursDetails(trainingDtUuid) {
    if (this.isLoading || !trainingDtUuid) return;
    this.isLoading = true;

    try {
      const endpoint = `${config.api.training}/training/time?train_dt_uuid=${trainingDtUuid}`;
      const response = await http.get(endpoint);
      if (response.status === 200 && response.data) {
        return humps.camelizeKeys(response.data[0]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  async changeActiveSubCourse(trainingDtUuid, value) {

    if (this.isLoading || !trainingDtUuid) return;
    this.isLoading = true;
    const data = {
      "training_detail_uuid": trainingDtUuid,
      "is_active": value || false
    }

    try {
      const endpoint = `${config.api.training}/training/time`;
      const response = await http.put(endpoint, data);

      if (response.status === 200 && response.data) {
        // Swal.fire(
        //   'อัพเดทเสร็จสิ้น',
        //   '',
        //   'success'
        // )
      }
    } catch (error) {
      console.log(error);
      Swal.fire(
        'อัพเดทเสร็จสิ้น',
        error,
        'error'
      )
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async confirmAdmin(pin, ctrUuid, trainingUuid) {
    if (this.isLoading) return;
    this.isLoading = true;

    try {
      const endpoint = `${config.qchangApp.baseUrl.authen}/confirm-admin`;
      console.log('endpoint -->', endpoint);
      const body = {
        ctr_uuid: ctrUuid,
        pin: pin,
        training_dt_uuid: trainingUuid,
      };
      const response = await http.post(endpoint, body);
      if (response.status === 200 && response.data) {
        return `${config.publicUrl}/superadmin/training`;
      } else {
        console.log('response.data', response.data);
        if (response.data.error_message_title) {
          throw new Error(`${response.data.error_message_title}`);
        } else {
          throw new Error('ไม่สามารถเข้าสู่ระบบได้ กรุณาติดต่อผู้ดูแลระบบ');
        }
      }
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new TrainingCourse();
