import React from 'react';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import { required } from '../../../../components/form/FormValidation';
import FormGroup from '../../../../components/form/FormGroup';
import EnToggle from '../../../../components/form/EnToggle';
import EnDropDown from '../../../../components/form/EnDropDown';

export class InstallmentSection extends React.Component {
  onToggle = (value) => {
    this.props.product.saveInfo('allowInstallment', value);
    if (!value) {
      this.props.product.saveInfo('installmentPeriod', 0);
    }
  };

  onInstallmentPeriodChanged(e) {
    const value = e.target.value;
    if (value > 0) {
      this.props.product.saveInfo('allowInstallment', true);
    } else {
      this.props.product.saveInfo('allowInstallment', false);
    }
    this.props.product.saveInfo('installmentPeriod', value);
  }

  displayInstallmentPeriodSelector() {
    const monthOpts = [
      <option key={0} value={0}>
        กรุณาเลือกจำนวนเดือนสูงสุด
      </option>,
      <option key={3} value={3}>
        3 เดือน
      </option>,
      <option key={4} value={4}>
        4 เดือน
      </option>,
      <option key={6} value={6}>
        6 เดือน
      </option>,
      <option key={10} value={10}>
        10 เดือน
      </option>,
    ];
    return monthOpts;
  }

  render() {
    const { allowInstallment, installmentPeriod } = this.props;
    return (
      <div className="row">
        <FormGroup containerClassName="col-md-2" label="การผ่อนชำระ">
          <EnToggle checked={allowInstallment} onChange={this.onToggle.bind(this)} />
        </FormGroup>
        <FormGroup containerClassName={`col-md-4`} label="จำนวนเดือน" validations={[required]}>
          <EnDropDown id="installment-period" value={installmentPeriod} onChange={this.onInstallmentPeriodChanged.bind(this)}>
            {this.displayInstallmentPeriodSelector()}
          </EnDropDown>
        </FormGroup>
      </div>
    );
  }
}

export default inject('product')(observer(InstallmentSection));
