import React from 'react';
import { observer, inject } from 'mobx-react';
import Container from '../../layouts/Container';
import FormGroup from '../../components/form/FormGroup';
import EnDropDown from '../../components/form/EnDropDown';
import EnButton from '../../components/form/EnButton';
import Loader from '../../components/common/Loader';

export class ExportJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      workType: 1,
      isLoading: false,
    };
  }

  async componentDidMount() {
    try {
      await this.props.typeofwork.getTypeOfWorks();
    } catch (error) {
    }
  }

  async onWorkTypeChanged(e) {
    this.setState({
      workType: e.target.value,
      isLoading: this.state.isLoading,
    });
  }

  async onClickDownload() {
    this.setState({
      workType: this.state.workType,
      isLoading: true,
    });
    await this.props.exportdata.exportJobs(null, this.state.workType);
    this.setState({
      workType: this.state.workType,
      isLoading: false,
    });
  }

  render() {
    const typeOfWorks = this.props.typeofwork.mapIdAndLabel();
    const workType = typeOfWorks.map((item, idx) => {
      return <option key={`workType-${idx}`} value={item.id}>{item.label}</option>;
    });
    return (
      <Container isAdmin>
        <Loader show={this.state.isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header" data-background-color="orange">
                <h4 className="title">ดาวน์โหลดงาน</h4>
              </div>
              <div className="card-content">
                <div className="row">
                  <FormGroup label="ประเภทงาน:">
                    <div className="row">
                      <div className="col-md-6 ">
                        <EnDropDown value={this.state.workType} onChange={this.onWorkTypeChanged.bind(this)}>
                          {workType}
                        </EnDropDown>
                      </div>
                      <div className="col-md-4">
                        <EnButton className="btn-info" onClick={this.onClickDownload.bind(this)}>ดาวน์โหลด</EnButton>
                      </div>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('exportdata', 'typeofwork')(observer(ExportJob));
