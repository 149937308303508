import qs from 'query-string';
import BaseStore from './BaseStore';
import config from '../config';
import http from '../utils/http';

export class ContractorGradeStore extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      query: {},
      data: [],
      gradeMap: undefined,
    });
    this.setDefaultQuery();
  }

  setDefaultQuery() {
    this.query = {
      contractor_id: '',
    };
  }

  setQueryValue(key, value) {
    this.query[key] = value;
  }

  async searchContractorGrade() {
    if (this.isLoading) return;
    this.isLoading = true;
    const { query } = this.toJS();
    const contractorId = query.contractor_id;
    try {
      const res = await http.get(`${config.api.sims}/v1/contractors/${contractorId}/grade?${qs.stringify(query)}`);
      if (res && res.data && res.data.data) {
        this.data = res.data.data;
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

  initGradeMap = async () => {
    try {
      if (this.gradeMap) {
        return;
      }

      const { data, status } = await http.get(`${config.api.sims}/v1/contractors/grade-map`);
      if (status === 200) {
        this.gradeMap = data.grade_map;
      }
    } catch ({ message }) {
      throw new Error({ message });
    }
  };

  getDisplayedGrade = (originalGrade) => {
    if (this.gradeMap && Object.keys(this.gradeMap).includes(originalGrade)) {
      return this.gradeMap[originalGrade];
    }
    return 'New';
  };
}

export default new ContractorGradeStore();
