import React from 'react';
import EnButton from '../../../components/form/EnButton';
import EnDatePicker from '../../../components/form/EnDatePicker';
import EnDropDown from '../../../components/form/EnDropDown';
import moment from 'moment';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import { broadcastRecipientType } from '../../../utils/data';

const releaseStatusOption = [
  { label: 'พร้อมเผยแพร่', value: true },
  { label: 'ฉบับร่าง', value: false },
];

const isPublished = [
  { label: 'เผยแพร่', value: true },
  { label: 'ไม่เผยแพร่', value: false },
];

class BroadcastSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {},
    };
  }

  async handleChange(key, event) {
    const newQuery = { ...this.state.query };
    if ((key === 'createdAt' || key === 'publishedAt') && event) {
      return this.setState({ query: { ...newQuery, [key]: event.toDate() } });
    }

    if (event && event.target) {
      return this.setState({ query: { ...newQuery, [key]: event.target.value } });
    }
  }

  async onClearSearch() {
    this.setState({
      query: {
        name: '',
        recipientType: '',
        isPublish: '',
        isActive: '',
        publishedAt: null,
        createdAt: null,
      },
    });
    this.props.handleClearSearch();
  }

  checkInstDayBlock(date) {
    const currentDate = moment();
    const threeMonthsAgo = currentDate.clone().subtract(3, 'months').startOf('month');
    const threeMonthsFromNow = currentDate.clone().add(3, 'months').endOf('month');

    return moment(date).isBefore(threeMonthsAgo) || moment(date).isAfter(threeMonthsFromNow);
  }

  render() {
    const { query } = this.state;
    const { handleClickSearch, handleClearSearch, handleChangeQuery } = this.props;
    return (
      <div>
        <div className="row" style={{ margin: '1rem' }}>
          {/* column1 */}
          <div className="col-md-4">
            <div className="row">
              <label className="col-md-4 control-label">หัวข้อประกาศ</label>
              <div className="col-md-8">
                <EnTextFormGroup
                  type="text"
                  placeholder="ค้นหาด้วยชื่อหัวข้อประกาศ"
                  value={query['name']}
                  onChange={(value) => this.handleChange('name', value)}
                  notRequired
                />
              </div>
            </div>
            <div className="row" style={{ marginBottom: '1rem' }}>
              <label className="col-md-4 control-label">ผู้รับประกาศ</label>
              <div className="col-md-8">
                <EnDropDown id="recipient" value={query['recipientType']} onChange={(value) => this.handleChange('recipientType', value)}>
                  <option value={''}>ทุกช่องทาง</option>
                  {broadcastRecipientType.map((option) => (
                    <option key={`recipientOption-${option.value}`} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </EnDropDown>
              </div>
            </div>
          </div>
          {/* column 2 */}
          <div className="col-md-4">
            <div className="row" style={{ marginBottom: '1rem' }}>
              <label className="col-md-4 control-label">วันที่สร้าง</label>
              <div className="col-md-8">
                <EnDatePicker
                  id="createdAt"
                  isPlaceholder="วันที่"
                  initialDate={query.createdAt ? moment(query.createdAt) : undefined}
                  onDateChange={(value) => {
                    this.handleChange('createdAt', value);
                  }}
                  isOutsideRange={() => false}
                  isDayBlocked={this.checkInstDayBlock.bind(this)}
                />
              </div>
            </div>
            <div className="row" style={{ marginBottom: '1rem' }}>
              <label className="col-md-4 control-label">วันให้มีผล</label>
              <div className="col-md-8">
                <EnDatePicker
                  id="publishedAt"
                  isPlaceholder="วันที่"
                  initialDate={query.publishedAt ? moment(query.publishedAt) : undefined}
                  onDateChange={(value) => this.handleChange('publishedAt', value)}
                  isOutsideRange={() => false}
                  isDayBlocked={this.checkInstDayBlock.bind(this)}
                />
              </div>
            </div>
          </div>
          {/* column 3 */}
          <div className="col-md-4">
            <div className="row" style={{ marginBottom: '1rem' }}>
              <label className="col-md-4 control-label">สถานะประกาศ</label>
              <div className="col-md-8">
                <EnDropDown id="isActive" value={query['isActive']} onChange={(value) => this.handleChange('isActive', value)}>
                  <option value={''}>ทั้งหมด</option>
                  {releaseStatusOption.map((option) => (
                    <option key={`releaseStatusOption-${option.value}`} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </EnDropDown>
              </div>
            </div>
            <div className="row" style={{ marginBottom: '1rem' }}>
              <label className="col-md-4 control-label">สถานะเผยแพร่</label>
              <div className="col-md-8">
                <EnDropDown id="isPublish" value={query['isPublish']} onChange={(value) => this.handleChange('isPublish', value)}>
                  <option value={''}>ทั้งหมด</option>
                  {isPublished.map((option) => (
                    <option key={`isPublished-${option.value}`} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </EnDropDown>
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ paddingRight: '1rem' }}>
          <EnButton className="btn-warning pull-right" onClick={this.onClearSearch.bind(this)}>
            <i className="fa fa-refresh btn-icon" aria-hidden="true" />
            ล้างตัวกรอง
          </EnButton>
          <EnButton
            className="btn-info pull-right"
            onClick={() => {
              handleChangeQuery(this.state.query);
              setTimeout(() => {
                handleClickSearch();
              });
            }}
          >
            <i className="fa fa-search btn-icon" aria-hidden="true" />
            ค้นหา
          </EnButton>
        </div>
      </div>
    );
  }
}

export default BroadcastSearchForm;
