import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Loader from '../../../components/common/Loader';
import EnTagSelect from '../../../components/form/EnTagSelect';
import Container from '../../../layouts/Container';
import EnButton from '../../../components/form/EnButton';
import EnTextFormGroup from '../../../components/form/EnTextFormGroup';
import swal from 'sweetalert2';
import momentTz from 'moment-timezone';
import EnText from '../../../components/form/EnText';
import config from '../../../config';
import { isAllow } from '../../../utils/permission';
import { sortBy } from 'lodash';

const frontendUrl = config.qchang['consumerWeb']
const Line = ({ withoutLine = false }) => (
  <>
    <div style={{ height: 30 }} />
    {!withoutLine && <hr />}
  </>
)

class SetBannerWebsite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupPromotionList: [
        {
          section_name: '',
          sequence: 1,
          promotions: ''
        }
      ],
      herobannersRedirectUrl: [
        // {
        //   promotionId: '',
        //   redirectUrl: ''
        // }
      ]
    }
  }

  checkPermission() {
    if (!isAllow("EDIT_HERO_BANNER")) {
      this.props.history.push(`${config.publicUrl}/`);
    }
  }

  async componentDidMount() {
    await this.fetchData()
  }

  fetchData = async () => {
    const { promotionconfig } = this.props;
    promotionconfig.setChannel('QChangV2');
    let { groupPromotionList, herobannersRedirectUrl } = await promotionconfig.getPromotionData();
    groupPromotionList = groupPromotionList.group_promotion_list.map(section => {
      const promotions = section.promotions.map(promo => promo.id).join(',')
      return { ...section, promotions }
    })
    herobannersRedirectUrl = herobannersRedirectUrl.filter(herobanner => herobanner.url)
    herobannersRedirectUrl = herobannersRedirectUrl.map(herobanner => {
      return {
        promotionId: herobanner.promotionId,
        redirectUrl: herobanner.url
      }
    })
    this.setState({ groupPromotionList, herobannersRedirectUrl })
  }

  onTagChange = (type) => (values) => {
    const { promotionconfig } = this.props;
    promotionconfig.setSelected(type, values);
    if (type === 'heroBanner') {
      const heroBanners = values.split(',')
      const filterHeroBannerRedirectUrl = this.state.herobannersRedirectUrl.filter(herobanner => heroBanners.includes(herobanner.promotionId))
      this.setState({ herobannersRedirectUrl: filterHeroBannerRedirectUrl })
    }
  }

  onSubmit = async () => {
    const { promotionconfig } = this.props;
    const { groupPromotionList, herobannersRedirectUrl } = this.state
    const findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)

    const sectionNameArray = groupPromotionList.map(section => section.section_name)
    if (findDuplicates(sectionNameArray).length > 0) {
      return swal.fire('กรุณาตรวจสอบ มีหัวข้อซ้ำ', '', 'error')
    }
    const isHaveSectionNameEmpyt = groupPromotionList.find(section => section.section_name === '')
    if (isHaveSectionNameEmpyt) {
      return swal.fire('กรุณาตรวจสอบ หัวข้อ', '', 'error')
    }

    const sequenceArray = groupPromotionList.map(section => section.sequence)
    if (findDuplicates(sequenceArray).length > 0) {
      return swal.fire('กรุณาตรวจสอบ ลำดับ sectionซ้ำ', '', 'error')
    }

    const isPromotionsInSectionEmpty = groupPromotionList.find(section => section.promotions.length === 0)
    if (isPromotionsInSectionEmpty) {
      return swal.fire('กรุณาตรวจสอบ มีบาง group promotions \nไม่มี promotion', '', 'error')
    }


    if (herobannersRedirectUrl.length > 0) {
      const { selected } = promotionconfig.toJS()
      const isRedirectUrlEmpyt = herobannersRedirectUrl.find(herobannerURL =>
        !herobannerURL.promotionId ||
        !herobannerURL.redirectUrl ||
        !herobannerURL.redirectUrl.startsWith(frontendUrl)
      )
      const selectedHeroBanner = selected.heroBanner.split(',')
      const promoIdRedirectNotInSelectedHeroBanner = herobannersRedirectUrl.find(herobanner => !selectedHeroBanner.includes(herobanner.promotionId))
      if (isRedirectUrlEmpyt || promoIdRedirectNotInSelectedHeroBanner) {
        return swal.fire('กรุณาตรวจสอบ Redirect URL Hero Banner', '', 'error')
      }
    }

    await promotionconfig.updatePromotionDataToServer({ groupPromotionList, herobannersRedirectUrl })
    await this.fetchData()
  }

  addSection = () => {
    const listSection = [...this.state.groupPromotionList]
    listSection.push({
      section_name: '',
      sequence: this.state.groupPromotionList.length + 1,
      promotions: ''
    })
    this.setState({ groupPromotionList: listSection })
  }

  updateSection = ({ value, name, index }) => {
    const sections = [...this.state.groupPromotionList]
    const section = sections[index]
    switch (name) {
      case "section_name":
        if (value.length > 60) break
        section.section_name = value
        break
      case "sequence":
        if (+value === 0) break
        section.sequence = +value
        break
    }
    sections[index] = section
    this.setState({ groupPromotionList: sections })
  }

  updatePromotionsInGroup = (index) => (values) => {
    const promotionsSections = [...this.state.groupPromotionList]
    const section = promotionsSections[index]
    section.promotions = values
    promotionsSections[index] = section
    this.setState({ groupPromotionList: promotionsSections })
  }

  deleteSection = (index) => {
    let listSection = [...this.state.groupPromotionList]
    listSection = listSection.filter((section, idx) => index != idx)
    this.setState({ groupPromotionList: listSection })
  }

  modifyColorCouponOption = () => {
    const { options } = this.props.promotionconfig.toJS();
    let currentTime = momentTz().utcOffset(7)
    return (options || []).map(option => {
      const { value, label, start_date, end_date, status } = option
      let type = ""
      let className = ''
      const startDate = momentTz(start_date).utcOffset(7).startOf('day').toDate()
      const endDate = momentTz(end_date).utcOffset(7).endOf('day').toDate()
      if (currentTime.isBetween(startDate, endDate)) {
        type = 'active'
        className = 'banner-active'
      }
      // else if(currentTime.isBefore(startDate)) {
      //   type = 'upcoming'
      //   className = 'banner-upcoming'
      // }
      else {
        type = 'expired'
        className = 'banner-expired'
      }
      return { value, label, type, className, }
    })
  }

  heroBannerSeletedOption = () => {
    const { options, selected } = this.props.promotionconfig.toJS();
    const heroBannerSeleted = options.filter(option => selected.heroBanner.includes(option.value))
    const listHerobannerUrl = (this.state.herobannersRedirectUrl || []).map(herobanner => herobanner.promotionId)
    return (heroBannerSeleted || []).map(option => {
      const { value, label, start_date, end_date } = option
      let type = ""
      let className = ''
      if (listHerobannerUrl.includes(value)) {
        type = 'inactive'
        className = 'heroBannerUrl-inactive'
      } else {
        type = 'active'
        className = 'heroBannerUrl-active'
      }
      return { value, label, type, className, }
    })
  }

  handlerChangeHeroBannerRedirectUrl = ({ type, value, index }) => {
    const herobannersRedirectUrl = [...this.state.herobannersRedirectUrl]
    if (type === 'promotionId') {
      const promotionIdDupicate = herobannersRedirectUrl.find(herobanner => herobanner.promotionId === value)
      if (promotionIdDupicate) return swal.fire('Hero Banner ที่เลือกมีอยู่แล้ว', '', 'error')
      herobannersRedirectUrl[index].promotionId = value
    }
    if (type === 'redirectUrl') {
      herobannersRedirectUrl[index].redirectUrl = value
    }
    this.setState({ herobannersRedirectUrl })
  }

  addHeroBannerRedirectUrl = () => {
    const { selected } = this.props.promotionconfig.toJS();
    const selectedHerobanners = selected.heroBanner.split(',')
    const herobannersRedirectUrl = [...this.state.herobannersRedirectUrl]
    if (herobannersRedirectUrl.length >= selectedHerobanners.length) return
    herobannersRedirectUrl.push({
      promotionId: '',
      redirectUrl: ''
    })
    this.setState({ herobannersRedirectUrl })
  }

  deleteHeroBannerRedirectUrl = (index) => {
    let herobannersRedirectUrl = [...this.state.herobannersRedirectUrl]
    herobannersRedirectUrl = herobannersRedirectUrl.filter((herobannerRedirectUrl, idx) => index !== idx)
    this.setState({ herobannersRedirectUrl })
  }

  checkUrlFront = (url) => {
    return (url && !url.startsWith(frontendUrl))
  }

  disableAddHeroBannerRedirectUrl = () => {
    const { selected } = this.props.promotionconfig.toJS();
    const selectedHerobanners = selected.heroBanner.split(',')
    const herobannersRedirectUrl = [...this.state.herobannersRedirectUrl]
    return (!selectedHerobanners[0] || herobannersRedirectUrl.length >= selectedHerobanners.length)
  }

  render() {
    const { selected, isLoading } = this.props.promotionconfig.toJS();
    const { groupPromotionList, herobannersRedirectUrl } = this.state

    const colorizedPromotions = this.modifyColorCouponOption()
    const colorizedHeroBanners = selected.heroBanner.split(',').map((id) => colorizedPromotions.find(where => where.value == id)).filter((each) => !!each)
    const heroBanners = sortBy(colorizedHeroBanners, (each) => each.type === 'expired').map((item) => item.value).join(',');
    return (
      <div className="card-content set-banner">
        {/* <FormValidation> */}
        <div className="row">
          <div className="col-md-12">
            <span className="form-label" style={{display: 'flex', justifyContent: 'space-between'}}>
              <span>Set Hero Banner</span>
              <div className="detail-ready-to-use">
                <span>ถึงวันที่เริ่มต้นใช้งานแล้ว</span>
                <span>ยังไม่ถึงวันเริ่มต้นใช้งาน</span>
              </div>
            </span>
            <EnTagSelect
              disabled={isAllow("EDIT_HERO_BANNER") ? false : true}
              className="select-banner"
              placeholder="เลือก Hero Banner"
              closeOnSelect={false}
              options={this.modifyColorCouponOption()}
              searchable={false}
              value={heroBanners}
              onTagChange={this.onTagChange('heroBanner')}
            />
          </div>
        </div>
        <Line />
        <div className="row">
          <div className="col-md-12">
            <span className="form-label">Set Redirect URL Hero Banner</span>
            <button
              className="btn btn-success"
              title="ADD SREDIRECT URL HERO BANNERECTION"
              data-tip="ADD SECTIREDIRECT URL HERO BANNERON"
              onClick={this.addHeroBannerRedirectUrl}
              disabled={this.disableAddHeroBannerRedirectUrl()}
            >
              <span className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่ม REDIRECT URL HERO BANNER
            </button>
            {
              herobannersRedirectUrl.map((item, index) => (
                <div className="row" style={{ marginBottom: '2rem' }} key={index + 'herobanersRedirectUrl'}>
                  <div className="col-md-6">
                    <EnTagSelect
                      className="select-banner "
                      placeholder="เลือก Hero Banner"
                      closeOnSelect={true}
                      options={this.heroBannerSeletedOption()}
                      searchable={true}
                      value={item.promotionId}
                      onTagChange={(value) => this.handlerChangeHeroBannerRedirectUrl({ type: 'promotionId', value: value, index })}
                      multi={false}
                      disable={true}
                    />
                  </div>

                  <div className="col-md-2">
                    <button
                      style={{ width: "100%", marginTop: "-1px" }}
                      className="btn btn-danger"
                      onClick={() => this.deleteHeroBannerRedirectUrl(index)}>ลบ</button>
                  </div>

                  <div className="col-md-12">
                    <EnText
                      placeholder="Redirect URL"
                      className=""
                      value={item.redirectUrl}
                      onChange={(event) => this.handlerChangeHeroBannerRedirectUrl({ type: 'redirectUrl', value: event.target.value, index })}
                    />
                    {this.checkUrlFront(item.redirectUrl) && <span className="text-danger">กรุณาใส่ link ของ Q-CHANG เท่านั้น</span>}
                  </div>
                </div>
              ))
            }

          </div>
        </div>
        <Line />
        <div className="row">
          <div className="col-md-12">
            <span className="form-label">Set Promotion Bottom</span>
            <EnTagSelect
              disabled={isAllow("EDIT_PROMOTION_BOTTOM") ? false : true}
              className="select-banner"
              placeholder="เลือก Promotion Bottom"
              closeOnSelect={false}
              options={this.modifyColorCouponOption()}
              searchable={false}
              value={selected.promotionBottom}
              onTagChange={this.onTagChange('promotionBottom')}
              multi={false}
            />
          </div>
        </div>
        <Line />
        <div className="row">
          <div className="col-md-12">
            <span className="form-label">Set main promotion </span>
            <EnTagSelect
              disabled={isAllow("EDIT_MAIN_PROMOTION") ? false : true}
              className="select-banner"
              placeholder="เลือก Promotion Main"
              closeOnSelect={false}
              options={this.modifyColorCouponOption()}
              searchable={false}
              value={selected.promotionList}
              onTagChange={this.onTagChange('promotionList')}
            />
          </div>
        </div>
        {/* </FormValidation> */}
        <Line />
        <span className="form-label" style={{ paddingRight: "2rem" }}>Set Group Promotion List</span>
        <button disabled={isAllow("CREATE_GROUP_PROMOTION_LIST") ? false : true} className="btn btn-success" title="ADD SECTION" data-tip="ADD SECTION" onClick={this.addSection}>
          <span className="fa fa-plus btn-icon" aria-hidden="true" />เพิ่ม SECTION
        </button>
        {
          groupPromotionList.map((section, index) => (
            <div key={index}>
              <hr style={{ margin: '2rem 0', borderTop: "1px solid grey" }} />
              <div className="row">
                <div className="col-md-8 col-xs-4">
                  <span className="form-label">หัวข้อ</span>
                  <EnTextFormGroup
                    disabled={isAllow("EDIT_GROUP_PROMOTION_LIST") ? false : true}
                    placeholder="Section Name"
                    type="text"
                    value={section.section_name}
                    onChange={(e) => this.updateSection({ value: e.target.value, name: 'section_name', index })}
                  />
                </div>
                <div className="col-md-2 col-xs-4">
                  <span className="form-label">ลำดับ</span>
                  <EnTextFormGroup
                    disabled={isAllow("EDIT_GROUP_PROMOTION_LIST") ? false : true}
                    placeholder="ลำดับ"
                    type="number"
                    value={section.sequence}
                    onChange={(e) => this.updateSection({ value: e.target.value, name: 'sequence', index })}
                  />
                </div>
                <div className="col-md-2 col-xs-4">
                  <button
                    disabled={isAllow("DELETE_GROUP_PROMOTION_LIST") ? false : true}
                    style={{ marginTop: '2.1rem', width: "100%" }}
                    className="btn btn-danger"
                    onClick={() => this.deleteSection(index)}>
                    ลบ
                  </button>
                </div>
              </div>
              <div>
                <span className="form-label">เลือก Promotions</span>
                <EnTagSelect
                  disabled={isAllow("EDIT_GROUP_PROMOTION_LIST") ? false : true}
                  className="select-banner"
                  placeholder="เลือก Promotion"
                  closeOnSelect={false}
                  options={this.modifyColorCouponOption()}
                  searchable={false}
                  value={section.promotions}
                  onTagChange={this.updatePromotionsInGroup(index)}
                />
              </div>
            </div>
          ))
        }
        <div className="row">
          <div className="col-md-12">
            <EnButton className="btn-info pull-right" onClick={this.onSubmit}>
              <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
            </EnButton>
          </div>
        </div>
      </div>
    )
  }
}

export default inject('promotionconfig')(observer(SetBannerWebsite));
