import _ from 'lodash';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import swal from 'sweetalert2';
import EnButton from '../../../components/form/EnButton';
import '../../../assets/css/loader.css';
import xml from './ver4.mobileconfig'

const queryString = require('query-string');


class UUID extends Component {
  constructor(props) {
    super(props);
  }

  async onSubmit() {
    window.location.href = xml
  }

  render() {
    const params = queryString.parse(this.props.location.search);

    if (params.register === 'success') {
      swal.fire({
        title: 'ขอบคุณที่ร่วมทดสอบ Application Q-Chang',
        // text: 'กรุณาไปที่ การตั้งค่า (Settings) และดำเนินการต่อตามขั้นตอนด้านล่าง',
        icon: 'success',
      });
    }

    return (
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div>
              <div className="card info">
                <div className="card-header" data-background-color="orange">
                  <h4 className="title">ลงทะเบียน สำหรับระบบ iOS</h4>
                  <p className="category">เพื่อทดสอบการใช้งาน Application Q-Chang</p>
                </div>
                <div className="card-content">
                  <div className="text-center">
                    <EnButton className="btn-info" onClick={this.onSubmit.bind(this)}>ลงทะเบียน</EnButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default inject('bestdealStore')(observer(UUID));
