import React, { Fragment } from 'react';
import _ from 'lodash'
import swal from 'sweetalert2';
import { observer, inject } from 'mobx-react';
import config from '../../config';
import Container from '../../layouts/Container';
import Loader from '../../components/common/Loader';
import Notification from '../../components/common/Notification';
import { FormValidation, required, positiveIntegers } from '../../components/form/FormValidation';
import EnTextFormGroup from '../../components/form/EnTextFormGroup';
import FormGroup from '../../components/form/FormGroup';
import EnButton from '../../components/form/EnButton';
import EnToggle from '../../components/form/EnToggle';
import EnTagSelect from '../../components/form/EnTagSelect';
import { toJS } from 'mobx';
import { isAllow } from '../../utils/permission';

export class EmployeePage extends React.Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.id || false;
    this.state = {
      isLoading: false,
      channel: null,
      id: id,
      isEdit: id,
      allBranch: []
    };
  }

  async componentWillMount() {
    try {
      const channelName = this.props.auth.getChannelName();
      if (!channelName) {
        swal.fire({
          icon: 'error',
          title: 'โหลดข้อมูล Channel ไม่ได้',
          confirmButtonText: 'ตกลง',
        });
        setTimeout(() => {
          this.props.history.push(`${config.publicUrl}/employee`);
        }, 1000);
      }
      this.setState({ channel: channelName });

      if (this.state.isEdit) {
        await this.props.employee.getEmployeeById(this.state.id);
      } else {
        this.props.employee.clearInfo();
        this.props.employee.saveInfo('channel', channelName);
      }
      if (channelName) {
        const branch = await this.props.coupon.getStoreByID({ channel: channelName })
        this.setState({ 'allBranch': branch })
      }
    } catch (err) {
      throw new Error('โหลดข้อมูลล้มเหลว');
    }
  }

  onTextChange(key, e) {
    if (e.target) {
      this.props.employee.saveInfo(key, e.target.value);
    }
  }

  onToggleChange(key, value) {
    this.props.employee.saveInfo(key, value);
  }

  isValid() {
    const info = this.props.employee.toJS().info;
    const validFirstnameTh = !_.isEmpty(info.firstnameTh);
    const validLastnameTh = !_.isEmpty(info.lastnameTh);
    // const validFirstnameEn = !_.isEmpty(info.firstnameEn);
    // const validLastnameEn = !_.isEmpty(info.lastnameEn);
    const validNicknameTh = !_.isEmpty(info.nicknameTh);
    // const validNicknameEng = !_.isEmpty(info.nicknameEng);
    const validPhone1 = !_.isEmpty(info.phone1);
    const validEmail = this.state.channel === 'Q-Chang' ? !_.isEmpty(info.email) : true;
    const hasPermission = this.state.isEdit ? isAllow('EDIT_EMPLOYEE_DETAILS') : isAllow('CREATE_NEW_EMPLOYEE_BY_USER');

    return validFirstnameTh && validLastnameTh && validNicknameTh && validPhone1 && validEmail && hasPermission;
  }

  async submitForm() {
    try {
      if (this.state.isEdit) {
        await this.props.employee.updateEmployee(this.state.id);
      } else {
        await this.props.employee.createEmployee();
      }
    } catch (error) {
      throw error;
    }
  }

  async onSubmit() {
    let confirmDialogOptions = {
      title: 'บันทึกข้อมูล',
      text: this.state.isEdit ? 'กรุณายืนยันการแก้ไขรายชื่อพนักงาน' : 'กรุณายืนยันการเพิ่มรายชื่อพนักงาน',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      customClass: 'font-size-200',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await this.submitForm();
          return true;
        } catch (error) {
          swal.showValidationMessage(`การบันทึกล้มเหลว ${error}`);
        }
      },
      allowOutsideClick: () => !swal.isLoading(),
    };

    let result = await swal.fire(confirmDialogOptions);
    if (result.value) {
      swal.fire({
        title: 'บันทึกข้อมูลเรียบร้อย',
        icon: 'success',
      });
      setTimeout(() => {
        this.props.history.push(`${config.publicUrl}/employee`);
      }, 500);
    }
  }

  async onCancel() {
    swal.close();
    let url = `${config.publicUrl}/employee`;
    this.props.history.replace(url);
  }

  onBranchChange = (e) => {
    let branchId = e.split(',');
    if (branchId[branchId.length - 1] === 'ALL') {
      branchId = this.state.allBranch.map(b => b._id);
    }
    let data = []
    if (branchId[0].length > 1) {
      data = branchId.map((item) => {
        const nameBranch = this.state.allBranch.find((itemBranch) => itemBranch._id === item)
        return { id: item, name: nameBranch.name }
      })
    }
    this.props.employee.saveInfo('branch', data);
  }

  showBranchItem() {
    const info = this.props.employee.toJS().info;
    let branchOptions;
    branchOptions = (this.state.allBranch || []).map(d => {
      return ({
        label: d['name'],
        value: d['_id'],
      });
    });
    const idBranch = info.branch && typeof info.branch !== 'string' && Object.keys(info.branch).length > 0
      && info.branch.map((item) => item.id)
    if (info.branch && (this.state.allBranch.length !== info.branch.length)) {
      branchOptions = [{ label: 'เลือกทั้งหมด', value: 'ALL' }, ...branchOptions];
    }
    return (
      <FormGroup label="สาขา" containerClassName='col-md-6'>
        <EnTagSelect
          className='approve-tow-select'
          closeOnSelect={false}
          options={branchOptions}
          onTagChange={this.onBranchChange}
          value={idBranch}
          searchable
          placeholder=''
        />
      </FormGroup>
    );
  }

  render() {
    const { info, isLoading } = this.props.employee.toJS();
    let { isEdit } = this.state;
    const title = isEdit ? 'แก้ไขรายชื่อพนักงาน' : 'เพิ่มรายชื่อพนักงานใหม่';
    return (
      <Container isAdmin>
        <Loader show={isLoading} />
        <Notification ref={(ref) => { this.noti = ref; }} />
        <div className='row'>
          <div className='col-md-12'>
            <div className='card'>
              <div className="card-header" data-background-color="orange">
                <h4 className="title">{title}</h4>
                <p className="category">กรุณากรอกข้อมูลให้ครบถ้วน</p>
              </div>
              <div className='card-content'>
                <FormValidation>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="รหัสพนักงาน"
                      value={info.code}
                      onChange={this.onTextChange.bind(this, 'code')} />
                    {this.showBranchItem()}
                  </div>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="ชื่อ (ไทย)"
                      value={info.firstnameTh}
                      onChange={this.onTextChange.bind(this, 'firstnameTh')}
                      validations={[required]} />
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="นามสกุล (ไทย)"
                      value={info.lastnameTh}
                      onChange={this.onTextChange.bind(this, 'lastnameTh')}
                      validations={[required]} />
                  </div>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="ชื่อ (Eng)"
                      value={info.firstnameEn}
                      onChange={this.onTextChange.bind(this, 'firstnameEn')} />
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="นามสกุล (Eng)"
                      value={info.lastnameEn}
                      onChange={this.onTextChange.bind(this, 'lastnameEn')} />
                  </div>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="ชื่อเล่น (ไทย)"
                      value={info.nicknameTh}
                      onChange={this.onTextChange.bind(this, 'nicknameTh')}
                      validations={[required]} />
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="ชื่อเล่น (Eng)"
                      value={info.nicknameEng}
                      onChange={this.onTextChange.bind(this, 'nicknameEng')} />
                  </div>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="เบอร์ติดต่อ"
                      value={info.phone1}
                      onChange={this.onTextChange.bind(this, 'phone1')}
                      validations={[required]} />
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="เบอร์ติดต่อ (เพิ่มเติม)"
                      value={info.phone2}
                      onChange={this.onTextChange.bind(this, 'phone2')} />
                  </div>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="อีเมล"
                      value={info.email}
                      onChange={this.onTextChange.bind(this, 'email')}
                      validations={[required]} />
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="ทะเบียนรถ"
                      value={info.carRegistration}
                      onChange={this.onTextChange.bind(this, 'carRegistration')} />
                  </div>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="ตำแหน่ง"
                      value={info.position}
                      onChange={this.onTextChange.bind(this, 'position')} />
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="หน่วยงาน"
                      value={info.organization}
                      onChange={this.onTextChange.bind(this, 'organization')} />
                  </div>
                  <div className="row">
                    <EnTextFormGroup
                      containerClassName="col-md-6"
                      type="text"
                      label="Channel"
                      value={info.channel}
                      disabled
                      onChange={this.onTextChange.bind(this, 'channel')} />
                  </div>
                  <div className="row">
                    <FormGroup containerClassName="col-md-3" label="แสดงชื่อในการ update job" >
                      <EnToggle
                        checked={info.isAdmin}
                        onChange={this.onToggleChange.bind(this, 'isAdmin')}
                        disabled={!isAllow('EDIT_DISPLAY_STATUS_OF_EMPLOYEE_UPDATE_JOB')} />
                    </FormGroup>

                    {this.state.channel === 'q-chang' && (
                      <Fragment>
                        <FormGroup containerClassName="col-md-3" label="Superadmin Email" >
                          <EnToggle
                            checked={info.isSuperadminEmail}
                            onChange={this.onToggleChange.bind(this, 'isSuperadminEmail')}
                            disabled={!isAllow('EDIT_MAIL_TO_SUPER_ADMIN')} />
                        </FormGroup>
                        <FormGroup containerClassName="col-md-3" label="Contact Email" >
                          <EnToggle
                            checked={info.isContactEmail}
                            onChange={this.onToggleChange.bind(this, 'isContactEmail')}
                            disabled={!isAllow('EDIT_CONTACT_MAIL')} />
                        </FormGroup>
                      </Fragment>
                    )}
                  </div>
                  <div className="row coupon-button-box" >
                    <EnButton className="btn-info pull-right" disabled={!this.isValid()} onClick={this.onSubmit.bind(this)}>
                      <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
                    </EnButton>
                    <EnButton className="btn-danger pull-right" onClick={this.onCancel.bind(this)}>
                      <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
                    </EnButton>
                  </div>
                </FormValidation>
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default inject('auth', 'employee', 'coupon')(observer(EmployeePage));
