import BaseStore from '../BaseStore';
import config from '../../config';
import { http } from '../../utils/http';

export class Chat extends BaseStore {
  constructor() {
    super();
    this.observable({
      isLoading: false,
      info: this.initInfo(),
    });
  }

  initInfo() {
    return {
      lineUserId: '',
      lineDisplayName: '',
      linePictureUrl: '',
      to: '',
      botIndex: 0,
      message: '',
    };
  }

  clearInfo() {
    this.info = this.initInfo();
  }

  saveInfo(key, value) {
    const info = this.toJS().info;
    info[key] = value;
    this.info = info;
  }

  async createChat() {
    if (this.isLoading) return;
    this.isLoading = true;
    try {
      const info = this.toJS().info;
      const response = await http.post(`${config.api.bestdeal}/v1/chats`, info);
      if (response.status !== 200 && response.data) {
        throw new Error(response.data.message);
      }
      return response.data.data;
    } catch (error) {
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

export default new Chat();
