import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal } from 'react-bootstrap';
import EnButton from '../form/EnButton';
import { observer, inject } from 'mobx-react';

class ModalBlockContractor extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
  }

  initialState() {
    return {
      daySuspended: '1',
      daySuspendedOptions: Array.from({ length: 30 }, (_, index) => index + 1),
      customRadioValue: 1
    };
  }

  async saveModal() {
    await this.props.onSave({...this.state});
    this.setState(this.initialState());
  }

  closeModal() {
    this.props.onCancel();
    this.setState(this.initialState());
  }

  onChangeSelect(e) {
    this.setState({ daySuspended: e.target.value });
  }

  render() {
    const styles = {
      gridCenter: {
        display: 'grid',
        gridTemplateColumns: '30px 185px',
        gap: '5px',
        placeItems: 'start',
        width: '100%',
        justifyContent: 'center'
      },
      gridCenter2: {
        display: 'grid',
        gridTemplateColumns: '30px 55px 95px 25px',
        gap: '5px',
        placeItems: 'start',
        width: '100%',
        justifyContent: 'center'
      }
    }
    return (
      <Modal
        show={this.props.isVisible}
        onHide={() => this.closeModal()}
        backdrop="static"
      >
        <div className="position-modal-center">
          <h3>{this.props.title}</h3>
          <div style={{ fontSize: '22px', marginTop: '15px', cursor: 'default' }}>
            <div onClick={() => { this.setState({customRadioValue: 1}); }} style={styles.gridCenter}>
              <span className={this.state.customRadioValue === 1 ? 'circle circle--active' : 'circle'} />
              <span>ไม่กำหนดช่วงเวลา</span>
            </div>
            <div onClick={() => { this.setState({customRadioValue: 2}); }} style={{ ...styles.gridCenter2, marginTop: '10px' }}>
              <span className={this.state.customRadioValue === 2 ? 'circle circle--active' : 'circle'} />
              <span>
                พักงาน
              </span>
              <span>
                <select style={{ width: "80px", margin: "0" }} value={this.state.daySuspended} onChange={this.onChangeSelect.bind(this)}>
                  {this.state.daySuspendedOptions.map(day =>
                    <option value={day} key={`day${day}`}>{day}</option>
                  )}
                </select>
              </span>
              <span>
                วัน
              </span>
            </div>
          </div>
          <div style={{ marginTop: "3vw" }}>
            <EnButton className="btn-danger" onClick={this.closeModal.bind(this)}>
              <i className="fa fa-times btn-icon" aria-hidden="true" />ยกเลิก
            </EnButton>
            <EnButton style={{ marginLeft: '20px' }} disabled={false} className="btn-info" onClick={this.saveModal.bind(this)}>
              <i className="fa fa-floppy-o btn-icon" aria-hidden="true" />บันทึก
            </EnButton>
          </div>
        </div>
      </Modal>
    )
  }
}

ModalBlockContractor.defaultProps = {
  isVisible: true
};

ModalBlockContractor.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
};

export default inject('modal')(observer(ModalBlockContractor));